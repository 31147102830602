import { useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";

import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AddModeratorOutlinedIcon from "@mui/icons-material/AddModeratorOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import CompressOutlinedIcon from "@mui/icons-material/CompressOutlined";
import ExpandOutlinedIcon from "@mui/icons-material/ExpandOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import PlaylistPlayOutlinedIcon from "@mui/icons-material/PlaylistPlayOutlined";
import PlaylistRemoveOutlinedIcon from "@mui/icons-material/PlaylistRemoveOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import AddBoxIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

import { ErrorStatusEnum } from "core/enums";
import { getEnvVal } from "core/env.js";

import "./CellIconWithAction.css";

export default function CellIconWithAction({ row, clm, rowOptions, funcs }) {
	const userPermissions = useSelector((state) => state.auth.permissions);

	let cellTitle = "";
	let icon;

	if (clm.action === "navigate_new_tab_lsr_errors") {
		if (getEnvVal("App") === "guest") return;
		let row_dct_f = JSON.parse(JSON.stringify(row));
		row_dct_f.new_tab = true;
		if (row.model_name === "check_status") return;
		return (
			<div className="TreeActionCell" title="Открыть документ в новой вкладке" onClick={() => rowOptions["navigate"]["func"](row_dct_f)}>
				<OpenInNewOutlinedIcon />
			</div>
		);
	} else if (clm.action === "add_except_by_state") {
		let row_dct_f = JSON.parse(JSON.stringify(row));
		if (userPermissions.is_expert) {
			if (row.model_name === "check_status") return;
			return (
				<div className="TreeActionCell" title="Скрыть аналогичные записи" onClick={() => funcs["addExcept"](row_dct_f)}>
					<VisibilityOffOutlinedIcon />
				</div>
			);
		}
	} else if (clm.action === "filter_this_type_only") {
		if (userPermissions.is_expert) {
			if (row.model_name === "check_status") return;
			return (
				<div className="TreeActionCell" title="Отобрать данных только выбранного типа" onClick={() => funcs["filterThisTypeOnly"](row)}>
					<FilterAltOutlinedIcon />
				</div>
			);
		}
	} else if (clm.action === "hide_this_type") {
		if (userPermissions.is_expert) {
			if (row.model_name === "check_status") return;
			return (
				<div className="TreeActionCell" title="Скрыть данныe только выбранного типа" onClick={() => funcs["hideThisType"](row)}>
					<FilterAltOffOutlinedIcon />
				</div>
			);
		}
	} else if (clm.action === "action_show_more") {
		let funcName = "showMore";
		icon = <ExpandOutlinedIcon />;
		cellTitle = "Развенуть";
		if (row.rowHeightMultiply != null) {
			funcName = "hideMore";
			icon = <CompressOutlinedIcon />;
			cellTitle = "Свернуть";
		}
		return (
			<div className="TreeActionCell" title={cellTitle.toString()} onClick={() => funcs[funcName](row)}>
				{icon}
			</div>
		);
	} else if (clm.action === "show_error_status") {
		if (row.error_status_id === ErrorStatusEnum.ERROR) {
			return (
				<div className="TreeActionCell" title="Значимая ошибка">
					<ErrorOutlineOutlinedIcon />
				</div>
			);
		} else if (row.error_status_id === ErrorStatusEnum.WARNING) {
			return (
				<div className="TreeActionCell" title="Предупреждение">
					<WarningAmberOutlinedIcon />
				</div>
			);
		} else if (row.error_status_id === ErrorStatusEnum.ALERT) {
			return (
				<div className="TreeActionCell" title="Уведомление">
					<InfoOutlinedIcon />
				</div>
			);
		}
	} else if (clm.action === "protection") {
		let isProtected;
		if (row.hasOwnProperty("protected_id")) {
			icon = <ShieldOutlinedIcon />;
			isProtected = "true";
		} else {
			icon = <AddModeratorOutlinedIcon />;
			isProtected = "false";
		}
		row.protectable === true ? (cellTitle = "Защита") : (cellTitle = "");
		if (userPermissions.is_expert) {
			return (
				<div title={cellTitle.toString()} is_protected={isProtected} className="TreeFlexCell" onClick={() => funcs["lsrProtection"](row)}>
					{icon}
				</div>
			);
		}
	} else if (clm.action === "add_cost_content") {
		const rowCopy = JSON.parse(JSON.stringify(row));
		rowCopy.parentRow = rowOptions.parentRow;
		if (row.costContentShow === true) {
			icon = <PlaylistRemoveOutlinedIcon />;
		} else {
			icon = <PlaylistPlayOutlinedIcon />;
		}
		return (
			<div className="TreeActionCell" title="Открыть состав работ" onClick={() => funcs["addCostContent"](rowCopy)}>
				{icon}
			</div>
		);
	} else if (clm.action === "open_fsnb_catalog") {
		return (
			<div
				className="TreeActionCell"
				title="Открыть ФСНБ"
				action="open_fsnb_catalog"
				onClick={() => {
					funcs["openFsnbCatalog"](row);
				}}
			>
				<AccountTreeOutlinedIcon />
			</div>
		);
	} else if (clm.action === "show_error_justification") {
		icon = <InfoOutlinedIcon />;
		return (
			<div
				className="TreeActionCell"
				title="Информация об ошибке"
				action="show_error_justification"
				onClick={() => funcs["showErrorJustification"](row)}
			>
				{icon}
			</div>
		);
	} else if (clm.action === "show_fsnb_content") {
		if (row.model_name === "FsnbNorm") {
			icon = row.contentVisibility ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxIcon />;
			return (
				<div className="TreeActionCell" title="Показать состав работ" onClick={() => funcs["changeNormContentVisible"](row, "content")}>
					{icon}
				</div>
			);
		}
	} else if (clm.action === "show_fsnb_resource") {
		if (row.model_name === "FsnbNorm") {
			icon = row.resourcesVisibility ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxIcon />;
			return (
				<div className="TreeActionCell" title="Показать состав нормы" onClick={() => funcs["changeNormContentVisible"](row, "resources")}>
					{icon}
				</div>
			);
		}
	} else if (clm.action === "show_fsnb_coefs") {
		return (
			<div className="TreeActionCell" title="Показать коэффициенты" action="show_fsnb_coefs" onClick={() => funcs["showCoefs"](row)}>
				<InfoOutlinedIcon />
			</div>
		);
	} else if (clm.action === "show_fsnb_coefs_on_error") {
		return (
			<div className="TreeActionCell" title="Показать коэффициенты" action="show_fsnb_coefs" onClick={() => funcs["showCoefsOnError"](row)}>
				<InfoOutlinedIcon />
			</div>
		);
	} else if (clm.action === "show_protocol_status_icon_error") {
		return (
			<div className="TreeShowCellError">
				<ErrorOutlineIcon />
			</div>
		);
	} else if (clm.action === "show_protocol_status_icon_success") {
		return (
			<div className="TreeShowCellSuccess">
				<CheckCircleOutlineIcon />
			</div>
		);
	} else if (clm.action === "show_tech_groups") {
		if (row.technology_groups == null) return;
		return (
			<div
				className="TreeActionCell"
				title="Показать технологические группы"
				action="show_tech_groups"
				onClick={() => funcs["showTechGroup"](row)}
			>
				<InfoOutlinedIcon />
			</div>
		);
	} else if (clm.action === "show_tech_groups_from_estm") {
		return (
			<div
				className="TreeActionCell"
				title="Показать технологические группы"
				action="show_tech_groups_from_estm"
				onClick={() => funcs["openTechGrp"](row)}
			>
				<InfoOutlinedIcon />
			</div>
		);
	} else if (clm.action === "show_connected_norm_on_nrsp") {
		return (
			<div
				className="TreeActionCell"
				title="Показать сметные нормы"
				action="show_connected_norm_on_nrsp"
				onClick={() => funcs["showConnectedNorm"](row)}
			>
				<InfoOutlinedIcon />
			</div>
		);
	} else if (clm.action === "check_box_select") {
		return (
			<div className="TreeActionCell" title="Показать сметные нормы" action="check_box_select">
				<Checkbox checked={row.isChecked == null ? false : row.isChecked} onChange={() => funcs["checkBoxRow"](row)} />
			</div>
		);
	} else if (clm.action === "show_source_cost_formulas") {
		if (row.has_cost !== true) return;
		return (
			<div
				className="TreeActionCell"
				title="Показать формулу расчета измерителя"
				action="show_source_cost_formulas"
				onClick={(e) => funcs["showSourceCostFormulas"](row, e)}
			>
				<CalculateOutlinedIcon />
			</div>
		);
	}
}

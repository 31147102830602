import axios from "axios";

//let API_URL = "http://10.2.4.12:5030";
let API_URL = "http://10.166.71.8:5030";

if (process.env.REACT_APP_ENV === "stage") {
	API_URL = "http://10.2.4.11:5032/api";
} else if (process.env.REACT_APP_ENV === "ais") {
	API_URL = "http://10.166.8.16:80/api";
} else if (process.env.REACT_APP_ENV === "ecpe") {
	API_URL = "https://smeta.platformaexpert.ru/api";
} else if (process.env.REACT_APP_ENV === "sipcs") {
	API_URL = "http://37.230.139.29:5032/api";
} else if (process.env.REACT_APP_ENV === "dev2") {
	API_URL = "http://10.166.67.27:5030";
} else if (process.env.REACT_APP_ENV === "guest") {
	API_URL = "https://smetafree.platformaexpert.ru/api";
}

export const apiBase = {
	API_URL: API_URL,
	get_headers() {
		return {
			headers: {
				Authorization: localStorage.getItem("accessToken"),
				"Auth-Uuid": localStorage.getItem("authUuid"),
				"Session-Project": localStorage.getItem("SessionProject"),
				"Guest-Uuid": localStorage.getItem("guestUuid"),
			},
		};
	},
	get_headersPdf() {
		return {
			responseType: "blob",
			headers: {
				Authorization: localStorage.getItem("accessToken"),
				"Auth-Uuid": localStorage.getItem("authUuid"),
				"Session-Project": localStorage.getItem("SessionProject"),
				"Guest-Uuid": localStorage.getItem("guestUuid"),
				"Content-Type": "application/pdf",
			},
		};
	},
	get_client() {
		return axios.create({ baseURL: this.API_URL });
	},
	get_file_name(text) {
		let filename = decodeURIComponent(text);
		return filename.slice(filename.indexOf("UTF") + 7, 999);
	},
};

export const apiToken = {
	refreshToken() {
		setInterval(() => {
			const client = axios.create();
			const body = { refresh: localStorage.getItem("refreshToken") };
			const prom = client.post(API_URL + "/token/refresh/", body);
			prom.then((responce) => {
				localStorage.setItem("accessToken", "Bearer " + responce.data.access);
				localStorage.setItem("refreshToken", responce.data.refresh);
			});
		}, 1000 * 60 * 2);
	},
};

export const API_BASE_URL = API_URL;

export const getInstanceName = () => {
	return process.env.REACT_APP_ENV;
};

export const allowShowKatsService = () => {
	if (["ais", "ecpe", "sipcs"].includes(getInstanceName())) {
		return false;
	} else {
		return true;
	}
};

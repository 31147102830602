import Checkbox from '@mui/material/Checkbox';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import './TreeMain.css';

export default function TreeMainNew(props) {
    const treeDom = [];
    const paddingMul = 5;
    let paddingLeftArrow;

    if (props.paddingPropsArrow) { paddingLeftArrow = props.paddingPropsArrow };

    let nameField = "name";
    if (props.nameField) nameField = props.nameField

    const TreeRowCheckBox = ({ row, level }) => {
        const getArrowDown = (row, level) => {
            return <KeyboardArrowDownIcon
                style={{ paddingLeft: level * paddingMul }}
                onClick={() => props.changeTreeVisible(row)}>
            </KeyboardArrowDownIcon>
        }

        const getArrowRight = (row, level) => {
            return <KeyboardArrowRightIcon
                style={{ paddingLeft: level * paddingMul }}
                onClick={() => props.changeTreeVisible(row)}>
            </KeyboardArrowRightIcon>
        }

        const getArrow = (row, level) => {
            if (row._children && row._children.length) {
                if (row.childsVisible) {
                    return getArrowDown(row, level);
                } else {
                    return getArrowRight(row, level);
                }
            } else {

                if (props.alignPaddingNullLevel && level === 0) {
                    return <span style={{ paddingLeft: level * paddingMul + paddingLeftArrow + "px" }} />
                } else {
                    return <span style={{ paddingLeft: level * paddingMul + 10 + "px" }} />
                }

            }
        }

        const getCheckBox = (row) => {
            if (!props.useCheckBox) return
            let isChecked = false;
            let checkBoxColor = "primary";

            if (row.isChecked) isChecked = true;

            if (row._children != null) {
                const childsCnt = row._children.length;
                const childsCheckedCnt = row._children.filter(x => x.isChecked).length;
                childsCheckedCnt > 0 ? isChecked = true : isChecked = false;
                if (childsCnt > childsCheckedCnt) checkBoxColor = "default";
            }

            return (
                <Checkbox
                    checked={isChecked}
                    color={checkBoxColor}
                    onChange={() => props.changeTreeChecked(row)}
                />
            )
        }

        const getPaddingLeft = (row, level) => {
            let padLeft = level * paddingMul;
            if (row._children == null) padLeft += 8;
            return padLeft;
        }

        const rowDom = (
            <div key={row.key}>
                <div className="TreeRowWrapper"
                    seleted={String(row.selected)}>
                    <div
                        className="TreeRow"
                        title={row[nameField]}
                        key={row.key}
                        style={{ paddingLeft: getPaddingLeft(row, level) + "px" }}
                        onClick={() => props.selectTreeRow(row)}
                    >
                        {getArrow(row, level)}
                        {getCheckBox(row)}
                        <span>{row[nameField]}</span>
                    </div>
                </div>
                {row.childsVisible ? (
                    row._children?.map((ch) => {
                        const levelChild = level + 1;
                        return TreeRowCheckBox({ row: ch, level: levelChild });
                    })
                ) : (
                    <></>
                )}
            </div>
        )
        return rowDom
    }

    for (let row of props.data) {
        const rowDom = TreeRowCheckBox({ row: row, level: 0 })
        treeDom.push(rowDom);
    }

    return treeDom;
}


import { CustomNumCell } from "./components/CustomCell/CustomNumCell";
import { CustomMarksCell } from "./components/CustomCell/CustomMarksCell";

export default function LocalEstimateTableColumnsRim() {
	let numWidht = 50;
	let codeWidht = 165;
	let unitWidht = 75;

	let consumptionWidht = 100;
	let consumption_coefWidht = 100;
	let consumptiontotalWidht = 100;

	let priceperunitbaseWidht = 100;
	let index_valueWidht = 100;
	//let priceperunitcurWidht = 100;
	let pricetotalcurWidht = 100;
	let cost_coefWidht = 100;

	let checkWidht = 20;
	let protWidht = 7;

	let sumWidht = 0;
	for (let x of [
		numWidht,
		codeWidht,
		unitWidht,
		consumptionWidht,
		consumption_coefWidht,
		consumptiontotalWidht,
		priceperunitbaseWidht,
		index_valueWidht, //priceperunitcurWidht,
		pricetotalcurWidht,
		cost_coefWidht,
		checkWidht,
		protWidht,
	]) {
		sumWidht += x;
	}

	let nameWidht = window.innerWidth * 0.7 - sumWidht;

	if (nameWidht < 300) nameWidht = 300;

	return [
		{
			name: "num",
			title: "№ п/п",
			width: numWidht + "px",
			cellHorAlign: "center",
			headHorAlign: "center",
			rowSpan: "2",
			headHeight: "25px",
			marginLeft: "0px",
			cellJsx: ({ rowObj }) => CustomNumCell({ rowObj }),
		},
		{
			name: "code",
			title: "Обоснование",
			width: codeWidht + "px",
			cellHorAlign: "center",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "25px",
			marginLeft: "10px",
		},
		{
			name: "name",
			title: "Наименование работ и затрат",
			width: nameWidht + "px",
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "25px",
			marginLeft: "10px",
			actions_icon_on_cell: [
				{
					check_value: {
						key: "model_name",
						value: ["CostParentLabel"],
					},
					action: "add_cost_content",
				},
				{
					check_value: {
						key: "model_name",
						value: ["LocalEstimatesItems", "LocalEstimatesResMachine", "LocalEstimatesResMaterial", "LocalEstimatesResEquipment"],
					},
					action: "open_fsnb_catalog",
				},
				{
					check_value: {
						key: "res_has_tech_gpr",
						value: [true],
					},
					action: "show_tech_groups_from_estm",
				},
				{
					check_value: {
						key: "cost_has_coef",
						value: [true],
					},
					action: "show_fsnb_coefs",
				},
			],
		},
		{
			name: "unit",
			title: "Ед. изм.",
			width: unitWidht + "px",
			cellHorAlign: "center",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "25px",
			marginLeft: "10px",
		},
		{
			name: "consum",
			title: "Количество",
			width: "300px",
			cellHorAlign: "left",
			headHorAlign: "center",
			headHeight: "25px",
			colSpan: "3",
			children: [
				{
					name: "consumption",
					title: "на ед. изм.",
					width: consumptionWidht + "px",
					cellHorAlign: "center",
					headHorAlign: "center",
					headHeight: "33px",
					actions_icon_on_cell: [
						{
							check_value: {
								key: "has_cost",
								value: [true],
							},
							action: "show_source_cost_formulas",
						},
					],
				},
				{
					name: "consumption_coef",
					title: "коэф.",
					width: consumption_coefWidht + "px",
					cellHorAlign: "center",
					headHorAlign: "center",
					headHeight: "33px",
				},
				{
					name: "consumptiontotal",
					title: "всего с учетом коэф.",
					width: consumptiontotalWidht + "px",
					cellHorAlign: "center",
					headHorAlign: "center",
					headHeight: "33px",
				},
			],
		},
		{
			name: "cost",
			title: "Сметная стоимость, руб.",
			width: "500px",
			cellHorAlign: "left",
			headHorAlign: "center",
			headHeight: "25px",
			colSpan: "5",
			children: [
				{
					name: "priceperunitbase",
					title: "на ед. изм. в баз. цен",
					width: priceperunitbaseWidht + "px",
					cellHorAlign: "center",
					headHorAlign: "center",
					headHeight: "33px",
				},
				{
					name: "index",
					title: "индекс",
					width: index_valueWidht + "px",
					cellHorAlign: "center",
					headHorAlign: "center",
					headHeight: "33px",
				},
				{
					name: "priceperunitcur",
					title: "на ед. изм. в тек. цен",
					width: pricetotalcurWidht + "px",
					cellHorAlign: "center",
					headHorAlign: "center",
					headHeight: "33px",
				},
				{
					name: "cost_coef",
					title: "коэф.",
					width: cost_coefWidht + "px",
					cellHorAlign: "center",
					headHorAlign: "center",
					headHeight: "32px",
				},
				{
					name: "pricetotalcur",
					title: "всего в тек. цен",
					width: pricetotalcurWidht + "px",
					cellHorAlign: "center",
					headHorAlign: "center",
					headHeight: "32px",
				},
			],
		},
		{
			name: "marks",
			rowSpan: "2",
			cellJsx: ({ rowObj, parentProps, funcs }) => CustomMarksCell({ rowObj, parentProps, funcs }),
		},
	];
}

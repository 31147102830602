import React, { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import apiCalcer from '../../api/calcer';
import TableMain from '../../components/Table/TableMain';
import AlertLoading from "../../components/AlertLoading";
import IndexesSmrTableColumns from './IndexesSmrTableColumns';

import "./IndexesSmr.css";
import "./IndexesAutocomplete.css";
import "./IndexesTable.css";
import RibbonContent from "components/Ribbon/RibbonContent/RibbonContent";
import IndexesSmrRibbonSettings from "./IndexesSmrRibbonSettings";

export default function IndexesSmr() {
    const [fetchStatus, setFetchStatus] = useState(null);
    const [fetchStatusText, setFetchStatusText] = useState("");

    const [objTypes, setObjTypes] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [priceZones, setPriceZones] = useState([]);

    const [selectedObjType, setSelectedObjType] = useState({});
    const [selectedPeriod, setSelectedPeriod] = useState({});
    const [selectedPriceZone, setSelectedPriceZone] = useState({});

    const [defaultPeriod, setDefaultPeriod] = useState({});
    const [defaultPriceZone, setDefaultPriceZone] = useState({});

    const [rowsData, setRowsData] = useState({});
    const columnsData = IndexesSmrTableColumns();

    useEffect(() => {
        setFetchStatus("loading");
        const objPromise = new Promise((resolve, reject) => {
            apiCalcer.getObjectTypes()
                .then(response => { resolve(response.data); })
                .catch(error => { reject(error); });
        });

        const perPromise = new Promise((resolve, reject) => {
            apiCalcer.getPeriods()
                .then(response => { resolve(response.data); })
                .catch(error => { reject(error); });
        });

        const pzPromise = new Promise((resolve, reject) => {
            apiCalcer.getPriceZones()
                .then(response => { resolve(response.data); })
                .catch(error => { reject(error); });
        });

        Promise.all([objPromise, perPromise, pzPromise]).then(response_arrs => {
            setObjTypes(response_arrs[0]);
            setPeriods(response_arrs[1]);
            setPriceZones(response_arrs[2]);
            return response_arrs;
        }).then(data => {
            if (localStorage.getItem("indexPriceZonesId") != null) {
                const pzVal = data[2].find(x => String(x["id"]) === localStorage.getItem("indexPriceZonesId"));
                setDefaultPriceZone(pzVal);
            } else {
                setDefaultPriceZone(data[2][0]);
            }
            if (localStorage.getItem("indexPeriodId") != null) {
                const perVal = data[1].find(x => String(x["id"]) === localStorage.getItem("indexPeriodId"));
                setDefaultPeriod(perVal);
            } else {
                setDefaultPeriod(data[1][0]);
            }
            setFetchStatus("success");
        }).catch(error => {
            console.error(error);
            setFetchStatus("failed");
            if (error?.response?.data?.detail != null) {
                setFetchStatusText(error.response.data.detail);
            } else {
                setFetchStatusText(error.message);
            }
        });
    }, [])


    useEffect(() => {
        setFetchStatus("loading");
        if (selectedPriceZone.id != null && selectedPeriod.id != null) {
            let apiPromise;
            if (selectedObjType.id == null) {
                apiPromise = apiCalcer.getIndexSmrDefault(selectedPriceZone.id, selectedPeriod.id);
            } else {
                apiPromise = apiCalcer.getIndexSmr(selectedPriceZone.id, selectedPeriod.id, selectedObjType.id)
            }
            apiPromise.then(response => {
                setRowsData({ "data": response.data.map(x => ({ ...x, key: x.id })) });
                setFetchStatus("success");
            }).catch(error => {
                setFetchStatus("failed");
                if (error?.response?.data?.detail != null) {
                    setFetchStatusText(error.response.data.detail);
                } else {
                    setFetchStatusText(error.message);
                }
            });
        }
    }, [selectedObjType, selectedPriceZone, selectedPeriod])

    if (fetchStatus === "failed") {
        return <AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} />
    }

    if (objTypes.length === 0 || periods.length === 0 || priceZones.length === 0) {
        return
    }

    const periodPriceZoneAutocomplete = () => {
        return <div className="indexesAutocompleteWrapper">

            <Autocomplete
                sx={{
                    width: '100%',
                    "& legend": {
                        fontSize: "9px"
                    }
                }}
                options={periods}
                defaultValue={defaultPeriod}
                disableClearable
                getOptionLabel={(option) => option.name}
                size="small"
                noOptionsText="нет данных"
                onInputChange={(e, val) => {
                    const inpVal = periods.find(x => x["name"] === val);
                    if (inpVal != null) { setSelectedPeriod(inpVal) }
                }}
                onClose={(e) => {
                    const itm = periods.find(x => x["name"] === e.target.innerText);
                    if (itm != null) { localStorage.setItem("indexPeriodId", itm.id) }
                }}
                renderInput={(params) => <TextField {...params}
                    InputLabelProps={{ shrink: true, }} label="Период" />}
            />
            <Autocomplete
                sx={{
                    width: '100%',
                    "& legend": {
                        fontSize: "9px"
                    }
                }}
                options={priceZones}
                defaultValue={defaultPriceZone}
                disableClearable
                getOptionLabel={(option) => option.name}
                size="small"
                noOptionsText="нет данных"
                onInputChange={(e, val) => {
                    const inpVal = priceZones.find(x => x["name"] === val);
                    if (inpVal != null) { setSelectedPriceZone(inpVal) }
                }}
                onClose={(e) => {
                    const itm = priceZones.find(x => x["name"] === e.target.innerText);
                    if (itm != null) { localStorage.setItem("indexPriceZonesId", itm.id) }
                }}
                renderInput={(params) => <TextField
                    {...params}
                    InputLabelProps={{ shrink: true, }}
                    label="Ценовая зона"
                />}
            />
        </div>
    }

    const objectAutocomplete = () => {
        return <Autocomplete
            sx={{
                width: '100%',
                "& legend": {
                    fontSize: "9px"
                }
            }}
            options={objTypes}
            getOptionLabel={(option) => option.name}
            size="small"
            noOptionsText="нет данных"
            onInputChange={(e, val) => {
                const optVal = objTypes.find(x => x["name"] === val);
                optVal == null ? setSelectedObjType({}) : setSelectedObjType(optVal)
            }}
            renderInput={(params) => <TextField {...params} label="Объект строительства" />}
        />
    }

    return (
        <>
            <RibbonContent ribbonSettings={IndexesSmrRibbonSettings(periodPriceZoneAutocomplete, objectAutocomplete)} />
            <div className="IndexesSmr">
                <div className="IndexesFilters">
                    {/*  <Autocomplete
                        sx={{ width: 500 }}
                        options={objTypes}
                        getOptionLabel={(option) => option.name}
                        size="small"
                        noOptionsText="нет данных"
                        onInputChange={(e, val) => {
                            const optVal = objTypes.find(x => x["name"] === val);
                            optVal == null ? setSelectedObjType({}) : setSelectedObjType(optVal)
                        }}
                        renderInput={(params) => <TextField {...params} label="Объект строительства" />}
                    />
                    <Autocomplete
                        sx={{ width: 150 }}
                        options={periods}
                        defaultValue={defaultPeriod}
                        disableClearable
                        getOptionLabel={(option) => option.name}
                        size="small"
                        noOptionsText="нет данных"
                        onInputChange={(e, val) => {
                            const inpVal = periods.find(x => x["name"] === val);
                            if (inpVal != null) { setSelectedPeriod(inpVal) }
                        }}
                        onClose={(e) => {
                            const itm = periods.find(x => x["name"] === e.target.innerText);
                            if (itm != null) { localStorage.setItem("indexPeriodId", itm.id) }
                        }}
                        renderInput={(params) => <TextField {...params}
                            InputLabelProps={{ shrink: true, }} label="Период" />}
                    />
                    <Autocomplete
                        sx={{ width: 350 }}
                        options={priceZones}
                        defaultValue={defaultPriceZone}
                        disableClearable
                        getOptionLabel={(option) => option.name}
                        size="small"
                        noOptionsText="нет данных"
                        onInputChange={(e, val) => {
                            const inpVal = priceZones.find(x => x["name"] === val);
                            if (inpVal != null) { setSelectedPriceZone(inpVal) }
                        }}
                        onClose={(e) => {
                            const itm = priceZones.find(x => x["name"] === e.target.innerText);
                            if (itm != null) { localStorage.setItem("indexPriceZonesId", itm.id) }
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            InputLabelProps={{ shrink: true, }}
                            label="Ценовая зона"
                        />}
                    />*/}
                </div>
                <div className="IndexesTable">
                    <TableMain
                        rows={rowsData}
                        columns={columnsData}
                        options={{
                            "selectable": false,
                        }}
                        func={{}}
                    ></TableMain>
                </div>
                <AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} />
            </div >
        </>


    )
}

import LinkableErrorCell from "./components/LinkableErrorCell";
import CellWIthColorPart from "components/Table/CellFormatter/CellWIthColorPart";

export default function ProtocolTableColumns(objType) {
	const getWhiteSpace = (rowObj) => {
		let whiteSpace;
		if (rowObj.rowHeightMultiply != null) whiteSpace = "normal";
		return whiteSpace;
	};

	let clms = [
		{
			name: "num",
			title: "№",
			width: "40px",
			cellHorAlign: "center",
			headHorAlign: "center",
			rowSpan: "2",
			headHeight: "22px",
			marginLeft: "1px",
			actions_icon_on_cell: [
				{
					check_value: {
						key: "key",
						value: ["last_error"],
					},
					action: "show_protocol_status_icon_error",
				},
				{
					check_value: {
						key: "key",
						value: ["last_success"],
					},
					action: "show_protocol_status_icon_success",
				},
			],
		},
		{
			name: "error_status_id",
			title: "",
			width: "4px",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			showonselect: true,
			action: "show_error_status",
		},
		{
			name: "state_name",
			title: "Проверка",
			width: "300px",
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "22px",
			marginLeft: "1px",
			cellJsx: ({ rowObj }) => {
				let colSpan = 0;
				if (rowObj.selected !== true) colSpan = 3;
				let titleValue = rowObj.state_name == null ? "" : rowObj.state_name.toString();
				let cellJsx = <>{titleValue}</>;

				return (
					<td
						key={`cell-state_name-${rowObj.id}`}
						style={{ textAlign: "left", width: "auto", whiteSpace: getWhiteSpace(rowObj) }}
						className="TableCell"
						clm="state_name"
						treecell="false"
						colSpan={colSpan}
						title={titleValue}
					>
						{cellJsx}
					</td>
				);
			},
		},
		{
			name: "filter_this_type_only",
			title: "",
			width: "8px",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			showonselect: true,
			action: "filter_this_type_only",
			action_show: "on_select",
		},
		{
			name: "hide_this_type",
			title: "",
			width: "8px",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			showonselect: true,
			action: "hide_this_type",
			action_show: "on_select",
		},
		{
			name: "item_num",
			title: "Поз.",
			width: "35px",
			cellHorAlign: "center",
			headHorAlign: "center",
			rowSpan: "2",
			headHeight: "22px",
			marginLeft: "1px",
		},
		{
			name: "text",
			title: "Описание ошибки",
			width: "auto",
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "22px",
			marginLeft: "1px",
			cellJsx: ({ rowObj }) => {
				let colSpan = 0;
				if (rowObj.selected !== true) colSpan = 3;
				let titleValue = rowObj.text == null ? "" : rowObj.text.toString();
				let cellJsx = <>{titleValue}</>;
				if (rowObj.text_with_action) {
					cellJsx = <LinkableErrorCell rowObj={rowObj} />;
					titleValue = rowObj.text_with_action.text == null ? "" : rowObj.text_with_action.text.toString().replaceAll("$1", "");
				}
				return (
					<td
						key={`cell-text-${rowObj.id}`}
						style={{ textAlign: "left", width: "auto", whiteSpace: getWhiteSpace(rowObj) }}
						className="TableCell"
						clm="text"
						treecell="false"
						colSpan={colSpan}
						title={titleValue}
					>
						{cellJsx}
					</td>
				);
			},
		},
		{
			name: "action_filter_state",
			title: "",
			width: "8px",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			showonselect: true,
			action: "add_except_by_state",
			action_show: "on_select",
		},
		{
			name: "action",
			title: "",
			width: "8px",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			showonselect: true,
			action: "navigate_new_tab_lsr_errors",
			action_show: "on_select",
		},
		{
			name: "expected",
			title: "Ожидаемое значение",
			width: "250px",
			cellHorAlign: "left",
			headHorAlign: "center",
			rowSpan: "2",
			headHeight: "22px",
			marginLeft: "1px",
			actions_icon_on_cell: [
				{
					check_value: {
						// key: "model_name",
						// value: [undefined],
						key: "has_just",
						value: [true],
					},
					action: "show_error_justification",
				},
				{
					check_value: {
						key: "code",
						value: ["2.14.1"],
					},
					action: "show_fsnb_coefs",
				},
			],
		},
		{
			name: "received",
			title: "Полученное значение",
			width: "250px",
			cellHorAlign: "left",
			headHorAlign: "center",
			rowSpan: "2",
			headHeight: "22px",
			marginLeft: "1px",
			cellJsx: ({ rowObj }) => {
				const cellValue = rowObj.received == null ? "" : rowObj.received.toString();
				let colSpan = 2;
				if (rowObj.selected) colSpan = 1;

				if (String(cellValue).includes("<color:")) {
					const cellTitle = cellValue.slice(0, cellValue.indexOf("<color"));
					return (
						<td
							key={`cell-received-${rowObj.id}`}
							style={{ whiteSpace: getWhiteSpace(rowObj) }}
							className="TableCell"
							clm="received"
							colSpan={colSpan}
							treecell="false"
							title={cellTitle}
						>
							<CellWIthColorPart value={cellValue} />
						</td>
					);
				} else {
					return (
						<td
							key={`cell-received-${rowObj.id}`}
							style={{ whiteSpace: getWhiteSpace(rowObj) }}
							className="TableCell"
							clm="received"
							colSpan={colSpan}
							treecell="false"
							title={cellValue}
						>
							{cellValue}
						</td>
					);
				}
			},
		},
		{
			name: "action_show_more",
			title: "",
			width: "8px",
			cellHorAlign: "center",
			headHorAlign: "center",
			rowSpan: "2",
			headHeight: "22px",
			sort: false,
			filter: false,
			editable: false,
			showonselect: true,
			action: "action_show_more",
			action_show: "on_select",
		},
	];

	if (!["lsr", "kats", "osr"].includes(objType)) {
		clms = clms.filter((clm) => clm.name !== "item_num");
	}

	return clms;
}

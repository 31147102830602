import { apiBase } from "./base";

const apiImportFiles = {
    client: apiBase.get_client(),
    url: "/smetdocs/import/",

    import_xml(file, project_id, index) {
        let formData = new FormData();
        formData.append("file", file);

        return this.client.post(this.url + "xml/" + project_id + "/" + index + "/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("accessToken"),
                "Session-Project": localStorage.getItem("SessionProject"),
                "Auth-Uuid": localStorage.getItem("authUuid"),
            },
        });
    },

    getProgress(body) {
        return this.client.post(this.url + "progress/", body, apiBase.get_headers());
    },

    getImportAttempts(id) {
        return this.client.get(`${this.url}attempts/${id}/`, apiBase.get_headers());
    },

    getImportBrainStormAttempts(id) {
        return this.client.get(`${this.url}attempts_brain_storm/${id}/`, apiBase.get_headers());
    },

    getXsdErrors(id) {
        return this.client.get(`${this.url}attempts/xsd_errors/${id}/`, apiBase.get_headers());
    },

    getXsdEstmErrorsExcel(id) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "blob";
        return this.client.get(`${this.url}attempts/xsd_errors/estm/${id}/excel/`, headers);
    },

    getXsdProjErrorsExcel(id) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "blob";
        return this.client.get(`${this.url}attempts/xsd_errors/project/${id}/excel/`, headers);
    },
};

export default apiImportFiles;

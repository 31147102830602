import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";

import apiLocalEstimateEditor from "api/local_estimate_editor";
import apiFsnb from "api/fsnb";

import { searchDataRecourse, changeRowDataRecourse } from "./utils";
import { changeNestedIterableKeyValue } from "core/iterable_utils";
import {
	ChangeSectionChildsVisible,
	ChangeEndingChildsVisible,
	ChangeItemChildsVisible,
	ShowHideItemsNameVisible,
} from "pages/EstimateViewer/LocalEstimateViewer/utils/table_utils";

export const getOrCreateCacheJson = createAsyncThunk("localEstimateEditor/cacheJson", async (id, { rejectWithValue }) => {
	try {
		const response = await apiLocalEstimateEditor.getOrCreateCacheJson(id);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response.data);
	}
});

export const getFsnbAutocompete = createAsyncThunk("localEstimateSlice/edit", async ({ val, versId, limit }) => {
	const response = await apiFsnb.getFsnbAutocompete({ val, versId, limit });
	return response.data;
});

export const localEstimateEditor = createSlice({
	name: "localEstimateEditor",
	initialState: {
		data: {},
		tableData: [],
		visibleSettings: {},
		cancelActions: [],
		techGroupTableData: [],
		editorMode: "",
		clickedRowBeforeNav: {},
		fetchStatus: null,
		fetchRibbonStatus: null,
		fetchStatusText: null,
		prevCell: { key: "", value: "" },
		counter: 0,
		editorCellisActive: false,
		arrowMoveisActive: false,
		inputAutocompleteSettings: {
			visible: false,
			positionTop: 0,
			inputVal: "",
			data: [],
			filtredData: [],
			activateInnerScroll: 0,
			disableInputEnter: false,
		},
		itemBuffer: {},
		selectedItems: [],
		rowsMap: {},
		itemsMap: {},
		ItemIdAddAfter: 0,
	},
	reducers: {
		setCancelActions: (state, { payload }) => {
			state.cancelActions = payload;
		},
		setTableData: (state, { payload }) => {
			state.tableData = payload;
		},
		setData: (state, { payload }) => {
			state.data = payload;
		},
		setTechGroupTableData: (state, { payload }) => {
			state.techGroupTableData = payload;
		},
		setFetchStatus: (state, { payload }) => {
			state.fetchStatus = payload;
		},
		setFetchRibbonStatus: (state, { payload }) => {
			state.fetchRibbonStatus = payload;
		},
		setFetchStatusText: (state, { payload }) => {
			state.fetchStatusText = payload;
		},
		changeRowData: (state, { payload: { rowKey, callback } }) => {
			const t0 = performance.now();
			state.tableData = changeRowDataRecourse(state.tableData, rowKey, callback);
			const t1 = performance.now();
			if (t1 - t0 > 1) console.log(`Call to changeRowData took ${t1 - t0} milliseconds.`);
		},
		selectItemRowWithChilds: (state, { payload: { itemId, sectionId, color, isAnimated, itemIdCallback } }) => {
			state.tableData = state.tableData.map((sec) => {
				if (sec.id === sectionId) {
					if (sec._children) {
						sec._children = sec._children.map((item) => {
							if (item.item_id === itemId) {
								item.bgColor = color;
								if (item._children) item._children = changeNestedIterableKeyValue(item._children, "bgColor", color);

								if (isAnimated) {
									item.is_animated = true;
									if (item._children) item._children = changeNestedIterableKeyValue(item._children, "is_animated", true);
								}
								if (itemIdCallback) item = itemIdCallback(item);
							}
							return item;
						});
					}
				}
				return sec;
			});
		},
		increaseCounter: (state, { payload }) => {
			state.counter++;
		},
		changeSectionChildsVisible: (state, { payload }) => {
			const visibleSetCopy = { ...state.visibleSettings, mock: false };
			const { tableData, visibleSet } = ChangeSectionChildsVisible({ tableData: state.tableData, value: payload, visibleSet: visibleSetCopy });
			state.tableData = tableData;
			state.visibleSettings = visibleSet;
		},
		changeEndingChildsVisible: (state, { payload }) => {
			const visibleSetCopy = { ...state.visibleSettings, mock: false };
			const { tableData, visibleSet } = ChangeEndingChildsVisible({ tableData: state.tableData, value: payload, visibleSet: visibleSetCopy });
			state.tableData = tableData;
			state.visibleSettings = visibleSet;
		},
		changeItemChildsVisible: (state, { payload }) => {
			const visibleSetCopy = { ...state.visibleSettings, mock: false };
			const { tableData, visibleSet } = ChangeItemChildsVisible({ tableData: state.tableData, value: payload, visibleSet: visibleSetCopy });
			state.tableData = tableData;
			state.visibleSettings = visibleSet;
		},
		showHideItemsNameVisible: (state) => {
			const { tableData } = ShowHideItemsNameVisible({ tableData: state.tableData, oneLineLen: 65 });
			state.tableData = tableData;
		},
		editTableData: (state, { payload }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = changeRowDataRecourse(state.tableData, payload.key, (row) => {
				Object.keys(payload).forEach((key) => {
					row[key] = payload[key];
				});
				return row;
			});
		},
		changeLocEstmVisible: (state, { payload: { val, row } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = searchDataRecourse(state.tableData, row["key"], val);
		},
		setVisibleSettings: (state, { payload }) => {
			state.visibleSettings = payload;
		},
		setEditorMode: (state, { payload }) => {
			state.editorMode = payload;
		},
		setClickedRowBeforeNav: (state, { payload }) => {
			state.clickedRowBeforeNav = payload;
		},
		setPrevCell: (state, { payload }) => {
			state.prevCell = payload;
		},
		setEditorCellisActive: (state, { payload }) => {
			state.editorCellisActive = payload;
		},
		setArrowMoveisActive: (state, { payload }) => {
			state.arrowMoveisActive = payload;
		},
		setInputAutocompleteSettings: (state, { payload }) => {
			state.inputAutocompleteSettings = { ...state.inputAutocompleteSettings, ...payload };
		},
		increaseFiltredDataAutocompleteSetting: (state) => {
			const filtredDataCopy = [...state.inputAutocompleteSettings.filtredData];
			let seekIndex = -1;
			const rowHovered = filtredDataCopy.find((x) => x.hovered === true);
			if (!rowHovered && filtredDataCopy.length > 0) {
				filtredDataCopy[0].hovered = true;
			} else {
				for (let i = 0; i < filtredDataCopy.length; i++) {
					if (filtredDataCopy[i].hovered) {
						filtredDataCopy[i].hovered = false;
						seekIndex = i + 1;
					} else if (i === seekIndex) {
						filtredDataCopy[i].hovered = true;
						state.inputAutocompleteSettings.activateInnerScroll++;
						state.inputAutocompleteSettings.disableInputEnter = true;
						break;
					}
				}
			}
			state.inputAutocompleteSettings = { ...state.inputAutocompleteSettings, filtredData: filtredDataCopy };
		},
		decreaseFiltredDataAutocompleteSetting: (state) => {
			const filtredDataCopy = [...state.inputAutocompleteSettings.filtredData];
			let seekIndex = -1;
			for (let i = filtredDataCopy.length - 1; i >= 0; i--) {
				if (filtredDataCopy[i].hovered) {
					filtredDataCopy[i].hovered = false;
					seekIndex = i - 1;
				} else if (i === seekIndex) {
					filtredDataCopy[i].hovered = true;
					state.inputAutocompleteSettings.activateInnerScroll++;
					state.inputAutocompleteSettings.disableInputEnter = true;
					break;
				}
			}
			state.inputAutocompleteSettings = { ...state.inputAutocompleteSettings, filtredData: filtredDataCopy };
		},
		setItemBuffer: (state, { payload }) => {
			state.itemBuffer = payload;
		},
		setSelectedItems: (state, { payload }) => {
			state.selectedItems = payload;
		},
		setItemsMap: (state, { payload }) => {
			state.itemsMap = payload;
		},
		setRowsMap: (state, { payload }) => {
			state.rowsMap = payload;
		},
		setItemIdAddAfter: (state, { payload }) => {
			state.ItemIdAddAfter = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getOrCreateCacheJson.fulfilled, (state, { payload }) => {
			state.data = payload["data"];
			state.tableData = payload["table_data"];
			state.rowsMap = payload["rows_map"];
			state.itemsMap = payload["items_map"];
			state.visibleSettings = payload["visible_settings"];
			state.fetchStatus = "success";
		});

		builder.addCase(getFsnbAutocompete.fulfilled, (state, { payload }) => {
			state.inputAutocompleteSettings = { ...state.inputAutocompleteSettings, filtredData: payload };
		});

		builder.addMatcher(isAnyOf(getOrCreateCacheJson.pending), (state, action) => {
			state.fetchStatus = "loading";
		});

		builder.addMatcher(isAnyOf(getOrCreateCacheJson.rejected), (state, action) => {
			state.fetchStatus = "failed";
			if (action?.payload?.error_text) {
				state.fetchStatusText = action.payload?.error_text;
			} else {
				state.fetchStatusText = action.payload?.detail;
			}
		});
	},
});

export const {
	setCurrentInputAction,
	setCancelActions,
	setTableData,
	setData,
	setFetchStatus,
	setFetchStatusText,
	changeRowData,
	changeSectionChildsVisible,
	editTableData,
	changeLocEstmVisible,
	increaseCounter,
	setTechGroupTableData,
	changeItemChildsVisible,
	showHideItemsNameVisible,
	setVisibleSettings,
	changeEndingChildsVisible,
	setEditorMode,
	setClickedRowBeforeNav,
	setPrevCell,
	selectItemRowWithChilds,
	setEditorCellisActive,
	setFetchRibbonStatus,
	setArrowMoveisActive,
	setInputAutocompleteSettings,
	increaseFiltredDataAutocompleteSetting,
	decreaseFiltredDataAutocompleteSetting,
	setItemBuffer,
	setSelectedItems,
	setRowsMap,
	setItemsMap,
	setItemIdAddAfter,
} = localEstimateEditor.actions;

export default localEstimateEditor.reducer;

import "./LabelVerticalHierarchy.css";

const LabelVerticalHierarchy = ({ objArr, rowHeight, leftPadding, callback }) => {
	const jsxs = [];

	let loopIndex = 0;

	const getSubClass = ({ loopIndex }) => {
		if (loopIndex === 0) {
			return "HRFirst";
		} else if (loopIndex === objArr.length - 1) {
			return "HRLast";
		} else {
			return "HRComon";
		}
	};

	for (let obj of objArr) {
		const jsx = (
			<div className="LabelVerticalHierarchyRow" key={obj.id} style={{ height: `${rowHeight}px` }}>
				<span
					className={`LabelVerticalHierarchyHr ${getSubClass({ loopIndex })}`}
					style={{ minWidth: `${leftPadding * loopIndex}px` }}
				></span>
				<span className="LabelVerticalHierarchyName">{obj.name}</span>
			</div>
		);

		jsxs.push(jsx);
		loopIndex += 1;
	}

	return (
		<div
			className="LabelVerticalHierarchy"
			onClick={() => {
				if (callback) callback();
			}}
		>
			{jsxs}
		</div>
	);
};

export default LabelVerticalHierarchy;

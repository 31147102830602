import React from 'react';

import "./KsprSplitTextButton.css";

export default function KsprSplitTextButton(props) {
    return (
        <div className="KsprSplitTextButton">
            <div className={props.isLeftValid ? "Link Link__disable" : "Link"} 
                onClick={() => props.leftCallback()}>
                {props.leftText}
            </div> |
            <div className={props.isRightValid ? "Link Link__disable" : "Link"} 
                onClick={() => props.rightCallback()}>
                {props.rightText}
            </div>
        </div>
    )
}


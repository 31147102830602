import { useState, useEffect } from "react";
import { useCellEditing } from "./useCellEditing";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import "./CellEditingSelect.css";

const CellEditingSelect = ({ rowObj, style, clmName, colSpan, props, clmObj }) => {
	const { handleEdit } = useCellEditing({ rowObj: rowObj, funcs: props.func, options: {} });

	const selectOptions = props.rowOptions.selecting[clmObj.selecting];

	const emptyObj = { id: 0, name: "" };

	let defaultValue = null;
	if (rowObj.hasOwnProperty(clmName)) {
		if (rowObj[clmName] !== null && rowObj[clmName] !== "") {
			defaultValue = selectOptions.find((row) => row.id === rowObj[clmName]);
		}
	}
	const [selectedValue, setSelectedValue] = useState(defaultValue || emptyObj);
	const [isSelectValueChange, setIsSelectValueChange] = useState(false);

	const handleChange = (val) => {
		const itm = selectOptions.find((x) => x["name"] === val);
		if (itm != null) {
			setSelectedValue(itm);
		} else {
			setIsSelectValueChange(true);
		}
	};

	useEffect(() => {
		if (selectedValue !== null && selectedValue?.id !== 0 && selectedValue?.id !== defaultValue?.id) {
			handleEdit(clmName, selectedValue.id, selectedValue.name);
		} else if (isSelectValueChange && clmName !== "north_reg_terr_id") {
			handleEdit(clmName, "empty", "0");
		}
	}, [selectedValue, isSelectValueChange]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<td className="TableCellOnSelect" clm={clmName} style={style} colSpan={colSpan}>
			<Autocomplete
				className="cellEditingAutocomplete"
				clearText="Очистить"
				closeText="Закрыть"
				openText="Открыть"
				value={selectedValue}
				getOptionLabel={(option) => option.name}
				onInputChange={(e, val) => {
					handleChange(val);
				}}
				size="small"
				noOptionsText="нет данных"
				id="combo-box-demo"
				options={selectOptions}
				sx={{
					height: "20px",
					paddingLeft: "4px",
					"& .MuiButtonBase-root.MuiAutocomplete-clearIndicator svg": {
						fontSize: "16px",
					},
				}}
				renderInput={(params) => <TextField {...params} variant="standard" />}
			/>
		</td>
	);
};

export { CellEditingSelect };

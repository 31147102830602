const TableDefaultOptions = {
	rowHeight: 22,
	rowsDisplayCount: 75,
	scrollHeightControll: 100,
	displayScrollDelay: 1,
	addH: 0,
	selectable: true,
	singleClickOptions: {
		useTargetParentNode: true,
		useStringInclude: true,
	},
	useEditIcon: false,
	resizableColumns: false,
	displayOptions: {
		rowHeight: 22,
		rowsDisplayCount: 75,
		scrollHeightControll: 100,
		displayScrollDelay: 1,
		addH: 0,
	},
};

const getTableDefaultOptions = () => {
	return { ...TableDefaultOptions };
};

export { TableDefaultOptions, getTableDefaultOptions };

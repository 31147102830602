export default function NormNrspConnectedColumns() {
    return [
        {
            name: 'code',
            title: 'Шифр',
            width: '200px',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'name',
            title: 'Наименование',
            width: 'auto',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'measure_unit',
            title: 'Ед. изм',
            width: '150px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        }
    ];
}
import { useEffect } from "react";
import { useSelector } from "react-redux";

import ContextMenuGroup from "./ContextMenuGroup";

import "./ContextMenu.css";

const ContextMenu = ({ items, isVisible, position, onClose }) => {
    const availableYMax = useSelector((state) => state.windowDimensions.windowHeight);

    useEffect(() => {
        const handleDocumentClick = (e) => {
            if (!isVisible) return;
            const contextMenu = document.querySelector(".context-menu");
            if (contextMenu && !contextMenu.contains(e.target)) onClose();
        };
        document.addEventListener("click", handleDocumentClick);
        return () => document.removeEventListener("click", handleDocumentClick);
    }, [isVisible, onClose]);

    const style = {
        display: isVisible ? "block" : "none",
        top: position.top,
        left: position.left,
    };

    if (!items || items?.length === 0) return;

    const rowHeight = 22;

    let itemsLength = 0;
    for (let item of items) {
        if (item.subgroups) {
            for (let subgroup of item.subgroups) {
                if (subgroup.items) {
                    itemsLength += subgroup.items?.length;
                }
            }
        }
    }

    const calcedYMax = style.top + rowHeight * itemsLength;

    if (calcedYMax > availableYMax) {
        style.top = style.top - rowHeight * itemsLength;
    }

    return (
        <div style={style} className="context-menu">
            {items && items.map((group, index) => <ContextMenuGroup key={index} {...group} />)}
        </div>
    );
};

export default ContextMenu;

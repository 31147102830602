import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";

import apiUserAdv from "api/user_adv";

export const getUserSettings = createAsyncThunk("userAdvSlice/getUserSettings", async ({ userId }) => {
	const response = await apiUserAdv.getUserSettings({ userId });
	return response.data;
});

export const getUserTasks = createAsyncThunk("userAdvSlice/getUserTasks", async () => {
	const response = await apiUserAdv.getUserTasks();
	return response.data;
});

export const getUserTasksAndRead = createAsyncThunk("userAdvSlice/getUserTasksAndRead", async () => {
	const response = await apiUserAdv.getUserTasksAndRead();
	return response.data;
});

export const userAdvSlice = createSlice({
	name: "userAdvSlice",
	initialState: {
		settings: {},
		userTasks: [],
		userTasksVisible: false,
		unreadTasks: 0,
		userSettingsChangedCount: 0,
	},
	reducers: {
		setUserSettings: (state, { payload }) => {
			state.settings = { ...state.settings, ...payload };
		},
		setUserTasks: (state, { payload }) => {
			state.userTasks = payload;
		},
		setUserTasksVisible: (state, { payload }) => {
			state.userTasksVisible = payload;
		},
		setParamUserSettings: (state, { payload }) => {
			const { param, key, value } = payload;
			if (key) {
				state.settings[param][key] = value;
			} else {
				state.settings[param] = value;
			}
			state.userSettingsChangedCount += 1;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(getUserSettings.fulfilled), (state, { payload }) => {
			state.settings = { ...state.settings, ...payload };
		});
		builder.addMatcher(isAnyOf(getUserTasks.fulfilled), (state, { payload }) => {
			state.userTasks = payload;
			state.unreadTasks = payload?.filter((x) => x?.is_viewed === false)?.length;
		});
		builder.addMatcher(isAnyOf(getUserTasksAndRead.fulfilled), (state, { payload }) => {
			state.userTasks = payload;
			state.unreadTasks = 0;
		});

		builder.addMatcher(isAnyOf(getUserSettings.pending, getUserTasks.pending, getUserTasksAndRead.pending), (state, action) => {});

		builder.addMatcher(isAnyOf(getUserSettings.rejected, getUserTasks.rejected, getUserTasksAndRead.rejected), (state, { payload }) => {
			console.error(payload);
		});
	},
});

export const { setUserSettings, setUserTasks, setUserTasksVisible, setParamUserSettings } = userAdvSlice.actions;

export default userAdvSlice.reducer;

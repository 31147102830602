import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Divider, TextField, Radio, RadioGroup, FormLabel, FormControl, FormControlLabel, Checkbox, FormGroup } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { MonitorDetailsAutocomplete } from "./UI/MonitorDetailsAutocomplete.jsx";
import { MonitorDetailsTextField } from "./UI/MonitorDetailsTextField.jsx";
import { RecommendedIndexButton } from "./components";
import MonitorDetailButtonWrapper from "./MonitorDetailButtonWrapper";

import { updateEqpIndexFromDb, updateIndexFromDb, updatePgIndexFromDb, updateSmrIndexFromDb } from "./MonitorParamsFetcher.js";

import { setRequestBody, setLastClickedRow } from "slices/monitor.js";
import apiProjects from "api/projects";
import apiCalcer from "api/calcer.js";
import { calcExecOptions, fsnbExecOptions, fsnbCheckAreaEnum, calcModeEnum, calcModeOptions } from "./const.js";

import { copyArr } from "core/iterable_utils.js";
import { getEnvVal } from "core/env";

import "./MonitorTableDetails.css";

export default function MonitorTableDetails(props) {
	const dispatch = useDispatch();
	const { row, height } = props;

	const userPermissions = useSelector((state) => state.auth.permissions);
	const lastClickedRow = useSelector((state) => state.monitor.lastClickedRow);
	const foundRecommendedIndex = useSelector((state) => state.monitor.foundRecommendedIndex);

	const [useMehIndForPg, setUseMehIndForPg] = useState(Boolean(row.use_meh_ind_for_pg));
	const [mehIndForPgLabel, setMehIndForPgLabel] = useState("Использовать индексы МЕХ для перевозки");

	const [calcMode, setCalcMode] = useState(calcModeEnum.CHECK_CALC);
	const [curPerData, setCurPerData] = useState(copyArr(props.perData));
	const [detailParams, setDetailParams] = useState({
		index_use: "",
		fsnb_vers: "",
		pz_vers: "",
		obj_vers: "",
		ind_vers: "",
		per_vers: "",
		ind_org_vers: "",
		estm_methodic_vers: "",
		ot_index: "",
		mech_index: "",
		mat_index: "",
		eqp_index: "",
		smr_index: "",
		pg_bort_index: "",
		pg_sam_index: "",
		pg_pan_index: "",
		pg_trub_index: "",
		pg_bet_index: "",
	});

	const getInputIndexKeys = () => {
		const fsnb = ["fsnb_vers"];
		const epz = ["ot_index", "mech_index", "mat_index"];
		const smr = ["smr_index"];
		const other = ["eqp_index", "pg_bort_index", "pg_sam_index", "pg_pan_index", "pg_trub_index", "pg_bet_index"];
		if (row.estm_indextype !== "Индексы к СМР") {
			return [...fsnb, ...epz, ...other];
		} else {
			return [...fsnb, ...smr, ...other];
		}
	};

	const inputIndexKeys = useRef(getInputIndexKeys());

	const setInputBordersDefault = () => {
		return inputIndexKeys.current.reduce((acc, key) => {
			return { ...acc, [key]: "" };
		}, {});
	};

	const [inputBorders, setInputBorders] = useState(setInputBordersDefault());

	const setDetailParamsDecorator = (updatedValues, fieldName) => {
		setDetailParams((prevParams) => ({ ...prevParams, ...updatedValues }));

		if (updatedValues[fieldName] !== "") {
			setInputBorders((prevBorders) => ({
				...prevBorders,
				[fieldName]: "",
			}));
		}
	};

	const getMonitorData = (id) => {
		apiProjects
			.getLsrMonitorData(id)
			.then((response) => {
				const updateValues = {
					...response.data,
					fsnb_vers: response.data.fsnb_vers.value,
					pz_vers: response.data.pz_vers.value,
					obj_vers: response.data.obj_vers.value,
					ind_vers: response.data.ind_vers.value,
					per_vers: response.data.per_vers.value,
					ind_org_vers: response.data.ind_org_vers.value,
					estm_methodic_vers: response.data.estm_methodic_vers.value,
				};
				setDetailParamsDecorator(updateValues);
			})
			.catch((error) => {
				console.error("Error fetching monitor data:", error);
			});
	};

	const handleChangCalcMode = (event) => setCalcMode(event.value);

	useEffect(() => {
		if (height === 0) return;
		if (row.id === lastClickedRow.id) {
			setDetailParams({ ...detailParams, ...foundRecommendedIndex, ...{ index_use: "handle" } });
		} else {
			getMonitorData(row.id);
		}
	}, [height, foundRecommendedIndex]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		return () => dispatch(setLastClickedRow({}));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/*eslint-disable */
	useEffect(() => {
		if (detailParams.index_use === "db") {
			if (!useMehIndForPg) updatePgIndexFromDb(detailParams, setDetailParamsDecorator);
			updateEqpIndexFromDb(detailParams, setDetailParamsDecorator);
			updateSmrIndexFromDb(detailParams, setDetailParamsDecorator);
			updateIndexFromDb(detailParams, setDetailParamsDecorator);
			setInputBorders(setInputBordersDefault());
		}
	}, [detailParams.pz_vers, detailParams.ind_vers, detailParams.per_vers, detailParams.obj_vers, detailParams.index_use]);

	useEffect(() => {
		if (detailParams.pz_vers && row.methodic === "rim") {
			setCurPerData(copyArr(props.perData));
			const prom = apiCalcer.getPerPzRimDateStart({ pz: detailParams.pz_vers });
			prom.then((res) => {
				setCurPerData((prev) =>
					prev.map((row) => {
						for (let r of res.data) {
							if (row.value === r.period_id) row.label = `${row.label} (Действует с ${r.date_start})`;
						}
						return row;
					})
				);
			}).catch((err) => {
				console.error("err", err);
			});
		}
	}, [detailParams.pz_vers]);
	/*eslint-enable */

	useEffect(() => {
		if (useMehIndForPg) {
			let mehIndex = detailParams.mech_index;
			if (row.estm_indextype === "Индексы к СМР") {
				mehIndex = detailParams.smr_index;
			}
			setDetailParamsDecorator({
				pg_bort_index: mehIndex,
				pg_sam_index: mehIndex,
				pg_pan_index: mehIndex,
				pg_trub_index: mehIndex,
				pg_bet_index: mehIndex,
			});
		} else {
			updatePgIndexFromDb(detailParams, setDetailParamsDecorator);
		}
	}, [useMehIndForPg, detailParams.mech_index, detailParams.smr_index]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (row.methodic === "rim") return;
		if (row.estm_indextype === "Индексы к СМР") {
			setMehIndForPgLabel("Использовать индексы СМР для перевозки");
		} else {
			setMehIndForPgLabel("Использовать индексы МЕХ для перевозки");
		}
	}, [row]); // eslint-disable-line react-hooks/exhaustive-deps

	const getRequestBody = (arg, typeBtn) => {
		if (!setAndCheckFsnbVersBorders()) {
			return;
		}

		if (typeBtn === "btnCalc") {
			if (!setAndCheckInputBorders()) return;
		}

		const body = {
			...detailParams,
			id: row.id,
			indx_for_empty: false,
			fsnb_for_empty: false,
			fsnb_action: true,
			calc_action: true,
			estm_indextype: row.estm_indextype,
			use_meh_ind_for_pg: useMehIndForPg,
			checkedEstmIds: [],
		};

		if (calcMode === calcModeEnum.RECALC_TC) {
			body.save_relaced = true;
			body.use_ot_coef = false;
		} else if (calcMode === calcModeEnum.RECALC_TC_WITH_OT) {
			body.save_relaced = true;
			body.use_ot_coef = true;
		}

		if (typeBtn === "btnFsnb") {
			body.fsnb_action = true;
			body.calc_action = false;
		} else if (typeBtn === "btnCalc") {
			body.fsnb_action = true;
			body.calc_action = true;
		}

		if (arg === fsnbCheckAreaEnum.CURRENT_ESTM) {
			body.indx_for_empty = false;
		} else if (arg === fsnbCheckAreaEnum.SELECTED_ESTMS) {
			body.indx_for_empty = false;
		} else if (arg === fsnbCheckAreaEnum.ESTMS_WITHOUT_INDEX) {
			body.indx_for_empty = true;
		}

		dispatch(setRequestBody({ body, arg }));
	};

	const setAndCheckFsnbVersBorders = () => {
		const updatedBorders = {};
		if (!detailParams.fsnb_vers) updatedBorders.fsnb_vers = "red";
		setInputBorders(updatedBorders);
		return Object.keys(updatedBorders).some((k) => updatedBorders[k] === "red") ? false : true;
	};

	const setAndCheckInputBorders = () => {
		const updatedBorders = {};
		if (row.methodic === "rim") {
			for (let field of ["pz_vers", "per_vers"]) {
				if (!detailParams[field] || detailParams[field] === 0) {
					updatedBorders[field] = "red";
				}
			}
		} else if (row.methodic === "bim") {
			for (let field of inputIndexKeys.current) {
				if (!detailParams[field]) {
					// "Пусконаладочные работы"
					if (detailParams?.obj_vers === 5 && String(field).startsWith("pg_")) {
						continue;
					} else if (row?.has_equipment_resource === false && field === "eqp_index") {
						continue;
					} else {
						updatedBorders[field] = "red";
					}
				}
				if (detailParams[field] === "-") {
					if (field !== "mech_index" && field !== "mat_index") {
						updatedBorders[field] = "red";
					}
				}
			}
		}
		setInputBorders(updatedBorders);
		return Object.keys(updatedBorders).some((k) => updatedBorders[k] === "red") ? false : true;
	};

	const getLabelFromValue = (dataArray, valueToFind) => {
		const foundItem = dataArray.find((item) => item["value"] === valueToFind);
		return foundItem ? foundItem.label : null;
	};

	const getHanleIndexDisableStatus = () => {
		return detailParams.index_use !== "handle" || !userPermissions.is_expert;
	};

	const getDbIndexDisableStatus = () => {
		return (detailParams.index_use !== "db" && row.methodic !== "rim") || !userPermissions.is_expert;
	};

	const getIndexCompletnessStyle = (key) => {
		return {
			"& fieldset.MuiOutlinedInput-notchedOutline": {
				borderColor: `${inputBorders[key]}`,
			},
			"& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
				borderColor: `${inputBorders[key]}`,
			},
			"& legend": { fontSize: "9px" },
		};
	};
	if (height === 0) {
		return (
			<div
				className="MonitorTableDetails"
				style={{
					maxHeight: "0px",
					height: "0px",
				}}
			></div>
		);
	}

	const handleInputClick = (fieldName) => {
		setInputBorders((prevBorders) => ({
			...prevBorders,
			[fieldName]: "",
		}));
	};

	return (
		<div
			className="MonitorTableDetails"
			methodic={row.methodic}
			style={{
				maxHeight: height + "px",
				height: height + "px",
				borderTopWidth: "1px",
			}}
		>
			<div className="MonDetLeftBox" methodic={row.methodic}>
				<Divider>
					<div className="MonDetLabel">РЕДАКЦИЯ ФСНБ</div>
				</Divider>

				<TextField
					title={row["legal"]}
					id="standard-multiline-static"
					label="Данные в исходном файле"
					multiline
					rows={row.methodic === "bim" ? 3 : 1}
					defaultValue={row["legal"]}
					variant="standard"
					InputProps={{
						readOnly: true,
					}}
				/>
				<div className="MonDetFlexWrapperLeft">
					<div
						className={row.methodic === "bim" ? "MonDetFlex MonDetFlexBim MonDetFlexToBottom" : "MonDetFlex  MonDetFlexToBottom"}
						type="fsnb"
					>
						<MonitorDetailsAutocomplete
							sx={getIndexCompletnessStyle("fsnb_vers")}
							disablePortal={true}
							options={props.fsnbData}
							props={props}
							row={row}
							width={200}
							value={getLabelFromValue(props.fsnbData, detailParams.fsnb_vers)}
							onChange={(e, val) => setDetailParamsDecorator({ fsnb_vers: val.value })}
							label="Редакция ФСНБ"
						/>
					</div>
					<div className="MonDetFlex" type="estm_methodic">
						<MonitorDetailsAutocomplete
							sx={getIndexCompletnessStyle()}
							disablePortal={true}
							disableClearable
							options={props.estmMethodicData}
							props={props}
							row={row}
							width={200}
							value={getLabelFromValue(props.estmMethodicData, detailParams.estm_methodic_vers)}
							onChange={(e, val) => setDetailParamsDecorator({ estm_methodic_vers: val.value })}
							label="Редакция методики"
						/>
					</div>
					<MonitorDetailButtonWrapper
						options={fsnbExecOptions}
						typeBtn={"btnFsnb"}
						ksprBtnTitle="Запустить"
						f_setModalOpen={props.f_setModalOpen}
						f_setRequestBody={getRequestBody}
						f_setAndCheckInputBorders={setAndCheckInputBorders}
					/>
				</div>
			</div>
			<Divider orientation="vertical" variant="middle" flexItem />
			<div className="MonDetRightBox" methodic={row.methodic}>
				<div style={{ display: "flex" }}>
					<Divider>
						<div className="MonDetLabel">ИНДЕКСЫ ИЗМЕНЕНИЯ СТОИМОСТИ</div>
					</Divider>
					<div className="CloseDetails">
						<CloseIcon onClick={(e) => props.dispatch(props.f_close_details(row["key"]))} />
					</div>
				</div>

				<div className="IndexString">
					<TextField
						id="standard-helperText"
						title={row["index_string"]}
						label="Данные в исходном файле"
						defaultValue={row["index_string"]}
						variant="standard"
						disabled={!userPermissions.is_expert}
						InputProps={{
							readOnly: true,
						}}
					/>
				</div>
				<div className="MonDetFlexWrapper">
					<div className="MonDetFlex" type="pz">
						<MonitorDetailsAutocomplete
							sx={getIndexCompletnessStyle("pz_vers")}
							disabled={getDbIndexDisableStatus()}
							options={props.pzData}
							value={getLabelFromValue(props.pzData, detailParams.pz_vers)}
							onChange={(e, val) => setDetailParamsDecorator({ pz_vers: val.value })}
							label="Регион (ценовая зона)"
						/>
					</div>

					{row.methodic === "bim" && (
						<div className="MonDetFlex" type="obj">
							<MonitorDetailsAutocomplete
								sx={getIndexCompletnessStyle()}
								disabled={getDbIndexDisableStatus()}
								options={props.objData}
								value={getLabelFromValue(props.objData, detailParams.obj_vers)}
								onChange={(e, val) => setDetailParamsDecorator({ obj_vers: val.value })}
								label="Объект строительства"
							/>
						</div>
					)}
					{row.methodic === "bim" && (
						<div className="MonDetFlex" type="eqp">
							<MonitorDetailsAutocomplete
								sx={getIndexCompletnessStyle()}
								disabled={getDbIndexDisableStatus()}
								options={props.indData}
								value={getLabelFromValue(props.indData, detailParams.ind_vers)}
								onChange={(e, val) => setDetailParamsDecorator({ ind_vers: val.value })}
								label="Отрасль народного хозяйства"
							/>
						</div>
					)}
					<div className="MonDetFlex" type="per">
						<MonitorDetailsAutocomplete
							sx={getIndexCompletnessStyle("per_vers")}
							disabled={getDbIndexDisableStatus()}
							options={curPerData}
							value={getLabelFromValue(curPerData, detailParams.per_vers)}
							onChange={(e, val) => setDetailParamsDecorator({ per_vers: val.value })}
							label="Период"
						/>
					</div>
					{row.methodic === "rim" && (
						<div className="MonDetFlex" type="ind_org">
							<MonitorDetailsAutocomplete
								sx={getIndexCompletnessStyle()}
								disabled={getDbIndexDisableStatus()}
								options={props.indOrgData}
								value={getLabelFromValue(props.indOrgData, detailParams.ind_org_vers)}
								onChange={(e, val) => {
									if (val) {
										setDetailParamsDecorator({ ind_org_vers: val.value });
									} else {
										setDetailParamsDecorator({ ind_org_vers: 0 });
									}
								}}
								label="Отраслевая организация"
							/>
						</div>
					)}
					{row.methodic === "rim" && (
						<>
							{getEnvVal("App") === "sipcs" && (
								<div className="MonDetFlex MonDetFlexSipcs" type="action">
									<MonitorDetailsAutocomplete
										disabled={!userPermissions.is_expert}
										options={calcModeOptions}
										defaultValue={calcModeOptions[0]}
										onChange={(e, val) => handleChangCalcMode(val)}
										label="Выберите действие"
										disableClearable={true}
										onKeyPress={(e) => e.preventDefault()}
									/>
								</div>
							)}

							<MonitorDetailButtonWrapper
								disabled={!userPermissions.is_expert}
								options={calcExecOptions}
								typeBtn="btnCalc"
								ksprBtnTitle={getEnvVal("App") === "sipcs" ? "Выполнить" : "Запустить"}
								noMarginLeft={getEnvVal("App") === "sipcs"}
								f_setModalOpen={props.f_setModalOpen}
								f_setRequestBody={getRequestBody}
								f_setAndCheckInputBorders={setAndCheckInputBorders}
							/>
						</>
					)}
				</div>

				<div className="MonDetFlexIntWrapper">
					<div className="MonDetInputWrappersFlex">
						{row.methodic === "bim" && (
							<div className="MonDetInputWrapper">
								{row.estm_indextype !== "Индексы к СМР" ? (
									<>
										<MonitorDetailsTextField
											sx={getIndexCompletnessStyle("ot_index")}
											disabled={getHanleIndexDisableStatus()}
											label="ФОТ"
											value={detailParams.ot_index}
											onClick={() => handleInputClick("ot_index")}
											onChange={(e) => {
												setDetailParamsDecorator({ ot_index: e.target.value }, "ot_index");
											}}
										/>
										<MonitorDetailsTextField
											sx={getIndexCompletnessStyle("mech_index")}
											disabled={getHanleIndexDisableStatus()}
											label="МЕХ"
											value={detailParams.mech_index}
											onClick={() => handleInputClick("mech_index")}
											onChange={(e) => setDetailParamsDecorator({ mech_index: e.target.value }, "mech_index")}
										/>
										<MonitorDetailsTextField
											sx={getIndexCompletnessStyle("mat_index")}
											disabled={getHanleIndexDisableStatus()}
											label="МАТ"
											value={detailParams.mat_index}
											onClick={() => handleInputClick("mat_index")}
											onChange={(e) => setDetailParamsDecorator({ mat_index: e.target.value }, "mat_index")}
										/>
									</>
								) : (
									<MonitorDetailsTextField
										sx={getIndexCompletnessStyle("smr_index")}
										disabled={getHanleIndexDisableStatus()}
										label="Индекс СМР / ПНР"
										value={detailParams.smr_index}
										onChange={(e) => setDetailParamsDecorator({ smr_index: e.target.value })}
									/>
								)}
								<MonitorDetailsTextField
									sx={getIndexCompletnessStyle("eqp_index")}
									disabled={getHanleIndexDisableStatus()}
									label="Оборуд."
									value={detailParams.eqp_index}
									onClick={() => handleInputClick("eqp_index")}
									onChange={(e) => setDetailParamsDecorator({ eqp_index: e.target.value })}
								/>
								<div className="PgIndBox">
									<div className="PgIndLebel" is_disable={`${!userPermissions.is_expert}`}>
										Индексы на перевозку:
									</div>
								</div>
								<MonitorDetailsTextField
									sx={getIndexCompletnessStyle("pg_bort_index")}
									disabled={getHanleIndexDisableStatus()}
									label="А/м бортовые"
									value={detailParams.pg_bort_index}
									onClick={() => handleInputClick("pg_bort_index")}
									onChange={(e) => setDetailParamsDecorator({ pg_bort_index: e.target.value })}
								/>
								<MonitorDetailsTextField
									sx={getIndexCompletnessStyle("pg_sam_index")}
									disabled={getHanleIndexDisableStatus()}
									label="Самосвалы"
									value={detailParams.pg_sam_index}
									onClick={() => handleInputClick("pg_sam_index")}
									onChange={(e) => setDetailParamsDecorator({ pg_sam_index: e.target.value })}
								/>
								<MonitorDetailsTextField
									sx={getIndexCompletnessStyle("pg_pan_index")}
									disabled={getHanleIndexDisableStatus()}
									label="Панелевозы"
									value={detailParams.pg_pan_index}
									onClick={() => handleInputClick("pg_pan_index")}
									onChange={(e) => setDetailParamsDecorator({ pg_pan_index: e.target.value })}
								/>
								<MonitorDetailsTextField
									sx={getIndexCompletnessStyle("pg_trub_index")}
									disabled={getHanleIndexDisableStatus()}
									label="Трубоплетевозы"
									value={detailParams.pg_trub_index}
									onClick={() => handleInputClick("pg_trub_index")}
									onChange={(e) => setDetailParamsDecorator({ pg_trub_index: e.target.value })}
								/>
								<MonitorDetailsTextField
									sx={getIndexCompletnessStyle("pg_bet_index")}
									disabled={getHanleIndexDisableStatus()}
									label="Автобетоносмесители"
									value={detailParams.pg_bet_index}
									onClick={() => handleInputClick("pg_bet_index")}
									onChange={(e) => setDetailParamsDecorator({ pg_bet_index: e.target.value })}
								/>
							</div>
						)}

						<div className="MonDetInputWrapper">
							{row.methodic === "bim" && (
								<div className="MonDetRadioWrapper">
									<FormLabel id="demo-row-radio-buttons-group-label" className="RatioLabel">
										При расчете использовать значения индексов
									</FormLabel>
									<FormControl>
										<RadioGroup row>
											<FormControlLabel
												checked={detailParams.index_use === "db"}
												disabled={!userPermissions.is_expert}
												control={
													<Radio
														size="small"
														disabled={!userPermissions.is_expert}
														onChange={() => setDetailParamsDecorator({ index_use: "db" })}
													/>
												}
												label="Из справочника"
											/>
											<FormControlLabel
												checked={detailParams.index_use === "handle"}
												disabled={!userPermissions.is_expert}
												control={
													<Radio
														size="small"
														disabled={!userPermissions.is_expert}
														onChange={() => setDetailParamsDecorator({ index_use: "handle" })}
													/>
												}
												label="Введенные пользователем"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							)}
							{row.methodic === "bim" && (
								<div className="UseMehIndexFotPgBim">
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox size="small" checked={useMehIndForPg} onChange={() => setUseMehIndForPg((prev) => !prev)} />
											}
											label={mehIndForPgLabel}
										/>
									</FormGroup>
								</div>
							)}
							{row.methodic === "bim" && (
								<RecommendedIndexButton
									params={[detailParams.pz_vers, detailParams.obj_vers, detailParams.ind_vers, detailParams.fsnb_vers]}
									row={row}
									f_setModalRecIndexVisible={props.f_setModalRecIndexVisible}
								/>
							)}
							{row.methodic === "bim" && (
								<MonitorDetailButtonWrapper
									disabled={!userPermissions.is_expert}
									options={calcExecOptions}
									typeBtn="btnCalc"
									ksprBtnTitle="Запустить"
									f_setModalOpen={props.f_setModalOpen}
									f_setRequestBody={getRequestBody}
									f_setAndCheckInputBorders={setAndCheckInputBorders}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

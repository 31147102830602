import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import apiLocalEstimateEditor from "api/local_estimate_editor";
import {
	setTableData,
	setCancelActions,
	increaseCounter,
	changeRowData,
	setTechGroupTableData,
	setVisibleSettings,
	setData,
	selectItemRowWithChilds,
	setEditorCellisActive,
	setInputAutocompleteSettings,
	setItemBuffer,
	setRowsMap,
	setItemsMap,
	setItemIdAddAfter,
} from "slices/local_estimate_editor";
import { setClickedTableRow, setClickedTableClmName } from "slices/ribbon";
import { getNestedIterableElementsCountWithBreak } from "core/iterable_utils";
import { genConstValue } from "pages/EstimateEditor/const";

const useItemEditor = ({ estmId, _setFetchStatus, _setFetchStatusText, scrollBlock, setConfirmContent }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const setFetchStatus = (data) => dispatch(_setFetchStatus(data));
	const setFetchStatusText = (data) => dispatch(_setFetchStatusText(data));
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const tableData = useSelector((state) => state.localEstimateEditor.tableData);
	const itemBuffer = useSelector((state) => state.localEstimateEditor.itemBuffer);

	const getScrollBlock = () => {
		if (scrollBlock.current) {
			return scrollBlock.current;
		} else {
			return document.querySelector("#EstimateEditorTable");
		}
	};

	const scrollIntoItemPos = ({ tableCopy, newItem, rowsBeforeCnt }) => {
		const scrollElmIndex = getNestedIterableElementsCountWithBreak(
			tableCopy,
			(row) => {
				return row.visible !== false || row.show_always === true;
			},
			(row) => {
				return row.key === newItem.key;
			},
			(row) => {
				return row.childs_visible !== false || row.model_name === "CostParentLabel";
			}
		);
		const scrollPosition = genConstValue("rowHeight") * scrollElmIndex - genConstValue("rowHeight") * rowsBeforeCnt;
		const scrollBlock = getScrollBlock();

		if (scrollBlock) {
			scrollBlock.scrollTo({
				top: scrollPosition,
				// behavior: "smooth",
			});
		}

		return { scrollElmIndex };
	};

	const enableAddItemMode = ({ afterSelected }) => {
		if (getInputingItem()) return;
		const clickedSectionId = clickedTableRow?.section_id;
		let indexOfClickedSection = 0;
		if (!clickedSectionId) {
			indexOfClickedSection = tableData.map((x) => x.id).indexOf(0) - 1;
			if (indexOfClickedSection === -2) indexOfClickedSection = 0;
		} else {
			indexOfClickedSection = tableData.map((x) => x.id).indexOf(clickedSectionId);
		}
		const sectionsBefore = [...tableData.slice(0, indexOfClickedSection)];
		const sectionsClicked = tableData[indexOfClickedSection];
		const sectionsAfter = [...tableData.slice(indexOfClickedSection + 1, tableData.length)];
		const sectionEndingIndex = sectionsClicked._children.map((x) => x.model_name).indexOf("LocalEstimateRimSecSumDirect") - 1;

		let newSection = {};
		const newItem = genItemEmptyRow({ section_id: sectionsClicked.id });

		if (sectionEndingIndex === -1) {
			newSection = { ...sectionsClicked, _children: [newItem, ...sectionsClicked._children] };
		} else {
			const sectionsClickedItems = [...sectionsClicked._children.slice(0, sectionEndingIndex + 1)];
			const sectionsClickedEnding = [...sectionsClicked._children.slice(sectionEndingIndex + 1, sectionsClicked._children.length)];
			let newSectionsClickedChildren;

			let indexOfClickedItem = -1;
			if (afterSelected === true && sectionsClickedItems) {
				const clickedItemId = clickedTableRow?.item_id;

				if (!clickedItemId) {
					indexOfClickedItem = sectionsClickedItems.map((x) => x.id).indexOf(0) - 1;
					if (indexOfClickedItem === -2) indexOfClickedItem = 0;
				} else {
					indexOfClickedItem = sectionsClickedItems
						.map((x) => `${x.model_name}${x.item_id}`)
						.indexOf(`LocalEstimateRimItemSum${clickedItemId}`);
				}
			}

			if (indexOfClickedItem === -1) {
				newSectionsClickedChildren = [...sectionsClickedItems, newItem, ...sectionsClickedEnding];
			} else {
				const itemsBefore = [...sectionsClickedItems.slice(0, indexOfClickedItem)];
				const itemsClicked = sectionsClickedItems[indexOfClickedItem];
				const itemsAfter = [...sectionsClickedItems.slice(indexOfClickedItem + 1, sectionsClickedItems.length)];
				newSectionsClickedChildren = [...itemsBefore, itemsClicked, newItem, ...itemsAfter, ...sectionsClickedEnding];
				dispatch(setItemIdAddAfter(clickedTableRow?.item_id));
			}

			newSection = { ...sectionsClicked, _children: newSectionsClickedChildren };
		}

		const tableCopy = [...sectionsBefore, newSection, ...sectionsAfter];

		dispatch(setTableData(tableCopy));
		const { scrollElmIndex } = scrollIntoItemPos({ tableCopy, newItem, rowsBeforeCnt: 4 });

		// console.log("scrollElmIndex", scrollElmIndex)
		dispatch(
			setInputAutocompleteSettings({
				visible: true,
				positionTop: (scrollElmIndex + 1) * genConstValue("rowHeight") - getScrollBlock().scrollTop,
			})
		);
	};

	const removeNewRow = () => {
		const tableCopy = tableData
			.map((sec) => {
				let secCopy = sec;
				if (sec?._children?.find((item) => item.is_new_row === true)) {
					secCopy = { ...sec };
					secCopy._children = secCopy._children.filter((item) => item.is_new_row !== true);
				}
				return secCopy;
			})
			.filter((sec) => sec.is_new_input_section !== true);
		dispatch(setTableData(tableCopy));
	};

	const addItemByCode = ({ sectionId, requestCode, thenNavigate, thenAlert, thenScroll, ItemIdAddAfter }) => {
		if (requestCode === "") {
			dispatch(
				setTableData(
					tableData.map((section) => {
						const sectionCopy = { ...section };
						if (sectionCopy._children) {
							sectionCopy._children = sectionCopy._children.filter((item) => item.is_new_input_item !== true);
						}
						return sectionCopy;
					})
				)
			);
			return;
		}
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.addItemByCode({ estmId, sectionId, ItemIdAddAfter, requestCode });
		prom.then((response) => {
			const newItem = response.data.new_item;
			const newTableData = selectItemOnItemId({
				tableData: response.data.estm_data.table_data,
				newItem: newItem,
				sectionId: sectionId,
				rowChanges: [
					{ key: "inline_cell", value: "consumption" },
					{ key: "consumption", value: "" },
				],
			});
			dispatch(setEditorCellisActive(true));
			dispatch(setData(response.data.estm_data.data));
			dispatch(setTableData(newTableData));
			dispatch(setClickedTableClmName("consumption"));
			dispatch(setCancelActions(response.data.actions_data));
			dispatch(setVisibleSettings(response.data.visible_settings));
			dispatch(setRowsMap(response.data.estm_data.rows_map));
			dispatch(setItemsMap(response.data.estm_data.items_map));
			if (thenNavigate?.path) navigate(thenNavigate.path);
			if (thenAlert) {
				setFetchStatus("successAlert");
				setFetchStatusText(`Добавлен. Позиция: ${newItem.position_num}. Раздел: ${newItem.section_name}`);
				setTimeout(() => {
					setFetchStatus("success");
					setFetchStatusText("");
				}, 3000);
			} else {
				setFetchStatus("success");
			}

			let timeOut = 10;
			if (thenScroll) timeOut = 500;

			setTimeout(() => {
				highlightItem({
					newItem,
					sectionId,
					newTableData,
					itemIdCallback: (row) => {
						row.inline_cell = "consumption";
						row.consumption = "";
						return row;
					},
				});
			}, timeOut);
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
		});
	};

	const editItem = ({ sectionId, itemId, key, value, objType, resId, otherParams }) => {
		let valueChange = value;
		let keyChange = key;
		if (otherParams?.targetValue) valueChange = otherParams.targetValue;
		if (otherParams?.targetKey) keyChange = otherParams.targetKey;

		dispatch(
			changeRowData({
				rowKey: clickedTableRow.key,
				callback: (row) => (row[keyChange] = valueChange),
			})
		);
		if (["consumption", "priceperunitcur"].includes(key)) {
			if (value === "") value = 0;
		}
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.editItem({
			estmId,
			sectionId,
			itemId,
			key,
			value,
			objType,
			resId,
			otherParams,
		});
		prom.then((response) => {
			const newTableData = selectItemOnItemId({
				tableData: response.data.estm_data.table_data,
				newItem: response.data.new_item,
				sectionId: sectionId,
			});
			dispatch(setData(response.data.estm_data.data));
			dispatch(setTableData(newTableData));
			dispatch(setCancelActions(response.data.actions_data));
			dispatch(setVisibleSettings(response.data.visible_settings));
			dispatch(setEditorCellisActive(false));
			dispatch(setRowsMap(response.data.estm_data.rows_map));
			dispatch(setItemsMap(response.data.estm_data.items_map));
			setFetchStatus("success");
		}).catch((err) => {
			console.error(err);
			if (err?.response?.data?.error_text) setFetchStatusText(err?.response?.data?.error_text);
			setFetchStatus("failed");
		});
	};

	const editItemValArr = ({ sectionId, itemId, keyValArr, objType, resId }) => {
		dispatch(
			changeRowData({
				rowKey: clickedTableRow.key,
				callback: (row) => {
					for (let obj in keyValArr) {
						row[obj.key] = obj.value;
					}
				},
			})
		);

		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.editItemValArr({ estmId, sectionId, itemId, keyValArr, objType, resId });
		prom.then((response) => {
			const newItem = response.data.new_item;
			const newTableData = selectItemOnItemId({
				tableData: response.data.estm_data.table_data,
				newItem: newItem,
			});
			dispatch(setData(response.data.estm_data.data));
			dispatch(setTableData(newTableData));
			dispatch(setCancelActions(response.data.actions_data));
			dispatch(setVisibleSettings(response.data.visible_settings));
			dispatch(setEditorCellisActive(false));
			dispatch(setRowsMap(response.data.estm_data.rows_map));
			dispatch(setItemsMap(response.data.estm_data.items_map));
			setFetchStatus("success");
			// setTimeout(() => {
			//     highlightItem({ newItem, sectionId, newTableData });
			// }, 100)
		}).catch((err) => {
			console.error(err);
			if (err?.response?.data?.error_text) setFetchStatusText(err?.response?.data?.error_text);
			setFetchStatus("failed");
		});
	};

	const deleteItem = ({ sectionId, itemId }) => {
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.deleteItem({ estmId, sectionId, itemId });
		prom.then((response) => {
			console.log(response);
			dispatch(setData(response.data.estm_data.data));
			dispatch(setTableData(response.data.estm_data.table_data));
			dispatch(setCancelActions(response.data.actions_data));
			dispatch(setVisibleSettings(response.data.visible_settings));
			dispatch(setEditorCellisActive(false));
			dispatch(setRowsMap(response.data.estm_data.rows_map));
			dispatch(setItemsMap(response.data.estm_data.items_map));
			setFetchStatus("success");
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
		});
	};

	const showDeleteItemConfirm = ({ item }) => {
		setConfirmContent({
			label: "Удаление",
			text: `Удалить выбранную позицию (${item.num} ${item.code}) ?`,
			labelOk: "Удалить",
			callback: () => {
				deleteItem({ sectionId: item.section_id, itemId: item.id });
				dispatch(setClickedTableRow(null));
			},
		});
	};

	const moveItem = ({ sectionId, itemId, direction }) => {
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.moveItem({ estmId, sectionId, itemId, direction });
		prom.then((response) => {
			const newTableData = selectItemOnItemId({
				tableData: response.data.estm_data.table_data,
				newItem: response.data.new_item,
				sectionId: sectionId,
			});
			dispatch(setData(response.data.estm_data.data));
			dispatch(setTableData(newTableData));
			dispatch(setCancelActions(response.data.actions_data));
			dispatch(setVisibleSettings(response.data.visible_settings));
			dispatch(setEditorCellisActive(false));
			dispatch(setRowsMap(response.data.estm_data.rows_map));
			dispatch(setItemsMap(response.data.estm_data.items_map));
			setFetchStatus("success");
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
		});
	};

	const getTechGroup = ({ sectionId, itemId, code, name }) => {
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.getTechGroup({ estmId, sectionId, itemId, code, name });
		prom.then((response) => {
			console.log(response);
			dispatch(setTechGroupTableData(response.data));
			setFetchStatus("success");
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
		});
	};

	const getNormCoefsForItem = ({ sectionId, itemId }) => {
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.getNormCoefsForItem({ estmId, sectionId, itemId });
		prom.then((response) => {
			console.log(response);
			dispatch(setTechGroupTableData(response.data));
			setFetchStatus("success");
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
		});
	};

	const replaceAbsResource = ({ sectionId, itemId, resId, newResCode, thenNavigate, thenAlert, thenScroll }) => {
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.replaceAbsResource({ estmId, sectionId, itemId, resId, newResCode });
		prom.then((response) => {
			const newItem = response.data.new_item;

			const newTableData = selectItemOnItemId({
				tableData: response.data.estm_data.table_data,
				newItem: newItem,
				sectionId: sectionId,
				rowChanges: [
					{ key: "inline_cell", value: "consumption" },
					{ key: "consumption", value: "" },
				],
			});
			dispatch(setClickedTableClmName("consumption"));
			dispatch(setData(response.data.estm_data.data));
			dispatch(setTableData(newTableData));
			dispatch(setCancelActions(response.data.actions_data));
			dispatch(setVisibleSettings(response.data.visible_settings));
			dispatch(setEditorCellisActive(false));
			dispatch(setRowsMap(response.data.estm_data.rows_map));
			dispatch(setItemsMap(response.data.estm_data.items_map));
			if (thenNavigate?.path) navigate(thenNavigate.path);
			if (thenAlert) {
				setFetchStatus("successAlert");
				setFetchStatusText(
					`Уточнен ресурс: ${newItem.abs_res_code}. Позиция: ${newItem.position_num}. Раздел: ${newItem.section_name}. Смета: ${newItem.estm_name}.`
				);
				setTimeout(() => {
					setFetchStatus("success");
					setFetchStatusText("");
				}, 1400);
			} else {
				setFetchStatus("success");
			}
			if (thenScroll) {
				setTimeout(() => {
					highlightItem({ newItem, sectionId, newTableData });
				}, 500);
			}
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
		});
	};

	const insertEmptyHandleRes = ({ model_name }) => {
		const item = getItemOnItemId({
			tableData,
			itemId: clickedTableRow.item_id,
			sectionId: clickedTableRow.section_id,
		});
		const emptyHandleAbsKey = `${clickedTableRow.key}-emptyHandleAbs`;
		const emptyHandleAbs = {
			key: emptyHandleAbsKey,
			model_name: model_name,
			selected: true,
			inline_cell: "code",
			parent_res_id: clickedTableRow.id,
			item_id: clickedTableRow.item_id,
			section_id: clickedTableRow.section_id,
		};
		dispatch(
			changeRowData({
				rowKey: item.key,
				callback: (row) => {
					const fotPos = row?._children?.map((x) => x.model_name).indexOf("ItemCostFot");
					if (fotPos > 0) {
						row._children = [...row?._children.slice(0, fotPos), emptyHandleAbs, ...row?._children.slice(fotPos, 10)];
					}
					return row;
				},
			})
		);
		dispatch(setClickedTableRow(emptyHandleAbs));
		dispatch(setClickedTableClmName("code"));
	};

	const highlightItem = ({ newItem, sectionId, newTableData, itemIdCallback }) => {
		dispatch(
			selectItemRowWithChilds({
				itemId: newItem.item_id,
				sectionId: sectionId,
				color: "#f5f5f575",
				isAnimated: true,
				itemIdCallback: itemIdCallback,
			})
		);
		dispatch(setClickedTableRow(newItem));
		scrollIntoItemPos({ tableCopy: newTableData, newItem, rowsBeforeCnt: 4 });
	};

	const genItemEmptyRow = ({ section_id }) => {
		const newItem = {
			id: null,
			// key: `section_${clickedTableRow?.section_id}|item_${editorCounter}--localestimatesitems-0`,
			key: `section_${clickedTableRow?.section_id}|item_0--localestimatesitems-0`,
			name: "",
			code: "",
			colSpan: 1,
			model_name: "LocalEstimatesItems",
			colSpanTarget: "name",
			inline_cell: "code",
			is_new_row: true,
			selected: false,
			section_id: section_id,
			is_new_input_item: true,
		};

		dispatch(increaseCounter());
		if (clickedTableRow) {
			dispatch(
				changeRowData({
					rowKey: clickedTableRow.key,
					callback: (row) => (row.selected = false),
				})
			);
		}
		dispatch(setClickedTableRow(newItem));
		dispatch(setClickedTableClmName("code"));

		return newItem;
	};

	const showHideItemChildren = (val) => {
		dispatch(
			changeRowData({
				rowKey: clickedTableRow.key,
				callback: (row) => {
					row.children_visible = val;
					if (row._children) {
						row?._children.map((ch) => {
							ch.visible = val;
							return ch;
						});
					}
					return row;
				},
			})
		);
	};

	const showHideName = () => {
		dispatch(
			changeRowData({
				rowKey: clickedTableRow.key,
				callback: (row) => {
					const nameLen = String(row?.name).length;
					if (row.rowHeightMultiply == null || row.rowHeightMultiply === 1) {
						row.rowHeightMultiply = 2;
						if (nameLen > 150) row.rowHeightMultiply = 3;
					} else {
						row.rowHeightMultiply = null;
					}
					return row;
				},
			})
		);
	};

	const selectItemOnItemId = ({ tableData, newItem, rowChanges, sectionId }) => {
		let isObjFound = false;

		for (let sec of tableData) {
			if (sec.id !== sectionId) continue;
			if (sec._children) {
				for (let i = 0; i < sec._children.length; i++) {
					if (sec._children[i].item_id === newItem.item_id) {
						if (!newItem.res_id) {
							const copyItem = { ...sec._children[i] };
							if (rowChanges) {
								for (let ch of rowChanges) copyItem[ch.key] = ch.value;
							}
							dispatch(setClickedTableRow(copyItem));
							sec._children[i] = copyItem;
							isObjFound = true;
							break;
						} else {
							if (sec._children[i]._children) {
								for (let j = 0; j < sec._children[i]._children.length; j++) {
									let res = sec._children[i]._children[j];
									if (res.id === newItem.res_id) {
										const copyRes = { ...res };
										if (rowChanges) {
											for (let ch of rowChanges) copyRes[ch.key] = ch.value;
										}
										dispatch(setClickedTableRow(copyRes));
										sec._children[i]._children[j] = copyRes;
										isObjFound = true;
										break;
									}
								}
							}

							if (!isObjFound) {
								const copyItem = { ...sec._children[i] };
								if (rowChanges) {
									for (let ch of rowChanges) copyItem[ch.key] = ch.value;
								}
								dispatch(setClickedTableRow(copyItem));
								sec._children[i] = copyItem;
							}
							break;
						}
					}
				}
			}
		}
		return tableData;
	};

	const getItemOnItemId = ({ tableData, itemId, sectionId }) => {
		for (let sec of tableData) {
			if (sec.id !== sectionId) continue;
			if (sec._children) {
				for (let i = 0; i < sec._children.length; i++) {
					if (sec._children[i].item_id === itemId) {
						return sec._children[i];
					}
				}
			}
		}
	};

	const getInputingItem = () => {
		for (let sec of tableData) {
			if (sec._children) {
				for (let i = 0; i < sec._children.length; i++) {
					if (sec._children[i].is_new_row) {
						return sec._children[i];
					}
				}
			}
		}
	};

	const copyItemToBuffer = ({ action }) => {
		const item = getItemOnItemId({
			tableData,
			itemId: clickedTableRow.item_id,
			sectionId: clickedTableRow.section_id,
		});
		dispatch(setItemBuffer({ ...item, action: action }));
	};

	const copyItemFromBufferToEstm = ({ position }) => {
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.copyItem({
			estmId,
			sectionId: clickedTableRow.section_id,
			itemId: clickedTableRow.item_id,
			body: {
				copied_item_id: itemBuffer.item_id,
				copied_section_id: itemBuffer.section_id,
				relative_position: position,
				action: itemBuffer.action,
			},
		});
		prom.then((response) => {
			const newItem = response.data.new_item;
			const newTableData = selectItemOnItemId({
				tableData: response.data.estm_data.table_data,
				newItem: newItem,
				sectionId: newItem.section_id,
			});
			dispatch(setData(response.data.estm_data.data));
			dispatch(setTableData(newTableData));
			dispatch(setCancelActions(response.data.actions_data));
			dispatch(setVisibleSettings(response.data.visible_settings));
			dispatch(setRowsMap(response.data.estm_data.rows_map));
			dispatch(setItemsMap(response.data.estm_data.items_map));
			setFetchStatus("success");
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
		});
	};

	const copyItemResFromBufferToItemCostEstm = () => {
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.copyItemResToItemCost({
			estmId,
			sectionId: clickedTableRow.section_id,
			itemId: clickedTableRow.item_id,
			body: {
				copied_res_id: itemBuffer.id,
				copied_item_id: itemBuffer.item_id,
				copied_section_id: itemBuffer.section_id,
				action: itemBuffer.action,
			},
		});
		prom.then((response) => {
			const newItem = response.data.new_item;
			const newTableData = selectItemOnItemId({
				tableData: response.data.estm_data.table_data,
				newItem: newItem,
				sectionId: newItem.section_id,
			});
			dispatch(setData(response.data.estm_data.data));
			dispatch(setTableData(newTableData));
			dispatch(setCancelActions(response.data.actions_data));
			dispatch(setVisibleSettings(response.data.visible_settings));
			dispatch(setRowsMap(response.data.estm_data.rows_map));
			dispatch(setItemsMap(response.data.estm_data.items_map));
			setFetchStatus("success");
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
		});
	};

	return {
		enableAddItemMode,
		addItemByCode,
		editItem,
		showDeleteItemConfirm,
		deleteItem,
		moveItem,
		editItemValArr,
		getTechGroup,
		replaceAbsResource,
		// addItemDepResource,
		getNormCoefsForItem,
		showHideItemChildren,
		showHideName,
		getItemOnItemId,
		copyItemToBuffer,
		copyItemFromBufferToEstm,
		copyItemResFromBufferToItemCostEstm,
		insertEmptyHandleRes,
		removeNewRow,
	};
};

export { useItemEditor };

import React from 'react';
import ContextMenuElementWrapper from './ContextMenuElementWrapper.jsx'; // Путь к вашему ContextMenuElementWrapper

const ContextMenuSubgroup = ({ name, items }) => {
    return (
        <div>
            {items.map((elementSettings, index) => {
                if (elementSettings.isVisible === false) {
                    return <span key={index}></span>;
                } else {
                    return <ContextMenuElementWrapper key={index} elementSettings={elementSettings} />
                }
            }
            )}
        </div>
    );
};

export default ContextMenuSubgroup;
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { setUserSetAllowErPerc } from "slices/protocol";

import { FilterErrorStatus, FilterGroupSelect } from "pages/EstimateViewer/ProtocolViewer/components";

const ProtocolRibbonFilter = ({
	selectTypeData,
	gprVisible,
	saveFiltredDataGrp,
	proj_main,
	selectedErrorStatus,
	setSelectedErrorStatus,
	allowErPercVal,
	setAllowErPercVal,
	dispatch,
	userPermissions,
}) => {
	const handleAllowedPerc = (e) => {
		const val = e.target.value;
		if (val < 100) {
			if (parseInt(val) === 0 || val === "" || val > 0.001) {
				if (val.toString().length < 6) {
					setAllowErPercVal(val);
				}
			}
		}
	};

	const saveUserAllowErPerc = () => {
		const body = {
			user_id: localStorage.getItem("userId"),
			project_id: proj_main?.id,
			val: allowErPercVal / 100,
		};
		dispatch(setUserSetAllowErPerc(body));
	};

	const handleFocus = (event) => {
		event.target.select();
	};

	return (
		<>
			<FilterGroupSelect selectTypeData={selectTypeData} saveFiltredDataGrp={saveFiltredDataGrp} dispatch={dispatch} />
			<div className="CustomElRibbonWrapper">
				<FilterErrorStatus
					selectedErrorStatus={selectedErrorStatus}
					setSelectedErrorStatus={setSelectedErrorStatus}
					projectId={proj_main?.id}
				/>
				{userPermissions?.is_expert === true ? (
					<div className="TableOuterHeadMoveRow">
						<TextField
							type="number"
							inputMode="numeric"
							size="small"
							label="Погр, %"
							sx={{
								width: 80,
								"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
									WebkitAppearance: "none",
									margin: 0,
								},
								'& input[type="number"]': {
									MozAppearance: "textfield",
								},
							}}
							InputProps={{
								startAdornment: <InputAdornment position="start">%</InputAdornment>,
							}}
							value={allowErPercVal}
							onChange={(e) => handleAllowedPerc(e)}
							onFocus={handleFocus}
							//    onClick={(e) => e.target.select()}
							onKeyDown={(e) => {
								if (e.key === "Enter") saveUserAllowErPerc();
							}}
						></TextField>
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export { ProtocolRibbonFilter };

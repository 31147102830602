import { createSlice } from "@reduxjs/toolkit";

export const confirmSlice = createSlice({
    name: "app",
    initialState: {
        label: "",
        labelCancel: "",
        labelOk: "",
        text: "",
        visible: false,
        callback: false,
    },
    reducers: {
        setConfirmOptions: (state, { payload }) => {
            state.label = payload.label;
            state.labelCancel = payload.labelCancel;
            state.labelOk = payload.labelOk;
            state.text = payload.text;
            state.visible = payload.visible;
            state.callback = payload.callback;
        },
        closeConfirm: (state, action) => {
            state.visible = false;
        },
        setAnswer: (state, { payload }) => {
            state.answer = payload;
        },
        setConfirmCallback: (state, { payload }) => {
            state.callback = payload;
        },
    },
});

export const { setConfirmOptions, closeConfirm, setAnswer, setConfirmCallback } = confirmSlice.actions;
export default confirmSlice.reducer;

import { setPanelFoundRowPositions } from "slices/ribbon";

import { searchNestedIterableForValueAndOpen } from "core/iterable_utils";

const RibbonPaneSearchInputFactory = ({ currentPage, arg, tableData, updateTableData, scrollBlock, dispatch, panelSearchPrevKey }) => {
	if (!arg || String(arg).length < 2) {
		dispatch(setPanelFoundRowPositions([]));
		return;
	}

	let curTableData;
	let setTableData;
	let callback = (row) => {
		let res = [{ status: false, key: null }];
		for (const key in row) {
			if (
				[
					"id",
					"colSpan",
					"row_type",
					"model_name",
					"section_id",
					"key",
					"state_text",
					"error_value_size",
					"hideColumns",
					"colSpanTarget",
					"childs_visible",
					"visible",
					"advSelected",
					"_details",
					"is_item",
					"check",
					"has_cost",
					"_children",
					"protectable",
					"summarytype",
					"cost_has_coef",
					"errors_visible",
					"tree_key",
					"target_key",
					"item_id",
					"fsnb_errors",
				].includes(key)
			)
				continue;
			const inputValueFix = String(arg).toLowerCase().replaceAll(" ", "").replaceAll(",", ".");
			const rowValFix = String(row[key]).toLowerCase().replaceAll(" ", "").replaceAll(",", ".");

			if (row[key] && rowValFix.includes(inputValueFix)) {
				if (res.length === 1 && res[0].status === false) {
					res = [];
				}
				res.push({ status: true, key: key });
			}
		}
		return res;
	};

	if (currentPage === "LocalEstimateViewer") {
		curTableData = tableData.local;
		setTableData = updateTableData.local;
	} else if (currentPage === "SummaryEstimateViewer") {
		curTableData = tableData.summary;
		setTableData = updateTableData.summary;
	} else if (currentPage === "ObjectEstimateViewer") {
		curTableData = tableData.object;
		setTableData = updateTableData.object;
	} else if (currentPage === "KatsEstimateViewer") {
		curTableData = tableData.kats;
		setTableData = updateTableData.kats;
	} else if (currentPage === "KatsProjectEstimateViewer") {
		curTableData = tableData.kats;
		setTableData = updateTableData.kats;
	} else if (currentPage === "StartEstimateViewer") {
	} else if (currentPage === "ProtocolViewer") {
		curTableData = tableData.protocol;
		setTableData = updateTableData.protocol;
	} else if (currentPage === "KatsEditor") {
		curTableData = tableData.katsEditor;
		setTableData = updateTableData.katsEditor;
	}

	if (!curTableData) return;
	if (!setTableData) return;

	const { iterableCopy, foundPositions } = searchNestedIterableForValueAndOpen(curTableData, arg, callback, true);

	dispatch(setPanelFoundRowPositions(foundPositions));
	dispatch(setTableData(iterableCopy));
};

export default RibbonPaneSearchInputFactory;

import React, { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import apiCalcer from '../../api/calcer';
import TableMain from '../../components/Table/TableMain';
import AlertLoading from "../../components/AlertLoading";
import IndexesEqpTableColumns from './IndexesEqpTableColumns';

import "./IndexesEqp.css";
import "./IndexesAutocomplete.css";
import "./IndexesTable.css";
import RibbonContent from "components/Ribbon/RibbonContent/RibbonContent";
import IndexesEqpRibbonSettings from "./IndexesEqpRibbonSettings";


export default function IndexesEqp() {
    const [fetchStatus, setFetchStatus] = useState(null);
    const [fetchStatusText, setFetchStatusText] = useState("");

    const [objTypes, setObjTypes] = useState([]);
    const [periods, setPeriods] = useState([]);

    const [selectedObjType, setSelectedObjType] = useState({});
    const [selectedPeriod, setSelectedPeriod] = useState({});

    const [defaultPeriod, setDefaultPeriod] = useState({});

    const [rowsData, setRowsData] = useState({});
    const columnsData = IndexesEqpTableColumns();

    useEffect(() => {
        setFetchStatus("loading");
        const objPromise = new Promise((resolve, reject) => {
            apiCalcer.getIndustryTypes()
                .then(response => { resolve(response.data); })
                .catch(error => { reject(error); });
        });

        const perPromise = new Promise((resolve, reject) => {
            apiCalcer.getPeriods()
                .then(response => { resolve(response.data); })
                .catch(error => { reject(error); });
        });

        Promise.all([objPromise, perPromise]).then(response_arrs => {
            setObjTypes(response_arrs[0]);
            setPeriods(response_arrs[1]);
            setFetchStatus("success");
            return response_arrs;
        }).then(data => {
            if (localStorage.getItem("indexPeriodId") != null) {
                const perVal = data[1].find(x => String(x["id"]) === localStorage.getItem("indexPeriodId"));
                setDefaultPeriod(perVal);
            } else {
                setDefaultPeriod(data[1][0]);
            }
        }).catch(error => {
            console.error(error);
            setFetchStatus("failed");
            if (error?.response?.data?.detail != null) {
                setFetchStatusText(error.response.data.detail);
            } else {
                setFetchStatusText(error.message);
            }
        });
    }, [])


    useEffect(() => {
        setFetchStatus("loading");
        if (selectedPeriod.id != null) {
            let apiPromise;
            if (selectedObjType.id == null) {
                apiPromise = apiCalcer.getEqpIndexDefault(selectedPeriod.id);
            } else {
                apiPromise = apiCalcer.getEqpIndex(selectedPeriod.id, selectedObjType.id)
            }
            apiPromise.then(response => {
                setRowsData({ "data": response.data.map(x => ({ ...x, key: x.id })) });
                setFetchStatus("success");
            }).catch(error => {
                setFetchStatus("failed");
                setFetchStatusText(error.message);
            });
        }
    }, [selectedObjType, selectedPeriod])

    if (fetchStatus === "failed") {
        return <AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} />
    }

    if (objTypes.length === 0 || periods.length === 0) {
        return
    }

    const periodPriceZoneAutocomplete = () => {
        return <div className="indexesAutocompleteWrapper">

            <Autocomplete
                sx={{
                    width: '100%',
                    "& legend": {
                        fontSize: "9px"
                    }
                }}
                options={periods}
                defaultValue={defaultPeriod}
                disableClearable
                getOptionLabel={(option) => option.name}
                size="small"
                noOptionsText="нет данных"
                onInputChange={(e, val) => {
                    const inpVal = periods.find(x => x["name"] === val);
                    if (inpVal != null) { setSelectedPeriod(inpVal) }
                }}
                onClose={(e) => {
                    const itm = periods.find(x => x["name"] === e.target.innerText);
                    if (itm != null) { localStorage.setItem("indexPeriodId", itm.id) };
                }}
                renderInput={(params) => <TextField {...params}
                    InputLabelProps={{ shrink: true, }} label="Период" />}
            />
        </div>
    }

    const objectAutocomplete = () => {
        return <Autocomplete
            sx={{
                width: '100%',
                "& legend": {
                    fontSize: "9px"
                }
            }}
            options={objTypes}
            getOptionLabel={(option) => option.name}
            size="small"
            noOptionsText="нет данных"
            onInputChange={(e, val) => {
                const optVal = objTypes.find(x => x["name"] === val);
                optVal == null ? setSelectedObjType({}) : setSelectedObjType(optVal)
            }}
            renderInput={(params) => <TextField {...params} label="Отрасль народного хозяйства" />}
        />
    }

    return (

        <>
            <RibbonContent ribbonSettings={IndexesEqpRibbonSettings(periodPriceZoneAutocomplete, objectAutocomplete)} />
            <div className="IndexesDirectMain" >
                <div className="IndexesFilters">
                    {/* <Autocomplete
                        sx={{ width: 500 }}
                        options={objTypes}
                        getOptionLabel={(option) => option.name}
                        size="small"
                        noOptionsText="нет данных"
                        onInputChange={(e, val) => {
                            const optVal = objTypes.find(x => x["name"] === val);
                            optVal == null ? setSelectedObjType({}) : setSelectedObjType(optVal)
                        }}
                        renderInput={(params) => <TextField {...params} label="Отрасль народного хозяйства" />}
                    /> */}
                    {/* <Autocomplete
                        sx={{ width: 150 }}
                        options={periods}
                        defaultValue={defaultPeriod}
                        disableClearable
                        getOptionLabel={(option) => option.name}
                        size="small"
                        noOptionsText="нет данных"
                        onInputChange={(e, val) => {
                            const inpVal = periods.find(x => x["name"] === val);
                            if (inpVal != null) { setSelectedPeriod(inpVal) }
                        }}
                        onClose={(e) => {
                            const itm = periods.find(x => x["name"] === e.target.innerText);
                            if (itm != null) { localStorage.setItem("indexPeriodId", itm.id) };
                        }}
                        renderInput={(params) => <TextField {...params}
                            InputLabelProps={{ shrink: true, }} label="Период" />}
                    /> */}
                </div>
                <div className="IndexesTable">
                    <TableMain
                        rows={rowsData}
                        columns={columnsData}
                        options={{
                            "selectable": false,
                        }}
                        func={{}}
                    ></TableMain>
                </div>
                <AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} />
            </div >
        </>


    )
}
import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { changeStepsStatus } from "slices/import_files";
import { setAllowRequestBody } from "slices/monitor";
import { forceFetchProjectTree } from "slices/project_work";
import { setUserTasksVisible, getUserTasksAndRead } from "slices/user_adv";
import apiMonitor from "api/monitor";

import MonitorTableRow from "./MonitorTableRow";

import "./Monitor.css";

const Monitor = ({ setRibbonStatus }) => {
	const dispatch = useDispatch();
	const monitorData = useSelector((state) => state.importFiles.monitorData);
	const requestBody = useSelector((state) => state.monitor.requestBody);
	const allowRequestBody = useSelector((state) => state.monitor.allowRequestBody);

	const { id } = useParams();

	useEffect(() => {
		if (allowRequestBody) {
			execMonitorHttp();
			dispatch(setAllowRequestBody(false));
		}
	}, [allowRequestBody]); // eslint-disable-line react-hooks/exhaustive-deps

	const execMonitorHttp = () => {
		let body = requestBody;
		setRibbonStatus("loading");
		const getInWorkRow = (id) => {
			return {
				id_val: id,
				id_key: "id",
				key_vals: [
					{ key: "in_work", val: true },
					{ key: "step_1_status", val: null },
					{ key: "step_2_status", val: null },
					{ key: "step_3_status", val: null },
				],
			};
		};

		for (const estmID of [body.id, ...body.checkedEstmIds]) {
			dispatch(changeStepsStatus(getInWorkRow(estmID)));
		}

		const prom = apiMonitor.startMonitorAction(body);
		prom.then((response) => {
			setRibbonStatus("success");
			for (let estm of response.data) {
				if (estm["status"] === true) {
					if (estm["actions"] === "change_step_status") {
						dispatch(changeStepsStatus(estm));
					}
				}
			}
			setRibbonStatus("success");
			dispatch(forceFetchProjectTree(id));
			if (body?.checkedEstmIds && body?.checkedEstmIds?.length) {
				dispatch(getUserTasksAndRead());
				dispatch(setUserTasksVisible(true));
			}
		}).catch((er) => {
			console.error(er);
			// dispatch(setMonFetchStatus("failed"));
			// setLocalFetchStatusText(er);
		});
	};

	const stepsLsr = ["Проверка соответствия ФСНБ", "Проверка сметных расчетов", "Перекрестные проверки данных"];
	const stepsKats = ["Проверка комплектности данных", "Проверка достоверности данных", "Перекрестные проверки данных"];

	const ssrList = [];

	for (let ssr of monitorData) {
		const osrList = [];
		if (ssr._children) {
			for (let osr of ssr._children) {
				if (osr.visible === false) continue;

				if (osr.type === "object") {
					osrList.push(<MonitorTableRow row={osr} steps={stepsLsr} type="Osr" />);
				} else if (osr.type === "kats-child") {
					osrList.push(<MonitorTableRow row={osr} steps={stepsKats} type="Kats-Child" />);
				}

				const lsrList = [];
				if (osr._children) {
					for (let lsr of osr._children) {
						if (lsr.visible === false) continue;
						lsrList.push(<MonitorTableRow row={lsr} steps={stepsLsr} type="Lsr" />);
					}
				}
				const lsrListWrapper = (
					<div className="LsrWrapper">
						{lsrList.map((item, index) => (
							<div className="MrowWrapper" key={"m_row_lwrapp" + index}>
								{item}
							</div>
						))}
					</div>
				);
				osrList.push(lsrListWrapper);
			}
		}
		ssrList.push(<MonitorTableRow row={ssr} steps={stepsLsr} type="Ssr" />);

		const osrListWrapper = (
			<div className="OsrWrapper">
				{osrList.map((item, index) => (
					<div className="MrowWrapper" key={"m_row_owrapp" + index}>
						{item}
					</div>
				))}
			</div>
		);
		ssrList.push(osrListWrapper);
	}

	return (
		<div className="MonitorTableMain">
			{ssrList.map((item, index) => (
				<div className="Mrow" key={"m_row_" + index}>
					{item}
				</div>
			))}
		</div>
	);
};

export default Monitor;

const getBodyWidthByClassName = ({ className, displayOptions }) => {
	if (className === "KsrTree") {
		if (!displayOptions.tree) {
			return "0%";
		} else if (displayOptions.tree && displayOptions.table) {
			return "30%";
		} else if (displayOptions.tree && !displayOptions.table) {
			return "100%";
		}
	} else if (className === "KsrTable") {
		if (!displayOptions.table) {
			return "0%";
		} else if (displayOptions.tree && displayOptions.table) {
			return "70%";
		} else if (!displayOptions.tree && displayOptions.table) {
			return "100%";
		}
	}
};

export { getBodyWidthByClassName };

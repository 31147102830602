import Wait from "imgs/Wait.png";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import "./WaitingDialog.css";

export default function WaitingDialog(props) {
    return (
        <Dialog
            className="WaitingDialog"
            sx={{ "& .MuiDialog-paper": { width: "30vw", maxWidth: "30vw", height: "23vh", maxHeight: "23vh" } }}
            open
            slotProps={{ backdrop: { style: { backgroundColor: "transparent" } } }}
            onClick={() => {
                props.clickCallback();
            }}>
            <DialogTitle className="WaitingDialogTitle">
                <div>{props.title}</div>
            </DialogTitle>
            <DialogContent className="WaitingDialogModalContent">
                <img height={173} width={300} src={Wait} alt="waiting"></img>
            </DialogContent>
        </Dialog>
    );
}

import React from 'react';


export function CellSelect({ clm, row, opts }) {
    if (!checkIsConditions(clm, row, opts)) { return null }

    const select_dom = [];
    if (opts[clm.selecting] != null) {

        for (let opt of opts[clm?.selecting]) {
            const opt_key = `opt-${opt["id"]}`;
            const opt_dom = (
                <option key={opt_key} value={opt["id"]}>{opt["name"]}</option>
            )
            select_dom.push(opt_dom);
        }
    }

    return select_dom;
}


export function getDefaultTextForSelect(clm, row, opts) {
    if (opts[clm.selecting] != null) {
        for (let opt of opts[clm.selecting]) {
            if (String(opt.id) === String(row[clm.name])) {
                return opt.name
            }
        }
    }
    return ""
}

export function checkIsConditions(clm, row, opts) {
    let isConditions = false;

    if (clm.hasOwnProperty("selecting_conditions")) {
        const conditions = clm.selecting_conditions;
        for (let rule of conditions) {
            if (row[rule.key] === rule.value) {
                isConditions = true;
                break;
            }
        }
    } else {
        isConditions = true;
    }

    return isConditions;
}
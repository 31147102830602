import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiImportFiles from "../api/import_files";
import { downloadFile } from "core/request_utils";
import { changeNestedArrKeyValueOnCondition } from "core/iterable_utils";

export const importFilesXml = createAsyncThunk("importFilesSlice/importFilesXml", async (dct, { rejectWithValue }) => {
	try {
		const response = await apiImportFiles.import_xml(dct["file"], dct["project_id"], dct["index"]);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const getImportAttempts = createAsyncThunk("importFilesSlice/getImportAttempts", async (id, { rejectWithValue }) => {
	try {
		const response = await apiImportFiles.getImportAttempts(id);
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

export const getImportBrainStormAttempts = createAsyncThunk("importFilesSlice/getImportBrainStormAttempts", async (id, { rejectWithValue }) => {
	try {
		const response = await apiImportFiles.getImportBrainStormAttempts(id);
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

export const getXsdErrors = createAsyncThunk("importFilesSlice/getXsdErrors", async (id, { rejectWithValue }) => {
	try {
		const response = await apiImportFiles.getXsdErrors(id);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const getXsdEstmErrorsExcel = createAsyncThunk("importFilesSlice/getXsdEstmErrorsExcel", async (id) => {
	const response = await apiImportFiles.getXsdEstmErrorsExcel(id);
	downloadFile(response, "xsd_errors.xlsx");
});

export const getXsdProjErrorsExcel = createAsyncThunk("importFilesSlice/getXsdProjErrorsExcel", async (id) => {
	const response = await apiImportFiles.getXsdProjErrorsExcel(id);
	downloadFile(response, "xsd_errors.xlsx");
});

export const importFilesSlice = createSlice({
	name: "importFilesSlice",
	fetchStatus: "",
	fetchStatusText: "",
	needUpdate: null,
	curProjId: null,
	initialState: {
		filesList: [],
		monitorData: [],
		fetchFilesList: 0,
		fsnbData: [],
		perData: [],
		objData: [],
		indData: [],
		indOrgData: [],
		modalVisible: {
			estimateTree: false,
			recomIndex: false,
			waiting: false,
		},
	},
	reducers: {
		pushFilesToList: (state, { payload }) => {
			const arr = Object.entries(payload).map((x) => x[1]);
			state.filesList = [...state.filesList, ...arr].map((f, index) => {
				if (f.key == null) {
					f.key = index;
					f.status = 0;
					f.front_index = index;
				}
				return f;
			});
			state.filesList = state.filesList.sort((a, b) => (a.front_index < b.front_index ? 1 : -1));
		},
		updateFileFromList: (state, { payload: { curRow, data } }) => {
			state.filesList = state.filesList.map((stateRow) => {
				if (stateRow.key === curRow.key) {
					stateRow.id = data.id;
					stateRow.status = data.status;
					if (data.steps) {
						stateRow.steps = { ...stateRow.steps, ...data.steps };
					}
				}
				return stateRow;
			});
		},
		hideFileListDetails: (state, { payload }) => {
			state.filesList = state.filesList.map((row) => {
				if (row.id === payload.id) {
					row._details = null;
				}
				return row;
			});
		},
		setFetchStatus: (state, { payload }) => {
			state.fetchStatus = payload;
		},
		clearFileList: (state, { payload }) => {
			state.filesList = [];
		},
		setCurProjId: (state, { payload }) => {
			state.curProjId = payload;
		},
		changeFileKeyValue: (state, { payload }) => {
			const objKey = payload?.objKey;
			const key = payload?.key;
			const value = payload?.value;
			state.filesList = state.filesList.map((fileRow) => {
				if (fileRow.key === objKey) fileRow[key] = value;
				return fileRow;
			});
		},
		setFilesList: (state, { payload }) => {
			state.filesList = payload;
		},
		setMonitorData: (state, { payload }) => {
			state.monitorData = payload;
		},
		changeMonitorDataChildrenVisible: (state, { payload }) => {
			state.monitorData = changeNestedArrKeyValueOnCondition({
				arr: state.monitorData,
				keySearch: "key",
				valSearch: payload.row.key,
				keyChange: "childs_visible",
				valChange: !payload.row.childs_visible,
				callback: (row) => {
					if (row._children) {
						for (let ch of row._children) {
							ch.visible = !payload.row.childs_visible;
						}
					}
				},
			});
		},
		changeMonitorDataDetailsVisible: (state, { payload }) => {
			state.monitorData = changeNestedArrKeyValueOnCondition({
				arr: state.monitorData,
				keySearch: "key",
				valSearch: payload.row.key,
				keyChange: "details_visible",
				valChange: !payload.row.details_visible,
			});
		},
		changeStepsStatus: (state, { payload }) => {
			state.monitorData = changeNestedArrKeyValueOnCondition({
				arr: state.monitorData,
				keySearch: "id",
				valSearch: payload.id_val,
				keyChange: "t",
				valChange: null,
				callback: (row) => {
					if (payload.key_vals) {
						for (let item of payload.key_vals) {
							row[item.key] = item.val;
						}
					}
				},
			});
		},
		changeModalVisible: (state, { payload }) => {
			if (payload.value === null) {
				state.modalVisible[payload.key] = !state.modalVisible[payload.key];
			} else {
				state.modalVisible[payload.key] = payload.value;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getImportAttempts.fulfilled, (state, { payload }) => {
			if (!payload.length) {
				state.fetchStatus = "success";
				state.fetchFilesList = 1;
				return;
			}

			const payloadIndexes = payload.map((x) => x.front_index);
			const filtredState = state.filesList.filter((x) => !payloadIndexes.includes(x.front_index));
			state.filesList = [...filtredState, ...payload];
			state.filesList = state.filesList.sort((a, b) => (a.front_index < b.front_index ? 1 : -1));
			state.fetchStatus = "success";
			state.fetchFilesList = 1;
		});

		builder.addCase(getImportBrainStormAttempts.fulfilled, (state, { payload }) => {
			const importData = payload.import_data;
			state.monitorData = payload.monitor_data;

			state.fsnbData = payload.fsnb_data;
			state.perData = payload.per_data;
			state.pzData = payload.pz_data;
			state.objData = payload.obj_data;
			state.indData = payload.ind_data;
			state.indOrgData = payload.ind_org_data;
			state.estmMethodicData = payload.estm_methodic_data;

			if (!importData.length) {
				state.fetchStatus = "success";
				state.fetchFilesList = 1;
				return;
			}

			const payloadIndexes = importData.map((x) => x.front_index);
			const filtredState = state.filesList.filter((x) => !payloadIndexes.includes(x.front_index));
			state.filesList = [...filtredState, ...importData];
			state.filesList = state.filesList.sort((a, b) => (a.front_index < b.front_index ? 1 : -1));
			state.fetchStatus = "success";
			state.fetchFilesList = 1;
		});

		builder.addCase(getXsdErrors.fulfilled, (state, { payload }) => {
			state.filesList = state.filesList.map((row) => {
				if (row.id === payload[0].attempt_id) {
					row._details = payload;
				}
				return row;
			});
			state.fetchStatus = "success";
		});

		builder.addMatcher(
			isAnyOf(importFilesXml.rejected, getImportAttempts.rejected, getXsdErrors.rejected, getImportBrainStormAttempts.pending),
			(state, action) => {
				state.fetchStatus = "failed";
			}
		);

		builder.addMatcher(isAnyOf(importFilesXml.pending, getXsdErrors.pending, getImportBrainStormAttempts.pending), (state, action) => {
			state.fetchStatus = "loading";
		});

		builder.addMatcher(isAnyOf(getImportAttempts.pending), (state, action) => {
			state.fetchFilesList = 0;
			state.fetchStatus = "loading";
		});
	},
});

export const {
	pushFilesToList,
	updateFileFromList,
	hideFileListDetails,
	setFetchStatus,
	clearFileList,
	setCurProjId,
	changeFileKeyValue,
	changeMonitorDataChildrenVisible,
	changeMonitorDataDetailsVisible,
	setFilesList,
	setMonitorData,
	changeStepsStatus,
	changeModalVisible,
} = importFilesSlice.actions;

export default importFilesSlice.reducer;

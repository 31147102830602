import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import Settings from "@mui/icons-material/Settings";

import "./CheckBoxFlat.css";

export default function CheckBoxFlat({
    label,
    optionValues,
    curSelected,
    f_setCurSelected,
    boxWidth,
    f_closeAction,
    superSearchView,
}) {
    const handleChange = (row) => {
        f_setCurSelected((prev) => {
            const inArray = prev.find((x) => x.id === row.id);
            if (inArray === undefined) {
                return [...prev, row];
            } else {
                return prev.filter((x) => x.id !== row.id);
            }
        });
    };

    const renderSelValue = (selected) => {
        let chipLst = [];
        for (let x of selected) {
            if (x != null) {
                chipLst.push(<Chip key={x.id} label={x.name} />);
            }
        }
        return chipLst;
    };

    return (
        <div
            className="CheckBoxFlat"
            style={{ width: `${boxWidth}px`, maxWidth: `${boxWidth}px`, minWidth: `${boxWidth}px` }}>
            <TextField
                disabled={superSearchView}
                sx={{
                    "& legend": {
                        fontSize: "9px",
                    },
                }}
                label={label}
                size="small"
                // error={isError}
                select
                SelectProps={{
                    multiple: true,
                    value: curSelected,
                    renderValue: renderSelValue,
                    onClose: (e) => f_closeAction(),
                    IconComponent: Settings,
                }}>
                {optionValues.map((opt) => (
                    <MenuItem
                        key={opt.id}
                        title={opt.name}
                        onClick={(e) => handleChange(opt)}
                        selected={curSelected.filter((x) => x.id === opt.id).length === 1}>
                        <Checkbox checked={curSelected.filter((x) => x.id === opt.id).length === 1}></Checkbox>
                        <ListItemText primary={opt.name} />
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
}

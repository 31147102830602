import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { setUserTasksVisible, getUserTasksAndRead } from "slices/user_adv";
import UserTaskRow from "./UserTaskRow";

import "./UserTasks.css";

const UserTasks = () => {
	const dispatch = useDispatch();
	const userTasks = useSelector((state) => state.userAdv.userTasks);
	const userTasksVisible = useSelector((state) => state.userAdv.userTasksVisible);

	let loopRead;

	const poolLoopRead = () => {
		loopRead = !loopRead && setInterval(() => dispatch(getUserTasksAndRead()), 5000);
	};

	useEffect(() => {
		if (!userTasksVisible) return;
		poolLoopRead();
		return () => clearTimeout(loopRead);
	}, [userTasksVisible]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="UserTasks">
			<div className="UserTasksHeader">
				<div className="UserTasksTitle">Активные задачи (процессы)</div>
				<div className="UserTasksClose">
					<Tooltip title="Закрыть">
						<IconButton
							className="UploadModalClose"
							onClick={(e) => {
								dispatch(getUserTasksAndRead());
								dispatch(setUserTasksVisible(false));
							}}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</div>
			</div>
			<div className="UserTasksBody">
				{userTasks?.map((task) => {
					return <UserTaskRow key={task.key} task={task} />;
				})}
			</div>
		</div>
	);
};

export default UserTasks;

import KsprSplitTextButton from "components/UI/buttons/KsprSplitTextButton";

const PriceLevelSwitcher = ({ priceLevel, setPriceLevel, priceLevelText, baseText, curText }) => {
	return (
		<div style={{ display: "flex" }}>
			{priceLevelText}
			<KsprSplitTextButton
				leftText={baseText}
				isLeftValid={priceLevel === "base"}
				leftCallback={() => setPriceLevel("base")}
				rightText={curText}
				isRightValid={priceLevel === "current"}
				rightCallback={() => setPriceLevel("current")}
			/>
		</div>
	);
};

export { PriceLevelSwitcher };

import { XmlResourceTypeEnum } from "core/enums"

import { CustomNumCell } from "./CustomCells/CustomNumCell";


export default function LocalEstimateTableColumnsRim({ hooks }) {
    let numWidht = 50;
    let codeWidht = 150;
    let unitWidht = 75;

    let consumptionWidht = 100;
    let consumption_coefWidht = 100;
    let consumptiontotalWidht = 100;

    let priceperunitbaseWidht = 100;
    let index_valueWidht = 100;
    //let priceperunitcurWidht = 100;
    let pricetotalcurWidht = 100;
    let cost_coefWidht = 100;

    let checkWidht = 20;
    let protWidht = 17;

    let sumWidht = 0;
    for (let x of [
        numWidht,
        codeWidht,
        unitWidht,
        consumptionWidht,
        consumption_coefWidht,
        consumptiontotalWidht,
        priceperunitbaseWidht,
        index_valueWidht, //priceperunitcurWidht,
        pricetotalcurWidht,
        cost_coefWidht,
        checkWidht,
        protWidht,
    ]) {
        sumWidht += x;
    }

    let nameWidht = window.innerWidth * 0.7 - sumWidht;

    if (nameWidht < 300) nameWidht = 300;

    return [
        {
            name: "num",
            title: "№ п/п",
            width: numWidht + "px",
            cellHorAlign: "center",
            headHorAlign: "center",
            rowSpan: "2",
            headHeight: "22px",
            marginLeft: "0px",
            cellJsx: ({ rowObj, clmName, clickedTableClmName }) => CustomNumCell({ rowObj, clmName, clickedTableClmName, hooks })
        },
        {
            name: "code",
            title: "Обоснование",
            width: codeWidht + "px",
            cellHorAlign: "center",
            headHorAlign: "left",
            rowSpan: "2",
            headHeight: "22px",
            marginLeft: "10px",
            editable: true,
            // cellJsx: ({ rowObj, parentProps, clmObj }) => CustomCodeCell({ rowObj, parentProps, clmObj, hooks })
        },
        {
            name: "name",
            title: "Наименование работ и затрат",
            width: nameWidht + "px",
            cellHorAlign: "left",
            headHorAlign: "left",
            rowSpan: "2",
            headHeight: "22px",
            marginLeft: "10px",
            editable: (arg) => {
                if (arg.rowObj?.model_name === "LocalEstimateRimSections" || arg.rowObj?.is_tc === true) {
                    return true
                } else {
                    return false
                }
            },
        },
        {
            name: "unit",
            title: "Ед. изм.",
            width: unitWidht + "px",
            cellHorAlign: "center",
            headHorAlign: "left",
            rowSpan: "2",
            headHeight: "22px",
            marginLeft: "10px",
            editable: (arg) => {
                if (arg.rowObj?.is_tc === true) {
                    return true
                } else {
                    return false
                }
            },
        },
        {
            name: "consum",
            title: "Количество",
            width: "300px",
            cellHorAlign: "left",
            headHorAlign: "center",
            headHeight: "22px",
            colSpan: "3",
            children: [
                {
                    name: "consumption",
                    title: "на ед. изм.",
                    width: consumptionWidht + "px",
                    cellHorAlign: "center",
                    headHorAlign: "center",
                    headHeight: "22px",
                    editable: (arg) => {
                        if (arg.rowObj?.is_item === "1" ||
                            arg.rowObj?.xml_res_type_id === XmlResourceTypeEnum.ITEM_RESOURCE ||
                            arg.rowObj?.is_dependant_pg === true) {
                            return true
                        } else {
                            return false
                        }
                    },
                    editablePermission: {
                        type: "number",
                    },
                },
                {
                    name: "consumption_coef",
                    title: "коэф.",
                    width: consumption_coefWidht + "px",
                    cellHorAlign: "center",
                    headHorAlign: "center",
                    headHeight: "22px",
                },
                {
                    name: "consumptiontotal",
                    title: "всего с уч. коэф.",
                    width: consumptiontotalWidht + "px",
                    cellHorAlign: "center",
                    headHorAlign: "center",
                    headHeight: "22px",
                },
            ],
        },
        {
            name: "cost",
            title: "Сметная стоимость, руб.",
            width: "500px",
            cellHorAlign: "left",
            headHorAlign: "center",
            headHeight: "22px",
            colSpan: "5",
            children: [
                {
                    name: "priceperunitbase",
                    title: "на еи в баз. цен",
                    width: priceperunitbaseWidht + "px",
                    cellHorAlign: "center",
                    headHorAlign: "center",
                    headHeight: "22px",
                },
                {
                    name: "index",
                    title: "индекс",
                    width: index_valueWidht + "px",
                    cellHorAlign: "center",
                    headHorAlign: "center",
                    headHeight: "22px",
                },
                {
                    name: "priceperunitcur",
                    title: "на еи в тек. цен",
                    width: pricetotalcurWidht + "px",
                    cellHorAlign: "center",
                    headHorAlign: "center",
                    headHeight: "22px",
                    editable: (arg) => {
                        if (arg.rowObj?.is_tc === true || arg.rowObj?.is_void === true) {
                            return true
                        } else {
                            return false
                        }
                    },
                    editablePermission: {
                        type: "number",
                    },
                },
                {
                    name: "cost_coef",
                    title: "коэф.",
                    width: cost_coefWidht + "px",
                    cellHorAlign: "center",
                    headHorAlign: "center",
                    headHeight: "22px",
                },
                {
                    name: "pricetotalcur",
                    title: "всего в тек. цен",
                    width: pricetotalcurWidht + "px",
                    cellHorAlign: "center",
                    headHorAlign: "center",
                    headHeight: "22px",
                },
            ],
        },
    ];
}


const getLocalEstimateTableColumnsRimFlat = () => {
    return [
        "num", "code", "name", "unit", "consumption", "consumption_coef",
        "consumptiontotal", "priceperunitbase", "index", "priceperunitcur", "cost_coef", "pricetotalcur"
    ]
}

export { getLocalEstimateTableColumnsRimFlat }
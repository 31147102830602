import { useState } from "react";
import Popover from "@mui/material/Popover";
import { Tooltip } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const RibbonElementSelectCustomOptions = ({ elementSettings }) => {
    const { name, icon, isDisabled, nameVisible, size, getJsxOptions, callback } = elementSettings;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClick = (event) => {
        if (!event.target) return;
        const selectActivator = event.target.getAttribute("select_activator");
        if (callback) {
            if (selectActivator !== "1") callback();
        } else {
            handleOpen(event);
        }
    };

    const handleClose = (e) => {
        if (
            !String(e.target.className).includes("MuiTypography-root") &&
            !String(e.target.className).includes("MuiCheckbox-root")
        ) {
            setAnchorEl(null);
        }
    };

    const open = Boolean(anchorEl);

    return (
        <div className="ribbon-element-select-wrapper">
            <Tooltip title={nameVisible && name}>
                <span>
                    <button
                        className={
                            size === "large"
                                ? "ribbon-element-select ribbon-element-select__large"
                                : "ribbon-element-common"
                        }
                        disabled={isDisabled}
                        onClick={handleClick}>
                        {icon}
                        {!nameVisible && name}
                        <ArrowDropDownIcon
                            className={size === "large" ? "ribbon-element-select-svg__large" : ""}
                            onClick={handleOpen}
                            select_activator="1"
                        />
                    </button>
                </span>
            </Tooltip>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}>
                <div className="ribbon-select-options">{getJsxOptions()}</div>
            </Popover>
        </div>
    );
};

export default RibbonElementSelectCustomOptions;

import { useNavigate } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import { getLastFiltredUrlFromStack } from "../../../components/utils";
import KsprButton from "components/UI/buttons/KsprButton";

import "./FsnbNavigation.css";

export default function FsnbNavigation(props) {
	const navigate = useNavigate();
	return (
		<div className="FsnbNavigation">
			<div className="FsnbNavigationLeft">
				<KsprButton
					size="small"
					variant="outlined"
					label={<span style={{ position: "relative", top: "1px" }}>Обратно</span>}
					onClick={() => {
						navigate(getLastFiltredUrlFromStack(props.history, ["/fsnb"]));
					}}
					startIcon={<LogoutOutlinedIcon rotate="180" />}
				></KsprButton>
			</div>
			{props.tabValue !== "search" && (
				<div className="FsnbNavigationRight">
					<Tabs variant="scrollable" scrollButtons="auto" value={props.tabValue} onChange={props.f_changeTabValue}>
						<Tab value="norm" label="СМЕТНЫЕ НОРМАТИВЫ" />
						<Tab value="mat" label="МАТЕРИАЛЫ" />
						<Tab value="mach" label="МАШИНЫ" />
						<Tab value="coef" label="КОЭФФИЦИЕНТЫ" />
						<Tab value="nrsp" label="НР И СП" />
						<Tab value="pg" label="ПЕРЕВОЗКА" />
						<Tab value="ksr" label="КСР" />
					</Tabs>
				</div>
			)}
		</div>
	);
}

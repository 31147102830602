import { apiBase } from "./base";

const apiProjectsPack = {
	client: apiBase.get_client(),
	url: "/projects/packs/",

	get(id) {
		return this.client.get(this.url + id + "/", apiBase.get_headers());
	},

	getList() {
		return this.client.get(this.url, apiBase.get_headers());
	},

	delete(id) {
		return this.client.delete(this.url + id + "/", apiBase.get_headers());
	},

	edit(id, body) {
		return this.client.patch(this.url + id + "/", body, apiBase.get_headers());
	},

	create(body) {
		return this.client.post(this.url, body, apiBase.get_headers());
	},
};

export default apiProjectsPack;

import { useState, useEffect } from "react";

import Draggable from "react-draggable";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CommentsBox from "./CommentsBox";
import AlertLoading from "components/AlertLoading";

import apiProjects from "api/projects";

import "./CommentsBoxModal.css";

const CommentsBoxModal = ({ rowObj, setVisible, postActions }) => {
	const [inputComment, setInputComment] = useState();
	const [fetchStatus, setFetchStatus] = useState();
	const [fetchStatusText, setFetchStatusText] = useState();
	const [commentObj, setCommentObj] = useState(rowObj);

	const readComment = () => {
		setFetchStatus("loading");
		const prom = apiProjects.getRowComments({
			projectId: commentObj.project_id,
			key: commentObj.key,
		});
		prom.then((response) => {
			setCommentObj({ ...commentObj, _comments: response.data });
			setFetchStatus("success");
		}).catch((error) => {
			console.error(error);
			setFetchStatus("failed");
		});
	};

	useEffect(() => {
		readComment();
	}, [commentObj?.key]); // eslint-disable-line react-hooks/exhaustive-deps

	const postComment = (text) => {
		if (!inputComment) return;
		setFetchStatus("loading");
		const prom = apiProjects.postRowComment({
			project_id: rowObj.project_id,
			estm_id: rowObj.id,
			type: rowObj.type,
			key: rowObj.key,
			text: text,
		});
		prom.then((response) => {
			setFetchStatus("success");
			readComment();
			setInputComment("");
			if (rowObj._comments == null || rowObj._comments?.length === 0) {
				postActions.updateProjectTree();
			}
		}).catch((error) => {
			console.error(error);
			setFetchStatus("failed");
		});
	};

	const deleteComment = (commentObj) => {
		setFetchStatus("loading");
		const prom = apiProjects.deleteRowComment(commentObj.id);
		prom.then((response) => {
			setFetchStatus("success");
			readComment();
			setInputComment("");
			if (rowObj._comments == null || rowObj._comments?.length === 1) {
				postActions.updateProjectTree();
			}
		}).catch((error) => {
			if (error?.response?.data?.detail != null) setFetchStatusText(error.response.data.detail);
			setFetchStatus("failed");
		});
	};

	const editComment = ({ commentObj, text }) => {
		setFetchStatus("loading");
		const prom = apiProjects.editRowComment({ commentId: commentObj.id, text });
		prom.then((response) => {
			setFetchStatus("success");
			readComment();
			setInputComment("");
		}).catch((error) => {
			if (error?.response?.data?.detail != null) setFetchStatusText(error.response.data.detail);
			setFetchStatus("failed");
		});
	};

	return (
		<Draggable handle=".CommentsBoxModalTitle">
			<Dialog
				className="CustomDetailsModal"
				sx={{ "& .MuiDialog-paper": { width: "50vw", maxWidth: "50vw", height: "400px" } }}
				open
				slotProps={{ backdrop: { style: { backgroundColor: "transparent" } } }}
			>
				<DialogTitle className="CommentsBoxModalTitle">
					<div>Комментарии к {rowObj.innertext}</div>
					<Tooltip title="Закрыть">
						<IconButton aria-label="Закрыть" onClick={() => setVisible(false)}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</DialogTitle>
				<DialogContent className="CommentsBoxModalContent">
					<CommentsBox
						rowObj={commentObj}
						inputComment={inputComment}
						setInputComment={setInputComment}
						setVisible={setVisible}
						postComment={postComment}
						deleteComment={deleteComment}
						editComment={editComment}
					/>
					<AlertLoading
						fetchStatus={fetchStatus}
						func={setFetchStatus}
						fetchStatusText={fetchStatusText}
						setFetchStatusText={setFetchStatusText}
						isLocal={true}
					/>
				</DialogContent>
			</Dialog>
		</Draggable>
	);
};

export default CommentsBoxModal;

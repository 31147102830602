import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
// import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
// import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";

import CheckBoxFlat from "components/CheckBoxFlat";
// import RibbonElementCommon from "components/Ribbon/RibbonContent/RibbonElementCommon";

import { useLocalStorage } from "hooks/useLocalStorage";

import "./FsnbRibbonFilters.css";

const FsnbRibbonFilters = ({
	fsnbVersArr,
	selectedFsnbVers,
	setSelectedFsnbVers,
	attrsOpts,
	selectedSearchOptions,
	setSelectedSearchOptions,
	inputSearchValue,
	fetchStatus,
	setInputSearchValue,
	moveNextSearch,
	searchFounds,
	tabValue,
	superSearchView,
	// searchObjScope,
	// setSearchObjScope,
	// selectedTreeData,
	searchMode,
	setSearchMode,
	setFilterTrigger,
	// snbTreeDrawerVisible,
	// setSnbTreeDrawerVisible,
}) => {
	const navigateFromEditor = useSelector((state) => state.ribbon.navigateFromEditor);
	// const currentPage = useSelector((state) => state.ribbon.currentPage);

	const { setLsItem } = useLocalStorage();

	const triggerSearch = () => setFilterTrigger((prev) => prev + 1);

	let searchResult = "";
	if (searchFounds?.length) {
		if (searchFounds.length >= 1000) {
			searchResult = `Найдено: более ${searchFounds.length}`;
		} else {
			searchResult = `Найдено: ${searchFounds.length}`;
		}
	}

	let fsnbVersSelectLabel = "Редакция ФСНБ";
	if (tabValue === "nrsp") {
		fsnbVersSelectLabel = "Номера приказов и период действия";
	}

	return (
		<div className="fsnbCustomFilterGroup">
			<div className="fsnbCustomFilterGroupTop">
				<Autocomplete
					sx={{ width: "490px", "& legend": { fontSize: "9px" } }}
					options={fsnbVersArr}
					value={selectedFsnbVers}
					disableClearable
					disabled={navigateFromEditor === "add" || navigateFromEditor === "abs" || tabValue === "ksr"}
					getOptionLabel={(option) => option.name}
					size="small"
					noOptionsText="нет данных"
					onInputChange={(e, val) => {
						const inpVal = fsnbVersArr.find((x) => x["name"] === val);
						if (inpVal != null) {
							setSelectedFsnbVers(inpVal);
							if (!["nrsp", "ksr"].includes(tabValue)) {
								setLsItem({ key: "selectedFsnbVersId", value: inpVal?.id });
							}
						}
					}}
					renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} label={fsnbVersSelectLabel} />}
				/>

				<CheckBoxFlat
					boxWidth={480}
					label={"Настройки поиска"}
					optionValues={attrsOpts}
					curSelected={selectedSearchOptions}
					f_setCurSelected={setSelectedSearchOptions}
					f_closeAction={triggerSearch}
					superSearchView={superSearchView}
				/>
			</div>

			<div className="fsnbCustomFilterGroupBottom">
				<TextField
					sx={{
						width: "490px",
						"& legend": {
							fontSize: "9px",
						},
					}}
					label="Поиск"
					size="small"
					value={inputSearchValue}
					onChange={(e) => {
						if (fetchStatus !== "loading") {
							setInputSearchValue((prev) => e.target.value);
							triggerSearch();
						}
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							if (fetchStatus !== "loading") {
								setInputSearchValue((prev) => e.target.value);
								triggerSearch();
							}
						}
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon fontSize="16px" />
							</InputAdornment>
						),
					}}
				/>

				{/* <div className="SearchOnSelected">
					<RibbonElementCommon
						elementSettings={{
							name: "Поиск / фильтр",
							callback: () => {
								if (searchMode === "nested") {
									setSearchMode("flat");
								} else if (searchMode === "flat") {
									setSearchMode("nested");
								}
							},
							icon: <ManageSearchOutlinedIcon />,
							active: searchMode === "flat",
							isDisabled: tabValue !== "norm" || currentPage === "pdf",
						}}
					/>
				</div> */}

				{/* {tabValue === "norm" && searchMode === "nested" && (
					<div className="SearchOnSelected">
						<RibbonElementCommon
							elementSettings={{
								name: "Фикс. область поиска",
								callback: () => {
									if (!selectedTreeData?.id || searchObjScope?.id === selectedTreeData?.id) {
										setSearchObjScope({});
									} else if (selectedTreeData?.id) {
										setSearchObjScope(selectedTreeData);
									}
								},
								icon: <ShieldOutlinedIcon />,
								isDisabled: selectedTreeData?.id == null || searchMode === "flat",
								active: searchObjScope?.id != null,
							}}
						/>
					</div>
				)} */}
				{/* {tabValue === "norm" && searchMode === "flat" && (
					<div className="SearchOnSelected">
						<RibbonElementCommon
							elementSettings={{
								name: "Поиск в разделах содержания",
								callback: () => setSnbTreeDrawerVisible((prev) => !prev),
								icon: <ShieldOutlinedIcon />,
								active: snbTreeDrawerVisible === true,
							}}
						/>
					</div>
				)} */}
				{searchFounds?.length > 0 && (
					<div className="SearchNavButtons">
						<ArrowDropDownOutlinedIcon onClick={(e) => moveNextSearch("next")} />
						<ArrowDropUpOutlinedIcon onClick={(e) => moveNextSearch("prev")} />
					</div>
				)}

				<div className="SearchResults">{searchResult}</div>
			</div>
		</div>
	);
};

export { FsnbRibbonFilters };

import { useState, useEffect, useRef, lazy, Suspense } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import FsnbNavigation from "./components/FsnbNavigation";
import FsnbBody from "./components/FsnbBody";
import { resourceChangeVisible, resourceOpenTree } from "./utils.js";

import AlertLoading from "components/AlertLoading";

import apiFsnb from "api/fsnb";
import apiUserAdv from "api/user_adv";
import { setStartSearchObj, setStartSearchCode } from "slices/fsnb";

import { setCurrentPage, setNavigateFromEditor, setClickedTableRow } from "slices/ribbon";

import { showTableMore } from "./utils";
import { arrProxy } from "core/iterable_utils";
import { useLocalStorage } from "hooks/useLocalStorage";
import useDebounceCallback from "hooks/useDebounceCallback";

import FsnbSuperSearchBody from "./FsnbSuperSearch/FsnbSuperSearchBody";
import RibbonContent from "components/Ribbon/RibbonContent/RibbonContent";
import FsnbRibbonSettings from "./FsnbRibbonSettings";
import FsnbFooterSettings from "./FsnbFooterSettings";
import Footer from "components/Footer/Footer";

import { getEnvVal } from "core/env.js";

import "./FsnbMain.css";

const BlackListModal = lazy(() => import("pages/Fsnb/components/BlackListModal/BlackListModal"));
const SnbTreeDrawer = lazy(() => import("pages/Fsnb/components/SnbTreeDrawer/SnbTreeDrawer"));

export default function FsnbMain(props) {
	const inputEl = useRef(null);
	const scrollBlock = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const startSearchObj = useSelector((state) => state.fsnb.startSearchObj);
	const startSearchCode = useSelector((state) => state.fsnb.startSearchCode);
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const windowWidth = useSelector((state) => state.windowDimensions.windowWidth);
	const fetchStatusEditor = useSelector((state) => state.localEstimateEditor.fetchStatus);
	const fetchStatusEditorText = useSelector((state) => state.localEstimateEditor.fetchStatusText);

	const [fetchStatus, setFetchStatus] = useState(null);
	const [fetchStatusText, setFetchStatusText] = useState();
	const [selectedFsnbVers, setSelectedFsnbVers] = useState({
		id: 0,
		name: "",
	});
	const [fsnbVersArr, setFsnbVersArr] = useState([]);
	const [fsnbVersType, setFsnbVersType] = useState("fsnb");
	const needUpdateVers = useRef(true);

	const [tabValue, setTabValue] = useState(null);
	const match = useMatch("/fsnb/:lastPart");

	const [treeData, setTreeData] = useState([]);
	const [selectedTreeData, setSelectedTreeData] = useState({});
	const [tableData, setTableData] = useState([]);
	const [searchFounds, setSearchFounds] = useState([]);
	const [searchFoundsStats, setSearchFoundsStats] = useState({});
	const [curSearchRow, setCurSearchRow] = useState({});
	const [fsnbBodyContent, setFsnbBodyContent] = useState("table");
	const [pdfData, setPdfData] = useState("");

	const [inputSearchValue, setInputSearchValue] = useState("");
	const [superSearchView, setSuperSearchView] = useState(false);

	const [blackListVisible, setBlackListVisible] = useState(false);
	const [searchObjScope, setSearchObjScope] = useState({});
	const [searchMode, setSearchMode] = useState("nested");
	const [filterTrigger, setFilterTrigger] = useState(0);
	const [filterLimit, setFilterLimit] = useState(getEnvVal("FsnbFlatSearchLimit"));

	// const [snbTreeDrawerVisible, setSnbTreeDrawerVisible] = useState(false);
	const [selectedSnbRow, setSelectedSnbRow] = useState([]);

	const lowerCallbacks = useRef({});

	const { getLsItem } = useLocalStorage();

	const attrsOpts = [
		{ id: 1, name: "Наименование расценки" },
		{ id: 3, name: "Шифр расценки" },
		{ id: 2, name: "Наименование ресурса" },
		{ id: 4, name: "Код ресурса" },
		{ id: 6, name: "Состав работ" },
	];
	const [selectedSearchOptions, setSelectedSearchOptions] = useState(attrsOpts.filter((x) => [1, 3].includes(x.id))); // 2, 3, 6

	useEffect(() => {
		if (fetchStatusEditor !== "failed") {
			setFetchStatus(fetchStatusEditor);
		}
		if (fetchStatusEditor === "successAlert" && fetchStatusEditorText) {
			setFetchStatusText(fetchStatusEditorText);
		}
	}, [fetchStatusEditor, fetchStatusEditorText]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (match == null) {
			setTabValue("norm");
			setSuperSearchView(false);
			navigate("/fsnb/norm/");
		} else {
			setTabValue(match?.params?.lastPart);
		}
	}, [match, navigate]);

	useEffect(() => {
		if (startSearchCode && selectedFsnbVers?.id) {
			dispatch(setStartSearchCode(""));
			setInputSearchValue(startSearchCode);
		}
	}, [selectedFsnbVers, startSearchCode]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		handleSearch(
			inputSearchValue,
			selectedSearchOptions.map((x) => x.id)
		);
	}, [searchObjScope]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setTableData([]);
		setSearchObjScope({});
		setSearchFounds([]);
		setFilterLimit(getEnvVal("FsnbFlatSearchLimit"));
	}, [searchMode]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSearchMode("nested");
	}, [tabValue]); // eslint-disable-line react-hooks/exhaustive-deps

	const getCurFsnbVersions = (versId) => {
		if (!needUpdateVers.current) return;
		if (match?.params?.lastPart === "nrsp") {
			getCurNrpsVersions();
			return;
		}
		needUpdateVers.current = false;
		setFetchStatus("loading");
		apiFsnb
			.getFsnbVersions()
			.then((response) => {
				setFsnbVersArr(response.data);
				if (startSearchObj) {
					const searchVers = response.data.find((x) => x.id === startSearchObj.vers_id);
					setSelectedFsnbVers(searchVers);
				} else if (versId) {
					const searchVers = response.data.find((x) => x.id === versId);
					setSelectedFsnbVers(searchVers);
				} else if (getLsItem({ key: "selectedFsnbVersId", _type: "int" })) {
					const searchVers = response.data.find(
						(x) =>
							x.id ===
							getLsItem({
								key: "selectedFsnbVersId",
								_type: "int",
							})
					);
					setSelectedFsnbVers(searchVers);
				} else {
					setSelectedFsnbVers(response.data[0]);
				}
			})
			.then((data) => {
				setFetchStatus("success");
			})
			.catch((error) => {
				console.error(error);
				setFetchStatus("failed");
				if (error?.response?.data?.detail != null) {
					setFetchStatusText(error.response.data.detail);
				}
			});
	};

	const getCurNrpsVersions = () => {
		setFetchStatus("loading");
		apiFsnb
			.getNrpsPeriod()
			.then((response) => {
				setFsnbVersArr(response.data);
				setSelectedFsnbVers(response.data[0]);
			})
			.then((data) => {
				setFetchStatus("success");
				needUpdateVers.current = true;
			})
			.catch((error) => {
				console.error(error);
				setFetchStatus("failed");
				if (error?.response?.data?.detail != null) {
					setFetchStatusText(error.response.data.detail);
				}
			});
	};

	/*eslint-disable */
	useEffect(() => {
		dispatch(setCurrentPage("table"));

		const item = localStorage.getItem("superSearchItem");
		if (item) {
			const obj = JSON.parse(item);

			if (obj.model_name.includes("TechParts")) {
				inputEl.current = obj;
				setFsnbBodyContent("pdf");
			}

			dispatch(setStartSearchObj(obj));
			getCurFsnbVersions(obj.vers_id);

			localStorage.removeItem("superSearchItem");
		} else {
			getCurFsnbVersions(null);
		}
		return () => {
			dispatch(setStartSearchObj(null));
			dispatch(setStartSearchCode(""));
			dispatch(setNavigateFromEditor(false));
		};
	}, []);

	useEffect(() => {
		if (fsnbVersType === "fsnb") {
			getCurFsnbVersions();
		} else if (fsnbVersType === "nrsp") {
			getCurNrpsVersions();
		}
	}, [fsnbVersType]);

	useEffect(() => {
		if (!Object.keys(selectedFsnbVers).length) return;

		setFetchStatus("loading");
		setTableData([]);
		if (!String(inputEl?.current?.model_name).includes("TechParts")) {
			setPdfData("");
		}
		let treeProm;
		if (tabValue === "mat") {
			setFsnbVersType("fsnb");
			treeProm = new Promise((resolve, reject) => {
				apiFsnb
					.getFsscTree(selectedFsnbVers.id)
					.then((response) => resolve(response.data))
					.catch((error) => reject(error.message));
			});
		} else if (tabValue === "pg") {
			setFsnbVersType("fsnb");
			treeProm = new Promise((resolve, reject) => {
				apiFsnb
					.getFsscPgTree(selectedFsnbVers.id)
					.then((response) => resolve(response.data))
					.catch((error) => reject(error.message));
			});
		} else if (tabValue === "ksr") {
			setFsnbVersType("fsnb");
			treeProm = new Promise((resolve, reject) => {
				apiFsnb
					.getKsrTree()
					.then((response) => resolve(response.data))
					.catch((error) => reject(error.message));
			});
		} else if (tabValue === "mach") {
			setFsnbVersType("fsnb");
			treeProm = new Promise((resolve, reject) => {
				apiFsnb
					.getFsemTree(selectedFsnbVers.id)
					.then((response) => resolve(response.data))
					.catch((error) => reject(error.message));
			});
		} else if (tabValue === "norm") {
			setFsnbVersType("fsnb");
			treeProm = new Promise((resolve, reject) => {
				apiFsnb
					.getNormTreeBase(selectedFsnbVers.id)
					.then((response) => resolve(response.data))
					.catch((error) => reject(error.message));
			});
		} else if (tabValue === "coef") {
			setFsnbVersType("fsnb");
			treeProm = new Promise((resolve, reject) => {
				apiFsnb
					.getCoefTree(selectedFsnbVers.id)
					.then((response) => resolve(response.data))
					.catch((error) => reject(error.message));
			});
		} else if (tabValue === "nrsp") {
			setFsnbVersType("nrsp");
			treeProm = new Promise((resolve, reject) => {
				apiFsnb
					.getNrpsTree(selectedFsnbVers.id)
					.then((response) => resolve(response.data))
					.catch((error) => reject(error.message));
			});
			setFetchStatus("success");
			setSelectedTreeData({});
		} else if (tabValue === "search") {
			setFsnbVersType("fsnb");
			treeProm = null;
			setSuperSearchView(true);
			setFetchStatus("success");
		}

		if (treeProm != null) {
			Promise.all([treeProm])
				.then((response_arrs) => {
					setTreeData(response_arrs[0]);
					return response_arrs;
				})
				.then((data) => {
					if (startSearchObj) {
						setSearchFounds([startSearchObj]);
						setCurSearchRow({
							...startSearchObj,
							openChilds: true,
						});
					}
					if (inputSearchValue) {
						handleSearch(
							inputSearchValue,
							selectedSearchOptions.map((x) => x.id)
						);
					}
					setFetchStatus("success");
				})
				.catch((error) => {
					console.error(error);
					setFetchStatus("failed");
				});
		}
	}, [selectedFsnbVers, tabValue]);

	useEffect(() => {
		if (fsnbBodyContent === "pdf" && isAllowedFetchPdf(inputEl.current, true)) {
			fetchPDF(inputEl.current.id);
		} else if (fsnbBodyContent === "table" && inputEl.current) {
			handleTreeClick(inputEl.current);
		}
	}, [fsnbBodyContent]);

	useEffect(() => {
		if (tabValue === "coef") setFsnbBodyContent("table");
		if (["coef", "nrsp"].includes(tabValue)) dispatch(setCurrentPage("table"));
	}, [tabValue]);

	//search effect
	useEffect(() => {
		if (!searchFounds.length) return;
		if (curSearchRow.parent_id == null) return;

		let tableProm;

		if (tabValue === "mat") {
			tableProm = apiFsnb.getFsscTableResource(selectedFsnbVers.id, curSearchRow.parent_id);
		} else if (tabValue === "pg") {
			tableProm = apiFsnb.getFsscPgTableResource(selectedFsnbVers.id, curSearchRow.parent_id);
		} else if (tabValue === "ksr") {
			if (curSearchRow.max_level) {
				tableProm = apiFsnb.getKsrTableResource(curSearchRow.parent_parent_id);
			} else {
				tableProm = apiFsnb.getKsrTableResource(curSearchRow.parent_id);
			}
		} else if (tabValue === "mach") {
			tableProm = apiFsnb.getFsemTableResource(selectedFsnbVers.id, curSearchRow.parent_id);
		} else if (tabValue === "norm") {
			tableProm = apiFsnb.getNormTableResource(selectedFsnbVers.id, curSearchRow.parent_id);
		} else if (tabValue === "coef") {
			tableProm = apiFsnb.getCoefTableResource(selectedFsnbVers.id, curSearchRow.parent_id);
			if (isAllowedFetchPdf(curSearchRow, true)) fetchPDF(curSearchRow.parent_id);
		} else if (tabValue === "nrsp") {
			tableProm = apiFsnb.getNrpsTable(selectedFsnbVers.id, curSearchRow.parent_id);
		} else if (tabValue === "search") {
			return;
		}
		setFetchStatus("loading");
		tableProm
			.then((response) => {
				if (response.data.length) {
					const searchIds = searchFounds.map((x) => x.id);
					const searchResIds = searchFounds.map((x) => x.res_id);
					let data = response.data;
					if (["mat", "pg", "ksr", "mach", "norm"].includes(tabValue)) {
						data = data.map((gpr) => {
							if (gpr._children == null) return gpr;
							gpr._children = gpr._children.map((row) => {
								if (searchIds.includes(row.id)) {
									if (["name", "code", "cost_name", "cost_code", "full_row"].includes(curSearchRow.str_clm)) {
										row.row_type_style = `selected_norm_${searchFounds.find((x) => x.id === row.id).str_clm}`;
										if (row._children) {
											row._children.map((child) => {
												if (searchIds.includes(child.id)) {
													child.row_type_style = `selected_norm_${searchFounds.find((x) => x.id === child.id).str_clm}`;
												}
												if (child.id === curSearchRow.id) {
													child.row_type_style = `cur_selected_norm_${curSearchRow.str_clm}`;
												}
											});
										}
									}
									if (row.id === curSearchRow.id) {
										if (["name", "code", "cost_name", "cost_code"].includes(curSearchRow.str_clm)) {
											row.row_type_style = `cur_selected_norm_${curSearchRow.str_clm}`;
										}
										if (
											tabValue === "norm" &&
											(["res_name", "res_code", "content_name"].includes(curSearchRow.str_clm) || curSearchRow.openChilds)
										) {
											row.resourcesVisibility = true;
											row.contentVisibility = true;
											row._children = [...arrProxy(row.norm_content), ...arrProxy(row.norm_resource)];
											row._children = [
												...row._children.map((ch) => {
													if (curSearchRow.res_id === ch.id) {
														ch.row_type_style = `${ch.row_type_style} cur_selected_norm_${curSearchRow.str_clm}`;
													} else if (searchResIds.includes(ch.id)) {
														ch.row_type_style = `${ch.row_type_style} selected_norm_${curSearchRow.str_clm}`;
													}
													return ch;
												}),
											];
										}
									}
								}
								return row;
							});
							return gpr;
						});
					} else if (["coef", "nrsp"].includes(tabValue)) {
						data = data.map((row) => {
							if (searchIds.includes(row.id)) {
								row.row_type_style = `selected_norm_${searchFounds.find((x) => x.id === row.id).str_clm}`;
								if (row.id === curSearchRow.id) {
									row.row_type_style = `cur_selected_norm_${curSearchRow.str_clm}`;
									row.isSearching = true;
								}
							}
							return row;
						});
					}
					setTableData(data);
				}

				if (tabValue === "pg") {
					setTreeData((prev) => [...resourceOpenTree(prev, { key: curSearchRow.parent_parent_id }, 0)[0]]);
					setSelectedTreeData({ key: curSearchRow.parent_parent_id });
					inputEl.current = { id: curSearchRow.parent_parent_id };
				} else if (tabValue === "ksr") {
					if (curSearchRow.max_level) {
						setTreeData((prev) => [...resourceOpenTree(prev, { key: curSearchRow.parent_parent_id }, 0)[0]]);
						setSelectedTreeData({
							key: curSearchRow.parent_parent_id,
						});
						inputEl.current = { id: curSearchRow.parent_parent_id };
					} else {
						setTreeData((prev) => [...resourceOpenTree(prev, { key: curSearchRow.parent_id }, 0)[0]]);
						setSelectedTreeData({ key: curSearchRow.parent_id });
						inputEl.current = { id: curSearchRow.parent_id };
					}
				} else {
					setTreeData((prev) => [...resourceOpenTree(prev, { key: curSearchRow.parent_id }, 0)[0]]);
					setSelectedTreeData({ key: curSearchRow.parent_id });
					inputEl.current = { id: curSearchRow.parent_id };
				}
				setFetchStatus("success");
			})
			.catch((error) => {
				console.error(error);
				setFetchStatus("failed");
			});
	}, [searchFounds, tabValue, selectedFsnbVers, curSearchRow]);
	/*eslint-enable */

	const changeTabValue = (e, newValue) => {
		inputEl.current = null;
		setPdfData("");
		setTreeData([]);
		setTableData([]);
		setSearchFounds([]);
		setCurSearchRow({});
		dispatch(setStartSearchObj(null));
		dispatch(setClickedTableRow(null));
		setInputSearchValue("");
		setTabValue(newValue);
		navigate(`/fsnb/${newValue}/`);
	};

	const isAllowedFetchPdf = (row, onSearch = false) => {
		if (!row) return false;
		let allowFetchPdf = false;
		if (["mat", "mach"].includes(tabValue)) {
			allowFetchPdf = true;
		} else if (tabValue === "coef" && row.level) {
			allowFetchPdf = true;
		} else if (tabValue === "norm" && row.order > 5) {
			allowFetchPdf = true;
		} else if (onSearch) {
			allowFetchPdf = true;
		}
		return allowFetchPdf;
	};

	const handleTreeClick = (row) => {
		inputEl.current = row;

		if (["mat", "mach", "norm", "coef", "nrsp", "pg", "ksr"].includes(tabValue)) {
			setTreeData((prev) => [...resourceChangeVisible(prev, row)]);

			if (fsnbBodyContent === "pdf") {
				if (isAllowedFetchPdf(row)) fetchPDF(row.id);
				return;
			}

			let tableProm;
			if (tabValue === "mat") {
				if (row.level < 2) return;
				tableProm = apiFsnb.getFsscTableResource(selectedFsnbVers.id, row.id);
			} else if (tabValue === "mach") {
				tableProm = apiFsnb.getFsemTableResource(selectedFsnbVers.id, row.id);
			} else if (tabValue === "pg") {
				tableProm = apiFsnb.getFsscPgTableResource(selectedFsnbVers.id, row.id);
			} else if (tabValue === "ksr") {
				tableProm = apiFsnb.getKsrTableResource(row.id);
			} else if (tabValue === "norm") {
				if (row.level < 1) return;
				tableProm = apiFsnb.getNormTableResource(selectedFsnbVers.id, row.id);
			} else if (tabValue === "coef") {
				if (row.level < 1) return;
				tableProm = apiFsnb.getCoefTableResource(selectedFsnbVers.id, row.id);
				if (isAllowedFetchPdf(row)) fetchPDF(row.id);
			} else if (tabValue === "nrsp") {
				tableProm = apiFsnb.getNrpsTable(selectedFsnbVers.id, row.id);
			}
			setFetchStatus("loading");
			tableProm
				.then((response) => {
					setTableData(response.data);
					setFetchStatus("success");
				})
				.catch((error) => {
					console.error(error);
					setFetchStatus("failed");
				});
		}
	};

	const getFlatTableResource = ({ strSearch, attrOpts }) => {
		if (searchMode === "nested") return;
		if (tabValue === "norm") {
			setFetchStatus("loading");
			const body = {
				attr_opts: attrOpts,
				search_text: strSearch,
				limit: filterLimit,
				selected_parent_tree_id: selectedSnbRow.map((x) => x.id),
			};
			const prom = apiFsnb.getNormFlatTableSearch({ versId: selectedFsnbVers.id, body: body });
			prom.then((response) => {
				setTableData(response.data.data);
				setSearchFoundsStats(response.data.stats);
				setFetchStatus("success");
			}).catch((error) => {
				console.error(error);
				setFetchStatus("failed");
			});
		}
	};

	const handleSearch = (strSearch, attrOpts = null) => {
		if (superSearchView) return;
		if (!strSearch) return;
		if (strSearch?.length < 3) return;

		let searchProm;

		if (tabValue === "mat") {
			searchProm = apiFsnb.getFsscTableSearch(selectedFsnbVers.id, strSearch);
		} else if (tabValue === "pg") {
			searchProm = apiFsnb.getFsscPgTableSearch(selectedFsnbVers.id, strSearch);
		} else if (tabValue === "ksr") {
			searchProm = apiFsnb.getKsrTableSearch({ body: { str_search: strSearch, level_search: [4, 5] } });
		} else if (tabValue === "mach") {
			searchProm = apiFsnb.getFsemTableSearch(selectedFsnbVers.id, strSearch);
		} else if (tabValue === "norm") {
			setSearchMode("flat");
			getFlatTableResource({ strSearch, attrOpts });
			return;
			// if (searchMode === "flat") {
			// 	getFlatTableResource({ strSearch, attrOpts });
			// 	return;
			// } else {
			// 	const body = {
			// 		attr_opts: attrOpts,
			// 		selected_parent_tree_id: searchObjScope?.id,
			// 		filter_on_parent_tree: searchObjScope?.id != null,
			// 	};
			// 	searchProm = apiFsnb.getNormTableSearch(selectedFsnbVers.id, strSearch, body);
			// }
		} else if (tabValue === "coef") {
			searchProm = apiFsnb.getCoefTableSearch(selectedFsnbVers.id, strSearch);
		} else if (tabValue === "nrsp") {
			searchProm = apiFsnb.getNrspTableSearch(selectedFsnbVers.id, strSearch);
		}
		setFetchStatus("loading");
		searchProm
			.then((responce) => {
				setCurSearchRow(responce.data[0]);
				if (!responce.data.length) {
					setTableData([]);
				}
				setSearchFounds(responce.data);
				setFetchStatus("success");
			})
			.catch((error) => {
				console.error(error);
				setFetchStatus("failed");
			});
	};

	useDebounceCallback({
		callback: () =>
			handleSearch(
				inputSearchValue,
				selectedSearchOptions.map((x) => x.id)
			),
		delayInMs: 1000,
		useEffectTrigger: filterTrigger,
		allowCallback: filterTrigger > 0,
	});

	useEffect(() => {
		setFilterLimit(getEnvVal("FsnbFlatSearchLimit"));
		if (scrollBlock.current) {
			scrollBlock.current.scrollTop = 0;
		}
		if (searchMode === "flat" && inputSearchValue.length === 0) {
			setSearchMode("nested");
			if (tabValue === "norm") {
				const navRow = { id: clickedTableRow.id, parent_id: clickedTableRow.parent_id, str_clm: "code" };
				setSearchFounds([navRow]);
				setCurSearchRow(navRow);
			}
		}
	}, [inputSearchValue, selectedSnbRow?.length]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (scrollBlock.current) {
			if (searchMode === "flat") {
				scrollBlock.current.style.marginLeft = "500px";
				scrollBlock.current.style.maxWidth = "calc(100% - 500px)";
				scrollBlock.current.style.width = "calc(100% - 500px)";
			} else {
				scrollBlock.current.style.marginLeft = "0px";
				scrollBlock.current.style.maxWidth = "100%";
				scrollBlock.current.style.width = "100%";
			}
		}
	}, [searchMode]);

	useEffect(() => {
		setFilterTrigger((prev) => prev + 1);
	}, [selectedSnbRow?.length]);

	const handleScroll = () => {
		if (tabValue !== "norm") return;
		if (searchMode !== "flat") return;
		if (fetchStatus === "loading") return;
		const { scrollTop, clientHeight, scrollHeight } = scrollBlock.current;
		const diff = Math.abs(scrollHeight - scrollTop - clientHeight);
		let allRowsDataCount = searchFoundsStats.__ALL__;

		const limitWork = getEnvVal("FsnbFlatSearchLimit");

		if (!allRowsDataCount) allRowsDataCount = limitWork;

		if (diff < 100 && filterLimit < allRowsDataCount && filterLimit + limitWork - searchFoundsStats.__DATA_COUNT__ <= limitWork) {
			setFilterLimit((prev) => prev + limitWork);
			setFilterTrigger((prev) => prev + 1);
		}
	};

	const moveNextSearch = (direction) => {
		const curIndex = searchFounds.findIndex((x) => x.id === curSearchRow.id && x.res_id === curSearchRow.res_id);
		let newIndex = -1;
		if (direction === "prev" && curIndex > 0) {
			newIndex = curIndex - 1;
		} else if (direction === "next" && curIndex < searchFounds.length) {
			newIndex = curIndex + 1;
		}
		setCurSearchRow({ ...searchFounds[newIndex] });
	};

	const showMainTableMore = (row) => {
		if (row?.row_type === "group" || ["norm_name_group", "fake_parent"].includes(String(row?.row_type_style))) return;
		if (tabValue === "coef") {
			showTableMore(row, "12px", ["name"], setTableData, "FsnbBodyRightCoef");
		} else {
			showTableMore(row, "12px", ["name"], setTableData, "FsnbBodyRight");
		}
	};

	const fetchPDF = (rowId) => {
		setPdfData(apiFsnb.getTechPartPdfStrLink(tabValue, rowId));
	};

	function openTechPartPdfFile() {
		apiFsnb
			.getTechPartPdfFile(tabValue, inputEl?.current?.id)
			.then((response) => {
				var file = new Blob([response.data], {
					type: "application/pdf",
				});
				var fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	const addToBlackList = () => {
		setFetchStatus("loading");
		let model_name;
		let name = clickedTableRow?.name;
		if (tabValue === "mat") {
			model_name = "FsnbFssc";
		} else if (tabValue === "mach") {
			model_name = "FsnbFsem";
		} else if (tabValue === "norm") {
			model_name = "FsnbNorm";
			if (clickedTableRow?.name_group) {
				name = `${clickedTableRow?.name_group} ${name}`;
			}
		}
		const prom = apiUserAdv.addToBlackList({
			prefix: clickedTableRow?.prefix == null ? "" : clickedTableRow?.prefix,
			code: clickedTableRow?.code,
			name: name,
			unit: clickedTableRow?.measure_unit,
			model_name: model_name,
		});
		prom.then((response) => {
			setFetchStatus("successAlert");
			setFetchStatusText(`Норматив ${clickedTableRow?.code} добавлен в исключения`);
			setTimeout(() => {
				setFetchStatus("success");
			}, 1300);
		}).catch((error) => {
			console.error(error);
			setFetchStatus("failed");
		});
	};

	const delFromBlackList = ({ clickedRow }) => {
		if (!clickedRow) return;
		setFetchStatus("loading");
		const prom = apiUserAdv.delFromBlackList(clickedRow.id);
		prom.then((response) => {
			setFetchStatus("successAlert");
			setFetchStatusText(`Норматив ${clickedRow?.code} удален в исключения`);
			setTimeout(() => {
				setFetchStatus("success");
			}, 1300);
		}).catch((error) => {
			console.error(error);
		});
	};

	let showContent = false;
	if (fsnbVersArr.length) {
		showContent = true;
	}

	return (
		<>
			<RibbonContent
				ribbonSettings={FsnbRibbonSettings({
					fsnbVersArr: fsnbVersArr,
					selectedFsnbVers: selectedFsnbVers,
					setSelectedFsnbVers: setSelectedFsnbVers,
					attrsOpts: attrsOpts,
					selectedSearchOptions: selectedSearchOptions,
					setSelectedSearchOptions: setSelectedSearchOptions,
					inputSearchValue: inputSearchValue,
					fetchStatus: fetchStatus,
					setInputSearchValue: setInputSearchValue,
					moveNextSearch: moveNextSearch,
					searchFounds: searchFounds,
					setFsnbBodyContent: setFsnbBodyContent,
					setSuperSearchView: setSuperSearchView,
					tabValue: tabValue,
					handleSearch: handleSearch,
					clickedTableRow: clickedTableRow,
					lowerCallbacks: lowerCallbacks,
					superSearchView: superSearchView,
					history: props.history,
					windowWidth: windowWidth,
					setBlackListVisible: setBlackListVisible,
					addToBlackList: addToBlackList,
					delFromBlackList: delFromBlackList,
					searchObjScope: searchObjScope,
					setSearchObjScope: setSearchObjScope,
					selectedTreeData: selectedTreeData,
					searchMode: searchMode,
					setSearchMode: setSearchMode,
					setFilterTrigger: setFilterTrigger,
					// snbTreeDrawerVisible: snbTreeDrawerVisible,
					// setSnbTreeDrawerVisible: setSnbTreeDrawerVisible,
				})}
			/>

			{showContent && (
				<div className={superSearchView ? "FsnbHead FsnbSuperSearchHead" : "FsnbHead"}>
					{!superSearchView && (
						<FsnbNavigation
							history={props.history}
							tabValue={tabValue}
							fsnbBodyContent={fsnbBodyContent}
							f_openTechPartPdfFile={openTechPartPdfFile}
							f_changeTabValue={changeTabValue}
							f_setTreeData={setTreeData}
							f_setTableData={setTableData}
							f_setFsnbBodyContent={setFsnbBodyContent}
						/>
					)}
				</div>
			)}

			{superSearchView ? (
				<FsnbSuperSearchBody
					history={props.history}
					selectedFsnbVers={selectedFsnbVers}
					inputSearchValue={inputSearchValue}
					f_setFetchStatus={setFetchStatus}
					f_setFetchStatusText={setFetchStatusText}
					f_setSuperSearchView={setSuperSearchView}
					f_setSearchFounds={setSearchFounds}
					f_setInputSearchValue={setInputSearchValue}
					f_setSelectedTreeData={setSelectedTreeData}
				/>
			) : (
				<FsnbBody
					treeData={treeData}
					selectedTreeData={selectedTreeData}
					tableData={tableData}
					tabValue={tabValue}
					curSearchRow={curSearchRow}
					selectedFsnbVers={selectedFsnbVers}
					fsnbBodyContent={fsnbBodyContent}
					pdfData={pdfData}
					fetchStatus={fetchStatus}
					lowerCallbacks={lowerCallbacks}
					searchObjScope={searchObjScope}
					f_setSelectedTreeData={setSelectedTreeData}
					f_setTreeData={setTreeData}
					f_changeChildsVisible={handleTreeClick}
					f_setTableData={setTableData}
					f_setFetchStatus={setFetchStatus}
					f_setFetchStatusText={setFetchStatusText}
					f_showMainTableMore={showMainTableMore}
					f_handleTreeClick={handleTreeClick}
					f_setSearchObjScope={setSearchObjScope}
					searchMode={searchMode}
					inputSearchValue={inputSearchValue}
					scrollBlock={scrollBlock}
					handleScroll={handleScroll}
					setInputSearchValue={setInputSearchValue}
					// snbTreeDrawerVisible={snbTreeDrawerVisible}
				/>
			)}

			{blackListVisible && (
				<Suspense>
					<BlackListModal setVisible={setBlackListVisible} setFetchStatus={setFetchStatus} delFromBlackList={delFromBlackList} />
				</Suspense>
			)}

			{searchMode === "flat" && (
				<Suspense>
					<SnbTreeDrawer
						visible={true}
						selectedFsnbVers={selectedFsnbVers}
						selectedSnbRow={selectedSnbRow}
						setSelectedSnbRow={setSelectedSnbRow}
					/>
				</Suspense>
			)}

			<AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} isLocal={true} />
			<Footer footerItemSettings={FsnbFooterSettings({ searchMode, searchFoundsStats, selectedSnbRow })} />
		</>
	);
}

import { getRowHeightMultiplier } from "components/Table/utils/row_utils.js";

const calcRowHeightMultiplier = (row, columns_data) => {
    // if (row.model_name === "LocalEstimateCostCoefficients") return 1;

    let diff = -30; //padding
    const fontSize = "13px";
    if (row.res_has_tech_gpr) diff -= 40;
    if (row.cost_has_coef) diff -= 30;
    if (row._children != null) diff -= 20;
    if (row.model_name === "LocalEstimateRimSecSumDirect") diff -= 20;
    if (row.is_item) {
        diff -= 40;
    } else {
        if (["LocalEstimatesResMaterial", "LocalEstimatesResMachine"].includes(row.model_name)) {
            diff -= 40;
        }
    }

    const realWidthName =
        parseInt(getComputedStyle(document.querySelector("th[clm='name']")).width.replace("px", "")) + diff;
    const realWidthCode =
        parseInt(getComputedStyle(document.querySelector("th[clm='code']")).width.replace("px", "")) + diff;

    const rowHeightMultiplierName = getRowHeightMultiplier(row, columns_data, ["name"], fontSize, 1.25, realWidthName);
    const rowHeightMultiplierCode = getRowHeightMultiplier(row, columns_data, ["code"], fontSize, 0.75, realWidthCode);
    let rowHeightMultiplier = 1;

    if (rowHeightMultiplierName >= rowHeightMultiplierCode) {
        rowHeightMultiplier = rowHeightMultiplierName;
    } else {
        rowHeightMultiplier = rowHeightMultiplierCode;
    }

    if (row.model_name === "LocalEstimateCostCoefficients") {
        if (rowHeightMultiplier < 5) {
            const codeWidth =
                parseInt(getComputedStyle(document.querySelector("th[clm='code']")).width.replace("px", "")) + diff;
            rowHeightMultiplier = getRowHeightMultiplier(row, columns_data, ["code"], fontSize, 1.25, codeWidth);
        } else {
            rowHeightMultiplier = parseInt(rowHeightMultiplier / 2.5);
        }
    }

    return rowHeightMultiplier;
};

export { calcRowHeightMultiplier };

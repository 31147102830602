import { createSlice } from "@reduxjs/toolkit";
import { searchDataRecourse, changeRowDataRecourse } from "./utils";

export const katsEditorSlice = createSlice({
	name: "katsEditorSlice",
	initialState: {
		data: {},
		tableData: [],
		visibleSettings: {},
		cancelActions: [],
		editorMode: "",
		clickedRowBeforeNav: {},
		fetchStatus: null,
		fetchRibbonStatus: null,
		fetchStatusText: null,
		prevCell: { key: "", value: "" },
		selectedItems: [],
		fileLinksMap: {},
		lastPressedEvent: {},
	},
	reducers: {
		setData: (state, { payload }) => {
			state.data = payload;
		},
		setTableData: (state, { payload }) => {
			state.tableData = payload;
		},
		setVisibleSettings: (state, { payload }) => {
			state.visibleSettings = payload;
		},
		setEditorMode: (state, { payload }) => {
			state.editorMode = payload;
		},
		setFetchStatus: (state, { payload }) => {
			state.fetchStatus = payload;
		},
		setFetchStatusText: (state, { payload }) => {
			state.fetchStatusText = payload;
		},
		setFetchRibbonStatus: (state, { payload }) => {
			state.fetchRibbonStatus = payload;
		},
		changeRowData: (state, { payload: { rowKey, callback } }) => {
			const t0 = performance.now();
			state.tableData = changeRowDataRecourse(state.tableData, rowKey, callback);
			const t1 = performance.now();
			if (t1 - t0 > 1) console.log(`Call to changeRowData took ${t1 - t0} milliseconds.`);
		},
		changeVisible: (state, { payload: { val, row } }) => {
			state.tableData = searchDataRecourse(state.tableData, row["key"], val);
		},
		setCancelActions: (state, { payload }) => {
			state.cancelActions = payload;
		},
		setPrevCell: (state, { payload }) => {
			state.prevCell = payload;
		},
		setFileLinksMap: (state, { payload }) => {
			state.fileLinksMap = payload;
		},
		setLastPressedEvent: (state, { payload }) => {
			state.lastPressedEvent = payload;
		},
	},
});

export const {
	setData,
	setTableData,
	setEditorMode,
	setFetchStatus,
	setFetchStatusText,
	setFetchRibbonStatus,
	setVisibleSettings,
	changeRowData,
	changeVisible,
	setCancelActions,
	setPrevCell,
	setFileLinksMap,
	setLastPressedEvent,
} = katsEditorSlice.actions;

export default katsEditorSlice.reducer;

export default function FsscTableColumns() {
    return [
        {
            name: 'code',
            title: 'Код',
            width: '100px',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'name',
            title: 'Наименование',
            width: 'auto',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'measure_unit',
            title: 'Ед. изм',
            width: '70px',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'opt_cost',
            title: 'Отпускная цена, руб.',
            width: '185px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'cost',
            title: 'Сметная цена, руб.',
            width: '185px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        }
    ];
}
import { fsnbCheckAreaEnum } from "./const";

import KsprButtonWithOption from "components/UI/buttons/KsprButtonWithOption";

export default function MonitorDetailButtonWrapper({
    f_setRequestBody,
    typeBtn,
    f_onBtnClick,
    options,
    f_setModalOpen,
    disabled,
    ksprBtnTitle,
    noMarginLeft,
    f_setAndCheckInputBorders,
}) {
    const handleMenuItemClick = (value) => {
        f_setRequestBody(value, typeBtn);
        if (value === fsnbCheckAreaEnum.SELECTED_ESTMS) {
            if (f_setAndCheckInputBorders() || typeBtn === "btnFsnb") {
                f_setModalOpen(true);
            }
        }
    };

    const handleStartBtnClick = () => {
        f_setRequestBody(fsnbCheckAreaEnum.CURRENT_ESTM, typeBtn);
    };

    return (
        <KsprButtonWithOption
            f_onBtnClick={f_onBtnClick}
            options={options}
            f_onMenuItemClick={handleMenuItemClick}
            f_handleStartBtnClick={handleStartBtnClick}
            disabled={disabled}
            ksprBtnTitle={ksprBtnTitle}
            noMarginLeft={noMarginLeft}
        />
    );
}

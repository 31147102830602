import React, { useState } from 'react';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

import "./PopupBox.css";

export default function PopupBox(props) {
    const title = props.title;
    const [selectedId, setSelectedId] = useState(null);
    let w = "50%";
    let h = "30%";
    if (props["width"] != null) { w = props["width"]; }
    if (props["height"] != null) { h = props["height"]; }

    const genRows = (data) => {
        let rows = [];
        for (let x of data) {
            let _className = "PopupBoxTRow";
            if (x["id"] === selectedId) {
                _className = "PopupBoxTRow Selected";
            }
            let rowContent = x[props.data_clm];
            if (props.f_delete != null && x["id"] === selectedId) {
                rowContent = (
                    <div className="CellWithIcon">
                        <div className="CellText">{x[props.data_clm]}</div>
                        <div className="CellIcon">
                            <CloseIcon onClick={() => {
                                props.f_delete(x)
                            }}>
                            </CloseIcon>
                        </div>
                    </div>
                )
            }
            rows.push(
                <div className={_className}
                    onClick={() => {
                        setSelectedId(x["id"])
                    }}
                    title={x[props.data_clm]}
                    key={x["id"]}>
                    {rowContent}
                </div>
            )
        }
        return rows;
    }

    let body = [];
    if (props["bodyType"] === "one-clm-table") {
        body.push(
            <div className="PopupBoxThead" key="thead">
                {props.theadTitle}
            </div>
        )
        body.push(
            <div className="PopupBoxTRows" key="rows">
                {genRows(props.data)}
            </div>
        )

    }

    return (
        <div className="PopupBox"
            style={{ width: w, height: h }}>
            <div className="PopupTitle">{title}</div>
            <div className="PopupBody">
                {body}
            </div>
            <div className="PopupAct">
                <Button size="small"
                    onClick={() => { props.f_popup_visb(false) }}>
                    Закрыть</Button>
            </div>
        </div >
    );
}
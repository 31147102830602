import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";

import "./KsprTextBoxWithSupLabels.css";

const KsprTextBoxWithSupLabels = ({ obj }) => {
    const [value, setValue] = useState("");

    useEffect(() => {
        if (obj.value) setValue(obj.value);
    }, [obj.value]);

    return (
        <div className="KsprTextBoxWithSupLabels">
            <div className="MainInput">
                <TextField
                    id="standard-multiline-flexible"
                    label={obj.label}
                    variant="standard"
                    value={value}
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div className="SubLabels">
                {obj._children.map((child) => (
                    <div className="SubLabel" key={child.key}>
                        {child.label}: {child.value}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default KsprTextBoxWithSupLabels;

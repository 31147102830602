import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLocalStorage } from "hooks/useLocalStorage";

import Rows from "./Rows";
import TableHeaders from "./Header/TableHeaders";
import { TableDefaultOptions } from "./TableDefaultOptions";

import "./TableMain.css";

export default function TableMain(props) {
	// const t0 = performance.now();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [scrollTop, setScrollPosition] = useState(0);
	const [scrollElementIsRender, setScrollElementIsRender] = useState(false);
	const { getLsItem, setLsItem, removeLsItem } = useLocalStorage();

	const tableOptions = {
		...TableDefaultOptions,
		...props.options,
	};

	const ROWS_DISPLAY_COUNT = tableOptions.rowsDisplayCount;
	const ROW_HEIGHT = tableOptions.rowHeight;
	const SCROLL_HEIGHT_CONTROLL = tableOptions.scrollHeightControll;
	const ADDITINAL_HEADER_HEIGHT = tableOptions.addH;
	const DISPLAY_SCROLL_DELAY = tableOptions.displayScrollDelay;

	let scrollElement;

	if (props.options["scrollBlock"] != null) scrollElement = props.options["scrollBlock"]["current"];
	if (scrollElement != null && scrollElementIsRender === false) setScrollElementIsRender(true);

	const onScroll = (e) => {
		const scrollTopEvent = e.target.scrollTop;
		setScrollPosition((prevState) => {
			if (Math.abs(scrollTopEvent - prevState) > SCROLL_HEIGHT_CONTROLL) {
				if (scrollTopEvent >= 0) {
					const LocScrCnt = getLsItem({ key: "scrollCnt", _type: "int" }) + 1;
					setLsItem({ key: "scrollCnt", value: LocScrCnt });
					// if (LocScrCnt > 1 && props.options["tableType"] != null) {
					//     removeLsItem({ key: "displayScrollTop" });
					// }
					// if (LocScrCnt > 1) {
					removeLsItem({ key: "displayScrollTop" });
					// }
				}
				return scrollTopEvent;
			} else {
				return prevState;
			}
		});
	};

	useEffect(() => {
		if (scrollElement != null) {
			scrollElement.addEventListener("scroll", onScroll);
			setLsItem({ key: "scrollCnt", value: 0 });
			return () => scrollElement.removeEventListener("scroll", onScroll);
		}
	}, [scrollElementIsRender, props.rows]); // eslint-disable-line react-hooks/exhaustive-deps

	const columnsFlat = useMemo(() => {
		let columnsFlatM = [];
		for (let x of props.columns) {
			if (x["children"] === undefined) {
				columnsFlatM.push(x);
			} else {
				for (let child of x["children"]) {
					columnsFlatM.push(child);
				}
			}
		}
		return columnsFlatM;
	}, [props.columns]); // eslint-disable-line react-hooks/exhaustive-deps

	const TableHeaderMemo = useMemo(() => {
		return (
			<TableHeaders
				sortParams={props.sortParams}
				filterParams={props.filterParams}
				filterValues={props?.filterValues}
				columns={props.columns}
				func={props.func}
				tableData={props.rows?.data}
				options={tableOptions}
			/>
		);
	}, [props]); // eslint-disable-line react-hooks/exhaustive-deps

	const rows = useMemo(() => {
		if (props.rows == null) return [];
		return Rows({
			tableData: props.rows?.data,
			columns: props.columns,
			columnsFlat: columnsFlat,
			func: props.func,
			options: tableOptions,
			dispatch: dispatch,
			navigate: navigate,
			childLevel: 0,
		});
	}, [props, columnsFlat]); // eslint-disable-line react-hooks/exhaustive-deps

	let rowsSlice = [];
	let lazyTopDom;
	let lazyBottomDom;

	if (rows != null) {
		let LAZY_START_INDEX;
		const savedScrollTop = getLsItem({ key: "displayScrollTop", _type: "int" });
		if (savedScrollTop !== 0 && scrollElement != null && props.options["tableType"] != null) {
			const scrollElementHeight = scrollElement.offsetHeight;
			const seekScrollTop = savedScrollTop * ROW_HEIGHT + ADDITINAL_HEADER_HEIGHT - scrollElementHeight / 3;
			setTimeout(() => {
				scrollElement.scrollTop = seekScrollTop;
			}, 500);

			LAZY_START_INDEX = parseInt(seekScrollTop / ROW_HEIGHT);
		} else {
			LAZY_START_INDEX = parseInt(scrollTop / ROW_HEIGHT);
		}
		if (LAZY_START_INDEX < 0) LAZY_START_INDEX = 0;

		rowsSlice = rows.slice(0, LAZY_START_INDEX + ROWS_DISPLAY_COUNT);

		const lazyPaddingBottom = rows.length * ROW_HEIGHT - rowsSlice.length * ROW_HEIGHT;
		lazyBottomDom = <div style={{ paddingBottom: lazyPaddingBottom + "px" }}></div>;

		if (LAZY_START_INDEX > DISPLAY_SCROLL_DELAY) {
			rowsSlice = rows.slice(LAZY_START_INDEX - DISPLAY_SCROLL_DELAY, LAZY_START_INDEX + ROWS_DISPLAY_COUNT);
			const lazyPaddingTop = (LAZY_START_INDEX - DISPLAY_SCROLL_DELAY) * ROW_HEIGHT;
			lazyTopDom = (
				<tr>
					<td style={{ paddingTop: lazyPaddingTop + "px", border: 0, paddingBottom: 0 }}></td>
				</tr>
			);
		}
	}

	// const t1 = performance.now();
	// if (t1 - t0 > 1) console.log(`Table Main took ${t1 - t0} milliseconds.`);

	return (
		<div>
			<table className="TableMain">
				{TableHeaderMemo}
				<tbody className="TableBody">
					{lazyTopDom}
					{rowsSlice}
				</tbody>
			</table>
			{lazyBottomDom}
		</div>
	);
}

import { useDispatch, useSelector } from "react-redux";

import apiLocalEstimateEditor from "api/local_estimate_editor";
import { setTableData, setCancelActions, changeRowData, setVisibleSettings, setData } from "slices/local_estimate_editor";

import { getNestedIterableElementsCountWithBreak } from "core/iterable_utils";
import { setClickedTableRow } from "slices/ribbon";
import { genConstValue } from "pages/EstimateEditor/const";


const useSectionEditor = ({ estmId, _setFetchStatus, scrollBlock, setConfirmContent }) => {
    const dispatch = useDispatch();
    const tableData = useSelector((state) => state.localEstimateEditor.tableData);
    const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);

    const setFetchStatus = (data) => dispatch(_setFetchStatus(data));

    const enableAddSectionMode = () => {
        let indexOfEstmPrices = tableData.map((x) => x.id).indexOf(0);
        if (indexOfEstmPrices === -1) indexOfEstmPrices = 0;
        const newSec = genSectionEmptyRow();
        const tableCopy = [
            ...tableData.slice(0, indexOfEstmPrices),
            newSec,
            ...tableData.slice(indexOfEstmPrices),
        ];
        dispatch(setTableData(tableCopy));
        dispatch(
            changeRowData({
                rowKey: clickedTableRow?.key,
                callback: (row) => (row.selected = false),
            })
        );
        const scrollElmIndex = getNestedIterableElementsCountWithBreak(tableCopy, (row) => {
            return row.visible !== false || row.show_always === true;
        }, (row) => {
            return row.key === newSec.key;
        }, (row) => {
            return row.childs_visible !== false || row.model_name === "CostParentLabel";
        });
        const rowsBeforeCnt = 4;
        const scrollPosition = genConstValue("rowHeight") * scrollElmIndex - genConstValue("rowHeight") * rowsBeforeCnt;
        scrollBlock.current.scrollTop = scrollPosition;
    };

    const addSection = ({ name }) => {
        if (name === "") {
            dispatch(setTableData(tableData.filter(row => row.is_new_input_section !== true)));
            return
        }
        setFetchStatus("loading");
        const prom = apiLocalEstimateEditor.addSection({ estmId, body: { name: name, clicked_row_section_key: clickedTableRow?.key } });
        prom.then((response) => {
            console.log(response);
            dispatch(setData(response.data.estm_data.data));
            dispatch(setTableData(response.data.estm_data.table_data));
            dispatch(setCancelActions(response.data.actions_data));
            dispatch(setVisibleSettings(response.data.visible_settings));
            setFetchStatus("success");
        }).catch((err) => {
            console.error(err);
            setFetchStatus("failed");
        });
    };

    const editSection = ({ sectionId, name }) => {
        dispatch(
            changeRowData({
                rowKey: clickedTableRow.key,
                callback: (row) => (row.name = name),
            })
        );

        setFetchStatus("loading");
        const prom = apiLocalEstimateEditor.editSection({ estmId, sectionId, body: { name: name } });
        prom.then((response) => {
            console.log(response);
            dispatch(setData(response.data.estm_data.data));
            dispatch(setTableData(response.data.estm_data.table_data));
            dispatch(setCancelActions(response.data.actions_data));
            dispatch(setVisibleSettings(response.data.visible_settings));
            setFetchStatus("success");
        }).catch((err) => {
            console.error(err);
            setFetchStatus("failed");
        });
    };


    const deleteSection = ({ sectionId, sectionKey }) => {
        setFetchStatus("loading");
        const prom = apiLocalEstimateEditor.deleteSection({ estmId, sectionId });
        prom.then((response) => {
            console.log(response);
            dispatch(setData(response.data.estm_data.data));
            dispatch(setTableData(response.data.estm_data.table_data));
            dispatch(setCancelActions(response.data.actions_data));
            dispatch(setVisibleSettings(response.data.visible_settings));
            setFetchStatus("success");
        }).catch((err) => {
            console.error(err);
            setFetchStatus("failed");
        });
    };

    const moveSection = ({ sectionId, direction }) => {
        setFetchStatus("loading");
        const prom = apiLocalEstimateEditor.moveSection({ estmId, sectionId, direction });
        prom.then((response) => {
            console.log(response);
            dispatch(setData(response.data.estm_data.data));
            dispatch(setTableData(response.data.estm_data.table_data));
            dispatch(setCancelActions(response.data.actions_data));
            dispatch(setVisibleSettings(response.data.visible_settings));
            setFetchStatus("success");
        }).catch((err) => {
            console.error(err);
            setFetchStatus("failed");
        });
    };

    const showDeleteSectionConfirm = ({ section }) => {
        setConfirmContent({
            label: "Удаление",
            text: `Удалить выбранный раздел (${section.num} ${section.name}) ?`,
            labelOk: "Удалить",
            callback: () => {
                deleteSection({ sectionId: section.id, sectionKey: section.key });
                dispatch(setClickedTableRow(null));
            },
        })
    };

    const showHideSectionChilds = ({ rowObj, visibleStatus }) => {
        dispatch(
            changeRowData({
                rowKey: rowObj.key,
                callback: (row) => {
                    row.childs_visible = visibleStatus;
                    row?._children.forEach(ch => ch.visible = visibleStatus);
                    return row;
                },
            })
        );
    }

    const genSectionEmptyRow = () => {
        return {
            id: null,
            key: "section_0--localestimaterimsections-0",
            num: "",
            name: "",
            colSpan: 9,
            row_type: "group",
            model_name: "LocalEstimateRimSections",
            colSpanTarget: "name",
            childs_visible: true,
            hideColumns: getHideColumns(),
            inline_cell: "name",
            is_new_input_section: true
        };
    };

    const getHideColumns = () => {
        return [
            "code",
            "unit",
            "cost",
            "consumption",
            "consumption_coef",
            "consumptiontotal",
            "priceperunitbase",
            "index_value",
            "priceperunitcur",
            "cost_coef",
        ];
    };

    return {
        enableAddSectionMode, addSection, editSection,
        showDeleteSectionConfirm, moveSection, showHideSectionChilds
    };
};

export { useSectionEditor };

import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import LocalPrintshopTwoToneIcon from "@mui/icons-material/LocalPrintshopTwoTone";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LowPriorityOutlinedIcon from "@mui/icons-material/LowPriorityOutlined";

const EstimateViewerContextMenuProjectTree = ({ contextMenuObj, callbacks }) => {
	return [
		{
			name: "Протокол",
			subgroups: [
				{
					name: "Subgroup",
					items: [
						{
							label: "Экспорт протокола",
							icon: <FileOpenOutlinedIcon />,
							type: "common",
							callback: () => {
								callbacks.exportProtocolContextMenu({ clickedRow: contextMenuObj });
								callbacks.closeContextMenu();
							},
							disabled: false,
						},
					],
				},
			],
		},
		{
			name: "Печатная форма",
			subgroups: [
				{
					name: "Subgroup",
					items: [
						{
							label: "Печать документа",
							icon: <PrintOutlinedIcon />,
							type: "common",
							callback: () => {
								callbacks.printDocContextMenu(false);
								callbacks.closeContextMenu();
							},
							disabled: false,
						},
						{
							label: "Печать с ошибками",
							icon: <LocalPrintshopTwoToneIcon />,
							type: "common",
							callback: () => {
								callbacks.printDocContextMenu(true);
								callbacks.closeContextMenu();
							},
							disabled: !["local", "kats"].includes(contextMenuObj.type),
						},
					],
				},
			],
		},
		{
			name: "",
			subgroups: [
				{
					name: "Subgroup",
					items: [
						{
							label: "Экспорт в XML",
							icon: <FileDownloadOutlinedIcon />,
							type: "common",
							callback: () => {
								callbacks.exportXmlDocContextMenu({ clickedRow: contextMenuObj });
								callbacks.closeContextMenu();
							},
							disabled: false,
						},
						{
							label: "Комментарий",
							icon: <FileOpenOutlinedIcon />,
							type: "common",
							callback: () => {
								callbacks.showProjectTreeComment({ targetRow: contextMenuObj });
								callbacks.closeContextMenu();
							},
							disabled: contextMenuObj.type === "summary",
						},
						{
							label: "Удалить документ",
							icon: <DeleteOutlinedIcon />,
							type: "common",
							callback: () => {
								callbacks.deleteDocContextMenu({ cascade: true });
								callbacks.closeContextMenu();
							},
							disabled: contextMenuObj.type === "summary",
						},
						{
							label: "Удалить документ без ЛСР",
							icon: <DeleteOutlinedIcon />,
							type: "common",
							callback: () => {
								callbacks.deleteDocContextMenu({ cascade: false });
								callbacks.closeContextMenu();
							},
							isVisible: contextMenuObj.type === "object",
						},
						{
							label: "Скопировать документ",
							icon: <ContentCopyOutlinedIcon />,
							type: "common",
							callback: () => {
								callbacks.copyEstimateDoc();
								callbacks.closeContextMenu();
							},
							disabled: !["local"].includes(contextMenuObj.type),
						},
						{
							label: "Сделать актуальным",
							icon: <LowPriorityOutlinedIcon />,
							type: "common",
							callback: () => {
								callbacks.makeLsrDoubleWinner();
								callbacks.closeContextMenu();
							},
							isVisible: (contextMenuObj.type === "local") & (contextMenuObj?.double_is_winner === false),
						},
					],
				},
			],
		},
	];
};

export { EstimateViewerContextMenuProjectTree };

import { useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";

import Settings from "@mui/icons-material/Settings";

import "./CompletenessFilter.css";

const CompletenessFilter = ({ optionValues, curSelected, setCurSelected }) => {
	const [open, setOpen] = useState(false);

	const handleSelectClick = () => setOpen(!open);

	const handleChange = (row) => {
		setCurSelected((prev) => {
			const inArray = prev.find((x) => x.id === row.id);
			if (inArray == null) {
				return [...prev, row];
			} else {
				return prev.filter((x) => x.id !== row.id);
			}
		});
	};

	const renderSelValue = (selected) => {
		const chipLst = [];
		for (let x of selected) {
			if (x != null) {
				chipLst.push(<Chip key={x.id} label={x.code} />);
			}
		}
		return chipLst;
	};

	return (
		<div className="CompletenessFilter TextBoxModifyMui">
			<TextField
				sx={{ "& legend": { fontSize: "9px" } }}
				label={"Фильтр по типу ошибок"}
				size="small"
				select
				SelectProps={{
					open: open,
					onClose: handleSelectClick,
					onOpen: handleSelectClick,
					multiple: true,
					value: curSelected,
					renderValue: renderSelValue,
					IconComponent: Settings,
				}}
			>
				{optionValues.map((opt) => (
					<MenuItem
						key={opt.id}
						title={opt.name}
						parent_component="CompletenessFilter"
						onClick={(e) => handleChange(opt)}
						selected={curSelected.filter((x) => x.id === opt.id).length === 1}
					>
						<Checkbox checked={curSelected.filter((x) => x.id === opt.id).length === 1}></Checkbox>
						<ListItemText primary={opt.name} />
					</MenuItem>
				))}
			</TextField>
		</div>
	);
};

export default CompletenessFilter;

import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { Droppable, Draggable } from "react-beautiful-dnd";
import "./CustomDndCell.css";

const CustomDndCell = ({ rowObj }) => {
	const getCellInnerDom = () => {
		if (rowObj.model_name === "UserProjectPacks") {
			return (
				<Droppable key={String(rowObj.key)} droppableId={String(rowObj.key)}>
					{(provided, snapshot) => (
						<div
							className="DroppableCell"
							ref={provided.innerRef}
							{...provided.droppableProps}
							is_drag_over={String(snapshot.isDraggingOver)}
						>
							<FolderOpenOutlinedIcon />
							<div style={{ display: "none" }}>{provided.placeholder}</div>
						</div>
					)}
				</Droppable>
			);
		} else if (rowObj.model_name === "UserProjects") {
			return (
				<Droppable key={String(rowObj.key)} droppableId={`UserProjects_${rowObj.key}`}>
					{(provided, snapshot) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<Draggable key={rowObj.key} draggableId={String(rowObj.key)} index={rowObj.id == null ? 0 : rowObj.id}>
								{(provided) => (
									<div className="DraggableCell" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
										<DragHandleOutlinedIcon />
									</div>
								)}
							</Draggable>
							<div style={{ display: "none" }}>{provided.placeholder}</div>
						</div>
					)}
				</Droppable>
			);
		}
	};

	return (
		<td key={`cell-dnd-${rowObj.id}`} className="TableCell" clm="dnd" treecell="false">
			{getCellInnerDom()}
		</td>
	);
};

export default CustomDndCell;

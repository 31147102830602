import { apiBase } from "./base";

const apiCalcer = {
	client: apiBase.get_client(),
	url: "/calc/",

	getIndex(pz, per, obj) {
		return this.client.get(this.url + "index_direct/" + pz + "/" + per + "/" + obj + "/", apiBase.get_headers());
	},

	getIndexDefault(pz, per) {
		return this.client.get(this.url + "index_direct/" + pz + "/" + per + "/", apiBase.get_headers());
	},

	getIndexSmr(pz, per, obj) {
		return this.client.get(this.url + "index_smr/" + pz + "/" + per + "/" + obj + "/", apiBase.get_headers());
	},

	getIndexSmrDefault(pz, per) {
		return this.client.get(this.url + "index_smr/" + pz + "/" + per + "/", apiBase.get_headers());
	},

	getOtherIndex(per, eqp) {
		return this.client.get(this.url + "index_other_direct/" + per + "/" + eqp + "/", apiBase.get_headers());
	},
	getOtherIndexDefault(per) {
		return this.client.get(this.url + "index_other_direct/" + per + "/", apiBase.get_headers());
	},

	getEqpIndex(per, eqp) {
		return this.client.get(this.url + "index_eqp_direct/" + per + "/" + eqp + "/", apiBase.get_headers());
	},

	getEqpIndexDefault(per) {
		return this.client.get(this.url + "index_eqp_direct/" + per + "/", apiBase.get_headers());
	},

	getPgIndex(pz, per) {
		return this.client.get(this.url + "index_pg_direct/" + pz + "/" + per + "/", apiBase.get_headers());
	},

	getDefaultGosrIndexes(resType, per, pz, orgId) {
		return this.client.get(`${this.url}gosr_resource_group/${resType}/${per}/${pz}/${orgId}/`, apiBase.get_headers());
	},

	getGosrChildren(id) {
		return this.client.get(`${this.url}gosr_resource_by_group/${id}/`, apiBase.get_headers());
	},

	getGosrFilter(body) {
		return this.client.post(this.url + "gosr_resource_filter/", body, apiBase.get_headers());
	},

	getObjectTypes() {
		return this.client.get(this.url + "objects_type/", apiBase.get_headers());
	},

	getPeriods() {
		return this.client.get(this.url + "period/", apiBase.get_headers());
	},

	getPriceZones() {
		return this.client.get(this.url + "price_zone/", apiBase.get_headers());
	},

	getIndustryTypes() {
		return this.client.get(this.url + "industry_type/", apiBase.get_headers());
	},

	getVehicleTypes() {
		return this.client.get(this.url + "vehicle_type/", apiBase.get_headers());
	},

	getCargoClass() {
		return this.client.get(this.url + "cargo_class/", apiBase.get_headers());
	},

	getIndustryOrganization() {
		return this.client.get(this.url + "industry_organization/", apiBase.get_headers());
	},

	getRoadType() {
		return this.client.get(this.url + "road_types/", apiBase.get_headers());
	},

	getGosrGroupTypes() {
		return this.client.get(this.url + "gosr_resource_types_group/", apiBase.get_headers());
	},

	getTruckingCls() {
		return this.client.get(this.url + "trucking_cls/", apiBase.get_headers());
	},

	getNorthRegTerrs() {
		return this.client.get(this.url + "north_reg_terrs/", apiBase.get_headers());
	},

	getAutoTruckingTariff(pz, per, vh, orgId) {
		return this.client.get(`${this.url}auto_trucking_tariff/${pz}/${per}/${vh}/${orgId}/`, apiBase.get_headers());
	},

	getAutoTruckingTariffByCode(pz, per, orgId, code) {
		return this.client.get(`${this.url}auto_trucking_tariff_by_code/${pz}/${per}/${orgId}/${code}/`, apiBase.get_headers());
	},

	getAutoLoadingTariff(pz, per, orgId) {
		return this.client.get(`${this.url}auto_loading_tariff/${pz}/${per}/${orgId}/`, apiBase.get_headers());
	},

	getRailTruckingTariff(per, cargo) {
		return this.client.get(`${this.url}rail_trucking_tariff/${per}/${cargo}/`, apiBase.get_headers());
	},

	getRailCargoNames() {
		return this.client.get(`${this.url}rail_cargo_names/`, apiBase.get_headers());
	},

	getRecommendedIndexes(pz, obj, ind, dateStr, useDirectSmrPer) {
		return this.client.get(`${this.url}index_on_date/${pz}/${obj}/${ind}/${dateStr}/${useDirectSmrPer}/`, apiBase.get_headers());
	},

	getPerPzRimDateStart({ pz }) {
		return this.client.get(`${this.url}per_pz_rim_map/${pz}/`, apiBase.get_headers());
	},
};

export default apiCalcer;

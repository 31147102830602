import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

import { changeFileKeyValue, getXsdErrors, hideFileListDetails, getXsdEstmErrorsExcel } from "slices/import_files";

import TableRowStepper from "./TableRowStepper";
import TableRowDetails from "./TableRowDetails";
import ImportEmptyPlaceholder from "./ImportEmptyPlaceholder";

import apiSummaryEstimate from "api/summary_estimate";
import apiObjectEstimate from "api/object_estimate";

import "./ImportTableBody.css";

export default function ImportTableBody({ tableTab, importExpanded }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scrollBlock = useRef(null);
    const filesList = useSelector((state) => state.importFiles.filesList);
    const fetchFilesList = useSelector((state) => state.importFiles.fetchFilesList);

    const { id: projectId } = useParams();

    const priceLevelOptions = [
        {
            id: "null",
            name: "",
        },
        {
            id: "base",
            name: "Базисный",
        },
        {
            id: "current",
            name: "Текущий",
        },
    ];

    const getFormatDate = (dateString) => {
        if (dateString) {
            const options = { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" };
            const date = new Date(dateString);
            return date.toLocaleDateString("ru-RU", options);
        }
    };

    const getLinearProgress = (f) => {
        if ([0, 1].includes(f.status)) {
            return <LinearProgress value={0} />;
        } else if ([2, 3].includes(f.status)) {
            return <LinearProgress variant="determinate" import_status={f.status} value={100} />;
        }
    };

    const handleChangeRowKeyValue = ({ rowObj, key, value }) => {
        let prom;
        if (rowObj?.estm_type === "ССРСС") {
            prom = apiSummaryEstimate.edit(rowObj.object_id, { price_level: value.id });
        } else if (rowObj?.estm_type === "ОСР") {
            prom = apiObjectEstimate.edit(rowObj.object_id, { price_level: value.id });
        }

        if (!prom) return;

        prom.then((response) => {
            dispatch(changeFileKeyValue({ objKey: rowObj.key, key: key, value: value }));
        });
    };

    const showErrors = (row) => dispatch(getXsdErrors(row.id));
    const hideErrors = (row) => dispatch(hideFileListDetails(row));

    if (fetchFilesList === 0 && filesList?.length === 0) return <ImportEmptyPlaceholder />;
    if (tableTab === "errors" && filesList?.filter((x) => x.status === 3 || x.status === 1).length === 0) {
        return <ImportEmptyPlaceholder />;
    }

    return (
        <div className="Layout">
            <div className="ImportXmlBrainStormTable">
                <div className="TableHead">
                    <div className="TableHeadRow">
                        <div className="TableHeadCell" clm="File">
                            Файл
                        </div>
                        <div className="TableHeadCell" clm="Schema">
                            Схема XSD
                        </div>
                        <div className="TableHeadCell" clm="Progress">
                            Процесс проверки
                        </div>
                    </div>
                </div>
                <div className="TableBody" ref={scrollBlock}>
                    {importExpanded &&
                        filesList.map((f) => (
                            <div key={f.key == null ? f.id : f.key}>
                                {tableTab === "all" || f.status === 3 || f.status === 1 ? (
                                    <>
                                        <div
                                            className="TableRow"
                                            key={f.key == null ? f.id : f.key}
                                            test_key={f.key == null ? f.id : f.key}>
                                            <div className="TableCell" clm="File">
                                                <div className="NameProgress">
                                                    <div
                                                        className="FileName"
                                                        onClick={() => {
                                                            if (f.status === 2 && f.schema.includes("LocalEstimate")) {
                                                                navigate(`/project/${projectId}/lsr/${f.object_id}/`);
                                                            }
                                                        }}>
                                                        {f.name}
                                                    </div>
                                                    <div className="FileProgress">{getLinearProgress(f)}</div>
                                                    <div className="FileDateCreate">{getFormatDate(f.date_create)}</div>
                                                </div>
                                            </div>
                                            <div className="TableCell" clm="Schema">
                                                <div className="SchemaText">
                                                    <span>{f.schema}</span>
                                                    <span style={{ fontWeight: 600 }}>
                                                        {f.estm_type != null ? ` (${f.estm_type})` : ""}
                                                    </span>
                                                </div>
                                                <div className="SchemaTextPriceLevel">
                                                    {["ССРСС", "ОСР"].includes(String(f?.estm_type)) && (
                                                        <TextField
                                                            select
                                                            size="small"
                                                            label=""
                                                            value={f.price_level?.id}
                                                            variant="standard"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        Уровень цен
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onChange={(e) => {
                                                                handleChangeRowKeyValue({
                                                                    rowObj: f,
                                                                    key: "price_level",
                                                                    value: priceLevelOptions.find(
                                                                        (x) => x.id === e.target.value
                                                                    ),
                                                                });
                                                            }}>
                                                            {priceLevelOptions.map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="TableCell" clm="Progress">
                                                <TableRowStepper
                                                    row={f}
                                                    projectId={projectId}
                                                    f_showErrors={showErrors}
                                                    f_hideErrors={hideErrors}
                                                />
                                            </div>
                                        </div>
                                        {f._details != null ? (
                                            <TableRowDetails
                                                rows={f._details}
                                                f_downloadExcelError={() => dispatch(getXsdEstmErrorsExcel(f.id))}
                                                rowId={f.id}
                                                scrollBlock={scrollBlock}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

import { setPanelSearchPrevKey } from "slices/ribbon";

import { searchNestedIterableItem, copyArr } from "core/iterable_utils";
import { PageParams } from "./page_params";

const RibbonPanelMoveToNumFactory = ({ currentPage, arg, tableData, updateTableData, scrollBlock, dispatch, panelSearchPrevKey }) => {
	let curTableData;
	let curUpdateTableData;

	let isSeekRowCallback = (row) => {
		if (String(row.num) === arg) return true;
		return false;
	};
	let isPermittedRowCallback = (row) => {
		if (row.visible !== false) return true;
		return false;
	};
	let isPermittedChildsLoopCallback = (row) => {
		if (row.childs_visible !== false || row.model_name === "CostParentLabel") return true;
		return false;
	};

	let rowHeight = 22;
	let rowsBeforeCnt = 2;
	let headerHeight = 0;

	if (currentPage === "LocalEstimateViewer") {
		curTableData = tableData.local;
		curUpdateTableData = updateTableData.local;
		isSeekRowCallback = (row) => {
			if (String(row.num) === arg && row.is_item === "1") return true;
			return false;
		};
		headerHeight = PageParams().local.headerHeight;
		rowsBeforeCnt = PageParams().local.rowsBeforeCnt;
	} else if (currentPage === "SummaryEstimateViewer") {
		curTableData = tableData.summary;
		curUpdateTableData = updateTableData.summary;
		headerHeight = PageParams().summary.headerHeight;
	} else if (currentPage === "ObjectEstimateViewer") {
		curTableData = tableData.object;
		curUpdateTableData = updateTableData.object;
		headerHeight = PageParams().object.headerHeight;
	} else if (currentPage === "KatsEstimateViewer") {
		curTableData = tableData.kats;
		curUpdateTableData = updateTableData.kats;
		headerHeight = PageParams().kats.headerHeight;
		rowsBeforeCnt = PageParams().kats.rowsBeforeCnt;
	} else if (currentPage === "KatsProjectEstimateViewer") {
		curTableData = tableData.kats;
		curUpdateTableData = updateTableData.kats;
		headerHeight = PageParams().kats.headerHeight;
		rowsBeforeCnt = PageParams().kats.rowsBeforeCnt;
	} else if (currentPage === "StartEstimateViewer") {
	} else if (currentPage === "ProtocolViewer") {
		curTableData = tableData.protocol;
		curUpdateTableData = updateTableData.protocol;
		headerHeight = PageParams().protocol.headerHeight;
		rowsBeforeCnt = PageParams().protocol.rowsBeforeCnt;
	} else if (currentPage === "KatsEditor") {
		curTableData = tableData.katsEditor;
		curUpdateTableData = updateTableData.katsEditor;
		headerHeight = PageParams().protocol.headerHeight;
		rowsBeforeCnt = PageParams().protocol.rowsBeforeCnt;
		rowHeight = PageParams().katsEditor.rowHeight;
	}

	if (!curTableData) return;
	if (!arg || arg === "") return;

	const { seekItem, seekCount } = searchNestedIterableItem({
		iterable: copyArr(curTableData),
		isSeekRowCallback: isSeekRowCallback,
		isPermittedRowCallback: isPermittedRowCallback,
		isPermittedChildsLoopCallback: isPermittedChildsLoopCallback,
		useRowHeightMultiply: true,
		breakAfterFirstMatch: true,
	});

	if (panelSearchPrevKey) {
		dispatch(
			curUpdateTableData({
				rowKey: panelSearchPrevKey,
				callback: (row) => (row.advSelected = ""),
			})
		);
	}

	if (!seekItem) return;
	const scrollPosition = rowHeight * seekCount - rowHeight * rowsBeforeCnt + headerHeight;

	dispatch(
		curUpdateTableData({
			rowKey: seekItem.key,
			callback: (row) => (row.advSelected = "full_row"),
		})
	);
	dispatch(setPanelSearchPrevKey(seekItem.key));

	if (scrollBlock.current) {
		scrollBlock.current.scrollTo({
			top: scrollPosition,
		});
	}
};

export default RibbonPanelMoveToNumFactory;

export default function IndexesDirectTableColumns() {
    return [
        {
            name: 'objects_type__name',
            title: 'Объект строительства',
            width: 'auto',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'ot_index',
            title: 'Оплата труда',
            width: '170px',
            cellHorAlign: 'right',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'mat_index',
            title: 'Материалы, изделия и конструкции',
            width: '170px',
            cellHorAlign: 'right',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'mech_index',
            title: 'Эксплуатация машин и механизмов',
            width: '170px',
            cellHorAlign: 'right',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'letter__name',
            title: '№ письма МС',
            width: '200px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        }
    ];
}
import React, { useState, useEffect, useRef } from 'react';

import FsnbFilterVersSelect from './FsnbFilterVersSelect';
import TableMain from "components/Table/TableMain";
import NrspTableColumns from '../TableSetting/NrspTableColumns';
import NrspCoefTableColumns from '../TableSetting/NrspCoefTableColumns';
import apiFsnb from 'api/fsnb';

import "./FsnbNrspSubBox.css";
import "./FsnbAutocomplete.css";

export default function FsnbNrspSubBox(props) {
    const [nrspPerArr, setNrspPerArr] = useState([]);
    const [selectedNrspPer, setSelectedNrspPer] = useState({});
    const [tableData, setTableData] = useState([]);
    const [tableCoefsData, setTableCoefsData] = useState([]);
    const scrollBlock = useRef(null);
    const columnsData = NrspTableColumns();
    const columnsCoefData = NrspCoefTableColumns();

    /*eslint-disable */
    useEffect(() => {
        props.setFetchStatus("loading");
        apiFsnb.getNrpsInterVersPeriod(props.vers.id)
            .then(response => {
                setNrspPerArr(response.data);
                setSelectedNrspPer(response.data[0]);
            }).then(data => {
                props.setFetchStatus("success");
            }).catch(error => {
                console.error(error);
                props.setFetchStatus("failed");
                if (error?.response?.data?.detail != null) {
                    props.setFetchStatusText(error.response.data.detail);
                } else {
                    props.setFetchStatusText(error);
                }
            });
    }, [])

    useEffect(() => {
        if (selectedNrspPer?.id == null) return
        props.setFetchStatus("loading");
        apiFsnb.getNrspOnNorm(selectedNrspPer.id, props.row.id)
            .then(response => {
                setTableData(response.data.map((row, index) => {
                    if (index === 0) row.selected = true;
                    return row
                }));
                props.setNrspRowsCount(response.data.length)
            }).then(data => {
                props.setFetchStatus("success");
            }).catch(error => {
                console.error(error);
                props.setFetchStatus("failed");
                if (error?.response?.data?.detail != null) {
                    props.setFetchStatusText(error.response.data.detail);
                } else {
                    props.setFetchStatusText(error);
                }
            });
    }, [selectedNrspPer])

    useEffect(() => {
        const selected = tableData.find(row => row.selected === true);
        if (selected) {
            setTableCoefsData(selected?.coefs)
        }
    }, [tableData])

    /*eslint-enable */

    const selectDataLocal = (tableData, clickedRow) => {
        setTableData(prev => prev.map(row => {
            if (row.id === clickedRow.id) {
                row.selected = true
            } else {
                row.selected = false
            }
            return row
        }));
    };

    return (
        <div className="FsnbNrpsBox" style={{ height: props.getHeight("nrsp_and_coef") }}>
            <div className="FsnbCoefBoxHeader">
                <div className="FsnbCoefBoxHeaderLabel">Нормативы НР и СП</div>
                {nrspPerArr.length > 0 &&
                    <div className="FsnbFilters">
                        <FsnbFilterVersSelect
                            label="Номера приказов и период действия"
                            fsnbVersArr={nrspPerArr}
                            selectedFsnbVers={selectedNrspPer}
                            f_setSelectedFsnbVers={setSelectedNrspPer}
                        />
                    </div>
                }
            </div>
            <div className="FsnbNrpsBoxBody" style={{ height: props.getHeight("nrsp") }} >
                {nrspPerArr.length > 0 &&
                    <div className="FsnbNrpsTableLayout" ref={scrollBlock}>
                        <TableMain
                            rows={{ "data": tableData }}
                            columns={columnsData}
                            options={{
                                "scrollBlock": scrollBlock,
                                "displayScrollDelay": 20,
                                "funcCall": "local"
                            }}
                            func={{
                                selectDataLocal: selectDataLocal,
                                updateData: setTableData,
                            }}
                        ></TableMain>
                    </div>
                }
            </div>
            <div className="FsnbCoefSubBoxHeader">
                <div className="FsnbCoefBoxHeaderLabel">Коэффициенты к НР и СП</div>
            </div>
            <div className="FsnbNrpsSubBoxBody" style={{ height: props.getHeight("nrsp_coef") }}>
                {nrspPerArr.length > 0 &&
                    <div className="FsnbNrpsTableLayout" ref={scrollBlock}>
                        <TableMain
                            rows={{ "data": tableCoefsData }}
                            columns={columnsCoefData}
                            options={{
                                "scrollBlock": scrollBlock,
                                "displayScrollDelay": 20,
                                "funcCall": "local"
                            }}
                            func={{
                            }}
                        ></TableMain>
                    </div>
                }
            </div>
        </div>
    );
}
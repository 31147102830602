import { createSlice } from "@reduxjs/toolkit";

export const windowDimensionsSlice = createSlice({
    name: "windowDimensions",
    initialState: {
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        headerHeight: 150,
    },
    reducers: {
        setWindowWidth: (state, { payload }) => {
            state.windowWidth = payload;
        },
        setWindowHeight: (state, { payload }) => {
            state.windowHeight = payload;
        },
    },
});

export const { setWindowWidth, setWindowHeight } = windowDimensionsSlice.actions;

export default windowDimensionsSlice.reducer;

import { useState, useEffect, useLayoutEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";

import "./FilterGroupSelect.css";
import KsprButton from "components/UI/buttons/KsprButton";

import { copyArr } from "core/iterable_utils";

import { setSelectTypeData } from "slices/protocol";

export default function FilterGroupSelect({ selectTypeData, saveFiltredDataGrp, dispatch }) {
	const userPermissions = useSelector((state) => state.auth.permissions);
	const [open, setOpen] = useState(false);
	const [curSelected, setCurSelected] = useState([]);
	const indeterminateColor = "#707070";

	const { id } = useParams();

	useEffect(() => {
		initSelectedGroups();
	}, [selectTypeData]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		return () => setCurSelected([]);
	}, [id]); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() => {
		initSelectedGroups();
	}, [id]); // eslint-disable-line react-hooks/exhaustive-deps

	const hasPermission = (obj) => obj.step_id === 1 || userPermissions.is_expert;

	const getNewCheckedValue = (checked) => {
		if (checked === true) {
			return false;
		} else if (checked === false) {
			return true;
		} else if (checked == null) {
			return true;
		}
	};

	const initSelectedGroups = () => {
		const tempArr = [];
		for (let step of selectTypeData) {
			for (let group of step._children) {
				if (group.checked && !tempArr.find((x) => x.key === group.key)) {
					tempArr.push(group.key);
				}
			}
		}
		setCurSelected(tempArr);
	};

	const handleStepClick = ({ obj }) => {
		const copy = copyArr(selectTypeData);
		for (let step of copy) {
			if (step.id === obj.id) {
				step.checked = getNewCheckedValue(step.checked);
				step.indeterminate = false;
				for (let group of step._children) {
					group.checked = step.checked;
					group.indeterminate = false;
					for (let type of group._children) {
						type.checked = step.checked;
					}
				}
			}
		}
		dispatch(setSelectTypeData(copy));
	};

	const changeGroupChildsVisible = ({ obj }) => {
		const copy = copyArr(selectTypeData);
		for (let step of copy) {
			if (step.id === obj.step_id) {
				for (let group of step._children) {
					if (group.id === obj.id) {
						group._childrenVisible = getNewCheckedValue(group._childrenVisible);
					}
				}
			}
		}
		dispatch(setSelectTypeData(copy));
	};

	const setIndeterminate = ({ obj }) => {
		const childrenCount = obj._children.length;
		const checkedChildrenCount = obj._children.filter((ch) => ch.checked === true).length;
		if (checkedChildrenCount === 0) {
			obj.checked = false;
			obj.indeterminate = false;
		} else if (checkedChildrenCount > 0 && checkedChildrenCount < childrenCount) {
			obj.checked = false;
			obj.indeterminate = true;
		} else if (checkedChildrenCount === childrenCount) {
			obj.checked = true;
			obj.indeterminate = false;
		}
	};

	const handleObjClick = ({ obj }) => {
		const copy = copyArr(selectTypeData);
		for (let step of copy) {
			if (step.id === obj.step_id) {
				for (let group of step._children) {
					if (obj.entety === "group") {
						if (group.id === obj.id) {
							group.checked = getNewCheckedValue(group.checked);
							group.indeterminate = false;
							for (let type of group._children) type.checked = group.checked;
						}
					} else if (obj.entety === "type") {
						if (group.id === obj.group_id) {
							for (let type of group._children) {
								if (type.id === obj.id) type.checked = getNewCheckedValue(type.checked);
							}
							setIndeterminate({ obj: group });
						}
					}
				}
			}
			setIndeterminate({ obj: step });
		}
		dispatch(setSelectTypeData(copy));
	};

	const getGroupChildsButton = (group) => {
		let icon;
		if (group._childrenVisible) {
			icon = <KeyboardArrowDownIcon />;
		} else {
			icon = <KeyboardArrowRightIcon />;
		}
		return (
			<span
				className="GroupChildsButton"
				onClick={(e) => {
					e.preventDefault();
					changeGroupChildsVisible({ obj: group });
				}}
			>
				{icon}
			</span>
		);
	};

	const getTypeList = ({ group }) => {
		if (!group._childrenVisible) return;
		return group._children.map((type) => {
			return (
				<Fragment key={type.key}>
					<FormControlLabel
						entety="type"
						label={type.name}
						title={type.name}
						disabled={!hasPermission(group)}
						control={
							<Checkbox
								size="small"
								disabled={!hasPermission(group)}
								checked={type.checked == null ? false : type.checked}
								indeterminate={false}
								onChange={() => handleObjClick({ obj: type })}
							/>
						}
					/>
				</Fragment>
			);
		});
	};

	const getGroupList = (groups) => {
		return groups.map((group) => {
			return (
				<Fragment key={group.key}>
					<FormControlLabel
						entety="group"
						label={
							<span>
								{getGroupChildsButton(group)}
								<span>{group.name}</span>
							</span>
						}
						disabled={!hasPermission(group)}
						title={group.name}
						control={
							<Checkbox
								size="small"
								disabled={!hasPermission(group)}
								checked={group.checked == null ? false : group.checked}
								indeterminate={group.indeterminate == null ? false : group.indeterminate}
								indeterminateIcon={<CheckBoxIcon sx={{ color: indeterminateColor }} />}
								onChange={() => handleObjClick({ obj: group })}
							/>
						}
					/>
					{getTypeList({ group })}
				</Fragment>
			);
		});
	};

	const getStepList = () => {
		return (
			<div>
				{selectTypeData.map((step) => {
					return (
						<Fragment key={step.key}>
							<FormControlLabel
								entety="step"
								label={step.name}
								title={step.name}
								disabled={!hasPermission(step)}
								control={
									<Checkbox
										size="small"
										checked={step.checked == null ? false : step.checked}
										disabled={!hasPermission(step)}
										indeterminate={step.indeterminate == null ? false : step.indeterminate}
										indeterminateIcon={<CheckBoxIcon sx={{ color: indeterminateColor }} />}
										onChange={() => handleStepClick({ obj: step })}
									/>
								}
							/>
							{getGroupList(step?._children)}
						</Fragment>
					);
				})}
			</div>
		);
	};

	const renderSelValue = () => {
		const chipLst = [];
		for (let groupKey of curSelected) {
			chipLst.push(<Chip key={groupKey} label={groupKey} />);
		}
		return chipLst;
	};

	const handleSelectClick = () => setOpen(!open);

	return (
		<div className="GroupSelect">
			<div className="SessionSelect">
				<TextField
					type="SessionSelect"
					size="small"
					label="Тип проверки"
					sx={{
						width: 400,
						"& legend": { fontSize: "9px" },
					}}
					select
					SelectProps={{
						open: open,
						multiple: true,
						value: curSelected,
						onOpen: handleSelectClick,
						onClose: (e) => {
							saveFiltredDataGrp();
							setOpen(false);
						},
						renderValue: renderSelValue,
					}}
				>
					{getStepList()}
					<KsprButton
						sx={{ ml: "auto" }}
						variant="text"
						label="сохранить"
						onClick={(e) => {
							saveFiltredDataGrp();
							setOpen(false);
						}}
					/>
				</TextField>
			</div>
		</div>
	);
}

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { Button, Divider, IconButton, LinearProgress, Tooltip, FormControlLabel, Checkbox } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

import apiAdvSearch from "api/adv_search";
import {
	applyAdvSearchSelected as applyAdvSearchSelectedLocalEstimate,
	clearAdvSearchSelected as clearAdvSearchSelectedLocalEstimate,
	setAdvSearchSelected as setAdvSearchSelectedLocalEstimate,
} from "slices/local_estimate";

import {
	setAdvSearchSelected as setAdvSearchSelectedKats,
	clearAdvSearchSelected as clearAdvSearchSelectedKats,
	applyAdvSearchSelected as applyAdvSearchSelectedKats,
} from "slices/kats";

import { setTreeClicked } from "slices/project_work";

import { setActiveRow, setActiveColorRow } from "slices/adv_search";
import { RecourseCalcPosition } from "./apply_adv_search";
import { arrProxy } from "core/iterable_utils";
import { downloadFile } from "core/request_utils";

import AdvSearchBoxTextBox from "./AdvSearchBoxTextBox";
import AdvSearchResultBox from "./AdvSearchResultBox";
import AdvSearchInput from "./AdvSearchInput";

import "./AdvSearchBox.css";
import "components/TextBoxModifyMui.css";

export default function AdvSearchBox({ visible, mode, setAdvSearchBoxVisible, treeScrollBlock }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const treeData = useSelector((state) => state.projectWork.tree);
	const treeSelected = useSelector((state) => state.projectWork.treeSelected);
	const treeClicked = useSelector((state) => state.projectWork.treeClicked);

	const [searchText, setSearchText] = useState("");
	const [searchEstms, setSearchEstms] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [fetchStatus, setFetchStatus] = useState(null);
	const [treeOpts, setTreeOpts] = useState([null]);

	const [searchTextisError, setSearchTextisError] = useState(false);
	const [searchAttrsisError, setSearchAttrsisError] = useState(false);
	const [searchEstmisError, setSearchEstmisError] = useState(false);
	const [sortByRating, setSortByRating] = useState(false);
	const [changeScopeCnt, setChangeScopeCnt] = useState(0);

	const { id } = useParams();
	const location = useLocation();

	const attrsOpts = [
		{ id: 1, name: "Наименование расценки" },
		{ id: 2, name: "Наименование ресурса" },
		{ id: 3, name: "Шифр расценки" },
		{ id: 4, name: "Код ресурса" },
		{ id: 5, name: "Наименование поправки" },
		{ id: 13, name: "Обоснование поправки" },
		// { "id": 6, "name": "Состав работ" },
		{ id: 7, name: "Наименование нормативов НР и СП" },
		{ id: 8, name: "Расход расценки" },
		{ id: 9, name: "Расход ресурса" },
		{ id: 14, name: "Итоги расценки" },
		{ id: 10, name: "Позиция итоги" },
		{ id: 11, name: "Стоимость ресурса" },
		{ id: 12, name: "Стоимость НРСП" },
	];

	const [searchAttrs, setSearchAttrs] = useState(attrsOpts.filter((x) => [1, 2, 3, 4].includes(x.id))); // []

	const treeOptsTemp = [{ id: "all", name: "Искать по всему проекту", _children: [] }, ...createTreeOpts(treeData)];

	const patternOpts = [
		{ id: 1, name: "Отрицательные стоимости в позиции", pattern: "__NEGATIVE_COSTS__" },
		{ id: 2, name: "Ресурсы, отсутствующие в ФРСН", pattern: "__RESOURCE_TC__" },
		{ id: 3, name: "Перевозка", pattern: "__RESOURCE_PG__" },
		{ id: 4, name: "Оборудование", pattern: "__RESOURCE_EQP__" },
	];
	const [selectedPatternOpts, setSelectedPatternOpts] = useState([]);

	/*eslint-disable */
	useEffect(() => {
		const activeRow = searchResults.find((x) => x.active === true);

		if (activeRow != null) {
			dispatch(setActiveRow(JSON.parse(JSON.stringify(activeRow))));
			dispatch(setActiveColorRow(JSON.parse(JSON.stringify(activeRow))));
			if (activeRow.id !== id) {
				const locationSplit = location["pathname"].split("/").filter((x) => x !== "");
				const projectIndex = locationSplit.indexOf("project");

				if (activeRow.type === "Kats") {
					navigate(`/${locationSplit.slice(0, projectIndex + 2).join("/")}/kats/${activeRow.estm_id}/`);
				} else {
					navigate(`/${locationSplit.slice(0, projectIndex + 2).join("/")}/lsr/${activeRow.estm_id}/`);
				}
			}
		}
	}, [searchResults]);

	useEffect(() => {
		if (!treeData.length) return;

		if (treeSelected.length) {
			if (searchEstms.length === 0) {
				setSearchEstms(initSelectTreeOpts(treeSelected[0].id, treeSelected[0].type, treeOptsTemp));
			} else if (treeClicked.id != null) {
				setSearchEstms(initSelectTreeOpts(treeClicked.id, treeClicked.type, treeOptsTemp));
				dispatch(setTreeClicked({}));
			}

			if (visible) {
				const { rowCnt, isFound } = RecourseCalcPosition(treeData, treeSelected[0], {
					rowCnt: 0,
					isFound: false,
				});
				treeScrollBlock.current.scrollTop = rowCnt * 20 - 20;
			}
		}
		setTreeOpts(treeOptsTemp);
	}, [treeData, treeSelected, treeClicked]);
	/*eslint-enable */

	useEffect(() => {
		setSearchResults([]);
		setChangeScopeCnt(0);
	}, [mode]);

	useEffect(() => {
		if (visible && mode === "rating" && searchEstms && searchEstms.length > 0 && changeScopeCnt > 0) {
			executeRating({ format: "json" });
		}
	}, [mode, visible, searchEstms, changeScopeCnt]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleChange = (e) => {
		setSearchText((prev) => e.target.value);
	};

	const execute = () => {
		if (mode === "search") {
			executeSearch({ format: "json" });
		} else if (mode === "rating") {
			executeRating({ format: "json" });
		}
	};

	const executeSearch = ({ format }) => {
		setSearchResults([]);

		let textErr = false;
		let atrErr = false;
		let estmErr = false;

		if (searchText.toString().length < 3 && selectedPatternOpts.length === 0) {
			textErr = true;
		}
		if (searchAttrs.length === 0) {
			atrErr = true;
		}
		if (searchEstms.length === 0) {
			estmErr = true;
		}

		setSearchTextisError(textErr);
		setSearchAttrsisError(atrErr);
		setSearchEstmisError(estmErr);

		if (textErr || atrErr || estmErr) {
			return;
		}

		setFetchStatus("loading");
		const body = {
			project_id: id,
			search_text: searchText,
			local_estms: searchEstms.filter((x) => x.type === "local").map((x) => x.db_id),
			kats_estms: searchEstms
				.filter((x) => x.type === "kats")
				.map((x) => x.db_id)
				.filter((x) => x != null),
			search_attrs: searchAttrs.map((x) => x.id),
			sort_by_value: sortByRating,
			pattern: selectedPatternOpts?.pattern,
		};
		dispatch(clearAdvSearchSelectedLocalEstimate());
		dispatch(clearAdvSearchSelectedKats());
		apiAdvSearch
			.strSearch(body)
			.then((response) => {
				setSearchResults((prev) => response.data);
				dispatch(setAdvSearchSelectedLocalEstimate(JSON.parse(JSON.stringify(response.data))));
				dispatch(setAdvSearchSelectedKats(JSON.parse(JSON.stringify(response.data))));
				dispatch(applyAdvSearchSelectedLocalEstimate());
				dispatch(applyAdvSearchSelectedKats());
				if (format === "xlsx") {
					const xlsxBody = { ...body, format: "xlsx" };
					apiAdvSearch.strSearchXlsx(xlsxBody).then((resp) => {
						downloadFile(resp);
						setFetchStatus("success");
					});
				} else {
					setFetchStatus("success");
				}
			})
			.catch((error) => {
				console.error(error);
				setFetchStatus("failed");
			})
			.finally(() => {
				setSelectedPatternOpts([]);
			});
	};

	const executeRating = ({ format }) => {
		let estmErr = false;
		if (searchEstms.length === 0) {
			estmErr = true;
		}
		setSearchEstmisError(estmErr);
		if (estmErr) return;

		setFetchStatus("loading");
		const body = {
			project_id: id,
			local_estms: searchEstms.filter((x) => x.type === "local").map((x) => x.db_id),
		};
		dispatch(clearAdvSearchSelectedLocalEstimate());
		dispatch(clearAdvSearchSelectedKats());
		const prom = apiAdvSearch.itemsRating(body);
		prom.then((response) => {
			setSearchResults((prev) => response.data);
			dispatch(setAdvSearchSelectedLocalEstimate(JSON.parse(JSON.stringify(response.data))));
			dispatch(applyAdvSearchSelectedLocalEstimate());
			if (format === "xlsx") {
				const xlsxBody = { ...body, format: "xlsx" };
				apiAdvSearch.itemsRatingXlsx(xlsxBody).then((resp) => {
					downloadFile(resp);
					setFetchStatus("success");
				});
			} else {
				setFetchStatus("success");
			}
		}).catch((error) => {
			console.error(error);
			setFetchStatus("failed");
		});
	};

	const genLoader = () => {
		if (fetchStatus === "loading") {
			return (
				<div className="LinearProgressWrap">
					<LinearProgress />
				</div>
			);
		} else if (fetchStatus === "success") {
			return (
				<div className="LinearProgressWrap">
					<LinearProgress variant="determinate" value={100} color="success" />
				</div>
			);
		} else if (fetchStatus === "failed") {
			return (
				<div className="LinearProgressWrap">
					<LinearProgress variant="determinate" value={100} color="error" />
				</div>
			);
		} else if (fetchStatus === null) {
			return <div className="LinearProgressWrap" style={{ height: "4px" }} />;
		}
	};

	let mainLabel = "";
	let buttonLabel = "";
	if (mode === "search") {
		mainLabel = "ПОИСК";
		buttonLabel = "ИСКАТЬ";
	} else if (mode === "rating") {
		mainLabel = "УДЕЛЬНЫЙ ВЕС ПОЗИЦИИ";
		buttonLabel = "СОРТИРОВАТЬ";
	}

	return (
		<div className="AdvSearchBox" mode={mode} style={{ maxHeight: 510 * visible + "px" }}>
			<Divider>
				<div className="AdvSearchLabel">{mainLabel}</div>
			</Divider>
			<div className="AdvSearchInputs TextBoxModifyMui">
				{mode === "search" && (
					<AdvSearchInput
						searchText={searchText}
						handleChange={handleChange}
						execute={execute}
						searchTextisError={searchTextisError}
						attrsOpts={patternOpts}
						selectedSearchOptions={selectedPatternOpts}
						setSelectedSearchOptions={setSelectedPatternOpts}
					/>
				)}
				<AdvSearchBoxTextBox
					label="Область поиска"
					boxType="tree"
					optionValues={treeOpts}
					curSelected={searchEstms}
					setCurSelected={setSearchEstms}
					isError={searchEstmisError}
					setChangeScopeCnt={setChangeScopeCnt}
				/>
				{mode === "search" && (
					<AdvSearchBoxTextBox
						label="Поиск по аттрибутам данных"
						boxType="flat"
						optionValues={attrsOpts}
						curSelected={searchAttrs}
						setCurSelected={setSearchAttrs}
						isError={searchAttrsisError}
					/>
				)}
			</div>
			<div className="AdvSearchBut">
				{mode === "search" && (
					<FormControlLabel
						control={<Checkbox value={sortByRating} onChange={(e) => setSortByRating((prev) => !prev)} />}
						label="Сортировать по удел. весу поз."
					/>
				)}
				<Button
					size="small"
					onClick={(e) => {
						if (mode === "rating") {
							executeRating({ format: "xlsx" });
						} else if (mode === "search") {
							executeSearch({ format: "xlsx" });
						}
					}}
				>
					ПЕЧАТЬ
				</Button>
				<div>
					<Button size="small" onClick={(e) => execute()}>
						{buttonLabel}
					</Button>
					<Tooltip
						title="Очистить"
						onClick={() => {
							dispatch(clearAdvSearchSelectedLocalEstimate());
							dispatch(clearAdvSearchSelectedKats());
							setSearchText("");
							setSearchEstms([]);
							setSearchResults([]);
						}}
					>
						<IconButton>
							<ClearIcon />
						</IconButton>
					</Tooltip>
				</div>
			</div>
			{genLoader()}
			<AdvSearchResultBox mode={mode} data={searchResults} f_data={setSearchResults} />
		</div>
	);
}

export function createTreeOpts(data, parentId = "") {
	const newData = [];
	if (data == null) {
		return [];
	}
	for (let row of data) {
		const newRow = {
			id: `${parentId}-${row.type}-${row.id}`,
			name: row.innertext,
			type: row.type,
			db_id: row.id,
			childs_visible: false,
		};
		if (row._children != null) {
			newRow._children = createTreeOpts(row._children, newRow.id);
		}
		newData.push(newRow);
	}
	return newData;
}

export function initSelectTreeOpts(rowId, rowType, treeOpts) {
	let objs = [];
	for (let ssr of treeOpts) {
		if (rowType === "summary") {
			if (ssr.db_id === rowId) {
				ssr.childs_visible = true;
				for (let osr of ssr?._children) {
					osr.childs_visible = true;
				}
				objs = [...ssr._children.flatMap((x) => x?._children).filter((x) => x !== undefined)];
				break;
			}
		}

		if (rowType === "kats") {
			for (let kats_child of arrProxy(ssr?._children)) {
				if (kats_child.db_id === rowId) {
					objs.push(kats_child);
				}
			}
		}

		for (let osr of arrProxy(ssr?._children)) {
			if (rowType === "object") {
				if (osr.db_id === rowId) {
					osr.childs_visible = true;
					ssr.childs_visible = true;
					if (osr._children == null) {
						objs = [];
					} else {
						objs = [...osr?._children];
					}
					break;
				}
			} else {
				for (let lsr of arrProxy(osr?._children)) {
					if (rowType === "local") {
						if (lsr.db_id === rowId) {
							objs.push(lsr);
							osr.childs_visible = true;
							ssr.childs_visible = true;
							break;
						}
					}
				}
			}
		}
	}
	return objs;
}

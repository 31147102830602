import { useSelector, useDispatch } from "react-redux";

import { closeConfirm, setAnswer, setConfirmCallback } from "../slices/confirm";

import "./ConfirmDialog.css";

export default function ConfirmDialog(props) {
    const dispatch = useDispatch();
    const labelText = useSelector((state) => state.confirm.label);
    const bodyText = useSelector((state) => state.confirm.text);
    const cancelText = useSelector((state) => state.confirm.labelCancel);
    const okText = useSelector((state) => state.confirm.labelOk);
    const visible = useSelector((state) => state.confirm.visible);
    const callback = useSelector((state) => state.confirm.callback);

    const handleClose = (val) => {
        dispatch(closeConfirm());
        dispatch(setAnswer(val));
    };

    const doOkAction = () => {
        handleClose(true);
        if (callback) {
            callback();
            dispatch(setConfirmCallback(null));
        } else if (props?.func) {
            props.func();
        }
    };

    return (
        <div className="Alert-dialog" style={{ display: { visible } }}>
            <div className="Alert-title">{labelText}</div>
            <div className="Alert-text">{bodyText}</div>
            <div className="Alert-actn">
                <div
                    className="Alert-cancel-but"
                    onClick={() => {
                        handleClose(false);
                    }}>
                    {cancelText}
                </div>
                <div className="Alert-ok-but" onClick={() => doOkAction()}>
                    {okText}
                </div>
            </div>
        </div>
    );
}

export default function FsemTableColumns2022() {
    return [
        {
            name: 'code',
            title: 'Код',
            width: '100px',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'name',
            title: 'Наименование',
            width: 'auto',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'measure_unit',
            title: 'Ед. изм',
            width: '60px',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'cost',
            title: 'Смет. стоим. без ОТМ',
            width: '95px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'salary_mach',
            title: 'ОТМ',
            width: '95px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'machinist_category',
            title: 'Средний разряд',
            width: '95px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'driver_code',
            title: 'Код среднего разряда',
            width: '95px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'labour_mach',
            title: 'Трудозатраты',
            width: '95px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'electricity',
            title: 'Затраты на э/э, кВт/ч',
            width: '95px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'electricity_cost',
            title: 'Затраты на э/э, руб.',
            width: '95px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'with_relocation',
            title: 'Перебазировка',
            width: '95px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        }
    ];
}
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import "./KpsrBreadcrumbs.css";

const KpsrBreadcrumbRowWrapper = ({ obj, sep, objArrCount, startIndex }) => {
	return (
		<span className="KpsrBreadcrumbRowWrapper">
			<span className="KpsrBreadcrumbRow">{obj.name}</span>
			{startIndex < objArrCount && <span className="KpsrBreadcrumbRow">{sep}</span>}
		</span>
	);
};

const KpsrBreadcrumbShowHiddenButton = ({ sep }) => {
	return (
		<span className="KpsrBreadcrumbShowHiddenButton">
			<MoreHorizOutlinedIcon />
			<span className="KpsrBreadcrumbRow">{sep}</span>
		</span>
	);
};

const KpsrBreadcrumbs = ({ objArr, diplayCount, sep = "", showHiddenCallback }) => {
	const objArrCount = objArr?.length ? objArr?.length : 0;

	const jsxs = [];
	let loopIndex = 0;
	let startIndex = 0;
	let endIndex = 0;
	let showHiddenButtonIsAdded = false;

	for (let obj of objArr) {
		startIndex = loopIndex + 1;
		endIndex = objArrCount - startIndex + 1;

		if (!diplayCount || startIndex <= diplayCount.left || endIndex <= diplayCount.right) {
			jsxs.push(<KpsrBreadcrumbRowWrapper key={obj.id} obj={obj} sep={sep} objArrCount={objArrCount} startIndex={startIndex} />);
		} else {
			if (!showHiddenButtonIsAdded) {
				jsxs.push(<KpsrBreadcrumbShowHiddenButton key={`sep${obj.id}`} sep={sep} />);
			}
		}

		loopIndex += 1;
	}

	return (
		<div
			className="KpsrBreadcrumbs"
			onClick={(e) => {
				if (showHiddenCallback) showHiddenCallback();
			}}
		>
			{jsxs}
		</div>
	);
};

export default KpsrBreadcrumbs;

export default function CoefTableColumns() {
    const clmWidth = {
        code: 170,
        tech_part_code: 90,
        decreeuse: 100,
        startuse: 75,
        worker: 30,
        labor_costs: 30,
        machine: 30,
        machinist: 30,
        machinist_labor_costs: 30,
        material: 30,
        name: 0,
    };

    const sumWidht =
        clmWidth.code +
        clmWidth.tech_part_code +
        clmWidth.decreeuse +
        clmWidth.startuse +
        clmWidth.worker +
        clmWidth.labor_costs +
        clmWidth.machine +
        clmWidth.machinist +
        clmWidth.machinist_labor_costs +
        clmWidth.material;

    const rightSideSizePercent = 0.7;
    clmWidth.name = window.innerWidth * rightSideSizePercent * 0.7 - sumWidht;
    if (clmWidth.name < 60) clmWidth.name = 60;

    return [
        {
            name: "code",
            title: "Код",
            width: `${clmWidth.code}px`,
            cellHorAlign: "left",
            headHorAlign: "left",
            sort: true,
            filter: true,
            editable: false,
        },
        {
            name: "tech_part_code",
            title: "Текст пункта",
            width: `${clmWidth.tech_part_code}px`,
            cellHorAlign: "left",
            headHorAlign: "left",
            sort: true,
            filter: false,
            editable: false,
        },
        {
            name: "decreeuse",
            title: "НПА",
            width: `${clmWidth.decreeuse}px`,
            cellHorAlign: "left",
            headHorAlign: "left",
            sort: true,
            filter: false,
            editable: false,
        },
        {
            name: "startuse",
            title: "Действует с",
            width: `${clmWidth.startuse}px`,
            cellHorAlign: "left",
            headHorAlign: "left",
            sort: true,
            filter: false,
            editable: false,
        },
        {
            name: "name",
            title: "Наименование",
            width: `${clmWidth.name}px`,
            cellHorAlign: "left",
            headHorAlign: "left",
            sort: true,
            filter: true,
            editable: false,
        },
        {
            name: "worker",
            title: "ОТ",
            width: `${clmWidth.worker}px`,
            cellHorAlign: "center",
            headHorAlign: "center",
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: "labor_costs",
            title: "ЗТ",
            width: `${clmWidth.labor_costs}px`,
            cellHorAlign: "center",
            headHorAlign: "center",
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: "machine",
            title: "ЭМ",
            width: `${clmWidth.machine}px`,
            cellHorAlign: "center",
            headHorAlign: "center",
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: "machinist",
            title: "ОТМ",
            width: `${clmWidth.machinist}px`,
            cellHorAlign: "center",
            headHorAlign: "center",
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: "machinist_labor_costs",
            title: "ЗТМ",
            width: `${clmWidth.machinist_labor_costs}px`,
            cellHorAlign: "center",
            headHorAlign: "center",
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: "material",
            title: "МАТ",
            width: `${clmWidth.material}px`,
            cellHorAlign: "center",
            headHorAlign: "center",
            sort: false,
            filter: false,
            editable: false,
        },
    ];
}

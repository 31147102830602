import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";


const EstimateViewerContextMenuProtocol = ({ contextMenuObj, callbacks }) => {
    return [
        {
            name: '',
            subgroups: [
                {
                    name: 'Subgroup',
                    items: [
                        // {
                        //     label: "Информация об ошибке",
                        //     icon: <ReportGmailerrorredOutlinedIcon />,
                        //     type: "common",
                        //     disabled: true,
                        //     callback: () => {
                        //         callbacks.closeContextMenu();
                        //     },
                        // },
                        {
                            label: "Скрыть похожие записи",
                            icon: <VisibilityOffOutlinedIcon />,
                            type: "common",
                            disabled: false,
                            callback: () => {
                                callbacks.lowerCallbacks.current.protocol["handleAddException"](contextMenuObj);
                                callbacks.closeContextMenu();
                            },
                        },
                        {
                            label: "Отобрать данные по типу",
                            icon: <FilterAltOutlinedIcon />,
                            type: "common",
                            disabled: false,
                            callback: () => {
                                callbacks.lowerCallbacks.current.protocol["filterThisTypeOnly"](contextMenuObj);
                                callbacks.closeContextMenu();
                            },
                        },
                        {
                            label: "Скрыть данные по типу",
                            icon: <FilterAltOffOutlinedIcon />,
                            type: "common",
                            disabled: false,
                            callback: () => {
                                callbacks.lowerCallbacks.current.protocol["hideThisType"](contextMenuObj);
                                callbacks.closeContextMenu();
                            },
                        }
                    ],
                },

            ],
        },
    ]
}

export { EstimateViewerContextMenuProtocol };
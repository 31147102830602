const genConstValue = (name) => {
    const objConst = {
        headerHeight: 220,
        headerTableHeight: 65,
        rowHeight: 22,
    };

    return objConst[name];
};

export { genConstValue };

import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { CircularProgress, Alert } from "@mui/material";

import "./AlertLoading.css";

export default function AlertLoading({ left = "50%", ...props }) {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(true);
	const [withFading, setWithFading] = useState(false);

	let errorText = "Действие недоступно";
	if (props["fetchStatusText"] != null) {
		errorText = JSON.stringify(props["fetchStatusText"]);
	} else if (props["fetchStatusRawText"] != null) {
		errorText = props["fetchStatusRawText"];
	}

	const close = () => {
		setVisible(false);
		if (props.func != null) {
			if (typeof props.func === "function") {
				if (props.isLocal) {
					props.func(null);
				} else {
					dispatch(props.func(null));
					clearFetchStatusText();
				}
			} else if (typeof props.func === "object") {
				for (let f of props.func) {
					if (props.isLocal) {
						f(null);
					} else {
						dispatch(f(null));
						clearFetchStatusText();
					}
				}
			}
		}
	};

	useEffect(() => {
		setVisible(true);
	}, [props]);

	useEffect(() => {
		const actionMousemove = (e) => {
			setWithFading(true);
			setTimeout(() => {
				setVisible(false);
				close();
			}, 5000);
		};

		if (["failed", "successAlert"].includes(props.fetchStatus)) {
			document.addEventListener("mousemove", actionMousemove, { once: true });
		}

		return () => {
			document.removeEventListener("mousemove", actionMousemove);
		};
	}, [props.fetchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	const clearFetchStatusText = () => {
		if (props.setFetchStatusText != null) {
			dispatch(props.setFetchStatusText(null));
		}
	};

	return (
		<div className="ProjectViewStatusContainers" style={{ left: left }} onClick={() => close()}>
			{visible && (
				<div>
					{props.fetchStatus === "failed" && (
						<Alert severity="info" with_fading={String(withFading)}>
							{errorText}
						</Alert>
					)}
					{props.fetchStatus === "successAlert" && (
						<Alert severity="success" with_fading={String(withFading)}>
							{errorText}
						</Alert>
					)}
					<div className="CircularProgress">{props.fetchStatus === "loading" && props.showLoader !== "false" && <CircularProgress />}</div>
				</div>
			)}
		</div>
	);
}

export function selectFetchStatus(arr) {
	for (let x of arr) {
		if (["loading", "failed", "successAlert", "successAlertWithFading"].includes(x)) {
			return x;
		}
	}
}

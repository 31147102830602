import { createSlice } from "@reduxjs/toolkit";

export const ribbon = createSlice({
	name: "ribbon",
	initialState: {
		currentPage: false,
		lastEstimateCurrentPage: false,
		lastActiveScroll: 0,
		currentEstmDocUrlPart: "",
		currentProjectID: false,
		currentResourceSheetID: false,
		resourceSheetList: false,
		currentEstmDocId: false,
		editorEstmDocClickedSectionId: 0,
		clickedTableClmName: "",
		clickedTablePrevRow: null,
		clickedTableRow: null,
		clickedTableCtrlRows: [],
		editorIsVisible: false,
		panelSearchNumber: "",
		panelSearchPrevKey: null,
		panelFoundRowPositionIndex: 0,
		panelFoundRowPositions: [],
		panelSearchValue: "",
		navigateFromEditor: false,
		clickedTarget: null,
	},
	reducers: {
		setCurrentPage: (state, { payload }) => {
			state.currentPage = payload;
			if (payload === "LocalEstimateViewer") {
				state.currentEstmDocUrlPart = "lsr";
			} else if (payload === "ObjectEstimateViewer") {
				state.currentEstmDocUrlPart = "osr";
			} else if (payload === "KatsEstimateViewer") {
				state.currentEstmDocUrlPart = "kats";
			} else if (payload === "SummaryEstimateViewer") {
				state.currentEstmDocUrlPart = "ssr";
			}
		},
		setLastEstimateCurrentPage: (state, { payload }) => {
			state.lastEstimateCurrentPage = payload;
		},
		setLastActiveScroll: (state, { payload }) => {
			state.lastActiveScroll = payload;
		},
		setCurrentEstmDocUrlPart: (state, { payload }) => {
			state.currentEstmDocUrlPart = payload;
		},
		setCurrentEstmDocId: (state, { payload }) => {
			state.currentEstmDocId = payload;
		},
		setCurrentProjectId: (state, { payload }) => {
			state.currentProjectID = payload;
		},
		setCurrentResourceSheetId: (state, { payload }) => {
			state.currentResourceSheetID = payload;
		},
		setResourceSheetList: (state, { payload }) => {
			state.resourceSheetList = payload;
		},
		setClickedTableClmName: (state, { payload }) => {
			state.clickedTableClmName = payload;
		},
		setClickedTableRow: (state, { payload }) => {
			state.clickedTablePrevRow = state.clickedTableRow;
			state.clickedTableRow = payload;
		},
		setEditorIsVisible: (state, { payload }) => {
			state.editorIsVisible = payload;
		},
		setPanelSearchNumber: (state, { payload }) => {
			state.panelSearchNumber = payload;
		},
		setPanelSearchPrevKey: (state, { payload }) => {
			state.panelSearchPrevKey = payload;
		},
		setPanelFoundRowPositionIndex: (state, { payload }) => {
			state.panelFoundRowPositionIndex = payload;
		},
		setPanelSearchValue: (state, { payload }) => {
			state.panelSearchValue = payload;
		},
		setPanelFoundRowPositions: (state, { payload }) => {
			state.panelFoundRowPositions = payload;
		},
		setNavigateFromEditor: (state, { payload }) => {
			state.navigateFromEditor = payload;
		},
		setEditorEstmDocClickedSectionId: (state, { payload }) => {
			state.editorEstmDocClickedSectionId = payload;
		},
		setClickedTarget: (state, { payload }) => {
			state.clickedTarget = payload;
		},
		pushToClickedTableCtrlRows: (state, { payload }) => {
			if (state.clickedTableCtrlRows.filter((x) => `${x.key}_${x.pressedClm}` !== `${payload.key}_${payload.pressedClm}`).length === 0) {
			}
			state.clickedTableCtrlRows = [...state.clickedTableCtrlRows, payload];
		},
		removeFromClickedTableCtrlRows: (state, { payload }) => {
			state.clickedTableCtrlRows = state.clickedTableCtrlRows.filter(
				(x) => `${x.key}_${x.pressedClm}` !== `${payload.key}_${payload.pressedClm}`
			);
		},
		clearClickedTableCtrlRows: (state, { payload }) => {
			state.clickedTableCtrlRows = [];
		},
	},
});

export const {
	setCurrentPage,
	setLastEstimateCurrentPage,
	setLastActiveScroll,
	setCurrentProjectId,
	setCurrentResourceSheetId,
	setCurrentEstmDocUrlPart,
	setResourceSheetList,
	setCurrentEstmDocId,
	setClickedTableClmName,
	setClickedTableRow,
	setEditorIsVisible,
	setPanelSearchNumber,
	setPanelFoundRowPositionIndex,
	setPanelSearchValue,
	setPanelFoundRowPositions,
	setNavigateFromEditor,
	setEditorEstmDocClickedSectionId,
	setClickedTarget,
	pushToClickedTableCtrlRows,
	clearClickedTableCtrlRows,
	removeFromClickedTableCtrlRows,
	setPanelSearchPrevKey,
} = ribbon.actions;

export default ribbon.reducer;

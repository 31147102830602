export const moveNextSearch = (direction, searchFounds, curSearchRow, setCurSearchRow) => {
	const curIndex = searchFounds.findIndex((x) => x.id === curSearchRow.id && x.res_id === curSearchRow.res_id);
	let newIndex = -1;
	if (direction === "prev" && curIndex > 0) {
		newIndex = curIndex - 1;
	} else if (direction === "next" && curIndex < searchFounds.length) {
		newIndex = curIndex + 1;
	}
	setCurSearchRow({ ...searchFounds[newIndex] });
};

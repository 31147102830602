export function getEnvVal(envName) {
	if (envName === "App") {
		// return "ecpe";
		//  ecpe ais sipcs guest
		if (process.env.REACT_APP_ENV === "dev_guest") return "guest";
		return process.env.REACT_APP_ENV;
	} else if (envName === "ProjectsLimit") {
		return 100;
	} else if (envName === "FsnbFlatSearchLimit") {
		return 100;
	} else if (envName === "PriceOffersLimit") {
		return 500;
	}
}

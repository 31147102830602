import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiResourceSheet from "api/resource_sheet";

export const fetchResourceSheetList = createAsyncThunk(
    "resourceSheet/fetchResourceSheetList",
    async (id = null, { rejectWithValue }) => {
        try {
            const response = await apiResourceSheet.getList();
            return response.data;
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const resourceSheetSlice = createSlice({
    name: "resourceSheetSlice",
    fetchStatus: null,
    fetchStatusText: null,
    initialState: {
        table: {
            data: [],
            selectedData: [],
        },
    },
    reducers: {

        updateResourceSheetData: (state, { payload }) => {
            state.table.data = payload;
        },
        updateFetchStatus: (state, { payload }) => {
            state.fetchStatus = payload;
        },
        updateFetchStatusText: (state, { payload }) => {
            state.fetchStatusText = payload;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(fetchResourceSheetList.fulfilled, (state, { payload }) => {
            state.fetchStatus = "success";
            state.table["data"] = payload;
        });

        builder.addMatcher(
            isAnyOf(
                fetchResourceSheetList.pending,

            ),
            (state, action) => {
                state.fetchStatus = "loading";
            }
        );

        builder.addMatcher(
            isAnyOf(
                fetchResourceSheetList.rejected,

            ),
            (state, { payload }) => {
                state.fetchStatus = "failed";
                state.fetchStatusText = payload?.detail;
            }
        );
    },
});

export const {
    updateResourceSheetData,
    updateFetchStatus,
    updateFetchStatusText
} = resourceSheetSlice.actions;
export default resourceSheetSlice.reducer;

import { useEffect, useState, useRef, useLayoutEffect, useMemo } from "react";
import { useParams, useNavigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
	fetchProject,
	fetchProjectTree,
	forceFetchProjectTree,
	setSelected,
	changeEditingMode,
	unsetSelected,
	editTreeLocalRow,
	editTreeObjectRow,
	setFetchStatus,
	setFetchStatusText,
	fetchProjectErrorsExcel,
	getProjectInputXml,
	changeProjectTree,
	makeProjectLsrDoubleWinner,
} from "slices/project_work";
import { postUserSetGpr } from "slices/protocol";
import {
	deleteLocalEstimate,
	editLocalEstimate,
	setAdvSearchSelected,
	getPrintXlsx as getPrintXlsxLocal,
	getPrintXlsxWithErrors as getPrintXlsxLocalWithErrors,
	setLocalFetchStatus,
	getInputXml as getInputXmlLocal,
	getOutputXml as getOutputXmlLocal,
	getInputOriginXml as getInputOriginXmlLocal,
	changeRowData as changeRowDataLocal,
	setTableData as setTableDataLocal,
} from "slices/local_estimate";
import { setTableData as setEditorTableData } from "slices/local_estimate_editor";

import {
	deleteObjectEstimate,
	editObjectEstimate,
	editObjectEstmState,
	getPrintXlsx as getPrintXlsxObject,
	getPrintXlsxWithErrors as getPrintXlsxObjectWithErrors,
	getInputXml as getInputXmlObject,
	changeRowData as changeRowDataObject,
	setTableData as setTableDataObject,
} from "slices/object_estimate";

import {
	changeSumEstmVisible,
	setFetchStatus as setFetchSumStatus,
	changeRowData as changeRowDataSum,
	setTableData as setTableDataSummary,
} from "slices/summary_estimate";
import { getUserSettings, setUserTasksVisible, getUserTasksAndRead } from "slices/user_adv";

import {
	getKatsTree,
	deleteKats,
	setKatsFetchStatus,
	getPrintXlsxPpk,
	getOutputXml as getOutputXmlKats,
	getInputOriginXml as getInputOriginXmlKats,
	getPrintXlsx as getPrintXlsxKats,
	getPrintXlsxWithErrors as getPrintXlsxKatsWithErrors,
	changeFiltredRowData as changeFiltredRowDataKats,
	setFiltredData as setTableDataKats,
	getOutputXmlWithJusts as getOutputXmlWithJustsKats,
	getInputOriginXmlWithJusts as getInputOriginXmlWithJustsKats,
} from "slices/kats";

import {
	getUserSetErrorStatus,
	getUserSetExcept,
	getUserSetAllowErPerc,
	getProtocolExcel,
	getSelectedTypes,
	changeRowData as changeRowDataProtocol,
	setData as setDataProtocol,
	setReGetProtocol,
} from "slices/protocol";

import {
	setCurrentProjectId,
	setCurrentEstmDocId,
	setClickedTableRow,
	setLastEstimateCurrentPage,
	setLastActiveScroll,
	setPanelSearchNumber,
	setPanelSearchPrevKey,
	setPanelFoundRowPositionIndex,
	setPanelSearchValue,
	setPanelFoundRowPositions,
	setClickedTableClmName,
} from "slices/ribbon";
import { setConfirmOptions, setAnswer } from "slices/confirm";
import { setIsContextMenuOpen, setContextMenuPosition, setContextMenuObj } from "slices/context_menu.js";

import { getEnvVal } from "core/env.js";

import ProjectTree from "./ProjectTree/ProjectTree";
import SummaryEstimateViewer from "./SummaryEstimateViewer";
import LocalEstimateViewer from "./LocalEstimateViewer";
import KatsViewer from "./KatsViewer";
import { KatsProjectViewer } from "./KatsViewer/KatsProjectViewer";

import ObjectEstimateViewer from "./ObjectEstimateViewer";
import ProtocolViewer from "./ProtocolViewer";
import AlertLoading, { selectFetchStatus } from "../../components/AlertLoading";
import StartWorkBox from "../../components/StartWorkBox";
import ConfirmDialog from "../../components/ConfirmDialog";
import { KatsPriceOfferSelectorModal } from "./KatsViewer/components/KatsPriceOfferSelectorModal/KatsPriceOfferSelectorModal";
import AdvSearchBox from "./components/AdvSearchBox/AdvSearchBox";
import CommentsBoxModal from "./components/CommentsBox/CommentsBoxModal";

import EstimateRibbonSettings from "./EstimateRibbonSettings";
import EstimateRibbonContentWrapper from "./EstimateRibbonContentWrapper";
import ProtocolRibbonSettings from "./ProtocolRibbonSettings";

import RibbonContent from "components/Ribbon/RibbonContent/RibbonContent";
import ContextMenu from "components/ContextMenu/ContextMenu.jsx";
import { EstimateViewerContextMenuFactory, getContextMenuVisible } from "./EstimateViewerContextMenuFactory/EstimateViewerContextMenuFactory";

import Footer from "components/Footer/Footer";
import KatsViewerFooterSettings from "../EstimateViewer/KatsViewer/KatsViewerFooterSettings";
import LocalEstimateViewerFooterSettings from "../EstimateViewer/LocalEstimateViewer/LocalEstimateViewerFooterSettings";
import ObjectEstimateViewerFooterSettings from "../EstimateViewer/ObjectEstimateViewer/ObjectEstimateViewerFooterSettings";
import SummaryEstimateViewerFooterSettings from "../EstimateViewer/SummaryEstimateViewer/SummaryEstimateViewerFooterSettings";

import RibbonPanelActionFactory from "./RibbonPanelActions/RibbonPanelActionFactory";

import { IconButton, Tooltip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { UploadJustModel } from "./KatsViewer/components/UploadJustModel/UploadJustModel";
import { MaxFileSizeEnum } from "core/enums";
import apiKatsEstimate from "api/kats";
import apiSummaryEstimate from "api/summary_estimate";
import apiLocalEstimate from "api/local_estimate";
import apiProjects from "api/projects";
import apiProtocol from "api/protocol";

import "./EstimateViewerMain.css";

export default function EstimateViewerMain() {
	const dispatch = useDispatch();
	const fetchStatus = useSelector((state) => state.projectWork.fetchStatus);
	const proj_main = useSelector((state) => state.projectWork.main, shallowEqual);
	const fetchMonStatus = useSelector((state) => state.projectWork.fetchMonStatus);
	const fetchStatusText = useSelector((state) => state.projectWork.fetchStatusText);
	const fetchTreeStatus = useSelector((state) => state.projectWork.fetchTreeStatus);
	//const fetchLsrStatus = useSelector((state) => state.localEstimate.fetchStatus);
	const fetchKatsStatus = useSelector((state) => state.kats.fetchStatus);
	const treeData = useSelector((state) => state.projectWork.tree, shallowEqual);
	const selected = useSelector((state) => state.projectWork.treeSelected, shallowEqual);
	const editingMode = useSelector((state) => state.projectWork.editingMode, shallowEqual);
	const katsAllColumnsSettings = useSelector((state) => state.kats.columnsSettings);
	const katsHeaderData = useSelector((state) => state.kats.headerData);
	const [katsColumnsSettings, setKatsColumnsSettings] = useState([]);
	const lsrData = useSelector((state) => state.localEstimate.data);

	const currentEstmDocId = useSelector((state) => state.ribbon.currentEstmDocId);
	const currentPage = useSelector((state) => state.ribbon.currentPage);
	const currentEstmDocUrlPart = useSelector((state) => state.ribbon.currentEstmDocUrlPart);
	const clickedTableCtrlRows = useSelector((state) => state.ribbon.clickedTableCtrlRows);
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const panelSearchNumber = useSelector((state) => state.ribbon.panelSearchNumber);
	const panelSearchPrevKey = useSelector((state) => state.ribbon.panelSearchPrevKey);
	const panelSearchValue = useSelector((state) => state.ribbon.panelSearchValue);
	const panelFoundRowPositions = useSelector((state) => state.ribbon.panelFoundRowPositions);
	const panelFoundRowPositionIndex = useSelector((state) => state.ribbon.panelFoundRowPositionIndex);
	const isContextMenuOpen = useSelector((state) => state.contextMenu.isContextMenuOpen);
	const contextMenuPosition = useSelector((state) => state.contextMenu.contextMenuPosition);
	const contextMenuObj = useSelector((state) => state.contextMenu.contextMenuObj);

	const fetchSumStatus = useSelector((state) => state.summaryEstimate.fetchStatus);
	const confirmVisible = useSelector((state) => state.confirm.visible, shallowEqual);
	const windowWidth = useSelector((state) => state.windowDimensions.windowWidth);
	const userSettings = useSelector((state) => state.userAdv.settings);

	const [advSearchBoxVisible, setAdvSearchBoxVisible] = useState(false);
	const [ksrVisible, setKsrVisible] = useState(false);
	const [fsnbVisible, setFsnbVisible] = useState(false);
	const [advSearchBoxMode, setAdvSearchBoxMode] = useState("");
	const [selectedErrorStatus, setSelectedErrorStatus] = useState([]);
	const [katsPriceOfferSelectorVisible, setKatsPriceOfferSelectorVisible] = useState(false);
	const [commentBoxVisible, setCommentBoxVisible] = useState(false);
	const [commentBoxObj, setCommentBoxObj] = useState();

	const userPermissions = useSelector((state) => state.auth.permissions);

	const selectTypeData = useSelector((state) => state.protocol.selectTypeData);
	const gprVisible = useSelector((state) => state.protocol.gprVisible);
	const [allowErPercVal, setAllowErPercVal] = useState(0);
	const allowErPerc = useSelector((state) => state.protocol.allowErPerc);
	const localEstmData = useSelector((state) => state.localEstimate.data);
	const localEstimateTableData = useSelector((state) => state.localEstimate.tableData);
	const objectEstimateTableData = useSelector((state) => state.objectEstimate.tableData);
	const summaryEstimateTableData = useSelector((state) => state.summaryEstimate.tableData);
	const katsEstimateTableData = useSelector((state) => state.kats.filtredData);
	const protocolTableData = useSelector((state) => state.protocol.data);
	const summaryHeaderData = useSelector((state) => state.summaryEstimate.headerData);

	const scrollBlock = useRef();
	const treeScrollBlock = useRef();

	const lowerCallbacks = useRef({ local: {}, object: {}, protocol: {}, summary: {}, kats: {}, project: {} });

	// const { removeLsItem } = useLocalStorage();

	const navigate = useNavigate();
	const { id } = useParams();
	const location = useLocation();

	const localFetchStatus = selectFetchStatus([
		fetchStatus,
		fetchTreeStatus,
		fetchMonStatus,
		fetchSumStatus,
		fetchKatsStatus,
		//fetchLsrStatus,
	]);

	const [deletableSelected, setDeletableSelected] = useState(0);

	const [errorsVisible, setErrorsVisible] = useState(null);
	const [expectPopVisb, setExpectPopVisb] = useState(false);
	const [katsJustUploadVisible, setKatsJustUploadVisible] = useState(false);
	const [katsJustUploadCnt, setKatsJustUploadCnt] = useState(0);

	const lsrId = parseInt(location.pathname.slice(location.pathname.indexOf("lsr/") + 4));
	const osrId = parseInt(location.pathname.slice(location.pathname.indexOf("osr/") + 4));
	const katsId = parseInt(location.pathname.slice(location.pathname.indexOf("kats/") + 5));

	const treeDataHasObjs = useMemo(() => {
		return treeData.find((x) => x._children && x._children.length && x._children.length > 0);
	}, [treeData, id]); // eslint-disable-line react-hooks/exhaustive-deps

	const clearPanel = () => {
		dispatch(setPanelSearchNumber(""));
		dispatch(setPanelSearchPrevKey(""));
		dispatch(setPanelFoundRowPositionIndex(0));
		dispatch(setPanelSearchValue(""));
		dispatch(setPanelFoundRowPositions([]));
		dispatch(setClickedTableClmName(""));
	};

	useEffect(() => {
		dispatch(setLastEstimateCurrentPage(currentPage));
		clearPanel();
	}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() => {
		return () => {
			dispatch(setLastActiveScroll(scrollBlock?.current?.scrollTop)); // eslint-disable-line react-hooks/exhaustive-deps
			// removeLsItem({ keySubStr: "___com-" });
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/*eslint-disable */
	useEffect(() => {
		dispatch(setFetchStatus("success"));
		dispatch(setFetchSumStatus("success"));
		dispatch(setCurrentProjectId(id));
		dispatch(setEditorTableData([]));

		const prom = dispatch(fetchProject(id));
		prom.then(() => {
			return dispatch(fetchProjectTree(id));
		}).then((data) => {
			if (data?.meta?.requestStatus == "rejected") {
				navigate("/");
				return;
			}
			if (data.payload == null) return;
			// Для выбора документа в дереве при прямом открытии страницы по ссылке
			if (location.pathname.includes("/kats")) {
				if (katsId > 0) {
					dispatch(setSelected(data.payload.find((x) => x.type === "kats" && x.id === katsId)));
					const initSelected = data.payload.flatMap((ssr) => ssr?._children).find((kats) => kats?.id === katsId);
					if (initSelected != null) {
						dispatch(setSelected(initSelected));
					}
				} else {
					dispatch(setSelected(data.payload.find((x) => x.type === "kats")));
				}
			} else if (location.pathname.includes("/osr")) {
				dispatch(setSelected(data.payload.find((x) => x.type === "object" && x.id === osrId)));
				const initSelected = data.payload.flatMap((ssr) => ssr?._children).find((osr) => osr?.id === osrId);
				if (initSelected != null) {
					dispatch(setSelected(initSelected));
				}
			} else if (location.pathname.includes("/lsr")) {
				dispatch(setSelected(data.payload.find((x) => x.type === "local" && x.id === lsrId)));
				let initSelected = data.payload
					.flatMap((ssr) => ssr?._children)
					.flatMap((osr) => osr?._children)
					.find((lsr) => lsr?.id === lsrId);
				if (initSelected == null) {
					initSelected = data.payload
						.flatMap((ssr) => ssr?._children)
						.flatMap((osr) => osr?._children)
						.flatMap((lsrWinner) => lsrWinner?._children)
						.find((lsr) => lsr?.id === lsrId);
				}
				if (initSelected != null) {
					dispatch(setSelected(initSelected));
				}
			}
		});
		clearPanel();
	}, [id, lsrId, osrId, katsId]);

	useEffect(() => {
		if (proj_main["id"] != null) {
			let body = {
				user_id: localStorage.getItem("userId"),
				project_id: proj_main["id"],
			};
			dispatch(getUserSetErrorStatus(body));
			dispatch(getSelectedTypes(id));
			dispatch(getUserSetExcept(body));
			dispatch(getUserSetAllowErPerc(body));
			dispatch(getUserSettings({ userId: localStorage.getItem("userId") }));
		}

		return () => {
			dispatch(setAdvSearchSelected([]));
			clearPanel();
		};
	}, [proj_main]);

	useEffect(() => {
		if (advSearchBoxVisible === false) {
			dispatch(setAdvSearchSelected([]));
		}
	}, [advSearchBoxVisible]);

	useEffect(() => {
		setAllowErPercVal(allowErPerc);
	}, [allowErPerc]);

	useEffect(() => {
		setDeletableSelected(
			selected.filter((x) => ["local", "object", "kats"].includes(x["type"]) === true && x["id"] !== 0 && x["id"] != null).length
		);
	}, [selected]);
	/*eslint-enable */

	useEffect(() => {
		if (panelSearchNumber && panelSearchNumber !== "") {
			RibbonPanelActionFactory({
				action: "MOVE_TO_NUMBER",
				currentPage: currentPage,
				arg: panelSearchNumber,
				tableData: {
					local: localEstimateTableData,
					object: objectEstimateTableData,
					summary: summaryEstimateTableData,
					kats: katsEstimateTableData,
					protocol: protocolTableData,
				},
				updateTableData: {
					local: changeRowDataLocal,
					object: changeRowDataObject,
					summary: changeRowDataSum,
					kats: changeFiltredRowDataKats,
					protocol: changeRowDataProtocol,
				},
				scrollBlock: scrollBlock,
				dispatch: dispatch,
				panelSearchPrevKey: panelSearchPrevKey,
			});
		}
	}, [panelSearchNumber]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (panelSearchValue && panelSearchValue !== "") {
			RibbonPanelActionFactory({
				action: "SEARCH_INPUT",
				currentPage: currentPage,
				arg: panelSearchValue,
				tableData: {
					local: localEstimateTableData,
					object: objectEstimateTableData,
					summary: summaryEstimateTableData,
					kats: katsEstimateTableData,
					protocol: protocolTableData,
				},
				updateTableData: {
					local: setTableDataLocal,
					object: setTableDataObject,
					summary: setTableDataSummary,
					kats: setTableDataKats,
					protocol: setDataProtocol,
				},
				scrollBlock: scrollBlock,
				dispatch: dispatch,
				panelSearchPrevKey: panelSearchPrevKey,
			});
		}
	}, [panelSearchValue]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(setPanelFoundRowPositionIndex(0));
	}, [panelFoundRowPositions]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		RibbonPanelActionFactory({
			action: "MOVE_TO_INDEX",
			currentPage: currentPage,
			arg: panelFoundRowPositions[panelFoundRowPositionIndex],
			scrollBlock: scrollBlock,
		});
	}, [panelFoundRowPositionIndex]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleEnterParentSearchPress = () => {
		if (panelFoundRowPositions.length === 0) {
			dispatch(setPanelFoundRowPositionIndex(0));
		} else {
			dispatch(setPanelFoundRowPositionIndex((panelFoundRowPositionIndex + 1) % panelFoundRowPositions.length));
		}
	};

	const showDeleteDialog = ({ cascade }) => {
		let text = "Удалить выбранный документ?";
		if (selected.length > 1) {
			text = "Удалить выбранные документы?";
		}
		if (deletableSelected) {
			dispatch(
				setConfirmOptions({
					label: "Удаление",
					text: text,
					labelCancel: "Закрыть",
					labelOk: "Удалить",
					visible: true,
					callback: () => deleteSeleted({ cascade: cascade }),
				})
			);
		}
	};

	const deleteSeleted = ({ cascade }) => {
		dispatch(setAnswer(false));
		if (deletableSelected) {
			for (let obj of selected) {
				if (obj["type"] === "object") {
					const prom = dispatch(deleteObjectEstimate({ estmId: obj["id"], cascade: cascade }));
					prom.then(() => {
						const tProm = dispatch(forceFetchProjectTree(id));
						tProm.then(() => {
							dispatch(setCurrentEstmDocId(false));
							navigate("/project/" + obj["project_id"]);
						});
					});
				} else if (obj["type"] === "local") {
					const prom = dispatch(deleteLocalEstimate(obj["id"]));
					prom.then(() => {
						const tProm = dispatch(fetchProjectTree(id));
						tProm.then(() => {
							dispatch(setCurrentEstmDocId(false));
							navigate("/project/" + obj["project_id"]);
						});
					});
				} else if (obj["type"] === "kats") {
					const prom = dispatch(deleteKats(obj["id"]));
					prom.then(() => {
						return dispatch(fetchProjectTree(id));
					}).then((data) => {
						dispatch(setCurrentEstmDocId(false));
						navigate("/project/" + obj["project_id"]);
					});
				}
			}
		}
	};

	const showErrorDeleteDialog = () => {
		if (clickedTableRow.key) {
			dispatch(
				setConfirmOptions({
					label: "Удаление ошибки",
					// eslint-disable-next-line
					text: "Внимание! Удаление ошибки из протокола необратимая операции. \
						Для восстановления полной редакции протокола (с ранее удаленными ошибками) необходимо будет перезапустить алгоритм проверки. \
						Вы уверены, что хотите удалить выделенную ошибку?",
					labelCancel: "Закрыть",
					labelOk: "Удалить",
					visible: true,
					callback: () => deleteProtocolError(),
				})
			);
		}
	};

	const deleteProtocolError = () => {
		const prom = apiProtocol.deleteProtocolError({ projectId: id, estmType: currentEstmDocUrlPart, errorId: clickedTableRow?.id });
		prom.then(() => dispatch(setReGetProtocol(true)));
	};

	const editLocalCommit = (dct) => {
		dct["type"] = "local";
		dispatch(editTreeLocalRow(dct));
		const prom = dispatch(editLocalEstimate(dct));
		prom.then(() => dispatch(fetchProjectTree(id)));
	};

	const editObjectCommit = (dct) => {
		dct["type"] = "object";
		dispatch(editTreeObjectRow(dct));
		dispatch(editObjectEstmState(dct));
		const prom = dispatch(editObjectEstimate(dct));
		prom.then(() => dispatch(fetchProjectTree(id)));
	};

	const onClickSmetBtn = () => {
		let newUrl = "";
		if (!["ssr", ""].includes(currentEstmDocUrlPart)) {
			newUrl = `/project/${id}/${currentEstmDocUrlPart}/${currentEstmDocId}/`;
		} else {
			newUrl = `/project/${id}/`;
		}
		navigate(newUrl);
		dispatch(setClickedTableRow(null));
	};

	const onClickProtocolBtn = () => {
		let newUrl = "";
		if (!["SummaryEstimateViewer", "StartEstimateViewer", "KatsProjectEstimateViewer"].includes(currentPage) && currentEstmDocId != null) {
			newUrl = `/project/${id}/${currentEstmDocUrlPart}/${currentEstmDocId}/protocol/`;
		} else if (currentPage === "KatsProjectEstimateViewer") {
			newUrl = `/project/${id}/kats/`;
		} else {
			newUrl = `/project/${id}/protocol/`;
		}
		navigate(newUrl);
		dispatch(setClickedTableRow(null));
	};

	const onClickMonitorBtn = () => {
		navigate("/project/" + id + "/monitor/");
		dispatch(setClickedTableRow(null));
	};

	const onClickImportBtn = () => {
		navigate("/project/" + id + "/import/");
		dispatch(setClickedTableRow(null));
	};

	const onClickExportSmetBtn = () => {
		dispatch(fetchProjectErrorsExcel(id));
	};

	const onClickExportCurrentSmetBtn = () => {
		if (currentEstmDocId) dispatch(getProtocolExcel({ type: currentEstmDocUrlPart, id: currentEstmDocId }));
	};

	const onClickPrintCurrentSmetBtn = (withErrors) => {
		if (selected.length > 1) {
			const body = [...selected].map((elm) => {
				return { id: elm.id, type: elm.type };
			});
			let prom;
			if (withErrors) {
				prom = apiProjects.getPartProjectPrintXlsxWithErrors(id, body);
			} else {
				prom = apiProjects.getPartProjectPrintXlsx(id, body);
			}
			prom.then((response) => {
				dispatch(getUserTasksAndRead());
				dispatch(setUserTasksVisible(true));
			});
			return;
		}

		if (currentEstmDocId) {
			if (withErrors) {
				if (currentPage === "LocalEstimateViewer") {
					dispatch(getPrintXlsxLocalWithErrors(currentEstmDocId));
				} else if (currentPage === "KatsEstimateViewer") {
					dispatch(getPrintXlsxKatsWithErrors(currentEstmDocId));
				} else if (currentPage === "ObjectEstimateViewer") {
					dispatch(getPrintXlsxObjectWithErrors(currentEstmDocId));
				} else if (currentPage === "SummaryEstimateViewer") {
					lowerCallbacks.current.summary["getPrintXlsxWithErrorsSummary"]();
				}
			} else {
				if (currentPage === "LocalEstimateViewer") {
					dispatch(getPrintXlsxLocal(currentEstmDocId));
				} else if (currentPage === "ObjectEstimateViewer") {
					dispatch(getPrintXlsxObject(currentEstmDocId));
				} else if (currentPage === "KatsEstimateViewer") {
					dispatch(getPrintXlsxKats(currentEstmDocId));
				} else if (currentPage === "SummaryEstimateViewer") {
					lowerCallbacks.current.summary["getPrintXlsxSummary"]();
				}
			}
		}
	};

	const onClickPrintXlsxPpk = () => {
		dispatch(getPrintXlsxPpk(katsId));
	};

	const onClickPrintFullSmetBtn = (withErrors) => {
		let prom;
		if (withErrors) {
			prom = apiProjects.getProjectPrintXlsxWithErrors(id);
		} else {
			prom = apiProjects.getProjectPrintXlsx(id);
		}
		prom.then((response) => {
			dispatch(getUserTasksAndRead());
			dispatch(setUserTasksVisible(true));
		});
	};

	const onClickInputXmlFullSmetBtn = () => {
		dispatch(getProjectInputXml(id));
	};

	const onClickCurrentXmlExportBtn = (param) => {
		if (getEnvVal("App") === "sipcs" && currentPage === "LocalEstimateViewer") {
			dispatch(getOutputXmlLocal(currentEstmDocId));
		} else {
			if (currentPage === "LocalEstimateViewer") {
				dispatch(getInputXmlLocal(currentEstmDocId));
			} else if (currentPage === "ObjectEstimateViewer") {
				dispatch(getInputXmlObject(currentEstmDocId));
			} else if (currentPage === "KatsEstimateViewer") {
				if (param === "WITH_JUSTS") {
					dispatch(getOutputXmlWithJustsKats(currentEstmDocId));
				} else {
					dispatch(getOutputXmlKats(currentEstmDocId));
				}
			} else if (currentPage === "SummaryEstimateViewer") {
				lowerCallbacks.current.summary["getInputXmlSummary"]();
			}
		}
	};

	const getInputOriginXml = (param) => {
		if (currentPage === "LocalEstimateViewer") {
			dispatch(getInputOriginXmlLocal(currentEstmDocId));
		} else if (currentPage === "KatsEstimateViewer") {
			if (param === "WITH_JUSTS") {
				dispatch(getInputOriginXmlWithJustsKats(currentEstmDocId));
			} else {
				dispatch(getInputOriginXmlKats(currentEstmDocId));
			}
		}
	};

	const onClickSearchBtn = () => {
		if (advSearchBoxMode !== "rating") {
			setAdvSearchBoxVisible((prev) => !prev);
		} else {
			setAdvSearchBoxVisible(true);
		}
		setAdvSearchBoxMode("search");
	};

	const onClickSortBtn = () => {
		if (advSearchBoxMode !== "search") {
			setAdvSearchBoxVisible((prev) => !prev);
		} else {
			setAdvSearchBoxVisible(true);
		}
		setAdvSearchBoxMode("rating");
	};

	const onClickChangeRowValueBtn = ({ key, value }) => {
		if (currentPage === "LocalEstimateViewer") {
			if (clickedTableRow) {
				dispatch(
					changeRowDataLocal({
						rowKey: clickedTableRow.key,
						callback: (row) => (row[key] = value),
					})
				);
				dispatch(setClickedTableRow({ ...clickedTableRow, [key]: value }));
			}
		}
	};

	const saveFiltredDataGrp = () => {
		const body = [];
		for (let step of selectTypeData) {
			for (let group of step._children) {
				for (let type of group._children) {
					if (type.checked) {
						body.push({ step_id: type.step_id, group_id: type.group_id, type_id: type.id });
					}
				}
			}
		}
		dispatch(
			postUserSetGpr({
				user_id: localStorage.getItem("userId"),
				project_id: proj_main["id"],
				body: body,
			})
		);
		// dispatch(setGprVisibley(gprVisibleyArr));
	}; // eslint-disable-line react-hooks/exhaustive-deps

	const uploadJustFiles = ({ files }) => {
		dispatch(setKatsFetchStatus("loading"));
		const promisesArr = [];
		for (let file of files) {
			if (file?.size > MaxFileSizeEnum.KATS_JUST) {
				dispatch(setFetchStatus("failed"));
				dispatch(setFetchStatusText("Размер файла превышает допустимый"));
				return;
			}
			promisesArr.push(apiKatsEstimate.uploadJust({ file: file, katsId: currentEstmDocId }));
		}
		Promise.all(promisesArr)
			.then((response_arrs) => {
				dispatch(setFetchStatus("successAlert"));
				dispatch(setFetchStatusText("Обоснования загружены"));
				setTimeout(() => {
					setKatsJustUploadCnt((prev) => (prev += 1));
					dispatch(getKatsTree(currentEstmDocId));
				}, 500);
				setTimeout(() => {
					dispatch(setFetchStatus("success"));
				}, 5000);
			})
			.catch((error) => {
				if (error?.response?.data?.error_text != null) {
					dispatch(setFetchStatusText(error.response.data.error_text));
				}
				dispatch(setFetchStatus("failed"));
			});
	};

	const fillColorTableRow = (color) => {
		if (currentPage === "LocalEstimateViewer" && clickedTableRow) {
			dispatch(
				changeRowDataLocal({
					rowKey: clickedTableRow.key,
					callback: (row) => (row.bgColor = color.hex),
				})
			);
			dispatch(setClickedTableRow({ ...clickedTableRow, bgColor: color.hex }));
			const prom = apiLocalEstimate.setRowColor({
				project_id: id,
				estm_id: currentEstmDocId,
				section_id: clickedTableRow.section_id === "" ? null : clickedTableRow.section_id,
				item_id: clickedTableRow.item_id === "" ? null : clickedTableRow.item_id,
				key: clickedTableRow.key,
				color: color.hex,
			});
			prom.then((response) => {
				console.log(response.data);
			});
		} else if (currentPage === "KatsEstimateViewer" && clickedTableRow) {
			dispatch(
				changeFiltredRowDataKats({
					rowKey: clickedTableRow.key,
					callback: (row) => (row.bgColor = color.hex),
				})
			);
			dispatch(setClickedTableRow({ ...clickedTableRow, bgColor: color.hex }));
			const prom = apiKatsEstimate.setRowColor({
				project_id: id,
				estm_id: currentEstmDocId,
				key: clickedTableRow.key,
				color: color.hex,
			});
			prom.then((response) => {
				// console.log(response.data);
			});
		} else if (currentPage === "SummaryEstimateViewer" && clickedTableRow) {
			dispatch(
				changeRowDataSum({
					rowKey: clickedTableRow.key,
					callback: (row) => (row.bgColor = color.hex),
				})
			);
			dispatch(setClickedTableRow({ ...clickedTableRow, bgColor: color.hex }));
			const prom = apiSummaryEstimate.setRowColor({
				project_id: id,
				estm_id: summaryHeaderData?.id,
				key: clickedTableRow.key,
				color: color.hex,
			});
			prom.then((response) => {
				// console.log(response.data);
			});
		} else if (selected && selected.length > 0) {
			for (let row of selected) {
				dispatch(
					changeProjectTree({
						rowKey: row.key,
						callback: (row) => (row.bgColor = color.hex),
					})
				);
				const prom = apiProjects.setRowColor({
					project_id: id,
					key: row.key,
					color: color.hex,
				});
				prom.then((response) => {
					// console.log(response.data);
				});
			}
		}
	};

	const showProjectTreeComment = ({ targetRow }) => {
		const selectedProjectTreeRow = selected[0];
		if (!selectedProjectTreeRow && !targetRow) return;
		if (targetRow) {
			setCommentBoxObj(targetRow);
		} else {
			setCommentBoxObj(selectedProjectTreeRow);
		}
		setCommentBoxVisible(true);
	};

	const onClickShowKsr = () => {
		setKsrVisible((prev) => !prev);
	};

	const onClickShowFsnb = () => {
		setFsnbVisible((prev) => !prev);
	};

	lowerCallbacks.current.project["showProjectTreeComment"] = showProjectTreeComment;

	const openContextMenu = ({ clickedRow, event }) => {
		event.preventDefault();
		dispatch(setContextMenuPosition({ event }));
		dispatch(setContextMenuObj({ ...clickedRow, model_name: "ProjectTreeDoc" }));
		dispatch(setIsContextMenuOpen(true));
	};

	const copyEstimateDoc = () => {
		dispatch(setFetchStatus("loading"));
		let prom;
		if (currentPage === "LocalEstimateViewer" || currentEstmDocUrlPart === "lsr") {
			prom = apiLocalEstimate.copyDoc({ estmId: currentEstmDocId });
		}
		if (!prom) return;

		prom.then((response) => {
			dispatch(fetchProjectTree(id));
			dispatch(setFetchStatus("success"));
		}).catch((error) => {
			console.error(error);
			dispatch(setFetchStatus("failed"));
		});
	};

	if (proj_main.id === null) {
		return null;
	}

	const showClearProjectDialog = () => {
		dispatch(
			setConfirmOptions({
				label: "Очистить проект?",
				text: "Операция очистки приведет к удалению всех загруженных сметных документов проекта",
				labelCancel: "Закрыть",
				labelOk: "Удалить",
				visible: true,
				callback: () => clearProject(id),
			})
		);
	};

	const clearProject = (projectId) => {
		dispatch(setFetchStatus("loading"));
		const prom = apiProjects.clearProject(projectId);
		prom.then((response) => {
			dispatch(setFetchStatus("success"));
			dispatch(fetchProjectTree(projectId));
		});
	};

	const makeLsrDoubleWinner = () => {
		const prom = dispatch(
			makeProjectLsrDoubleWinner({
				projectId: proj_main.id,
				groupId: selected?.[0]?.double_group_id,
				lsrId: selected?.[0]?.id,
			})
		);
		prom.then(() => dispatch(setReGetProtocol(true)));
	};

	const hasFooter = () => {
		return ["KatsEstimateViewer", "LocalEstimateViewer", "SummaryEstimateViewer", "ObjectEstimateViewer", "KatsProjectEstimateViewer"].includes(
			currentPage
		);
	};

	return (
		<>
			<RibbonContent />
			<EstimateRibbonContentWrapper
				EstimateRibbonSettings={EstimateRibbonSettings({
					dispatch: dispatch,
					selectTypeData: selectTypeData,
					gprVisible: gprVisible,
					saveFiltredDataGrp: saveFiltredDataGrp,
					proj_main: proj_main,
					selectedErrorStatus: selectedErrorStatus,
					setSelectedErrorStatus: setSelectedErrorStatus,
					onClickProtocolBtn: onClickProtocolBtn,
					onClickMonitorBtn: onClickMonitorBtn,
					onClickImportBtn: onClickImportBtn,
					onClickExportSmetBtn: onClickExportSmetBtn,
					onClickExportCurrentSmetBtn: onClickExportCurrentSmetBtn,
					currentEstmDocId: currentEstmDocId,
					onClickPrintCurrentSmetBtn: onClickPrintCurrentSmetBtn,
					onClickPrintFullSmetBtn: onClickPrintFullSmetBtn,
					onClickCurrentXmlExportBtn: onClickCurrentXmlExportBtn,
					onClickInputXmlFullSmetBtn: onClickInputXmlFullSmetBtn,
					onClickSearchBtn: onClickSearchBtn,
					onClickSortBtn: onClickSortBtn,
					deletableSelected: deletableSelected,
					showDeleteDialog: showDeleteDialog,
					setErrorsVisible: setErrorsVisible,
					windowWidth: windowWidth,
					currentPage: currentPage,
					clickedTableRow: clickedTableRow,
					onClickChangeRowValueBtn: onClickChangeRowValueBtn,
					userPermissions: userPermissions,
					lowerCallbacks: lowerCallbacks,
					advSearchBoxMode: advSearchBoxMode,
					advSearchBoxVisible: advSearchBoxVisible,
					allowErPercVal: allowErPercVal,
					setAllowErPercVal: setAllowErPercVal,
					navigate: navigate,
					localEstmData: localEstmData,
					setKatsJustUploadVisible: setKatsJustUploadVisible,
					setKatsPriceOfferSelectorVisible: setKatsPriceOfferSelectorVisible,
					fillColorTableRow: fillColorTableRow,
					projectTreeSelected: selected,
					getInputOriginXml: getInputOriginXml,
					allColumnsSettings: { kats: katsAllColumnsSettings },
					columnsSettings: { kats: katsColumnsSettings },
					setColumnsSettings: { kats: setKatsColumnsSettings },
					userSettings: userSettings,
					ksrVisible: ksrVisible,
					onClickShowKsr: onClickShowKsr,
					onClickPrintXlsxPpk: onClickPrintXlsxPpk,
					fsnbVisible: fsnbVisible,
					showClearProjectDialog: showClearProjectDialog,
					currentEstmDocUrlPart: currentEstmDocUrlPart,
					makeLsrDoubleWinner: makeLsrDoubleWinner,
				})}
				ProtocolRibbonSettings={ProtocolRibbonSettings({
					dispatch: dispatch,
					selectTypeData: selectTypeData,
					gprVisible: gprVisible,
					saveFiltredDataGrp: saveFiltredDataGrp,
					proj_main: proj_main,
					selectedErrorStatus: selectedErrorStatus,
					setSelectedErrorStatus: setSelectedErrorStatus,
					onClickSmetBtn: onClickSmetBtn,
					onClickMonitorBtn: onClickMonitorBtn,
					onClickImportBtn: onClickImportBtn,
					onClickExportSmetBtn: onClickExportSmetBtn,
					onClickExportCurrentSmetBtn: onClickExportCurrentSmetBtn,
					onClickPrintCurrentSmetBtn: onClickPrintCurrentSmetBtn,
					onClickPrintFullSmetBtn: onClickPrintFullSmetBtn,
					currentEstmDocId: currentEstmDocId,
					expectPopVisb: expectPopVisb,
					setExpectPopVisb: setExpectPopVisb,
					allowErPercVal: allowErPercVal,
					setAllowErPercVal: setAllowErPercVal,
					lowerCallbacks: lowerCallbacks,
					clickedTableRow: clickedTableRow,
					currentPage: currentPage,
					userPermissions: userPermissions,
					navigate: navigate,
					localEstmData: localEstmData,
					setKatsJustUploadVisible: setKatsJustUploadVisible,
					currentEstmDocUrlPart: currentEstmDocUrlPart,
					showDeleteDialog: showDeleteDialog,
					showErrorDeleteDialog: showErrorDeleteDialog,
				})}
				handleEnterParentSearchPress={handleEnterParentSearchPress}
			/>
			<div className={hasFooter() ? "ProjectSummary ProjectSummaryWithFooter" : "ProjectSummary"}>
				<div className={hasFooter() ? "ProjectSummaryTree ProjectSummaryTreeWithFooter" : "ProjectSummaryTree"} ref={treeScrollBlock}>
					<div className="ProjectSummaryTreeFixed">
						<div className="ProjectSummaryName">
							<div
								className="ProjectSummaryNameWrapper"
								style={{
									display: "-webkit-box",
									WebkitLineClamp: 3,
									WebkitBoxOrient: "vertical",
									overflow: "hidden",
								}}
							>
								{proj_main.name}
							</div>
							{advSearchBoxVisible && (
								<Tooltip
									className="advSearchBoxCloseBtn"
									title="Закрыть"
									onClick={() => {
										setAdvSearchBoxVisible(false);
									}}
								>
									<IconButton>
										<ClearIcon fontSize="17px" />
									</IconButton>
								</Tooltip>
							)}
						</div>
						<AdvSearchBox
							visible={advSearchBoxVisible}
							mode={advSearchBoxMode}
							setAdvSearchBoxVisible={setAdvSearchBoxVisible}
							treeScrollBlock={treeScrollBlock}
						/>
						<div className="ProjectEstmateTree">
							{treeData.length > 0 ? (
								<ProjectTree
									data={treeData}
									selected={selected}
									selected_func={setSelected}
									deselected_func={unsetSelected}
									editingMode={editingMode}
									editingMode_func={changeEditingMode}
									changeChildsVisb_func={changeSumEstmVisible}
									scrollBlock={scrollBlock}
									setLastSelectedEstm={() => {}}
									editback_func={{ local: editLocalCommit, object: editObjectCommit }}
									callbacks={{
										showProjectTreeComment: showProjectTreeComment,
										openContextMenu: openContextMenu,
									}}
								/>
							) : (
								<div></div>
							)}
						</div>
					</div>
				</div>

				<div className="ProjectSummaryTable" ref={scrollBlock}>
					<Routes>
						<Route
							path="/lsr/:id"
							element={
								<LocalEstimateViewer
									setErrorsVisible={setErrorsVisible}
									errorsVisible={errorsVisible}
									scrollBlock={scrollBlock}
									lowerCallbacks={lowerCallbacks}
									parentFetchStatus={localFetchStatus}
									fsnbVisible={fsnbVisible}
									setFsnbVisible={setFsnbVisible}
									onClickShowFsnb={onClickShowFsnb}
								/>
							}
						/>
						<Route path="/osr/:id" element={<ObjectEstimateViewer scrollBlock={scrollBlock} lowerCallbacks={lowerCallbacks} />} />
						<Route path="/" element={<SummaryEstimateViewer scrollBlock={scrollBlock} lowerCallbacks={lowerCallbacks} />} />
						<Route
							path="/kats/:katsId"
							element={
								<KatsViewer
									scrollBlock={scrollBlock}
									lowerCallbacks={lowerCallbacks}
									allColumnsSettings={katsAllColumnsSettings}
									columnsSettings={katsColumnsSettings}
									setColumnsSettings={setKatsColumnsSettings}
									ksrVisible={ksrVisible}
									setKsrVisible={setKsrVisible}
								/>
							}
						/>
						{treeDataHasObjs ? (
							<Route
								path="/kats/"
								element={
									<KatsProjectViewer
										scrollBlock={scrollBlock}
										lowerCallbacks={lowerCallbacks}
										allColumnsSettings={katsAllColumnsSettings}
										columnsSettings={katsColumnsSettings}
										setColumnsSettings={setKatsColumnsSettings}
										ksrVisible={ksrVisible}
										setKsrVisible={setKsrVisible}
									/>
								}
							/>
						) : (
							<Route path="/kats" element={<StartWorkBox />} />
						)}
						<Route path="/start" element={<StartWorkBox />} />
						<Route path="/start/protocol/" element={<StartWorkBox />} />

						<Route
							path="/lsr/:id/protocol"
							element={
								<ProtocolViewer
									objType="lsr"
									scrollBlock={scrollBlock}
									expectPopVisb={expectPopVisb}
									lowerCallbacks={lowerCallbacks}
									setExpectPopVisb={setExpectPopVisb}
								/>
							}
						/>
						<Route
							path="/osr/:id/protocol"
							element={
								<ProtocolViewer
									objType="osr"
									scrollBlock={scrollBlock}
									expectPopVisb={expectPopVisb}
									lowerCallbacks={lowerCallbacks}
									setExpectPopVisb={setExpectPopVisb}
								/>
							}
						/>
						<Route
							path="/kats/:katsId/protocol"
							element={
								<ProtocolViewer
									objType="kats"
									scrollBlock={scrollBlock}
									expectPopVisb={expectPopVisb}
									lowerCallbacks={lowerCallbacks}
									setExpectPopVisb={setExpectPopVisb}
								/>
							}
						/>
						<Route
							path="/protocol"
							element={<ProtocolViewer objType="ssr" scrollBlock={scrollBlock} lowerCallbacks={lowerCallbacks} />}
							expectPopVisb={expectPopVisb}
							setExpectPopVisb={setExpectPopVisb}
						/>
					</Routes>
					{katsJustUploadVisible && (
						<UploadJustModel
							setKatsJustUploadVisible={setKatsJustUploadVisible}
							justStatusApi={apiKatsEstimate}
							uploadCallback={uploadJustFiles}
							katsJustUploadCnt={katsJustUploadCnt}
						/>
					)}
					{commentBoxVisible && (
						<CommentsBoxModal
							rowObj={commentBoxObj}
							setVisible={setCommentBoxVisible}
							postActions={{ updateProjectTree: () => dispatch(forceFetchProjectTree(id)) }}
						/>
					)}
					<AlertLoading
						fetchStatus={localFetchStatus}
						func={[setFetchStatus, setFetchSumStatus, setLocalFetchStatus, setKatsFetchStatus]}
						fetchStatusText={fetchStatusText}
						setFetchStatusText={setFetchStatusText}
					/>
				</div>

				{currentPage === "KatsEstimateViewer" && <Footer footerItemSettings={KatsViewerFooterSettings(katsHeaderData)} />}
				{currentPage === "LocalEstimateViewer" && (
					<Footer footerItemSettings={LocalEstimateViewerFooterSettings({ clickedTableCtrlRows, lsrData })} />
				)}
				{currentPage === "ObjectEstimateViewer" && (
					<Footer footerItemSettings={ObjectEstimateViewerFooterSettings({ clickedTableCtrlRows })} />
				)}
				{currentPage === "SummaryEstimateViewer" && (
					<Footer footerItemSettings={SummaryEstimateViewerFooterSettings({ clickedTableCtrlRows })} />
				)}
				{katsPriceOfferSelectorVisible && (
					<KatsPriceOfferSelectorModal setVisible={setKatsPriceOfferSelectorVisible} lowerCallbacks={lowerCallbacks} />
				)}

				{confirmVisible === true && <ConfirmDialog />}

				{isContextMenuOpen && (
					<ContextMenu
						items={EstimateViewerContextMenuFactory({
							contextMenuObj,
							callbacks: {
								closeContextMenu: () => dispatch(setIsContextMenuOpen(false)),
								exportProtocolContextMenu: onClickExportCurrentSmetBtn,
								printDocContextMenu: onClickPrintCurrentSmetBtn,
								exportXmlDocContextMenu: onClickCurrentXmlExportBtn,
								deleteDocContextMenu: showDeleteDialog,
								showProjectTreeComment: showProjectTreeComment,
								fillColorTableRow: fillColorTableRow,
								copyEstimateDoc: copyEstimateDoc,
								lowerCallbacks: lowerCallbacks,
								makeLsrDoubleWinner: makeLsrDoubleWinner,
							},
							currentPage,
							userSettings: userSettings,
						})}
						isVisible={isContextMenuOpen && getContextMenuVisible(contextMenuObj)}
						position={contextMenuPosition}
						onClose={() => dispatch(setIsContextMenuOpen(false))}
					/>
				)}
			</div>
		</>
	);
}

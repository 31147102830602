import { useLayoutEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import "./KatsDateRangeAutocomplete.css";


const KatsDateRangeAutocomplete = ({ selectedOption, setSelectedOption, label, isDisabled }) => {
    const periodArr = [
        {
            id: "start_state", name: "исходное состояние"
        }, {
            id: "3_month", name: "+/- квартал к периоду"
        }, {
            id: "6_month", name: "+/- полугодие к периоду"
        }, {
            id: "9_month", name: "+/- 9 месяцев к периоду"
        }, {
            id: "12_month", name: "+/- год к периоду"
        }, {
            id: "all_time", name: "за все время"
        }
    ];

    useLayoutEffect(() => {
        setSelectedOption(periodArr[5])
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const getAutocompeleStyle = () => {
        return {
            width: '100%',
            "& legend": {
                fontSize: "9px"
            }
        }
    }

    const getRenderInput = (params, text) => {
        return <TextField {...params} InputLabelProps={{ shrink: false }} placeholder={text} />
    }

    return <div className="KatsDateRangeAutocomplete">
        <Autocomplete
            sx={getAutocompeleStyle()}
            options={periodArr}
            value={selectedOption}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            size="small"
            disableClearable
            disabled={isDisabled}
            noOptionsText="нет данных"
            onInputChange={(e, val) => {
                const inpVal = periodArr.find((x) => x.name === val);
                if (inpVal) setSelectedOption(inpVal);
            }}
            renderInput={(params) => getRenderInput(params, label)}
        />
    </div>

}

export { KatsDateRangeAutocomplete }

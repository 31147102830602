import { apiBase } from "./base";

const apiAdvSearch = {
    client: apiBase.get_client(),
    url: "/smetdocs/",

    strSearch(body) {
        return this.client.post(this.url + "adv_search/", body, apiBase.get_headers());
    },

    strSearchXlsx(body) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "blob";
        return this.client.post(this.url + "adv_search/", body, headers);
    },

    itemsRating(body) {
        return this.client.post(this.url + "items_rating/", body, apiBase.get_headers());
    },

    itemsRatingXlsx(body) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "blob";
        return this.client.post(this.url + "items_rating/", body, headers);
    },
};

export default apiAdvSearch;

export default function IndexesSmrTableColumns() {
    return [
        {
            name: 'objects_type__name',
            title: 'Объект строительства',
            width: 'auto',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'smr_index',
            title: 'Индекы СМР',
            width: '170px',
            cellHorAlign: 'right',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'letter__name',
            title: '№ письма МС',
            width: '200px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        }
    ];
}
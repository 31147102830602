import { apiBase, API_BASE_URL } from "./base";

const apiFsnb = {
	client: apiBase.get_client(),
	url: "/smetnorms/fsnb",

	getCostContent(vers_id, prefix, code) {
		return this.client.get(`${this.url}/cost/content/${vers_id}/${prefix}/${code}/`, apiBase.get_headers());
	},

	getFsnbVersions() {
		return this.client.get(`${this.url}/versions/`, apiBase.get_headers());
	},

	getFsnbVersionsInterPeriod(period_id) {
		return this.client.get(`${this.url}/versions/${period_id}/`, apiBase.get_headers());
	},

	getFsscTree(vers_id) {
		return this.client.get(`${this.url}/fssc/tree/${vers_id}/`, apiBase.get_headers());
	},

	getFsscPgTree(vers_id) {
		return this.client.get(`${this.url}/fsscpg/tree/${vers_id}/`, apiBase.get_headers());
	},

	getKsrTree() {
		return this.client.get(`${this.url}/ksr/tree/`, apiBase.get_headers());
	},

	getKsrGroups() {
		return this.client.get(`${this.url}/ksr/groups/`, apiBase.get_headers());
	},

	getFsscTableResource(vers_id, parent_id) {
		return this.client.get(`${this.url}/fssc/table/${vers_id}/${parent_id}/`, apiBase.get_headers());
	},

	getFsscPgTableResource(vers_id, parent_id) {
		return this.client.get(`${this.url}/fsscpg/table/${vers_id}/${parent_id}/`, apiBase.get_headers());
	},

	getKsrTableResource(parent_id) {
		return this.client.get(`${this.url}/ksr/table/${parent_id}/`, apiBase.get_headers());
	},

	getFsscTableSearch(vers_id, searchText) {
		return this.client.get(`${this.url}/fssc/table/search/${vers_id}/${searchText}/`, apiBase.get_headers());
	},

	getFsscPgTableSearch(vers_id, searchText) {
		return this.client.get(`${this.url}/fsscpg/table/search/${vers_id}/${searchText}/`, apiBase.get_headers());
	},

	getKsrTableSearch({ body }) {
		return this.client.post(`${this.url}/ksr/table/search/`, body, apiBase.get_headers());
	},

	getFsemTree(vers_id) {
		return this.client.get(`${this.url}/fsem/tree/${vers_id}/`, apiBase.get_headers());
	},

	getFsemTableResource(vers_id, parent_id) {
		return this.client.get(`${this.url}/fsem/table/${vers_id}/${parent_id}/`, apiBase.get_headers());
	},

	getFsemTableSearch(vers_id, searchText) {
		return this.client.get(`${this.url}/fsem/table/search/${vers_id}/${searchText}/`, apiBase.get_headers());
	},

	getNormTreeBase(vers_id) {
		return this.client.get(`${this.url}/norm/tree/${vers_id}/`, apiBase.get_headers());
	},

	getNormTableResource(vers_id, parent_id) {
		return this.client.get(`${this.url}/norm/table/${vers_id}/${parent_id}/`, apiBase.get_headers());
	},

	getNormTableSearch(vers_id, searchText, body) {
		return this.client.post(`${this.url}/norm/table/search/${vers_id}/${searchText}/`, body, apiBase.get_headers());
	},

	getNormIdOnTableRow(vers_id, row_id, model_name) {
		return this.client.get(`${this.url}/norm_id_on_table_row/${vers_id}/${row_id}/${model_name}/`, apiBase.get_headers());
	},

	getNormIdOnCodePrefix({ versId, code, prefix }) {
		return this.client.get(`${this.url}/norm_id_on_code_str/${versId}/${code}/${prefix}/`, apiBase.get_headers());
	},

	getNormIdOnErrorRow(row_id) {
		return this.client.get(`${this.url}/norm_id_on_error_row/${row_id}/`, apiBase.get_headers());
	},

	getNormCoef(row_id) {
		return this.client.get(`${this.url}/norm/coef/${row_id}/`, apiBase.get_headers());
	},

	getPartNorm(versId, code, count) {
		return this.client.get(`${this.url}/norm_mapping/${versId}/${code}/${count}/`, apiBase.get_headers());
	},

	getNormCoefOnCode({ versId, prefix, code }) {
		return this.client.get(`${this.url}/norm_code/coef/${versId}/${prefix}/${code}/`, apiBase.get_headers());
	},

	getNpaNormCoefs({ versId }) {
		return this.client.get(`${this.url}/norm_npa/coef/${versId}/`, apiBase.get_headers());
	},

	getCoefTree(vers_id) {
		return this.client.get(`${this.url}/coef/tree/${vers_id}/`, apiBase.get_headers());
	},

	getCoefTableResource(vers_id, parent_id) {
		return this.client.get(`${this.url}/coef/table/${vers_id}/${parent_id}/`, apiBase.get_headers());
	},

	getCoefTableSearch(vers_id, searchText) {
		return this.client.get(`${this.url}/coef/table/search/${vers_id}/${searchText}/`, apiBase.get_headers());
	},

	getTechGrp(versId, techGrp) {
		return this.client.get(`${this.url}/tech_grp/${versId}/${techGrp}/`, apiBase.get_headers());
	},

	getTechGrpOnCode({ versId, code, itemId }) {
		return this.client.get(`${this.url}/tech_grp_on_code_item_id/${versId}/${code}/${itemId}/`, apiBase.get_headers());
	},

	getNormResIdOnTableRow(versId, rowId) {
		return this.client.get(`${this.url}/norm_res_on_table_row/${versId}/${rowId}/`, apiBase.get_headers());
	},

	getSuperSearch(body) {
		return this.client.post(`${this.url}/super_search/`, body, apiBase.get_headers());
	},

	getNrpsPeriod() {
		return this.client.get(`${this.url}/nrspperiod/`, apiBase.get_headers());
	},

	getNrpsInterVersPeriod(vers_id) {
		return this.client.get(`${this.url}/nrspperiod/${vers_id}/`, apiBase.get_headers());
	},

	getNrpsTree(vers_id) {
		return this.client.get(`${this.url}/nrsp/tree/${vers_id}/`, apiBase.get_headers());
	},

	getNrpsWorksTree(vers_id) {
		return this.client.get(`${this.url}/nrsp/works_tree/${vers_id}/`, apiBase.get_headers());
	},

	getNrpsTable(vers_id, parent_id) {
		return this.client.get(`${this.url}/nrsp/table/${vers_id}/${parent_id}/`, apiBase.get_headers());
	},

	getNrspTableSearch(vers_id, searchText) {
		return this.client.get(`${this.url}/nrsp/table/search/${vers_id}/${searchText}/`, apiBase.get_headers());
	},

	getNrspOnNorm(period_id, norm_id) {
		return this.client.get(`${this.url}/nrsp_on_norm/${period_id}/${norm_id}/`, apiBase.get_headers());
	},

	getNrspOnVersCodeDate({ dateQ, versId, prefix, code, northRegTerrId }) {
		return this.client.get(`${this.url}/nrsp_on_vers_code_date/${dateQ}/${versId}/${prefix}/${code}/${northRegTerrId}/`, apiBase.get_headers());
	},

	getNormOnNrsp(period_id, nrsp_id) {
		return this.client.get(`${this.url}/norm_on_nrsp/${period_id}/${nrsp_id}/`, apiBase.get_headers());
	},

	getTechPartPdfStrLink(tabValue, row_id) {
		return `${API_BASE_URL}${this.url}/doc/tech_part/link/${tabValue}/${row_id}/pdf/`;
	},

	getTechPartPdfFile(tabValue, row_id) {
		return this.client.get(`${API_BASE_URL}${this.url}/doc/tech_part/link/${tabValue}/${row_id}/pdf/`, apiBase.get_headersPdf());
	},

	getFsnbCache(vers_id) {
		return this.client.get(`${this.url}/cache_elements/${vers_id}/`, apiBase.get_headers());
	},

	getFsnbAutocompete({ val, versId, limit }) {
		return this.client.get(`${this.url}/autocomplete/${versId}/${val}/${limit}/`, apiBase.get_headers());
	},

	getNormFlatTableSearch({ versId, body }) {
		return this.client.post(`${this.url}/norm/table/flat_search/${versId}/`, body, apiBase.get_headers());
	},
};

export default apiFsnb;

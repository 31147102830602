import { useState, useEffect, useLayoutEffect, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, useLocation, useSearchParams, Navigate, useNavigate } from "react-router-dom";

import AuthNew from "./pages/Auth/AuthNew.jsx";
import ProjectsView from "./pages/Projects/ProjectsView.jsx";
import IndexesMain from "./pages/Indexes/IndexesMain.jsx";
import FsnbMain from "./pages/Fsnb/FsnbMain.jsx";
import ImportBrainStorm from "./pages/ImportBrainStorm/ImportBrainStorm.jsx";
import Header from "./components/Header.jsx";
import UserTasks from "./components/UserTasks/UserTasks.jsx";
import ErrorBoundary from "components/ErrorBoundary.jsx";
import RibbonHeaderTabs from "components/Ribbon/RibbonHeaderTabs/RibbonHeaderTabs.jsx";
import EstimateViewerMainWrapper from "pages/EstimateViewer/EstimateViewerMainWrapper.jsx";

import { apiToken } from "api/base";
import apiNewsFeed from "api/news_feed.js";
import apiAuth from "api/auth.js";

import { setNewsFeedVisible } from "slices/global_visible.js";
import { setHasUnreadNews } from "slices/news_feed.js";
import { getUserPermission, Logout, setAuthData } from "slices/auth";
import { fetchResourceSheetList } from "slices/resource_sheet.js";
import { fetchProjectsList } from "slices/projects.js";
import { setWindowHeight, setWindowWidth } from "slices/window_dimensions.js";
import { getUserTasks, getUserSettings, setUserTasksVisible } from "slices/user_adv";

import { getEnvVal } from "core/env.js";
import { doubleUuidv4 } from "core/guid_utils";

const ResourceSheetList = lazy(() => import("./pages/ResourceSheet/ResourceSheetList"));
const ResourceSheetCreator = lazy(() => import("./pages/ResourceSheet/ResourceSheetCreator"));
const ResourceSheetViewer = lazy(() => import("./pages/ResourceSheet/ResourceSheetViewer"));
const ResourceStats = lazy(() => import("./pages/ResourceStats/ResourceStats"));
const EstimateEditor = lazy(() => import("./pages/EstimateEditor/EstimateEditorLayout.jsx"));
const PriceOffersBase = lazy(() => import("./pages/PriceOffersBase/PriceOffersBase.jsx"));
const KatsEditor = lazy(() => import("./pages/KatsEditor/KatsEditorLayout.jsx"));
const SomItem = lazy(() => import("./pages/Som/SomItem/SomItem.jsx"));
const SomList = lazy(() => import("./pages/Som/SomList/SomList.jsx"));

export default function App() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const authFact = useSelector((state) => state.auth.authFact);
	const userPermissions = useSelector((state) => state.auth.permissions);
	const fetchStatus = useSelector((state) => state.projects.fetchStatus);
	const fetchStatusCode = useSelector((state) => state.projects.fetchStatusCode);
	const userTasksVisible = useSelector((state) => state.userAdv.userTasksVisible);
	const projMain = useSelector((state) => state.projectWork.main);
	const tableViewParams = useSelector((state) => state.projects.table.viewParams);

	const [history, pushHistory] = useState([]);
	const location = useLocation();
	const searchParams = useSearchParams()[0];
	const [integParams, setIntegParams] = useState(searchParams.get("integration"));
	const [appIsInited, setAppIsInited] = useState(false);

	const [guestIsInited, setGuestIsInited] = useState(false);

	const setWindowDimensions = () => {
		dispatch(setWindowWidth(window.innerWidth));
		dispatch(setWindowHeight(window.innerHeight));
	};

	const onAppClick = (e) => {
		if (["ribbon-content", "Header"].includes(e.target.className)) {
			dispatch(setUserTasksVisible(false));
		}
	};

	useEffect(() => {
		window.addEventListener("resize", setWindowDimensions);
		window.addEventListener("click", onAppClick);
		const taskStatusLoop = setInterval(() => dispatch(getUserTasks()), 30000);
		return () => {
			window.removeEventListener("resize", setWindowDimensions);
			window.removeEventListener("click", onAppClick);
			clearInterval(taskStatusLoop);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() => {
		if (getEnvVal("App") === "guest") {
			let guestUuid = localStorage.getItem("guestUuid");
			if (!guestUuid) {
				guestUuid = doubleUuidv4();
				localStorage.setItem("guestUuid", guestUuid);
			}
			const authProm = apiAuth.authAsGuest({ guestUuid });
			authProm
				.then((response) => {
					console.log(response);
					dispatch(setAuthData(response.data));
					setGuestIsInited(true);
					navigate(`/project/${response.data.project_id}/start/`);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			const integration = searchParams.get("integration");
			if (integration) {
				const obj = JSON.parse(integration);
				localStorage.setItem("userName", obj.userName);
				localStorage.setItem("userFio", obj.userName);
				localStorage.setItem("userId", obj.userId);
				localStorage.setItem("accessToken", "Bearer " + obj.accessToken);
				localStorage.setItem("refreshToken", obj.refreshToken);
			}
			setIntegParams(null);
			dispatch(getUserSettings({ userId: localStorage.getItem("userId") }));
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!appIsInited && localStorage.getItem("accessToken") && userPermissions && getEnvVal("App") !== "guest") {
			initApp();
		}
	}, [userPermissions?.is_guest]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		pushHistory((prev) => [...prev, location]);
	}, [location]);

	useEffect(() => {
		if (fetchStatusCode === 403 && fetchStatus === "failed") {
			dispatch(Logout());
		}
	}, [fetchStatusCode]); // eslint-disable-line react-hooks/exhaustive-deps

	const initApp = () => {
		projectsInit();
		resourceSheetsInit();
		setAppIsInited(true);
	};

	const newsInit = () => {
		apiNewsFeed
			.getUnreadNewsForUser()
			.then((response) => {
				if (response.data.length) {
					dispatch(setHasUnreadNews(true));
					dispatch(setNewsFeedVisible(true));
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const projectsInit = () => dispatch(fetchProjectsList({ limit: getEnvVal("ProjectsLimit"), ...tableViewParams }));
	const resourceSheetsInit = () => {
		if (userPermissions?.is_res_sheet_user) dispatch(fetchResourceSheetList());
	};

	useEffect(() => {
		if (localStorage.getItem("accessToken")) apiToken.refreshToken();
		if (authFact && getEnvVal("App") !== "guest") {
			newsInit();
			initApp();
		}
	}, [authFact]); // eslint-disable-line react-hooks/exhaustive-deps

	const getImportElement = () => {
		return <ImportBrainStorm history={history} />;
	};

	const getApp = () => {
		return (
			<div className="App">
				<div style={{ width: "100%" }}>
					<Header />
					<RibbonHeaderTabs />
					{userTasksVisible && <UserTasks />}
					<ErrorBoundary>
						<Routes>
							<Route exact path="/" element={<ProjectsView />} />
							<Route exact path="/indexes/*" element={<IndexesMain history={history} />} />
							<Route exact path="/fsnb/*" element={<FsnbMain history={history} />} />
							<Route exact path="/project/:id/*" element={<EstimateViewerMainWrapper history={history} />} />
							<Route
								path="/project/stats/:id"
								element={
									<Suspense>
										<ResourceStats history={history} />
									</Suspense>
								}
							/>
							<Route exact path="/project/:id/import/" element={getImportElement()} />
							<Route exact path="/project/:id/monitor/" element={getImportElement()} />
							<Route
								exact
								path="/project/:projectId/editor/lsr/:estmId/"
								element={
									<Suspense>
										<EstimateEditor history={history} mode="editor" />
									</Suspense>
								}
							/>
							<Route
								exact
								path="/project/:projectId/editor/lsr/new/"
								element={
									<Suspense>
										<EstimateEditor history={history} mode="creation" />
									</Suspense>
								}
							/>
							<Route
								exact
								path="/project/:projectId/editor/kats/:estmId/"
								element={
									<Suspense>
										<KatsEditor history={history} mode="editor" />
									</Suspense>
								}
							/>
							<Route
								exact
								path="/project/:projectId/editor/kats/new/"
								element={
									<Suspense>
										<KatsEditor history={history} mode="creation" />
									</Suspense>
								}
							/>

							<Route
								exact
								path="/resource_sheet/create/"
								element={
									<Suspense>
										<ResourceSheetCreator history={history} refresh={false} />
									</Suspense>
								}
							/>
							<Route
								exact
								path="/resource_sheet/refresh/:id/"
								element={
									<Suspense>
										<ResourceSheetCreator history={history} refresh={true} />
									</Suspense>
								}
							/>
							<Route
								exact
								path="/resource_sheet/:id/"
								element={
									<Suspense>
										<ResourceSheetViewer history={history} />
									</Suspense>
								}
							/>
							<Route
								exact
								path="/resource_sheet/*"
								element={
									<Suspense>
										<ResourceSheetList history={history} />
									</Suspense>
								}
							/>
							<Route
								exact
								path="/price_offers_base/*"
								element={
									<Suspense>
										<PriceOffersBase history={history} />
									</Suspense>
								}
							/>
							<Route
								exact
								path="/som_item/"
								element={
									<Suspense>
										<SomList history={history} />
									</Suspense>
								}
							/>
							<Route
								exact
								path="/som_item/:id/"
								element={
									<Suspense>
										<SomItem history={history} />
									</Suspense>
								}
							/>
							<Route exact path="*" element={<ProjectsView />} />
							<Route path="*" element={<Navigate to="/" replace />} />
						</Routes>
					</ErrorBoundary>
				</div>
			</div>
		);
	};

	const getStartApp = () => {
		if (authFact !== true && !localStorage.getItem("accessToken")) {
			return <AuthNew />;
		} else {
			if (userPermissions == null) {
				const prom = dispatch(getUserPermission());
				prom.then((payload) => {
					if (payload?.type === "permission/rejected") {
						dispatch(Logout());
					} else {
						return getApp();
					}
				}).catch((err) => {
					return <AuthNew />;
				});
			} else {
				return getApp();
			}
		}
	};

	const getGuestApp = () => {
		let redirectUrl = "/fsnb/";
		if (projMain.id) redirectUrl = `/project/${projMain.id}/start/`;
		return (
			<div className="App">
				<div style={{ width: "100%" }}>
					<Header />
					<RibbonHeaderTabs />
					<ErrorBoundary>
						<Routes>
							<Route exact path="/indexes/*" element={<IndexesMain history={history} />} />
							<Route exact path="/fsnb/*" element={<FsnbMain history={history} />} />
							<Route exact path="/project/:id/*" element={<EstimateViewerMainWrapper history={history} />} />
							<Route exact path="/project/:id/import/" element={getImportElement()} />
							<Route exact path="/project/:id/monitor/" element={getImportElement()} />

							<Route exact path="/" element={<Navigate to={redirectUrl} replace />} />
							<Route exact path="*" element={<Navigate to={redirectUrl} replace />} />
							<Route path="*" element={<Navigate to={redirectUrl} replace />} />
						</Routes>
					</ErrorBoundary>
				</div>
			</div>
		);
	};

	if (getEnvVal("App") === "guest" && guestIsInited === true) return getGuestApp();
	if (getEnvVal("App") !== "guest" && integParams == null) return getStartApp();
}

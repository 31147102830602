import { Link, useLocation } from "react-router-dom";

import "./RibbonTab.css";

const RibbonTab = ({ name, route, allowDisplay, icon, isActive, callback, styles }) => {
    const location = useLocation();
    if (!allowDisplay(location.pathname)) {
        return null;
    }

    return (
        <Link
            to={route}
            style={styles}
            className={isActive(location.pathname) ? "ribbon-tab  ribbon-tab-active" : "ribbon-tab"}
            onClick={() => {
                if (callback) callback();
            }}>
            {icon} {name}
        </Link>
    );
};

export default RibbonTab;

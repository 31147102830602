export function getNestedIterableElementsCount(iterable, conditionCallback = null, childrenLoopConditionCallback = null) {
	let count = 0;

	const countElements = (iterable) => {
		iterable.forEach((item) => {
			if (conditionCallback == null) {
				count++;
			} else {
				if (conditionCallback(item)) {
					count++;
				}
			}
			if (item._children) {
				if (childrenLoopConditionCallback) {
					if (childrenLoopConditionCallback(item)) countElements(item._children);
				} else {
					countElements(item._children);
				}
			}
		});
	};

	countElements(iterable);
	return count;
}

export function getNestedIterableElementsCountWithBreak(
	iterable,
	conditionCallback = null,
	breakCallback = null,
	childrenLoopConditionCallback = null
) {
	let count = 0;
	let isFound = false;

	const countElements = (iterable) => {
		iterable.forEach((item) => {
			if (isFound) return;

			if (conditionCallback == null) {
				count++;
			} else {
				if (conditionCallback(item)) {
					count++;
				}
			}
			if (breakCallback(item)) {
				isFound = true;
				return;
			}
			if (childrenLoopConditionCallback) {
				if (item._children && childrenLoopConditionCallback(item)) countElements(item._children);
			} else {
				if (item._children && conditionCallback(item)) countElements(item._children);
			}
		});
	};

	countElements(iterable);
	if (!isFound) count = 0;
	return count;
}

export function searchNestedIterableForValueAndOpen(iterable, inputValue, conditionCallback, useRowHeightMultiply) {
	const foundPositions = [];
	const iterableCopy = copyArr(iterable);
	let count = 0;

	const searchElements = (iterableCopy) => {
		iterableCopy.forEach((item) => {
			count++;
			if (useRowHeightMultiply === true) {
				if (item.rowHeightMultiply && item.rowHeightMultiply > 1) {
					count += item.rowHeightMultiply - 1;
				}
			}
			item.visible = true;
			item.advSelected = null;
			let results = conditionCallback(item, inputValue);
			if (!Array.isArray(results)) {
				results = [results];
			}

			for (let result of results) {
				if (result.status) {
					foundPositions.push(count);
					if (!item.advSelected) {
						item.advSelected = `__${result.key}__`;
					} else {
						item.advSelected = `__${item.advSelected}__${result.key}__`;
					}
				}
			}

			if (item._children) {
				item.childs_visible = true;
				searchElements(item._children);
			}
		});
	};

	searchElements(iterableCopy);
	return { iterableCopy: iterableCopy, foundPositions: foundPositions };
}

export function getArrayChildByPosition(iterable, position = 0) {
	if (!Array.isArray(iterable)) return;
	if (iterable.length <= position) return;
	return iterable.at(position);
}

export function changeNestedIterableKeyValue(iterable, key, value, childrenKey = "_children") {
	for (let item of iterable) {
		item[key] = value;
		if (item[childrenKey]) changeNestedIterableKeyValue(item[childrenKey], key, value, childrenKey);
	}
	return iterable;
}

export function changeNestedArrKeyValueOnCondition({ arr, keySearch, valSearch, keyChange, valChange, childrenKey = "_children", callback }) {
	let isFound = false;

	const changeKeyValue = (arr) => {
		for (let row of arr) {
			if (isFound) return;
			if (row[keySearch] === valSearch) {
				row[keyChange] = valChange;
				isFound = true;
				if (callback) callback(row);
			} else {
				if (row[childrenKey]) {
					changeKeyValue(row[childrenKey]);
				}
			}
		}
	};

	changeKeyValue(arr);

	return arr;
}

export function getChangeVisibleFunc(updateDataFunc) {
	let needRecource = true;

	function changeRecourceBoolArg(row, clickedRow, clm, callback) {
		if (!needRecource) return row;
		if (row.key === clickedRow.key) {
			row[clm] == null ? (row[clm] = true) : (row[clm] = !row[clm]);
			callback(row, clm, row[clm]);
			needRecource = false;
		} else {
			if (row._children != null) {
				for (let ch of row._children) changeRecourceBoolArg(ch, clickedRow, clm, callback);
			}
		}
		return row;
	}

	return ({ clickedRow }) => {
		needRecource = true;
		const callback = (row, clm, val) => {
			if (row._children != null) {
				for (let ch of row._children) {
					ch.visible = val;
					callback(ch, clm, val);
				}
			}
		};
		updateDataFunc((prev) =>
			prev.map((row) => {
				return changeRecourceBoolArg(row, clickedRow, "childs_visible", callback);
			})
		);
	};
}

export function copyArr(arr) {
	if (!arr) return;
	return JSON.parse(JSON.stringify(arr));
}

export function copyObj(obj) {
	if (!obj) return;
	return JSON.parse(JSON.stringify(obj));
}

export function checkKeyValueExistNested(iterable, key, arrValue, childrenKey = "_children") {
	let isFound = false;

	const checkKeyValueExist = (iterable, key, arrValue, childrenKey) => {
		for (let item of iterable) {
			if (arrValue.includes(item[key])) {
				isFound = true;
				return;
			} else {
				if (item[childrenKey]) checkKeyValueExist(item[childrenKey], key, arrValue, childrenKey);
			}
		}
	};

	checkKeyValueExist(iterable, key, arrValue, childrenKey);
	return isFound;
}

export function getNextObjInArrOnPrevKeyVal({ arr, key, value }) {
	const prevIndex = arr.map((x) => x[key]).indexOf(value);
	if (prevIndex + 1 < arr.length) {
		return arr[prevIndex + 1];
	} else {
		return arr[0];
	}
}

export function arrProxy(arr) {
	if (arr == null) {
		return [];
	}
	return arr;
}

export function sortObjArrByNumberAsStr({ data, clmName, direction }) {
	const prepare = (val) => {
		let strVal = String(val).replaceAll(" ", "");
		return parseFloat(strVal);
	};

	if (direction === "up") {
		data = data.sort((a, b) => (prepare(a[clmName]) < prepare(b[clmName]) ? 1 : -1));
	} else if (direction === "down") {
		data = data.sort((a, b) => (prepare(a[clmName]) > prepare(b[clmName]) ? 1 : -1));
	}

	return data;
}

export function getNextInArr({ arr, value }) {
	const prevIndex = arr.indexOf(value);
	if (prevIndex + 1 < arr.length) {
		return arr[prevIndex + 1];
	} else {
		return arr[prevIndex];
	}
}

export function getPrevInArr({ arr, value }) {
	const prevIndex = arr.indexOf(value);
	if (prevIndex - 1 >= 0) {
		return arr[prevIndex - 1];
	} else {
		return arr[prevIndex];
	}
}

export function searchNestedIterableItem({
	iterable,
	isSeekRowCallback,
	isPermittedRowCallback,
	isPermittedChildsLoopCallback,
	useRowHeightMultiply,
	breakAfterFirstMatch,
}) {
	let seekItem;
	let isFound = false;
	let prevItem;
	let nextItem;
	let seekCount = 0;
	let parentItem;

	const searchElements = (iterable) => {
		if (breakAfterFirstMatch === true && isFound === true) return;
		iterable.forEach((item) => {
			if (isFound && nextItem) return;
			if (isSeekRowCallback(item) && !isFound) {
				seekItem = item;
				isFound = true;
				seekCount++;
			} else if (isPermittedRowCallback && isPermittedRowCallback(item)) {
				if (isFound) {
					nextItem = item;
				} else {
					seekCount++;
					prevItem = item;
					if (useRowHeightMultiply === true) {
						if (item.rowHeightMultiply && item.rowHeightMultiply > 1) {
							seekCount += item.rowHeightMultiply - 1;
						}
					}
				}
			}
			if (item._children && isPermittedChildsLoopCallback && isPermittedChildsLoopCallback(item)) {
				parentItem = item;
				searchElements(item._children);
			}
		});
	};

	searchElements(iterable);
	return { prevItem, seekItem, nextItem, seekCount, parentItem };
}

export function getObjGroupInNestedArray({ nestedData, objectKey }) {
	let seekGroup;

	const seekObjKeyGroup = ({ arr }) => {
		for (let row of arr) {
			if (row.key === objectKey) {
				seekGroup = arr;
				return;
			} else {
				if (row._children) seekObjKeyGroup({ arr: row._children });
			}
		}
	};

	seekObjKeyGroup({ arr: nestedData });
	return seekGroup;
}

export function getSliceFromArrByKeys({ arr, startKey, endKey }) {
	const seekSlice = [];
	let allowAdding = false;

	for (let row of arr) {
		if (row.key === startKey) allowAdding = true;
		if (allowAdding) seekSlice.push(row);
		if (row.key === endKey) break;
	}

	return seekSlice;
}

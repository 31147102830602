import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiProjects from "../api/projects";
import { getNestedIterableElementsCount } from "core/iterable_utils";
import { downloadFile } from "core/request_utils";
import { changeRowDataRecourse } from "./utils";

export const fetchProject = createAsyncThunk("projects/fetchProject", async (id) => {
	const response = await apiProjects.get(id);
	return response.data;
});

export const fetchProjectTree = createAsyncThunk("projects/fetchProjectTree", async (id) => {
	const response = await apiProjects.getTree(id);
	return response.data;
});

export const forceFetchProjectTree = createAsyncThunk("projects/forceFetchProjectTree", async (id) => {
	const response = await apiProjects.getTree(id);
	return response.data;
});

export const fetchProjectMonitorData = createAsyncThunk("projects/fetchProjectMonitor", async (id) => {
	const response = await apiProjects.getMonitorData(id);
	return response.data;
});

export const fetchLsrMonitorData = createAsyncThunk("projects/fetchLsrMonitorData", async (id) => {
	const response = await apiProjects.getLsrMonitorData(id);
	return response.data;
});

export const fetchProjectResStatsData = createAsyncThunk("projects/fetchProjectResStatsData", async (id) => {
	const response = await apiProjects.getLsrResStatsData(id);
	return response.data;
});

export const fetchProjectResStatsExcel = createAsyncThunk("projects/fetchProjectResStatsExcel", async (id) => {
	const response = await apiProjects.getLsrResStatsExcel(id);
	downloadFile(response);
});

export const fetchProjectErrorsExcel = createAsyncThunk("projects/fetchProjectErrorsExcel", async (id) => {
	const response = await apiProjects.getProjectErrorsExcel(id);
	downloadFile(response);
});

export const getProjectPrintXlsx = createAsyncThunk("projects/getProjectPrintXlsx", async (id) => {
	await apiProjects.getProjectPrintXlsx(id);
});

export const getPartProjectPrintXlsx = createAsyncThunk("projects/getPartProjectPrintXlsx", async ({ id, body }) => {
	await apiProjects.getPartProjectPrintXlsx(id, body);
});

export const getProjectPrintXlsxWithErrors = createAsyncThunk("projects/getProjectPrintXlsxWithErrors", async (id) => {
	await apiProjects.getProjectPrintXlsxWithErrors(id);
});

export const getPartProjectPrintXlsxWithErrors = createAsyncThunk("projects/getProjectPrintXlsxWithErrors", async ({ id, body }) => {
	await apiProjects.getPartProjectPrintXlsxWithErrors(id, body);
});

export const downloadPrinterXlsxResult = createAsyncThunk("projects/getProjectPrintXlsxWithErrors", async ({ taskId }) => {
	const response = await apiProjects.downloadPrinterXlsxResult({ taskId });
	downloadFile(response);
});

export const getProjectInputXml = createAsyncThunk("projects/getProjectInputXml", async (id) => {
	const response = await apiProjects.getProjectInputXml(id);
	downloadFile(response);
});

export const getProjectLsrOsrReestr = createAsyncThunk("projects/getProjectLsrOsrReestr", async (id) => {
	const response = await apiProjects.getProjectLsrOsrReestr(id);
	downloadFile(response);
});

export const dedublicateProjectLsr = createAsyncThunk("projects/dedublicateProjectLsr", async ({ projectId }) => {
	const response = await apiProjects.dedublicateProjectLsr({ projectId });
	return response.data;
});

export const undoDedublicateProjectLsr = createAsyncThunk("projects/undoDedublicateProjectLsr", async ({ projectId }) => {
	const response = await apiProjects.undoDedublicateProjectLsr({ projectId });
	return response.data;
});

export const makeProjectLsrDoubleWinner = createAsyncThunk("projects/dedublicateProjectLsr", async ({ projectId, groupId, lsrId }) => {
	const response = await apiProjects.makeProjectLsrDoubleWinner({ projectId, groupId, lsrId });
	return response.data;
});

export const projectWorkSlice = createSlice({
	name: "projectsSlice",
	fetchStatus: null,
	fetchStatusText: null,
	fetchMonStatus: null,
	fetchTreeStatus: null,
	initialState: {
		main: {
			name: "",
			code: "",
			id: null,
			price_zone_name: null,
			date_create: null,
			price_zone_id: null,
			north_reg_terr_id: null,
			nrsp_vers_id: null,
		},
		tree: [],
		treeSelected: [],
		treeClicked: {},
		monitorData: [],
		fsnbData: [],
		perData: [],
		objData: [],
		indData: [],
		indOrgData: [],
		estmMethodicData: [],
		statsResData: [],
		editingMode: false,
	},
	reducers: {
		setMainOpts: (state, { payload }) => {
			state.main = payload;
		},
		setFetchStatus: (state, { payload }) => {
			state.fetchStatus = payload;
		},
		setMonFetchStatus: (state, { payload }) => {
			state.fetchMonStatus = payload;
		},
		setSelected: (state, { payload }) => {
			if (payload) {
				if (Array.isArray(payload)) {
					state.treeSelected = payload;
				} else {
					state.treeSelected = [payload];
				}
			} else {
				state.treeSelected = [];
			}
		},
		unsetSelected: (state, { payload }) => {
			state.treeSelected = state.treeSelected.filter((x) => x["key"] !== payload["key"]);
		},
		changeEditingMode: (state, { payload }) => {
			state.editingMode = payload;
		},
		changeChildsVisible: (state, { payload }) => {
			let found = false;
			const updateItem = (item) => {
				if (found) return;
				if (!item._children) return;

				if (item.key === payload) {
					item.childs_visible = item.childs_visible === undefined ? false : !item.childs_visible;
					found = true;
				} else {
					item._children.forEach(updateItem);
				}
			};
			state.tree.forEach(updateItem);
		},
		editTreeLocalRow: (state, { payload }) => {
			let copy = JSON.parse(JSON.stringify(state.tree));
			let edited = false;
			for (let sumEstm of copy) {
				for (let obj of sumEstm["_children"]) {
					let lsrs = obj["_children"];
					let filtred = [];
					if (lsrs != null) {
						for (let lsr of lsrs) {
							if (lsr["id"] === payload["id"]) {
								Object.keys(payload).forEach(function eachKey(key) {
									lsr[key] = payload[key];
								});
								lsr["innertext"] = "ЛСР " + lsr["code"] + ". " + lsr["name"];
								edited = true;
							}
							filtred.push(lsr);
						}
						obj["_children"] = filtred;
					}
				}
				if (edited === true) {
					break;
				}
			}
			state.tree = copy;
		},
		editTreeObjectRow: (state, { payload }) => {
			let copy = JSON.parse(JSON.stringify(state.tree));
			for (let sumEstm of copy) {
				const objs = sumEstm["_children"];
				const filtred = [];
				if (objs == null) {
					continue;
				}
				for (let obj of objs) {
					if (obj["id"] === payload["id"]) {
						Object.keys(payload).forEach(function eachKey(key) {
							obj[key] = payload[key];
						});
						obj["innertext"] = "ОСР " + obj["code"] + ". " + obj["name"];
					}
					filtred.push(obj);
				}
				sumEstm["_children"] = filtred;
			}
			state.tree = copy;
		},
		clearTree: (state, { payload }) => {
			state.tree = [];
			state.treeSelected = [];
			state.editingMode = false;
			state.statsResData = [];
			state.main = {
				name: "",
				code: "",
				id: null,
			};
		},
		changeMonitorVisable: (state, { payload }) => {
			state.monitorData = state.monitorData.map((row) => {
				if (row["key"] === payload) {
					row["childs_visible"] = !row["childs_visible"];
				} else {
					if (row["_children"] != null) {
						for (let child of row["_children"]) {
							if (child["key"] === payload) {
								child["childs_visible"] = !child["childs_visible"];
								break;
							}
						}
					}
				}
				return row;
			});
		},
		changeDetailsVisable: (state, { payload }) => {
			state.monitorData = workWithLsrMonitor({
				data: state.monitorData,
				id_key: "key",
				id_val: payload,
				key_vals: [{ key: "details_visible", neg: true }],
			});
		},
		changeStepsStatus: (state, { payload }) => {
			let id_key = payload.id_key;
			if (id_key == null) id_key = "id";
			state.monitorData = workWithLsrMonitor({
				data: state.monitorData,
				id_key: id_key,
				id_val: payload.id_val,
				key_vals: payload.key_vals,
			});
		},
		setProjectResStatsData: (state, { payload }) => {
			state.statsResData = payload;
		},
		changeStatsResChildVisible: (state, { payload: row }) => {
			state.statsResData = state.statsResData.map((x_row) => {
				if (x_row["id"] === row["id"]) {
					x_row["childs_visible"] = !x_row["childs_visible"];
					for (let ch of x_row["_children"]) {
						ch["visible"] = !ch["visible"];
					}
				}
				return x_row;
			});
		},
		setMonitorData: (state, { payload }) => {
			state.monitorData = payload;
		},
		setFetchStatusText: (state, { payload }) => {
			state.fetchStatusText = payload;
		},
		changeProjectTree: (state, { payload: { rowKey, callback } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.tree = changeRowDataRecourse(state.tree, rowKey, callback);
		},
		setTreeClicked: (state, { payload }) => {
			state.treeClicked = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProject.fulfilled, (state, { payload }) => {
			state.main.name = payload[0]["name"];
			state.main.code = payload[0]["code"];
			state.main.id = payload[0]["id"];
			state.main.price_zone_id = payload[0]["price_zone_id"];
			state.main.date_create = payload[0]["date_create"];
			state.main.price_zone_name = payload[0]["price_zone_name"];
			state.main.north_reg_terr_id = payload[0]["north_reg_terr_id"];
			state.main.nrsp_vers_id = payload[0]["nrsp_vers_id"];
			state.fetchStatus = "success";
		});
		builder.addCase(fetchProjectTree.fulfilled, (state, { payload }) => {
			const currentCount = getNestedIterableElementsCount(state.tree);
			const payloadCount = getNestedIterableElementsCount(payload);
			if (currentCount !== payloadCount) state.tree = payload;
			state.fetchTreeStatus = "success";
		});
		builder.addCase(fetchProjectMonitorData.fulfilled, (state, { payload }) => {
			state.monitorData = payload["data"];
			state.fsnbData = payload["fsnb_data"];
			state.perData = payload["per_data"];
			state.pzData = payload["pz_data"];
			state.objData = payload["obj_data"];
			state.indData = payload["ind_data"];
			state.indOrgData = payload["ind_org_data"];
			state.estmMethodicData = payload["estm_methodic_data"];
			state.fetchMonStatus = "success";
		});
		builder.addCase(fetchLsrMonitorData.fulfilled, (state, { payload }) => {
			let isFound = false;
			state.monitorData = state.monitorData.map((row) => {
				if (row["_children"] == null || isFound === true) {
					return row;
				}
				for (let osr of row["_children"]) {
					if (osr["_children"] == null) {
						continue;
					}
					for (let lsr of osr["_children"]) {
						if (lsr["id"] === payload["id"]) {
							for (let key of [
								"fsnb_vers",
								"obj_vers",
								"per_vers",
								"pz_vers",
								"ind_vers",
								"base_ot_index",
								"base_mech_index",
								"base_mat_index",
								"base_eqp_index",
								"base_index_use",
								"base_pg_bort_index",
								"base_pg_sam_index",
								"base_pg_pan_index",
								"base_pg_trub_index",
								"base_pg_bet_index",
							]) {
								lsr[key] = payload[key];
							}
							lsr["details_visible"] = true;
							isFound = true;
							return row;
						}
						if (lsr["id"] === payload["id"]) break;
					}
				}
				return row;
			});
			state.fetchMonStatus = "success";
		});

		builder.addCase(fetchProjectResStatsData.fulfilled, (state, { payload }) => {
			state.statsResData = payload;
			state.fetchMonStatus = "success";
		});

		builder.addMatcher(
			isAnyOf(
				forceFetchProjectTree.fulfilled,
				dedublicateProjectLsr.fulfilled,
				undoDedublicateProjectLsr.fulfilled,
				makeProjectLsrDoubleWinner.fulfilled
			),
			(state, { payload }) => {
				state.tree = payload;
				state.fetchTreeStatus = "success";
				state.fetchMonStatus = "success";
			}
		);

		builder.addMatcher(
			isAnyOf(
				fetchProjectResStatsExcel.fulfilled,
				fetchProjectErrorsExcel.fulfilled,
				getProjectPrintXlsx.fulfilled,
				getProjectInputXml.fulfilled,
				getProjectPrintXlsxWithErrors.fulfilled,
				getPartProjectPrintXlsx.fulfilled,
				getPartProjectPrintXlsxWithErrors.fulfilled,
				getProjectLsrOsrReestr.fulfilled
			),
			(state, action) => {
				state.fetchMonStatus = "success";
			}
		);

		builder.addMatcher(isAnyOf(fetchProject.pending), (state, action) => {
			state.fetchStatus = "loading";
		});

		builder.addMatcher(isAnyOf(fetchProjectTree.pending), (state, action) => {
			state.fetchTreeStatus = "loading";
		});

		builder.addMatcher(isAnyOf(fetchProject.rejected), (state, action) => {
			state.fetchStatus = "failed";
			if (action["payload"] != null) {
				if (action["payload"].hasOwnProperty("error_text")) {
					state.fetchStatusText = action["payload"]["error_text"];
				}
			}
		});

		builder.addMatcher(isAnyOf(fetchProjectTree.rejected), (state, action) => {
			state.fetchTreeStatus = "failed";
		});

		builder.addMatcher(
			isAnyOf(
				fetchProjectMonitorData.pending,
				fetchLsrMonitorData.pending,
				fetchProjectResStatsData.pending,
				fetchProjectResStatsExcel.pending,
				fetchProjectErrorsExcel.pending,
				getProjectPrintXlsx.pending,
				getProjectInputXml.pending,
				getProjectPrintXlsxWithErrors.pending,
				getPartProjectPrintXlsx.pending,
				getPartProjectPrintXlsxWithErrors.pending,
				getProjectLsrOsrReestr.pending,
				dedublicateProjectLsr.pending,
				undoDedublicateProjectLsr.pending,
				makeProjectLsrDoubleWinner.pending
			),
			(state, action) => {
				state.fetchMonStatus = "loading";
			}
		);
		builder.addMatcher(
			isAnyOf(
				fetchProjectMonitorData.rejected,
				fetchLsrMonitorData.rejected,
				fetchProjectResStatsData.rejected,
				fetchProjectResStatsExcel.rejected,
				fetchProjectErrorsExcel.rejected,
				getProjectPrintXlsx.rejected,
				getProjectInputXml.rejected,
				getProjectPrintXlsxWithErrors.rejected,
				getPartProjectPrintXlsx.rejected,
				getPartProjectPrintXlsxWithErrors.rejected,
				getProjectLsrOsrReestr.rejected,
				dedublicateProjectLsr.rejected,
				undoDedublicateProjectLsr.rejected,
				makeProjectLsrDoubleWinner.rejected
			),
			(state, action) => {
				state.fetchMonStatus = "failed";
			}
		);
	},
});

function workWithLsrMonitor(params) {
	const id_key = params["id_key"];
	const id_val = params["id_val"];
	const key_vals_lst = params["key_vals"];

	// console.log("params", params)

	const changeValue = (obj) => {
		for (let kv of key_vals_lst) {
			if (kv["neg"] === true) {
				obj[kv["key"]] = !obj[kv["key"]];
			} else {
				obj[kv["key"]] = kv["val"];
			}
		}
	};

	return params["data"].map((row) => {
		if (row["_children"] != null) {
			for (let osr of row["_children"]) {
				if (osr["_children"] != null) {
					for (let lsr of osr["_children"]) {
						if (lsr[id_key] === id_val) {
							changeValue(lsr);
							break;
						}
					}
				} else if (osr.type === "kats-child") {
					// console.log("row", { ...osr })
					// console.log(osr[id_key], id_val, osr[id_key] === id_val)
					if (osr[id_key] === id_val) {
						// console.log("1", JSON.parse(JSON.stringify(osr)))
						changeValue(osr);
						// console.log("2", JSON.parse(JSON.stringify(osr)))
						break;
					}
				}
			}
		}
		return row;
	});
}

export const {
	setMainOpts,
	setSelected,
	changeEditingMode,
	changeChildsVisible,
	changeChildsVisibleStart,
	unsetSelected,
	setEditingObj,
	editTreeLocalRow,
	editTreeObjectRow,
	clearTree,
	updateSumEstmData,
	changeMonitorVisable,
	changeDetailsVisable,
	changeStepsStatus,
	setProjectResStatsData,
	changeStatsResChildVisible,
	changeSumEstmErrorsVisible,
	setFetchStatus,
	setMonitorData,
	setMonFetchStatus,
	setFetchStatusText,
	changeProjectTree,
	setTreeClicked,
} = projectWorkSlice.actions;

export default projectWorkSlice.reducer;

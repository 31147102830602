import { useSelector, useDispatch } from "react-redux";

import MonitorTableDetails from "pages/Monitor/MonitorTableDetails";

import { handlelocalEstimateIndex } from "slices/local_estimate";
import { getlocalEstimateIndexDirect } from "slices/calcer";
import { changeMonitorDataDetailsVisible, changeModalVisible } from "slices/import_files";

import "./MonitorTableRowDetails.css";

const MonitorTableRowDetails = ({ row }) => {
    const dispatch = useDispatch();

    const fsnbData = useSelector((state) => state.importFiles.fsnbData);
    const perData = useSelector((state) => state.importFiles.perData);
    const pzData = useSelector((state) => state.importFiles.pzData);
    const objData = useSelector((state) => state.importFiles.objData);
    const indData = useSelector((state) => state.importFiles.indData);
    const indOrgData = useSelector((state) => state.importFiles.indOrgData);
    const estmMethodicData = useSelector((state) => state.importFiles.estmMethodicData);

    let detailsHeight = 0;

    if (row.details_visible === true) {
        if (row.methodic === "bim") {
            detailsHeight = 138;
        } else if (row.methodic === "rim") {
            detailsHeight = 100;
        }
    }

    return (
        <div className="MonitorTableRowDetails" style={{ maxHeight: detailsHeight }}>
            <MonitorTableDetails
                row={row}
                height={detailsHeight}
                fsnbData={fsnbData}
                perData={perData}
                pzData={pzData}
                objData={objData}
                indData={indData}
                indOrgData={indOrgData}
                estmMethodicData={estmMethodicData}
                save_func_back={handlelocalEstimateIndex}
                get_opts_func={getlocalEstimateIndexDirect}
                f_close_details={() => changeMonitorDataDetailsVisible({ row: row })}
                f_setModalOpen={(val) => dispatch(changeModalVisible({ key: "estimateTree", value: val }))}
                f_setModalRecIndexVisible={(val) => dispatch(changeModalVisible({ key: "recomIndex", value: val }))}
                dispatch={dispatch}
            />
        </div>
    );
};

export default MonitorTableRowDetails;

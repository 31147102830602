import apiKatsEstimate from "api/kats";
import { getNestedIterableElementsCountWithBreak } from "core/iterable_utils";

import "./LinkableErrorCell.css";

const LinkableErrorCell = ({ rowObj }) => {
    const splitText = String(rowObj.text_with_action.text).split("$1");

    if (!splitText.length < 3) {
        splitText.push("");
        splitText.push("");
        splitText.push("");
    }

    return (
        <>
            <span>{splitText[0]}</span>
            <span
                className="LinkableErrorCell"
                onClick={() => {
                    if (rowObj.text_with_action.action) {
                        if (rowObj.text_with_action.action === "open_new_tab") {
                            const prom = apiKatsEstimate.getTree(rowObj.text_with_action.other_ka_id);
                            prom.then((response) => {
                                const otherKaPos = getNestedIterableElementsCountWithBreak(
                                    response.data.table_data,
                                    (row) => true,
                                    (row) => row.key === rowObj.text_with_action.key_for_scroll
                                );
                                // localStorage.setItem("scrollType", "kats");
                                localStorage.setItem("scrollKey", rowObj.text_with_action.key_for_scroll);
                                localStorage.setItem("highlightRowClm", "code");
                                localStorage.setItem("displayScrollTop", otherKaPos);
                                window.open(rowObj.text_with_action.path);
                            });
                        }
                    }
                }}>
                {splitText[1]}
            </span>
            <span>{splitText[2]}</span>
        </>
    );
};

export default LinkableErrorCell;

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	changeRowData,
	setTableData,
	setCancelActions,
	setData,
	setVisibleSettings,
	setPrevCell,
	selectItemRowWithChilds,
	setEditorCellisActive,
	setInputAutocompleteSettings,
	increaseFiltredDataAutocompleteSetting,
	decreaseFiltredDataAutocompleteSetting,
	setClickedRowBeforeNav,
	setSelectedItems,
	setRowsMap,
	setItemsMap,
	setItemIdAddAfter,
} from "slices/local_estimate_editor";
import { forceFetchProjectTree } from "slices/project_work";
import {
	setPanelFoundRowPositionIndex,
	setPanelFoundRowPositions,
	setClickedTableClmName,
	setClickedTableRow,
	setNavigateFromEditor,
	setEditorEstmDocClickedSectionId,
} from "slices/ribbon";
import { setConfirmOptions } from "slices/confirm";
import { setStartSearchObj, setStartSearchCode } from "slices/fsnb";

import apiLocalEstimateEditor from "api/local_estimate_editor";
import apiFsnb from "api/fsnb";

import { getNestedIterableElementsCountWithBreak, searchNestedIterableForValueAndOpen } from "core/iterable_utils";

import LocalEstimateTableColumnsRim, { getLocalEstimateTableColumnsRimFlat } from "../components/LocalEstimateTableColumnsRim";
import LocalEstimateTableColumnsBim from "../components/LocalEstimateTableColumnsBim";

import { XmlResourceTypeEnum } from "core/enums";
import { getNextInArr, getPrevInArr, searchNestedIterableItem } from "core/iterable_utils";
import { copyToClipboard } from "core/clipboard_utils";
import { genConstValue } from "pages/EstimateEditor/const";

const useEstimateManager = ({ estmId, projectId, _setFetchStatus, _setFetchStatusText, _setFetchCircleStatus, scrollBlock, hooks, modalVisible }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const estmData = useSelector((state) => state.localEstimateEditor.data);
	const tableData = useSelector((state) => state.localEstimateEditor.tableData);
	const prevCell = useSelector((state) => state.localEstimateEditor.prevCell);
	const editorCellisActive = useSelector((state) => state.localEstimateEditor.editorCellisActive);
	const inputAutocompleteSettings = useSelector((state) => state.localEstimateEditor.inputAutocompleteSettings);
	const selectedItems = useSelector((state) => state.localEstimateEditor.selectedItems);
	const ItemIdAddAfter = useSelector((state) => state.localEstimateEditor.ItemIdAddAfter);
	const panelSearchNumber = useSelector((state) => state.ribbon.panelSearchNumber);
	const panelSearchValue = useSelector((state) => state.ribbon.panelSearchValue);
	const clickedTablePrevRow = useSelector((state) => state.ribbon.clickedTablePrevRow);
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const clickedTableClmName = useSelector((state) => state.ribbon.clickedTableClmName);
	const setFetchStatus = (data) => dispatch(_setFetchStatus(data));
	const setFetchStatusText = (data) => dispatch(_setFetchStatusText(data));
	const setFetchCircleStatus = (data) => dispatch(_setFetchCircleStatus(data));

	const searchPanelRowPosition = () => {
		if (!panelSearchNumber) {
			dispatch(setPanelFoundRowPositionIndex(0));
		} else {
			const rowPosition = getNestedIterableElementsCountWithBreak(
				tableData,
				(row) => row.visible !== false,
				(row) => {
					if (row.num) {
						return String(row.num) === String(panelSearchNumber);
					}
				}
			);
			dispatch(setPanelFoundRowPositionIndex(rowPosition));
		}
	};

	const searchPanelRowPositions = () => {
		if (!panelSearchValue || panelSearchValue.length < 3) {
			dispatch(setPanelFoundRowPositions([]));
		} else {
			const result = searchNestedIterableForValueAndOpen(tableData, panelSearchValue, (row, inputValue) => {
				for (const key in row) {
					if (key === "key") continue;
					let inputValueFix = String(inputValue).toLowerCase().replace(" ", "");
					let rowValFix = String(row[key]).toLowerCase().replace(" ", "");

					if (row[key] && rowValFix.includes(inputValueFix)) {
						return { status: true, key: key };
					}
				}
				return { status: false, key: null };
			});
			dispatch(setPanelFoundRowPositions(result.foundPositions));
			dispatch(setTableData(result.iterableCopy));
		}
	};

	const getColumnsData = () => {
		let columnsData = [];
		if (estmData != null) {
			if (estmData.hasOwnProperty("methodic")) {
				if (estmData.methodic === "РИМ") {
					columnsData = LocalEstimateTableColumnsRim({ hooks });
				} else if (estmData.methodic === "БИМ") {
					columnsData = LocalEstimateTableColumnsBim({ hooks });
				}
			}
		}
		return columnsData;
	};

	const selectTableRow = ({ mode }) => {
		if (clickedTablePrevRow) {
			dispatch(
				changeRowData({
					rowKey: clickedTablePrevRow.key,
					callback: (row) => (row.selected = false),
				})
			);
			if (mode === "single") {
				dispatch(
					selectItemRowWithChilds({
						itemId: clickedTablePrevRow.item_id,
						sectionId: clickedTablePrevRow.section_id,
					})
				);
				if (selectedItems.length > 0) {
					for (let item of selectedItems) {
						if (!item) continue;
						if (item.key === clickedTablePrevRow.key) continue;
						dispatch(
							selectItemRowWithChilds({
								itemId: item.item_id,
								sectionId: item.section_id,
							})
						);
					}
				}
			}
		}
		if (clickedTableRow) {
			dispatch(
				changeRowData({
					rowKey: clickedTableRow.key,
					callback: (row) => (row.selected = true),
				})
			);
			dispatch(
				selectItemRowWithChilds({
					itemId: clickedTableRow.item_id,
					sectionId: clickedTableRow.section_id,
					color: "#f5f5f575",
				})
			);
		}

		if (mode === "single") {
			dispatch(setSelectedItems([clickedTableRow]));
		} else if (mode === "multiple") {
			if (selectedItems.filter((row) => row.item_id === clickedTableRow.item_id).length) {
				dispatch(setSelectedItems(selectedItems.filter((row) => row.item_id !== clickedTableRow.item_id)));
				dispatch(
					selectItemRowWithChilds({
						itemId: clickedTableRow.item_id,
						sectionId: clickedTableRow.section_id,
					})
				);
			} else {
				dispatch(setSelectedItems([...selectedItems, clickedTableRow]));
			}
		}
	};

	const getObjTypeOnModelName = (modelName) => {
		if (modelName === "LocalEstimatesResMaterial") {
			return "mat";
		} else if (modelName === "LocalEstimatesResEquipment") {
			return "eqp";
		} else if (modelName === "LocalEstimatesResMachine") {
			return "mach";
		} else if (modelName === "LocalEstimatesResWorker") {
			return "wrk";
		} else if (modelName === "LocalEstimatesResTransport") {
			return "trps";
		} else if (modelName === "LocalEstimatesResVoid") {
			return "void";
		}
	};

	const editorFactory = ({ rowObj, dataObj, clmName, keyValArr, thenNavigate, thenAlert, thenScroll, isPg }) => {
		console.log("_______ editorFactory", dataObj, clmName, keyValArr, dataObj[clmName]);
		// console.log("rowObj", rowObj);
		// console.log("-------------");

		dispatch(setInputAutocompleteSettings({ disableInputEnter: false }));

		if (rowObj.id && String(prevCell.value) === String(dataObj[clmName]) && prevCell.key === rowObj.key) {
			dispatch(
				changeRowData({
					rowKey: rowObj.key,
					callback: (row) => {
						row.inline_cell = "";
						row[clmName] = prevCell.value;
					},
				})
			);
			return;
		}

		if (rowObj.model_name === "LocalEstimateRimSections") {
			if (rowObj.id == null) {
				hooks.sectionEditor.addSection({ name: dataObj.name });
			} else {
				hooks.sectionEditor.editSection({ sectionId: rowObj.id, name: dataObj.name });
			}
		} else if (rowObj.model_name === "LocalEstimatesItems") {
			if (rowObj.id == null) {
				if (
					isPg &&
					["LocalEstimatesResMaterial", "LocalEstimatesResEquipment"].includes(clickedTableRow?.model_name) &&
					(clickedTableRow?.is_item === "1" || clickedTableRow?.xml_res_type_id === 2)
				) {
					// подчиненная перевозка
					hooks.itemEditor.editItemValArr({
						sectionId: clickedTableRow.section_id,
						itemId: clickedTableRow.item_id,
						keyValArr: [
							{
								key: "dependant_pg_add",
								value: dataObj.code,
							},
						],
						objType: getObjTypeOnModelName(clickedTableRow.model_name),
						resId: clickedTableRow.id,
					});
				} else {
					hooks.itemEditor.addItemByCode({
						sectionId: rowObj.section_id,
						requestCode: dataObj.code,
						ItemIdAddAfter: ItemIdAddAfter,
						thenNavigate,
						thenAlert,
						thenScroll,
					});
					dispatch(setItemIdAddAfter(0));
				}
			} else {
				if (keyValArr) {
					hooks.itemEditor.editItemValArr({
						sectionId: rowObj.section_id,
						itemId: rowObj.item_id,
						keyValArr: keyValArr,
						objType: "cost",
						resId: 0,
					});
				} else {
					hooks.itemEditor.editItem({
						sectionId: rowObj.section_id,
						itemId: rowObj.id,
						key: clmName,
						value: dataObj[clmName],
						objType: "cost",
						resId: 0,
					});
				}
			}
		} else if (
			([
				"LocalEstimatesResMaterial",
				"LocalEstimatesResEquipment",
				"LocalEstimatesResMachine",
				"LocalEstimatesResTransport",
				"LocalEstimatesResVoid",
			].includes(rowObj.model_name) &&
				rowObj.is_item === "1") ||
			(["LocalEstimatesResMaterial", "LocalEstimatesResMachine", "LocalEstimatesResEquipment"].includes(rowObj.model_name) &&
				rowObj?.xml_res_type_id === XmlResourceTypeEnum.ITEM_RESOURCE)
		) {
			if (rowObj.id !== null) {
				if (keyValArr) {
					hooks.itemEditor.editItemValArr({
						sectionId: rowObj.section_id,
						itemId: rowObj.item_id,
						keyValArr: keyValArr,
						objType: getObjTypeOnModelName(rowObj.model_name),
						resId: rowObj.id,
					});
				} else {
					hooks.itemEditor.editItem({
						sectionId: rowObj.section_id,
						itemId: rowObj.item_id,
						key: clmName,
						value: dataObj[clmName],
						objType: getObjTypeOnModelName(rowObj.model_name),
						resId: rowObj.id,
					});
				}
			}
		} else if (rowObj.model_name === "LocalEstimatesResTransport" && rowObj.is_dependant_pg === true) {
			if (rowObj.id !== null) {
				if (keyValArr) {
					keyValArr = keyValArr.map((row) => {
						row.key = `dependant_pg_${row.key}`;
						row.params = { trps_id: rowObj.id };
						return row;
					});
					hooks.itemEditor.editItemValArr({
						sectionId: rowObj.section_id,
						itemId: rowObj.item_id,
						keyValArr: keyValArr,
						objType: getObjTypeOnModelName(clickedTableRow?.parentRow?.model_name),
						resId: clickedTableRow?.parentRow?.id,
					});
				} else {
					hooks.itemEditor.editItem({
						sectionId: rowObj.section_id,
						itemId: rowObj.item_id,
						key: "dependant_pg_edit",
						value: { trps_id: rowObj.id, clm_name: clmName, clm_value: dataObj[clmName] },
						objType: getObjTypeOnModelName(clickedTableRow?.parentRow?.model_name),
						resId: clickedTableRow?.parentRow?.id,
						otherParams: { targetValue: dataObj[clmName], targetKey: clmName },
					});
				}
			}
		} else if (
			["LocalEstimatesResMaterial", "LocalEstimatesResEquipment", "LocalEstimatesResMachine", "LocalEstimatesResWorker"].includes(
				rowObj.model_name
			) &&
			rowObj.is_item !== "1"
		) {
			if (rowObj.id !== null) {
				if (keyValArr) {
					keyValArr = keyValArr.map((row) => {
						row.key = `internal_${row.key}`;
						return row;
					});
					hooks.itemEditor.editItemValArr({
						sectionId: rowObj.section_id,
						itemId: rowObj.item_id,
						keyValArr: keyValArr,
						objType: getObjTypeOnModelName(rowObj.model_name),
						resId: rowObj.id,
					});
				}
			}
		} else if (rowObj.model_name === "EmptyHandleAbsRes") {
			if (dataObj.code) {
				hooks.itemEditor.replaceAbsResource({
					sectionId: rowObj.section_id,
					itemId: rowObj.item_id,
					resId: rowObj.parent_res_id,
					newResCode: dataObj.code,
				});
			}
		} else if (rowObj.model_name === "EmptyHandleDepRes") {
			if (dataObj.code) {
				hooks.itemEditor.editItemValArr({
					sectionId: rowObj.section_id,
					itemId: rowObj.item_id,
					keyValArr: [{ key: "dependant_resource_add", value: dataObj.code }],
					objType: "cost",
					resId: 0,
				});
			}
		}
	};

	const editingModeFactory = (rowObj, event) => {
		// console.log("_______ editingModeFactory", clickedTableClmName, event?.key);

		if (event?.ctrlKey === true) {
			if (event?.key === "c" || event?.key === "с") {
				copyToClipboard(clickedTableRow?.[clickedTableClmName]);
				return;
			}
		}

		if (event?.key === "ArrowRight") {
			if (editorCellisActive) return;
			if (inputAutocompleteSettings.visible) return;
			dispatch(setClickedTableClmName(getNextInArr({ arr: getLocalEstimateTableColumnsRimFlat(), value: clickedTableClmName })));
			return;
		} else if (event?.key === "ArrowLeft") {
			if (editorCellisActive) return;
			if (inputAutocompleteSettings.visible) return;
			dispatch(setClickedTableClmName(getPrevInArr({ arr: getLocalEstimateTableColumnsRimFlat(), value: clickedTableClmName })));
			return;
		} else if (event?.key === "ArrowUp") {
			event.preventDefault();
			if (inputAutocompleteSettings.visible) {
				dispatch(decreaseFiltredDataAutocompleteSetting());
				return;
			}
			if (editorCellisActive) return;
			const t0 = performance.now();
			const { prevItem, seekCount, parentItem } = searchNestedItem({ rowKey: clickedTableRow?.key });
			if (!prevItem) return;
			dispatch(setClickedTableRow({ ...prevItem, parentRow: parentItem }));
			const t1 = performance.now();
			if (t1 - t0 > 1) console.log(`Call to changeRowData took ${t1 - t0} milliseconds.`);
			const scrollBlock = getScrollBlock();
			if (scrollBlock) {
				const headerHeight = 50;
				if (scrollBlock.scrollTop > seekCount * genConstValue("rowHeight") - headerHeight) {
					scrollBlock.scrollTop -= genConstValue("rowHeight");
				}
			}
			// dispatch(setArrowMoveisActive(false))
			return;
		} else if (event?.key === "ArrowDown") {
			event.preventDefault();
			if (inputAutocompleteSettings.visible) {
				dispatch(increaseFiltredDataAutocompleteSetting());
				return;
			}
			if (editorCellisActive) return;
			const t0 = performance.now();
			const { nextItem, seekCount, parentItem } = searchNestedItem({ rowKey: clickedTableRow?.key });
			if (!nextItem) return;
			dispatch(setClickedTableRow({ ...nextItem, parentRow: parentItem }));
			const t1 = performance.now();
			if (t1 - t0 > 1) console.log(`Call to changeRowData took ${t1 - t0} milliseconds.`);
			const scrollBlock = getScrollBlock();
			if (scrollBlock) {
				const footerHeight = 75;
				if (scrollBlock.scrollTop + scrollBlock.offsetHeight - footerHeight < seekCount * genConstValue("rowHeight")) {
					scrollBlock.scrollTop += genConstValue("rowHeight");
				}
			}
			// dispatch(setArrowMoveisActive(false))
			return;
		} else if (event?.key === "Delete") {
			event.preventDefault();
			deleteFactory({ rowObj: clickedTableRow });
			return;
		} else if (event?.key === "Enter") {
			if (inputAutocompleteSettings.visible) {
				const hovered = inputAutocompleteSettings?.filtredData.find((x) => x.hovered === true);
				if (!hovered) return;
				let codeSelected = hovered.code;
				if (hovered.prefix) codeSelected = `${hovered.prefix} ${hovered.code}`;
				editorFactory({ rowObj: clickedTableRow, dataObj: { code: codeSelected }, clmName: "code" });
				dispatch(setInputAutocompleteSettings({ visible: false, filtredData: [], inputVal: "" }));
				return;
			} else if (modalVisible.positionSettings) {
				return;
			}
			dispatch(setClickedTableRow(null));
			dispatch(setConfirmOptions({ visible: false }));
			dispatch(setInputAutocompleteSettings({ visible: false, filtredData: [], inputVal: "" }));
			return;
		} else if (event?.key === "Escape") {
			dispatch(setInputAutocompleteSettings({ visible: false, filtredData: [], inputVal: "" }));
			hooks.itemEditor.removeNewRow();
		}

		if (
			(rowObj.model_name === "LocalEstimateRimSections" && clickedTableClmName === "name") ||
			(rowObj.is_item === "1" && clickedTableClmName === "consumption") ||
			(rowObj.is_item === "1" && clickedTableClmName === "code") ||
			(rowObj.is_tc === true && ["name", "code", "unit", "consumption", "priceperunitcur"].includes(clickedTableClmName)) ||
			(rowObj.is_void === true && ["name", "code", "unit", "consumption", "priceperunitcur"].includes(clickedTableClmName)) ||
			(rowObj.xml_res_type_id === XmlResourceTypeEnum.ITEM_RESOURCE && ["consumption"].includes(clickedTableClmName)) ||
			(rowObj.is_dependant_pg === true && clickedTableClmName === "consumption") ||
			(rowObj.model_name === "EmptyHandleAbsRes" && clickedTableClmName === "code") ||
			(rowObj.model_name === "EmptyHandleDepRes" && clickedTableClmName === "code") ||
			rowObj.is_new_row
		) {
			if (["Enter", "Shift", "Control", "Alt", "Tab", "ContextMenu"].includes(event?.key)) {
				return;
			} else if (modalVisible.estimateSetting || modalVisible.positionSettings || modalVisible.pgCreation) {
				return;
			} else if (["Escape"].includes(event?.key)) {
				dispatch(
					changeRowData({
						rowKey: rowObj.key,
						callback: (row) => {
							row.inline_cell = "";
							row[clickedTableClmName] = prevCell.value;
						},
					})
				);
				dispatch(setEditorCellisActive(false));
				return;
			}

			if (
				(rowObj.is_item === "1" && clickedTableClmName === "code") ||
				(rowObj.model_name === "EmptyHandleAbsRes" && clickedTableClmName === "code") ||
				(rowObj.model_name === "EmptyHandleDepRes" && clickedTableClmName === "code") ||
				(rowObj.is_new_row && clickedTableClmName === "code")
			) {
				const { seekCount } = searchNestedItem({ rowKey: clickedTableRow?.key });
				dispatch(
					setInputAutocompleteSettings({
						visible: true,
						positionTop: (seekCount + 1) * genConstValue("rowHeight") - getScrollBlock().scrollTop,
					})
				);
			}

			if (rowObj[clickedTableClmName] !== "") {
				dispatch(setPrevCell({ key: rowObj.key, value: rowObj[clickedTableClmName] }));
			}

			dispatch(setEditorCellisActive(true));

			dispatch(
				changeRowData({
					rowKey: rowObj.key,
					callback: (row) => {
						row.inline_cell = clickedTableClmName;
						if (event) row[clickedTableClmName] = "";
						if (["consumption", "priceperunitcur"].includes(clickedTableClmName) && row[clickedTableClmName] === "0") {
							row[clickedTableClmName] = "";
						}
					},
				})
			);
		}
	};

	const searchNestedItem = ({ rowKey }) => {
		return searchNestedIterableItem({
			iterable: tableData,
			isSeekRowCallback: (row) => {
				if (row.key && row.key === rowKey) {
					return true;
				} else {
					return false;
				}
			},
			isPermittedRowCallback: (row) => {
				if (row.visible !== false) {
					return true;
				} else {
					return false;
				}
			},
			isPermittedChildsLoopCallback: (row) => {
				if (row.childs_visible !== false || row.model_name === "CostParentLabel") {
					return true;
				} else {
					return false;
				}
			},
		});
	};

	const getScrollBlock = () => {
		if (scrollBlock.current) {
			return scrollBlock.current;
		} else {
			return document.querySelector("#EstimateEditorTable");
		}
	};

	const deleteFactory = ({ rowObj }) => {
		console.log("_______ deleteFactory", rowObj);
		if (rowObj.model_name === "LocalEstimateRimSections") {
			hooks.sectionEditor.showDeleteSectionConfirm({
				section: clickedTableRow,
			});
			hooks.setModalVisible.confirm(true);
		} else if (rowObj.model_name === "LocalEstimatesItems") {
			hooks.itemEditor.showDeleteItemConfirm({
				item: clickedTableRow,
			});
			hooks.setModalVisible.confirm(true);
		} else if (
			[
				"LocalEstimatesResMaterial",
				"LocalEstimatesResEquipment",
				"LocalEstimatesResMachine",
				"LocalEstimatesResTransport",
				"LocalEstimatesResVoid",
			].includes(rowObj.model_name) &&
			rowObj.is_item === "1"
		) {
			hooks.itemEditor.showDeleteItemConfirm({
				item: { ...clickedTableRow, id: clickedTableRow.item_id },
			});
			hooks.setModalVisible.confirm(true);
		} else if (
			["LocalEstimatesResMaterial", "LocalEstimatesResMachine", "LocalEstimatesResEquipment"].includes(rowObj.model_name) &&
			rowObj.xml_res_type_id === XmlResourceTypeEnum.ITEM_RESOURCE
		) {
			hooks.resourceEditor.showDeleteResourceConfirm({
				res: clickedTableRow,
			});
			hooks.setModalVisible.confirm(true);
		} else if (rowObj.is_dependant_pg === true) {
			hooks.resourceEditor.showDeleteDependangPgConfirm({
				res: clickedTableRow,
				parentRes: clickedTableRow?.parentRow,
			});
			hooks.setModalVisible.confirm(true);
		}
	};

	const moveFactory = ({ rowObj, direction }) => {
		if (rowObj.model_name === "LocalEstimateRimSections") {
			hooks.sectionEditor.moveSection({ sectionId: rowObj.id, direction });
		} else if (rowObj.model_name === "LocalEstimatesItems") {
			hooks.itemEditor.moveItem({ sectionId: rowObj.section_id, itemId: rowObj.id, direction });
		} else if (
			[
				"LocalEstimatesResMaterial",
				"LocalEstimatesResEquipment",
				"LocalEstimatesResMachine",
				"LocalEstimatesResTransport",
				"LocalEstimatesResVoid",
			].includes(rowObj.model_name) &&
			rowObj.is_item === "1"
		) {
			hooks.itemEditor.moveItem({ sectionId: rowObj.section_id, itemId: rowObj.item_id, direction });
		}
	};

	const editEstimateSettings = ({ body }) => {
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.editEstimateSettings({ estmId, body });
		prom.then((response) => {
			// console.log(response);
			hooks.setModalVisible.estimateSetting(false);
			dispatch(setData(response.data.estm_data.data));
			dispatch(setTableData(response.data.estm_data.table_data));
			dispatch(setCancelActions(response.data.actions_data));
			dispatch(setVisibleSettings(response.data.visible_settings));
			dispatch(setRowsMap(response.data.estm_data.rows_map));
			dispatch(setItemsMap(response.data.estm_data.items_map));
			setFetchStatus("success");
			setFetchStatusText(null);
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
			setFetchCircleStatus("failed");
		});
	};

	const createEstimate = ({ body }) => {
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.createEstimate({ body });
		prom.then((response) => {
			hooks.setModalVisible.estimateSetting(false);
			navigate(`/project/${response.data.project_id}/editor/lsr/${response.data.new_estm_id}/`);
			setFetchStatus("success");
			setFetchStatusText(null);
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
		});
	};

	const cancelAction = ({ actionId }) => {
		setFetchStatus("loading");
		const prom = apiLocalEstimateEditor.cancelAction({ estmId, actionId });
		prom.then((response) => {
			dispatch(setData(response.data.estm_data.data));
			dispatch(setTableData(response.data.estm_data.table_data));
			dispatch(setCancelActions(response.data.actions_data));
			dispatch(setVisibleSettings(response.data.visible_settings));
			dispatch(setRowsMap(response.data.estm_data.rows_map));
			dispatch(setItemsMap(response.data.estm_data.items_map));
			setFetchStatus("success");
		}).catch((err) => {
			console.error(err);
			setFetchStatus("failed");
		});
	};

	const convertJsonToDb = ({ asCopy }) => {
		setFetchCircleStatus("loading");
		let prom;
		if (asCopy) {
			prom = apiLocalEstimateEditor.convertJsonToDbAsCopy({ estmId });
		} else {
			prom = apiLocalEstimateEditor.convertJsonToDb({ estmId });
		}
		prom.then((response) => {
			setFetchCircleStatus("successAlert");
			setFetchStatusText(`Документ сохранен в проекте`);
			dispatch(forceFetchProjectTree(projectId));
			setTimeout(() => {
				setFetchCircleStatus("success");
				setFetchStatusText("");
				navigate(`/project/${projectId}/lsr/${response.data.estm_id}/`);
			}, 1300);
		}).catch((err) => {
			console.error(err);
			setFetchCircleStatus("failed");
		});
	};

	const getSectionId = () => {
		if (clickedTableRow?.section_id) {
			return clickedTableRow.section_id;
		} else {
			const sections = tableData.filter((row) => row?.id !== 0 && row?.id != null);
			const lastSections = sections.slice(-1)[0];
			return lastSections.id;
		}
	};

	const openFsnb = (normName, actionType, searchCode = "") => {
		if (searchCode) dispatch(setStartSearchCode(searchCode));
		const splitCodes = searchCode.split(" ");
		let code = "";
		let prefix = "0";
		if (splitCodes.length === 2) {
			prefix = splitCodes[0];
			code = splitCodes[1];
		} else if (splitCodes.length === 1) {
			code = splitCodes[0];
		}
		const prom = apiFsnb.getNormIdOnCodePrefix({ versId: estmData.fsnb_vers_id, code: code, prefix: prefix });
		prom.then((response) => {
			dispatch(setStartSearchObj({ ...response.data, vers_id: estmData.fsnb_vers_id, str_clm: "code" }));
			dispatch(setNavigateFromEditor(actionType));
			dispatch(setEditorEstmDocClickedSectionId(getSectionId()));
			dispatch(setClickedRowBeforeNav(clickedTableRow));
			navigate(`/fsnb/${normName}/`);
		});
	};

	const getItemDataPromise = ({ clickedTableRow, body }) => {
		const prom = apiLocalEstimateEditor.getItemData({
			estmId,
			sectionId: clickedTableRow.section_id,
			itemId: clickedTableRow.item_id,
			body,
		});
		return prom;
	};

	const handleAddCostContent = () => {
		const prom = apiFsnb.getCostContent(estmData.fsnb_vers_id, clickedTableRow.code.split(" ")[0], clickedTableRow.code.split(" ")[1]);
		prom.then((response) => {
			dispatch(
				changeRowData({
					rowKey: clickedTableRow.key,
					callback: (row) => {
						row._children = [
							...response.data.map((x) => {
								return {
									name: x,
									key: `${clickedTableRow.key}-${x}`,
									model_name: "CostContent",
									_style: {
										_cell: { fontStyle: "italic", backgroundColor: "rgb(244 255 244)" },
									},
								};
							}),
							...row._children,
						];
						return row;
					},
				})
			);
		});
	};

	const handleHideCostContent = () => {
		dispatch(
			changeRowData({
				rowKey: clickedTableRow.key,
				callback: (row) => {
					row._children = [...row._children].filter((x) => x.model_name !== "CostContent");
					return row;
				},
			})
		);
	};

	return {
		getColumnsData,
		selectTableRow,
		editorFactory,
		editingModeFactory,
		searchPanelRowPosition,
		searchPanelRowPositions,
		cancelAction,
		moveFactory,
		convertJsonToDb,
		deleteFactory,
		editEstimateSettings,
		setFetchStatus,
		getSectionId,
		openFsnb,
		createEstimate,
		setFetchCircleStatus,
		getItemDataPromise,
		handleAddCostContent,
		handleHideCostContent,
		hooks,
	};
};

export { useEstimateManager };

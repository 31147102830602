export default function NrspCoefTableColumns() {
    return [
        {
            name: 'coef_name',
            title: 'Наименование коэффициента',
            width: 'auto',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'nr_coef_code',
            title: 'НР, код',
            width: '150px',
            cellHorAlign: 'left',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'nr_coef_value',
            title: 'НР, К',
            width: '150px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'sp_coef_code',
            title: 'СП, код',
            width: '150px',
            cellHorAlign: 'left',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'sp_coef_value',
            title: 'СП, К',
            width: '150px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
    ];
}
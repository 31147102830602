import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import RibbonContent from "components/Ribbon/RibbonContent/RibbonContent";
import ImportBrainStormRibbonSettings from "./components/ImportBrainStormRibbonSettings";
import CompletenessModal from "./components/CompletenessModal";
import ImportAccordionHeader from "./Import/ImportAccordionHeader";
import ImportAccordionBody from "./Import/ImportAccordionBody";
import Monitor from "./Monitor/Monitor";
import { RecommendedIndexModal } from "pages/Monitor/components";
import MonitorEstimateTreeModal from "pages/Monitor/MonitorEstimateTreeModal";
import WaitingDialog from "components/WaitingDialog/WaitingDialog";
import ConfirmDialog from "components/ConfirmDialog";

import {
	pushFilesToList,
	updateFileFromList,
	getImportBrainStormAttempts,
	setFilesList,
	setMonitorData,
	changeModalVisible,
} from "slices/import_files";
import { setCurrentPage } from "slices/ribbon";
import { setConfirmOptions } from "slices/confirm";
import { fetchProject, forceFetchProjectTree } from "slices/project_work";
import apiImportFiles from "api/import_files";
import apiMonitor from "api/monitor";
import apiProjects from "api/projects";
import apiKatsEstimate from "api/kats";
import { downloadFile } from "core/request_utils";

import "./ImportBrainStorm.css";

const ImportBrainStorm = () => {
	const dispatch = useDispatch();
	const filesList = useSelector((state) => state.importFiles.filesList);
	const userTasksVisible = useSelector((state) => state.userAdv.userTasksVisible);
	const reduxFetchStatus = useSelector((state) => state.importFiles.fetchStatus);
	const modalTreeVisible = useSelector((state) => state.importFiles.modalVisible.estimateTree);
	const modalRecIndexVisible = useSelector((state) => state.importFiles.modalVisible.recomIndex);
	const modalWaitingVisible = useSelector((state) => state.importFiles.modalVisible.waiting);
	const confirmVisible = useSelector((state) => state.confirm.visible);
	const userTasks = useSelector((state) => state.userAdv.userTasks);

	const [mainClassName, setMainClassName] = useState("ImportBrainStorm");
	const [importExpanded, setImportExpanded] = useState(true);
	const [monitorPseudoVisible, setMonitorPseudoVisible] = useState(false);
	const [completenessVisible, setCompletenessVisible] = useState(false);
	const [completenessErrors, setCompletenessErrors] = useState([]);
	const [ribbonStatus, setRibbonStatus] = useState();
	const [tableTab, setTableTab] = useState("errors");

	const importAccRef = useRef();
	const monitorAccRef = useRef();
	const mainRef = useRef();

	const { id: projectId } = useParams();

	let poolFetch;

	const execCrossEstmAction = () => {
		setRibbonStatus("loading");
		const promise = apiMonitor.startCrossMonitorAction(projectId);
		promise
			.then(() => {
				dispatch(getImportBrainStormAttempts(projectId));
				setRibbonStatus("success");
			})
			.catch((err) => {
				console.log(err);
				setRibbonStatus("failed");
			});
	};

	const execJustValidation = () => {
		const promise = apiKatsEstimate.execProjectKatsJustValidation({ projectId: projectId });
		setRibbonStatus("loading");
		promise
			.then(() => {
				dispatch(getImportBrainStormAttempts(projectId));
				// setRibbonStatus("success");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const execCompletenessCheck = () => {
		setRibbonStatus("loading");
		const promise = apiMonitor.execCompletenessErrors(projectId);
		promise
			.then((response) => {
				setCompletenessErrors(response.data);
				setCompletenessVisible(true);
				setRibbonStatus("success");
			})
			.catch((err) => {
				console.log(err);
				setRibbonStatus("failed");
			});
	};

	const downloadCompletenessCheck = ({ errorsFilter }) => {
		setRibbonStatus("loading");
		const promise = apiMonitor.downloadCompletenessErrors({ projectId, errorsFilter });
		promise
			.then((response) => {
				downloadFile(response);
				setRibbonStatus("success");
			})
			.catch((err) => {
				console.log(err);
				setRibbonStatus("failed");
			});
	};

	useEffect(() => {
		dispatch(setMonitorData([]));
		dispatch(setFilesList([]));
		dispatch(fetchProject(projectId));
		dispatch(getImportBrainStormAttempts(projectId));
		dispatch(setCurrentPage("ImportBrainStormViewer"));
		return () => clearInterval(poolFetch);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setMonitorPseudoVisible(getMonitorPseudoVisible());
		setImportExpanded(true);
	}, [tableTab]);

	useEffect(() => {
		setMonitorPseudoVisible(getMonitorPseudoVisible());
		setImportExpanded(true);
	}, [tableTab]);

	useEffect(() => {
		setRibbonStatus(reduxFetchStatus);
	}, [reduxFetchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	const poolLoop = () => {
		poolFetch =
			!poolFetch &&
			setInterval(() => {
				dispatch(getImportBrainStormAttempts(projectId));
			}, 5000);
		const completed = filesList.filter((x) => x.status > 1);
		if (filesList.length === completed.length) {
			dispatch(changeModalVisible({ key: "waiting", value: false }));
			clearInterval(poolFetch);
		}
	};

	useEffect(() => {
		poolLoop();
		setMonitorPseudoVisible(getMonitorPseudoVisible());
		return () => clearInterval(poolFetch);
	}, [filesList]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (userTasksVisible) {
			dispatch(getImportBrainStormAttempts(projectId));
			dispatch(forceFetchProjectTree(projectId));
		}
	}, [userTasks]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		importXmlFiles(filesList.filter((x) => x.status === 0));
	}, [filesList.length]); // eslint-disable-line react-hooks/exhaustive-deps

	const importXmlFiles = async (importArr) => {
		const importArrSort = importArr.sort((a, b) => (a.front_index > b.front_index ? 1 : -1));
		for (let f of importArrSort) {
			dispatch(updateFileFromList({ curRow: f, data: { status: 1 } }));
		}
		for (let f of importArr) {
			if (f.size && f.size / 1024 / 1024 > 84) {
				dispatch(
					updateFileFromList({
						curRow: f,
						data: { status: 3, steps: { format: { status: "Error", text: "Файл превышает допустимый размер" } } },
					})
				);
			} else {
				try {
					await apiImportFiles.import_xml(f, projectId, f.key);
				} catch (err) {
					console.error(err);
				}
			}
		}
	};

	const handleDrag = (e, type) => {
		e.preventDefault();
		e.stopPropagation();
		if (type === "enter") {
			setMainClassName("ImportBrainStorm Drag");
		} else if (type === "leave") {
			setMainClassName("ImportBrainStorm");
		}
	};

	const getMonitorPseudoVisible = () => {
		if (mainRef?.current && monitorAccRef?.current) {
			const mainRefHeight = mainRef?.current?.getBoundingClientRect()?.height;
			const monitorAccRefTop = monitorAccRef?.current?.getBoundingClientRect()?.top;
			if (mainRefHeight && monitorAccRefTop) {
				return mainRefHeight + 115 < monitorAccRefTop;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const handleScroll = (e) => {
		setMonitorPseudoVisible(getMonitorPseudoVisible());
	};

	const showClearProjectDialog = () => {
		dispatch(
			setConfirmOptions({
				label: "Очистить проект?",
				text: "Операция очистки приведет к удалению всех загруженных сметных документов проекта",
				labelCancel: "Закрыть",
				labelOk: "Удалить",
				visible: true,
			})
		);
	};

	const clearProject = () => {
		setRibbonStatus("loading");
		const prom = apiProjects.clearProject(projectId);
		prom.then((response) => {
			setRibbonStatus("success");
			dispatch(getImportBrainStormAttempts(projectId));
			dispatch(setFilesList([]));
			setImportExpanded(false);
		});
	};

	return (
		<>
			<RibbonContent
				ribbonSettings={ImportBrainStormRibbonSettings({
					execCrossEstmAction,
					execJustValidation,
					dispatch,
					showClearProjectDialog,
					execCompletenessCheck,
				})}
				useRibbonPanel={false}
				useRibbonLoader
				loaderStatus={ribbonStatus}
			/>
			<div
				className={mainClassName}
				ref={mainRef}
				onDragEnter={(e) => {
					handleDrag(e, "enter");
				}}
				onDragOver={(e) => {
					handleDrag(e, "enter");
				}}
				onDragLeave={(e) => {
					handleDrag(e, "leave");
				}}
				onDrop={(e) => {
					handleDrag(e, "leave");
					setImportExpanded(true);
					dispatch(pushFilesToList(e.dataTransfer.files));
				}}
				onScroll={handleScroll}
			>
				<div className="ImportAccordion">
					<Accordion expanded={importExpanded} className="ImportAccordionImport" ref={importAccRef}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							onClick={(e) => {
								if (!["ActiveTab", "NotActiveTab"].includes(e?.target?.className)) {
									if (mainRef.current && mainRef.current.scrollTop > 0) {
										setImportExpanded(true);
									} else {
										setImportExpanded((prev) => !prev);
									}
								}
								if (importAccRef?.current) {
									importAccRef.current.scrollIntoView();
								}
							}}
						>
							<ImportAccordionHeader setImportExpanded={setImportExpanded} tableTab={tableTab} setTableTab={setTableTab} />
						</AccordionSummary>
						<AccordionDetails>
							<ImportAccordionBody tableTab={tableTab} setTableTab={setTableTab} importExpanded={importExpanded} />
						</AccordionDetails>
					</Accordion>
				</div>
				<div className="MonitorAccordion" import_expanded={String(importExpanded)}>
					<Accordion expanded={true} className="ImportAccordionMonitor" ref={monitorAccRef}>
						<AccordionSummary>Монитор проверки</AccordionSummary>
						<AccordionDetails>
							<Monitor setRibbonStatus={setRibbonStatus} />
						</AccordionDetails>
					</Accordion>
				</div>
				{monitorPseudoVisible && (
					<div
						className="MonitorPseudoAccordion"
						onClick={() => {
							if (monitorAccRef?.current) monitorAccRef.current.scrollIntoView();
						}}
					>
						Монитор проверки
					</div>
				)}
				{modalTreeVisible && (
					<MonitorEstimateTreeModal
						id={projectId}
						setModalTreeVisible={(val) => dispatch(changeModalVisible({ key: "estimateTree", value: val }))}
					/>
				)}
				{modalRecIndexVisible && (
					<RecommendedIndexModal f_setVisible={(val) => dispatch(changeModalVisible({ key: "recomIndex", value: val }))} />
				)}
				{modalWaitingVisible && (
					<WaitingDialog
						title="Дождитесь окончания импорта для запуска второго этапа проверки"
						clickCallback={() => {
							dispatch(changeModalVisible({ key: "waiting", value: false }));
						}}
					/>
				)}
				{confirmVisible === true && <ConfirmDialog func={clearProject} />}
				{completenessVisible === true && (
					<CompletenessModal
						setVisible={setCompletenessVisible}
						tableData={completenessErrors}
						setTableData={setCompletenessErrors}
						downloadCompletenessCheck={downloadCompletenessCheck}
					/>
				)}
			</div>
		</>
	);
};

export default ImportBrainStorm;

import React from 'react';

import Button from '@mui/material/Button';

import "./KsprButton.css";

export default function KsprButton(props) {
    return (
        <div className="KsprButton">
            <Button size="small"
                {...props}
            >
                {props.label}
            </Button>
        </div>
    )
}


import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

import "./CustomNavigateCell.css";

const CustomNavigateCell = ({ rowObj, funcs }) => {
	const getCellInnerDom = () => {
		if (rowObj.selected !== true) return;
		if (rowObj.model_name === "UserProjects") {
			return (
				<div title="Перейти в проект" className="TreeActionCell">
					<ExitToAppOutlinedIcon />
				</div>
			);
		} else if (rowObj.model_name === "UserProjectPacks") {
			return (
				<div title="Создать проект" className="TreeActionCell">
					<AddBoxOutlinedIcon />
				</div>
			);
		}
	};
	return (
		<td
			key={`cell-action_navigate-${rowObj.id}`}
			className="TableCell"
			clm="action_navigate"
			treecell="false"
			style={{ paddingLeft: "5px", paddingRight: "5px" }}
			onClick={() => {
				if (rowObj.model_name === "UserProjects") {
					funcs.enterToProject({ targetId: rowObj.id });
				} else if (rowObj.model_name === "UserProjectPacks") {
					funcs.addNewProjectToPack({ rowObj });
				}
			}}
		>
			{getCellInnerDom()}
		</td>
	);
};

export default CustomNavigateCell;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
	TextField,
	InputAdornment,
	Alert,
	Checkbox,
	FormGroup,
	FormControlLabel,
	IconButton,
	FormControl,
	InputLabel,
	OutlinedInput,
	CircularProgress,
	Grid,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { setInputLogin, setPsswLogin, setShowPassword, fetchAuth, fetchEcpeAuth, fetchAisAuth, fetchSipCsAuth } from "../../slices/auth";
import { getEnvVal } from "core/env.js";
import { uuidv4 } from "core/guid_utils";

import { PersenalAgreementModal } from "./PersenalAgreementModal";
import ggelogoh from "../../imgs/ggelogoh.png";

import "./AuthNew.css";

export default function AuthNew() {
	const dispatch = useDispatch();
	const inputLogin = useSelector((state) => state.auth.inputLogin);
	const inputPssw = useSelector((state) => state.auth.inputPssw);
	const showPassword = useSelector((state) => state.auth.showPassword);
	const fetchStatus = useSelector((state) => state.auth.fetchStatus);
	const fetchStatusText = useSelector((state) => state.auth.fetchStatusText);
	const [ecpeChecked, setEcpeChecked] = useState(false);
	const [aisChecked, setAisChecked] = useState(false);
	const [sipcsChecked, setSipCsChecked] = useState(false);
	const [perAgrVisible, setPerAgrVisible] = useState(false);

	useEffect(() => {
		if (getEnvVal("App") === "ecpe") {
			setEcpeChecked(true);
		} else if (getEnvVal("App") === "ais") {
			setAisChecked(true);
		} else if (getEnvVal("App") === "sipcs") {
			setSipCsChecked(true);
		}
	}, []);

	const sendAuthData = () => {
		// console.log("inputLogin", inputLogin, ecpeChecked);
		const authUuid = uuidv4();
		localStorage.setItem("authUuid", authUuid);
		if (ecpeChecked) {
			dispatch(fetchEcpeAuth({ email: inputLogin, password: inputPssw, auth_uuid: authUuid }));
		} else if (aisChecked) {
			dispatch(fetchAisAuth({ email: inputLogin, password: inputPssw, auth_uuid: authUuid }));
		} else if (sipcsChecked) {
			dispatch(fetchSipCsAuth({ email: inputLogin, username: inputLogin, password: inputPssw, auth_uuid: authUuid }));
		} else {
			dispatch(fetchAuth({ username: inputLogin, password: inputPssw, auth_uuid: authUuid }));
		}
	};

	const handleLogin = (value) => {
		dispatch(setInputLogin(value));
	};

	const handlePssw = (value) => {
		dispatch(setPsswLogin(value));
	};

	const handleClickShowPassword = () => {
		dispatch(setShowPassword());
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const getPersenalAgreement = () => {
		if (getEnvVal("App") === "ecpe") {
			return (
				<div className="PersenalAgreementLabel">
					Принимаю <span onClick={() => setPerAgrVisible(true)}>согласие на обработку</span> персональных данных
				</div>
			);
		}
		return <div className="PersenalAgreementLabelBlank"></div>;
	};

	const getFreeLinkBox = () => {
		if (getEnvVal("App") === "ecpe") {
			return (
				<div className="AuthLoginBox Bold">
					<a className="AuthLoginLink" href="https://smetafree.platformaexpert.ru/" target="_blank" rel="noreferrer">
						Перейти в открытый модуль проверки комплектности сметной документации
					</a>
				</div>
			);
		}
	};

	const getGgeLinkBox = () => {
		if (getEnvVal("App") === "ecpe") {
			return (
				<div className="AuthLoginBox">
					<a className="AuthLoginLink" href="https://gge.ru/services/servis-kompleksnoy-proverki-smetnykh-raschetov/">
						Информация о сервисе на официальном сайте
					</a>
				</div>
			);
		}
	};

	return (
		<Grid container className="AuthMain" sx={{ height: "100vh" }}>
			<Grid item lg={3} display={{ xs: "none", lg: "block" }}>
				<div className="AuthLeft">
					<div className="AuthLeftTop">{/* <img src={ggelogow} alt=""></img> */}</div>
					<div className="AuthLeftBottom">
						<div className="AuthLabel">Объективность, надежность, эффективность - для безопасного будущего</div>
					</div>
				</div>
			</Grid>

			<Grid item xs={12} lg={9}>
				<div className="AuthRight">
					{/* <img src={authBackImg} alt=""></img> */}

					<div className="AuthLoginBox">
						<div className="LoginLogo">{getEnvVal("App") !== "sipcs" && <img src={ggelogoh} alt=""></img>}</div>
						<div className="LoginLabel">
							<div className="LabelText">
								{getEnvVal("App") === "sipcs"
									? "Специальный портал ценообразования в строительстве"
									: "Комплексная проверка сметных расчетов"}
							</div>
						</div>
						<div className="LoginInputs">
							<TextField
								id="outlined-basic"
								label="Имя пользователя"
								size="small"
								onChange={(event) => handleLogin(event.target.value)}
							/>
							<FormControl variant="outlined">
								<InputLabel htmlFor="outlined-adornment-password" size="small">
									Пароль
								</InputLabel>
								<OutlinedInput
									size="small"
									id="outlined-adornment-password"
									type={showPassword ? "text" : "password"}
									onChange={(event) => handlePssw(event.target.value)}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									}
									label="Пароль"
								/>
							</FormControl>
						</div>
						<div className="LoginSetOpts">
							<FormGroup size="small" row>
								{/* <FormControlLabel size="small"
                                control={<Checkbox defaultChecked size="small" />} label="Запомнить меня" /> */}
								{getEnvVal("App") === "ecpe" && (
									<FormControlLabel
										size="small"
										control={<Checkbox size="small" checked={ecpeChecked} onChange={() => setEcpeChecked((prev) => !prev)} />}
										label="Зайти через ЕЦПЭ"
									/>
								)}
								{getEnvVal("App") === "ais" && (
									<FormControlLabel
										size="small"
										control={<Checkbox size="small" checked={aisChecked} onChange={() => setAisChecked((prev) => !prev)} />}
										label="Зайти через АИС Экспертизу"
									/>
								)}
								{getEnvVal("App") === "sipcs" && (
									<FormControlLabel
										size="small"
										control={<Checkbox size="small" checked={sipcsChecked} onChange={() => setSipCsChecked((prev) => !prev)} />}
										label="Зайти через СИП ЦС"
									/>
								)}
							</FormGroup>
							{/* <div className="ForgetLabel"><a href="/">Забыли пароль?</a></div> */}
						</div>
						<div className="LoginBut">
							<div className="LoginButton" onClick={sendAuthData}>
								ВОЙТИ
							</div>
						</div>
						{getPersenalAgreement()}
					</div>

					{getGgeLinkBox()}
					{getFreeLinkBox()}

					{fetchStatus === "failed" && (
						<Alert severity="error" type="auth">
							Ошибка ответа сервера
						</Alert>
					)}
					{fetchStatus === "rejected" && (
						<Alert severity="error" type="auth">
							{fetchStatusText}
						</Alert>
					)}
				</div>
			</Grid>

			<div className="AuthCircularProgress">{fetchStatus === "loading" && <CircularProgress type="auth" />}</div>

			{perAgrVisible && <PersenalAgreementModal setVisible={setPerAgrVisible} />}
		</Grid>
	);
}

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import KsprButton from "components/UI/buttons/KsprButton";
import { setLastSelectedParams, setLastClickedRow } from "slices/monitor";

import "./RecommendedIndexButton.css";

const RecommendedIndexButton = ({ params, row, ...props }) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(params.filter((x) => x === 0).length > 0);
    }, [params, row]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = () => {
        if (!disabled) {
            dispatch(setLastSelectedParams({ pz: params[0], obj: params[1], inx: params[2] }));
            dispatch(setLastClickedRow(row));
            props.f_setModalRecIndexVisible(true);
        }
    };

    return (
        <div className="recommendedIndexBtn">
            <KsprButton
                type="recommended_index"
                title="Уточнить по письмам"
                label="Уточнить по письмам"
                disabled={disabled}
                onClick={handleClick}
            />
        </div>
    );
};

export default RecommendedIndexButton;

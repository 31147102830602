import React, { useRef } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import "./AdvSearchResultBox.css";

export default function AdvSearchResultBox({ data, f_data, mode }) {
	const scrollBody = useRef();

	const handleSelect = (row) => {
		f_data((prev) =>
			prev.map((x) => {
				x.active = x.key === row.key ? true : false;
				return x;
			})
		);
	};

	const moveSelect = (mode) => {
		const index = data.map((x) => x.active).indexOf(true);
		// Максимально допустимая высота видимых строк поиска 192px = 24 * 8 (Задано в стиле AdvSearchResultBox.css)
		const rowHeight = 24;
		const maxDisplayHeight = rowHeight * 7;
		let newIndex;
		if (index === -1 && mode === "down") {
			newIndex = 0;
		} else if (index < data.length - 1 && mode === "down") {
			newIndex = index + 1;
		} else if (index === data.length - 1 && mode === "down") {
			newIndex = 0;
		} else if (index > 0 && mode === "up") {
			newIndex = index - 1;
		} else if (index === 0 && mode === "up") {
			newIndex = data.length - 1;
		}
		if (newIndex != null) {
			const curScrollTop = scrollBody.current.scrollTop;
			const newScrollTop = rowHeight * newIndex;
			if (mode === "down") {
				if (newScrollTop > maxDisplayHeight && newScrollTop > curScrollTop) {
					scrollBody.current.scrollTop = curScrollTop + rowHeight;
				}
			} else if (mode === "up") {
				if (newScrollTop < curScrollTop) {
					scrollBody.current.scrollTop = curScrollTop - rowHeight;
				}
			}
			f_data((prev) =>
				prev.map((x, index) => {
					x.active = index === newIndex ? true : false;
					return x;
				})
			);
		}
	};

	return (
		<div className="AdvSearchResultBox">
			<div className="AdvSearchResultHead">
				<div className="AdvSearchResultLabel">Результаты: ({data.length})</div>
				<div className="AdvSearchResultButs">
					<ArrowDropDownIcon onClick={(e) => moveSelect("down")} />
					<ArrowDropUpIcon onClick={(e) => moveSelect("up")} />
				</div>
			</div>
			<div className="AdvSearchResultBody" ref={scrollBody}>
				{data.map((row) =>
					mode === "rating" ? (
						<div
							className="advSearchTextWrapper"
							is_active={row.active == null ? "false" : row.active.toString()}
							onClick={(e) => handleSelect(row)}
							key={row.key}
						>
							<div className="AdvSearchResultRow" title={row.display_text}>
								{row.display_text}
							</div>{" "}
							<span>{row.rating_percent}</span>
						</div>
					) : (
						<div
							className="AdvSearchResultRow"
							title={row.display_text}
							is_active={row.active == null ? "false" : row.active.toString()}
							onClick={(e) => handleSelect(row)}
							key={row.key}
						>
							{row.display_text}
						</div>
					)
				)}
			</div>
		</div>
	);
}

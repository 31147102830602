import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setLocalFetchStatus } from "slices/local_estimate";
import { setObjFetchStatus } from "slices/object_estimate";
import { setKatsFetchStatus } from "slices/kats";
import { setReGetProtocol } from "slices/protocol";
import { changeChildsVisible, setTreeClicked } from "slices/project_work";
import { setClickedTableRow, setCurrentEstmDocUrlPart, setCurrentEstmDocId } from "slices/ribbon";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";

import { getObjGroupInNestedArray, getSliceFromArrByKeys } from "core/iterable_utils";

import "./ProjectTree.css";

export default function ProjectTree(props) {
	const rows_data = useSelector((state) => state.projectWork.tableSumEstm);
	const currentPage = useSelector((state) => state.ribbon.currentPage);
	const fullTree = useSelector((state) => state.projectWork.tree);
	const treeDom = [];

	for (let elm of props.data) {
		const elmDom = RecourseTree({
			data: elm,
			level: 0,
			selected: props.selected,
			selected_func: props.selected_func,
			deselected_func: props.deselected_func,
			editingMode: props.editingMode,
			editingMode_func: props.editingMode_func,
			editback_func: props.editback_func,
			changeChildsVisb_func: props.changeChildsVisb_func,
			rows_data: rows_data,
			currentPage: currentPage,
			scrollBlock: props.scrollBlock,
			setLastSelectedEstm: props.setLastSelectedEstm,
			callbacks: props.callbacks,
			fullTree: fullTree,
		});
		treeDom.push(elmDom);
	}

	return treeDom;
}

function RecourseTree(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selected = props.selected;
	const selected_func = props.selected_func;
	const deselected_func = props.deselected_func;
	const editingMode = props.editingMode;
	const editingMode_func = props.editingMode_func;
	const editback_func = props.editback_func;
	const fullTree = props.fullTree;

	const elm = props["data"];
	const level = props["level"];
	const padLeft = 15 * level;
	const is_visible = (elem) => {
		if (elem === undefined) {
			return true;
		} else {
			return elem;
		}
	};

	//const [isVisible, setIsVisible] = useState(true);
	const [inputCode, setinputCode] = useState(elm["code"]);
	const [inputName, setinputName] = useState(elm["name"]);

	const inputId = elm["id"];
	const inputType = elm["type"];

	let is_selected = selected.filter((x) => x["key"] === elm["key"]).length !== 0;

	let selected_first_key;
	let selected_edit = selected.filter((x) => x["type"] !== "summary");
	if (selected_edit.length > 0) {
		selected_first_key = selected_edit[selected_edit.length - 1]["key"];
	}

	const getEditBody = () => {
		const dct = {};
		dct["id"] = inputId;
		dct["type"] = inputType;
		dct["code"] = inputCode;
		dct["num"] = inputCode;
		if (elm["type"] === "local") {
			dct["estm_name"] = inputName;
		} else if (elm["type"] === "object") {
			dct["name"] = inputName;
		}

		return dct;
	};

	const exEditFunc = () => {
		if (editingMode === true && selected_first_key === elm["key"]) {
			editback_func[elm["type"]](getEditBody());
			dispatch(editingMode_func(false));
		}
	};

	const handleVisible = (key) => {
		dispatch(changeChildsVisible(key));
	};

	const setFetchStatusNull = () => {
		dispatch(setLocalFetchStatus(null));
		dispatch(setObjFetchStatus(null));
		dispatch(setKatsFetchStatus(null));
	};

	const resetSettings = () => {
		setFetchStatusNull();
		localStorage.removeItem("displayScrollTop");
		if (props.scrollBlock["current"] != null) {
			props.scrollBlock["current"].scrollTop = 0;
		}
	};

	const handleSelected = (x, force, event) => {
		if (!force) {
			if (selected[0]?.type === x?.type && selected[0]?.id === x?.id) {
				return;
			}
		}
		if (event?.ctrlKey) {
			dispatch(selected_func([x, ...selected]));
			return;
		} else if (event?.shiftKey) {
			event.preventDefault();
			const seekGroup = getObjGroupInNestedArray({ nestedData: fullTree, objectKey: x.key });
			let seekSlice = getSliceFromArrByKeys({ arr: seekGroup, startKey: selected?.[0].key, endKey: x.key });
			if (seekSlice.length === 0) {
				seekSlice = getSliceFromArrByKeys({ arr: seekGroup, endKey: selected?.[0].key, startKey: x.key });
			}
			dispatch(selected_func(seekSlice));
			return;
		} else {
			if (is_selected === false) {
				dispatch(selected_func(x));
			} else {
				dispatch(deselected_func(x));
			}
		}

		dispatch(editingMode_func(false));
		if (props.changeChildsVisb_func !== undefined) {
			dispatch(props.changeChildsVisb_func({ id: x["id"], val: null }));
		}

		if (x["type"] === "summary") {
			const rowForScroll = document.querySelectorAll('tr[scrolltoid="' + x["code"] + '"]');
			if (rowForScroll != null) {
				if (rowForScroll.length > 0) {
					rowForScroll[0].scrollIntoView({
						block: "center",
						behavior: "smooth",
					});
				}
			}
			resetSettings();
			if (props.currentPage === "ProtocolViewer") dispatch(setReGetProtocol(true));
			let newUrl = "/project/" + x["project_id"] + "/";
			if (props.currentPage === "ProtocolViewer") newUrl = `${newUrl}protocol/`;
			navigate(newUrl);
			dispatch(setClickedTableRow(null));
			props.setLastSelectedEstm({ type: "", id: "" });
		} else if (x["type"] === "object") {
			if (x["id"] !== 0) {
				resetSettings();
				if (props.currentPage === "ProtocolViewer") dispatch(setReGetProtocol(true));
				let newUrl = "/project/" + x["project_id"] + "/osr/" + x["id"] + "/";
				if (props.currentPage === "ProtocolViewer") newUrl = `${newUrl}protocol/`;
				navigate(newUrl);
				dispatch(setClickedTableRow(null));
				dispatch(setCurrentEstmDocUrlPart("osr"));
				dispatch(setCurrentEstmDocId(x["id"]));
				props.setLastSelectedEstm({ type: "osr", id: x["id"] });
			}
		} else if (x["type"] === "local") {
			if (x["id"] !== 0) {
				resetSettings();
				if (props.currentPage === "ProtocolViewer") dispatch(setReGetProtocol(true));
				let newUrl = "/project/" + x["project_id"] + "/lsr/" + x["id"] + "/";
				if (props.currentPage === "ProtocolViewer") newUrl = `${newUrl}protocol/`;
				navigate(newUrl);
				dispatch(setClickedTableRow(null));
				dispatch(setCurrentEstmDocUrlPart("lsr"));
				dispatch(setCurrentEstmDocId(x["id"]));
				dispatch(setTreeClicked(x));
				props.setLastSelectedEstm({ type: "lsr", id: x["id"] });
			}
		} else if (x["type"] === "kats") {
			if (x["id"] !== 0) {
				resetSettings();
				if (props.currentPage === "ProtocolViewer") dispatch(setReGetProtocol(true));
				let newUrl = "";
				// if (x["id"] == null) return

				if (x["id"] == null) {
					newUrl = "/project/" + x["project_id"] + "/kats/";
				} else {
					newUrl = "/project/" + x["project_id"] + "/kats/" + x["id"] + "/";
					dispatch(setCurrentEstmDocId(x["id"]));
				}
				if (props.currentPage === "ProtocolViewer") newUrl = `${newUrl}protocol/`;
				navigate(newUrl);
				dispatch(setClickedTableRow(null));
				dispatch(setCurrentEstmDocUrlPart("kats"));
				dispatch(setTreeClicked(x));
				props.setLastSelectedEstm({ type: "kats", id: x["id"] });
			}
		}
	};

	const arrowDown = <KeyboardArrowDownIcon style={{ paddingLeft: padLeft }} onClick={() => handleVisible(elm.key)}></KeyboardArrowDownIcon>;

	const arrowRight = <KeyboardArrowRightIcon style={{ paddingLeft: padLeft }} onClick={() => handleVisible(elm.key)}></KeyboardArrowRightIcon>;

	const getEstmStatusJsx = (row) => {
		if (row.double_is_winner === false) return <></>;

		if (row?.valid_status === "HAS_NOT_ERRORS__HAS_NOT_TOTAL_CUR_ERROR") {
			return <span className="statusRound statusGreen"></span>;
		} else if (row?.valid_status === "HAS_ERRORS__HAS_NOT_TOTAL_CUR_ERROR") {
			return <span className="statusRound statusOrange"></span>;
		} else if (row?.valid_status === "HAS_ERRORS__HAS_TOTAL_CUR_ERROR") {
			return <span className="statusRound statusRed"></span>;
		} else if (row["price_total_cur_is_correct"] === true) {
			return <span className="statusRound statusGreen"></span>;
		} else if (row["price_total_cur_is_correct"] === false) {
			return <span className="statusRound statusRed"></span>;
		} else {
			return <></>;
		}
	};

	const getEstmCommentJsx = (row) => {
		if (row._has_comment) {
			return (
				<span className="treeEstmComment" onClick={() => props.callbacks.showProjectTreeComment({ targetRow: row })}>
					<CommentOutlinedIcon />
				</span>
			);
		}
		return <></>;
	};

	const getTreeFullRowAdditinalStyle = (row) => {
		if (row._has_comment) {
			return { display: "flex" };
		} else if (row.double_is_winner === false) {
			return { color: "#cccccc" };
		}
	};

	const curStyle = {};

	if (elm.bgColor) {
		curStyle.backgroundColor = elm.bgColor;
	}

	let treeFullRow;
	if (editingMode === true && selected_first_key === elm["key"]) {
		let padLeftPlus = padLeft + 20;
		treeFullRow = (
			<div className="TreeEditFullRow">
				<div style={{ marginLeft: padLeftPlus - 12 }}>{elm["type"] === "local" ? "ЛСР" : "ОСР"}</div>
				<input
					className="TreeEditRowCode"
					style={{ width: "calc(40% - " + padLeftPlus + "px)" }}
					defaultValue={elm["code"]}
					onChange={(e) => {
						setinputCode(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							exEditFunc();
						}
					}}
				></input>
				<input
					className="TreeEditRowName"
					style={{ width: "calc(60% - " + padLeftPlus + "px)" }}
					defaultValue={elm["name"]}
					onChange={(e) => {
						setinputName(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							exEditFunc();
						}
					}}
				></input>
			</div>
		);
	} else {
		treeFullRow = (
			<div
				className="TreeFullRow"
				isselected={is_selected.toString()}
				title={elm["innertext"]}
				style={{ ...curStyle, ...getTreeFullRowAdditinalStyle(elm) }}
				onClick={(e) => {
					handleSelected(elm, true, e);
				}}
				onContextMenu={(e) => {
					handleSelected(elm, false, e);
					props.callbacks.openContextMenu({ clickedRow: elm, event: e });
				}}
			>
				{elm._children && elm._children?.length > 0 ? (
					is_visible(elm.childs_visible) ? (
						arrowDown
					) : (
						arrowRight
					)
				) : (
					<span style={{ paddingLeft: padLeft + 10 }} />
				)}
				{elm.double_is_winner === true && <span style={{ paddingLeft: 5 }}>{getEstmStatusJsx(elm)}</span>}
				{elm.double_is_winner !== true && <span>{getEstmStatusJsx(elm)}</span>}
				<span style={{ width: "80%" }}>{elm["innertext"]}</span>
				<>{getEstmCommentJsx(elm)}</>
			</div>
		);
	}

	const elm_dom = (
		<div key={elm["key"]} className="TreeRow" elm_type={elm["type"]} elm_id={elm["id"]}>
			{treeFullRow}
			{is_visible(elm.childs_visible) ? (
				elm._children?.map((child) => {
					const level_child = level + 1;
					return (
						<div key={child["key"]}>
							<RecourseTree
								data={child}
								level={level_child}
								selected={selected}
								selected_func={selected_func}
								deselected_func={deselected_func}
								editingMode={editingMode}
								editingMode_func={editingMode_func}
								editback_func={editback_func}
								rows_data={props.rows_data}
								currentPage={props.currentPage}
								scrollBlock={props.scrollBlock}
								setLastSelectedEstm={props.setLastSelectedEstm}
								callbacks={props.callbacks}
								fullTree={fullTree}
							/>
						</div>
					);
				})
			) : (
				<></>
			)}
		</div>
	);

	return elm_dom;
}

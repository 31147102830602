import { PageParams } from "./page_params";

const RibbonPanelMoveToIndexFactory = ({ currentPage, arg, scrollBlock }) => {
	let rowHeight = 22;
	let rowsBeforeCnt = 2;
	let headerHeight = 0;

	if (currentPage === "LocalEstimateViewer") {
		headerHeight = PageParams().local.headerHeight;
		rowsBeforeCnt = PageParams().local.rowsBeforeCnt;
	} else if (currentPage === "SummaryEstimateViewer") {
		headerHeight = PageParams().summary.headerHeight;
	} else if (currentPage === "ObjectEstimateViewer") {
		headerHeight = PageParams().object.headerHeight;
	} else if (currentPage === "KatsEstimateViewer") {
		headerHeight = PageParams().kats.headerHeight;
		rowsBeforeCnt = PageParams().kats.rowsBeforeCnt;
	} else if (currentPage === "KatsProjectEstimateViewer") {
		headerHeight = PageParams().kats.headerHeight;
		rowsBeforeCnt = PageParams().kats.rowsBeforeCnt;
	} else if (currentPage === "StartEstimateViewer") {
	} else if (currentPage === "ProtocolViewer") {
		headerHeight = PageParams().protocol.headerHeight;
		rowsBeforeCnt = PageParams().protocol.rowsBeforeCnt;
	} else if (currentPage === "ProtocolViewer") {
		headerHeight = PageParams().protocol.headerHeight;
		rowsBeforeCnt = PageParams().protocol.rowsBeforeCnt;
		rowHeight = PageParams().katsEditor.rowHeight;
	}

	if (!arg || arg === 0) return;

	const scrollPosition = rowHeight * arg - rowHeight * rowsBeforeCnt + headerHeight;

	if (scrollBlock.current) {
		scrollBlock.current.scrollTo({
			top: scrollPosition,
		});
	}
};

export default RibbonPanelMoveToIndexFactory;

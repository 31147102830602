import { useEffect, useState } from "react";
import Draggable from "react-draggable";

import KsprButton from "components/UI/buttons/KsprButton";
import { getEnvVal } from "core/env.js";
import apiAuth from "api/auth";

import "./PersenalAgreementModal.css";


const PersenalAgreementModal = ({ setVisible }) => {
    const [data, setData] = useState([])
    useEffect(() => {
        const prom = apiAuth.getPersenalAgreement(getEnvVal("App"))
        prom.then((response) => {
            setData(response.data)
        })
    }, [])

    if (!data.length) return

    return <Draggable handle=".PersenalAgreementModalHead">
        <div className="PersenalAgreementModal">
            <div className="PersenalAgreementModalHead">
                <div>СОГЛАСИЕ на обработку персональных данных</div>
            </div>
            <div className="PersenalAgreementModalBody">
                {data.map((prg, index) => {
                    return <div className="PersenalAgreementModalPar"
                        key={index}
                        style={prg.style}
                        dangerouslySetInnerHTML={{ __html: prg.text }}
                    ></div>
                })}
            </div>
            <div className="PersenalAgreementModalFooter">
                <KsprButton
                    title="Закрыть"
                    label="Закрыть"
                    onClick={() => setVisible(false)}>
                </KsprButton>
            </div>
        </div>
    </Draggable >
}

export { PersenalAgreementModal }
import React from "react";

import { IconButton, Tooltip, Stack } from "@mui/material";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import ReportOffOutlinedIcon from "@mui/icons-material/ReportOffOutlined";
import "./EstmHeaderTitleButtons.css";

export default function EstmHeaderTitleButtons(props) {

    let erTitle;
    let erIcon;
    if (props.errorsVisible === true) {
        erTitle = "Показать список замечаний";
        erIcon = <ReportOutlinedIcon type="error" />;
    } else {
        erTitle = "Свернуть список замечаний";
        erIcon = <ReportOffOutlinedIcon type="error" />;
    }

    return (
        <div className="EstmHeadIcons">
            <Stack direction="row" spacing={0}>
                {["lsr"].includes(props.estm_type) && props.data?.fsnb_errors != null ? (
                    <Tooltip
                        title={erTitle}
                        onClick={() => {
                            props.f_setErrorsVisible(!props.errorsVisible);
                        }}>
                        <IconButton size="small" aria-label={erTitle}>
                            {erIcon}
                        </IconButton>
                    </Tooltip>
                ) : (
                    <></>
                )}

            </Stack>
        </div>
    );
}

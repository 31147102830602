import React from 'react';

const ContextMenuCustom = ({ customJsx, disabled }) => {
    return (
        <div className={`context-menu-item ${disabled ? 'disabled' : ''}`}>
            {customJsx}
        </div>
    );
};

export default ContextMenuCustom;
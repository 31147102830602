import { LabelComposition, LabelCommon, LabelTwoVerticalLevel } from "components/UI/labels";
import { changeNullAndEmptyStringToDefault } from "core/str_utils";
import styles from "./SummaryEstmFooterSignatures.module.css";

const MAIN_CHIEF_WIDTH = "180px";
const MAIN_CHIEF_KEY = "560px";

const CHIEF_WIDTH = "100px";
const CHIEF_KEY = "200px";
const CHIEF_DEP_WIDTH = "45px";
const CHIEF_DEP_KEY = "385px";

const COMMON_KEYS_WIDTH = "100px";
const COMMON_VALUE_WIDTH = "640px";

const mainLabelStyle = {
	borderBottom: "1px solid black",
	textDecoration: "auto",
	width: COMMON_VALUE_WIDTH,
	textAlign: "center",
};

const SummaryEstmFooterSignatures = ({ data }) => {
	return (
		<div className={styles.SummaryEstmFooterSignatures}>
			<LabelComposition>
				<LabelCommon text="Руководитель проектной организации" style={{ width: MAIN_CHIEF_WIDTH }} />
				<LabelTwoVerticalLevel
					main={changeNullAndEmptyStringToDefault(data.signatures_projectorg, "-")}
					second="[подпись (инициалы, фамилия)]"
					style={{ mainLabel: { ...mainLabelStyle, width: MAIN_CHIEF_KEY }, box: { width: MAIN_CHIEF_KEY } }}
				/>
			</LabelComposition>
			<LabelComposition>
				<LabelCommon text="Главный инженер проекта" style={{ width: MAIN_CHIEF_WIDTH }} />
				<LabelTwoVerticalLevel
					main={changeNullAndEmptyStringToDefault(data.signatures_chiefprojectengineer, "-")}
					second="[подпись (инициалы, фамилия)]"
					style={{ mainLabel: { ...mainLabelStyle, width: MAIN_CHIEF_KEY }, box: { width: MAIN_CHIEF_KEY } }}
				/>
			</LabelComposition>
			<LabelComposition>
				<LabelCommon text="Начальник" style={{ width: CHIEF_WIDTH }} />
				<LabelTwoVerticalLevel
					main={changeNullAndEmptyStringToDefault(data.signatures_chief_department, "-")}
					second="(наименование)"
					style={{ mainLabel: { ...mainLabelStyle, width: CHIEF_KEY }, box: { width: CHIEF_KEY } }}
				/>
				<LabelCommon text="отдела" style={{ width: CHIEF_DEP_WIDTH }} />
				<LabelTwoVerticalLevel
					main={changeNullAndEmptyStringToDefault(data.signatures_chief_name, "-")}
					second="[подпись (инициалы, фамилия)]"
					style={{ mainLabel: { ...mainLabelStyle, width: CHIEF_DEP_KEY }, box: { width: CHIEF_DEP_KEY } }}
				/>
			</LabelComposition>
			<LabelComposition>
				<LabelCommon text="Заказчик" style={{ width: COMMON_KEYS_WIDTH }} />
				<LabelTwoVerticalLevel
					main={changeNullAndEmptyStringToDefault(data.signatures_customer, "-")}
					second="[должность, подпись (инициалы, фамилия)]"
					style={{ mainLabel: mainLabelStyle, box: { width: COMMON_VALUE_WIDTH } }}
				/>
			</LabelComposition>
		</div>
	);
};

export { SummaryEstmFooterSignatures };

export default function PartNormColumns() {
    return [
        {
            name: 'show_fsnb_content',
            title: '',
            width: '8px',
            cellHorAlign: 'left',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
            rowSpan: "2",
            action: "show_fsnb_content",
        },
        {
            name: 'show_fsnb_resource',
            title: '',
            width: '8px',
            cellHorAlign: 'left',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
            rowSpan: "2",
            action: "show_fsnb_resource"
        },
        {
            name: 'code',
            title: 'Шифр',
            width: '150px',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
            rowSpan: "2",
        },
        {
            name: 'name',
            title: 'Наименование',
            width: 'auto',
            cellHorAlign: 'left',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
            rowSpan: "2",
        },
        {
            name: 'measure_unit',
            title: 'Ед. изм',
            width: '70px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
            rowSpan: "2",
        },
        {
            name: 'quantity',
            title: 'Расход',
            width: '70px',
            cellHorAlign: 'right',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
            rowSpan: "2",
        },


    ];
}
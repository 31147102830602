import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import { getSelectedItemsSum } from "components/Footer/aggFuncs";

const SummaryEstimateViewerFooterSettings = ({ clickedTableCtrlRows }) => {
	return [
		{
			type: "common",
			text: `Выделено ячеек: ${clickedTableCtrlRows.length}. Сумма: ${getSelectedItemsSum({ clickedTableCtrlRows }) || ""}`,
			icon: <FunctionsOutlinedIcon />,
			position: "right",
			isVisible: true,
			callback: () => {},
			style: {},
			jsx: null,
		},
	];
};

export default SummaryEstimateViewerFooterSettings;

import LabelNormWithUnder from "components/UI/LabelNormWithUnder";
import { LabelTwoVerticalLevel } from "components/UI/labels";

const EstimateInfoHead = ({ data }) => {
    return (
        <>
            <LabelNormWithUnder
                type="norm-fixed-width"
                norm="Наименование программного продукта"
                under={data["soft_name"]}
            />
            <LabelNormWithUnder
                type="norm-fixed-width"
                norm="Наименование редакции сметных нормативов"
                under={data["legal_main_name"]}
            />
            <LabelNormWithUnder
                type="norm-fixed-width"
                norm="Реквизиты приказа Минстроя России об утверждении дополнений и изменений к сметным нормативам"
                under={data["legal_main_order"]}
            />
            <LabelNormWithUnder
                type="norm-fixed-width"
                norm="Наименование субъекта Российской Федерации"
                under={data["obj_region_name"]}
            />
            <LabelNormWithUnder
                type="norm-fixed-width"
                norm="Наименование зоны субъекта Российской Федерации"
                under={data["obj_subregion"]}
            />
            {/* <div style={{ marginTop: "10px" }}></div> */}
            <LabelTwoVerticalLevel main={data["name"]} second="(наименование стройки)" />
            <LabelTwoVerticalLevel main={data["obj_name"]} second="(наименование объекта капитального строительства)" />
        </>
    );
};

export default EstimateInfoHead;

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { ProtocolRibbonFilter } from "pages/EstimateViewer/ProtocolViewer/components";
import { setEditorIsVisible } from "slices/ribbon";

const ProtocolRibbonSettings = ({
	selectTypeData,
	gprVisible,
	saveFiltredDataGrp,
	proj_main,
	selectedErrorStatus,
	setSelectedErrorStatus,
	onClickSmetBtn,
	onClickMonitorBtn,
	onClickImportBtn,
	onClickPrintFullSmetBtn,
	onClickPrintCurrentSmetBtn,
	onClickExportSmetBtn,
	onClickExportCurrentSmetBtn,
	expectPopVisb,
	setExpectPopVisb,
	allowErPercVal,
	setAllowErPercVal,
	dispatch,
	clickedTableRow,
	lowerCallbacks,
	currentPage,
	currentEstmDocId,
	currentEstmDocUrlPart,
	userPermissions,
	setKatsJustUploadVisible,
	showDeleteDialog,
	navigate,
	localEstmData,
	showErrorDeleteDialog,
}) => {
	return [
		{
			name: "Импорт",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Импорт",
							icon: <FileUploadOutlinedIcon />,
							size: "large",
							type: "common",
							callback: onClickImportBtn,
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Режимы работы",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "вид",
							icon: <AddBoxOutlinedIcon />,
							size: "medium",
							type: "radio",
							radioArray: [
								{
									name: "Сметная документация",
									callback: onClickSmetBtn,
									currentPage: "EstimateDocViewer",
								},
								{
									name: "Протокол проверки",
									callback: () => {},
									currentPage: "ProtocolViewer",
								},
								{
									name: "Монитор проверки",
									callback: onClickMonitorBtn,
									currentPage: "MonitorViewer",
								},
							],
							callback: () => {},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Редактор",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: { flexDirection: "row" },
					_children: [
						{
							name: "Создать смету",
							icon: <NoteAddOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: userPermissions.is_lsr_editors !== true,
							callback: () => {
								navigate(`/project/${proj_main.id}/editor/lsr/new/`);
							},
							styles: {},
						},
						{
							name: "Редактор сметы",
							icon: <ModeEditOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled:
								currentPage !== "ProtocolViewer" ||
								currentEstmDocId == null ||
								localEstmData?.methodic !== "РИМ" ||
								userPermissions.is_lsr_editors !== true ||
								currentEstmDocUrlPart !== "lsr",
							callback: () => {
								dispatch(setEditorIsVisible(true));
								navigate(`/project/${proj_main.id}/editor/lsr/${currentEstmDocId}/`);
							},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Экспорт",
			styles: {},
			_children: [
				{
					name: "Subgroup 1.1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Экспорт протокола",
							icon: <FileOpenOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "По документу",
									value: "value",
									callback: onClickExportCurrentSmetBtn,
								},
								{
									name: "Печать документа с ошибками",
									value: "value",
									callback: () => onClickPrintCurrentSmetBtn(true),
									isDisabled: currentPage !== "LocalEstimateViewer",
								},
								{
									name: "По всему проекту",
									value: "value",
									callback: onClickExportSmetBtn,
								},
								{
									name: "Печать всего проекта с ошибками",
									value: "value",
									callback: () => onClickPrintFullSmetBtn(true),
								},
							],
						},
						{
							name: "Посмотреть в смете",
							icon: <ExitToAppOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							isDisabled: clickedTableRow === null || currentPage !== "ProtocolViewer",
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "Посмотреть в смете",
									callback: () => {
										lowerCallbacks.current.protocol["navigateWrapper"](clickedTableRow);
									},
								},
								{
									name: "Открыть смету в новой вкладке",
									callback: () => {
										lowerCallbacks.current.protocol["navigateWrapper"]({
											...clickedTableRow,
											new_tab: true,
										});
									},
								},
							],
						},
					],
				},
			],
		},
		{
			name: "Фильтр данных протокола",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Проверить смету",
							icon: <CalculateOutlinedIcon />,
							size: "large",
							type: "custom",
							customJsx: ProtocolRibbonFilter({
								selectTypeData: selectTypeData,
								gprVisible: gprVisible,
								saveFiltredDataGrp: saveFiltredDataGrp,
								proj_main: proj_main,
								selectedErrorStatus: selectedErrorStatus,
								setSelectedErrorStatus: setSelectedErrorStatus,
								allowErPercVal: allowErPercVal,
								setAllowErPercVal: setAllowErPercVal,
								dispatch: dispatch,
								userPermissions: userPermissions,
							}),
							callback: () => {},
							styles: {},
						},
					],
				},
				{
					name: "Subgroup 1",
					orientation: "vertical",
					_children: [
						{
							name: "Скрыть похожие записи",
							icon: <VisibilityOffOutlinedIcon />,
							size: "medium",
							type: "common",
							isDisabled: clickedTableRow === null || currentPage !== "ProtocolViewer",
							callback: () => lowerCallbacks.current.protocol["handleAddException"](clickedTableRow),
						},
						{
							name: "Список скрытых записей",
							icon: <FilterListOutlinedIcon />,
							size: "medium",
							type: "common",
							callback: () => setExpectPopVisb((prev) => !expectPopVisb),
						},
						{
							name: "Удалить ошибку",
							icon: <DeleteOutlinedIcon />,
							size: "medium",
							type: "common",
							isDisabled: clickedTableRow === null || currentPage !== "ProtocolViewer",
							isVisible: userPermissions?.is_expert === true,
							callback: () => showErrorDeleteDialog(),
						},
					],
				},
				{
					name: "Subgroup 1",
					orientation: "vertical",
					_children: [
						{
							name: "Отобрать данные по типу",
							icon: <FilterAltOutlinedIcon />,
							size: "medium",
							type: "common",
							isDisabled: clickedTableRow === null || currentPage !== "ProtocolViewer",
							callback: () => lowerCallbacks.current.protocol["filterThisTypeOnly"](clickedTableRow),
						},
						{
							name: "Скрыть данные по типу",
							icon: <FilterAltOffOutlinedIcon />,
							size: "medium",
							type: "common",
							isDisabled: clickedTableRow === null || currentPage !== "ProtocolViewer",
							callback: () => lowerCallbacks.current.protocol["hideThisType"](clickedTableRow),
						},
					],
				},
			],
		},
		{
			name: "Действия",
			isVisible: currentEstmDocUrlPart === "kats" && currentEstmDocId != null && currentEstmDocId !== false,
			styles: {},
			_children: [
				{
					name: "Subgroup",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Загрузить обоснования",
							icon: <AttachFileOutlinedIcon />,
							size: "large",
							type: "common",
							callback: () => setKatsJustUploadVisible(true),
							styles: {},
						},
					],
				},
				{
					name: "Subgroup",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Удалить КА",
							icon: <DeleteOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: ["SummaryEstimateViewer", "StartEstimateViewer", false].includes(currentPage),
							callback: () => showDeleteDialog({ cascade: true }),
							styles: {},
						},
					],
				},
			],
		},
	];
};

export default ProtocolRibbonSettings;

import { Avatar } from "@mui/material";
import Badge from "@mui/material/Badge";

import "./CustomUserCell.css";

const CustomUserCell = ({ rowObj, projectSelectData }) => {
	const cellValue = rowObj.username == null ? "" : rowObj.username.toString();

	const getAvatar = () => {
		let srcImg = rowObj?.avatar_url;
		return (
			<Avatar alt="Username" sx={{ width: 30, height: 30 }} src={srcImg}>
				{rowObj?.user_fio}
			</Avatar>
		);
	};
	return (
		<td
			key={`cell-username-${rowObj.id}`}
			className="TableCell CustomUserCell"
			clm="username"
			treecell="false"
			title={cellValue}
			onClick={() => projectSelectData([], rowObj)}
		>
			<div title={cellValue} className="userNameCell" style={{ display: "flex", alignItems: "center" }}>
				<Badge
					overlap="circular"
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					color={rowObj?.user_act_color}
					badgeContent=" "
					variant="dot"
				>
					{getAvatar()}
				</Badge>

				<span style={{ marginLeft: "10px" }}>{cellValue}</span>
			</div>
		</td>
	);
};

export default CustomUserCell;

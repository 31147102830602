import { apiBase } from "./base";

const apiResourceSheet = {
    client: apiBase.get_client(),
    url: "/resource_sheet/",

    getInfo(id) {
        return this.client.get(this.url + id + "/", apiBase.get_headers());
    },

    getList() {
        return this.client.get(this.url, apiBase.get_headers());
    },

    delete(id) {
        return this.client.delete(this.url + id + "/", apiBase.get_headers());
    },

    post(body) {
        return this.client.post(this.url, body, apiBase.get_headers());
    },

    patch(body, id) {
        return this.client.patch(this.url + id + "/", body, apiBase.get_headers());
    },

    create(id) {
        return this.client.get(this.url + id + "/create/", apiBase.get_headers());
    },

    getTable(id, priceLevel) {
        return this.client.get(this.url + id + "/table/" + priceLevel + "/", apiBase.get_headers());
    },

    getTableWithFilter(id, priceLevel, searchInput) {
        return this.client.get(this.url + id + "/table/" + priceLevel + "/" + searchInput + "/", apiBase.get_headers());
    },

    getExcel(id, priceLevel) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "blob";
        return this.client.get(this.url + id + "/excel/" + priceLevel + "/", headers);
    },

    getResourceMapping(id, code, res_type) {
        return this.client.get(this.url + id + "/res_mapping/" + code + "/" + res_type + "/", apiBase.get_headers());
    },

};

export default apiResourceSheet;

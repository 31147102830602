import { lazy, Suspense } from "react";

import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import FillColorSeparateIcon from "components/UI/icons/FillColorSeparateIcon";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";

const ColorPicker = lazy(() => import("components/UI/ColorPicker"));

const EstimateViewerContextMenuEstimateEnding = ({ contextMenuObj, callbacks, userSettings }) => {
    return [
        {
            name: "",
            subgroups: [
                {
                    name: "Subgroup",
                    items: [
                        {
                            label: "Комментарии",
                            icon: <CommentOutlinedIcon />,
                            type: "common",
                            disabled: false,
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["showComments"]({
                                    val: true,
                                    targetRowObj: contextMenuObj,
                                });
                                callbacks.closeContextMenu();
                            },
                            styles: {},
                        },
                        {
                            label: "Заливка",
                            icon: <FillColorSeparateIcon secondaryColor={userSettings?.USER_COLORS?.last_color} />,
                            type: "select-custom-options",
                            disabled: false,
                            callback: () => {
                                callbacks.fillColorTableRow({ hex: userSettings?.USER_COLORS?.last_color });
                                callbacks.closeContextMenu();
                            },
                            getJsxOptions: () => {
                                return (
                                    <Suspense>
                                        <ColorPicker
                                            type="GithubPicker"
                                            onChange={(color) => {
                                                callbacks.fillColorTableRow(color);
                                                callbacks.closeContextMenu();
                                            }}
                                        />
                                    </Suspense>
                                );
                            },
                        },
                    ],
                },
            ],

        },
        {
            name: "Позиция",
            subgroups: [
                {
                    name: "Subgroup",
                    items: [
                        {
                            label: "Позиция в XML",
                            icon: <CodeOutlinedIcon />,
                            type: "common",
                            disabled: false,
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["showErrorJustificationShort"]({ obj: "estimate" });
                                callbacks.closeContextMenu();
                            },
                        },
                        {
                            label: "Обоснование расчета",
                            icon: <CalculateOutlinedIcon />,
                            type: "common",
                            disabled: false,
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["showLogJustification"]({ obj: "estimate" });
                                callbacks.closeContextMenu();
                            },
                        },
                    ],
                },
            ],
        },
    ];
};

export { EstimateViewerContextMenuEstimateEnding };

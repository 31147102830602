import { createSlice } from "@reduxjs/toolkit";

export const somSlice = createSlice({
	name: "som",
	initialState: {
		tableData: [],
		curItem: {},
		changeAction: {},
		changesCount: 0,
	},
	reducers: {
		setTableData: (state, { payload }) => {
			state.tableData = payload;
		},
		setCurItem: (state, { payload }) => {
			state.curItem = payload;
		},
		editCurItemAttr: (state, { payload }) => {
			state.curItem = { ...state.curItem, [payload.key]: payload.value };
			state.changeAction = { key: "item", payload: { [payload.key]: payload.value }, action: "EDIT" };
		},
		addCurItemChar: (state, { payload }) => {
			state.curItem.chars = [...state.curItem.chars.filter((x) => x.char_id !== -1), payload];
		},
		editCurItemChar: (state, { payload }) => {
			const { itemCharValueObj, increaseChangesCount } = payload;
			state.curItem.chars = state.curItem.chars.map((itemChar) => {
				if (itemChar.key === itemCharValueObj.key) itemChar = itemCharValueObj;
				return itemChar;
			});
			state.changeAction = { key: "chars", payload: itemCharValueObj };
			if (itemCharValueObj.id === 0) {
				state.changeAction.action = "ADD";
			} else {
				state.changeAction.action = "EDIT";
			}
			if (increaseChangesCount) state.changesCount += 1;
		},
		delCurItemChar: (state, { payload }) => {
			const { itemCharValue } = payload;
			state.curItem.chars = state.curItem.chars.filter((itemChar) => itemChar.id !== itemCharValue.id);
			state.changeAction = { key: "chars", payload: itemCharValue, action: "DEL" };
			state.changesCount += 1;
		},
		addPriceOfferCurItemOffer: (state, { payload }) => {
			const { priceOffersIds } = payload;
			state.changeAction = { key: "offers", payload: { ids: priceOffersIds }, action: "ADD" };
			state.changesCount += 1;
		},
		editCurItemOffer: (state, { payload }) => {
			const { offerObj } = payload;
			state.curItem.offers = state.curItem.offers.map((offer) => {
				if (offer.id === offerObj.id) offer = offerObj;
				return offer;
			});
			state.changeAction = { key: "offers", payload: offerObj, action: "EDIT" };
			state.changesCount += 1;
		},
		delCurItemOffer: (state, { payload }) => {
			const { offerObj } = payload;
			state.changeAction = { key: "offers", payload: { id: offerObj.id }, action: "DEL" };
			state.changesCount += 1;
		},
		addCurItemCls: (state, { payload }) => {},
		editCurItemCls: (state, { payload }) => {
			const { clsObj } = payload;
			state.curItem.classifiers = state.curItem.classifiers.map((cls) => {
				if (cls.id === clsObj.id) cls = clsObj;
				return cls;
			});
			state.changeAction = { key: "classifiers", payload: clsObj, action: "EDIT" };
			state.changesCount += 1;
		},
		delCurItemCls: (state, { payload }) => {
			const { clsObj } = payload;
			state.changeAction = { key: "classifiers", payload: clsObj, action: "DEL" };
			state.changesCount += 1;
		},
		increaseChangesCount: (state, { payload }) => {
			state.changesCount += 1;
		},
		setChangeAction: (state, { payload }) => {
			state.changeAction = payload;
		},
	},
});

export const {
	setTableData,
	setCurItem,
	editCurItemAttr,
	addCurItemChar,
	editCurItemChar,
	delCurItemChar,
	addPriceOfferCurItemOffer,
	editCurItemOffer,
	delCurItemOffer,
	addCurItemCls,
	editCurItemCls,
	delCurItemCls,
	increaseChangesCount,
	setChangeAction,
} = somSlice.actions;

export default somSlice.reducer;

const CustomNumCell = ({ rowObj }) => {
	const cellCenterLabel = rowObj.num == null ? "" : rowObj.num.toString();
	const cellCenter = <span className="CustomNumCellCenter">{cellCenterLabel}</span>;

	let cellLeft = <span className="CustomNumCellLeft"></span>;
	let cellRight = <span className="CustomNumCellRight"></span>;

	if (rowObj.is_item === "1") {
		if (rowObj.has_cost) {
			let sumTypeShort = "";
			if (rowObj.summarytype === "Строительные") {
				sumTypeShort = "СТР";
			} else if (rowObj.summarytype === "Монтажные") {
				sumTypeShort = "МОН";
			} else if (rowObj.summarytype === "Прочее") {
				sumTypeShort = "ПРЧ";
			}
			cellRight = (
				<span className="CustomNumCellRight" title={rowObj.summarytype}>
					{sumTypeShort}
				</span>
			);
		}
	}

	if (rowObj.is_item === "1" || rowObj.protectable === true) {
		if (rowObj.model_name === "LocalEstimatesResMaterial") {
			cellRight = (
				<span className="CustomNumCellRight" title="Материал">
					МАТ
				</span>
			);
		} else if (rowObj.model_name === "LocalEstimatesResEquipment") {
			cellRight = (
				<span className="CustomNumCellRight" title="Оборудование">
					ОБ
				</span>
			);
		} else if (rowObj.model_name === "LocalEstimatesResMachine") {
			cellRight = (
				<span className="CustomNumCellRight" title="Машины и механизмы">
					МЕХ
				</span>
			);
		} else if (rowObj.model_name === "LocalEstimatesResTransport") {
			cellRight = (
				<span className="CustomNumCellRight" title="Перевозка">
					ПГ
				</span>
			);
		} else if (rowObj.model_name === "LocalEstimatesResOther") {
			cellRight = (
				<span className="CustomNumCellRight" title="Прочие">
					ПРЧ
				</span>
			);
		}
	}

	let height = 17;
	if (rowObj.rowHeightMultiply && rowObj.rowHeightMultiply !== 1) {
		height = 22 * rowObj.rowHeightMultiply;
	}

	return (
		<td
			key={`cell-num-${rowObj.key}`}
			className="TableCell"
			clm="num"
			treecell="false"
			style={{
				display: "flex",
				justifyContent: "space-around",
				height: `${height}px`,
				paddingLeft: 0,
				paddingRight: 0,
				alignItems: "center",
			}}
			title={cellCenterLabel}
		>
			<div className="CustomNumCell">
				{cellLeft}
				{cellCenter}
				{cellRight}
			</div>
		</td>
	);
};

export { CustomNumCell };

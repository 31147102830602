import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ProjectsTable from "./ProjectsTable";
import RibbonContent from "../../components/Ribbon/RibbonContent/RibbonContent";
import ConfirmDialog from "components/ConfirmDialog";
import AlertLoading, { selectFetchStatus } from "components/AlertLoading";

import {
	setFetchStatus,
	deleteProject,
	deleteProjectPack,
	pushNewEmpty,
	pushNewProjectToPack,
	editProject,
	setFetchStatusText,
	fetchProjectsList,
} from "slices/projects";
import { setConfirmOptions, setAnswer } from "slices/confirm";
import { fetchProjectErrorsExcel, setMonFetchStatus, getProjectInputXml } from "slices/project_work";
import { setCurrentEstmDocId, setCurrentProjectId } from "slices/ribbon";
import { setUserTasksVisible, getUserTasksAndRead } from "slices/user_adv";

import apiCalcer from "api/calcer";
import apiProjects from "api/projects";

import { getEnvVal } from "core/env.js";

import ProjectsRibbonSettings from "./ProjectsRibbonSettings";
import useDebounceCallback from "hooks/useDebounceCallback";

import "./ProjectsView.css";

export default function ProjectsView() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const fetchStatus = useSelector((state) => state.projects.fetchStatus);
	const fetchStatusText = useSelector((state) => state.projects.fetchStatusText);
	const fetchMonStatus = useSelector((state) => state.projectWork.fetchMonStatus);
	const confirmVisible = useSelector((state) => state.confirm.visible);
	const rowsData = useSelector((state) => state.projects.table);
	const allRowsDataCount = useSelector((state) => state.projects.table.all_data_count);
	const tableViewParams = useSelector((state) => state.projects.table.viewParams);
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const scrollBlock = useRef();
	const [priceZones, setPriceZones] = useState([]);
	const localFetchStatus = selectFetchStatus([fetchStatus, fetchMonStatus]);
	const [filterTrigger, setFilterTrigger] = useState(0);

	useEffect(() => {
		setFetchStatus("loading");
		apiCalcer
			.getPriceZones()
			.then((response) => {
				setPriceZones(response.data);
				setFetchStatus("success");
			})
			.catch((error) => {
				setFetchStatus("failed");
				console.log(error);
				if (error.response.status === 403) {
					dispatch(setFetchStatusText(error?.response?.data?.detail));
				}
			});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const addNewProject = () => dispatch(pushNewEmpty({ model_name: "UserProjects" }));
	const addNewProjectPack = () => dispatch(pushNewEmpty({ model_name: "UserProjectPacks" }));

	const addNewProjectToPack = ({ rowObj }) => dispatch(pushNewProjectToPack({ rowObj }));

	const checkIsProjectSelected = () => clickedTableRow && clickedTableRow.id && clickedTableRow.model_name === "UserProjects";

	const enterToProject = ({ targetId }) => {
		if (checkIsProjectSelected()) {
			dispatch(setCurrentEstmDocId(false));
			if (targetId) {
				navigate(`project/${targetId}/start/`);
			} else {
				navigate(`project/${clickedTableRow.id}/start/`);
			}
		}
	};

	const enterToProjectImport = () => {
		if (checkIsProjectSelected()) navigate(`/project/${clickedTableRow.id}/import/`);
	};

	const enterToLsrEditor = () => {
		if (checkIsProjectSelected()) {
			dispatch(setCurrentProjectId(clickedTableRow.id));
			navigate(`/project/${clickedTableRow.id}/editor/lsr/new/`);
		}
	};

	const getProjectXlsx = () => {
		if (checkIsProjectSelected()) {
			apiProjects.getProjectPrintXlsx(clickedTableRow.id).then((response) => {
				dispatch(getUserTasksAndRead());
				dispatch(setUserTasksVisible(true));
			});
		}
	};

	const getProjectProtocol = () => {
		if (checkIsProjectSelected()) dispatch(fetchProjectErrorsExcel(clickedTableRow.id));
	};

	const enableEditingMode = () => {
		if (clickedTableRow?.id) {
			dispatch(editProject({ key: clickedTableRow.key, inline_cell: "name", model_name: clickedTableRow.model_name }));
		}
	};

	const showDeleteDialog = () => {
		if (clickedTableRow.id) {
			dispatch(
				setConfirmOptions({
					label: "Удаление",
					text: "Удалить выбранный проект?",
					labelCancel: "Закрыть",
					labelOk: "Удалить",
					visible: true,
					callback: () => deleteSeleted(),
				})
			);
		}
	};

	const getProjectXmlZip = () => dispatch(getProjectInputXml(clickedTableRow.id));

	const deleteSeleted = () => {
		dispatch(setAnswer(false));
		if (clickedTableRow.model_name === "UserProjects") {
			dispatch(deleteProject(clickedTableRow.id));
		} else if (clickedTableRow.model_name === "UserProjectPacks") {
			dispatch(deleteProjectPack(clickedTableRow.id));
		}
	};

	const handleScroll = () => {
		if (fetchStatus === "loading") return;
		const { scrollTop, clientHeight, scrollHeight } = scrollBlock.current;
		const diff = Math.abs(scrollHeight - scrollTop - clientHeight);
		if (diff < 100 && rowsData.data.length < allRowsDataCount) {
			let count = rowsData.data.length + getEnvVal("ProjectsLimit");
			dispatch(fetchProjectsList({ limit: count, ...tableViewParams }));
		}
	};

	const getRequestObjLimit = () => {
		let count = rowsData.data?.length;
		if (!count || count < getEnvVal("ProjectsLimit")) count = getEnvVal("ProjectsLimit");
		return count;
	};

	useDebounceCallback({
		callback: () => getTableData(),
		delayInMs: 1000,
		useEffectTrigger: filterTrigger,
		allowCallback: filterTrigger > 0,
	});

	const getTableData = () => {
		dispatch(fetchProjectsList({ limit: getRequestObjLimit(), ...tableViewParams }));
	};

	useEffect(() => {
		if (tableViewParams.isEmpty === true) return;
		getTableData();
	}, [tableViewParams.sort]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (tableViewParams.isEmpty === true) return;
		setFilterTrigger((prev) => prev + 1);
	}, [tableViewParams.filter]); // eslint-disable-line react-hooks/exhaustive-deps

	const ribbonSettings = ProjectsRibbonSettings({
		addNewProject: addNewProject,
		addNewProjectPack: addNewProjectPack,
		addNewProjectToPack: addNewProjectToPack,
		showDeleteDialog: showDeleteDialog,
		enterToProject: enterToProject,
		enterToProjectImport: enterToProjectImport,
		enterToLsrEditor: enterToLsrEditor,
		getProjectXlsx: getProjectXlsx,
		getProjectProtocol: getProjectProtocol,
		enableEditingMode: enableEditingMode,
		getProjectXmlZip: getProjectXmlZip,
	});

	if (!priceZones.length) return;

	return (
		<>
			<RibbonContent ribbonSettings={ribbonSettings} />
			<div className="ProjectsView">
				<div className="Projects">
					<div className="Projects-header">
						<div className="Projects-title">Список проектов</div>
					</div>

					<div className="TableOuterBox" type="Projects" ref={scrollBlock} onScroll={handleScroll}>
						<ProjectsTable
							scrollBlock={scrollBlock}
							rowsData={rowsData}
							priceZones={priceZones}
							funcs={{ enterToProject: enterToProject, addNewProjectToPack: addNewProjectToPack, getTableData: getTableData }}
						/>
					</div>
				</div>
				<AlertLoading fetchStatus={localFetchStatus} fetchStatusText={fetchStatusText} func={[setFetchStatus, setMonFetchStatus]} />
				{confirmVisible === true && <ConfirmDialog />}
			</div>
		</>
	);
}

import { useRef, useEffect } from "react";

const useDebounceCallback = ({ callback, callbackArg, delayInMs, useEffectTrigger, allowCallback }) => {
	const callbacksStack = useRef([]);

	const closureFunc = (callbackArg) => {
		return () => callback(callbackArg);
	};

	useEffect(() => {
		if (!allowCallback) return;
		callbacksStack.current.push(closureFunc(callbackArg));
		setTimeout(() => {
			if (callbacksStack.current.length) {
				const lastCallback = callbacksStack.current.slice(-1)[0];
				lastCallback();
				callbacksStack.current = [];
			}
		}, delayInMs);

		return () => (callbacksStack.current = []);
	}, [useEffectTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

	return;
};

export default useDebounceCallback;

import { useState } from "react";

import TextField from "@mui/material/TextField";

import { IconButton, Tooltip } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

import CommentsBoxRow from "./CommentsBoxRow";
import CommentsBoxAdvRow from "./CommentsBoxAdvRow";

import "./CommentsBox.css";

const CommentsBox = ({ rowObj, inputComment, setInputComment, postComment, deleteComment, editComment, targetHeight }) => {
	const [selectedRow, setSelectedRow] = useState({});

	const handleClick = ({ clickedRow }) => setSelectedRow(clickedRow);

	return (
		<div className="CustomDetailsComments">
			<div className="CustomDetailsCommentsReadBlock">
				<div className="CustomDetailsCommentsReadBlockLeft">
					{rowObj._comments?.map((row, index) => {
						return <CommentsBoxRow key={index} rowObj={row} handleClick={handleClick} handleDelete={() => deleteComment(row)} />;
					})}
				</div>
				<div className="CustomDetailsCommentsReadBlockRight">
					<CommentsBoxAdvRow rowObj={selectedRow} handleSave={editComment} />
				</div>
			</div>
			<div className="CustomDetailsCommentsWriteBlock">
				<div className="CustomDetailsCommentsWriteTextBlock">
					<TextField
						sx={{ width: "80.7%" }}
						autoFocus
						id="outlined-multiline-static"
						label="Текст комментария"
						multiline
						rows={2}
						defaultValue=""
						value={inputComment}
						onChange={(e) => setInputComment(e.target.value)}
						InputLabelProps={{ shrink: true }}
					/>
				</div>
				<div className="CustomDetailsCommentsWriteButtonBlock">
					<Tooltip title="Отправить">
						<IconButton aria-label="Отправить" onClick={() => postComment(inputComment)}>
							<SendOutlinedIcon />
						</IconButton>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};

export default CommentsBox;

import { useSelector, useDispatch } from "react-redux";
import { clearTree } from "slices/project_work";

export const GetRibbonTabsSettings = () => {
	const dispatch = useDispatch();

	const lastEstimateCurrentPage = useSelector((state) => state.ribbon.lastEstimateCurrentPage);

	const currentProjectId = useSelector((state) => state.ribbon.currentProjectID);
	const currentEstmDocId = useSelector((state) => state.ribbon.currentEstmDocId);
	const currentResourceSheetId = useSelector((state) => state.ribbon.currentResourceID);
	const currentEstmDocUrlPart = useSelector((state) => state.ribbon.currentEstmDocUrlPart);
	const projectListData = useSelector((state) => state.projects.table.data);
	const resourceSheetData = useSelector((state) => state.resourceSheet.table.data);
	const getLastProjectId = () => projectListData && projectListData.filter((x) => x.model_name === "UserProjects")?.[0]?.id;
	const getLastResourcetId = () => resourceSheetData.length && resourceSheetData[0]?.id;
	const userPermissions = useSelector((state) => state.auth.permissions);
	const selectedProjects = useSelector((state) => state.projects.table.selectedData);
	const resSheetData = useSelector((state) => state.resourceSheet.table.data);
	const curLsr = useSelector((state) => state.localEstimate.data);
	const somsList = useSelector((state) => state.som.tableData);
	const getCurrentSomId = () => somsList && somsList?.[0]?.id;

	let selectedProjectId;
	if (selectedProjects?.length > 0) {
		selectedProjectId = selectedProjects.filter((x) => x.model_name === "UserProjects")?.[0]?.id;
	}

	let selectedResSheetId;
	if (resSheetData?.length > 0) {
		const selectedResSheet = resSheetData.filter((x) => x.selected === true);
		if (selectedResSheet?.length > 0) {
			selectedResSheetId = selectedResSheet[0].id;
		}
	}

	const projectRouteId = selectedProjectId || currentProjectId || getLastProjectId();
	const resSheetRouteId = selectedResSheetId || currentResourceSheetId || getLastResourcetId();

	const getCurrentEstimateRoute = () => {
		if (currentEstmDocUrlPart === "lsr") {
			return `/project/${currentProjectId}/lsr/${currentEstmDocId}`;
		} else if (currentEstmDocUrlPart === "kats") {
			return `/project/${currentProjectId}/kats/${currentEstmDocId}`;
		} else {
			return `/project/${projectRouteId}/start/`;
		}
	};

	const getCurrentProtocolRoute = () => {
		if (currentEstmDocUrlPart === "lsr") {
			return `/project/${currentProjectId}/lsr/${currentEstmDocId}/protocol`;
		} else if (currentEstmDocUrlPart === "kats") {
			return `/project/${currentProjectId}/kats/${currentEstmDocId}/protocol`;
		} else {
			return `/project/${projectRouteId}/start/`;
		}
	};

	const getCurrentMonitorRoute = () => {
		return `/project/${projectRouteId}/monitor/`;
	};

	const getCurrentRadioPageRoute = () => {
		if (lastEstimateCurrentPage === "ProtocolViewer") {
			return getCurrentProtocolRoute();
		} else if (lastEstimateCurrentPage === "MonitorViewer") {
			return getCurrentMonitorRoute();
		} else {
			return getCurrentEstimateRoute();
		}
	};

	const getEditorAllowDisplay = (curLocation) => {
		if (userPermissions.is_lsr_editors === false) return false;
		if (!projectRouteId) return false;
		if (!currentEstmDocId) return false;
		if (currentEstmDocUrlPart === "lsr") {
			if (curLsr?.methodic === "БИМ" && !curLocation.includes("/editor/")) return false;
			return true;
		} else if (currentEstmDocUrlPart === "kats") {
			return true;
		} else {
			return false;
		}
	};

	return [
		{
			name: "Список проектов",
			route: "/",
			isActive: (curLocation) => curLocation === "/",
			allowDisplay: () => userPermissions.is_guest === false || userPermissions.is_admin === true,
			callback: () => {
				dispatch(clearTree());
			},
		},
		{
			name: "Сметная документация",
			//route: projectRouteId != null ? getCurrentEstimateRoute() : "/",
			route: projectRouteId != null ? getCurrentRadioPageRoute() : "/",
			isActive: (curLocation) => curLocation.includes("/project/") && !curLocation.includes("/editor/"),
			allowDisplay: () => true,
		},
		{
			name: "Редактор",
			route:
				currentEstmDocUrlPart === "kats"
					? `/project/${projectRouteId}/editor/kats/${currentEstmDocId}/`
					: `/project/${projectRouteId}/editor/lsr/${currentEstmDocId}/`,
			isActive: (curLocation) => curLocation.includes("/editor/"),
			allowDisplay: (curLocation) => getEditorAllowDisplay(curLocation),
		},
		{
			name: "ФСНБ",
			route: "/fsnb/norm/",
			isActive: (curLocation) => curLocation.includes("/fsnb/"),
			allowDisplay: () => true,
		},
		{
			name: "Индексы",
			route: "/indexes/",
			isActive: (curLocation) => curLocation.includes("/indexes/"),
			allowDisplay: () => true,
		},
		{
			name: "Список ведомостей",
			route: "/resource_sheet/",
			isActive: (curLocation) => curLocation === "/resource_sheet/",
			allowDisplay: () => userPermissions.is_res_sheet_user === true,
		},
		{
			name: "Ресурсная ведомость",
			route: resSheetRouteId != null && resSheetRouteId !== 0 ? `/resource_sheet/${resSheetRouteId}/` : "/resource_sheet/",
			isActive: (curLocation) => curLocation.match(/resource_sheet\/\d+/),
			allowDisplay: () => userPermissions.is_res_sheet_user === true,
		},
		{
			name: "База ЦП",
			route: "/price_offers_base/",
			isActive: (curLocation) => curLocation === "/price_offers_base/",
			allowDisplay: () => userPermissions.is_kats_admin === true || userPermissions.is_som_user === true,
		},
		{
			name: "Список СОМ",
			route: "/som_item/",
			isActive: (curLocation) => curLocation === "/som_item/",
			allowDisplay: () => userPermissions.is_som_user === true,
		},
		{
			name: "Редактор СОМ",
			route: getCurrentSomId() == null ? "/som_item/" : `/som_item/${getCurrentSomId()}/`,
			isActive: (curLocation) => curLocation.match(/som_item\/\d+/),
			allowDisplay: () => userPermissions.is_som_user === true,
		},
	];
};

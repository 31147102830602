import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiLocalEstimate from "../api/local_estimate";

import { searchDataRecourse, unProtectInState, ProtectInState, changeRowDataRecourse } from "./utils";
import applyAdvSearch, { clearAdvSearchLocalEstimate } from "../pages/EstimateViewer/components/AdvSearchBox/apply_adv_search";
import { downloadFile } from "core/request_utils";
import { copyArr, changeNestedArrKeyValueOnCondition } from "core/iterable_utils";

import {
	ChangeSectionChildsVisible,
	ChangeEndingChildsVisible,
	ChangeItemChildsVisible,
	ShowHideItemsNameVisible,
	ShowHideCostChildrenVisible,
	ChangeTableVisibleOnColor,
} from "pages/EstimateViewer/LocalEstimateViewer/utils/table_utils";

export const deleteLocalEstimate = createAsyncThunk("localEstimateSlice/delete", async (id) => {
	const response = await apiLocalEstimate.delete(id);
	return response.data;
});

export const editLocalEstimate = createAsyncThunk("localEstimateSlice/edit", async (body) => {
	const response = await apiLocalEstimate.edit(body["id"], body);
	return response.data;
});

export const getLocaltEstimateTree = createAsyncThunk("localEstimateSlice/tree", async (id, { rejectWithValue }) => {
	try {
		const response = await apiLocalEstimate.getTree(id);
		return response.data;
	} catch (err) {
		if (err.response.status === 401) {
			console.log(err);
		}
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

export const getLocalEstimateTreeWithOutRefresh = createAsyncThunk("getLocalEstimateTreeWithOutRefresh/tree", async (id) => {
	const response = await apiLocalEstimate.getTree(id);
	return response.data;
});

export const calcLocalEstimate = createAsyncThunk("localEstimateSlice/calc", async (id, { rejectWithValue }) => {
	try {
		const response = await apiLocalEstimate.calculate(id);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const calcLocalEstimateCache = createAsyncThunk("localEstimateSlice/calcCache", async (body, { rejectWithValue }) => {
	try {
		const response = await apiLocalEstimate.calculateCache(body["id"], body["item_id"]);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const handlelocalEstimateIndex = createAsyncThunk("localEstimateSlice/handleIndex", async (body, { rejectWithValue }) => {
	try {
		const response = await apiLocalEstimate.handleIndex(body["id"], body);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const handleProtectObj = createAsyncThunk("localEstimateSlice/handleProtectObj", async (body, { rejectWithValue }) => {
	try {
		const response = await apiLocalEstimate.protectObj(body);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const handleUnProtectObj = createAsyncThunk("localEstimateSlice/handleUnProtectObj", async (id, { rejectWithValue }) => {
	try {
		const response = await apiLocalEstimate.unProtectObj(id);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const protectLocEstmFull = createAsyncThunk("localEstimateSlice/protectLocEstmFull", async (body, { rejectWithValue }) => {
	try {
		const response = await apiLocalEstimate.protectFull(body["id"], body);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const getInputXml = createAsyncThunk("localEstimateSlice/getInputXml", async (id) => {
	const response = await apiLocalEstimate.getInputXml(id);
	downloadFile(response);
});

export const getOutputXml = createAsyncThunk("localEstimateSlice/getOutputXml", async (id) => {
	const response = await apiLocalEstimate.getOutputXml(id);
	downloadFile(response);
});

export const getInputOriginXml = createAsyncThunk("localEstimateSlice/getInputOriginXml", async (id) => {
	const response = await apiLocalEstimate.getInputOriginXml(id);
	downloadFile(response);
});

export const getPrintXlsx = createAsyncThunk("localEstimateSlice/getPrintXlsx", async (id) => {
	const response = await apiLocalEstimate.getPrintXlsx(id);
	downloadFile(response);
});

export const getPrintXlsxWithErrors = createAsyncThunk("localEstimateSlice/getPrintXlsxWithErrors", async (id) => {
	const response = await apiLocalEstimate.getPrintXlsxWithErrors(id);
	downloadFile(response);
});

export const localEstimateSlice = createSlice({
	name: "localEstimateSlice",
	fetchStatus: null,
	fetchStatusText: null,
	initialState: {
		data: {},
		tableData: [],
		treeControlData: [],
		errorClms: {},
		advSearchResults: [],
	},
	reducers: {
		changeLocEstmVisible: (state, { payload }) => {
			let val = payload["val"];
			let row = payload["row"];
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = searchDataRecourse(state.tableData, row["key"], val);
		},
		increaseRowCnt: (state, { payload }) => {
			state.rowCnt = state.rowCnt + 1;
		},
		setLocalFetchStatus: (state, { payload }) => {
			state.fetchStatus = payload;
		},
		setLocalFetchStatusText: (state, { payload }) => {
			state.fetchStatusText = payload;
		},
		changeLocEstmErrorsVisible: (state, { payload }) => {
			const val = payload["val"];
			const row = payload["row"];
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = searchDataRecourse(state.tableData, row["key"], val, "_details", "errors_visible");
		},
		setAdvSearchSelected: (state, { payload }) => {
			state.advSearchResults = payload;
		},
		clearAdvSearchSelected: (state, { payload }) => {
			state.tableData = clearAdvSearchLocalEstimate(state.tableData);
		},
		applyAdvSearchSelected: (state, { payload }) => {
			const thisLocEstmSeleted = state.advSearchResults.filter((row) => row.estm_id === state.data.id);
			state.tableData = applyAdvSearch(state.tableData, thisLocEstmSeleted, "add");
		},
		addCostContent: (state, { payload: { rowKey, costContents } }) => {
			let isFound;
			for (let sec of state.tableData) {
				if (isFound) {
					break;
				}
				for (let item of sec._children) {
					if (item.model_name === "LocalEstimatesItems") {
						if (item._children == null) {
							continue;
						}
						const costParent = item._children.find((x) => x.model_name === "CostParentLabel");
						if (costParent.key === rowKey) {
							isFound = true;
							// costParent.childs_visible = true;
							costParent.costContentShow = true;
							const objArr = [];
							for (let x of costContents) {
								objArr.push({
									name: x,
									key: `${item.id}-${x}`,
									visible: true,
									model_name: "CostContent",
									show_always: true,
								});
							}
							costParent._children = [...objArr, ...costParent._children];
							break;
						}
					}
				}
			}
		},
		hideCostContent: (state, { payload: { rowKey } }) => {
			let isFound;
			for (let sec of state.tableData) {
				if (isFound) {
					break;
				}
				for (let item of sec._children) {
					if (item.model_name === "LocalEstimatesItems") {
						if (item._children == null) {
							continue;
						}
						const costParent = item._children.find((x) => x.model_name === "CostParentLabel");
						if (costParent.key === rowKey) {
							isFound = true;
							costParent.costContentShow = false;
							costParent._children = costParent._children.filter((x) => x.model_name !== "CostContent");
							break;
						}
					}
				}
			}
		},
		changeRowData: (state, { payload: { rowKey, callback } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = changeRowDataRecourse(state.tableData, rowKey, callback);
		},
		setTableData: (state, { payload }) => {
			state.tableData = payload;
		},
		editTableData: (state, { payload }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = changeRowDataRecourse(state.tableData, payload.key, (row) => {
				Object.keys(payload).forEach((key) => {
					row[key] = payload[key];
				});
				return row;
			});
		},
		delSectionUpdateEstimatePrices: (state, { payload: { deleteSectionKey, estimatePrices, sectionCodesMap } }) => {
			let tableDataCopy = copyArr(state.tableData);
			localStorage.setItem("DO_RECOURSE", "1");
			tableDataCopy = changeRowDataRecourse(tableDataCopy, deleteSectionKey, (row) => {
				row.isDeleted = true;
				return row;
			});
			tableDataCopy = tableDataCopy.map((row) => {
				if (sectionCodesMap[row.id]) row.num = sectionCodesMap[row.id];
				return row;
			});
			const estmPrcIndex = tableDataCopy.findIndex((row) => row.key === "section_--localestimaterimsections-0") + 1;
			state.tableData = [...tableDataCopy.slice(0, estmPrcIndex), ...estimatePrices];
		},
		multiPurposeUpdateTableData: (state, { payload }) => {
			let tableDataCopy = copyArr(state.tableData);
			if (payload["section_codes_map"]) {
				tableDataCopy = tableDataCopy.map((row) => {
					if (payload["section_codes_map"][row.id]) row.num = payload["section_codes_map"][row.id];
					return row;
				});
			}
			if (payload["deleted_key"]) {
				localStorage.setItem("DO_RECOURSE", "1");
				tableDataCopy = changeRowDataRecourse(tableDataCopy, payload["deleted_key"], (row) => {
					row.isDeleted = true;
					return row;
				});
			}
			if (payload["estimate_prices"]) {
				const estmPrcIndex = tableDataCopy.findIndex((row) => row.key === "section_--localestimaterimsections-0") + 1;
				tableDataCopy = [...tableDataCopy.slice(0, estmPrcIndex), ...payload["estimate_prices"]];
			}
			if (payload["full_estimate"]) {
				tableDataCopy = payload["full_estimate"]["table_data"];
			}
			state.tableData = tableDataCopy;
		},
		changeSectionChildsVisible: (state, { payload }) => {
			const { tableData, visibleSet } = ChangeSectionChildsVisible({
				tableData: state.tableData,
				value: payload,
				visibleSet: {},
			});
			state.tableData = tableData;
			state.visibleSettings = visibleSet;
		},
		changeEndingChildsVisible: (state, { payload }) => {
			const { tableData, visibleSet } = ChangeEndingChildsVisible({
				tableData: state.tableData,
				value: payload,
				visibleSet: {},
			});
			state.tableData = tableData;
			state.visibleSettings = visibleSet;
		},
		changeItemChildsVisible: (state, { payload }) => {
			const { tableData, visibleSet } = ChangeItemChildsVisible({
				tableData: state.tableData,
				value: payload,
				visibleSet: {},
			});
			state.tableData = tableData;
			state.visibleSettings = visibleSet;
		},
		showHideItemsNameVisible: (state) => {
			const { tableData } = ShowHideItemsNameVisible({ tableData: state.tableData, oneLineLen: 40 });
			state.tableData = tableData;
		},
		showCostChildren: (state) => {
			const { tableData } = ShowHideCostChildrenVisible({ tableData: state.tableData, val: true });
			state.tableData = tableData;
		},
		hideCostChildren: (state) => {
			const { tableData } = ShowHideCostChildrenVisible({ tableData: state.tableData, val: false });
			state.tableData = tableData;
		},
		colorFilter: (state, { payload }) => {
			const { tableData } = ChangeTableVisibleOnColor({ tableData: state.tableData, color: payload.color });
			state.tableData = tableData;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(deleteLocalEstimate.fulfilled, (state, { payload }) => {
			state.fetchStatus = "success";
		});

		builder.addCase(editLocalEstimate.fulfilled, (state, action) => {
			state.fetchStatus = "success";
		});

		builder.addCase(getLocaltEstimateTree.fulfilled, (state, action) => {
			state.data = action["payload"]["data"];
			if (localStorage.getItem("scrollType") === "lsr") {
				localStorage.setItem("DO_RECOURSE", "1");
				state.tableData = searchDataRecourse(
					action["payload"]["table_data"],
					localStorage.getItem("scrollKey"),
					null,
					"_details",
					"errors_visible"
				);
				localStorage.removeItem("scrollType");
				localStorage.removeItem("displayScrollTop");

				if (localStorage.getItem("highlightRowClm")) {
					state.tableData = changeNestedArrKeyValueOnCondition({
						arr: state.tableData,
						keySearch: "key",
						valSearch: localStorage.getItem("scrollKey"),
						keyChange: "advSelected",
						valChange: localStorage.getItem("highlightRowClm"),
						childrenKey: "_children",
					});
					localStorage.removeItem("highlightRowClm");
				}

				localStorage.removeItem("scrollKey");
			} else {
				state.tableData = action["payload"]["table_data"];
			}
			state.fetchStatus = "success";
		});

		builder.addCase(handleProtectObj.fulfilled, (state, { payload }) => {
			state.tableData = ProtectInState(state.tableData, payload);
			state.fetchStatus = "success";
		});

		builder.addCase(handleUnProtectObj.fulfilled, (state, action) => {
			let protected_id = action["meta"]["arg"];
			state.tableData = unProtectInState(state.tableData, protected_id);
			state.fetchStatus = "success";
		});

		builder.addCase(calcLocalEstimateCache.fulfilled, (state, { payload }) => {
			state.data = payload["data"];
			state.tableData = payload["table_data"];
			state.fetchStatus = "success";
		});

		builder.addCase(getLocalEstimateTreeWithOutRefresh.fulfilled, (state, { payload }) => {
			state.data = payload["data"];
			state.tableData = payload["table_data"];
			state.fetchStatus = "success";
		});

		builder.addCase(protectLocEstmFull.fulfilled, (state, { payload }) => {
			state.data = payload["data"];
			state.tableData = payload["table_data"];
			state.fetchStatus = "success";
		});

		builder.addMatcher(
			isAnyOf(
				calcLocalEstimate.fulfilled,
				handlelocalEstimateIndex.fulfilled,
				getInputXml.fulfilled,
				getPrintXlsx.fulfilled,
				getPrintXlsxWithErrors.fulfilled,
				getOutputXml.fulfilled,
				getInputOriginXml.fulfilled
			),
			(state, action) => {
				state.fetchStatus = "success";
			}
		);

		builder.addMatcher(
			isAnyOf(
				calcLocalEstimate.pending,
				calcLocalEstimateCache.pending,
				handlelocalEstimateIndex.pending,
				handleProtectObj.pending,
				handleUnProtectObj.pending,
				protectLocEstmFull.pending,
				editLocalEstimate.pending,
				getLocalEstimateTreeWithOutRefresh.pending,
				getInputXml.pending,
				getPrintXlsx.pending,
				getPrintXlsxWithErrors.pending,
				getOutputXml.pending,
				getInputOriginXml.pending
			),
			(state, action) => {
				state.fetchStatus = "loading";
			}
		);

		builder.addMatcher(isAnyOf(deleteLocalEstimate.pending, getLocaltEstimateTree.pending), (state, action) => {
			state.data = {};
			state.tableData = [];
			state.treeControlData = [];
			state.fetchStatus = "loading";
		});

		builder.addMatcher(
			isAnyOf(
				deleteLocalEstimate.rejected,
				editLocalEstimate.rejected,
				getLocaltEstimateTree.rejected,
				calcLocalEstimate.rejected,
				calcLocalEstimateCache.rejected,
				handlelocalEstimateIndex.rejected,
				handleProtectObj.rejected,
				handleUnProtectObj.rejected,
				protectLocEstmFull.rejected,
				getLocalEstimateTreeWithOutRefresh.rejected,
				getInputXml.rejected,
				getOutputXml.rejected,
				getInputOriginXml.rejected,
				getPrintXlsx.rejected,
				getPrintXlsxWithErrors.rejected
			),
			(state, action) => {
				state.fetchStatus = "failed";
				if (action.payload !== undefined) {
					if (action.payload.hasOwnProperty("error_text")) {
						state.fetchStatusText = action.payload.error_text;
					} else {
						state.fetchStatusText = action.payload?.detail;
					}
				}
			}
		);
	},
});

export const {
	changeLocEstmVisible,
	increaseRowCnt,
	setLocalFetchStatus,
	changeLocEstmErrorsVisible,
	saveWs,
	setLocalFetchStatusText,
	applyAdvSearchSelected,
	clearAdvSearchSelected,
	setAdvSearchSelected,
	addCostContent,
	hideCostContent,
	changeRowData,
	setTableData,
	delSectionUpdateEstimatePrices,
	multiPurposeUpdateTableData,
	editTableData,
	changeSectionChildsVisible,
	changeItemChildsVisible,
	showHideItemsNameVisible,
	changeEndingChildsVisible,
	showCostChildren,
	hideCostChildren,
	colorFilter,
} = localEstimateSlice.actions;

export default localEstimateSlice.reducer;

export default function NrspTableColumns() {
    return [
        {
            name: 'name',
            title: 'Наименование вида работ',
            width: 'auto',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
            actions_icon_on_cell: [{
                "check_value": {
                    "key": "show_action",
                    "value": [true],
                },
                action: "show_connected_norm_on_nrsp"
            }]
        },
        {
            name: 'nr_code',
            title: 'НР код',
            width: '100px',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'nr_1_value',
            title: 'НР, %',
            width: '100px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'nr_2_value',
            title: 'НР, % (МПРКС)',
            width: '100px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'nr_3_value',
            title: 'НР, % (РКС)',
            width: '100px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'sp_code',
            title: 'СП код',
            width: '100px',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'sp_value',
            title: 'СП, %',
            width: '100px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
    ];
}
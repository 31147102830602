import Cells from "./Cells";

import { TableDetailsHead, TableDetailsRow } from "./TableDetails/TableDetails";
import { TableDetailsPaper } from "./TableDetails/TableDetailsPaper";
import { EmptyRow } from "./TableDetails/EmptyRow";

import { getRowOnCellClick } from "./utils/row_utils.js";
import { getTargetHeight } from "./utils/cell_utils.js";

export default function Rows({ tableData, navigate, dispatch, ...props }) {
	const t0 = performance.now();

	const childLevel = props.childLevel;
	const rowsDom = [];

	if (tableData == null) return;

	const selectRow = (rowId, row, target = null, event = null) => {
		if (props.func !== undefined) {
			if (props.func["selectData"] !== undefined) {
				dispatch(props.func["selectData"](rowId));
			}
			if (props.func["selectDataLocal"] !== undefined) {
				props.func["selectDataLocal"](tableData, row, target, props.parentRow, event);
			}
		}
	};

	const handleVisibleChilds = (rowdict, key) => {
		if (props.func !== undefined) {
			if (props.func["setActiveRow"]) {
				dispatch(props.func["setActiveRow"]([]));
			}

			if (props.func[key] !== undefined) {
				if (props?.options?.funcCall === "local") {
					props.func[key]({ clickedRow: rowdict, tableData: tableData });
				} else {
					dispatch(
						props.func[key]({
							row: rowdict,
							id: rowdict["id"],
							val: null,
						})
					);
				}
			}
		}
	};

	for (let rowObj of tableData) {
		if (rowObj["visible"] !== false && rowObj["isDeleted"] !== true) {
			let ClassName = "TableRow";
			let tBold;
			if (rowObj["selected"] === true) {
				ClassName = "TableRow Selected";
			} else if (rowObj["row_type"] === "group") {
				ClassName = "TableRow Group";
			} else if (rowObj["style"] === "bold") {
				ClassName = "TableRow";
			}

			if (rowObj["style"] === "bold") tBold = "true";

			const key = rowObj.key == null ? rowObj.id : rowObj.key;
			const protectable = rowObj["protectable"] === true ? "true" : null;

			// выделение строки активного поиска
			// работает только при найденных строках в поиске на выделенную мышью строку
			let activeAdvSelected;

			if (props.options.advSeachActiveRow && props.options.advSeachActiveRow?.found_obj_key) {
				if (rowObj.key === props.options.advSeachActiveRow.found_obj_key) activeAdvSelected = "1";
			}
			// выделение строки активного поиска

			let bColor;
			if (rowObj["bgColor"] != null) bColor = rowObj["bgColor"];
			let rowDataStyle = {};
			if (rowObj?._style?._row) rowDataStyle = rowObj._row._style;

			// произвольная высота стоки
			let rowHeightMax;
			if (rowObj["rowHeightMultiply"] != null) {
				rowHeightMax = getTargetHeight(props.options) * rowObj["rowHeightMultiply"] + "px";
			}

			let isLastRow;
			if (rowObj.key === tableData.slice(-1)[0].key) isLastRow = "true";

			if (props.func.customRowCallback) props.func.customRowCallback(rowObj);

			const rowDom = (
				<tr
					key={key}
					id={key}
					model_name={rowObj["model_name"]}
					protectable={protectable}
					is_item={rowObj["is_item"]}
					scrolltoid={rowObj["scroll_to_id"]}
					tbold={tBold}
					advselected={rowObj["advSelected"]}
					activeadvselected={activeAdvSelected}
					row_type_style={rowObj["row_type_style"]}
					style={{ backgroundColor: bColor, height: rowHeightMax, maxHeight: rowHeightMax, ...rowDataStyle }}
					row_multi={rowObj.rowHeightMultiply == null || rowObj.rowHeightMultiply === 1 ? null : "true"}
					is_last_row={isLastRow}
					is_new_row={rowObj.is_new_row != null ? String(rowObj.is_new_row) : null}
					ignored_in_calc={rowObj.ignored_in_calc != null ? String(rowObj.ignored_in_calc) : null}
					is_animated={rowObj.is_animated != null ? String(rowObj.is_animated) : null}
					onClick={(e) => {
						if (props.options["selectable"] !== false) {
							if (["TableCell"].includes(e.target.className) || ["TreeFlexCell"].includes(e.target.parentNode.className)) {
								selectRow(rowObj["key"], rowObj, e.target, e);
							} else if (["TableCell"].includes(e.target?.parentNode?.className)) {
								if (props?.options?.singleClickOptions?.useTargetParentNode === true) {
									selectRow(rowObj["key"], rowObj, e.target.parentNode, e);
								}
							} else if (["TableCell"].includes(e.target?.parentNode?.parentNode?.className)) {
								if (props?.options?.singleClickOptions?.useTargetParentNode === true) {
									selectRow(rowObj["key"], rowObj, e.target.parentNode.parentNode, e);
								}
							} else if (String(e.target.className).includes("TableCell")) {
								if (props?.options?.singleClickOptions?.useStringInclude === true) {
									selectRow(rowObj["key"], rowObj, e.target, e);
								}
							}
						} else if (props.options["checkable"] !== true) {
							const _row = getRowOnCellClick(e.target);

							if (_row != null && props.options["selectableUncontrolled"] !== false) {
								if (_row.className !== "TableRow Group") {
									if (_row.className === "TableRow Selected") {
										_row.className = "TableRow";
									} else {
										_row.className = "TableRow Selected";
									}
								}
							}
						}

						const row_type = e.target.getAttribute("row_type");
						const parent_row_type = e.target.parentNode.getAttribute("row_type");

						if (row_type === "Group" || parent_row_type === "Group") {
							let isAllow = true;
							if (props.func.changeVisibleOptions != null) {
								if (!props.func.changeVisibleOptions.tagName.allowed.includes(e.target.tagName)) isAllow = false;
							}
							if (isAllow) handleVisibleChilds(rowObj, "changeVisible");
						} else if (row_type === "Details" || parent_row_type === "Details") {
							handleVisibleChilds(rowObj, "changeDetailsVisible");
						} else if (row_type === "ErrorsDetails" || parent_row_type === "ErrorsDetails") {
							handleVisibleChilds(rowObj, "changeErrorsDetailsVisible");
						} else if (row_type === "PaperDetails" || parent_row_type === "PaperDetails") {
							handleVisibleChilds(rowObj, "changePaperDetailsVisible");
						}

						if (props.func["singleClickRowAction"] != null) {
							const func = props.func["singleClickRowAction"];
							let isAllow = true;
							if (func.check != null) {
								isAllow = false;
								if (rowObj[func.check.key] === func.check.value) {
									isAllow = true;
								}
							}

							if (func.tagName != null) {
								if (func.tagName.allowed != null) {
									isAllow = false;
									for (let t of func.tagName.allowed) {
										if (String(t).toLowerCase() === String(e.target.tagName).toLowerCase()) {
											isAllow = true;
										}
									}
								}
							}

							if (isAllow && func.action) func.action(rowObj, e);
						}
					}}
					onDoubleClick={(e) => {
						// console.log("onDoubleClick", e.target);
						if (props.func["doubleClickRowAction"] != null) {
							const func = props.func["doubleClickRowAction"];
							let isAllow = true;
							if (func.check != null) {
								isAllow = false;
								if (rowObj[func.check.key] === func.check.value) {
									isAllow = true;
								}
							}
							if (isAllow) func.action(rowObj, e);
						}
					}}
					tabIndex="0"
					onKeyDown={(event) => {
						if (props.func["onKeyDownRowAction"]) {
							props.func["onKeyDownRowAction"].action(rowObj, event);
						}
					}}
					onContextMenu={(e) => {
						if (props.func["onContextMenuRowAction"] != null) {
							props.func["onContextMenuRowAction"].action(e, rowObj, props.parentRow);
						}
					}}
					className={ClassName}
				>
					<Cells
						row={rowObj}
						columns={props.columns}
						columnsFlat={props.columnsFlat}
						tableData={tableData}
						func={props.func}
						childsVisible={rowObj["childs_visible"]}
						errorsVisible={rowObj["errors_visible"]}
						dispatch={dispatch}
						childLevel={childLevel}
						treeClmn={props.treeClmn}
						rowOptions={props.options}
						rowClassName={ClassName}
						parentRow={props.parentRow}
					/>
				</tr>
			);

			rowsDom.push(rowDom);

			let rowPositionInList = rowsDom.length;

			// CustomDetails
			if (props.options.customRowDetail) {
				const RowDetailJsx = props.options.customRowDetail.Jsx;
				const allowAdding = props.options.customRowDetail.allowAdding({ rowObj });
				const emptyRowCount = props.options.customRowDetail.emptyRowCount({ rowObj });
				if (allowAdding) {
					rowsDom.push(
						<RowDetailJsx rowObj={rowObj} options={props.options} funcs={props.func} key={`customDetail-${rowObj.id}-${rowObj.key}`} />
					);
					for (let i of [...Array(emptyRowCount).keys()]) {
						rowsDom.push(<EmptyRow key={`customDetail-${rowObj.id}-${rowObj.key}-${i}`} />);
					}
				}
			}

			if (rowObj.hasOwnProperty("_children")) {
				let newChildLevel = childLevel;
				if (rowObj["row_type"] !== "group") {
					newChildLevel = childLevel + 1;
				}

				if (rowObj["_children"] != null && rowObj["_children"].length > 0) {
					let childsRows = Rows({
						tableData: rowObj["_children"],
						columns: props.columns,
						columnsFlat: props.columnsFlat,
						func: props.func,
						options: props.options,
						navigate: navigate,
						dispatch: dispatch,
						childLevel: newChildLevel,
						treeClmn: rowObj["colSpanTarget"],
						parentRow: rowObj,
					});

					for (let child of childsRows) {
						rowsDom.push(child);
					}
				}
			}

			// Details
			if (rowObj?._details?.length > 0 && rowObj.errors_visible) {
				if (rowObj != null) {
					const detailHead = (
						<TableDetailsHead
							key={`detail_head-${rowObj.id}-${rowObj.target_key}-${rowObj.key}`}
							rowObj={rowObj}
							columnsObj={props.options["detailsClms"]}
							columnsFlat={props.columnsFlat}
						/>
					);

					rowsDom.splice(rowPositionInList, 0, detailHead);
					rowPositionInList++;

					let j = 0;
					const childsCnt = rowObj._details.length;
					for (let child of rowObj._details) {
						j += 1;
						let trDelCls = "DetailsErrorRow";
						if (childsCnt === j) trDelCls += " Last";
						const detailRow = (
							<TableDetailsRow
								key={`detail_val_${j}-${rowObj.id}-${child.id}-${rowObj.key}`}
								row_dct={child}
								rowParent={rowObj}
								columns={props.options["detailsClms"]}
								columnsFlat={props.columnsFlat}
								rowOptions={props.options}
								func={props.func}
								trDelCls={trDelCls}
							/>
						);
						rowsDom.splice(rowPositionInList, 0, detailRow);
						rowPositionInList++;
					}

					if (rowObj.addEmptyRowsCnt != null) {
						for (let i = 0; i < rowObj.addEmptyRowsCnt; i++) {
							rowsDom.splice(rowPositionInList, 0, <EmptyRow key={`${rowObj.key}-${i}`} />);
							rowPositionInList++;
						}
					}
				}
			}

			// PaperDetails
			if (rowObj?._paper_details?.length > 0 && rowObj.paper_visible) {
				rowsDom.push(
					<TableDetailsPaper
						key={`pap-${rowObj.id}-${rowObj.key}-main`}
						row={rowObj._paper_details[0]}
						options={props.options}
						func={props.func}
						columnsFlat={props.columnsFlat}
						parent={{ row: rowObj }}
						tableData={tableData}
					/>
				);
				rowsDom.push(<EmptyRow key={`pap-${rowObj.id}-${rowObj.key}-1`} />);
				rowsDom.push(<EmptyRow key={`pap-${rowObj.id}-${rowObj.key}-2`} />);
				rowsDom.push(<EmptyRow key={`pap-${rowObj.id}-${rowObj.key}-3`} _className="LastBlank" />);
			}
		}

		// добавление пустых строк для раскрытия протокола
		if (rowObj != null && rowObj.visible !== false) {
			if (rowObj.rowHeightMultiply != null && rowObj.rowHeightMultiply > 1) {
				for (let i = 1; i < rowObj.rowHeightMultiply; i++) {
					rowsDom.push(<EmptyRow key={`${rowObj.key}-${i}-${rowObj.id}`} />);
				}
			}
		}
	}

	const t1 = performance.now();
	if (t1 - t0 > 100) console.log(`Call to Table Rows took ${t1 - t0} milliseconds.`);
	return rowsDom;
}

import { createSlice } from '@reduxjs/toolkit';

export const globalVisibleSlice = createSlice({
    name: 'globalVisibleSlice',
    initialState: {
        question: false,
        newsFeed: false,
    },
    reducers: {
        setQuestionVisible: (state, { payload }) => {
            state.question = payload;
        },
        setNewsFeedVisible: (state, { payload }) => {
            state.newsFeed = payload;
        },
    },
});

export const { setQuestionVisible, setNewsFeedVisible } = globalVisibleSlice.actions;

export default globalVisibleSlice.reducer;

const useLocalStorage = () => {
	const getLsItem = ({ key, _type }) => {
		let item = localStorage.getItem(key);
		if (_type === "int") {
			const intItem = parseInt(item);
			if (isNaN(intItem)) {
				return 0;
			} else {
				return parseInt(item);
			}
		}
		return item;
	};

	const setLsItem = ({ key, value }) => {
		localStorage.setItem(key, value);
	};

	const removeLsItem = ({ key, keySubStr }) => {
		if (key) localStorage.removeItem(key);
		if (keySubStr) {
			for (let i = 0; i < localStorage.length; i++) {
				if (String(localStorage.key(i)).includes(String(keySubStr))) {
					localStorage.removeItem(localStorage.key(i));
				}
			}
		}
	};

	return {
		getLsItem,
		setLsItem,
		removeLsItem,
	};
};

export { useLocalStorage };

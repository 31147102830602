import React, { useState, useRef } from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import CheckIcon from "@mui/icons-material/Check";

export default function KsprButtonWithOption({
    f_onMenuItemClick,
    f_handleStartBtnClick,
    options,
    disabled,
    ksprBtnTitle = "Запустить проверку",
    noMarginLeft,
    f_handleButtonClick,
}) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleClick = () => {
        f_handleStartBtnClick();
    };

    const handleMenuItemClick = (event, value) => {
        f_onMenuItemClick(value);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <ButtonGroup
                className={noMarginLeft ? "KsprButtonWithOption noMarginLeft" : "KsprButtonWithOption"}
                disabled={disabled}
                size="small"
                variant="outlined"
                ref={anchorRef}
                aria-label="split button">
                <Button
                    startIcon={<CheckIcon sx={disabled ? { color: "gray" } : { color: "green" }} />}
                    size="small"
                    onClick={handleClick}
                    sx={{ fontSize: 11, fontWeight: "600" }}>
                    <div className="splitBtnName">{ksprBtnTitle}</div>
                </Button>
                {options.length > 0 && <Button
                    className="splitBtnPopperArrow"
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}>
                    <ArrowDropDownIcon />
                </Button>}

            </ButtonGroup>
            <Popper
                className="splitBtnPopper"
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom">
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                                <MenuItem
                                    key={option.value}
                                    onClick={(event) => handleMenuItemClick(event, option.value)}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
}

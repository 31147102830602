import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { patchProject } from "slices/projects";
import apiCalcer from "api/calcer";
import apiFsnb from "api/fsnb";

import "./ProjectSettingsRibbon.css";

const ProjectSettingsRibbon = () => {
	const dispatch = useDispatch();
	const projMain = useSelector((state) => state.projectWork.main);
	const [priceZones, setPriceZones] = useState([]);
	const [selectedPriceZone, setSelectedPriceZone] = useState();
	const northTerrs = [
		{ id: 1, name: "Обычная" },
		{ id: 2, name: "МПРКС" },
		{ id: 3, name: "РКС" },
	];
	const [selectedNorthTerr, setSelectedNorthTerr] = useState();
	const [nrspVers, setNrspVers] = useState([]);
	const [selectedNrspVers, setSelectedNrspVers] = useState();

	useLayoutEffect(() => {
		apiCalcer.getPriceZones().then((response) => setPriceZones(response.data));
		apiFsnb.getNrpsPeriod().then((response) => setNrspVers(response.data));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (projMain.price_zone_id) setSelectedPriceZone(priceZones.find((pz) => pz.id === projMain.price_zone_id));
		if (projMain.north_reg_terr_id) setSelectedNorthTerr(northTerrs.find((nt) => nt.id === projMain.north_reg_terr_id));
		if (projMain.nrsp_vers_id) setSelectedNrspVers(nrspVers.find((nr) => nr.id === projMain.nrsp_vers_id));
	}, [priceZones, nrspVers, projMain?.price_zone_id, projMain?.nrsp_vers_id]); // eslint-disable-line react-hooks/exhaustive-deps

	if (priceZones.length === 0) return;
	if (!projMain) return;
	if (!projMain.north_reg_terr_id) return;
	if (projMain.price_zone_id === undefined) return;
	if (projMain.price_zone_id && !selectedPriceZone) return;

	return (
		<div className="ProjectSettingsRibbon">
			<div className="ProjectSettingsRibbonRow">
				<Autocomplete
					sx={{ width: "100%", "& legend": { fontSize: "9px" } }}
					size="small"
					options={priceZones}
					value={selectedPriceZone || null}
					disableClearable
					getOptionLabel={(option) => option.name || ""}
					isOptionEqualToValue={(option, value) => option.name === value.name}
					noOptionsText="нет данных"
					onInputChange={(e, val) => {
						const clickedVal = priceZones.find((x) => x.name === val);
						if (clickedVal && clickedVal?.id !== selectedPriceZone?.id) {
							dispatch(patchProject({ id: projMain.id, price_zone_id: clickedVal.id }));
							setSelectedPriceZone(clickedVal);
						}
					}}
					renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} label={"Ценовая зона"} />}
				/>
				<Autocomplete
					sx={{ width: "100%", "& legend": { fontSize: "9px" } }}
					size="small"
					options={northTerrs}
					value={selectedNorthTerr || null}
					disableClearable
					getOptionLabel={(option) => option.name || ""}
					isOptionEqualToValue={(option, value) => option.name === value.name}
					noOptionsText="нет данных"
					onInputChange={(e, val) => {
						const clickedVal = northTerrs.find((x) => x.name === val);
						if (clickedVal && clickedVal?.id !== selectedNorthTerr?.id) {
							dispatch(patchProject({ id: projMain.id, north_reg_terr_id: clickedVal.id }));
							setSelectedNorthTerr(clickedVal);
						}
					}}
					renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} label={"Признак территории"} />}
				/>
			</div>
			<div className="ProjectSettingsRibbonRow Top">
				<Autocomplete
					sx={{ width: "100%", "& legend": { fontSize: "9px" } }}
					size="small"
					options={nrspVers}
					value={selectedNrspVers || null}
					disableClearable
					getOptionLabel={(option) => option.name || ""}
					isOptionEqualToValue={(option, value) => option.name === value.name}
					noOptionsText="нет данных"
					onInputChange={(e, val) => {
						const clickedVal = nrspVers.find((x) => x.name === val);
						if (clickedVal && clickedVal?.id !== selectedNrspVers?.id) {
							dispatch(patchProject({ id: projMain.id, nrsp_vers_id: clickedVal.id }));
							setSelectedNrspVers(clickedVal);
						}
					}}
					renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} label={"Номер приказа НРСП"} />}
				/>
			</div>
		</div>
	);
};

export default ProjectSettingsRibbon;

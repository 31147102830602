import SearchIcon from "@mui/icons-material/Search";

const IndexesOtherRibbonSettings = (periodPriceZoneAutocomplete, objectAutocomplete) => {
    return [
        {
            name: "Период и ценовая зона",
            styles: {},
            _children: [
                {
                    name: "Subgroup 1",
                    orientation: "vertical",
                    styles: {},
                    _children: [
                        {
                            name: "Проверить смету",
                            icon: <SearchIcon />,
                            size: "large",
                            type: "custom",
                            customJsx: periodPriceZoneAutocomplete(),
                            callback: () => {},
                            styles: {
                                width: "240px",
                                pt: "7px",
                            },
                        },
                    ],
                },
            ],
        },
        {
            name: "Параметры отбора данных",
            styles: {},
            _children: [
                {
                    name: "Subgroup 1",
                    orientation: "vertical",
                    styles: {},
                    _children: [
                        {
                            name: "Проверить смету",
                            icon: <SearchIcon />,
                            size: "large",
                            type: "custom",
                            customJsx: objectAutocomplete(),
                            callback: () => {},
                            styles: {
                                width: "370px",
                                pt: "7px",
                            },
                        },
                    ],
                },
            ],
        },
    ];
};

export default IndexesOtherRibbonSettings;

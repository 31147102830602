const getDigitValOrDefault = (val, _default) => {
    try {
        return parseFloat(val);
    } catch {
        return _default;
    }
};

const checkTextIsDigit = (text) => {
    if (typeof text === 'number' || (typeof text === 'string' && !isNaN(text))) {
        return true;
    } else return false;

}

export { getDigitValOrDefault, checkTextIsDigit };

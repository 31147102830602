import CellWithAdditionalIcon from "components/Table/CellFormatter/CellWithAdditionalIcon";

import { getCustomCellHeight, getCustomCellColSpan, getCustomCellContent } from "components/Table/utils/custom_cell_utils";
import "./CustomNameCell.css";

const CustomNameCell = ({ rowObj, funcs, clmObj, parentProps, inputSearchValue }) => {
	let { cellStrContent, cellJsxContent } = getCustomCellContent({
		rowObj,
		clmName: "name",
		applyHighlight: true,
		subStrHighlight: inputSearchValue,
	});

	let whiteSpace = "nowrap";
	if (rowObj.rowHeightMultiply && rowObj.rowHeightMultiply > 1) {
		whiteSpace = "normal";
	}
	let cellJsx = cellJsxContent;

	return (
		<td
			key={`cell-name-${rowObj.key}`}
			className="TableCell"
			clm="name"
			treecell="false"
			colSpan={getCustomCellColSpan({ colSpanTarget: "name", rowObj })}
			style={{
				height: `${getCustomCellHeight({ initHeight: 17, rowMultiplyHeight: 22, rowObj })}px`,
			}}
			title={cellStrContent}
			onClick={() => funcs.selectDataLocal(null, rowObj, null, null)}
		>
			<div className="TreeFlexCell" title={cellStrContent} style={{ justifyContent: "space-between" }}>
				<span
					className="TreeFlexCellSpan"
					title={cellStrContent}
					clm="name"
					lang="ru-RU"
					style={{ whiteSpace: whiteSpace, overflow: "hidden", textOverflow: "ellipsis" }}
				>
					{cellJsx}
				</span>
				<CellWithAdditionalIcon
					row={rowObj}
					clm={clmObj}
					rowOptions={parentProps.rowOptions}
					funcs={funcs}
					parentRow={parentProps.parentRow}
				/>
			</div>
		</td>
	);
};

export default CustomNameCell;

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomNumCell } from "./components/CustomCell/CustomNumCell";

import FlakyOutlinedIcon from "@mui/icons-material/FlakyOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

import KsprButton from "components/UI/buttons/KsprButton";
import { CustomMarksCell } from "pages/EstimateViewer/LocalEstimateViewer/components/CustomCell/CustomMarksCell";

import { setKatsFetchStatus, setKatsData, getKatsTree, setFilterParams, changeKatsPaperDetailsVisible } from "slices/kats";

import apiKatsEstimate from "api/kats";

export const KatsTableColumns = ({ fileName }) => {
	const dispatch = useDispatch();
	const userPermissions = useSelector((state) => state.auth.permissions);
	const rowsData = useSelector((state) => state.kats.data);

	const { katsId } = useParams();

	const localValidateResource = ({ rowObj, val }) => {
		dispatch(
			setKatsData(
				rowsData.map((row) => {
					if (row.id === rowObj.id) row = { ...row, expert_valid: val };
					return row;
				})
			)
		);
	};

	const remoteValidateResource = ({ rowObj, val }) => {
		dispatch(setKatsFetchStatus("loading"));
		const prom = apiKatsEstimate.setExpertValid({ resId: rowObj.id, expertValidValue: val });
		prom.then((reponse) => {
			localValidateResource({ rowObj, val });
			dispatch(getKatsTree(katsId));
			dispatch(setKatsFetchStatus("success"));
		}).catch((err) => {
			console.error(err);
			dispatch(setKatsFetchStatus("failed"));
		});
	};

	const clmWidth = {
		num: 40,
		code: 270,
		unit: 65,
		company_name: 130,
		project_weight: 120,
		cost_zsr: 70,
		perc_zsr: 50,
		cost_transportation: 75,
		cost_doc: 90,
		cost_doc_5: 90,
		cost: 100,
		price_level_print: 75,
		check: 30,
		name: 0,
	};

	const sumWidht =
		clmWidth.num +
		clmWidth.code +
		clmWidth.unit +
		clmWidth.company_name +
		clmWidth.project_weight +
		clmWidth.cost_zsr +
		clmWidth.perc_zsr +
		clmWidth.cost_transportation +
		clmWidth.cost_doc +
		clmWidth.cost_doc_5 +
		clmWidth.cost +
		clmWidth.price_level_print +
		clmWidth.check;

	clmWidth.name = window.innerWidth * 0.7 - sumWidht;
	if (clmWidth.name < 300 || clmWidth.name > 300) clmWidth.name = 300;

	let clms = [
		{
			name: "num",
			title: "№",
			width: `${clmWidth.num}px`,
			cellHorAlign: "center",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
			cellJsx: ({ rowObj }) => CustomNumCell({ rowObj }),
		},
		{
			name: "code",
			title: "Код ресурса",
			width: `${clmWidth.code}px`,
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
			cellJsx: ({ rowObj }) => {
				const cellValue = rowObj.code == null ? "" : rowObj.code.toString();
				if (rowObj.is_expert_offer) {
					return (
						<td
							key={`cell-code-${rowObj.id}`}
							className="TableCell"
							clm="code"
							treecell="false"
							title={cellValue}
							onClick={() => dispatch(changeKatsPaperDetailsVisible({ row: rowObj }))}
						>
							<div title={cellValue} className="TreeActionCell" style={{ display: "flex", justifyContent: "space-between" }}>
								<span style={{ marginLeft: "2px" }}>{cellValue}</span>
								<SchoolOutlinedIcon />
							</div>
						</td>
					);
				} else {
					return (
						<td
							key={`cell-code-${rowObj.id}`}
							className="TableCell"
							clm="code"
							treecell="false"
							title={cellValue}
							onClick={() => dispatch(changeKatsPaperDetailsVisible({ row: rowObj }))}
						>
							{cellValue}
						</td>
					);
				}
			},
		},
		{
			name: "name",
			title: "Наименование строительного ресурса, затрат",
			width: `${clmWidth.code}px`,
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
		},
		{
			name: "unit",
			title: "Ед. изм",
			width: `${clmWidth.unit}px`,
			cellHorAlign: "center",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "10px",
		},
		{
			name: "company_name",
			title: "Поставщик",
			width: `${clmWidth.company_name}px`,
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
		},
		{
			name: "project_weight",
			title: "Вес в проекте",
			width: `${clmWidth.project_weight}px`,
			cellHorAlign: "right",
			headHorAlign: "center",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
			cellJsx: ({ rowObj }) => {
				let title = "";
				if (rowObj.project_weight_f_percent && rowObj.project_weight_value) {
					title = `${rowObj.project_weight_f_percent} ${rowObj.project_weight_value}`;
				}
				return (
					<td
						key={`cell-project_weight-${rowObj.id}`}
						className="TableCell"
						clm="weight"
						treecell="false"
						style={{ justifyContent: "flex-end", gap: "3px", display: "table-cell", textAlign: "end" }}
						title={title}
						onClick={() => dispatch(changeKatsPaperDetailsVisible({ row: rowObj }))}
					>
						<span>{rowObj.project_weight_f_percent}</span>
						<span style={{ fontSize: "9px", marginLeft: "1px" }}>{rowObj.project_weight_value}</span>
					</td>
				);
			},
		},
		{
			name: "price_level_print",
			title: "Уровень цен",
			width: `${clmWidth.price_level_print}px`,
			cellHorAlign: "right",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
		},
		{
			name: "cost_doc",
			title: "Цена с НДС в док-те",
			width: `${clmWidth.cost_doc}px`,
			cellHorAlign: "right",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
		},
		{
			name: "cost_doc_5",
			title: "Цена без НДС за ЕИ",
			width: `${clmWidth.cost_doc_5}px`,
			cellHorAlign: "right",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
		},
		{
			name: "cost_transportation",
			title: "Стоимость перевозки",
			width: `${clmWidth.cost_transportation}px`,
			cellHorAlign: "right",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
		},
		{
			name: "cost_zsr",
			title: "ЗСР, руб.",
			width: `${clmWidth.cost_zsr}px`,
			cellHorAlign: "right",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
		},
		{
			name: "perc_zsr",
			title: "ЗСР, %",
			width: `${clmWidth.perc_zsr}px`,
			cellHorAlign: "right",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
		},
		{
			name: "cost",
			title: "СЦ без НДС",
			width: `${clmWidth.cost}px`,
			cellHorAlign: "right",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
		},
		{
			name: "check",
			title: "",
			width: `${clmWidth.check}px`,
			cellJsx: ({ rowObj, parentProps, funcs }) => CustomMarksCell({ rowObj, parentProps, funcs }),
		},
	];

	if (userPermissions.is_kats_expert && !fileName) {
		clms = [
			{
				name: "expert_valid",
				title: "",
				width: "25px",
				cellHorAlign: "center",
				headHorAlign: "left",
				rowSpan: "2",
				headHeight: "12px",
				marginLeft: "5px",
				cellJsx: ({ rowObj }) => {
					if (rowObj.model_name === "KatsResources") {
						let icon;
						if (rowObj?.expert_valid === null) {
							icon = <FlakyOutlinedIcon />;
						} else if (rowObj?.expert_valid === true) {
							icon = <CheckCircleOutlinedIcon />;
						} else if (rowObj?.expert_valid === false) {
							icon = <HighlightOffOutlinedIcon />;
						}
						return (
							<td key={`expert-valid-${rowObj.id}`}>
								<div
									className="ExpertValidIcon"
									onClick={(e) => {
										let newVal;
										if (rowObj?.expert_valid === null) {
											newVal = true;
										} else if (rowObj?.expert_valid === true) {
											newVal = false;
										} else if (rowObj?.expert_valid === false) {
											newVal = null;
										}
										remoteValidateResource({ rowObj: rowObj, val: newVal });
									}}
								>
									{icon}
								</div>
							</td>
						);
					} else {
						return <td key={`expert-valid-${rowObj.id}`}></td>;
					}
				},
				headerCellJsx: ({ clmObj }) => {
					return (
						<th clm={clmObj.name} key={clmObj.name} style={{ width: clmObj.width, maxWidth: clmObj.width }}>
							<div
								className="ExpertValidIconHead"
								onClick={(e) => {
									dispatch(
										setFilterParams({
											visible: true,
											posX: e.clientX,
											posY: e.clientY,
										})
									);
								}}
							>
								<FilterListOutlinedIcon />
							</div>
						</th>
					);
				},
			},
			...clms,
		];
	}
	if (fileName) {
		clms = [
			clms[0],
			{
				name: "file_name",
				title: "Наименование файла",
				width: `130px`,
				cellHorAlign: "left",
				headHorAlign: "left",
				rowSpan: "2",
				headHeight: "12px",
				marginLeft: "5px",
				sort: true,
			},
			...clms.slice(1, 99),
		];
	}

	return clms;
};

export const KatsDetailsPaper = ({ setInnKppInfoVisible, setInnKppInfoData }) => {
	return {
		top: [
			{
				row_key: "cost_doc",
				width: "14.5%",
				label: "Цена с НДС в док-те",
				component: "TextField",
				textAlign: "right",
			},
			{
				row_key: "cost_doc_5",
				width: "14.5%",
				label: "Цена без НДС за ЕИ",
				component: "TextField",
				textAlign: "right",
			},
			{
				row_key: "cost_transportation",
				width: "14.5%",
				label: "Стоимость перевозки",
				component: "TextField",
				textAlign: "right",
			},
			{
				row_key: "perc_zsr",
				width: "7%",
				label: "ЗСР, %",
				component: "TextField",
				textAlign: "right",
			},
			{
				row_key: "cost_zsr",
				width: "7%",
				label: "ЗСР, руб.",
				component: "TextField",
				textAlign: "right",
			},
			{
				row_key: "price_estimate_without_vat",
				width: "14.5%",
				label: "Сметная цена без НДС",
				component: "TextField",
				textAlign: "right",
			},
			{
				row_key: "price_level",
				width: "14.5%",
				label: "Уровень цен",
				component: "TextField",
				textAlign: "left",
			},
			{
				row_key: "company_status",
				width: "10%",
				label: "",
				component: "BoldSimpleLabel",
				textAlign: "left",
			},
		],
		bottom: [
			{
				row_key: "company_inn",
				width: "10%",
				label: "ИНН",
				component: "TextField",
				textAlign: "left",
			},
			{
				row_key: "company_kpp",
				width: "10%",
				label: "КПП",
				component: "TextField",
				textAlign: "left",
			},
			{
				row_key: "company_info",
				width: "8.5%",
				label: "",
				component: "СustomJsx",
				jsx: (rowObj) => {
					return (
						<KsprButton
							size="small"
							title="Инф. по ИНН"
							variant="outlined"
							label={<span style={{ position: "relative", top: "1px" }}>Инф. по ИНН</span>}
							onClick={() => {
								setInnKppInfoVisible(true);
								setInnKppInfoData({ inn: rowObj.company_inn, kpp: rowObj.company_kpp });
							}}
							// startIcon={<InfoOutlinedIcon rotate="180" />}
						></KsprButton>
					);
				},
			},
			{
				row_key: "company_store_place",
				width: "29.5%",
				label: "Населенный пункт склада, регион (ценовая зона)",
				component: "TextField",
				textAlign: "left",
			},
			{
				row_key: "company_name",
				width: "29.5%",
				label: "Наименование производителя (1) / поставщика (2)",
				component: "TextField",
				textAlign: "left",
			},
			{
				row_key: "company_site",
				width: "7%",
				label: "",
				component: "IconLink",
				icon: "PublicIcon",
				callback: (rowObj) => {
					if (rowObj.company_site) {
						if (rowObj.company_site.startsWith("http://") || rowObj.company_site.startsWith("https://")) {
							window.open(rowObj.company_site);
						} else {
							window.open("http://" + rowObj.company_site);
						}
					}
				},
				isActive: (rowObj) => {
					return String(rowObj.company_site != null && rowObj.company_site !== "");
				},
			},
		],
		rightBox: [
			{
				type: "List",
				label: "ОБОСНОВЫВАЮЩИЕ ДОКУМЕНТЫ",
				row_key: "link_files",
			},
			{
				type: "Elem",
				label: "ИСТОЧНИК ДАННЫХ",
				row_key: "project_name",
				rule: {
					key: "has_source_info",
					value: true,
				},
			},
		],
	};
};

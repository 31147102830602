import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiKatsEstimate from "../api/kats";
import { searchDataRecourse, changeRowDataRecourse } from "./utils";
import { changeNestedArrKeyValueOnCondition } from "core/iterable_utils";
import { downloadFile } from "core/request_utils";
import { applyAdvSearchKats, clearAdvSearchKats } from "../pages/EstimateViewer/components/AdvSearchBox/apply_adv_search";

export const getKatsTree = createAsyncThunk("katsSlice/getKatsTree", async (id) => {
	const response = await apiKatsEstimate.getTree(id);
	return response.data;
});

export const getProjectKatsTree = createAsyncThunk("katsSlice/getProjectKatsTree", async (id) => {
	const response = await apiKatsEstimate.getProjectTree(id);
	return response.data;
});

export const deleteKats = createAsyncThunk("katsSlice/delete", async (id) => {
	const response = await apiKatsEstimate.delete(id);
	return response.data;
});

export const getOutputXml = createAsyncThunk("katsSlice/getOutputXml", async (id) => {
	const response = await apiKatsEstimate.getOutputXml(id);
	downloadFile(response);
});

export const getInputOriginXml = createAsyncThunk("katsSlice/getInputOriginXml", async (id) => {
	const response = await apiKatsEstimate.getInputOriginXml(id);
	downloadFile(response);
});

export const getOutputXmlWithJusts = createAsyncThunk("katsSlice/getOutputXmlWithJusts", async (id) => {
	const response = await apiKatsEstimate.getOutputXmlWithJusts(id);
	downloadFile(response);
});

export const getInputOriginXmlWithJusts = createAsyncThunk("katsSlice/getInputOriginXmlWithJusts", async (id) => {
	const response = await apiKatsEstimate.getInputOriginXmlWithJusts(id);
	downloadFile(response);
});

export const getPrintXlsx = createAsyncThunk("katsSlice/getPrintXlsx", async (id) => {
	const response = await apiKatsEstimate.getPrintXlsx(id);
	downloadFile(response);
});

export const getPrintXlsxPpk = createAsyncThunk("katsSlice/getPrintXlsxPpk", async (id) => {
	const response = await apiKatsEstimate.getPrintXlsxPpk(id);
	downloadFile(response);
});

export const getPrintXlsxWithErrors = createAsyncThunk("katsSlice/getPrintXlsxWithErrors", async (id) => {
	const response = await apiKatsEstimate.getPrintXlsxWithErrors(id);
	downloadFile(response);
});

export const downloadPriceOffersXlsx = createAsyncThunk("katsSlice/downloadPriceOffersXlsx", async ({ taskId }) => {
	const response = await apiKatsEstimate.downloadPriceOffersXlsx({ taskId });
	downloadFile(response);
});

export const getColumnsSettings = createAsyncThunk("katsSlice/getColumnsSettings", async (id) => {
	const response = await apiKatsEstimate.getColumnsSettings();
	return response.data;
});

export const calcKats = createAsyncThunk("katsSlice/calc", async (id, { rejectWithValue }) => {
	try {
		const response = await apiKatsEstimate.calculate(id);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const katsSlice = createSlice({
	name: "katsSlice",
	fetchStatus: null,
	initialState: {
		data: [],
		filtredData: [],
		headerData: {},
		detailsClms: null,
		advSearchResults: [],
		filterParams: {
			visible: false,
			posX: null,
			posY: null,
			_true: true,
			_false: true,
			_null: true,
		},
		columnsSettings: [],
	},
	reducers: {
		setKatsFetchStatus: (state, { payload }) => {
			state.fetchStatus = payload;
		},
		selectData: (state, { payload }) => {
			state.filtredData = state.filtredData.map((row) => {
				if (row.key === payload.key) {
					row.selected = true;
				} else {
					row.selected = false;
				}
				if (row._children) {
					row._children = row._children.map((ch) => {
						if (ch.key === payload.key) {
							ch.selected = true;
						} else {
							ch.selected = false;
						}
						return ch;
					});
				}
				return row;
			});
		},
		changeKatsVisible: (state, { payload: { row, val } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.filtredData = searchDataRecourse(state.filtredData, row["key"], val);
		},
		changeKatsDetailsVisible: (state, { payload: { row, val } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.filtredData = searchDataRecourse(state.filtredData, row["key"], val, "_details");
		},
		changeKatsErrorDetailsVisible: (state, { payload: { row, val } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.filtredData = changeNestedArrKeyValueOnCondition({
				arr: state.filtredData,
				keySearch: "key",
				valSearch: row.key,
				keyChange: "t",
				valChange: null,
				childrenKey: "_children",
				callback: (row) => {
					if (row.errors_visible === true) {
						row.paper_visible = false;
						row.errors_visible = false;
					} else {
						row.paper_visible = true;
						row.errors_visible = true;
					}
				},
			});
		},
		changeKatsPaperDetailsVisible: (state, { payload: { row, val } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.filtredData = searchDataRecourse(state.filtredData, row["key"], val, "_paper_details", "paper_visible");
		},
		setKatsData: (state, { payload }) => {
			state.data = payload;
		},
		changeRowData: (state, { payload: { rowKey, callback } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = changeRowDataRecourse(state.data, rowKey, callback);
		},
		changeFiltredRowData: (state, { payload: { rowKey, callback } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.filtredData = changeRowDataRecourse(state.filtredData, rowKey, callback);
		},
		setFilterParams: (state, { payload }) => {
			state.filterParams = { ...state.filterParams, ...payload };
		},
		setFiltredData: (state, { payload }) => {
			state.filtredData = payload;
		},
		setAdvSearchSelected: (state, { payload }) => {
			state.advSearchResults = payload;
		},
		clearAdvSearchSelected: (state, { payload }) => {
			state.data = clearAdvSearchKats(state.data);
			state.filtredData = clearAdvSearchKats(state.filtredData);
		},
		applyAdvSearchSelected: (state, { payload }) => {
			const thisLocEstmSeleted = state.advSearchResults.filter((row) => row.estm_id === state.headerData.id);
			state.filtredData = applyAdvSearchKats(state.filtredData, thisLocEstmSeleted, "add");
		},
		setColumnsSettings: (state, { payload }) => {
			state.columnsSettings = { ...state.columnsSettings, payload };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(calcKats.fulfilled, (state, { payload }) => {
			state.data = payload["data"];
			state.fetchStatus = "success";
		});

		builder.addCase(getColumnsSettings.fulfilled, (state, { payload }) => {
			state.columnsSettings = payload;
			state.fetchStatus = "success";
		});

		builder.addMatcher(isAnyOf(getKatsTree.fulfilled, getProjectKatsTree.fulfilled), (state, { payload }) => {
			state.data = payload["table_data"];
			state.headerData = payload["header_data"];
			state.columnsSettings = payload["columns_settings"];
			if (localStorage.getItem("scrollType") === "kats") {
				state.data = changeNestedArrKeyValueOnCondition({
					arr: payload["table_data"],
					keySearch: "key",
					valSearch: localStorage.getItem("scrollKey"),
					keyChange: "errors_visible",
					valChange: true,
					childrenKey: "_children",
					callback: (row) => {
						row.paper_visible = true;
					},
				});
				localStorage.removeItem("scrollType");
				localStorage.removeItem("scrollKey");
				// localStorage.removeItem("displayScrollTop");
			}

			if (localStorage.getItem("scrollKey") && localStorage.getItem("highlightRowClm")) {
				state.data = changeNestedArrKeyValueOnCondition({
					arr: payload["table_data"],
					keySearch: "key",
					valSearch: localStorage.getItem("scrollKey"),
					keyChange: "advSelected",
					valChange: localStorage.getItem("highlightRowClm"),
					childrenKey: "_children",
				});
				localStorage.removeItem("scrollKey");
				localStorage.removeItem("highlightRowClm");
			}

			state.filtredData = state.data;
			state.fetchStatus = "success";
		});

		builder.addMatcher(
			isAnyOf(
				deleteKats.fulfilled,
				getOutputXml.fulfilled,
				getInputOriginXml.fulfilled,
				getOutputXmlWithJusts.fulfilled,
				getInputOriginXmlWithJusts.fulfilled,
				getPrintXlsx.fulfilled,
				getPrintXlsxPpk.fulfilled,
				getPrintXlsxWithErrors.fulfilled,
				downloadPriceOffersXlsx.fulfilled
			),
			(state, action) => {
				state.fetchStatus = "success";
			}
		);

		builder.addMatcher(
			isAnyOf(
				// getKatsTree.pending,
				getProjectKatsTree.pending,
				calcKats.pending,
				deleteKats.pending,
				getOutputXml.pending,
				getInputOriginXml.pending,
				getOutputXmlWithJusts.pending,
				getInputOriginXmlWithJusts.pending,
				getPrintXlsx.pending,
				getPrintXlsxPpk.pending,
				getPrintXlsxWithErrors.pending,
				downloadPriceOffersXlsx.pending
			),
			(state, action) => {
				state.fetchStatus = "loading";
			}
		);

		builder.addMatcher(
			isAnyOf(
				getKatsTree.rejected,
				getProjectKatsTree.rejected,
				calcKats.rejected,
				deleteKats.rejected,
				getOutputXml.rejected,
				getInputOriginXml.rejected,
				getOutputXmlWithJusts.rejected,
				getInputOriginXmlWithJusts.rejected,
				getPrintXlsx.rejected,
				getPrintXlsxPpk.rejected,
				getPrintXlsxWithErrors.rejected,
				downloadPriceOffersXlsx.rejected
			),
			(state, action) => {
				state.fetchStatus = "failed";
			}
		);
	},
});

export const {
	setKatsFetchStatus,
	changeKatsVisible,
	changeKatsDetailsVisible,
	changeKatsPaperDetailsVisible,
	changeKatsErrorDetailsVisible,
	selectData,
	setKatsData,
	changeRowData,
	setExpertModalParams,
	setFilterParams,
	setFiltredData,
	setAdvSearchSelected,
	clearAdvSearchSelected,
	applyAdvSearchSelected,
	changeFiltredRowData,
	setColumnsSettings,
	setSelectedColumnsSettings,
} = katsSlice.actions;

export default katsSlice.reducer;

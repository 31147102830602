import React from 'react';

import { TextField, Autocomplete } from '@mui/material';
import "./MonitorDetailsAutocomplete.css"

export const MonitorDetailsAutocomplete = (props) => {

    return (
        <Autocomplete
            id="combo-box-demo"
            noOptionsText="нет данных"
            isOptionEqualToValue={(option, value) => option.label === value}
            renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true, }} label={props.label}
            />}
            width={300}
            {...props}
        />
    )
}


import { lazy, Suspense } from "react";

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import ReportOffOutlinedIcon from "@mui/icons-material/ReportOffOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";
import ViewDayOutlinedIcon from "@mui/icons-material/ViewDayOutlined";
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined";
import FillColorSeparateIcon from "components/UI/icons/FillColorSeparateIcon";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import LowPriorityOutlinedIcon from "@mui/icons-material/LowPriorityOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";

import {
	protectLocEstmFull,
	changeSectionChildsVisible,
	changeItemChildsVisible,
	showHideItemsNameVisible,
	changeEndingChildsVisible,
	showCostChildren,
	hideCostChildren,
	colorFilter,
} from "slices/local_estimate";
import { setClickedTableRow, setEditorIsVisible } from "slices/ribbon";
import { setUserSettings } from "slices/user_adv";
import { getProjectLsrOsrReestr, dedublicateProjectLsr, undoDedublicateProjectLsr } from "slices/project_work";
import { setReGetProtocol } from "slices/protocol";
import { ProtocolRibbonFilter } from "pages/EstimateViewer/ProtocolViewer/components";
import ColumnsSettings from "pages/EstimateViewer/components/ColumnsSettings/ColumnsSettings";

const ColorPicker = lazy(() => import("components/UI/ColorPicker"));

const EstimateRibbonSettings = ({
	selectTypeData,
	gprVisible,
	saveFiltredDataGrp,
	proj_main,
	selectedErrorStatus,
	setSelectedErrorStatus,
	onClickProtocolBtn,
	onClickMonitorBtn,
	onClickImportBtn,
	onClickExportSmetBtn,
	onClickExportCurrentSmetBtn,
	onClickPrintCurrentSmetBtn,
	onClickPrintFullSmetBtn,
	onClickCurrentXmlExportBtn,
	onClickInputXmlFullSmetBtn,
	onClickSearchBtn,
	onClickSortBtn,
	showDeleteDialog,
	windowWidth,
	currentPage,
	clickedTableRow,
	onClickChangeRowValueBtn,
	dispatch,
	currentEstmDocId,
	userPermissions,
	lowerCallbacks,
	advSearchBoxMode,
	advSearchBoxVisible,
	allowErPercVal,
	setAllowErPercVal,
	navigate,
	localEstmData,
	// onClickGetLogJustification,
	setKatsJustUploadVisible,
	setKatsPriceOfferSelectorVisible,
	fillColorTableRow,
	projectTreeSelected,
	getInputOriginXml,
	allColumnsSettings,
	columnsSettings,
	setColumnsSettings,
	userSettings,
	ksrVisible,
	onClickShowKsr,
	onClickPrintXlsxPpk,
	fsnbVisible,
	showClearProjectDialog,
	currentEstmDocUrlPart,
	makeLsrDoubleWinner,
}) => {
	const getAdaptiveName = () => {
		return windowWidth < 1380 ? "Поиск" : "Поиск и сортировка";
	};

	const getJustCalcDisabled = () => {
		if (currentPage !== "LocalEstimateViewer") return true;
		if (
			[
				"LocalEstimatesItemsOverheads",
				"LocalEstimatesItemsProfits",
				"LocalEstimateRimSecSumDirect",
				"LocalEstimateRimSum",
				"LocalEstimatePnrGroup",
			].includes(clickedTableRow?.model_name)
		) {
			return false;
		}
		if (clickedTableRow?.model_name?.includes("LocalEstimatesRes")) {
			return false;
		}
		if (clickedTableRow?.is_item === "1") {
			return false;
		}
		return true;
	};

	const getFillColorStatus = () => {
		if (currentPage === "KatsProjectEstimateViewer") {
			return true;
		}
		if ((clickedTableRow !== null) & ["KatsEstimateViewer", "LocalEstimateViewer", "SummaryEstimateViewer"].includes(currentPage)) {
			return false;
		}
		if (projectTreeSelected && projectTreeSelected.length > 0) {
			return false;
		}
		return true;
	};

	const getCommentStatus = () => {
		if (userPermissions.is_guest === true) {
			return true;
		}
		if (currentPage === "KatsProjectEstimateViewer") {
			return true;
		}
		if ((clickedTableRow !== null) & ["LocalEstimateViewer"].includes(currentPage)) {
			return false;
		}
		if (projectTreeSelected && projectTreeSelected.length > 0) {
			return false;
		}
		return true;
	};

	const getCommentCallback = () => {
		if ((clickedTableRow !== null) & ["LocalEstimateViewer"].includes(currentPage)) {
			return lowerCallbacks.current.local["showComments"];
		}
		if ((clickedTableRow !== null) & ["KatsEstimateViewer"].includes(currentPage)) {
			return lowerCallbacks.current.kats["showComments"];
		}
		if (projectTreeSelected && projectTreeSelected.length > 0) {
			return lowerCallbacks.current.project["showProjectTreeComment"];
		}
		return () => {};
	};

	const getFsnbCatalogDisabled = () => {
		if (currentPage !== "LocalEstimateViewer") return true;
		if (clickedTableRow == null) return true;
		if (
			["LocalEstimatesItems", "LocalEstimatesResMachine", "LocalEstimatesResMaterial", "LocalEstimatesResEquipment"].includes(
				clickedTableRow.model_name
			)
		)
			return false;
		if (clickedTableRow.model_name === "LocalEstimatesResTransport") {
			if (localEstmData.methodic === "БИМ") {
				return false;
			} else {
				return true;
			}
		}
		return false;
	};

	const getEditorButtons = () => {
		if (["KatsProjectEstimateViewer", "KatsEstimateViewer"].includes(currentPage) || currentEstmDocUrlPart === "kats") {
			return [
				{
					name: "Создать КАЦ",
					icon: <NoteAddOutlinedIcon />,
					size: "large",
					type: "common",
					isDisabled: userPermissions.is_kats_admin !== true,
					callback: () => {
						navigate(`/project/${proj_main.id}/editor/kats/new/`);
					},
					styles: {},
				},
				{
					name: "Редактор КАЦ",
					icon: <ModeEditOutlinedIcon />,
					size: "large",
					type: "common",
					isDisabled: currentEstmDocId == null || userPermissions.is_kats_admin !== true || currentPage !== "KatsEstimateViewer",
					callback: () => {
						dispatch(setEditorIsVisible(true));
						navigate(`/project/${proj_main.id}/editor/kats/${currentEstmDocId}/`);
					},
					styles: {},
				},
			];
		} else {
			return [
				{
					name: "Создать смету",
					icon: <NoteAddOutlinedIcon />,
					size: "large",
					type: "common",
					isDisabled: userPermissions.is_lsr_editors !== true,
					callback: () => {
						navigate(`/project/${proj_main.id}/editor/lsr/new/`);
					},
					styles: {},
				},
				{
					name: "Редактор сметы",
					icon: <ModeEditOutlinedIcon />,
					size: "large",
					type: "common",
					isDisabled:
						currentEstmDocId == null ||
						localEstmData?.methodic !== "РИМ" ||
						userPermissions.is_lsr_editors !== true ||
						currentPage !== "LocalEstimateViewer",
					callback: () => {
						dispatch(setEditorIsVisible(true));
						navigate(`/project/${proj_main.id}/editor/lsr/${currentEstmDocId}/`);
					},
					styles: {},
				},
			];
		}
	};

	return [
		{
			name: "Импорт",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Импорт",
							icon: <FileUploadOutlinedIcon />,
							size: "large",
							type: "common",
							callback: onClickImportBtn,
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Режимы работы",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					_children: [
						{
							name: "вид",
							icon: <AddBoxOutlinedIcon />,
							size: "medium",
							type: "radio",
							radioArray: [
								{
									name: "Сметная документация",
									currentPage: [
										"EstimateDocViewer",
										"LocalEstimateViewer",
										"SummaryEstimateViewer",
										"ObjectEstimateViewer",
										"KatsEstimateViewer",
										"KatsProjectEstimateViewer",
										"StartEstimateViewer",
									],
								},
								{
									name: "Протокол проверки",
									callback: onClickProtocolBtn,
									currentPage: ["ProtocolViewer"],
								},
								{
									name: "Монитор проверки",
									callback: onClickMonitorBtn,
									currentPage: ["MonitorViewer"],
								},
							],
						},
					],
				},
			],
		},
		{
			name: "Редактор",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: { flexDirection: "row" },
					_children: getEditorButtons(),
				},
			],
		},
		{
			name: "Экспорт",
			styles: {},
			_children: [
				{
					name: "Subgroup 1.1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Экспорт протокола",
							nameVisible: windowWidth < 1480,
							icon: <FileOpenOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "По документу",
									value: "value",
									callback: onClickExportCurrentSmetBtn,
									isDisabled: currentPage === "KatsProjectEstimateViewer" || currentPage === "StartEstimateViewer",
								},
								{
									name: "Печать документа с ошибками",
									value: "value",
									callback: () => onClickPrintCurrentSmetBtn(true),
									isDisabled: ![
										"LocalEstimateViewer",
										"KatsEstimateViewer",
										"ObjectEstimateViewer",
										"SummaryEstimateViewer",
									].includes(currentPage),
								},
								{
									name: "По всему проекту",
									value: "value",
									callback: onClickExportSmetBtn,
								},
								{
									name: "Печать всего проекта с ошибками",
									value: "value",
									isDisabled: userPermissions.is_guest === true,
									callback: () => onClickPrintFullSmetBtn(true),
								},
							],
						},
						{
							name: "Печатная форма",
							nameVisible: windowWidth < 1480,
							icon: <PrintOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "Печать документа",
									value: "print1",
									callback: () => onClickPrintCurrentSmetBtn(false),
									isDisabled: ![
										"LocalEstimateViewer",
										"ObjectEstimateViewer",
										"SummaryEstimateViewer",
										"KatsEstimateViewer",
									].includes(currentPage),
								},
								{
									name: "Печать документа с ошибками",
									value: "print1",
									callback: () => onClickPrintCurrentSmetBtn(true),
									isDisabled: ![
										"LocalEstimateViewer",
										"KatsEstimateViewer",
										"ObjectEstimateViewer",
										"SummaryEstimateViewer",
									].includes(currentPage),
								},
								{
									name: "Печать документа ППК",
									isVisible: currentPage === "KatsEstimateViewer" && userPermissions.is_ppk_user,
									value: "print",
									callback: () => onClickPrintXlsxPpk(),
								},
								{
									name: "Печать всего проекта",
									isDisabled: userPermissions.is_guest === true,
									value: "print2",
									callback: () => onClickPrintFullSmetBtn(false),
								},
								{
									name: "Печать всего проекта с ошибками",
									isDisabled: userPermissions.is_guest === true,
									value: "print2",
									callback: () => onClickPrintFullSmetBtn(true),
								},
								{
									name: "Печать реестра ЛСР / ОСР",
									isDisabled: userPermissions.is_guest === true,
									value: "print2",
									callback: () => dispatch(getProjectLsrOsrReestr(proj_main.id)),
								},
							],
						},
						{
							name: "Экспорт в GGE",
							nameVisible: windowWidth < 1480,
							icon: <FileDownloadOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "Экспорт документа",
									callback: onClickCurrentXmlExportBtn,
									isDisabled: currentEstmDocId == null || currentPage === "KatsProjectEstimateViewer",
								},
								{
									name: "Экспорт документа с обоснованиями",
									callback: () => onClickCurrentXmlExportBtn("WITH_JUSTS"),
									isDisabled: currentEstmDocId == null || currentPage === "KatsProjectEstimateViewer",
									isVisible: currentPage === "KatsEstimateViewer",
								},
								{
									name: "Экспорт исходного документа",
									callback: getInputOriginXml,
									isDisabled: currentEstmDocId == null || currentPage === "KatsProjectEstimateViewer",
									isVisible: ["LocalEstimateViewer", "KatsEstimateViewer"].includes(currentPage),
								},
								{
									name: "Экспорт исходного документа с обоснованиями",
									callback: () => getInputOriginXml("WITH_JUSTS"),
									isDisabled: currentEstmDocId == null || currentPage === "KatsProjectEstimateViewer",
									isVisible: currentPage === "KatsEstimateViewer",
								},
								{
									name: "Экспорт всего проекта",
									value: "print2",
									callback: onClickInputXmlFullSmetBtn,
									isDisabled: false,
								},
							],
						},
					],
				},
			],
		},
		{
			name: getAdaptiveName(),
			styles: {},
			_children: [
				{
					name: "Subgroup 1.1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Расширенный поиск",
							nameVisible: windowWidth < 1380,
							icon: <SearchOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							active: advSearchBoxVisible && advSearchBoxMode === "search",
							callback: onClickSearchBtn,
							styles: {},
						},
						{
							name: "Сортировка по весу",
							nameVisible: windowWidth < 1380,
							icon: <AssessmentOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							active: advSearchBoxVisible && advSearchBoxMode === "rating",
							callback: onClickSortBtn,
							styles: {},
						},
						{
							name: "Позиция",
							nameVisible: windowWidth < 1380,
							icon: <CodeOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							isDisabled: getJustCalcDisabled(),
							styles: {},
							_children: [
								{
									name: "В исходном XML",
									nameVisible: windowWidth < 1280,
									icon: <CodeOutlinedIcon />,
									isDisabled: clickedTableRow?.model_name === "LocalEstimatesResWorker",
									callback: () => {
										if (clickedTableRow?.model_name === "LocalEstimateRimSecSumDirect") {
											lowerCallbacks.current.local["showErrorJustificationShort"]({
												obj: "section",
											});
										} else if (clickedTableRow?.model_name === "LocalEstimateRimSum") {
											lowerCallbacks.current.local["showErrorJustificationShort"]({
												obj: "estimate",
											});
										} else if (clickedTableRow?.model_name === "LocalEstimatePnrGroup") {
											lowerCallbacks.current.local["showErrorJustificationShort"]({
												obj: "estimate",
											});
										} else {
											lowerCallbacks.current.local["showErrorJustificationShort"]({
												obj: "item",
											});
										}
									},
								},
								{
									name: "Обоснование расчета",
									nameVisible: windowWidth < 1280,
									icon: <CalculateOutlinedIcon />,
									isDisabled: userPermissions.is_guest === true,
									callback: () => {
										if (clickedTableRow?.model_name === "LocalEstimateRimSecSumDirect") {
											lowerCallbacks.current.local["showLogJustification"]({ obj: "section" });
										} else if (clickedTableRow?.model_name === "LocalEstimateRimSum") {
											lowerCallbacks.current.local["showLogJustification"]({ obj: "estimate" });
										} else if (clickedTableRow?.model_name === "LocalEstimatePnrGroup") {
											lowerCallbacks.current.local["showLogJustification"]({ obj: "pnr" });
										} else {
											lowerCallbacks.current.local["showLogJustification"]({ obj: "item" });
										}
									},
								},
							],
						},
					],
				},
			],
		},
		{
			name: "Действия",
			isVisible: currentPage !== "KatsEstimateViewer",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Защитить строку",
							nameVisible: windowWidth < 1440,
							icon: <FileOpenOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							isDisabled:
								clickedTableRow == null ||
								currentPage !== "LocalEstimateViewer" ||
								clickedTableRow.protectable !== true ||
								clickedTableRow.protected_id != null ||
								userPermissions?.is_expert !== true,
							styles: {},
							_children: [
								{
									name: "Защитить строку",
									value: "print1",
									callback: () => {
										lowerCallbacks.current.local["lsrProtection"](clickedTableRow);
									},
								},
								{
									name: "Все строки сметы",
									value: "print2",
									callback: () => {
										dispatch(protectLocEstmFull({ id: currentEstmDocId, val: true }));
										dispatch(setClickedTableRow(null));
									},
								},
							],
						},
						{
							name: "Снять защиту",
							nameVisible: windowWidth < 1440,
							icon: <FileOpenOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							isDisabled:
								clickedTableRow == null ||
								currentPage !== "LocalEstimateViewer" ||
								clickedTableRow.protectable !== true ||
								clickedTableRow.protected_id == null ||
								userPermissions?.is_expert !== true,
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "Снять защиту со строки",
									value: "print1",
									callback: () => {
										lowerCallbacks.current.local["lsrProtection"](clickedTableRow);
									},
								},
								{
									name: "Снять защиту со всех строк",
									value: "print2",
									callback: () => {
										dispatch(protectLocEstmFull({ id: currentEstmDocId, val: false }));
										dispatch(setClickedTableRow(null));
									},
								},
							],
						},
						{
							name: "Удалить смету",
							nameVisible: windowWidth < 1440,
							icon: <DeleteOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: ["SummaryEstimateViewer", "StartEstimateViewer", "KatsProjectEstimateViewer", false].includes(currentPage),
							callback: () => showDeleteDialog({ cascade: true }),
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Ошибки и комментарии",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Ошибки",
							nameVisible: windowWidth < 1540,
							icon: <ReportGmailerrorredOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							isDisabled: clickedTableRow == null || clickedTableRow?._details == null,
							styles: {},
							_children: [
								{
									name: "Показать ошибки",
									icon: <ReportGmailerrorredOutlinedIcon />,
									isDisabled: clickedTableRow?.errors_visible === true || clickedTableRow?.protected_id != null,
									callback: () => onClickChangeRowValueBtn({ key: "errors_visible", value: true }),
								},
								{
									name: "Свернуть ошибки",
									icon: <ReportOffOutlinedIcon />,
									isDisabled: clickedTableRow?._details == null || clickedTableRow?.errors_visible === false,
									callback: () => onClickChangeRowValueBtn({ key: "errors_visible", value: false }),
								},
							],
						},
						{
							name: "Комментарии",
							nameVisible: windowWidth < 1540,
							icon: <FileOpenOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: getCommentStatus(),
							callback: () => getCommentCallback()({ val: true }),
							styles: {},
						},
						{
							name: "Заливка",
							nameVisible: windowWidth < 1540,
							icon: <FillColorSeparateIcon secondaryColor={userSettings?.USER_COLORS?.last_color} />,
							size: "MEDIUM",
							type: "select-custom-options",
							isDisabled: getFillColorStatus(),
							callback: () => fillColorTableRow({ hex: userSettings?.USER_COLORS?.last_color }),
							getJsxOptions: () => {
								return (
									<Suspense>
										<ColorPicker
											type="GithubPicker"
											onChange={(color) => {
												fillColorTableRow(color);
												dispatch(setUserSettings({ USER_COLORS: { last_color: color?.hex } }));
											}}
										/>
									</Suspense>
								);
							},
						},
					],
				},
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Проверить смету",
							icon: <CalculateOutlinedIcon />,
							size: "large",
							type: "custom",
							customJsx: ProtocolRibbonFilter({
								selectTypeData: selectTypeData,
								gprVisible: gprVisible,
								saveFiltredDataGrp: saveFiltredDataGrp,
								proj_main: proj_main,
								selectedErrorStatus: selectedErrorStatus,
								setSelectedErrorStatus: setSelectedErrorStatus,
								allowErPercVal: allowErPercVal,
								setAllowErPercVal: setAllowErPercVal,
								dispatch: dispatch,
								userPermissions: userPermissions,
							}),
							callback: () => {},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Справка",
			styles: {},
			isVisible: !["KatsProjectEstimateViewer", "KatsEstimateViewer"].includes(currentPage),
			_children: [
				{
					name: "Subgroup",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Открыть ФСНБ",
							nameVisible: windowWidth < 1340,
							icon: <AccountTreeOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: getFsnbCatalogDisabled(),
							active: fsnbVisible,
							callback: () => {
								lowerCallbacks.current.local["openFsnbCatalog"](clickedTableRow);
							},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Действия",
			isVisible: currentPage === "KatsEstimateViewer" && currentEstmDocId != null && currentEstmDocId !== false,
			styles: {},
			_children: [
				{
					name: "Subgroup",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Загрузить обоснования",
							icon: <AttachFileOutlinedIcon />,
							size: "large",
							type: "common",
							callback: () => setKatsJustUploadVisible(true),
							styles: {},
						},
					],
				},
				{
					name: "Subgroup",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Удалить КА",
							nameVisible: windowWidth < 1602,
							icon: <DeleteOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: ["SummaryEstimateViewer", "StartEstimateViewer", false].includes(currentPage),
							callback: () => showDeleteDialog({ cascade: true }),
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Экспертиза",
			isVisible:
				((currentPage === "KatsEstimateViewer" && currentEstmDocId != null && currentEstmDocId !== false) ||
					currentPage === "KatsProjectEstimateViewer") &&
				userPermissions?.is_kats_expert === true,
			styles: {},
			_children: [
				{
					name: "Subgroup",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Аналог ЦП из базы",
							icon: <AddToPhotosOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: clickedTableRow?.model_name !== "KatsResources" && clickedTableRow?.model_name !== "KatsOffers",
							callback: () => setKatsPriceOfferSelectorVisible(true),
							styles: {},
						},
					],
				},
				{
					name: "Subgroup",
					orientation: "vertical",
					styles: {},
					isVisible: userPermissions?.is_kats_expert === true && currentPage === "KatsEstimateViewer",
					_children: [
						{
							name: "Сравнить КА",
							nameVisible: windowWidth < 1678,
							icon: <CompareArrowsOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: !userPermissions?.is_kats_expert === true,
							callback: () => lowerCallbacks.current.kats["openKaDiffModal"](),
							styles: {},
						},
						{
							name: "Удалить ЦП",
							nameVisible: windowWidth < 1678,
							icon: <DeleteOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: clickedTableRow?.is_expert_offer !== true,
							callback: () => lowerCallbacks.current.kats["deleteExpertOffer"](),
							styles: {},
						},
						{
							name: "Ресурс в ЛСР",
							nameVisible: windowWidth < 1678,
							icon: <ArticleOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: clickedTableRow?.model_name !== "KatsResources",
							callback: () => lowerCallbacks.current.kats["gerResInLsrs"](),
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Настройки",
			isVisible:
				(currentPage === "KatsEstimateViewer" && currentEstmDocId != null && currentEstmDocId !== false) ||
				currentPage === "KatsProjectEstimateViewer",
			styles: {},
			_children: [
				{
					name: "Subgroup",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Столбцы",
							nameVisible: windowWidth < 1710,
							icon: <SettingsOutlinedIcon />,
							size: "MEDIUM",
							type: "select-custom-options",
							isDisabled: false,
							handleCloseCallback: () => {},
							getJsxOptions: () => {
								return ColumnsSettings({
									clmArr: allColumnsSettings.kats,
									selectedClmArr: columnsSettings.kats,
									setSelectedOpts: setColumnsSettings.kats,
									handleSelectClick: () => lowerCallbacks.current.kats["saveTableColumnsSettings"](),
								});
							},
						},
					],
				},
			],
		},
		{
			name: "Справка",
			isVisible:
				(currentPage === "KatsEstimateViewer" && currentEstmDocId != null && currentEstmDocId !== false) ||
				currentPage === "KatsProjectEstimateViewer",
			styles: {},
			_children: [
				{
					name: "Subgroup",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Открыть КСР",
							nameVisible: windowWidth < 1340,
							icon: <AccountTreeOutlinedIcon />,
							size: "large",
							type: "common",
							active: ksrVisible,
							callback: () => onClickShowKsr(),
						},
					],
				},
			],
		},
		{
			name: "Вид",
			isVisible: currentPage === "LocalEstimateViewer" && currentEstmDocId != null && currentEstmDocId !== false,
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Разделы",
							nameVisible: windowWidth < 1440,
							icon: <SegmentOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							isDisabled: false,
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "Свернуть все",
									value: "none",
									callback: () => dispatch(changeSectionChildsVisible(false)),
									isDisabled: false,
								},
								{
									name: "Развернуть все",
									value: "none",
									callback: () => dispatch(changeSectionChildsVisible(true)),
									isDisabled: false,
								},
							],
						},
						{
							name: "Позиции",
							nameVisible: windowWidth < 1440,
							icon: <ViewDayOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							isDisabled: false,
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "Свернуть все",
									callback: () => dispatch(changeItemChildsVisible(false)),
									isDisabled: false,
								},
								{
									name: "Развернуть все",
									callback: () => dispatch(changeItemChildsVisible(true)),
									isDisabled: false,
								},
								{
									name: "Показать наименования",
									callback: () => dispatch(showHideItemsNameVisible()),
									isDisabled: false,
								},
								{
									name: "Показать состав нормы",
									callback: () => dispatch(showCostChildren()),
									isDisabled: false,
								},
								{
									name: "Скрыть состав нормы",
									callback: () => dispatch(hideCostChildren()),
									isDisabled: false,
								},
							],
						},
						{
							name: "Итоги",
							nameVisible: windowWidth < 1440,
							icon: <TableViewOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							isDisabled: false,
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "Свернуть все",
									value: "none",
									callback: () => dispatch(changeEndingChildsVisible(false)),
									isDisabled: false,
								},
								{
									name: "Развернуть все",
									value: "none",
									callback: () => dispatch(changeEndingChildsVisible(true)),
									isDisabled: false,
								},
							],
						},
					],
				},
				{
					name: "Subgroup 2",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Фильтр",
							nameVisible: windowWidth < 1440,
							icon: <FillColorSeparateIcon />,
							size: "MEDIUM",
							type: "select-custom-options",
							isDisabled: false,
							callback: () => {},
							getJsxOptions: () => {
								return (
									<Suspense>
										<ColorPicker
											type="GithubPicker"
											onChange={(color) => {
												const hexColor = color.hex;
												dispatch(colorFilter({ color: hexColor }));
											}}
										/>
									</Suspense>
								);
							},
						},
					],
				},
			],
		},
		{
			name: "Действия",
			isVisible: !["KatsProjectEstimateViewer", "KatsEstimateViewer"].includes(currentPage) && userPermissions.is_expert === true,
			styles: {},
			_children: [
				{
					name: "Subgroup",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Исключить дубликаты ЛСР",
							nameVisible: windowWidth < 1480,
							icon: <LanOutlinedIcon />,
							type: "common",
							callback: () => {
								const prom = dispatch(dedublicateProjectLsr({ projectId: proj_main.id }));
								prom.then(() => dispatch(setReGetProtocol(true)));
							},
						},
						{
							name: "Сделать актуальным",
							icon: <LowPriorityOutlinedIcon />,
							nameVisible: windowWidth < 1480,
							type: "common",
							isDisabled: projectTreeSelected?.[0]?.type !== "local" || projectTreeSelected?.[0]?.double_is_winner !== false,
							callback: () => makeLsrDoubleWinner(),
						},
						{
							name: "Отменить исключание",
							icon: <UndoOutlinedIcon />,
							nameVisible: windowWidth < 1480,
							type: "common",
							callback: () => {
								const prom = dispatch(undoDedublicateProjectLsr({ projectId: proj_main.id }));
								prom.then(() => dispatch(setReGetProtocol(true)));
							},
						},
					],
				},
			],
		},
		{
			name: "Проект",
			styles: {},
			isVisible: userPermissions.is_guest === true,
			_children: [
				{
					name: "Subgroup 1",
					orientation: "horizontal",
					isVisible: userPermissions.is_guest === true,
					styles: {},
					_children: [
						{
							name: "Очистить проект",
							icon: <DeleteOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: userPermissions.is_guest === false,
							callback: () => showClearProjectDialog(),
							styles: {},
						},
					],
				},
			],
		},
	];
};

export default EstimateRibbonSettings;

import { useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import KpsrBreadcrumbs from "components/UI/breadcrumbs/KpsrBreadcrumbs";
import LabelVerticalHierarchy from "components/UI/labels/LabelVerticalHierarchy";

import { getCustomCellHeight, getCustomCellColSpan, getCustomCellContent } from "components/Table/utils/custom_cell_utils";

import "./CustomCodeCell.css";

const CustomCodeCell = ({ rowObj, funcs, inputSearchValue }) => {
	const [diplayBreadCount, setDiplayBreadCount] = useState({ left: 2, right: 1 });

	let { cellStrContent, cellJsxContent } = getCustomCellContent({
		rowObj,
		clmName: "code",
		applyHighlight: true,
		subStrHighlight: inputSearchValue,
	});

	let fontWeight = 400;
	let icon;
	let treecell = "false";
	let cellJsx;
	let backGroundColor;
	let borderLeft;

	cellJsx = (
		<span className="TreeFlexCellSpan" clm="code" lang="ru-RU" title={cellStrContent}>
			{cellJsxContent}
		</span>
	);

	const showHideBreadCallback = () => {
		if (!diplayBreadCount) {
			setDiplayBreadCount({ left: 2, right: 1 });
		} else {
			setDiplayBreadCount(null);
		}
	};

	if (Array.isArray(rowObj.code)) {
		if (diplayBreadCount) {
			cellJsxContent = (
				<KpsrBreadcrumbs objArr={rowObj.code} diplayCount={diplayBreadCount} sep="/" showHiddenCallback={showHideBreadCallback} />
			);
		} else {
			cellJsxContent = <LabelVerticalHierarchy objArr={rowObj.code} rowHeight={21} leftPadding={20} callback={showHideBreadCallback} />;
		}

		cellStrContent = rowObj.code.map((x) => `${x.name}`).join(" / ");
		fontWeight = 600;
		treecell = "true";
		backGroundColor = "#f5f5f57a";
		borderLeft = "1px solid #dddddd";
		if (rowObj.childs_visible === false) {
			icon = <ArrowRightIcon onClick={() => funcs.changeVisible({ clickedRow: rowObj })} />;
		} else {
			icon = <ArrowDropDownIcon onClick={() => funcs.changeVisible({ clickedRow: rowObj })} />;
		}

		cellJsx = (
			<div className="TreeFlexCell" clm="code" title={cellStrContent}>
				{icon}
				<span className="TreeFlexCellSpan" clm="code" lang="ru-RU" title={cellStrContent}>
					{cellJsxContent}
				</span>
			</div>
		);
	}

	return (
		<td
			key={`cell-code-${rowObj.key}`}
			className="TableCell"
			clm="code"
			treecell={treecell}
			colSpan={getCustomCellColSpan({ colSpanTarget: "code", rowObj })}
			style={{
				height: `${getCustomCellHeight({ initHeight: 17, rowMultiplyHeight: 22, rowObj })}px`,
				fontWeight: fontWeight,
				backgroundColor: backGroundColor,
				borderLeft: borderLeft,
			}}
			title={cellStrContent}
			onClick={(e) => {
				if (e.target.tagName !== "svg") {
					showHideBreadCallback();
				}
			}}
		>
			{cellJsx}
		</td>
	);
};

export default CustomCodeCell;

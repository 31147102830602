import React from "react";
import ContextMenuSubgroup from "./ContextMenuSubgroup.jsx";
import { Divider } from "@mui/material";

const ContextMenuGroup = ({ name, subgroups }) => {
    return (
        <div>
            {subgroups.map((subgroup, index) => (
                <ContextMenuSubgroup key={index} {...subgroup} />
            ))}
            <Divider />
        </div>
    );
};

export default ContextMenuGroup;

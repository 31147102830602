import React from "react";
import { GetRibbonTabsSettings } from "./RibbonTabsSettings.jsx";

import RibbonTab from "./RibbonTab.jsx";

import "./RibbonHeaderTabs.css";

const RibbonHeaderTabs = () => {
    const ribbonTabsSettings = GetRibbonTabsSettings();

    return (
        <div className="ribbon-header-tabs">
            {ribbonTabsSettings.map((tab, index) => (
                <RibbonTab key={index} {...tab} />
            ))}
        </div>
    );
};

export default RibbonHeaderTabs;

import "./CustomDetailsRowComment.css";

const CustomDetailsRowComment = ({ rowObj, colSpan }) => {
	return (
		<tr className="CustomDetailsRowComment" key={`com-${rowObj?.id}`} id={`com-${rowObj?.id}`}>
			<td colSpan={colSpan}>
				<div className="CustomDetailsRowCommentContent" title={rowObj?.text}>
					<span>{rowObj?.username}</span>
					<span>{rowObj?.date_update}</span>
					<span>{rowObj?.text}</span>
				</div>
			</td>
		</tr>
	);
};

export default CustomDetailsRowComment;

import React from 'react';

import "./LabelNormWithUnderTwoSides.css";

export default function LabelNormWithUnderTwoSides(props) {
    const label_norm_start = props.norm_start;
    const label_norm_end = props.norm_end;
    const label_under = props.under;
    const label_type = props.type;
    const under_width = props.under_width;

    let normStartFontWeight;
    if (props.norm_start_bold === true) {
        normStartFontWeight = 600;
    } else {
        normStartFontWeight = 400;
    }

    let normStartFontWidth;
    if (props.norm_start_width !== undefined) {
        normStartFontWidth = props.norm_start_width;
    } else {
        normStartFontWidth = "auto";
    }


    let normStartMarginLeft;
    if (props.norm_margin_left !== undefined) {
        normStartMarginLeft = props.norm_margin_left;
    } else {
        normStartMarginLeft = "0px";
    }

    return (
        <div
            className="LabelNormWithUnderTwoSides"
            type={label_type}
            style={{ marginTop: props.norm_margin_top }}
        >
            <div className="LabelNorm"
                style={
                    {
                        width: normStartFontWidth,
                        fontWeight: normStartFontWeight,
                        marginLeft: normStartMarginLeft,
                    }
                }>{label_norm_start}</div>
            <div className="LabelBorderBottom"
                style={{ width: under_width }}>{label_under}</div>
            <div className="LabelNorm">{label_norm_end}</div>
        </div>
    );
}
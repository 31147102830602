import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function CheckboxControl(props) {
    const { label, name, checked, onChange, disabled } = props;

    return (
        <FormControlLabel
            control={
                <Checkbox
                    size="small"
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    disabled={disabled}
                    sx={{
                        padding: "12px",
                        width: "16px",
                        height: "16px",
                        "& .MuiSvgIcon-root": {
                            fontSize: "15px",
                        },
                    }}
                />
            }
            label={label}
            sx={{
                "& .MuiTypography-body1": {
                    color: "#000000DE",
                    fontSize: "14px",
                },
            }}
        />
    );
}

export default CheckboxControl;

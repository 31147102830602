import { useState } from "react";

import CommentsBox from "pages/EstimateViewer/components/CommentsBox/CommentsBox";

// import { useLocalStorage } from "hooks/useLocalStorage";

import "./CustomDetailsComments.css";

const CustomDetailsComments = ({ rowObj, colSpan, setVisible, api, postInitBody, setFetchStatus, setFetchStatusText, successPostAct }) => {
	const [inputComment, setInputComment] = useState();

	// const { getLsItem, setLsItem, removeLsItem } = useLocalStorage();

	// useLayoutEffect(() => {
	// 	if (!rowObj?.key) return;
	// 	const commentQ = getLsItem({ key: `___com-${rowObj?.key}` });
	// 	if (commentQ != null && commentQ !== "undefined") setInputComment(commentQ);
	// }, []); // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 	if (inputComment) {
	// 		setLsItem({ key: `___com-${rowObj?.key}`, value: inputComment });
	// 	}
	// }, [inputComment]); // eslint-disable-line react-hooks/exhaustive-deps

	const postComment = (text) => {
		setFetchStatus("loading");
		const prom = api.postRowComment({ ...postInitBody, text: text });
		prom.then((response) => {
			setFetchStatus("success");
			setInputComment("");
			successPostAct();
		}).catch((error) => {
			console.error(error);
			setFetchStatus("failed");
		});
		// .finally(() => {
		// 	removeLsItem({ key: `___com-${rowObj?.key}` });
		// });
	};

	const deleteComment = (commentObj) => {
		setFetchStatus("loading");
		const prom = api.deleteRowComment(commentObj.id);
		prom.then((response) => {
			setFetchStatus("success");
			successPostAct();
		}).catch((error) => {
			if (error?.response?.data?.detail != null) setFetchStatusText(error.response.data.detail);
			setFetchStatus("failed");
		});
	};

	const editComment = ({ commentObj, text }) => {
		setFetchStatus("loading");
		const prom = api.editRowComment({ commentId: commentObj.id, text });
		prom.then((response) => {
			setFetchStatus("success");
			successPostAct();
		}).catch((error) => {
			if (error?.response?.data?.detail != null) setFetchStatusText(error.response.data.detail);
			setFetchStatus("failed");
		});
	};

	return (
		<>
			<tr className="CustomDetailsCommentsRow">
				<td colSpan={colSpan}>
					<CommentsBox
						rowObj={rowObj}
						inputComment={inputComment}
						setInputComment={setInputComment}
						setVisible={setVisible}
						postComment={postComment}
						deleteComment={deleteComment}
						editComment={editComment}
						// targetHeight={getTargetHeight()}
					/>
				</td>
			</tr>
		</>
	);
};

export default CustomDetailsComments;

import SearchIcon from "@mui/icons-material/Search";

const IndexesDirectRibbonSettings = (periodPriceZoneAutocomplete, objectAutocomplete) => {
    return [
        {
            name: "Период и ценовая зона",
            styles: {},
            _children: [
                {
                    name: "Subgroup 1",
                    orientation: "vertical",
                    styles: {},
                    _children: [
                        {
                            name: "Проверить смету",
                            icon: <SearchIcon />,
                            size: "large",
                            type: "custom",
                            customJsx: periodPriceZoneAutocomplete(),
                            callback: () => {},
                            styles: {
                                width: "330px",
                                pt: "7px",
                            },
                        },
                    ],
                },
            ],
        },
        {
            name: "Параметры отбора данных",
            styles: {},
            _children: [
                {
                    name: "Subgroup 1",
                    orientation: "vertical",
                    styles: {},
                    _children: [
                        {
                            name: "Проверить смету",
                            icon: <SearchIcon />, // путь к иконке
                            size: "large",
                            type: "custom",
                            customJsx: objectAutocomplete(),
                            //   customJsx: ResourceCustomFilterGroup(
                            //fsnbVersArr,
                            // ),
                            callback: () => {},
                            styles: {
                                width: "500px",
                                pt: "7px",
                            },
                        },
                    ],
                },
            ],
        },
    ];
};

export default IndexesDirectRibbonSettings;

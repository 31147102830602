import { highlightSubstring } from "core/highlight_utils";

const getCustomCellHeight = ({ rowObj, initHeight, rowMultiplyHeight }) => {
	let height = initHeight;
	if (rowObj.rowHeightMultiply && rowObj.rowHeightMultiply !== 1) height = rowMultiplyHeight * rowObj.rowHeightMultiply;
	return height;
};

const getCustomCellColSpan = ({ rowObj, colSpanTarget }) => {
	let colSpan = 1;
	if (rowObj.colSpan && rowObj.colSpanTarget === colSpanTarget) colSpan = rowObj.colSpan;
	return colSpan;
};

const getCustomCellContent = ({ rowObj, clmName, applyHighlight, subStrHighlight }) => {
	const cellText = rowObj[clmName] == null ? "" : String(rowObj[clmName]);

	const cellStrContent = cellText;
	let cellJsxContent = cellText;

	if (applyHighlight && subStrHighlight && String(subStrHighlight).length > 0) {
		cellJsxContent = highlightSubstring(cellStrContent, subStrHighlight, "SearchingText");
	}

	return { cellStrContent, cellJsxContent };
};

export { getCustomCellHeight, getCustomCellColSpan, getCustomCellContent };

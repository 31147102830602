import { apiBase } from "./base";

const apiLocalEstimate = {
    client: apiBase.get_client(),
    url: "/smetdocs/local_estimates/",

    get(id) {
        return this.client.get(this.url + id + "/", apiBase.get_headers());
    },

    edit(id, body) {
        return this.client.patch(this.url + id + "/", body, apiBase.get_headers());
    },

    delete(id) {
        return this.client.delete(this.url + id + "/", apiBase.get_headers());
    },

    getTree(id) {
        return this.client.get(this.url + "tree/" + id + "/", apiBase.get_headers());
    },

    calculate(id) {
        return this.client.get(this.url + "calculate/" + id + "/", apiBase.get_headers());
    },

    calculateCache(id, item_id) {
        return this.client.get(this.url + "calculate/" + id + "/use_cache/" + item_id + "/", apiBase.get_headers());
    },

    handleIndex(id, body) {
        return this.client.post(this.url + "handle_index/" + id + "/", body, apiBase.get_headers());
    },

    protectObj(body) {
        return this.client.post(this.url + "protection/", body, apiBase.get_headers());
    },

    unProtectObj(id) {
        return this.client.delete(this.url + "protection/" + id + "/", apiBase.get_headers());
    },

    protectFull(id, body) {
        return this.client.patch(this.url + "protect_full/" + id + "/", body, apiBase.get_headers());
    },

    getErrorJustification(id) {
        return this.client.get(this.url + "error_justification/" + id + "/", apiBase.get_headers());
    },

    getInputXml(id) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "text/xml";
        return this.client.get(this.url + "xml/input/" + id + "/", apiBase.get_headers());
    },

    getInputOriginXml(id) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "text/xml";
        return this.client.get(this.url + "xml/input/force/" + id + "/", apiBase.get_headers());
    },

    getOutputXml(id) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "text/xml";
        return this.client.get(this.url + "xml/output/" + id + "/", apiBase.get_headers());
    },

    getInputXmlPartOnError(id) {
        return this.client.get(this.url + "xml/input/part_on_error/" + id + "/", apiBase.get_headers());
    },

    getInputXmlPartItem(estm_id, item_id) {
        return this.client.get(
            this.url + "xml/input/source_part_item/" + estm_id + "/" + item_id + "/",
            apiBase.get_headers()
        );
    },

    getInputXmlPartSection(estm_id, section_id) {
        return this.client.get(
            this.url + "xml/input/source_part_section/" + estm_id + "/" + section_id + "/",
            apiBase.get_headers()
        );
    },

    getInputXmlPartEstimatePrice(estm_id) {
        return this.client.get(
            this.url + "xml/input/source_part_estimate_price/" + estm_id + "/",
            apiBase.get_headers()
        );
    },

    getPrintXlsx(id) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "blob";
        return this.client.get(this.url + "print/xlsx/" + id + "/", headers);
    },

    getPrintXlsxWithErrors(id) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "blob";
        return this.client.get(this.url + "print/xlsx_with_errors/" + id + "/", headers);
    },

    getCostSourceFormulas(id) {
        return this.client.get(this.url + "cost_source_formulas/" + id + "/", apiBase.get_headers());
    },

    getJustificationLogItem(id) {
        return this.client.get(this.url + "justification_log_item/" + id + "/", apiBase.get_headers());
    },

    getJustificationLogSection(id) {
        return this.client.get(this.url + "justification_log_section/" + id + "/", apiBase.get_headers());
    },

    getJustificationLogEstimatePrice(id) {
        return this.client.get(this.url + "justification_log_estimate_price/" + id + "/", apiBase.get_headers());
    },

    getJustificationLogPnr(id) {
        return this.client.get(this.url + "justification_log_pnr/" + id + "/", apiBase.get_headers());
    },

    setRowColor(body) {
        return this.client.post(this.url + "colorize/", body, apiBase.get_headers());
    },

    postRowComment(body) {
        return this.client.post(this.url + "comment/", body, apiBase.get_headers());
    },

    getRowComments({ estmId, key }) {
        return this.client.get(this.url + "comment/" + estmId + "/" + key + "/", apiBase.get_headers());
    },

    deleteRowComment(id) {
        return this.client.delete(this.url + "comment/" + id + "/", apiBase.get_headers());
    },

    editRowComment({ commentId, text }) {
        return this.client.patch(this.url + "comment/" + commentId + "/", { text: text }, apiBase.get_headers());
    },

    copyDoc({ estmId }) {
        return this.client.get(this.url + "copy/" + estmId + "/", apiBase.get_headers());
    },
};

export default apiLocalEstimate;

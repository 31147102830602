import { useState, useRef, useEffect } from "react";

import HeaderSort from "./HeaderSort";
import HeaderFilter from "./HeaderFilter";
import HeaderActionCell from "./HeaderActionCell";

import { getArgOrCallback } from "core/args_wrapper";

import "./TableHeaders.css";

export default function TableHeaders(props) {
	const [isMouseDown, setIsMouseDown] = useState(false);

	const [isResizing, setIsResizing] = useState(true);
	const [resizeLeft, setResizeLeft] = useState(0);

	const resizeParams = useRef({
		startX: 0,
		currentX: 0,
		clmName: "",
		cursorStyle: "pointer",
	});

	const columnsDom = [];
	const columnsDomChilds = [];

	let clmIndex = 0;

	const preventHandle = (e) => e.preventDefault();

	for (let clm of props.columns) {
		if (clm.isVisibleOnHeader === false) continue;
		const clmWidth = clm.width;
		const minWidth = clm.minWidth;
		const customStyle = clm.headCustomStyle == null ? {} : clm.headCustomStyle;
		let columnDom = <></>;
		if (clm.hasOwnProperty("headerCellJsx")) {
			columnDom = clm.headerCellJsx({ clmObj: clm });
		} else {
			columnDom = (
				<th
					key={clm.name}
					clm={clm.name}
					style={{
						textAlign: clm.headHorAlign,
						height: clm.headHeight,
						width: clmWidth,
						maxWidth: clmWidth,
						minWidth: minWidth,
						...customStyle,
					}}
					rowSpan={getArgOrCallback(clm.rowSpan)}
					colSpan={getArgOrCallback(clm.colSpan)}
					className="TableHeadCell"
				>
					<div
						className="TableHeadCellContent"
						resizable={String(clm.resize)}
						style={{
							justifyContent: clm.headHorAlign,
							marginLeft: clm.marginLeft,
						}}
					>
						<div className="TableHeadCellTitle" clm={clm.name}>
							{clm.title}
						</div>

						{clm.sort ? (
							<HeaderSort
								clmName={clm.name}
								tableData={props.tableData}
								sortParams={props.sortParams}
								sortDataUp={props.func.sortDataUp}
								sortDataDown={props.func.sortDataDown}
							/>
						) : (
							<></>
						)}
						{clm.filter ? (
							<HeaderFilter
								clmName={clm.name}
								tableData={props.tableData}
								filterParams={props.filterParams}
								filterValues={props?.filterValues}
								updateFilterStatus={props.func.updateFilterStatus}
								filterData={props.func.filterData}
							/>
						) : (
							<></>
						)}
						{clm.headerAction != null ? (
							<HeaderActionCell clmAction={clm.headerAction} tableData={props.tableData} funcs={props.func} />
						) : (
							<></>
						)}
					</div>
					{isResizing && props.options?.resizableColumns && clmIndex === 0 && (
						<div
							className="ResizePreview"
							style={{ left: `${resizeLeft}px` }}
							onMouseDown={preventHandle}
							onMouseUp={preventHandle}
							onMouseMove={preventHandle}
						></div>
					)}
				</th>
			);
		}

		columnsDom.push(columnDom);

		if (clm.children != null) {
			for (let clmChild of clm.children) {
				const columnDomChild = (
					<th
						key={clmChild.name}
						style={{
							textAlign: clmChild.headHorAlign,
							height: clmChild.headHeight,
							width: clmWidth,
							minWidth: minWidth,
							customStyle: customStyle,
						}}
						rowSpan={clmChild.rowSpan}
						colSpan={clmChild.colSpan}
						className="TableHeadCell"
					>
						<div
							className="TableHeadCellContent"
							style={{
								justifyContent: clmChild.headHorAlign,
								marginLeft: clmChild.marginLeft,
							}}
						>
							<div className="TableHeadCellTitle">{clmChild.title}</div>
						</div>
					</th>
				);
				columnsDomChilds.push(columnDomChild);
			}
		}

		clmIndex += 1;
	}

	useEffect(() => {
		if (!props.options?.resizableColumns) return;
		const handleMouseUp = (e) => {
			if (!e.target?.attributes?.clm?.value) {
				setIsMouseDown(false);
				setIsResizing(false);
			}
		};
		document.addEventListener("mouseup", handleMouseUp);
		return () => document.removeEventListener("mouseup", handleMouseUp);
	}, [props.options?.resizableColumns]);

	const handleMouseDown = (e) => {
		if (props.options?.resizableColumns !== true) return;
		resizeParams.current.clmName = e.target?.attributes?.clm?.value;
		if (resizeParams.current.clmName == null) return;
		setIsMouseDown(true);
		resizeParams.current.startX = e.clientX;
		resizeParams.current.cursorStyle = "col-resize";
	};

	const handleMouseUp = (e) => {
		if (props.options?.resizableColumns !== true) return;
		if (!isMouseDown) return;
		setIsMouseDown(false);
		resizeParams.current.cursorStyle = "pointer";
		const diff = resizeParams.current.currentX - resizeParams.current.startX;
		if (props.func.setParamUserSettings) {
			if (!props.options.userSettings?.[resizeParams.current.clmName]) return;
			const nextSetting = { ...props.options.userSettings?.[resizeParams.current.clmName] };
			nextSetting.width += diff;
			if (nextSetting.width < 40) nextSetting.width = 40;
			console.log(resizeParams.current.clmName, nextSetting.width);
			props.func.setParamUserSettings({ param: props.options.columnsSettingsName, key: resizeParams.current.clmName, value: nextSetting });

			if (props.func.saveUserSettings) props.func.saveUserSettings();
		}
		setIsResizing(false);
	};

	const handleMove = (e) => {
		if (props.options?.resizableColumns !== true) return;
		if (!isMouseDown) return;
		e.preventDefault();
		setIsResizing(true);
		resizeParams.current.currentX = e.clientX;
		setResizeLeft(e.clientX);
	};

	return (
		<thead
			className="TableHead"
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMove}
			style={{ cursor: resizeParams.current.cursorStyle }}
		>
			<tr
				className="TableHeadRow"
				onMouseDown={preventHandle}
				onMouseUp={preventHandle}
				onMouseMove={preventHandle}
				style={{ cursor: resizeParams.current.cursorStyle }}
			>
				{columnsDom}
			</tr>
			{columnsDomChilds.length ? <tr className="TableHeadRow">{columnsDomChilds}</tr> : <></>}
		</thead>
	);
}

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import MonitorOutlinedIcon from "@mui/icons-material/MonitorOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReportOffOutlinedIcon from "@mui/icons-material/ReportOffOutlined";

import { setQuestionVisible } from "slices/global_visible";

import "./TableRowStepper.css";

export default function TableRowStepper({ row, projectId, ...props }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const steps = ["Проверка формата файла", "Проверка по схеме XSD", "Проверка по ФСНБ"];

    let strType = "lsr";

    if (String(row?.schema).includes("MarketAnalysis")) {
        steps[2] = "Проверка достоверности";
        strType = "kats";
    }

    let activeStep = -1;
    const completed = {
        0: row?.steps?.format?.status,
        1: row?.steps?.xsd?.status,
        2: row?.steps?.fsnb?.status,
    };
    const stepText = {
        0: row?.steps?.format?.text,
        1: row?.steps?.xsd?.text,
        2: row?.steps?.fsnb?.text,
    };
    const stepName = {
        0: row?.steps?.format?.name,
        1: row?.steps?.xsd?.name,
        2: row?.steps?.fsnb?.name,
    };

    if (row?.steps?.fsnb?.status != null) {
        activeStep = 2;
    } else if (row?.steps?.xsd?.status != null) {
        activeStep = 1;
    } else if (row?.steps?.fsnb?.format != null) {
        activeStep = 0;
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const labelProps = {};
                    let icon;
                    if (completed[index] === "Success") {
                        labelProps.optional = (
                            <Typography variant="caption" title={stepText[index]}>
                                {stepText[index]}
                            </Typography>
                        );
                        icon = <CheckCircleIcon />;
                    } else if (completed[index] === "Warning") {
                        labelProps.optional = (
                            <Typography variant="caption" title={stepText[index]}>
                                {stepText[index]}
                                {index === 2 && (
                                    <span
                                        className="IconLink"
                                        onClick={() =>
                                            navigate(`/project/${projectId}/${strType}/${row.object_id}/protocol/`)
                                        }>
                                        <LogoutIcon />
                                    </span>
                                )}
                            </Typography>
                        );
                        icon = <WarningIcon />;
                    } else if (completed[index] === "Error") {
                        let errorText = stepText[index];
                        let isServerError = false;
                        if (String(stepName[index]).includes("SERVER_ERROR")) {
                            errorText = (
                                <span>
                                    <div>{`${errorText}.`}</div>
                                    <div
                                        className="StepperReportError"
                                        onClick={() => {
                                            dispatch(setQuestionVisible(true));
                                        }}>
                                        Сообщить об ошибке
                                    </div>
                                </span>
                            );
                            isServerError = true;
                        }
                        labelProps.optional = (
                            <Typography variant="caption" title={stepText[index]}>
                                {errorText}
                                {index === 2 && !isServerError && (
                                    <span
                                        className="IconLink"
                                        onClick={() => navigate(`/project/${projectId}/monitor/`)}>
                                        <MonitorOutlinedIcon />
                                    </span>
                                )}
                                {index === 1 && !isServerError && (
                                    <span
                                        className="IconLink"
                                        onClick={() => {
                                            if (row._details == null) {
                                                props.f_showErrors(row);
                                            } else {
                                                props.f_hideErrors(row);
                                            }
                                        }}>
                                        {row._details == null ? <ReportGmailerrorredIcon /> : <ReportOffOutlinedIcon />}
                                    </span>
                                )}
                            </Typography>
                        );
                        icon = <ErrorIcon />;
                    }
                    labelProps.status = completed[index];

                    return (
                        <Step key={label}>
                            <StepLabel {...labelProps} icon={icon}>
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}

import { useState, useRef } from "react";
import { useCellEditing } from "./useCellEditing";
import { checkTextIsDigit } from "core/digit_utils";

const CellEditing = ({ rowObj, style, clmName, cellTitle, colSpan, props }) => {
    const isActionDone = useRef(false);
    const [editingCell, setEditingCell] = useState("");
    const clickedTableClmName = props.rowOptions.clickedTableClmName;

    const { commitChanges, stopCurrentCellEditing } = useCellEditing({
        rowObj: rowObj,
        funcs: props.func,
        options: {
            editingCell,
            editingClmsRobin: props.rowOptions.editingClmsRobin,
            rowOptions: props.rowOptions,
            otherProps: props,
        },
    });

    const finishCurrentEditing = (clmName, eventName) => {
        let force = false;
        if (eventName === "onKeyDown") force = true;
        if (props.func.checkEditingFinishPermission) {
            if (!props.func.checkEditingFinishPermission({ rowObj })) return;
        }
        if (!isActionDone.current) {
            stopCurrentCellEditing(true);
            commitChanges({ clmName: clmName, remoteSave: true, force: force });
        }
    };

    const editablePermission = props.columnsFlat.find((item) => item.name === clmName).editablePermission;

    return (
        <td
            className="TableCellOnEdit"
            style={style}
            clm={clmName}
            colSpan={colSpan}
            is_clicked_clm_name={String(clmName === clickedTableClmName)}>
            <div className="TableCellEditing">
                <input
                    autoFocus
                    value={editingCell}
                    onFocus={(e) => setEditingCell(cellTitle)}
                    onInput={(e) => {
                        let val = e.target.value;
                        if (val == null) val = "";
                        if (editablePermission?.type === "number") {
                            val = val.replace(",", ".");
                            const isNumber = checkTextIsDigit(val);
                            if (isNumber || val === "-") setEditingCell(val);
                        } else if (editablePermission?.type === "regex") {
                            const regex = editablePermission.pattern;
                            const result = regex.test(val);
                            if (result) setEditingCell(val);
                        } else if (editablePermission?.type === "callback") {
                            const callbackPermission = editablePermission.callback(val);
                            if (callbackPermission) setEditingCell(val);
                        } else {
                            setEditingCell(val);
                        }
                        if (props.func.actionOnInput) {
                            props.func.actionOnInput({ inputVal: val });
                        }
                    }}
                    onBlur={(e) => finishCurrentEditing(clmName, "onBlur")}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            finishCurrentEditing(clmName, "onKeyDown");
                            // } else if (e.key === "Tab") {
                            //     finishCurrentEditingNext(clmName);
                        }
                    }}></input>
            </div>
        </td>
    );
};

export { CellEditing };

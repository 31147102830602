import { useState } from "react";

import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import KsprButton from "components/UI/buttons/KsprButton";

import "./KsprSelectBox.css";

const KsprSelectBox = (props) => {
    const label = props.label;
    const isError = props.isError;
    const optionValues = props.optionValues;
    const selectedOpts = props.selectedOpts;
    const setSelectedOpts = props.setSelectedOpts;
    const handleSelectClick = props.handleSelectClick;
    const width = props.width == null ? "150px" : props.width;
    let hideButton;
    if (props.hideButton == null) {
        hideButton = false;
    } else {
        hideButton = props.hideButton;
    }

    const [open, setOpen] = useState(props.defaultOpen === true ? true : false);

    const renderSelValue = (selected) => {
        let chipLst = [];
        for (let x of selected) {
            if (x != null) chipLst.push(<Chip key={x.id} label={x.name} />);
        }
        chipLst = chipLst.sort((a, b) => (a.key > b.key ? 1 : -1));
        return chipLst;
    };

    const handleChange = (row) => {
        setSelectedOpts((prev) => {
            const inArray = prev.find((x) => x.id === row.id);
            if (inArray == null) {
                return [...prev, row];
            } else {
                return prev.filter((x) => x.id !== row.id);
            }
        });
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        handleSelectClick();
    };

    return (
        <div className="KsprSelectBox" style={{ width: width }}>
            <TextField
                sx={{ "& legend": { fontSize: "9px" } }}
                label={label}
                size="small"
                error={isError}
                InputLabelProps={{ shrink: true }}
                select
                SelectProps={{
                    open: open,
                    onClose: handleClose,
                    onOpen: handleOpen,
                    multiple: true,
                    value: selectedOpts,
                    renderValue: renderSelValue,
                }}>
                {optionValues.map((opt) => (
                    <MenuItem
                        key={opt.id}
                        title={opt.name}
                        onClick={(e) => handleChange(opt)}
                        selected={selectedOpts.filter((x) => x.id === opt.id).length === 1}>
                        <Checkbox checked={selectedOpts.filter((x) => x.id === opt.id).length === 1}></Checkbox>
                        <ListItemText primary={opt.name} />
                    </MenuItem>
                ))}
                {hideButton !== true && <KsprButton variant="text" label="сохранить" onClick={handleClose} {...props.buttonProps} />}
            </TextField>
        </div>
    );
};

export default KsprSelectBox;

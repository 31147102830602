export default function changeVisibleRow(data, val) {
    for (let child of data) {
        if (child["show_always"] === true) {
            child["visible"] = true;
        } else {
            if (val === null) {
                if (child["visible"] === undefined) {
                    child["visible"] = false;
                } else {
                    child["visible"] = !child["visible"];
                }
            } else {
                child["visible"] = val;
            }
        }
    }
    return data;
}

export function searchDataRecourse(
    data_list,
    key,
    val,
    childs_key = "_children",
    childs_visible_key = "childs_visible",
    selectRow = false
) {
    for (let elm of data_list) {
        /// ускорение поиска по локалке
        if (elm["model_name"] === "LocalEstimateRimSections") {
            if (String(key).includes(`section_${elm["id"]}`) === false) {
                continue;
            }
        } else if (elm["model_name"] === "LocalEstimatesItems") {
            if (String(key).includes(`item_${elm["id"]}`) === false) {
                continue;
            }
        }
        /// ускорение поиска по локалке
        // console.log(JSON.parse(JSON.stringify(elm)), localStorage.getItem("DO_RECOURSE"));
        if (localStorage.getItem("DO_RECOURSE") === "0") {
            break;
        }

        let main_key = "_children";
        if (childs_key === "_children") {
            childs_key = "_children";
            if (elm.hasOwnProperty(main_key)) {
                if (elm["key"] === key) {
                    elm[childs_visible_key] = !elm[childs_visible_key];
                    if (selectRow) elm.selected = true;
                    elm = changeVisibleRow(elm[childs_key], val);

                    break;
                } else {
                    searchDataRecourse(elm[main_key], key, val, childs_key, childs_visible_key, selectRow);
                }
            }
        } else {
            // console.log(elm["key"], key, elm["key"] === key, elm.hasOwnProperty(childs_key), childs_key)
            if (elm.hasOwnProperty(childs_key)) {
                if (elm["key"] === key) {
                    elm[childs_visible_key] = !elm[childs_visible_key];
                    if (selectRow === true) elm.selected = true;
                    elm = changeVisibleRow(elm[childs_key], val);

                    localStorage.setItem("DO_RECOURSE", "0");
                    break;
                }
            } else if (selectRow === true) {
                if (elm["key"] === key) elm.selected = true;
            }
            if (elm.hasOwnProperty(main_key)) {
                searchDataRecourse(elm[main_key], key, val, childs_key, childs_visible_key, selectRow);
            }
        }
    }
    return data_list;
}

function compareUnProtect(itemDct, protected_id) {
    if (itemDct.hasOwnProperty("protected_id")) {
        if (itemDct["protected_id"] === protected_id) {
            return true;
        }
    }
    return false;
}

function compareProtect(itemDct, valDct) {
    if (itemDct.hasOwnProperty("protectable")) {
        if (itemDct["protectable"] === true) {
            if (itemDct["item_id"] === valDct["item_id"] && itemDct["id"] === valDct["object_id"]) {
                return true;
            }
        }
    }
    return false;
}

export function unProtectInState(data, protected_id) {
    let fnd = false;
    for (let sec of data) {
        if (fnd === true) {
            break;
        }
        for (let item of sec["_children"]) {
            if (compareUnProtect(item, protected_id)) {
                delete item["protected_id"];
                fnd = true;
                break;
            }
            if (item.hasOwnProperty("_children")) {
                for (let res of item["_children"]) {
                    if (compareUnProtect(res, protected_id)) {
                        delete res["protected_id"];
                        fnd = true;
                        break;
                    }
                    if (res.hasOwnProperty("_children")) {
                        for (let trnp of res["_children"]) {
                            if (compareUnProtect(trnp, protected_id)) {
                                delete trnp["protected_id"];
                                fnd = true;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
    return data;
}

export function ProtectInState(data, payload) {
    let fnd = false;
    for (let sec of data) {
        if (fnd === true) {
            break;
        }
        for (let item of sec["_children"]) {
            if (compareProtect(item, payload)) {
                item["protected_id"] = payload["id"];
                fnd = true;
                break;
            }
            if (item.hasOwnProperty("_children")) {
                for (let res of item["_children"]) {
                    if (compareProtect(res, payload)) {
                        res["protected_id"] = payload["id"];
                        fnd = true;
                        break;
                    }
                    if (res.hasOwnProperty("_children")) {
                        for (let trnp of res["_children"]) {
                            if (compareProtect(trnp, payload)) {
                                trnp["protected_id"] = payload["id"];
                                fnd = true;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
    return data;
}

export function changeRowDataRecourse(dataList, rowKey, callback) {
    let isFound = false;

    const changeRow = (dataList) => {
        if (isFound) return
        for (let elm of dataList) {
            if (elm.key === rowKey) {
                callback(elm);
                isFound = true;
                return;
            } else {
                if (elm._children) changeRow(elm._children);
            }
        }
    }

    changeRow(dataList);

    return dataList;
}



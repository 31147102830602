import { useSelector, useDispatch } from "react-redux";

import Checkbox from '@mui/material/Checkbox';
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ClickAwayListener } from '@mui/base';

import { setFilterParams } from "slices/kats";

import "./KatsExpertFilterModal.css";

const KatsExpertFilterModal = () => {
    const dispatch = useDispatch();
    const filterParams = useSelector((state) => state.kats.filterParams);

    const hideModal = () => {
        dispatch(setFilterParams({
            visible: false,
            posX: null,
            posY: null,
        }))
    }

    return <ClickAwayListener onClickAway={() => hideModal()}>
        <div className="KatsExpertValidModal"
            style={{ top: filterParams.posY, left: filterParams.posX }}>
            <Checkbox checked={filterParams._true}
                icon={<CheckCircleOutlineIcon cheked="false" />}
                checkedIcon={<CheckCircleOutlineIcon cheked="true" />}
                onChange={() => dispatch(setFilterParams({ ...filterParams, _true: !filterParams._true }))} />
            <Checkbox checked={filterParams._false}
                icon={<HighlightOffOutlinedIcon cheked="false" />}
                checkedIcon={<HighlightOffOutlinedIcon cheked="true" />}
                onChange={() => dispatch(setFilterParams({ ...filterParams, _false: !filterParams._false }))} />
            <Checkbox checked={filterParams._null}
                icon={<FlakyOutlinedIcon cheked="false" />}
                checkedIcon={<FlakyOutlinedIcon cheked="true" />}
                onChange={() => dispatch(setFilterParams({ ...filterParams, _null: !filterParams._null }))} />
        </div>
    </ClickAwayListener >
}

export { KatsExpertFilterModal }
export const getLastFiltredUrlFromStack = (stack, filterUrlArr) => {
    if (stack == null) { return "/" }
    if (stack.length < 2) { return "/" }
    for (let i = stack.length - 1; i >= 0; i--) {
        let isValid = true;
        for (let filterUrl of filterUrlArr) {
            if (stack[i].pathname.includes(filterUrl)) {
                isValid = false;
            }
        }
        if (isValid === true) {
            return stack[i].pathname;
        }
    }
    return stack[-1].pathname;
}

export const getNestedTableRowCnt = (table,
    childsKeysList = ["_children", "_details", "_paper_details"],
    visibleKey = "visible") => {
    let rowsCnt = 0;
    if (table == null) { return 0 }
    for (let row of table) {
        if (row[visibleKey] === false) { continue }
        rowsCnt += 1;
        for (let childKey of childsKeysList) {
            if (row.hasOwnProperty(childKey)) {
                rowsCnt += getNestedTableRowCnt(row[childKey], childsKeysList, visibleKey);
            }
        }
    }
    return rowsCnt;
}


export const getTextWidth = (text, font) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width;
}

export const calcRowsAdditinalHeight = (rows, rowHeight) => {
    if (rows == null) { return 0 }
    const rowsWithShowHide = rows.filter(x => x.rowHeightMultiply != null);
    const sumRowHeightMultiply = rowsWithShowHide.reduce((partialSum, x) => partialSum + x.rowHeightMultiply, 0) - rowsWithShowHide.length;
    return sumRowHeightMultiply * rowHeight;
}
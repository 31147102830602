import { useEffect, useRef, useLayoutEffect, useState, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import NoDataBox from "components/NoDataBox";
import Lottie from "lottie-react";
import animationData from "imgs/Anim3.json";

import { KatsEstmFooterSignatures, KatsExpertFilterModal } from "./components";

import {
	getProjectKatsTree,
	changeKatsVisible,
	changeKatsDetailsVisible,
	changeKatsPaperDetailsVisible,
	changeKatsErrorDetailsVisible,
	setKatsData,
	selectData,
	changeFiltredRowData,
	setKatsFetchStatus,
	setFiltredData,
	applyAdvSearchSelected,
} from "slices/kats";
import { setFetchStatusText } from "slices/project_work";
import { setCurrentPage, setClickedTableRow } from "slices/ribbon";

import { KatsTableColumns, KatsDetailsPaper } from "./KatsTableColumns";
import { KsrDrawer } from "./components/KsrDrawer/KsrDrawer";

import apiKatsEstimate from "api/kats";
import { downloadFile } from "core/request_utils";
import { isFileNamePdf } from "core/file_utils";
import { sortObjArrByNumberAsStr } from "core/iterable_utils";
import useTable from "hooks/useTable.js";

import "pages/EstimateViewer/TableOuterBox.css";
import "./KatsViewer.css";

const SlideDrawer = lazy(() => import("components/Drawer/Drawer.tsx"));

const PdfPreviewModel = lazy(() => import("components/PdfPreviewModel"));
const KaDiffChoiceModal = lazy(() => import("./components/KaDiffChoiceModal/KaDiffChoiceModal"));
const InnKppModal = lazy(() => import("./components/InnKppModal/InnKppModal"));

export const KatsProjectViewer = (props) => {
	const headBlock = useRef();

	const dispatch = useDispatch();
	const rowsData = useSelector((state) => state.kats.data);
	const filtredData = useSelector((state) => state.kats.filtredData);
	const headerData = useSelector((state) => state.kats.headerData);
	const detailsClms = useSelector((state) => state.kats.detailsClms);
	const filterParams = useSelector((state) => state.kats.filterParams);

	const originColumnsData = KatsTableColumns({ fileName: true });
	const [columnsData, setColumnsData] = useState([]);
	const [justPdfVisible, setJustPdfVisible] = useState(false);
	const [pdfBlobData, setPdfBlobData] = useState(null);
	const [pdfBlobName, setPdfBlobName] = useState("file.pdf");
	const advSearchActiveColorRow = useSelector((state) => state.advSearch.activeColorRow);
	const [kaDiffChoiceModalVisible, setKaDiffChoiceModal] = useState(false);
	const [innKppInfoVisible, setInnKppInfoVisible] = useState(false);
	const [innKppInfoData, setInnKppInfoData] = useState({});
	const [clmsSettingsInit, setClmsSettingsInit] = useState(false);
	const [previewVisible, setPreviewVisible] = useState(false);

	const { id: projectId, katsId } = useParams();

	useLayoutEffect(() => {
		dispatch(setCurrentPage("KatsProjectEstimateViewer"));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setPreviewVisible(true);
		const prom = dispatch(getProjectKatsTree(projectId));
		prom.then(() => {
			dispatch(applyAdvSearchSelected());
		}).finally(() => {
			setPreviewVisible(false);
		});
		return () => {
			dispatch(setKatsData([]));
			setPdfBlobData(null);
			setPreviewVisible(false);
		};
	}, [projectId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		props.setColumnsSettings(props.allColumnsSettings.filter((x) => x.checked === true));
	}, [props.allColumnsSettings]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const filterClms = [];
		for (let clm of originColumnsData) {
			if (["check", "expert_valid"].includes(clm.name)) {
				filterClms.push(clm);
			} else {
				const clmSet = props.columnsSettings.find((x) => x.id === clm.name);
				if (clmSet) {
					filterClms.push(clm);
				}
			}
		}
		setColumnsData(filterClms);
		setClmsSettingsInit(true);
	}, [props.columnsSettings]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!clmsSettingsInit) return;
		dispatch(
			setFiltredData(
				rowsData.filter((row) => {
					if (row.expert_valid === true && filterParams._true === true) {
						return true;
					} else if (row.expert_valid === false && filterParams._false === true) {
						return true;
					} else if (row.expert_valid === null && filterParams._null === true) {
						return true;
					}
					return false;
				})
			)
		);
	}, [filterParams._true, filterParams._false, filterParams._null, rowsData, clmsSettingsInit]); // eslint-disable-line react-hooks/exhaustive-deps

	const getJustFile = ({ offerId, fileName, fileId, rowObj, rowObjParent }) => {
		let arg = {};
		if (offerId == null) {
			if (rowObjParent?.source_offer_id != null) {
				arg = { offerId: rowObjParent.source_offer_id, fileId: fileId, projectId: rowObjParent.project_id };
			} else {
				return;
			}
		} else {
			arg = { offerId: offerId, fileId: fileId, projectId: 0 };
		}

		dispatch(setKatsFetchStatus("loading"));
		const prom = apiKatsEstimate.getJustFile(arg);
		prom.then((response) => {
			if (isFileNamePdf({ fileName })) {
				setPdfBlobName(fileName);
				setPdfBlobData(response.data);
				setJustPdfVisible(true);
			} else {
				downloadFile(response);
			}
			dispatch(setKatsFetchStatus("success"));
		}).catch((error) => {
			console.error(error);
			if (error?.response?.status === 400) {
				dispatch(setFetchStatusText("Запрошенное обоснование не загружено на сервер"));
				dispatch(setKatsFetchStatus("failed"));
			} else {
				dispatch(setKatsFetchStatus("success"));
			}
		});
	};

	props.lowerCallbacks.current.kats["getJustFile"] = getJustFile;

	const KatsEstmSelectData = (table, row, target, parentRow) => {
		dispatch(setClickedTableRow({ ...row, parentRow: parentRow }));
		dispatch(selectData(row));
	};

	const saveTableColumnsSettings = () => {
		const copy = [...props.allColumnsSettings];
		const body = [];
		for (let clmCopy of copy) {
			const elm = { ...clmCopy };
			if (props.columnsSettings.find((x) => x.id === clmCopy.id)) {
				elm.checked = true;
			} else {
				elm.checked = false;
			}
			body.push(elm);
		}
		dispatch(setKatsFetchStatus("loading"));
		const prom = apiKatsEstimate.saveColumnsSettings({ body });
		prom.then((response) => {
			dispatch(setKatsFetchStatus("success"));
		}).catch((error) => {
			dispatch(setKatsFetchStatus("failed"));
		});
	};
	props.lowerCallbacks.current.kats["saveTableColumnsSettings"] = saveTableColumnsSettings;

	const tableBody = useTable(
		filtredData,
		columnsData,
		{
			selectable: true,
			scrollBlock: props.scrollBlock,
			headBlock: headBlock,
			headScrollControllBlock: null,
			detailsClms: detailsClms,
			addH: 35,
			paperSettings: KatsDetailsPaper({ setInnKppInfoVisible, setInnKppInfoData }),
			tableType: "kats",
			scrollHeightControll: 10,
			advSeachActiveRow: advSearchActiveColorRow,
			originColumnsData: originColumnsData,
		},
		{
			changeVisible: changeKatsVisible,
			changeDetailsVisible: changeKatsDetailsVisible,
			changeErrorsDetailsVisible: changeKatsErrorDetailsVisible,
			changePaperDetailsVisible: changeKatsPaperDetailsVisible,
			selectDataLocal: KatsEstmSelectData,
			changeRowData: changeFiltredRowData,
			updateData: (data) => {
				dispatch(setFiltredData(data));
			},
			getJustFile: getJustFile,
			changeVisibleOptions: {
				tagName: {
					allowed: ["svg", "path"],
				},
			},
			customSortDataUp: {
				num: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: clmName, direction: "up" }),
				cost: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: clmName, direction: "up" }),
				project_weight: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: "project_weight_percent", direction: "up" }),
			},
			customSortDataDown: {
				num: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: clmName, direction: "down" }),
				cost: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: clmName, direction: "down" }),
				project_weight: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: "project_weight_percent", direction: "down" }),
			},
		},
		{
			notClosedOptions: {
				advSeachActiveRow: advSearchActiveColorRow,
			},
		}
	);

	if (!clmsSettingsInit) return;

	if (rowsData == null) {
		return <NoDataBox text="конъюнктурном анализе цен" />;
	} else if (rowsData.length === 0) {
		return (
			<>
				{previewVisible && (
					<section className="KatsPreviewAnimation">
						<Lottie animationData={animationData} loop={true} autoplay={true} speed={0.5} style={{ height: 200 }} />
						<span className="KatsPreviewAnimationText">Подождите пожалуйста, формируется сводный конъюнктурный анализ...</span>
					</section>
				)}
			</>
		);
	} else if (rowsData.length > 0) {
		return (
			<>
				<div className="TableOuterBox" type="Kats" ref={headBlock}>
					<div className="TableOuterHeadMainTitle">
						<div className="TitleLeft"></div>
						<div className="TitleCenter">СВОДНЫЙ КОНЪЮНКТУРНЫЙ АНАЛИЗ ЦЕН</div>
					</div>
					{tableBody}
					{katsId != null && <KatsEstmFooterSignatures data={headerData} />}
					{justPdfVisible && (
						<Suspense>
							<PdfPreviewModel blobData={pdfBlobData} blobName={pdfBlobName} setVisible={setJustPdfVisible}></PdfPreviewModel>
						</Suspense>
					)}
					{kaDiffChoiceModalVisible && (
						<Suspense>
							<KaDiffChoiceModal setVisible={setKaDiffChoiceModal} katsId={katsId}></KaDiffChoiceModal>
						</Suspense>
					)}
					{filterParams.visible && <KatsExpertFilterModal />}
					{innKppInfoVisible && (
						<Suspense>
							<InnKppModal setVisible={setInnKppInfoVisible} requestData={innKppInfoData} />
						</Suspense>
					)}
				</div>

				{props.ksrVisible && (
					<Suspense fallback={" "}>
						<SlideDrawer
							isVisible={props.ksrVisible}
							direction={"left"}
							width={"55%"}
							top={"143px"}
							content={() => (
								<KsrDrawer
									setVisible={props.setKsrVisible}
									displayOptions={{ tree: true, table: true }}
									searchOptions={{ levels: [4, 5] }}
								/>
							)}
						/>
					</Suspense>
				)}
			</>
		);
	}
};

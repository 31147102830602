import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import MonitorStepper from "./MonitorStepper";
import MonitorTableRowDetails from "./MonitorTableRowDetails";

import { changeMonitorDataChildrenVisible } from "slices/import_files";

import "./MonitorTableRow.css";

const MonitorTableRow = ({ row, steps, type }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let icn = null;
    let loading;
    let openess;

    const getParentIcon = () => {
        if (!row?._children?.length && type === "Osr") {
            return <div className="MonCellOsrEmpty"></div>;
        } else if (!row?._children?.length && type === "Ssr") {
            return <div className="MonCellSsrEmpty"></div>;
        } else {
            return (
                <div
                    className="MonCellIcon"
                    openess={openess}
                    onClick={() => {
                        dispatch(changeMonitorDataChildrenVisible({ row: row }));
                    }}>
                    {openess != null && <ArrowForwardIosOutlinedIcon />}
                </div>
            );
        }
    };

    if (["Ssr", "Osr"].includes(type)) {
        if (row.childs_visible === true) {
            openess = "true";
        } else if (row.childs_visible === false) {
            openess = "false";
        }
        icn = getParentIcon();
    }

    if (["Lsr", "Kats-Child"].includes(type)) {
        if (row.in_work === true) {
            loading = (
                <div className="MonitorSingleLoaderWrapper">
                    <CircularProgress />
                </div>
            );
        } else {
            loading = <div style={{ paddingLeft: "40px" }}></div>;
        }
    }

    return (
        <>
            <div className="MonitorTableRow" type={type}>
                <div className="MonitorTableCellTitle" title={row.innertext}>
                    {icn}
                    {loading}
                    <div
                        className="MonCellText"
                        onClick={() => {
                            localStorage.setItem("scrollCnt", 0);
                            if (row.type === "summary") {
                                navigate("/project/" + row.project_id);
                            } else if (row.type === "object") {
                                if (row.id !== 0) navigate("/project/" + row.project_id + "/osr/" + row.id);
                            } else if (row.type === "local") {
                                if (row.id !== 0) navigate("/project/" + row.project_id + "/lsr/" + row.id);
                            } else if (row.type === "full") {
                                if (row.id !== 0) navigate("/project/" + row.project_id);
                            } else if (row.type === "kats-child") {
                                if (row.id !== 0) navigate("/project/" + row.project_id + "/kats/" + row.id);
                            }
                        }}>
                        {row.innertext}
                    </div>
                </div>
                <div className="MonitorTableCellValue">
                    <MonitorStepper steps={steps} row={row} />
                </div>
            </div>
            {type === "Lsr" && <MonitorTableRowDetails row={row} />}
        </>
    );
};

export default MonitorTableRow;

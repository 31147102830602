import React from "react";

import "./NoDataBox.css";

import nodata from "../imgs/nodata.png";

export default function NoDataBox(props) {
    let label = "Нет данных";
    let firstText = `В ${props.text} нет данных.`;
    let secondText = "Выберите для просмотра другую смету из состава проектной документации.";

    if (props.label) label = props.label;
    if (props.firstText) firstText = props.firstText;
    if (props.secondText) secondText = props.secondText;

    return (
        <div className="NoDataBox">
            <div className="NoDataTop">
                <div className="NoDataImg">
                    <img src={nodata} alt=""></img>
                </div>
            </div>
            <div className="NotDataBottom">
                <div className="NotDataBottomLabel">{label}</div>
                <div className="NotDataBottomText">{firstText}</div>
                <div className="NotDataBottomText">{secondText}</div>
            </div>
        </div>
    );
}

import React from "react";
import { Typography } from "@mui/material";

function CustomTypography({ text }) {
    return (
        <Typography
            p={1}
            variant="body2"
            sx={{
                fontSize: "14px",
                color: "#000000b3",
            }}
        >
            {text}
        </Typography>
    );
}

export default CustomTypography;

import { useEffect, useState, lazy, Suspense } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
	getProtocol,
	setFetchStatus,
	selectData,
	setReGetProtocol,
	delUserSetExcept,
	postUserSetExcept,
	changeRowKeyValue,
	setData,
	getSelectedTypes,
} from "slices/protocol";
import { setLocalFetchStatus } from "slices/local_estimate";
import { setCurrentPage, setCurrentEstmDocId, setCurrentEstmDocUrlPart, setClickedTableRow, setClickedTableClmName } from "slices/ribbon";
// import { setContextMenuPosition, setContextMenuObj, setIsContextMenuOpen } from "slices/context_menu";

import apiLocalEstm from "api/local_estimate";
import apiFsnb from "api/fsnb";
import apiProtocol from "api/protocol";

import ProtocolTableColumns from "./ProtocolTableColumns";
import TableMain from "components/Table/TableMain";
import AlertLoading, { selectFetchStatus } from "components/AlertLoading";
import PopupBox from "components/PopupBox";
import NoDataBox from "components/NoDataBox";
import { getRowHeightMultiplier } from "components/Table/utils/row_utils.js";
import FsnbCoefBox from "pages/Fsnb/components/FsnbCoefBox";

import { KatsExternalLink } from "./components";

import apiLocalEstimate from "api/local_estimate";
import apiObjectEstimate from "api/object_estimate";
import apiSummaryEstimate from "api/summary_estimate";
import apiKatsEstimate from "api/kats";

import { searchNestedIterableItem } from "core/iterable_utils";

import "pages/EstimateViewer/TableOuterBox.css";
import "pages/EstimateViewer/KatsViewer/KatsViewer.css";
import "./ProtocolViewer.css";

const JustificationUnionModalBox = lazy(() => import("pages/EstimateViewer/components/JustificationModalBox/JustificationUnionModalBox"));

export default function ProtocolViewer(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const obj = useSelector((state) => state.protocol.obj);
	const proj_main = useSelector((state) => state.projectWork.main);
	const data = useSelector((state) => state.protocol.data);
	const fetchStatus = useSelector((state) => state.protocol.fetchStatus);
	const fetchLocStatus = useSelector((state) => state.localEstimate.fetchStatus);
	const reGetProtocol = useSelector((state) => state.protocol.reGetProtocol);
	const selectTypeData = useSelector((state) => state.protocol.selectTypeData);
	const objType = props.objType;
	const columns_data = ProtocolTableColumns(objType);

	const usrExceptions = useSelector((state) => state.protocol.usrExceptions);

	const [fetchDone, setFetchDone] = useState(false); // для отображения картинки нет данных
	const userPermissions = useSelector((state) => state.auth.permissions);

	const [justBoxVisible, setJustBoxVisible] = useState(false);
	const [justData, setJustData] = useState(null);
	const [xmlData, setXmlData] = useState(null);

	const [coefBoxVisible, setCoefBoxVisible] = useState(false);
	const [coefRow, setCoefRow] = useState({});

	const location = useLocation();
	const { id, katsId } = useParams();

	const localFetchStatus = selectFetchStatus([fetchStatus, fetchLocStatus]);

	useEffect(() => {
		dispatch(setCurrentPage("ProtocolViewer"));

		localStorage.removeItem("displayScrollTop");
		if (props.scrollBlock["current"] != null) {
			props.scrollBlock["current"].scrollTop = 0;
		}
		dispatch(setReGetProtocol(true));

		return () => {
			dispatch(setReGetProtocol(false));
			dispatch(setData([]));
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(setCurrentEstmDocUrlPart(objType));
		if (objType === "kats") {
			dispatch(setCurrentEstmDocId(katsId));
		} else {
			dispatch(setCurrentEstmDocId(id));
		}
	}, [id]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (reGetProtocol === true) fetchProtocol();
	}, [id, objType, reGetProtocol]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchProtocol = () => {
		let promise;
		if (objType === "kats") {
			if (katsId == null) {
				dispatch(setData([]));
				return;
			}
			promise = dispatch(getProtocol({ type: objType, id: katsId }));
		} else {
			promise = dispatch(getProtocol({ type: objType, id: id }));
		}
		promise.then((data) => {
			setFetchDone(true);
		});
	};

	const navigateWrapper = (clickedRow) => {
		if (clickedRow.id) {
			dispatch(setFetchStatus("loading"));

			let prom;
			if (objType === "lsr") {
				prom = apiLocalEstimate.getTree(id);
			} else if (objType === "osr") {
				prom = apiObjectEstimate.getTree(id);
			} else if (objType === "ssr") {
				prom = apiSummaryEstimate.getTree(id, "current");
			} else if (objType === "kats") {
				prom = apiKatsEstimate.getTree(katsId);
			}

			prom.then((response) => {
				const tableData = response.data.table_data;
				const { seekItem, seekCount } = searchNestedIterableItem({
					iterable: tableData,
					isSeekRowCallback: (row) => {
						if (row.target_key && row.target_key === clickedRow.target_key) {
							return true;
						} else if (row.target_key && row.target_key === clickedRow.key) {
							return true;
						} else if (row.is_item === "1" && row.item_id === clickedRow.item_id) {
							if (
								String(clickedRow["target_key"]).includes("LocalEstimatesCosts") ||
								String(clickedRow["target_key"]).includes("LocalEstimateCostCoefficients") ||
								String(clickedRow["target_key"]).includes("LocalEstimatesItems")
							)
								return true;
						}
						return false;
					},
					isPermittedRowCallback: (row) => {
						if (row.visible === false && objType !== "ssr") return false;
						return true;
					},
					isPermittedChildsLoopCallback: (row) => true,
				});
				if (seekCount > 0 && seekItem?.key) {
					localStorage.setItem("scrollType", objType);
					localStorage.setItem("displayScrollTop", seekCount);
					localStorage.setItem("scrollKey", seekItem.key);
					if (clickedRow.new_tab) {
						window.open(location["pathname"].replace("/protocol/", "/"));
					} else {
						navigate(location["pathname"].replace("/protocol/", "/"));
					}
				}
				dispatch(setFetchStatus("success"));
			});
		}
	};

	props.lowerCallbacks.current.protocol["navigateWrapper"] = navigateWrapper;

	const handleDelExpect = (body) => {
		dispatch(delUserSetExcept(body));
	};

	const handleAddException = (row) => {
		const body = {
			user_id: localStorage.getItem("userId"),
			project_id: proj_main["id"],
			except_str: row["state_name"],
		};
		dispatch(postUserSetExcept(body));
		dispatch(setClickedTableRow(null));
	};

	props.lowerCallbacks.current.protocol["handleAddException"] = handleAddException;

	const showMoreProtocol = (row) => {
		dispatch(
			changeRowKeyValue({
				rowKey: row.key,
				stepId: row.step_id,
				key: "rowHeightMultiply",
				value: getRowHeightMultiplier(row, columns_data, ["state_name", "expected", "received"], "12px", 1.25),
			})
		);
	};

	const hideMoreProtocol = (row) => {
		dispatch(
			changeRowKeyValue({
				rowKey: row.key,
				stepId: row.step_id,
				key: "rowHeightMultiply",
				value: null,
			})
		);
	};

	const filterThisTypeOnly = (row) => {
		const prom = apiProtocol.postUserSetGpr(localStorage.getItem("userId"), proj_main["id"], [
			{ step_id: row.step_id, group_id: row.error_group_id, type_id: row.error_type_id },
		]);
		prom.then((response) => {
			dispatch(getSelectedTypes(proj_main["id"]));
			dispatch(setReGetProtocol(true));
		});
		dispatch(setClickedTableRow(null));
	};

	props.lowerCallbacks.current.protocol["filterThisTypeOnly"] = filterThisTypeOnly;

	const hideThisType = (row) => {
		const chekedError = [];
		const otherError = [];
		let body = [];

		for (let step of selectTypeData) {
			for (let grp of step._children) {
				for (let err of grp._children) {
					if (err.id !== row.error_type_id) {
						if (err.checked !== false) {
							chekedError.push({
								step_id: err.step_id,
								group_id: err.group_id,
								type_id: err.id,
							});
						} else {
							otherError.push({
								step_id: err.step_id,
								group_id: err.group_id,
								type_id: err.id,
							});
						}
					}
				}
			}
		}

		if (chekedError.length < 2) {
			body = [...chekedError, ...otherError];
		} else {
			body = chekedError;
		}

		const prom = apiProtocol.postUserSetGpr(localStorage.getItem("userId"), proj_main["id"], body);
		prom.then((response) => {
			dispatch(getSelectedTypes(proj_main["id"]));
			dispatch(setReGetProtocol(true));
		});
		dispatch(setClickedTableRow(null));
	};

	props.lowerCallbacks.current.protocol["hideThisType"] = hideThisType;

	const showErrorJustification = (row) => {
		let api = apiLocalEstm;
		if (row.estm_type === "kats") api = apiKatsEstimate;
		dispatch(setFetchStatus("loading"));
		const justPromise = new Promise((resolve, reject) => {
			api.getErrorJustification(row.id)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.message));
		});
		const xmlPromise = new Promise((resolve, reject) => {
			api.getInputXmlPartOnError(row.id)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.message));
		});
		Promise.all([justPromise, xmlPromise])
			.then((response_arrs) => {
				setJustData(response_arrs[0]);
				setXmlData(response_arrs[1]);
				setJustBoxVisible(true);
				dispatch(setFetchStatus("success"));
			})
			.catch((error) => {
				console.error(error);
				dispatch(setFetchStatus("failed"));
			});
	};

	const showCoefs = (row) => {
		apiFsnb
			.getNormIdOnErrorRow(row.id)
			.then((response) => {
				setCoefRow(response.data);
				setCoefBoxVisible(true);
			})
			.catch((error) => {
				dispatch(setLocalFetchStatus("failed"));
				console.error(error);
			});
	};

	const localProtSelectData = (table, row, target, parentRow) => {
		const clmName = target?.attributes?.clm?.value;
		dispatch(setClickedTableRow({ ...row, parentRow: parentRow }));
		dispatch(selectData(row.key));
		dispatch(setClickedTableClmName(clmName));
	};

	const onContextMenuClick = (e, rowObj, parentRow) => {
		// e.preventDefault();
		// dispatch(setClickedTableRow({ ...rowObj, parentRow: parentRow }));
		// dispatch(setClickedTableClmName(e?.target?.attributes?.clm?.value));
		// dispatch(selectData(rowObj.key));
		// dispatch(setContextMenuPosition({ top: e.clientY - 150, left: e.clientX }));
		// dispatch(setContextMenuObj(rowObj));
		// dispatch(setIsContextMenuOpen(true));
	};

	let title;
	if (obj["name"] != null) {
		title = `Протокол проверки ${obj["name"]}`;
	}

	return (
		<div className="TableOuterBox" type="Protocol">
			<div className="ProtocolBlankPlaceHolder">
				<div className="TableOuterHeadMainTitle">
					<div className="TitleLeft"></div>
					<div className="TitleCenter">{title != null && <span title={title}>{title}</span>}</div>
					{userPermissions.is_admin && <div className="TitleRight">{objType === "kats" && <KatsExternalLink />}</div>}
				</div>
				<div className="TableOuterHeadMainFilter">
					<div className="ProtocolCnt">
						<div className="ProtocolCntLabel">
							Всего в протоколе: {obj.all_err_cnt} записей. Отобрано: {data.length} записей.
						</div>
					</div>
				</div>
			</div>
			{fetchDone === true && data.length === 0 ? (
				<div style={{ height: "895px" }}>
					<NoDataBox
						label="Нет замечаний и ошибок для отображения"
						firstText="Нет замечаний и ошибок для отображения"
						secondText="Выберите для просмотра другую смету из состава проектной документации. Возможно следует изменить параметры фильтра."
					/>
				</div>
			) : (
				<TableMain
					rows={{ data: data }}
					columns={columns_data}
					options={{
						selectable: true,
						headBlock: null,
						navigate: { func: navigateWrapper },
						scrollBlock: props.scrollBlock,
						displayScrollDelay: 4,
						headScrollControllBlock: null,
						scrollHeghtControll: 100,
					}}
					func={{
						selectDataLocal: localProtSelectData,
						addExcept: handleAddException,
						showMore: showMoreProtocol,
						hideMore: hideMoreProtocol,
						filterThisTypeOnly: filterThisTypeOnly,
						hideThisType: hideThisType,
						showErrorJustification: showErrorJustification,
						showCoefs: showCoefs,
						singleClickRowAction: {
							tagName: {
								allowed: ["td", "span"],
							},
							action: showMoreProtocol,
						},
						doubleClickRowAction: {
							action: navigateWrapper,
						},
						onContextMenuRowAction: {
							action: onContextMenuClick,
						},
					}}
				></TableMain>
			)}

			<AlertLoading fetchStatus={localFetchStatus} func={[setLocalFetchStatus, setFetchStatus]} />
			{justBoxVisible === true && (
				<Suspense>
					<JustificationUnionModalBox justData={justData} xmlData={xmlData} estmId={id} f_visible={setJustBoxVisible} />
				</Suspense>
			)}
			{props.expectPopVisb === true ? (
				<div className="ProtocolExceptionPopUp">
					<PopupBox
						width="50%"
						height="35%"
						title="Настройка исключений просмотра протокола"
						theadTitle="Описание ошибки"
						data={usrExceptions}
						data_clm="except_str"
						f_popup_visb={props.setExpectPopVisb}
						f_delete={handleDelExpect}
						bodyType="one-clm-table"
					></PopupBox>
				</div>
			) : (
				<div></div>
			)}
			{coefBoxVisible && <FsnbCoefBox row={coefRow} width="calc(90% - 250px)" f_closeCoefBox={() => setCoefBoxVisible(false)} />}
		</div>
	);
}

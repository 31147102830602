import React, { useRef, useState, useEffect } from 'react';

import TableMain from "components/Table/TableMain";
import KsprButton from "components/UI/buttons/KsprButton";
import TechGroupTableColumns from '../TableSetting/TechGroupTableColumns';
import AlertLoading from "components/AlertLoading";
import { showTableMore } from "../utils";
import Draggable from "react-draggable";

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from "@mui/material/Dialog";

import apiFsnb from 'api/fsnb';

import "./FsnbTechGrpBox.css";
import "./FsnbAutocomplete.css";


export default function FsnbTechGrpBox({ row, vers, ...props }) {
    // console.log(row)
    const [data, setData] = useState([]);
    const [filtredData, setFiltredData] = useState([]);
    const scrollBlock = useRef(null);
    const [fetchStatus, setFetchStatus] = useState(null);
    const [fetchStatusText, setFetchStatusText] = useState("");

    useEffect(() => {
        setFetchStatus("loading");
        let prom = apiFsnb.getTechGrp(vers.id, row.technology_groups);
        prom.then((responce) => {
            setData(responce.data);
            setFiltredData(responce.data);
            setFetchStatus("success");
        }).catch(error => {
            console.error(error)
            setFetchStatus("failed");
            setFetchStatusText(error);
        });
    }, [vers, row]) // eslint-disable-line react-hooks/exhaustive-deps

    const showMore = (row) => {
        showTableMore(row, "12px", ["name"], setData, "FsnbTechGrpBoxTableLayout")
    }

    const columnsData = TechGroupTableColumns();

    let headerName = row.name;
    if (headerName == null) headerName = row.end_name;

    return (
        <Draggable handle=".FsnbTechGrpBoxHeader">
            <Dialog open slotProps={{ backdrop: { style: { backgroundColor: "transparent" } }, }}>
                <div className="FsnbTechGrpBox">
                    <div className="FsnbTechGrpBoxHeader">
                        <div className="FsnbTechGrpBoxHeaderLabel">Технологическая группа для {row.code} {headerName}, {row.measure_unit}</div>
                    </div>
                    <div className="FsnbTechGrpBoxBody" >
                        <div className="FsnbFilters" style={{ width: "50%" }}>
                            <TextField
                                sx={{ width: "100%" }}
                                label="Поиск"
                                size="small"
                                value={props.inputSearchValue}
                                onChange={(e) => {
                                    const val = String(e.target.value).toLowerCase();
                                    setFiltredData(data.filter(row => {
                                        if (String(row.code).toLowerCase().includes(val) || String(row.name).toLowerCase().includes(val)) {
                                            return true
                                        }
                                        return false
                                    }))
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>
                                }}
                            />
                        </div>
                        <div className="FsnbTechGrpBoxTableLayout" ref={scrollBlock}>
                            <TableMain
                                rows={{ "data": filtredData }}
                                columns={columnsData}
                                options={{
                                    "scrollBlock": scrollBlock,
                                    "displayScrollDelay": 20,
                                    "funcCall": "local"
                                }}
                                func={{
                                    "singleClickRowAction": {
                                        "tagName": {
                                            "allowed": ["td", "span"],
                                        },
                                        "action": showMore
                                    }
                                }}
                            ></TableMain>
                        </div>
                    </div>
                    <div className="FsnbTechGrpBoxFooter">
                        <KsprButton variant="text" label="Закрыть" onClick={props.f_closeCoefBox} />
                    </div>
                    <AlertLoading
                        fetchStatus={fetchStatus}
                        fetchStatusText={fetchStatusText}
                        func={setFetchStatus}
                    />
                </ div>
            </Dialog>
        </Draggable >
    )
}

import { apiBase } from "./base";

const apiKatsEstimate = {
	client: apiBase.get_client(),
	url: "/smetdocs/kats/",
	urlExpert: "/kats/",
	urlMapper: "/smetdocs/kats_mapper/",

	getTree(id) {
		return this.client.get(this.url + "tree/" + id + "/", apiBase.get_headers());
	},

	getKatsMapper(projectId, code, name) {
		return this.client.get(this.urlMapper + projectId + "/" + code + "/" + name + "/", apiBase.get_headers());
	},

	getProjectTree(id) {
		return this.client.get(this.url + "tree/project/" + id + "/", apiBase.get_headers());
	},

	delete(id) {
		return this.client.delete(this.url + id + "/", apiBase.get_headers());
	},

	calculate(id) {
		return this.client.get(this.url + id + "/calculate/", apiBase.get_headers());
	},

	uploadJust({ file, katsId }) {
		const formData = new FormData();
		formData.append("file", file);

		return this.client.post(this.url + "upload_just/" + katsId + "/", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: localStorage.getItem("accessToken"),
				"Auth-Uuid": localStorage.getItem("authUuid"),
			},
		});
	},

	getJustFile({ offerId, projectId, fileId }) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "offer_just/" + offerId + "/" + projectId + "/" + fileId + "/", headers);
	},

	getJustPriceOfferFile({ priceOfferId }) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "price_offer_just/" + priceOfferId + "/", headers);
	},

	getJustFileUrl(id) {
		return this.url + "offer_just/" + id + "/";
	},

	getOutputXml(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "text/xml";
		return this.client.get(this.url + "xml/output/" + id + "/without_justs/", apiBase.get_headers());
	},

	getInputOriginXml(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "text/xml";
		return this.client.get(this.url + "xml/input/force/" + id + "/without_justs/", apiBase.get_headers());
	},

	getOutputXmlWithJusts(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "xml/output/" + id + "/with_justs/", headers);
	},

	getInputOriginXmlWithJusts(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "xml/input/force/" + id + "/with_justs/", headers);
	},

	getPrintXlsx(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "print/xlsx/" + id + "/", headers);
	},

	getPrintXlsxPpk(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "print/xlsx_ppk/" + id + "/", headers);
	},

	setExpertValid({ resId, expertValidValue }) {
		return this.client.patch(this.url + "kats_resource/" + resId + "/", { expert_valid: expertValidValue }, apiBase.get_headers());
	},

	addExpertOffer({ resId, body }) {
		return this.client.post(this.url + "kats_resource/expert_offer/" + resId + "/", body, apiBase.get_headers());
	},

	deleteExpertOffer({ offerId }) {
		return this.client.delete(this.url + "kats_resource/expert_offer/" + offerId + "/", apiBase.get_headers());
	},

	getPriceOfferData({ body }) {
		return this.client.post(this.urlExpert + "price_offer_filter/", body, apiBase.get_headers());
	},

	getPriceOfferDataXlsx({ body }) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.post(this.urlExpert + "price_offer_filter/xlsx/", body, headers);
	},

	getPilesosOfferData({ body }) {
		return this.client.post(this.urlExpert + "pilesos_offer_filter/", body, apiBase.get_headers());
	},

	getOfferNeighbour({ body }) {
		return this.client.post(this.urlExpert + "price_offer_filter_neighbour/", body, apiBase.get_headers());
	},

	// getOfferNeighbour({ offerId }) {
	//     return this.client.get(this.urlExpert + "price_offer_filter_neighbour/" + offerId + "/", apiBase.get_headers());
	// },

	getPrintXlsxWithErrors(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "print/xlsx_with_errors/" + id + "/", headers);
	},

	getDiff({ sourceId, targetId }) {
		return this.client.get(this.url + "diff/" + sourceId + "/" + targetId + "/", apiBase.get_headers());
	},

	getInnKppData({ inn, kpp }) {
		return this.client.get(this.urlExpert + "provider_data/" + inn + "/" + kpp + "/", apiBase.get_headers());
	},

	getDiffFile({ sourceId, targetId }) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "diff/" + sourceId + "/" + targetId + "/xlsx/", headers);
	},

	setRowColor(body) {
		return this.client.post(this.url + "colorize/", body, apiBase.get_headers());
	},

	getColumnsSettings() {
		return this.client.get(this.url + "columns_settings/", apiBase.get_headers());
	},

	saveColumnsSettings({ body }) {
		return this.client.put(this.url + "columns_settings/", body, apiBase.get_headers());
	},

	execKatsJustValidation({ katsId }) {
		return this.client.get(this.url + "verify_just_ocr/" + katsId, apiBase.get_headers());
	},

	execProjectKatsJustValidation({ projectId }) {
		return this.client.get(this.url + "verify_just_ocr_project/" + projectId + "/", apiBase.get_headers());
	},

	postRowComment(body) {
		return this.client.post(this.url + "comment/", body, apiBase.get_headers());
	},

	getRowComments({ estmId, key }) {
		return this.client.get(this.url + "comment/" + estmId + "/" + key + "/", apiBase.get_headers());
	},

	deleteRowComment(id) {
		return this.client.delete(this.url + "comment/" + id + "/", apiBase.get_headers());
	},

	editRowComment({ commentId, text }) {
		return this.client.patch(this.url + "comment/" + commentId + "/", { text: text }, apiBase.get_headers());
	},

	getJustStatus({ estmId }) {
		return this.client.get(this.url + "just_status/" + estmId + "/", apiBase.get_headers());
	},

	getProjectLsrTcOnKatsRes({ projectId, katsResId }) {
		return this.client.get(this.urlMapper + "lsr_tc_mapper/" + projectId + "/" + katsResId + "/", apiBase.get_headers());
	},

	getErrorJustification(id) {
		return this.client.get(this.url + "error_justification/" + id + "/", apiBase.get_headers());
	},

	getInputXmlPartOnError(id) {
		return this.client.get(this.url + "xml/input/part_on_error/" + id + "/", apiBase.get_headers());
	},

	downloadPriceOffersXlsx({ taskId }) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.urlExpert + "download_price_offer_xlsx/" + taskId + "/", headers);
	},

	getInnKppMultiData({ inn, kpp }) {
		return this.client.get(this.urlExpert + "provider_data_multi/" + inn + "/" + kpp + "/", apiBase.get_headers());
	},
};

export default apiKatsEstimate;

import { useSelector } from "react-redux";

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";

const ProjectsRibbonSettings = ({
	addNewProject,
	addNewProjectPack,
	addNewProjectToPack,
	showDeleteDialog,
	enterToProject,
	enterToProjectImport,
	enterToLsrEditor,
	getProjectXlsx,
	getProjectProtocol,
	enableEditingMode,
	getProjectXmlZip,
}) => {
	const userPermissions = useSelector((state) => state.auth.permissions);
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);

	return [
		{
			name: "Действия",
			_children: [
				{
					orientation: "horizontal",
					_children: [
						{
							name: "Создать проект",
							icon: <AddBoxOutlinedIcon />,
							size: "large",
							type: "common",
							callback: () => {
								if (clickedTableRow?.id != null && clickedTableRow?.model_name === "UserProjectPacks") {
									addNewProjectToPack({ rowObj: clickedTableRow });
								} else {
									addNewProject();
								}
							},
						},
						{
							name: "Создать группу",
							icon: <CreateNewFolderOutlinedIcon />,
							size: "large",
							type: "common",
							callback: () => addNewProjectPack(),
						},
					],
				},
				{
					orientation: "vertical",
					_children: [
						{
							name: "Редактировать",
							icon: <EditOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: clickedTableRow?.id == null,
							callback: () => enableEditingMode(),
						},
						{
							name: "Перейти в проект",
							icon: <ExitToAppOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: clickedTableRow?.id == null || clickedTableRow?.model_name !== "UserProjects",
							callback: () => enterToProject({ targetId: clickedTableRow?.id }),
						},
						{
							name: "Удалить проект",
							icon: <DeleteOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: clickedTableRow?.id == null,
							callback: () => showDeleteDialog(),
						},
					],
				},
			],
		},
		{
			name: "Импорт",
			_children: [
				{
					orientation: "vertical",
					_children: [
						{
							name: "Импорт",
							icon: <FileUploadOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: clickedTableRow?.id == null || clickedTableRow?.model_name !== "UserProjects",
							callback: () => enterToProjectImport(),
							styles: { width: "90px" },
						},
					],
				},
			],
		},
		{
			name: "Редактор",
			_children: [
				{
					orientation: "vertical",
					styles: { flexDirection: "row" },
					_children: [
						{
							name: "Создать смету",
							icon: <NoteAddOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled:
								userPermissions.is_lsr_editors !== true ||
								clickedTableRow?.id == null ||
								clickedTableRow?.model_name !== "UserProjects",
							callback: () => enterToLsrEditor(),
						},
					],
				},
			],
		},
		{
			name: "Экспорт",
			styles: {},
			_children: [
				{
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Экспорт протокола",
							icon: <FileOpenOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: clickedTableRow?.id == null || clickedTableRow?.model_name !== "UserProjects",
							callback: () => getProjectProtocol(),
						},
						{
							name: "Печатная форма",
							icon: <PrintOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: clickedTableRow?.id == null || clickedTableRow?.model_name !== "UserProjects",
							callback: () => getProjectXlsx(),
						},
						{
							name: "Экспорт в XML",
							icon: <FileDownloadOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: clickedTableRow?.id == null || clickedTableRow?.model_name !== "UserProjects",
							callback: () => getProjectXmlZip(),
						},
					],
				},
			],
		},
	];
};

export default ProjectsRibbonSettings;

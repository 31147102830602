import { createSlice } from '@reduxjs/toolkit';

export const advSearchSlice = createSlice({
    name: 'adv_search',
    initialState: {
        activeRow: {},
        activeColorRow: {},
    },
    reducers: {
        setActiveRow: (state, { payload }) => {
            state.activeRow = payload;
        },
        setActiveColorRow: (state, { payload }) => {
            state.activeColorRow = payload;
        },
    },
});

export const { setActiveRow, setActiveColorRow } = advSearchSlice.actions;
export default advSearchSlice.reducer;

import { useEffect, useRef, useLayoutEffect, useState, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import NoDataBox from "components/NoDataBox";
import Lottie from "lottie-react";
import animationData from "imgs/Anim3.json";

import { KatsEstmFooterSignatures, KatsExpertFilterModal } from "./components";
import { ResourceMappingModal } from "pages/ResourceSheet/components/resourceMappingModal/ResourceMappingModal";

import {
	getKatsTree,
	getProjectKatsTree,
	changeKatsVisible,
	changeKatsDetailsVisible,
	changeKatsPaperDetailsVisible,
	changeKatsErrorDetailsVisible,
	setKatsData,
	selectData,
	changeFiltredRowData,
	setKatsFetchStatus,
	setFiltredData,
	applyAdvSearchSelected,
} from "slices/kats";
import { setFetchStatusText } from "slices/project_work";
import { setReGetProtocol, postUserSetExcept } from "slices/protocol";
import { setCurrentPage, setCurrentEstmDocId, setClickedTableRow, setClickedTableClmName } from "slices/ribbon";
import { setActiveRow } from "slices/adv_search";

import { KatsTableColumns, KatsDetailsPaper } from "./KatsTableColumns";

import apiKatsEstimate from "api/kats";

import { downloadFile } from "core/request_utils";
import { isFileNamePdf } from "core/file_utils";
import { sortObjArrByNumberAsStr } from "core/iterable_utils";
import useTable from "hooks/useTable.js";
import { RecourseCalcPosition, ScrollLocEstmToPos } from "../components/AdvSearchBox/apply_adv_search";
import { KsrDrawer } from "./components/KsrDrawer/KsrDrawer";
import CustomDetailsComments from "pages/EstimateViewer/LocalEstimateViewer/components/CustomDetails/CustomDetailsComments";
import { calcRowHeightMultiplier } from "../LocalEstimateViewer/utils/row_utils";

import "pages/EstimateViewer/TableOuterBox.css";
import "./KatsViewer.css";

const SlideDrawer = lazy(() => import("components/Drawer/Drawer.tsx"));
const PdfPreviewModel = lazy(() => import("components/PdfPreviewModel"));
const KaDiffChoiceModal = lazy(() => import("./components/KaDiffChoiceModal/KaDiffChoiceModal"));
const InnKppModal = lazy(() => import("./components/InnKppModal/InnKppModal"));
const JustificationUnionModalBox = lazy(() => import("pages/EstimateViewer/components/JustificationModalBox/JustificationUnionModalBox"));

export const KatsViewer = (props) => {
	const headBlock = useRef();

	const dispatch = useDispatch();
	const rowsData = useSelector((state) => state.kats.data);
	const filtredData = useSelector((state) => state.kats.filtredData);
	const headerData = useSelector((state) => state.kats.headerData);
	const detailsClms = useSelector((state) => state.kats.detailsClms);
	const filterParams = useSelector((state) => state.kats.filterParams);

	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const currentEstmDocId = useSelector((state) => state.ribbon.currentEstmDocId);
	const lastActiveScroll = useSelector((state) => state.ribbon.lastActiveScroll);
	const lastEstimateCurrentPage = useSelector((state) => state.ribbon.lastEstimateCurrentPage);
	const currentPage = useSelector((state) => state.ribbon.currentPage);

	const originColumnsData = KatsTableColumns({ fileName: false });
	const [columnsData, setColumnsData] = useState([]);
	const [justPdfVisible, setJustPdfVisible] = useState(false);
	const [pdfBlobData, setPdfBlobData] = useState(null);
	const [pdfBlobName, setPdfBlobName] = useState("file.pdf");
	const advSearchActiveColorRow = useSelector((state) => state.advSearch.activeColorRow);
	const advSearchActiveRow = useSelector((state) => state.advSearch.activeRow);
	const reGetProtocol = useSelector((state) => state.protocol.reGetProtocol);
	const [kaDiffChoiceModalVisible, setKaDiffChoiceModal] = useState(false);
	const [innKppInfoVisible, setInnKppInfoVisible] = useState(false);
	const [innKppInfoData, setInnKppInfoData] = useState({});
	const [clmsSettingsInit, setClmsSettingsInit] = useState(false);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [resInLsrsVisible, setResInLsrsVisible] = useState(false);
	const [resInLsrsTableData, setResInLsrsTableData] = useState([]);
	const [justBoxVisible, setJustBoxVisible] = useState(false);
	const [justData, setJustData] = useState(null);
	const [justXmlData, setJustXmlData] = useState(null);

	const { id: projectId, katsId } = useParams();

	useLayoutEffect(() => {
		dispatch(setCurrentPage("KatsEstimateViewer"));
		dispatch(setCurrentEstmDocId(katsId));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setPreviewVisible(true);
		if (katsId == null) {
			dispatch(getProjectKatsTree(projectId));
		} else {
			const prom = dispatch(getKatsTree(katsId));
			prom.then(() => {
				dispatch(applyAdvSearchSelected());
			}).finally(() => {
				setPreviewVisible(false);
				if (katsId === currentEstmDocId && filtredData.length && lastEstimateCurrentPage !== "ProtocolViewer") {
					props.scrollBlock.current.scrollTop = lastActiveScroll;
				}
			});
		}

		dispatch(setCurrentEstmDocId(katsId));
		return () => {
			dispatch(setKatsData([]));
			setPdfBlobData(null);
			setPreviewVisible(false);
		};
	}, [projectId, katsId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (reGetProtocol === true) {
			dispatch(getKatsTree(katsId));
			dispatch(setReGetProtocol(false));
		}
	}, [reGetProtocol]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		props.setColumnsSettings(props.allColumnsSettings.filter((x) => x.checked === true));
	}, [props.allColumnsSettings]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const filterClms = [];
		for (let clm of originColumnsData) {
			if (["check", "expert_valid"].includes(clm.name)) {
				filterClms.push(clm);
			} else {
				const clmSet = props.columnsSettings.find((x) => x.id === clm.name);
				if (clmSet) {
					filterClms.push(clm);
				}
			}
		}
		setColumnsData(filterClms);
		setClmsSettingsInit(true);
	}, [props.columnsSettings]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!clmsSettingsInit) return;
		dispatch(
			setFiltredData(
				rowsData.filter((row) => {
					if (row.expert_valid === true && filterParams._true === true) {
						return true;
					} else if (row.expert_valid === false && filterParams._false === true) {
						return true;
					} else if (row.expert_valid === null && filterParams._null === true) {
						return true;
					}
					return false;
				})
			)
		);
	}, [filterParams._true, filterParams._false, filterParams._null, rowsData, clmsSettingsInit]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (currentPage !== "KatsEstimateViewer") return;
		if (!clmsSettingsInit) return;

		if (rowsData.length && Object.keys(advSearchActiveRow).length && props.scrollBlock["current"] != null) {
			const { rowCnt, isFound } = RecourseCalcPosition(rowsData, advSearchActiveRow, {
				rowCnt: 0,
				isFound: false,
			});
			if (isFound) {
				ScrollLocEstmToPos(props.scrollBlock, rowCnt, 310);
				setActiveRow({});
			}
		}
	}, [advSearchActiveRow, rowsData, props.scrollBlock, advSearchActiveColorRow, clmsSettingsInit]); // eslint-disable-line react-hooks/exhaustive-deps

	const getJustFile = ({ offerId, fileName, fileId, rowObj, rowObjParent }) => {
		let arg = {};
		if (offerId == null) {
			if (rowObjParent?.source_offer_id != null) {
				arg = { offerId: rowObjParent.source_offer_id, fileId: fileId, projectId: rowObjParent.project_id };
			} else {
				return;
			}
		} else {
			arg = { offerId: offerId, fileId: fileId, projectId: 0 };
		}

		dispatch(setKatsFetchStatus("loading"));
		const prom = apiKatsEstimate.getJustFile(arg);
		prom.then((response) => {
			if (isFileNamePdf({ fileName })) {
				setPdfBlobName(fileName);
				setPdfBlobData(response.data);
				setJustPdfVisible(true);
			} else {
				downloadFile(response);
			}
			dispatch(setKatsFetchStatus("success"));
		}).catch((error) => {
			console.error(error);
			if (error?.response?.status === 400) {
				dispatch(setFetchStatusText("Запрошенное обоснование не загружено на сервер"));
				dispatch(setKatsFetchStatus("failed"));
			} else {
				dispatch(setKatsFetchStatus("success"));
			}
		});
	};

	props.lowerCallbacks.current.kats["getJustFile"] = getJustFile;

	const deleteExpertOffer = () => {
		dispatch(setKatsFetchStatus("loading"));
		const prom = apiKatsEstimate.deleteExpertOffer({ offerId: clickedTableRow?.parentRow?.id });
		prom.then((response) => {
			dispatch(getKatsTree(katsId));
			dispatch(setKatsFetchStatus("success"));
		}).catch((error) => {
			dispatch(setKatsFetchStatus("failed"));
		});
	};

	props.lowerCallbacks.current.kats["deleteExpertOffer"] = deleteExpertOffer;

	const openKaDiffModal = () => {
		setKaDiffChoiceModal(true);
	};

	props.lowerCallbacks.current.kats["openKaDiffModal"] = openKaDiffModal;

	const KatsEstmSelectData = (table, row, target, parentRow) => {
		const clmName = target?.attributes?.clm?.value;
		dispatch(setClickedTableRow({ ...row, parentRow: parentRow }));
		dispatch(selectData(row));
		dispatch(setClickedTableClmName(clmName));
	};

	const saveTableColumnsSettings = () => {
		const copy = [...props.allColumnsSettings];
		const body = [];
		for (let clmCopy of copy) {
			const elm = { ...clmCopy };
			if (props.columnsSettings.find((x) => x.id === clmCopy.id)) {
				elm.checked = true;
			} else {
				elm.checked = false;
			}
			body.push(elm);
		}
		dispatch(setKatsFetchStatus("loading"));
		const prom = apiKatsEstimate.saveColumnsSettings({ body });
		prom.then((response) => {
			dispatch(setKatsFetchStatus("success"));
		}).catch((error) => {
			dispatch(setKatsFetchStatus("failed"));
		});
	};
	props.lowerCallbacks.current.kats["saveTableColumnsSettings"] = saveTableColumnsSettings;

	const showComments = ({ val, targetRowObj }) => {
		let rowObj = clickedTableRow;
		if (targetRowObj) rowObj = targetRowObj;

		if (val === true) {
			dispatch(setKatsFetchStatus("loading"));
			const prom = apiKatsEstimate.getRowComments({ estmId: katsId, key: rowObj.key });
			prom.then((response) => {
				dispatch(
					changeFiltredRowData({
						rowKey: rowObj.key,
						callback: (x) => {
							x.showComments = true;
							x._comments = response.data;
							if (response.data.length > 0) {
								x._has_comment = true;
							} else {
								x._has_comment = false;
							}
							return x;
						},
					})
				);
				dispatch(setClickedTableRow({ ...rowObj, showComments: true }));
				dispatch(setKatsFetchStatus("success"));
			}).catch((error) => {
				console.error(error);
				dispatch(setKatsFetchStatus("failed"));
			});
		} else if (val === false) {
			dispatch(
				changeFiltredRowData({
					rowKey: rowObj.key,
					callback: (x) => {
						x.showComments = false;
						return x;
					},
				})
			);
			dispatch(setClickedTableRow({ ...rowObj, showComments: false }));
		}
	};

	props.lowerCallbacks.current.kats["showComments"] = showComments;

	const customRowDetailFactory = ({ rowObj, options, funcs }) => {
		if (rowObj.showComments === true) {
			return (
				<CustomDetailsComments
					rowObj={rowObj}
					colSpan={options?.columnsData?.length}
					options={options}
					funcs={funcs}
					setVisible={(val, targetRowObj) => showComments({ val: val, targetRowObj: targetRowObj })}
					api={apiKatsEstimate}
					postInitBody={{
						project_id: projectId,
						estm_id: options?.katsId,
						section_id: rowObj.section_id === "" ? null : rowObj.section_id,
						item_id: rowObj.item_id === "" ? null : rowObj.item_id,
						key: rowObj.key,
					}}
					setFetchStatus={(val) => dispatch(setKatsFetchStatus(val))}
					setFetchStatusText={(text) => dispatch(setFetchStatusText(text))}
					successPostAct={() => {
						showComments({ val: true, targetRowObj: rowObj });
					}}
				/>
			);
		}
	};

	const handleAddException = (row) => {
		const body = {
			user_id: localStorage.getItem("userId"),
			project_id: projectId,
			except_str: row["state_text"],
			parent_key: row?.parentRow?.key,
		};
		const promise = dispatch(postUserSetExcept(body));
		promise
			.then((x) => dispatch(getKatsTree(katsId)))
			.then((data) => dispatch(changeKatsErrorDetailsVisible({ val: true, row: row.parentRow })))
			.catch((err) => console.error(err));
	};

	const showMoreRow = (row) => {
		const rowHeightMultiplier = calcRowHeightMultiplier(row, columnsData);
		dispatch(
			changeFiltredRowData({
				rowKey: row.key,
				callback: (x) => {
					if (x.rowHeightMultiply == null && rowHeightMultiplier > 1) {
						x.rowHeightMultiply = rowHeightMultiplier;
					} else {
						x.rowHeightMultiply = null;
					}
				},
			})
		);
	};

	const gerResInLsrs = () => {
		if (!clickedTableRow?.id) return;
		const prom = apiKatsEstimate.getProjectLsrTcOnKatsRes({ projectId, katsResId: clickedTableRow?.id });
		dispatch(setKatsFetchStatus("loading"));
		prom.then((response) => {
			setResInLsrsTableData(response.data);
			dispatch(setKatsFetchStatus("success"));
			setResInLsrsVisible(true);
		}).catch((err) => {
			console.error(err);
			dispatch(setKatsFetchStatus("failed"));
		});
	};
	props.lowerCallbacks.current.kats["gerResInLsrs"] = gerResInLsrs;

	const showErrorJustification = (row) => {
		dispatch(setKatsFetchStatus("loading"));
		const justPromise = new Promise((resolve, reject) => {
			apiKatsEstimate
				.getErrorJustification(row.id)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.message));
		});
		const xmlPromise = new Promise((resolve, reject) => {
			apiKatsEstimate
				.getInputXmlPartOnError(row.id)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.message));
		});
		Promise.all([justPromise, xmlPromise])
			.then((response_arrs) => {
				setJustData(response_arrs[0]);
				setJustBoxVisible(true);
				setJustXmlData(response_arrs[1]);
				dispatch(setKatsFetchStatus("success"));
			})
			.catch((error) => {
				console.error(error);
				dispatch(setKatsFetchStatus("failed"));
			});
	};
	props.lowerCallbacks.current.kats["showErrorJustification"] = showErrorJustification;

	const tableBody = useTable(
		filtredData,
		columnsData,
		{
			selectable: true,
			scrollBlock: props.scrollBlock,
			headBlock: headBlock,
			headScrollControllBlock: null,
			detailsClms: detailsClms,
			addH: 85,
			paperSettings: KatsDetailsPaper({ setInnKppInfoVisible, setInnKppInfoData }),
			tableType: "kats",
			scrollHeightControll: 100,
			advSeachActiveRow: advSearchActiveColorRow,
			originColumnsData: originColumnsData,
			customRowDetail: {
				Jsx: ({ rowObj, options, funcs }) => customRowDetailFactory({ rowObj, options, funcs }),
				emptyRowCount: ({ rowObj }) => 11,
				allowAdding: ({ rowObj }) => (rowObj.showComments === true ? true : false),
			},
		},
		{
			changeVisible: changeKatsVisible,
			changeDetailsVisible: changeKatsDetailsVisible,
			changeErrorsDetailsVisible: changeKatsErrorDetailsVisible,
			changePaperDetailsVisible: changeKatsPaperDetailsVisible,
			selectDataLocal: KatsEstmSelectData,
			changeRowData: changeFiltredRowData,
			showComments: showComments,
			addExcept: handleAddException,
			showErrorJustification: showErrorJustification,
			updateData: (data) => {
				dispatch(setFiltredData(data));
			},
			getJustFile: getJustFile,
			changeVisibleOptions: {
				tagName: {
					allowed: ["svg", "path"],
				},
			},
			customSortDataUp: {
				num: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: clmName, direction: "up" }),
				cost: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: clmName, direction: "up" }),
				project_weight: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: "project_weight_percent", direction: "up" }),
			},
			customSortDataDown: {
				num: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: clmName, direction: "down" }),
				cost: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: clmName, direction: "down" }),
				project_weight: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: "project_weight_percent", direction: "down" }),
			},
			singleClickRowAction: {
				action: showMoreRow,
			},
		},
		{
			notClosedOptions: {
				advSeachActiveRow: advSearchActiveColorRow,
				columnsData: columnsData,
				katsId: katsId,
			},
		}
	);

	if (!clmsSettingsInit) return;

	if (rowsData == null) {
		return <NoDataBox text="конъюнктурном анализе цен" />;
	} else if (rowsData.length === 0) {
		return (
			<>
				{previewVisible && (
					<section className="KatsPreviewAnimation">
						<Lottie animationData={animationData} loop={true} autoplay={true} speed={0.5} style={{ height: 200 }} />
						<span className="KatsPreviewAnimationText">Подождите пожалуйста, формируется конъюнктурный анализ...</span>
					</section>
				)}
			</>
		);
	} else if (rowsData.length > 0) {
		return (
			<>
				<div className="TableOuterBox" type="Kats" ref={headBlock}>
					<div className="TableOuterHeadMainTitle">
						<div className="TitleLeft"></div>
						<div className="TitleCenter">КОНЪЮНКТУРНЫЙ АНАЛИЗ ЦЕН</div>
					</div>
					{tableBody}
					{katsId != null && <KatsEstmFooterSignatures data={headerData} />}
					{justPdfVisible && (
						<Suspense>
							<PdfPreviewModel blobData={pdfBlobData} blobName={pdfBlobName} setVisible={setJustPdfVisible}></PdfPreviewModel>
						</Suspense>
					)}
					{kaDiffChoiceModalVisible && (
						<Suspense>
							<KaDiffChoiceModal setVisible={setKaDiffChoiceModal} katsId={katsId}></KaDiffChoiceModal>
						</Suspense>
					)}
					{filterParams.visible && <KatsExpertFilterModal />}
					{innKppInfoVisible && (
						<Suspense>
							<InnKppModal setVisible={setInnKppInfoVisible} requestData={innKppInfoData} />
						</Suspense>
					)}
					{resInLsrsVisible && (
						<ResourceMappingModal
							setResourceMappingModalVisible={setResInLsrsVisible}
							tableData={resInLsrsTableData}
							setTableData={setResInLsrsTableData}
							ResourceMappingItem={clickedTableRow}
							hideProject={true}
						/>
					)}
					{justBoxVisible && (
						<Suspense>
							<JustificationUnionModalBox justData={justData} xmlData={justXmlData} estmId={katsId} f_visible={setJustBoxVisible} />
						</Suspense>
					)}
				</div>

				{props.ksrVisible && (
					<Suspense fallback={" "}>
						<SlideDrawer
							isVisible={props.ksrVisible}
							direction={"left"}
							width={"55%"}
							top={"143px"}
							content={() => (
								<KsrDrawer
									setVisible={props.setKsrVisible}
									displayOptions={{ tree: true, table: true }}
									searchOptions={{ levels: [4, 5] }}
								/>
							)}
						/>
					</Suspense>
				)}
			</>
		);
	}
};

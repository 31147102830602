export default function IndexesGosrTableColumns() {
    return [
        {
            name: "code",
            title: "Код ресурса",
            width: "120px",
            cellHorAlign: "right",
            headHorAlign: "center",
            rowSpan: "2",
            sort: false,
            filter: false,
            editable: false,
        }, {
            name: "name",
            title: "Наименование строительного ресурса",
            width: "auto",
            cellHorAlign: "left",
            headHorAlign: "left",
            rowSpan: "2",
            sort: false,
            filter: false,
            editable: false,
        }, {
            name: "unit",
            title: "Единица измерения",
            width: "70px",
            cellHorAlign: "center",
            headHorAlign: "center",
            rowSpan: "2",
            sort: false,
            filter: false,
            editable: false,
        }, {
            name: "cost",
            title: "Цена по состоянию на 01.01.2022 г., руб.",
            width: "340px",
            cellHorAlign: "center",
            headHorAlign: "center",
            headHeight: "10px",
            colSpan: "2",
            children: [
                {
                    name: "opt_cost_2022",
                    title: "Отпускные базисные цены",
                    width: "170px",
                    cellHorAlign: "right",
                    headHorAlign: "center",
                    headHeight: "10px"
                },
                {
                    name: "cost_2022",
                    title: "Сметные базисные цены",
                    width: "170px",
                    cellHorAlign: "right",
                    headHorAlign: "center",
                    headHeight: "10px"
                }
            ]
        }, {
            name: "cost_cur",
            title: "Сметная цена в тек. уровне цен, руб.",
            width: "150px",
            cellHorAlign: "right",
            headHorAlign: "center",
            rowSpan: "2",
            sort: false,
            filter: false,
            editable: false,
        }, {
            name: "index",
            title: "Индекс",
            width: "70px",
            cellHorAlign: "center",
            headHorAlign: "center",
            rowSpan: "2",
            sort: false,
            filter: false,
            editable: false,
        }
    ];
}
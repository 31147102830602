import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import KsprButton from "components/UI/buttons/KsprButton";
import AlertLoading from "components/AlertLoading";
import apiQuestion from "api/question";

import { setQuestionVisible } from "slices/global_visible";

import "./QuestionBox.css";
import Draggable from "react-draggable";
import { Alert } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import { MaxFileSizeEnum } from "core/enums";

export default function QuestionBox(props) {
    const inputFile = useRef();
    const textQuestion = useRef();
    const inputEmail = useRef();
    const dispatch = useDispatch();

    const [uploadFile, setUploadFile] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [uploadFileName, setUploadFileName] = useState("Файл не загружен");
    const [fetchStatus, setFetchStatus] = useState("");
    const [fetchStatusText, setFetchStatusText] = useState("");

    useEffect(() => {
        if (uploadFile) {
            setShowAlert(true);
        }
    }, [uploadFile]);

    useEffect(() => {
        if (showAlert) {
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, 1250);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [showAlert]);

    const clickInputFile = () => {
        inputFile.current.click();
    };

    const postQuestion = () => {
        if (uploadFile?.size > MaxFileSizeEnum.QUESTION) {
            setFetchStatus("failed");
            setFetchStatusText("Размер файла превышает допустимый");
        } else if (inputEmail.current.value === "") {
            setFetchStatus("failed");
            setFetchStatusText("Укажите адрес электронной почты");
        } else {
            setFetchStatus("loading");
            const prom = apiQuestion.add(uploadFile, textQuestion.current.value, inputEmail.current.value);
            prom.then((response) => {
                setFetchStatus("successAlert");
                setFetchStatusText("Вопрос отправлен");
                textQuestion.current.value = "";
                setUploadFile();
                setUploadFileName("Файл не загружен");
            }).catch((err) => {
                console.error(err);
                setFetchStatus("failed");
                setFetchStatusText("Ошибка");
            });
        }
    };

    const [uploadBoxClassname, setUploadBoxClassname] = useState("QuestionBox");

    const handleDrag = (e, type) => {
        e.preventDefault();
        e.stopPropagation();
        if (type === "enter") {
            setUploadBoxClassname("QuestionBox Drag");
        } else if (type === "leave") {
            setUploadBoxClassname("QuestionBox");
        }
    };

    const handlePaste = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.type.indexOf("image") !== -1) {
                const file = item.getAsFile();
                setUploadFile(file);
                setUploadFileName(file.name);
                // Прерываем обработку события, чтобы изображение не вставлялось как обычный текст
                e.preventDefault();
                break; // Может быть несколько изображений, но мы обрабатываем только первое
            }
        }
    };

    return (
        <Draggable handle=".QuestionBoxHeader">
            <Dialog className="QuestionBoxWrapper"
                open
                slotProps={{ backdrop: { style: { backgroundColor: "transparent" } }, }}>
                <div
                    className={uploadBoxClassname}
                    onDragEnter={(e) => {
                        handleDrag(e, "enter");
                    }}
                    onDragOver={(e) => {
                        handleDrag(e, "enter");
                    }}
                    onDragLeave={(e) => {
                        handleDrag(e, "leave");
                    }}
                    onDrop={(e) => {
                        handleDrag(e, "leave");
                        const file = e.dataTransfer.files[0];
                        setUploadFile(file);
                        setUploadFileName(file.name);
                    }}>
                    <div className="QuestionBoxHeader">
                        <div className="QuestionBoxHeaderLabel">Форма обратной связи с разработчиками</div>
                    </div>
                    <div className="QuestionBoxBody">
                        <div className="QuestionBoxTextArea">
                            <input
                                className="UserEmail"
                                placeholder="Укажите адрес электронной почты"
                                ref={inputEmail}></input>
                            <textarea
                                placeholder="Введите текст вопроса и приложите файл, при загрузке которого возникла ошибка или скриншот с ошибкой "
                                ref={textQuestion}
                                onPaste={handlePaste}></textarea>
                        </div>
                        <div className="QuestionBoxFileArea">
                            <div className="QuestionBoxFileAreaLeft">
                                <label htmlFor="UploadFile">
                                    <input
                                        ref={inputFile}
                                        style={{ display: "none" }}
                                        id="UploadFile"
                                        type="file"
                                        value=""
                                        onChange={(e) => {
                                            setUploadFile(e.target.files[0]);
                                            setUploadFileName(e.target.files[0].name);
                                        }}
                                    />
                                    <KsprButton
                                        variant="outlined"
                                        label="ЗАГРУЗИТЕ ФАЙЛ"
                                        onClick={clickInputFile}
                                        startIcon={<UploadFileOutlinedIcon />}
                                    />
                                </label>
                            </div>
                            <div className="QuestionBoxFileAreaRight">
                                <div className="QuestionBoxFileName" uploadfile={uploadFile == null ? "empty" : "fill"}>
                                    {uploadFileName}
                                </div>
                                <div className="QuestionBoxFileComment">Максимально допустимый размер файла 15 Mb</div>
                            </div>
                        </div>
                    </div>
                    <div className="QuestionBoxFooter">
                        <KsprButton variant="text" label="Отмена" onClick={() => dispatch(setQuestionVisible(false))} />
                        <KsprButton variant="text" label="Отправить" onClick={postQuestion} />
                    </div>

                    <AlertLoading
                        fetchStatus={fetchStatus}
                        func={[setFetchStatus]}
                        fetchStatusText={fetchStatusText}
                        isLocal={true}
                    />
                    {showAlert && <Alert severity="success">Файл прикреплен</Alert>}
                </div>
            </Dialog>
        </Draggable>
    );
}

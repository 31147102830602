const LabelCommon = ({ text, style }) => {
    const defaultStyle = {};
    const applyingStyle = { ...defaultStyle, ...style };

    return (
        <div className="LabelCommon" style={applyingStyle} title={text}>
            {text}
        </div>
    );
};

export { LabelCommon };

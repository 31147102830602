import { useEffect, useState } from "react";

import KsprButton from "components/UI/buttons/KsprButton";

import apiLocalEstimate from "api/local_estimate";
import { copyToClipboard } from "core/clipboard_utils";

import "./CostSourceFormulas.css";
import Draggable from "react-draggable";

const CostSourceFormulas = (props) => {
    const row = props.data.row;
    const event = props.data.event;
    const [strFormulas, setStrFormulas] = useState("");

    useEffect(() => {
        const prom = apiLocalEstimate.getCostSourceFormulas(row.id);
        prom.then((response) => {
            setStrFormulas(response.data.formulas);
        }).catch((err) => {
            console.error(err);
        });
    }, [row.id]);

    if (strFormulas == null) return;
    if (!strFormulas.length) return;

    return (
        <Draggable handle=".CostSourceFormulasHeader">
            <div className="CostSourceFormulas" style={{ top: event.clientY, left: event.clientX }}>
                <div className="CostSourceFormulasHeader">Формула</div>
                <div className="CostSourceFormulasBody">{strFormulas}</div>
                <div className="CostSourceFormulasFooter">
                    <KsprButton variant="text" label="Копировать" onClick={() => copyToClipboard(strFormulas)} />
                    <KsprButton variant="text" label="Закрыть" onClick={props.f_closeBox} />
                </div>
            </div>
        </Draggable>

    );
};

export default CostSourceFormulas;

import { useSelector } from "react-redux";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { IconButton, Tooltip } from "@mui/material";

import { useLocalStorage } from "hooks/useLocalStorage";

import "./CommentsBoxRow.css";

const CommentsBoxRow = ({ rowObj, handleClick, handleDelete }) => {
	const userPermissions = useSelector((state) => state.auth.permissions);
	const { getLsItem } = useLocalStorage();

	return (
		<div className="CommentsBoxRow">
			<div className="CommentsBoxRowTop">
				<div
					className="CommentsBoxRowUsername"
					onClick={(e) => handleClick({ clickedRow: rowObj })}
				>
					{rowObj.username}
				</div>
				<div
					className="CommentsBoxRowDate"
					onClick={(e) => handleClick({ clickedRow: rowObj })}
				>
					{rowObj.date_create}
				</div>
				<div className="CommentsBoxRowDelete">
					<Tooltip title="Удалить">
						<IconButton
							aria-label="Удалить"
							disabled={
								rowObj.username !==
									getLsItem({ key: "userFio" }) &&
								!userPermissions.is_admin
							}
							onClick={() => handleDelete({ clickedRow: rowObj })}
						>
							<DeleteOutlinedIcon />
						</IconButton>
					</Tooltip>
				</div>
			</div>
			<div
				className="CommentsBoxRowBottom"
				title={rowObj.text}
				onClick={(e) => handleClick({ clickedRow: rowObj })}
			>
				<div className="CommentsBoxRowText">{rowObj.text}</div>
			</div>
		</div>
	);
};

export default CommentsBoxRow;

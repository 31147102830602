import dayjs from "dayjs";

export const getCurrentStrDate = () => {
    const now = new Date();
    return dayjs(`${now.getFullYear()}-${now.getMonth()}-${now.getDay()}`);
};

export const parseDateFromStr = (str, format) => {
    return dayjs(str, format);
};

export const stringifyDayjs = (str, format) => {
    return dayjs(str).format(format);
};

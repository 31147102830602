import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import TextField from '@mui/material/TextField';
import { IconButton, Tooltip } from "@mui/material";

import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import { useLocalStorage } from "hooks/useLocalStorage";

import "./CommentsBoxRow.css";

const CommentsBoxAdvRow = ({ rowObj, handleSave }) => {
    const userPermissions = useSelector((state) => state.auth.permissions);

    const [text, setText] = useState("");

    const { getLsItem } = useLocalStorage();

    useEffect(() => {
        setText(rowObj.text);
    }, [rowObj])

    return <div className="CommentsBoxAdvRow">
        <div className="CommentsBoxRowTop">
            <div className="CommentsBoxRowUsername">{rowObj.username}</div>
            <div className="CommentsBoxRowDate">{rowObj.date_create}</div>
        </div>
        <div className="CommentsBoxAdvRowBottom" title={rowObj.text}>
            <div className="CommentsBoxRowText">
                <TextField
                    id="standard-multiline-static"
                    label=""
                    multiline
                    rows={7}
                    value={text}
                    variant="standard"
                    inputProps={{
                        style: { fontSize: 13 },
                        disabled: rowObj.id == null || (rowObj.username !== getLsItem({ key: "userName" }) && !userPermissions.is_admin)
                    }}
                    onChange={(e) => setText(e.target.value)}
                />
            </div>
            <div className="CommentsBoxAdvButtons">
                <Tooltip title="Отменить">
                    <IconButton
                        aria-label="Отменить"
                        disabled={rowObj.text === text}
                        onClick={() => setText(rowObj.text)}>
                        <UndoOutlinedIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Сохранить">
                    <IconButton
                        aria-label="Сохранить"
                        disabled={rowObj.text === text}
                        onClick={() => {
                            handleSave({ commentObj: rowObj, text: text })
                        }
                        }>
                        <SaveOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    </div>
}

export default CommentsBoxAdvRow;
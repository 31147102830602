import Chip from "@mui/material/Chip";

import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import FlareOutlinedIcon from "@mui/icons-material/FlareOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";

import "./CustomNorthTerrCell.css";

const CustomNorthTerrCell = ({ rowObj, funcs }) => {
	const cellValue = rowObj.north_reg_terr_id == null ? "" : rowObj.north_reg_terr_id.toString();

	const getCurrentNorthRegTerrsId = () => {
		if (rowObj.north_reg_terr_id === 1) return 2;
		else if (rowObj.north_reg_terr_id === 2) return 3;
		else if (rowObj.north_reg_terr_id === 3) return 1;
	};

	const handleClick = () => {
		funcs.editDataFactory({ rowObj: rowObj, dataObj: { north_reg_terr_id: getCurrentNorthRegTerrsId() } });
	};

	const getNorthRegTerrsJsx = () => {
		if (cellValue === "1")
			return <Chip onClick={handleClick} icon={<WbSunnyOutlinedIcon />} label="Обычная" variant="outlined" size="small" clickable />;
		else if (cellValue === "2")
			return <Chip onClick={handleClick} icon={<FlareOutlinedIcon />} label="МПРКС" variant="outlined" size="small" clickable />;
		else if (cellValue === "3")
			return <Chip onClick={handleClick} icon={<AcUnitOutlinedIcon />} label="РКС" variant="outlined" size="small" clickable />;
	};

	return (
		<td key={`cell-north_reg_terr_id-${rowObj.id}`} className="TableCell" clm="north_reg_terr_id" treecell="false" title={cellValue}>
			<div title={cellValue} className="northRegTerrsCell" style={{ display: "flex", alignItems: "center" }}>
				{getNorthRegTerrsJsx()}
			</div>
		</td>
	);
};

export default CustomNorthTerrCell;

import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import NoDataBox from "components/NoDataBox";

import JustTableColumns from "./JustTableColumns";
import KsprButton from "components/UI/buttons/KsprButton";
import useTable from "hooks/useTable.js";
import { MaxFileSizeEnum } from "core/enums";

import "./UploadJustModel.css";

const UploadJustModel = ({ setKatsJustUploadVisible, justStatusApi, uploadCallback, katsJustUploadCnt, footerButtonAction }) => {
	const currentEstmDocId = useSelector((state) => state.ribbon.currentEstmDocId);
	const [uploadBoxClassname, setUploadBoxClassname] = useState("UploadBox");
	const [tableData, setTableData] = useState(null);
	const scrollBlock = useRef(null);

	const changeChildrenVisible = ({ clickedRow }) => {
		setTableData((prev) =>
			prev.map((row) => {
				if (row.key === clickedRow.key) {
					row.childs_visible = !row.childs_visible;
					row._children = row._children.map((ch) => {
						ch.visible = !ch.visible;
						return ch;
					});
				}
				return row;
			})
		);
	};

	const selectDataLocal = (tableData, clickedRow) => {
		setTableData((prev) =>
			prev.map((row) => {
				if (row.key === clickedRow.key) {
					row.selected = true;
				} else {
					row.selected = false;
				}
				return row;
			})
		);
	};

	const columnsData = JustTableColumns({ changeChildrenVisible });

	useEffect(() => {
		const prom = justStatusApi.getJustStatus({ estmId: currentEstmDocId });
		prom.then((response) => setTableData(response.data));
	}, [currentEstmDocId, katsJustUploadCnt, justStatusApi]);

	const handleDrag = (e, type) => {
		e.preventDefault();
		e.stopPropagation();
		if (type === "enter") {
			setUploadBoxClassname("UploadBox Drag");
		} else if (type === "leave") {
			setUploadBoxClassname("UploadBox");
		}
	};

	const tableBody = useTable(
		tableData,
		columnsData,
		{
			selectable: true,
			scrollBlock: scrollBlock,
			scrollHeightControll: 10,
			displayScrollDelay: 10,
		},
		{
			updateData: (data) => setTableData(data),
			selectDataLocal: (tableData, clickedRow) => selectDataLocal(tableData, clickedRow),
		}
	);

	const getTable = () => {
		if (tableData) {
			if (tableData.length === 0) {
				return (
					<NoDataBox
						label="Не загружены ценовые обоснования"
						firstText="Для загрузки укажите файлы обоснований или перенесите файлы на эту страницу"
						secondText=" "
					/>
				);
			} else {
				return tableBody;
			}
		}
	};

	return (
		<Draggable handle=".UploadModalHead">
			<div className="UploadModal">
				<div className="UploadModalHead">
					<div className="UploadModalHeadLeft">Загрузка обоснований</div>
					<div className="UploadModalHeadRight">
						<UploadFileOutlinedIcon />
						<span>Для загрузки</span>
						<label htmlFor="UploadModal">
							<input
								style={{ display: "none" }}
								id="UploadModal"
								name="UploadModal"
								type="file"
								multiple
								value=""
								onChange={(e) => uploadCallback({ files: e.target.files })}
							/>
							<span className="ImportLink">укажите файлы</span>
						</label>
						<span>обоснований или перенесите файлы на эту страницу</span>
						<span className="ImportComment">{`При передачи архива, используйте только формат ZIP (max. ${
							MaxFileSizeEnum.KATS_JUST / 1024 / 1024
						} MB)`}</span>
					</div>
					<IconButton
						className="UploadModalClose"
						onClick={(evt) => {
							evt.preventDefault();
							setKatsJustUploadVisible(false);
						}}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>

				<div className="Layout">
					<div
						className={uploadBoxClassname}
						onDragEnter={(e) => handleDrag(e, "enter")}
						onDragOver={(e) => handleDrag(e, "enter")}
						onDragLeave={(e) => handleDrag(e, "leave")}
						onDrop={(e) => {
							handleDrag(e, "leave");
							uploadCallback({ files: e.dataTransfer.files });
						}}
					>
						<div className="TableLayout" ref={scrollBlock}>
							{getTable()}
						</div>
					</div>
				</div>

				{footerButtonAction && (
					<div className="UploadModalFooter">
						<div className="btnWrapper">
							<KsprButton
								sx={{ ml: "auto" }}
								variant="text"
								disabled={!tableData?.find((x) => x.selected === true)}
								label={footerButtonAction.buttonName}
								onClick={() => {
									footerButtonAction.callback({ selectedJust: tableData?.find((x) => x.selected === true) });
									setKatsJustUploadVisible(false);
								}}
							/>
						</div>
					</div>
				)}
			</div>
		</Draggable>
	);
};

export { UploadJustModel };

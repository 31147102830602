import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

import "./FsnbBodyNoData.css";

export default function FsnbBodyNoData(props) {
	let textNoData = "";
	if (props.tabValue === "norm") {
		textNoData = "В этом разделе содержания нет таблиц. Выберите раздел, содержащий таблицы.";
	} else if (props.tabValue === "coef") {
		textNoData =
			"В этом разделе содержания нет поправочных коэффициентов. Выберите раздел, содержащий техническую часть к сборнику или методический документ.";
	} else if (["nrsp", "pg", "ksr"].includes(props.tabValue)) {
		textNoData = "Выберите раздел для отображения данных.";
	} else if (["mat", "mach"].includes(props.tabValue)) {
		textNoData = "В этом разделе содержания нет групп ресурсов. Выберите раздел, содержащий группы.";
	}

	return (
		<div className="FsnbBodyRightNoData">
			<div className="FsnbBodyRightContent">
				<div className="FsnbBodyRightNoDataIcon">
					<ContentPasteSearchOutlinedIcon />
				</div>
				<div className="FsnbBodyRightNoDataText">{textNoData}</div>
			</div>
		</div>
	);
}

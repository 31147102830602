import { useEffect, useRef, useState, useLayoutEffect } from "react";

import AlertLoading from "components/AlertLoading";
import Table from "components/Table/Table";
import FsnbBodyNoData from "pages/Fsnb/components/FsnbBodyNoData";
import KsrTableColumns from "pages/Fsnb/TableSetting/KsrTableColumns";
import FsnbTree from "pages/Fsnb/components/FsnbTree";
import { resourceChangeVisible, resourceOpenTree } from "pages/Fsnb/utils";

import KsrDrawerHeader from "./KsrDrawerHeader/KsrDrawerHeader";

import apiFsnb from "api/fsnb";
import useDebounceCallback from "hooks/useDebounceCallback";

import { searchNestedIterableItem } from "core/iterable_utils";

import { getBodyWidthByClassName } from "./funcs";

import "pages/EstimateViewer/TableOuterBox.css";
import "./KsrDrawer.css";

export const KsrDrawer = ({ setVisible, displayOptions, searchOptions, initOptions, actions }) => {
	const scrollBlock = useRef(null);
	const delayInMs = useRef(1000);
	const [fetchStatus, setFetchStatus] = useState(null);
	const [fetchStatusText, setFetchStatusText] = useState();

	const [treeData, setTreeData] = useState([]);
	const [selectedTreeData, setSelectedTreeData] = useState({});

	const [tableData, setTableData] = useState([]);

	const [curSearchRow, setCurSearchRow] = useState({});
	const [searchFounds, setSearchFounds] = useState([]);

	const [inputSearchValue, setInputSearchValue] = useState("");
	const [clickedTreeCnt, setClickedTreeCnt] = useState(0);

	let minStrLen = 4;
	if (searchOptions?.minStrLen) minStrLen = searchOptions?.minStrLen;

	const ROW_TABLE_HEIGHT = 22;

	const columnsData = KsrTableColumns();

	const setInitDelayInMs = () => {
		if (initOptions?.initDelayInMs != null) delayInMs.current = initOptions?.initDelayInMs;
	};

	useLayoutEffect(() => {
		setInitDelayInMs();
		const prom = apiFsnb.getKsrTree();
		prom.then((response) => {
			setTreeData(response.data);
		}).catch((error) => {
			console.error(error);
		});
		return () => setInitDelayInMs();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (initOptions?.inputSearchValue) {
			setInputSearchValue(initOptions?.inputSearchValue);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	//search effect
	useEffect(() => {
		if (!searchFounds.length) return;
		if (curSearchRow.parent_id == null) return;

		let tableProm;

		if (curSearchRow.max_level) {
			tableProm = apiFsnb.getKsrTableResource(curSearchRow.parent_parent_id);
		} else {
			tableProm = apiFsnb.getKsrTableResource(curSearchRow.parent_id);
		}

		setFetchStatus("loading");
		tableProm
			.then((response) => {
				if (response.data.length && displayOptions.table) {
					const searchIds = searchFounds.map((x) => x.id);
					const data = response.data.map((gpr) => {
						if (gpr._children == null) return gpr;
						gpr._children = gpr._children.map((row) => {
							if (searchIds.includes(row.id)) {
								if (["name", "code", "full_row"].includes(curSearchRow.str_clm)) {
									row.row_type_style = `selected_norm_${searchFounds.find((x) => x.id === row.id).str_clm}`;
									if (row._children) {
										row._children.map((child) => {
											if (searchIds.includes(child.id)) {
												child.row_type_style = `selected_norm_${searchFounds.find((x) => x.id === child.id).str_clm}`;
											}
											if (child.id === curSearchRow.id) child.row_type_style = `cur_selected_norm_${curSearchRow.str_clm}`;

											return child;
										});
									}
								}
								if (row.id === curSearchRow.id) {
									if (["name", "code"].includes(curSearchRow.str_clm))
										row.row_type_style = `cur_selected_norm_${curSearchRow.str_clm}`;
								}
							}
							return row;
						});
						return gpr;
					});

					setTableData(data);
				}

				if (curSearchRow.max_level) {
					setTreeData((prev) => [...resourceOpenTree(prev, { key: curSearchRow.parent_parent_id }, 0)[0]]);
					setSelectedTreeData({ key: curSearchRow.parent_parent_id });
				} else if (!searchOptions.levels.includes(4) && !searchOptions.levels.includes(5)) {
					setTreeData((prev) => [...resourceOpenTree(prev, { key: curSearchRow.id }, 0)[0]]);
					setSelectedTreeData({ key: curSearchRow.id });
				} else {
					setTreeData((prev) => [...resourceOpenTree(prev, { key: curSearchRow.parent_id }, 0)[0]]);
					setSelectedTreeData({ key: curSearchRow.parent_id });
				}
				setFetchStatus("success");
			})
			.catch((error) => {
				console.error(error);
				setFetchStatus("failed");
				setFetchStatusText(error);
			});
	}, [searchFounds, curSearchRow]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { seekItem, seekCount } = searchNestedIterableItem({
			iterable: tableData,
			isSeekRowCallback: (row) => {
				if (curSearchRow.id == null && row.isSearching === true) {
					return true;
				} else if (curSearchRow.id != null && curSearchRow.id === row.id) {
					return true;
				} else {
					return false;
				}
			},
			isPermittedRowCallback: (row) => true,
			isPermittedChildsLoopCallback: (row) => true,
		});
		if (seekItem && scrollBlock.current) {
			scrollBlock.current.scrollTop = seekCount * ROW_TABLE_HEIGHT - ROW_TABLE_HEIGHT * 2;
		}
	}, [tableData?.length, curSearchRow, clickedTreeCnt]); // eslint-disable-line react-hooks/exhaustive-deps

	useDebounceCallback({
		callback: () => handleSearch({ strSearch: inputSearchValue }),
		delayInMs: delayInMs.current,
		useEffectTrigger: inputSearchValue,
		allowCallback: inputSearchValue?.length >= minStrLen,
	});

	const handleSearch = ({ strSearch }) => {
		const prom = apiFsnb.getKsrTableSearch({ body: { str_search: strSearch, level_search: searchOptions.levels } });
		setFetchStatus("loading");
		prom.then((response) => {
			setCurSearchRow(response.data[0]);
			if (!response.data.length) {
				setTableData([]);
			}
			setSearchFounds(response.data);
			setFetchStatus("success");
		})
			.catch((error) => {
				console.error(error);
				setFetchStatus("failed");
				setFetchStatusText(error);
			})
			.finally(() => {
				delayInMs.current = 1000;
			});
	};

	const handleTreeClick = (row) => {
		setCurSearchRow({});
		setTreeData((prev) => [...resourceChangeVisible(prev, row)]);
		if (!displayOptions.table) return;
		const prom = apiFsnb.getKsrTableResource(row.id);
		setFetchStatus("loading");
		prom.then((response) => {
			setTableData(response.data);
			setClickedTreeCnt((prev) => (prev += 1));
			setFetchStatus("success");
		}).catch((error) => {
			console.error(error);
			setFetchStatus("failed");
			setFetchStatusText(error);
		});
	};

	return (
		<div className="KsrDrawer">
			<KsrDrawerHeader
				func={{ setVisible, setInputSearchValue, setCurSearchRow, setInitDelayInMs }}
				state={{ inputSearchValue, searchFounds, curSearchRow }}
			/>

			<div className="KsrDrawerBody">
				{displayOptions.tree && (
					<div className="KsrTree" style={{ width: getBodyWidthByClassName({ className: "KsrTree", displayOptions }) }}>
						<FsnbTree
							data={treeData}
							selected={selectedTreeData}
							f_setSelectedTreeData={setSelectedTreeData}
							f_changeChildsVisible={handleTreeClick}
							actions={actions?.tree}
						/>
					</div>
				)}
				{displayOptions.table && (
					<div className="KsrTable" ref={scrollBlock} style={{ width: getBodyWidthByClassName({ className: "KsrTable", displayOptions }) }}>
						{tableData.length > 0 ? (
							<Table
								rowData={tableData}
								columnsData={columnsData}
								scrollBlock={scrollBlock}
								options={{
									selectOptions: {
										controlled: true,
										uncontrolled: false,
										multiple: false,
										preventSetClickedTableRow: true,
										expandOnSelect: {
											columnsName: ["name"],
											fontSize: "12px",
											tableClass: "KsrTable",
										},
									},
									nestingOptions: {
										changeChildrenVisible: { tagName: { allowed: ["path", "svg"] } },
									},
								}}
								funcs={{
									setTableData: ({ newTableData }) => setTableData(newTableData),
									doubleClickRowAction: {
										action: (arg) => {
											if (actions?.table?.doubleClick) actions?.table?.doubleClick(arg);
										},
									},
								}}
							></Table>
						) : (
							<FsnbBodyNoData />
						)}
					</div>
				)}
			</div>

			<AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} isLocal={true} left={"80%"} />
		</div>
	);
};

import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiObjectEstimate from "../api/object_estimate";
import { searchDataRecourse, changeRowDataRecourse } from "./utils";
import { downloadFile } from "core/request_utils";

export const deleteObjectEstimate = createAsyncThunk("objectEstimateSlice/delete", async ({ estmId, cascade }) => {
	const response = await apiObjectEstimate.delete({ estmId, cascade });
	return response.data;
});

export const editObjectEstimate = createAsyncThunk("objectEstimateSlice/edit", async (body) => {
	const response = await apiObjectEstimate.edit(body["id"], body);
	return response.data;
});

export const getObjectEstimateTree = createAsyncThunk("objectEstimateSlice/tree", async (id) => {
	const response = await apiObjectEstimate.getTree(id);
	return response.data;
});

export const getPrintXlsx = createAsyncThunk("objectEstimateSlice/getPrintXlsx", async (id) => {
	const response = await apiObjectEstimate.getPrintXlsx(id);
	downloadFile(response);
});

export const getPrintXlsxWithErrors = createAsyncThunk("objectEstimateSlice/getPrintXlsxWithErrors", async (id) => {
	const response = await apiObjectEstimate.getPrintXlsxWithErrors(id);
	downloadFile(response);
});

export const calcObjEstimate = createAsyncThunk("objectEstimateSlice/calc", async (id, { rejectWithValue }) => {
	try {
		const response = await apiObjectEstimate.calculate(id);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const protectObjEstmFull = createAsyncThunk("objectEstimateSlice/protectObjEstmFull", async (body, { rejectWithValue }) => {
	try {
		const response = await apiObjectEstimate.protectFull(body["id"], body);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const getInputXml = createAsyncThunk("objectEstimateSlice/getInputXml", async (id) => {
	const response = await apiObjectEstimate.getInputXml(id);
	downloadFile(response);
});

export const objectEstimateSlice = createSlice({
	name: "objectEstimateSlice",
	fetchStatus: null,
	initialState: {
		headerData: {},
		tableData: [],
	},
	reducers: {
		editObjectEstmState: (state, { payload }) => {
			if (payload["id"] === state.data["id"]) {
				state.data["num"] = payload["code"];
				state.data["name"] = payload["name"];
			}
		},
		setObjFetchStatus: (state, { payload }) => {
			state.fetchStatus = payload;
		},
		changeObjEstmErrorsVisible: (state, { payload }) => {
			let val = payload["val"];
			let row = payload["row"];
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = searchDataRecourse(state.tableData, row["key"], val, "_details", "errors_visible");
		},
		changeRowData: (state, { payload: { rowKey, callback } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = changeRowDataRecourse(state.tableData, rowKey, callback);
		},
		setTableData: (state, { payload }) => {
			state.tableData = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(deleteObjectEstimate.fulfilled, (state, { payload }) => {
			state.fetchStatus = "success";
		});

		builder.addCase(editObjectEstimate.fulfilled, (state, action) => {
			state.fetchStatus = "success";
		});

		builder.addCase(getObjectEstimateTree.fulfilled, (state, action) => {
			state.headerData = action["payload"]["header_data"];
			state.tableData = action["payload"]["table_data"];
			if (localStorage.getItem("scrollType") === "osr") {
				localStorage.setItem("DO_RECOURSE", "1");
				state.tableData = searchDataRecourse(state.tableData, localStorage.getItem("scrollKey"), null, "_details", "errors_visible");
				localStorage.removeItem("scrollType");
				localStorage.removeItem("scrollKey");
			}
			state.fetchStatus = "success";
		});

		builder.addCase(protectObjEstmFull.fulfilled, (state, action) => {
			state.headerData = action["payload"]["header_data"];
			state.tableData = action["payload"]["table_data"];
			state.fetchStatus = "success";
		});

		builder.addCase(calcObjEstimate.fulfilled, (state, action) => {
			state.headerData = action["payload"]["header_data"];
			state.tableData = action["payload"]["table_data"];
			state.fetchStatus = "success";
		});

		builder.addMatcher(isAnyOf(getPrintXlsx.fulfilled, getPrintXlsxWithErrors.fulfilled), (state, action) => {
			state.fetchStatus = "success";
		});

		builder.addMatcher(
			isAnyOf(
				deleteObjectEstimate.pending,
				editObjectEstimate.pending,
				getObjectEstimateTree.pending,
				calcObjEstimate.pending,
				protectObjEstmFull.pending,
				getPrintXlsx.pending,
				getPrintXlsxWithErrors.pending
			),
			(state, action) => {
				state.fetchStatus = "loading";
			}
		);

		builder.addMatcher(
			isAnyOf(
				deleteObjectEstimate.rejected,
				editObjectEstimate.rejected,
				getObjectEstimateTree.rejected,
				calcObjEstimate.rejected,
				protectObjEstmFull.rejected,
				getPrintXlsx.rejected,
				getPrintXlsxWithErrors.rejected
			),
			(state, action) => {
				state.fetchStatus = "failed";
			}
		);
	},
});

export const { editObjectEstmState, setObjFetchStatus, changeObjEstmErrorsVisible, changeRowData, setTableData } = objectEstimateSlice.actions;

export default objectEstimateSlice.reducer;

import { useDispatch } from "react-redux";
import { getInputXml } from "slices/local_estimate";
import XMLViewer from "react-xml-viewer";
import Draggable from "react-draggable";

import "./JustificationModalBox.css";

export default function JustificationModalBoxShort({ xmlData, estmId, f_visible }) {
    const dispatch = useDispatch();

    return (
        <Draggable handle=".JustificationHead">
            <div className="JustificationModalBox">
                <div className="JustificationHead">
                    <div className="JustificationTitle">Позиция в исходом файле XML</div>
                </div>
                <div className="JustificationBody">
                    <div>
                        <XMLViewer xml={xmlData} />
                    </div>
                </div>
                <div className="JustificationBodyFooter">
                    <div
                        className="But"
                        onClick={() => {
                            dispatch(getInputXml(estmId));
                        }}>
                        Скачать XML
                    </div>
                    <div
                        className="But"
                        onClick={() => {
                            f_visible(false);
                        }}>
                        Закрыть
                    </div>
                </div>
            </div>
        </Draggable>
    );
}

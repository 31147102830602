const RECOMMENDED_INDEX_TEXT_BOX_PARAMS = [
	{
		label: "Индексы по элементам затрат",
		key: "direct_letter__name",
		_children: [
			{
				label: "Индекс на материалы",
				key: "mat_index",
			},
			{
				label: "Индекс на механизмы",
				key: "mech_index",
			},
			{
				label: "Индекс на оплату труда",
				key: "ot_index",
			},
		],
	},
	{
		label: "Индексы СМР",
		key: "smr_letter__name",
		_children: [
			{
				label: "Индекс СМР",
				key: "smr_index",
			},
		],
	},
	{
		label: "Индексы на оборудование",
		key: "eqp_letter__name",
		_children: [
			{
				label: "Индекс на оборудование",
				key: "eqp_index",
			},
		],
	},
	{
		label: "Индексы на перевозку",
		key: "pg_letter__name",
		_children: [
			{
				label: "Перевозка автомобилями бортовыми",
				key: "pg_bort_index",
			},
			{
				label: "Перевозка самосвалами",
				key: "pg_sam_index",
			},
			{
				label: "Перевозка панелевозами",
				key: "pg_pan_index",
			},
			{
				label: "Перевозка трубоплетевозами",
				key: "pg_trub_index",
			},
			{
				label: "Перевозка автобетоносмесителями",
				key: "pg_bet_index",
			},
		],
	},
	{
		label: "Индексы на прочие работы и затраты",
		key: "other_letter__name",
		_children: [
			{
				label: "Индекс на прочие работы и затраты",
				key: "other_index",
			},
		],
	},
];

export { RECOMMENDED_INDEX_TEXT_BOX_PARAMS };

import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from "@mui/icons-material/Search";

import TableMain from "../../components/Table/TableMain";
import AlertLoading from "../../components/AlertLoading";
import RibbonContent from "components/Ribbon/RibbonContent/RibbonContent";

import apiCalcer from "../../api/calcer";

import IndexesGosrTableColumns from "./IndexesGosrTableColumns";
import IndexesGosrRibbonSettings from "./IndexesGosrRibbonSettings";

import "./IndexesGosr.css";
import "./IndexesAutocomplete.css";
import "./IndexesTable.css";

export default function IndexesGosr(props) {
    const [fetchStatus, setFetchStatus] = useState(null);
    const [fetchStatusText, setFetchStatusText] = useState("");

    const [periods, setPeriods] = useState([]);
    const [priceZones, setPriceZones] = useState([]);
    const [resTypes, setResTypes] = useState([]);
    const [indOgrs, setIndOgrs] = useState([]);

    const [selectedPeriod, setSelectedPeriod] = useState({});
    const [selectedPriceZone, setSelectedPriceZone] = useState({});
    const [selectedResType, setSelectedResType] = useState({});
    const [selectedIndOgr, setSelectedIndOgr] = useState({});

    const [defaultPeriod, setDefaultPeriod] = useState({});
    const [defaultPriceZone, setDefaultPriceZone] = useState({});
    const [defaultResType, setDefaultResType] = useState({});
    const [defaultIndOgr, setDefaultIndOgr] = useState({});

    const [inputValue, setInputValue] = useState("");

    const [rowsData, setRowsData] = useState([]);
    const [filtredRowsData, setFiltredRowsData] = useState([]);
    const columnsData = IndexesGosrTableColumns();

    useEffect(() => {
        setFetchStatus("loading");
        const resTypePromise = new Promise((resolve, reject) => {
            apiCalcer
                .getGosrGroupTypes()
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });

        const perPromise = new Promise((resolve, reject) => {
            apiCalcer
                .getPeriods()
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });

        const pzPromise = new Promise((resolve, reject) => {
            apiCalcer
                .getPriceZones()
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });

        const indOrgPromise = new Promise((resolve, reject) => {
            apiCalcer
                .getIndustryOrganization()
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });

        Promise.all([resTypePromise, perPromise, pzPromise, indOrgPromise])
            .then((response_arrs) => {
                setResTypes(response_arrs[0]);
                setPeriods(response_arrs[1]);
                setPriceZones(response_arrs[2]);
                setIndOgrs(response_arrs[3]);
                return response_arrs;
            })
            .then((data) => {
                if (localStorage.getItem("indexResTypeId")) {
                    const resTypeVal = data[0].find((x) => String(x["id"]) === localStorage.getItem("indexResTypeId"));
                    setDefaultResType(resTypeVal);
                } else {
                    setDefaultResType(data[0][0]);
                }
                if (localStorage.getItem("indexPeriodId")) {
                    const perVal = data[1].find((x) => String(x["id"]) === localStorage.getItem("indexPeriodId"));
                    setDefaultPeriod(perVal);
                } else {
                    setDefaultPeriod(data[1][0]);
                }
                if (localStorage.getItem("indexPriceZonesId")) {
                    const pzVal = data[2].find((x) => String(x["id"]) === localStorage.getItem("indexPriceZonesId"));
                    setDefaultPriceZone(pzVal);
                } else {
                    setDefaultPriceZone(data[2][0]);
                }
                if (localStorage.getItem("indexIndOrgId")) {
                    const orgVal = data[3].find((x) => String(x["id"]) === localStorage.getItem("indexIndOrgId"));
                    setDefaultIndOgr(orgVal);
                    setSelectedIndOgr(orgVal);
                } else {
                    setDefaultIndOgr(data[3][0]);
                    setSelectedIndOgr(data[3][0]);
                }
                setFetchStatus("success");
            })
            .catch((error) => {
                console.error(error);
                setFetchStatus("failed");
                if (error?.response?.data?.detail != null) {
                    setFetchStatusText(error.response.data.detail);
                } else {
                    setFetchStatusText(error.message);
                }
            });
    }, []);

    const getFiltredData = (sourceData) => {
        if (!sourceData) return sourceData;
        if (!inputValue) return sourceData;
        if (String(inputValue).length < 3) return sourceData;

        const resultData = [];
        for (let parent of sourceData) {
            if (!parent._children) continue;
            const childrenFiltred = [];
            for (let ch of parent._children) {
                if (
                    String(ch?.name).toLocaleLowerCase().includes(inputValue) ||
                    String(ch?.code).startsWith(inputValue)
                ) {
                    const chCopy = { ...ch };
                    chCopy.visible = true;
                    childrenFiltred.push(chCopy);
                }
            }
            if (childrenFiltred.length > 0) {
                const parentCopy = { ...parent };
                parentCopy.childs_visible = true;
                parentCopy._children = childrenFiltred;
                resultData.push(parentCopy);
            }
        }
        return resultData;
    };

    const fetchDefaultData = () => {
        if (
            selectedPriceZone.id != null &&
            selectedPeriod.id != null &&
            selectedResType.id != null &&
            selectedIndOgr.id != null
        ) {
            setFetchStatus("loading");

            const apiPromise = apiCalcer.getDefaultGosrIndexes(
                selectedResType.id,
                selectedPeriod.id,
                selectedPriceZone.id,
                selectedIndOgr.id
            );
            apiPromise
                .then((response) => {
                    setRowsData(response.data);
                    setFetchStatus("success");
                })
                .catch((error) => {
                    setFetchStatus("failed");
                    setFetchStatusText(error.message);
                });
        }
    };

    useEffect(() => {
        fetchDefaultData();
    }, [selectedResType, selectedPriceZone, selectedPeriod, selectedIndOgr]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFiltredRowsData(getFiltredData(rowsData));
    }, [inputValue, rowsData]); // eslint-disable-line react-hooks/exhaustive-deps

    if (fetchStatus === "failed") {
        return <AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} />;
    }

    if (resTypes.length === 0 || periods.length === 0 || priceZones.length === 0) {
        return;
    }

    const showChildren = ({ clickedRow }) => {
        const newSelectedRowsData = filtredRowsData.map((x) => {
            if (x.id === clickedRow.id) {
                x.childs_visible = !x.childs_visible;
                x._children.map((ch) => {
                    ch.visible = x.childs_visible;
                    return ch;
                });
            }
            return x;
        });
        setFiltredRowsData(newSelectedRowsData);
    };

    const getAutocompleteSx = () => {
        return {
            width: "100%",
            "& legend": { fontSize: "9px" },
        };
    };

    const periodPriceZoneAutocomplete = () => {
        return (
            <div className="indexesAutocompleteWrapper">
                <Autocomplete
                    sx={getAutocompleteSx()}
                    options={periods}
                    defaultValue={defaultPeriod}
                    disableClearable
                    getOptionLabel={(option) => option.name || ""}
                    size="small"
                    noOptionsText="нет данных"
                    onInputChange={(e, val) => {
                        const inpVal = periods.find((x) => x["name"] === val);
                        if (inpVal) setSelectedPeriod(inpVal);
                    }}
                    onClose={(e) => {
                        const itm = periods.find((x) => x["name"] === e.target.innerText);
                        if (itm) localStorage.setItem("indexPeriodId", itm.id);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} InputLabelProps={{ shrink: true }} label="Период" />
                    )}
                />
                <Autocomplete
                    sx={getAutocompleteSx()}
                    options={priceZones}
                    defaultValue={defaultPriceZone}
                    disableClearable
                    getOptionLabel={(option) => option.name || ""}
                    size="small"
                    noOptionsText="нет данных"
                    onInputChange={(e, val) => {
                        const inpVal = priceZones.find((x) => x["name"] === val);
                        if (inpVal) setSelectedPriceZone(inpVal);
                    }}
                    onClose={(e) => {
                        const itm = priceZones.find((x) => x["name"] === e.target.innerText);
                        if (itm) localStorage.setItem("indexPriceZonesId", itm.id);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} InputLabelProps={{ shrink: true }} label="Ценовая зона" />
                    )}
                />
            </div>
        );
    };

    const typeAutocomplete = () => {
        return (
            <div className="indexesAutocompleteWrapper">
                <Autocomplete
                    sx={getAutocompleteSx()}
                    options={resTypes}
                    defaultValue={defaultResType}
                    disableClearable
                    getOptionLabel={(option) => option.name || ""}
                    size="small"
                    noOptionsText="нет данных"
                    onInputChange={(e, val) => {
                        const inpVal = resTypes.find((x) => x["name"] === val);
                        if (inpVal) setSelectedResType(inpVal);
                    }}
                    onClose={(e) => {
                        const itm = resTypes.find((x) => x["name"] === e.target.innerText);
                        if (itm != null) localStorage.setItem("indexResTypeId", itm.id);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} InputLabelProps={{ shrink: true }} label="Вид ресурсов" />
                    )}
                />
                <Autocomplete
                    sx={getAutocompleteSx()}
                    options={indOgrs}
                    defaultValue={defaultIndOgr}
                    getOptionLabel={(option) => option.name || ""}
                    size="small"
                    noOptionsText="нет данных"
                    onInputChange={(e, val) => {
                        const inpVal = indOgrs.find((x) => x["name"] === val);
                        if (inpVal) setSelectedIndOgr(inpVal);
                        // if (!e?.target) localStorage.setItem("indexIndOrgId", 0);
                    }}
                    onClose={(e) => {
                        const itm = indOgrs.find((x) => x["name"] === e.target.innerText);
                        if (itm) localStorage.setItem("indexIndOrgId", itm.id);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} InputLabelProps={{ shrink: true }} label="Отраслевая организация" />
                    )}
                />
            </div>
        );
    };

    const searchAutocomplete = () => {
        return (
            <TextField
                sx={getAutocompleteSx()}
                label="Поиск по коду или наименованию группы / ресурса"
                size="small"
                value={inputValue}
                onChange={(e, val) => setInputValue((prev) => e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon fontSize="16px" />
                        </InputAdornment>
                    ),
                }}
            />
        );
    };

    return (
        <>
            <RibbonContent
                ribbonSettings={IndexesGosrRibbonSettings(
                    periodPriceZoneAutocomplete,
                    typeAutocomplete,
                    searchAutocomplete
                )}
            />
            <div className="IndexesGosrMain">
                <div className="IndexesFilters"></div>
                <div className="IndexesTable">
                    <TableMain
                        rows={{ data: filtredRowsData }}
                        columns={columnsData}
                        options={{
                            selectable: true,
                            scrollBlock: props.scrollBlock,
                            scrollHeghtControll: 10,
                            displayScrollDelay: 20,
                            funcCall: "local",
                        }}
                        func={{
                            changeVisible: showChildren,
                        }}></TableMain>
                    <br></br>
                </div>
                <AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} />
            </div>
        </>
    );
}

import LabelNormWithUnderTwoSides from "components/UI/LabelNormWithUnderTwoSides";

const EstimatePricesHead = ({ data }) => {
    return (
        <div style={{ display: "flex" }}>
            <div>
                <LabelNormWithUnderTwoSides
                    norm_start="Сметная стоимость"
                    norm_start_bold={true}
                    norm_start_width="300px"
                    under={data["estm_prices_total_print"]}
                    norm_end="тыс. руб."
                    under_width="300px"
                />
                <div
                    style={{
                        // marginLeft: "70px",
                        fontStyle: "italic",
                        fontSize: "14px",
                        textAlign: "left",
                    }}>
                    в том числе:
                </div>
                <LabelNormWithUnderTwoSides
                    norm_start="строительных работ"
                    norm_start_bold={true}
                    norm_start_width="230px"
                    norm_margin_left="90px"
                    norm_margin_top="-20px"
                    under={data["estm_prices_building_print"]}
                    norm_end="тыс. руб."
                    under_width="300px"
                />
                <LabelNormWithUnderTwoSides
                    norm_start="монтажных работ"
                    norm_start_bold={true}
                    norm_start_width="230px"
                    norm_margin_left="90px"
                    under={data["estm_prices_mounting_print"]}
                    norm_end="тыс. руб."
                    under_width="300px"
                />
                <LabelNormWithUnderTwoSides
                    norm_start="оборудования"
                    norm_start_bold={true}
                    norm_start_width="230px"
                    norm_margin_left="90px"
                    under={data["estm_prices_equipment_print"]}
                    norm_end="тыс. руб."
                    under_width="300px"
                />
                <LabelNormWithUnderTwoSides
                    norm_start="прочих затрат"
                    norm_start_bold={true}
                    norm_start_width="230px"
                    norm_margin_left="90px"
                    under={data["estm_prices_otherworks_print"]}
                    norm_end="тыс. руб."
                    under_width="300px"
                />
            </div>
            <div>
                <LabelNormWithUnderTwoSides
                    norm_start="Средства на оплату труда рабочих"
                    norm_start_bold={true}
                    norm_start_width="280px"
                    norm_margin_left="50px"
                    under={data["estm_prices_wsalary_print"]}
                    norm_end="тыс. руб."
                    under_width="300px"
                />
                <LabelNormWithUnderTwoSides
                    norm_start="Средства на оплату труда машинистов"
                    norm_start_bold={true}
                    norm_start_width="280px"
                    norm_margin_left="50px"
                    under={data["estm_prices_msalary_print"]}
                    norm_end="тыс. руб."
                    under_width="300px"
                />
                <LabelNormWithUnderTwoSides
                    norm_start="Нормативные затраты труда рабочих"
                    norm_start_bold={true}
                    norm_start_width="280px"
                    norm_margin_left="50px"
                    under={data["estm_prices_labor_costs_print"]}
                    norm_end="чел.-ч."
                    under_width="300px"
                />
                <LabelNormWithUnderTwoSides
                    norm_start="Нормативные затраты труда машинистов"
                    norm_start_bold={true}
                    norm_start_width="280px"
                    norm_margin_left="50px"
                    under={data["estm_prices_m_labor_costs_print"]}
                    norm_end="чел.-ч."
                    under_width="300px"
                />
            </div>
        </div>
    );
};

export default EstimatePricesHead;

import React from 'react';

import "./LabelNormWithUnder.css";

export default function LabelNormWithUnder(props) {
    let labelNorm = props.norm + ":";
    if (props.useColon === false) labelNorm = props.norm
    const labelUnder = props.under;
    const labelType = props.type;
    let normfontWeight;
    if (props.norm_bold === true) {
        normfontWeight = 600;
    } else {
        normfontWeight = 400;
    }

    if (props.options) {
        if (props.options.normIsDom) labelNorm = <div style={{ display: "flex" }}>{props.norm}<span>:</span></div>
    }

    return (
        <div className="LabelNormWithUnder" type={labelType}>
            <div className="LabelNorm"
                style={{ "fontWeight": normfontWeight }}>{labelNorm}</div>
            <div className="LabelUnder" title={labelUnder}>{labelUnder}</div>
        </div >
    );
}
import React, { useRef } from "react";

import TreeMainNew from "./TreeMainNew";

export default function TreeMainNewWrapper(props) {
	const needRecource = useRef(true);

	const changeRecourceBoolArg = (row, clickedRow, clm, callback, defaultVal = null) => {
		if (!needRecource.current) return row;
		if (row.key === clickedRow.key) {
			if (defaultVal != null) {
				row[clm] = defaultVal;
			} else {
				row[clm] == null ? (row[clm] = true) : (row[clm] = !row[clm]);
			}
			callback(row);
			needRecource.current = false;
		} else {
			if (row._children != null) {
				for (let ch of row._children) {
					changeRecourceBoolArg(ch, clickedRow, clm, callback, defaultVal);
				}
			}
		}
		return row;
	};

	const changeTreeVisible = (clickedRow) => {
		needRecource.current = true;
		const callback = (row) => {};
		props.setData((prev) =>
			prev.map((row) => {
				return changeRecourceBoolArg(row, clickedRow, "childsVisible", callback);
			})
		);
	};

	const changeTreeChecked = (clickedRow, defaultVal = null) => {
		needRecource.current = true;
		const callback = (row) => {
			if (row._children != null) {
				for (let ch of row._children) {
					ch.isChecked = row.isChecked;
					callback(ch);
					if (props.changeTreeCheckedCallback) {
						props.changeTreeCheckedCallback({ row: ch, val: row.isChecked });
					}
				}
			}
		};
		if (props.clickRowCallback) {
			props.clickRowCallback({ row: clickedRow });
		}
		props.setData((prev) =>
			prev.map((row) => {
				return changeRecourceBoolArg(row, clickedRow, "isChecked", callback, defaultVal);
			})
		);
	};

	const selectTreeRow = (clickedRow) => {
		if (props.useRowSelect === false) return;
		needRecource.current = true;
		const callback = (row) => {};
		props.setData((prev) =>
			prev.map((row) => {
				return changeRecourceBoolArg(row, clickedRow, "selected", callback);
			})
		);
	};

	return (
		<TreeMainNew
			data={props.data}
			changeTreeVisible={changeTreeVisible}
			changeTreeChecked={changeTreeChecked}
			selectTreeRow={selectTreeRow}
			useCheckBox={props.useCheckBox}
			nameField={props.nameField}
			paddingPropsArrow={18}
			alignPaddingNullLevel={true}
		/>
	);
}

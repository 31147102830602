import { useSelector } from "react-redux";

import CustomUserCell from "./components/CustomCell/CustomUserCell";
import CustomNorthTerrCell from "./components/CustomCell/CustomNorthTerrCell";
import CustomNavigateCell from "./components/CustomCell/CustomNavigateCell";
import CustomExportProtocolCell from "./components/CustomCell/CustomExportProtocolCell";
import CustomDndCell from "./components/CustomCell/CustomDndCell";

import { getEnvVal } from "core/env.js";

export default function ProjectTableColumns({ funcs, rowsDataHasPack }) {
	const userPermissions = useSelector((state) => state.auth.permissions);
	const windowWidth = useSelector((state) => state.windowDimensions.windowWidth);

	const projectSelectData = () => {};

	const getCodeWidth = () => {
		if (windowWidth > 1200) return "200px";
		return "110px";
	};

	const getPzWidth = () => {
		if (windowWidth > 1200) return "250px";
		return "125px";
	};

	const getTerWidth = () => {
		if (windowWidth > 1200) return "150px";
		return "95px";
	};

	const getUserWidth = () => {
		if (windowWidth > 1200) return "250px";
		return "220px";
	};

	const columns = [
		{
			name: "dnd",
			title: "",
			width: "15px",
			cellHorAlign: "left",
			headHorAlign: "left",
			cellJsx: ({ rowObj }) => CustomDndCell({ rowObj, funcs }),
		},
		{
			name: "code",
			title: "Код",
			width: getCodeWidth(),
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: true,
			filter: true,
			editable: true,
		},
		{
			name: "name",
			title: "Наименование проекта",
			width: "auto",
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: true,
			filter: true,
			editable: true,
		},
		{
			name: "action_navigate",
			title: "",
			width: "10px",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			cellJsx: ({ rowObj }) => CustomNavigateCell({ rowObj, funcs }),
		},
		{
			name: "price_zone_id",
			title: "Ценовая зона",
			width: getPzWidth(),
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: false,
			filter: false,
			editable: true,
			selecting: "price_zones",
		},
		{
			name: "north_reg_terr_id",
			title: "Признак территории",
			width: getTerWidth(),
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: false,
			filter: false,
			editable: true,
			selecting: "north_reg_terrs",
			cellJsx: ({ rowObj }) => CustomNorthTerrCell({ rowObj, funcs }),
		},
		// {
		//     name: "date_control",
		//     title: "Контрольная дата",
		//     width: "150px",
		//     cellHorAlign: "left",
		//     headHorAlign: "left",
		//     sort: true,
		//     filter: false,
		//     editable: false,
		//     cellJsx: ({ rowObj }) => {
		//         return (
		//             <td
		//                 key={`cell-date_control-${rowObj.key}`}
		//                 style={{ paddingTop: "1px", paddingBottom: "1px" }}
		//                 className="TableCell"
		//                 clm="date_control"
		//                 treecell="false"
		//                 title={rowObj.date_control}>
		//                 <KsprDateInput
		//                     label=""
		//                     changeCallback={(newDate) => {
		//                         dispatch(editProject({ key: rowObj.key, date_control: newDate }));
		//                         dispatch(patchProject({ id: rowObj.id, date_control: newDate }));
		//                     }}
		//                     defaultDate={rowObj.date_control == null ? "" : rowObj.date_control}
		//                 />
		//             </td>
		//         );
		//     },
		// },
		{
			name: "date_create",
			title: "Дата создания",
			width: "130px",
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: true,
			filter: false,
			editable: false,
		},
		{
			name: "export_protocol",
			title: "",
			width: "10px",
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: false,
			filter: false,
			editable: false,
			cellJsx: ({ rowObj }) => CustomExportProtocolCell({ rowObj, funcs }),
		},
	];

	if (!userPermissions.is_guest) {
		columns.push({
			name: "username",
			title: "Пользователь",
			width: getUserWidth(),
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: true,
			filter: true,
			editable: false,
			cellJsx: ({ rowObj }) => CustomUserCell({ rowObj, projectSelectData }),
		});
	}

	if (getEnvVal("App") === "sipcs") {
		return columns.filter((clm) => clm.name !== "north_reg_terr_id");
	}

	if (rowsDataHasPack === false) {
		return columns.filter((clm) => clm.name !== "dnd");
	}

	return columns;
}

import { Button } from "@mui/material";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

import KATS_LINK from "api/external_links";

const KatsExternalLink = () => {
    <div className="KatsExternalLink" title="Перейти в ИС КАЦ">
        <Button
            onClick={() => window.open(KATS_LINK)}
            variant="contained"
            size="small"
            endIcon={<ExitToAppOutlinedIcon />}>
            ИС КАЦ
        </Button>
    </div>;
};

export default KatsExternalLink;

import React from 'react';

import Button from '@mui/material/Button';

import "./KsprIconButton.css";

export default function KsprIconButton({label, ...props}) {
    return (
        <div className="KsprIconButton">
            <Button size="small"
                {...props}
            >
                <div className="InnerText">{label}</div>
            </Button>
        </div>
    )
}


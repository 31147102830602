import React from 'react';

import Checkbox from '@mui/material/Checkbox';

import "./HeaderActionCell.css"

export default function HeaderActionCell({ clmAction, tableData, funcs }) {
    if  (clmAction === "check_box_select") {
        const rowLen = tableData.length;
        const rowCheckedLen = tableData.filter(row => row.isChecked).length;
        const checkVal = rowCheckedLen > 0;
        let color;
        if (rowCheckedLen > 0 && rowCheckedLen === rowLen) {
            color = "primary"
        } else if (rowCheckedLen > 0 && rowCheckedLen < rowLen) {
            color = "default"
        }
        const setVal = rowCheckedLen === rowLen ? false : true

        return (
            <div title="Добавить проверку в проект" className="TreeActionCell"
                onClick={() => funcs.checkAllRow(setVal)}>
                <Checkbox checked={checkVal} color={color}/>
            </div >
        )
    }

}
import { useDispatch } from "react-redux";

import LinearProgress from "@mui/material/LinearProgress";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { getUserTasksAndRead } from "slices/user_adv";
import { downloadPrinterXlsxResult } from "slices/project_work";
import { downloadPriceOffersXlsx } from "slices/kats";

import apiUserAdv from "api/user_adv";

import "./UserTaskRow.css";

const UserTaskRow = ({ task }) => {
	const dispatch = useDispatch();

	const getProgress = () => {
		if (task.status === "RUNNING") {
			return <LinearProgress variant="determinate" value={task.progress} />;
		} else if (task.status === "SUCCESS") {
			return <LinearProgress variant="determinate" color="success" value={task.progress} />;
		} else if (task.status === "FAILED") {
			return <LinearProgress variant="determinate" status="failed" value={task.progress} />;
		}
	};

	const handleDelTask = ({ taskId }) => {
		const prom = apiUserAdv.delUserTasks({ taskId: taskId });
		prom.then((response) => dispatch(getUserTasksAndRead()));
	};

	return (
		<div className="UserTaskRow">
			<div className="UserTaskRowTop">{`${task.date_start} [${task.project_code}] ${task.task_type}`}</div>
			<div className="UserTaskRowCenter">
				{`${task.duration} ${task.log}`}
				{task.task_type_en === "PRINT_ESTIMATE_DOCS" && task.status === "SUCCESS" && (
					<Tooltip title="Скачать" className="UserTaskRowDownload">
						<IconButton className="UserTaskRowDownload" onClick={(e) => dispatch(downloadPrinterXlsxResult({ taskId: task.id }))}>
							<FileDownloadOutlinedIcon />
						</IconButton>
					</Tooltip>
				)}
				{task.task_type_en === "PRINT_PRICE_OFFERS" && task.status === "SUCCESS" && (
					<Tooltip title="Скачать" className="UserTaskRowDownload">
						<IconButton className="UserTaskRowDownload" onClick={(e) => dispatch(downloadPriceOffersXlsx({ taskId: task.id }))}>
							<FileDownloadOutlinedIcon />
						</IconButton>
					</Tooltip>
				)}
				<Tooltip title="Удалить">
					<IconButton className="UserTaskRowDel" onClick={(e) => handleDelTask({ taskId: task.id })}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</div>
			<div className="UserTaskRowBottom">{getProgress()}</div>
		</div>
	);
};

export default UserTaskRow;

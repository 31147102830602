const LabelComposition = ({ children, style }) => {
    const defaultStyle = { display: "flex", flexDirection: "row", gap: "5px" };
    const applyingStyle = { ...defaultStyle, ...style };

    return (
        <div className="LabelComposition" style={applyingStyle}>
            {children}
        </div>
    );
};

export { LabelComposition };

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setCurrentPage } from "slices/ribbon";

import ForwardTwoToneIcon from "@mui/icons-material/ForwardTwoTone";

import "./StartWorkBox.css";

import startwork from "../imgs/startwork.png";

export default function StartWorkBox(props) {
	const dispatch = useDispatch();
	const location = useLocation();
	let curPathname = location["pathname"];

	let title = "Начало работы";
	let arrow = true;
	let text = (
		<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
			<div>{`Для начала работы загрузите сметную документацию в формате GGE (в верхнем левом углу по кнопке "Импорт")`}</div>
			<div>{`или выберите сметный расчет в дереве структуры проекта.`}</div>
		</div>
	);
	if (props.title != null) title = props.title;
	if (props.text != null) text = props.text;
	if (props.arrow != null) arrow = props.arrow;

	useEffect(() => {
		if (curPathname.includes("/protocol/")) {
			dispatch(setCurrentPage("ProtocolViewer"));
		} else {
			dispatch(setCurrentPage("StartEstimateViewer"));
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="StartWorkBox">
			<div className="StartWorkTop">
				<div className="StartWorkImg">
					<img src={startwork} alt=""></img>
				</div>
			</div>
			<div className="StartWorkBottom">
				<div className="StartWorkBottomLabel">{title}</div>
				<div className="StartWorkBottomText">{text}</div>
				<div className="StartWorkBottomArrow">{arrow && <ForwardTwoToneIcon />}</div>
			</div>
		</div>
	);
}

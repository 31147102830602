import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { getUserTasks, setUserTasksVisible } from "slices/user_adv";

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ShuffleOutlinedIcon from "@mui/icons-material/ShuffleOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AlignVerticalCenterOutlinedIcon from "@mui/icons-material/AlignVerticalCenterOutlined";

import ProjectSettingsRibbon from "./ProjectSettingsRibbon";

const ImportBrainStormRibbonSettings = ({ execCrossEstmAction, execJustValidation, dispatch, showClearProjectDialog, execCompletenessCheck }) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const currentEstmDocId = useSelector((state) => state.ribbon.currentEstmDocId);
	const currentEstmDocUrlPart = useSelector((state) => state.ribbon.currentEstmDocUrlPart);
	const userPermissions = useSelector((state) => state.auth.permissions);

	const onClickSmetBtn = () => {
		let newUrl = "";
		if (currentEstmDocUrlPart !== "ssr" && currentEstmDocId) {
			newUrl = `/project/${id}/${currentEstmDocUrlPart}/${currentEstmDocId}/`;
		} else {
			newUrl = `/project/${id}/`;
		}
		navigate(newUrl);
	};

	const onClickProtocolBtn = () => {
		let newUrl = "";
		if (currentEstmDocUrlPart !== "ssr" && currentEstmDocId) {
			newUrl = `/project/${id}/${currentEstmDocUrlPart}/${currentEstmDocId}/protocol/`;
		} else {
			newUrl = `/project/${id}/protocol/`;
		}
		navigate(newUrl);
	};

	const onClickImportBtn = () => {
		navigate("/project/" + id + "/import/");
	};

	return [
		{
			name: "Импорт",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Импорт",
							icon: <FileUploadOutlinedIcon />,
							size: "large",
							type: "common",
							callback: onClickImportBtn,
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Режимы работы",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "вид",
							icon: <AddBoxOutlinedIcon />,
							size: "medium",
							type: "radio",
							radioArray: [
								{
									name: "Сметная документация",
									callback: onClickSmetBtn,
									currentPage: "EstimateDocViewer",
								},
								{
									name: "Протокол проверки",
									callback: onClickProtocolBtn,
									currentPage: "ProtocolViewer",
								},
								{
									name: "Монитор проверки",
									currentPage: "ImportBrainStormViewer",
								},
							],
							callback: () => {},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Редактор",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: { flexDirection: "row" },
					_children: [
						{
							name: "Создать смету",
							icon: <NoteAddOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: userPermissions.is_lsr_editors !== true,
							callback: () => {
								navigate(`/project/${id}/editor/lsr/new/`);
							},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Проверка",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "horizontal",
					styles: {},
					_children: [
						{
							name: "Перекрестная проверка",
							icon: <ShuffleOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: userPermissions.is_expert === false,
							callback: () => {
								execCrossEstmAction();
								setTimeout(() => dispatch(getUserTasks()), 5000);
							},
							styles: {},
						},
						{
							name: "Проверка обоснований",
							icon: <AttachFileOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: userPermissions.is_expert === false,
							callback: () => {
								execJustValidation();
								setTimeout(() => {
									dispatch(getUserTasks());
									dispatch(setUserTasksVisible(true));
								}, 1000);
							},
							styles: {},
						},
						{
							name: "Проверка комплектности",
							icon: <AlignVerticalCenterOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: false,
							callback: () => execCompletenessCheck(),
							styles: { minWidth: 85 },
						},
					],
				},
			],
		},
		{
			name: "Настройки проекта",
			styles: {},
			_children: [
				{
					orientation: "vertical",
					styles: {},
					_children: [
						{
							type: "custom",
							customJsx: ProjectSettingsRibbon(),
							callback: () => {},
							styles: {
								width: "500px",
								pt: "7px",
							},
						},
					],
				},
				{
					name: "Subgroup 1",
					orientation: "horizontal",
					isVisible: userPermissions.is_guest === true || userPermissions.is_admin === true,
					styles: {},
					_children: [
						{
							name: "Очистить проект",
							icon: <DeleteOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: userPermissions.is_guest === false && userPermissions.is_admin === false,
							callback: () => showClearProjectDialog(),
							styles: {},
						},
					],
				},
			],
		},
	];
};

export default ImportBrainStormRibbonSettings;

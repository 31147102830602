import { useRef, useState, useEffect } from "react";

import AlertLoading from "components/AlertLoading";
import KsprButton from "components/UI/buttons/KsprButton";
import NormNrspConnectedColumns from "../TableSetting/NormNrspConnectedColumns";
import TableMain from "components/Table/TableMain";

import FsnbFilterVersSelect from "./FsnbFilterVersSelect";
import apiFsnb from "api/fsnb";

import "./FsnbNrspNormBox.css";
import "./FsnbCoefBox.css";
import "./FsnbAutocomplete.css";

export default function FsnbNrspNormBox(props) {
    const [tableData, setTableData] = useState([]);
    const scrollBlock = useRef(null);
    const [fetchStatus, setFetchStatus] = useState(null);
    const [fetchStatusText, setFetchStatusText] = useState("");
    const [fsnbVersArr, setFsnbVersArr] = useState([]);
    const [selectedFsnbVers, setSelectedFsnbVers] = useState({});
    const columnsData = NormNrspConnectedColumns();

    /*eslint-disable */
    useEffect(() => {
        setFetchStatus("loading");
        apiFsnb
            .getFsnbVersionsInterPeriod(props.vers.id)
            .then((response) => {
                setFsnbVersArr(response.data);
                setSelectedFsnbVers(response.data[0]);
            })
            .then((data) => {
                setFetchStatus("success");
            })
            .catch((error) => {
                console.error(error);
                setFetchStatus("failed");
                if (error?.response?.data?.detail != null) {
                    setFetchStatusText(error.response.data.detail);
                } else {
                    setFetchStatusText(error);
                }
            });
    }, []);

    useEffect(() => {
        if (selectedFsnbVers.id == null) return;
        setFetchStatus("loading");
        apiFsnb
            .getNormOnNrsp(selectedFsnbVers.id, props.row.id)
            .then((response) => {
                setTableData(response.data);
            })
            .then((data) => {
                setFetchStatus("success");
            })
            .catch((error) => {
                console.error(error);
                setFetchStatus("failed");
                if (error?.response?.data?.detail != null) {
                    setFetchStatusText(error.response.data.detail);
                } else {
                    setFetchStatusText(error);
                }
            });
    }, [selectedFsnbVers]);
    /*eslint-enable */

    const changeVisible = ({ clickedRow }) => {
        setTableData((prev) =>
            prev.map((row) => {
                if (row.id === clickedRow.id) {
                    row.childs_visible = !row.childs_visible;
                    if (row._children !== null) {
                        row._children = row._children.map((ch) => {
                            ch.visible = !ch.visible;
                            return ch;
                        });
                    }
                }
                return row;
            })
        );
    };

    return (
        <div className="FsnbCoefBox FsnbNrspNormBox">
            <div className="FsnbCoefBoxHeader">
                <div className="FsnbCoefBoxHeaderLabel">Сметные нормативы</div>
                {fsnbVersArr.length > 0 && (
                    <div className="FsnbFilters">
                        <FsnbFilterVersSelect
                            label="Редакция ФСНБ"
                            fsnbVersArr={fsnbVersArr}
                            selectedFsnbVers={selectedFsnbVers}
                            f_setSelectedFsnbVers={setSelectedFsnbVers}
                        />
                    </div>
                )}
            </div>
            <div className="FsnbCoefBoxBody">
                {fsnbVersArr.length > 0 && (
                    <div className="FsnbCoefTableLayout" ref={scrollBlock}>
                        <TableMain
                            rows={{ data: tableData }}
                            columns={columnsData}
                            options={{
                                scrollBlock: scrollBlock,
                                displayScrollDelay: 20,
                                funcCall: "local",
                            }}
                            func={{
                                changeVisible: changeVisible,
                            }}></TableMain>
                        <div style={{ height: "5px" }}></div>
                    </div>
                )}
            </div>
            <div className="FsnbCoefBoxFooter">
                <KsprButton variant="text" label="Закрыть" onClick={props.f_closeCoefBox} />
            </div>
            <AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} />
        </div>
    );
}

import RibbonGroup from "./RibbonGroup.jsx";
import RibbonPanel from "./RibbonPanel.jsx";
import RibbonLoader from "./RibbonLoader.jsx";

import "./RibbonContent.css";

const RibbonContent = ({ ribbonSettings, useRibbonPanel, handleEnterPress, useRibbonLoader, loaderStatus }) => {
    const currentRibbonSettings = ribbonSettings;

    return (
        <>
            <div className="ribbon-content">
                {currentRibbonSettings?.map((group, index) => {
                    if (group.isVisible === false) {
                        return null;
                    } else {
                        return <RibbonGroup key={index} groupSettings={group} />;
                    }
                })}
            </div>
            {useRibbonPanel && <RibbonPanel handleEnterPress={handleEnterPress} />}
            {useRibbonLoader && <RibbonLoader status={loaderStatus} useRibbonPanel={useRibbonPanel} />}
        </>
    );
};

export default RibbonContent;

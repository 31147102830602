export function IndexesBimPgTableColumns() {
	return [
		{
			name: "pg_bort_index",
			title: "Автомобили бортовые",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "pg_sam_index",
			title: "Автомобили-самосвалы",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "pg_pan_index",
			title: "Автомобили-панелевозы",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "pg_trub_index",
			title: "Автомобили-трубоплетевозы",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "pg_bet_index",
			title: "Автобетоносмесители",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "letter__name",
			title: "№ письма МС",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
	];
}

export function IndexesRimAutoTruckPgTableColumns() {
	return [
		{
			name: "distance",
			title: "Расстояние перевозки, км",
			width: "auto",
			cellHorAlign: "left",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "class_1_code",
			title: "Код перевозки 1-го класса грузов",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "class_1_cost",
			title: "Сметные цены, руб./т 1-го класса грузов",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "class_2_code",
			title: "Код перевозки 2-го класса грузов",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "class_2_cost",
			title: "Сметные цены, руб./т 2-го класса грузов",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "class_3_code",
			title: "Код перевозки 3-го класса грузов",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "class_3_cost",
			title: "Сметные цены, руб./т 3-го класса грузов",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "class_4_code",
			title: "Код перевозки 4-го класса грузов",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "class_4_cost",
			title: "Сметные цены, руб./т 4-го класса грузов",
			width: "auto",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
	];
}

export function IndexesRimAutoLoadPgTableColumns() {
	return [
		{
			name: "cargo_name",
			title: "Наименование группы грузов",
			width: "auto",
			cellHorAlign: "left",
			headHorAlign: "center",
			rowSpan: "2",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "loading",
			title: "Погрузка",
			width: "300px",
			cellHorAlign: "left",
			headHorAlign: "center",
			headHeight: "10px",
			colSpan: "2",
			children: [
				{
					name: "loading_code",
					title: "Код",
					width: "150px",
					cellHorAlign: "center",
					headHorAlign: "center",
					headHeight: "3310pxpx",
				},
				{
					name: "loading_cost",
					title: "Сметная цена, руб./т",
					width: "150px",
					cellHorAlign: "right",
					headHorAlign: "center",
					headHeight: "10px",
				},
			],
		},
		{
			name: "unloading",
			title: "Разгрузка",
			width: "300px",
			cellHorAlign: "left",
			headHorAlign: "center",
			headHeight: "10px",
			colSpan: "2",
			children: [
				{
					name: "unloading_code",
					title: "Код",
					width: "150px",
					cellHorAlign: "center",
					headHorAlign: "center",
					headHeight: "10px",
				},
				{
					name: "unloading_cost",
					title: "Сметная цена, руб./т",
					width: "150px",
					cellHorAlign: "right",
					headHorAlign: "center",
					headHeight: "10px",
				},
			],
		},
	];
}

export function IndexesRimRailPgTableColumns() {
	return [
		{
			name: "code",
			title: "Код перевозки",
			width: "200px",
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "cargo_name",
			title: "Наименование группы грузов",
			width: "auto",
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "cost",
			title: "Сметная цена в текущем уровне цен, руб.",
			width: "200px",
			cellHorAlign: "right",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
	];
}

import TextField from "@mui/material/TextField";

import "./KsprInput.css";

export default function KsprInput(props) {
	return (
		<div className="KsprInput">
			<TextField
				sx={{
					"& legend": {
						fontSize: "9px",
					},
				}}
				{...props}
			></TextField>
		</div>
	);
}

import { createSlice } from '@reduxjs/toolkit';

export const fsnbSlice = createSlice({
    name: "fsnb",
    initialState: {
        startSearchObj: null,
        startSearchCode: ""
    },
    reducers: {
        setStartSearchObj: (state, { payload }) => {
            state.startSearchObj = payload;
        },
        setStartSearchCode: (state, { payload }) => {
            state.startSearchCode = payload;
        },
    },
});

export const { setStartSearchObj, setStartSearchCode } = fsnbSlice.actions;
export default fsnbSlice.reducer;

import React, { useRef, useState, useEffect } from 'react';
import Draggable from "react-draggable";

import KsprButton from "components/UI/buttons/KsprButton";
import CoefTableColumns from '../TableSetting/CoefTableColumns';
import AlertLoading from "components/AlertLoading";
import { showTableMore } from "../utils";

import useTable from "hooks/useTable";
import apiFsnb from 'api/fsnb';

import FsnbNrspSubBox from './FsnbNrspSubBox';

import "./FsnbCoefBox.css";


export default function FsnbCoefBox(props) {
    const [data, setData] = useState([]);
    const scrollBlock = useRef(null);
    const [fetchStatus, setFetchStatus] = useState(null);
    const [fetchStatusText, setFetchStatusText] = useState("");
    const [nrpsRowsCnt, setNrspRowsCount] = useState(0);

    const columnsData = CoefTableColumns();

    useEffect(() => {
        setFetchStatus("loading");
        const prom = apiFsnb.getNormCoef(props.row.id);
        prom.then((responce) => {
            setData(responce.data);
            setFetchStatus("success");
        }).catch(error => {
            console.error(error)
            setFetchStatus("failed");
            setFetchStatusText(error);
        });
    }, [props.row.id])

    const showMore = (row) => {
        showTableMore(row, "12px", ["name"], setData, "FsnbCoefTableLayout")
    }

    let boxWidth = "90%";
    if (props.width != null) boxWidth = props.width


    const getHeight = (boxName) => {
        if (nrpsRowsCnt === 1) {
            if (boxName === "nrsp") return "59px";
            if (boxName === "nrsp_coef") return "101px";
            if (boxName === "nrsp_and_coef") return "265px";
            if (boxName === "coef") return "calc(100% - 356px)";
        } else if (nrpsRowsCnt === 2) {
            if (boxName === "nrsp") return "81px";
            if (boxName === "nrsp_coef") return "101px";
            if (boxName === "nrsp_and_coef") return "285px";
            if (boxName === "coef") return "calc(100% - 369px)";
        } else if (nrpsRowsCnt === 4) {
            if (boxName === "nrsp") return "122px";
            if (boxName === "nrsp_coef") return "101px";
            if (boxName === "nrsp_and_coef") return "325px";
            if (boxName === "coef") return "calc(100% - 410px)";
        }
    }

    let nrspBox = <></>
    let withNrspBox = "false"
    if (props.showNrsp) {
        nrspBox = <FsnbNrspSubBox
            fetchStatus={fetchStatus}
            setFetchStatus={setFetchStatus}
            fetchStatusText={fetchStatusText}
            setFetchStatusText={setFetchStatusText}
            vers={props.vers}
            row={props.row}
            getHeight={getHeight}
            setNrspRowsCount={setNrspRowsCount} />
        withNrspBox = "true"
    }

    const selectDataLocal = (tableData, clickedRow) => {
        setData(prev => prev.map(row => {
            if (row.id === clickedRow.id) {
                row.selected = true
            } else {
                row.selected = false
            }
            return row
        }));
    };

    const tableBody = useTable(
        data,
        columnsData,
        {
            scrollBlock: scrollBlock,
            scrollHeightControll: 5,
            displayScrollDelay: 20,
            funcCall: "local",
        },
        {
            selectDataLocal: selectDataLocal,
            updateData: setData,
            singleClickRowAction: {
                "tagName": {
                    "allowed": ["td", "span"],
                },
                "action": showMore
            },
        }
    );


    return (
        <Draggable handle=".FsnbCoefBoxHeaderLabel">
            <div className="FsnbCoefBox" style={{ width: boxWidth }} >
                {nrspBox}
                <div className="FsnbCoefBoxHeader">
                    <div className="FsnbCoefBoxHeaderLabel">Коэффициенты</div>
                    {/* <div className="FsnbCoefBoxHeaderName">Coef Name</div> */}
                </div>
                <div className="FsnbCoefBoxBody" with_nrsp_box={withNrspBox} style={{ height: getHeight("coef") }}>
                    <div className="FsnbCoefTableLayout" ref={scrollBlock}>
                        {tableBody}
                    </div>
                </div>
                <div className="FsnbCoefBoxFooter">
                    <KsprButton variant="text" label="Закрыть" onClick={props.f_closeCoefBox} />
                </div>
                <AlertLoading
                    fetchStatus={fetchStatus}
                    fetchStatusText={fetchStatusText}
                    func={setFetchStatus}
                />
            </ div>
        </Draggable >
    )
}
const getTextWidth = (text, font) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width;
};

const prepareStrUrl = (url) => {
    let newUrl = url.replace("//", "/");
    if (newUrl.at(-1) !== "/") newUrl = newUrl + "/";
    return newUrl;
};

const getStrOrEmptyStrIfNull = (txt) => {
    if (txt == null) {
        return "";
    } else {
        return txt;
    }
};

const changeNullAndEmptyStringToDefault = (text, _default) => {
    if (text == null || text === "") {
        return _default;
    } else {
        return String(text);
    }
};

const extractTextBetweenSimbols = (inputString, simbol) => {
    const firstUnderscoreIndex = String(inputString).indexOf(simbol);
    if (firstUnderscoreIndex !== -1) {
        const secondUnderscoreIndex = inputString.indexOf(simbol, firstUnderscoreIndex + 1);
        if (secondUnderscoreIndex !== -1) {
            return inputString.substring(firstUnderscoreIndex + 1, secondUnderscoreIndex);
        }
    }
    return '';
}

export {
    getTextWidth, prepareStrUrl, changeNullAndEmptyStringToDefault,
    getStrOrEmptyStrIfNull, extractTextBetweenSimbols
};

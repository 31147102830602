import { configureStore } from "@reduxjs/toolkit";

import authReducers from "./slices/auth";
import projectsReducers from "./slices/projects";
import resourceSheetReducers from "slices/resource_sheet";
import confirmReducers from "./slices/confirm";
import projectWorkSlice from "./slices/project_work";
import localEstimateSlice from "./slices/local_estimate";
import objectEstimateSlice from "./slices/object_estimate";
import summaryEstimateSlice from "./slices/summary_estimate";
import importFilesSlice from "./slices/import_files";
import katsSlice from "./slices/kats";
import protocolSlice from "./slices/protocol";
import calcerSlice from "./slices/calcer";
import monitorSlice from "./slices/monitor";
import advSearchSlice from "./slices/adv_search";
import fsnbSlice from "./slices/fsnb";
import globalVisibleSlice from "./slices/global_visible";
import newsFeed from "./slices/news_feed";
import ribbon from "slices/ribbon";
import contextMenu from "slices/context_menu";
import windowDimensionsSlice from "slices/window_dimensions";
import localEstimateEditor from "slices/local_estimate_editor";
import userAdvSlice from "slices/user_adv";
import katsEditorSlice from "slices/kats_editor";
import booksSlice from "slices/books";
import priceOfferSlice from "slices/price_offer";
import somSlice from "slices/som";

import thunk from "redux-thunk";

export default configureStore({
	reducer: {
		auth: authReducers,
		projects: projectsReducers,
		resourceSheet: resourceSheetReducers,
		confirm: confirmReducers,
		projectWork: projectWorkSlice,
		localEstimate: localEstimateSlice,
		objectEstimate: objectEstimateSlice,
		summaryEstimate: summaryEstimateSlice,
		importFiles: importFilesSlice,
		kats: katsSlice,
		protocol: protocolSlice,
		calcer: calcerSlice,
		monitor: monitorSlice,
		advSearch: advSearchSlice,
		fsnb: fsnbSlice,
		globalVis: globalVisibleSlice,
		newsFeed: newsFeed,
		ribbon: ribbon,
		windowDimensions: windowDimensionsSlice,
		localEstimateEditor: localEstimateEditor,
		katsEditor: katsEditorSlice,
		contextMenu: contextMenu,
		userAdv: userAdvSlice,
		books: booksSlice,
		priceOffer: priceOfferSlice,
		som: somSlice,
	},
	middleware: [thunk], // https://redux-toolkit.js.org/api/getDefaultMiddleware
});

import { useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";
import GetAppIcon from "@mui/icons-material/GetApp";
import PublicIcon from "@mui/icons-material/Public";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { IconButton, Tooltip } from "@mui/material";

import "./TableDetailsPaper.css";

const TableDetailsPaper = (props) => {
	const dispatch = useDispatch();
	const row = props.row;
	const paperSettings = props.options.paperSettings;
	const icons = {
		PublicIcon: <PublicIcon />,
		InfoOutlinedIcon: <InfoOutlinedIcon />,
	};

	let leftTopList = [];
	let leftBottomList = [];
	let arr = [
		{ key: "top", domArr: leftTopList },
		{ key: "bottom", domArr: leftBottomList },
	];

	for (let obj of arr) {
		for (let x of paperSettings[obj.key]) {
			let $elm;
			if (x.component === "TextField") {
				$elm = (
					<TextField
						sx={{ "& legend": { fontSize: "10px" } }}
						disabled
						id="outlined-size-small"
						title={row[x.row_key]}
						key={obj.key}
						label={x.label}
						defaultValue={row[x.row_key]}
						size="small"
						InputProps={{
							readOnly: true,
							inputProps: {
								style: { textAlign: x.textAlign },
							},
						}}
					/>
				);
			} else if (x.component === "BoldSimpleLabel") {
				$elm = (
					<div className="BoldSimpleLabel" title={row[x.row_key]}>
						{row[x.row_key]}
					</div>
				);
			} else if (x.component === "IconLink") {
				$elm = (
					<div className="IconLink">
						<Tooltip title={row[x.row_key]}>
							<IconButton aria-label={row[x.row_key]} is_active={x?.isActive(row)} onClick={() => x?.callback(row)}>
								{icons[x.icon]}
							</IconButton>
						</Tooltip>
					</div>
				);
			} else if (x.component === "СustomJsx") {
				$elm = x.jsx(row);
			}

			obj.domArr.push(
				<div className="TablePaperTextFieldWrapper" key={x.row_key} style={{ width: x.width }} clm={x.row_key}>
					{$elm}
				</div>
			);
		}
	}

	const $rightBox = [];
	for (let rSet of paperSettings.rightBox) {
		if (rSet.type === "List") {
			let i = 0;
			$rightBox.push(<div key={"RigthBoxListElmLabel" + i + String(rSet.row_key)}>{rSet.label}</div>);
			for (let x of row[rSet.row_key]) {
				let icon;
				if (x) icon = <GetAppIcon />;
				$rightBox.push(
					<div
						key={"RigthBoxListElm" + i + String(rSet.row_key)}
						className="RigthBoxListElm"
						onClick={() =>
							props.func?.getJustFile({
								offerId: props?.parent?.row?.id,
								fileName: x.name,
								fileId: x.id,
								rowObj: row,
								rowObjParent: props?.parent?.row,
							})
						}
					>
						<div className="RigthBoxListElmName" title={x?.name}>
							{icon}
							{x?.name}
						</div>
					</div>
				);
				i++;
			}
		} else if (rSet.type === "Elem") {
			let checkedRule = true;
			if (rSet.rule) {
				if (row[rSet.rule.key] !== rSet.rule.value) checkedRule = false;
			}
			if (checkedRule) {
				$rightBox.push(<div key={"RigthBoxListElmLabel" + String(rSet.row_key)}>{rSet.label}</div>);
				$rightBox.push(
					<div
						key={"RigthBoxListElm" + String(rSet.row_key)}
						className="RigthBoxListElm"
						onClick={() => props.func?.openSourceInfo({ paperRow: row })}
					>
						<div className="RigthBoxListElmName" title={row[rSet.row_key]}>
							<InfoOutlinedIcon />
							{row[rSet.row_key]}
						</div>
					</div>
				);
			}
		}
	}

	return (
		<tr key={"paper-main-" + row["id"]}>
			<td colSpan={props.columnsFlat?.length}>
				<div className="TableKatsDetailsPaper" style={{ maxHeight: "85px" }}>
					<div className="LeftBox">
						<div className="LeftTopBox">{leftTopList}</div>
						<div className="LeftBottomBox">{leftBottomList}</div>
					</div>
					<div className="RigthBox">
						<div className="RigthBoxLabel">{$rightBox}</div>
					</div>
					<div className="ClosePaper">
						<Tooltip title="Закрыть">
							<IconButton
								aria-label="Закрыть"
								onClick={() => {
									if (props?.options?.funcCall === "local") {
										props.func.changePaperDetailsVisible({
											clickedRow: props.parent.row,
											tableData: props.tableData,
											val: false,
										});
									} else {
										dispatch(props.func.changePaperDetailsVisible(props.parent, false));
									}
								}}
							>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</td>
		</tr>
	);
};

export { TableDetailsPaper };

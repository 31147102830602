import { createSlice } from "@reduxjs/toolkit";

export const priceOfferSlice = createSlice({
	name: "price_offer",
	initialState: {
		clickedTableRow: null,
	},
	reducers: {
		setClickedTableRow: (state, { payload }) => {
			state.clickedTableRow = payload;
		},
	},
});

export const { setClickedTableRow } = priceOfferSlice.actions;

export default priceOfferSlice.reducer;

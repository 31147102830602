import { pushToClickedTableCtrlRows, removeFromClickedTableCtrlRows, clearClickedTableCtrlRows } from "slices/ribbon";

const getSelectedItemsSum = ({ clickedTableCtrlRows }) => {
	let sumItems = 0;
	for (let item of clickedTableCtrlRows) {
		if (!item[item.pressedClm]) continue;
		const rawVal = item[item.pressedClm];
		if (!rawVal) continue;
		const editVal = String(rawVal).replaceAll(" ", "").replaceAll(",", ".");
		const floatVal = parseFloat(editVal);
		if (!floatVal) continue;

		sumItems += floatVal;
	}
	return String(Number(parseFloat(sumItems).toFixed(7))).replace(",", ".");
};

const pushToClickedRows = ({ rowObj, pressedClm, changeRowData, dispatch }) => {
	dispatch(pushToClickedTableCtrlRows({ ...rowObj, pressedClm: pressedClm }));
	dispatch(
		changeRowData({
			rowKey: rowObj.key,
			callback: (row) => {
				if (row.row_type_style) {
					if (!String(row.row_type_style).includes(`__${pressedClm}__`)) {
						row.row_type_style = `${row.row_type_style}__${pressedClm}__`;
					}
				} else {
					row.row_type_style = `ctrl_multi_select__${pressedClm}__`;
				}
			},
		})
	);
};

const removeFromClickedRows = ({ rowObj, pressedClm, changeRowData, dispatch }) => {
	dispatch(
		changeRowData({
			rowKey: rowObj.key,
			callback: (row) => {
				if (row.row_type_style) {
					row.row_type_style = String(row.row_type_style).replace(`__${pressedClm}__`, "");
					if (row.row_type_style === "ctrl_multi_select") row.row_type_style = null;
				}
			},
		})
	);
	dispatch(removeFromClickedTableCtrlRows({ key: rowObj.key, pressedClm: pressedClm }));
};

const clearClickedRows = ({ clickedTableCtrlRows, changeRowData, dispatch }) => {
	dispatch(clearClickedTableCtrlRows());
	for (let ctrlRow of clickedTableCtrlRows) {
		dispatch(
			changeRowData({
				rowKey: ctrlRow.key,
				callback: (row) => (row.row_type_style = null),
			})
		);
	}
};

export { getSelectedItemsSum, pushToClickedRows, removeFromClickedRows, clearClickedRows };

export default function IndexesEqpTableColumns() {
    return [
        {
            name: 'industry_type__name',
            title: 'Отрасли народного хозяйства и промышленности',
            width: 'auto',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'eqp_index',
            title: 'Индексы на оборудование к уровню цен по состоянию на 01.01.2000',
            width: '220px',
            cellHorAlign: 'right',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'letter__name',
            title: '№ письма МС',
            width: '200px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        }
    ];
}
import FooterItem from "./FooterItem.jsx";
import "./Footer.css";

const Footer = ({ footerItemSettings }) => {
	return (
		<div className="footer">
			{footerItemSettings.map((tab, index) => {
				if (tab.isVisible !== false) {
					return <FooterItem key={index} {...tab} />;
				} else {
					return <span key={index}></span>;
				}
			})}
		</div>
	);
};

export default Footer;

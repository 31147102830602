import CreateIcon from "@mui/icons-material/Create";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import ReportOffOutlinedIcon from "@mui/icons-material/ReportOffOutlined";

import CellIconWithAction from "./CellFormatter/CellIconWithAction";
import CellWithAdditionalIcon from "./CellFormatter/CellWithAdditionalIcon";

import { CellEditing } from "./CellFormatter/CellEditing";
import { CellEditingSelect } from "./CellFormatter/CellEditingSelect";
import { CellCheckBox } from "./CellFormatter/CellCheckBox";

import { getCellStyle, getIsCellParams } from "./utils/cell_utils";
import { getArgOrCallback } from "core/args_wrapper";

export default function Cell({ rowObj, clmObj, props }) {
	const dispatch = props.dispatch;
	const clickedTableClmName = props.rowOptions.clickedTableClmName;
	const childsVisibleParent = props.childsVisible;
	const errorsVisibleParent = props.errorsVisible;

	const clmName = clmObj.name;

	const { isClmSelectable, isCellEditable, defaultSelectVal } = getIsCellParams({ rowObj, clmName, props });
	const isRowSelected = rowObj.selected;
	const isCellEditing = rowObj.inline_cell === clmName;

	let cellValue = rowObj[clmName];
	let cellTitle = rowObj[clmName];

	if (clmObj.hasOwnProperty("cellJsx")) {
		return clmObj.cellJsx({ rowObj, clmName, clickedTableClmName, parentProps: props, funcs: props.func, clmObj });
	} else if (clmObj.hasOwnProperty("isCheckBox")) {
		return (
			<CellCheckBox rowObj={rowObj} clmName={clmName} setCheckedFunc={props.func?.setCheckedFunc} key={`${rowObj?.id}-checkbox-${clmName}`} />
		);
	}

	let colSpan = 0;
	if (clmObj.hasOwnProperty("colSpan")) {
		colSpan = getArgOrCallback(clmObj.colSpan, rowObj);
	}
	let treeCell = "false";

	let backendTitle;
	if (rowObj[`${clmName}__title`] != null) backendTitle = rowObj[`${clmName}__title`];

	let actionIconOnCell;
	if (clmObj.hasOwnProperty("actions_icon_on_cell")) {
		actionIconOnCell = (
			<CellWithAdditionalIcon row={rowObj} clm={clmObj} rowOptions={props.rowOptions} funcs={props.func} parentRow={props.parentRow} />
		);

		cellValue = (
			<div className="TreeFlexCell" title={cellTitle}>
				<span
					className="TreeFlexCellSpan"
					title={cellTitle}
					clm={clmName}
					style={getCellStyle(rowObj, props.rowOptions, clmObj)}
					lang="ru-RU"
				>
					{cellTitle}
				</span>
				{actionIconOnCell}
			</div>
		);
	}

	if ("colSpan" in rowObj) {
		if (rowObj["colSpanTarget"] === clmObj["name"] && clmObj["name"] !== "check") {
			colSpan = rowObj["colSpan"];
			treeCell = "true";
			let iconVible;
			let rowType;
			if (rowObj.hasOwnProperty("_children") && rowObj["isParent"] !== false) {
				rowType = "Group";
				if (childsVisibleParent === true) {
					iconVible = <ArrowDropDownIcon row_type="Group" clm={clmName} />;
				} else {
					iconVible = <ArrowRightIcon row_type="Group" clm={clmName} />;
				}
			}
			let tableFlexCelltitle = "";
			if (backendTitle != null) {
				tableFlexCelltitle = backendTitle;
			} else if (cellTitle != null) {
				tableFlexCelltitle = String(cellTitle);
			}
			let grpAddinalData;
			if (clmObj.grpAddinalData) {
				grpAddinalData = clmObj.grpAddinalData({ rowObj });
			}
			cellValue = (
				<div className="TreeFlexCell" row_type={rowType} clm={clmName} title={tableFlexCelltitle} test={tableFlexCelltitle}>
					{iconVible}
					<span
						className="TreeFlexCellSpan"
						clm={clmName}
						lang="ru-RU"
						row_type={rowType}
						title={tableFlexCelltitle}
						style={getCellStyle(rowObj, props.rowOptions, clmObj)}
					>
						{cellTitle}
					</span>
					{actionIconOnCell}
					{grpAddinalData}
				</div>
			);
		} else if (rowObj.hasOwnProperty("errors_visible") && clmObj["name"] === "check") {
			if (rowObj.hasOwnProperty("_details")) {
				let iconVible;
				let rowType;
				rowType = "ErrorsDetails";
				let errCellStyle = { color: "red", justifyContent: "center" };
				if (rowObj["_details"].length > 0) {
					if (errorsVisibleParent === true) {
						iconVible = <ReportOffOutlinedIcon style={{ fontSize: "17px" }} row_type="ErrorsDetails" />;
						errCellStyle = {
							color: "red",
							justifyContent: "center",
						};
						cellTitle = "Закрыть";
					} else {
						iconVible = <ReportOutlinedIcon style={{ fontSize: "17px" }} row_type="ErrorsDetails" />;
						cellTitle = "Открыть";
					}
				}

				cellValue = (
					<div className="TreeFlexCell" row_type={rowType} style={errCellStyle} clm={clmName}>
						{iconVible}
						{actionIconOnCell}
					</div>
				);
			}
		}
	}

	if (clmObj.hasOwnProperty("action")) {
		if (clmObj.hasOwnProperty("action_show")) {
			if (props.rowClassName !== "TableRow Selected") {
				return;
			}
		}
		cellValue = <CellIconWithAction row={rowObj} clm={clmObj} rowOptions={props.rowOptions} funcs={props.func} />;
	}

	if (props.func.customCallback) cellValue = props.func.customCallback(cellValue, clmName);

	const key = `cell-${clmObj["name"]}-${props.row["id"]}`;
	let style = { textAlign: clmObj["cellHorAlign"], width: clmObj["width"], maxWidth: clmObj["width"], minWidth: clmObj["minWidth"] };

	let whiteSpace;
	if (props.row["rowHeightMultiply"] != null) {
		whiteSpace = "normal";
	}
	style.whiteSpace = whiteSpace;

	let fColor;
	if (rowObj["highlight_font"] != null) {
		fColor = rowObj["highlight_font"];
	}
	style.color = fColor;
	if (rowObj?._style?._cell) style = { ...style, ...rowObj._style._cell };

	if (clmObj.cellCustomStyle) style = { ...style, ...clmObj.cellCustomStyle };

	if (clmObj["name"] === props.treeClmn) {
		if (props.childLevel > 0) {
			let paddingLeft = 20;
			if (props.rowOptions.treeChildPaddingLeft) paddingLeft = props.rowOptions.treeChildPaddingLeft;
			style["paddingLeft"] = props.childLevel * paddingLeft + "px";
			if (rowObj.hasOwnProperty("paddingLeft")) {
				style["paddingLeft"] = props.childLevel * paddingLeft + rowObj["paddingLeft"] + "px";
			}
		}
	}

	const getRowType = () => {
		if (rowObj.hasOwnProperty("_paper_details")) {
			return "PaperDetails";
		}
	};

	if (isClmSelectable) {
		if (isRowSelected && isCellEditable) {
			return (
				<CellEditingSelect
					key={key}
					style={style}
					clmName={clmName}
					colSpan={colSpan}
					cellTitle={cellTitle}
					props={props}
					rowObj={rowObj}
					clmObj={clmObj}
					clickedTableClmName={clickedTableClmName}
				/>
			);
		} else {
			return (
				<td
					key={key}
					className="TableCell"
					clm={clmName}
					treecell={treeCell}
					is_clicked_clm_name={String(defaultSelectVal === clickedTableClmName)}
					title={cellTitle == null ? "" : cellTitle.toString()}
					style={style}
					colSpan={colSpan}
				>
					{defaultSelectVal}
				</td>
			);
		}
	}

	if (isCellEditable) {
		if (isCellEditing) {
			return (
				<CellEditing
					key={key}
					style={style}
					clmName={clmName}
					colSpan={colSpan}
					cellTitle={cellTitle}
					props={props}
					rowObj={rowObj}
					clmObj={clmObj}
				/>
			);
		} else if (isRowSelected) {
			return (
				<td
					key={key}
					className="TableCell"
					clm={clmName}
					title={cellTitle == null ? "" : cellTitle.toString()}
					style={style}
					is_clicked_clm_name={String(clmName === clickedTableClmName)}
					colSpan={colSpan}
				>
					<div className="TableCellEditable">
						<div className="TableCellEditableValue">{cellValue}</div>
						{props.rowOptions.useEditIcon !== false && rowObj.id !== "-1" && (
							<CreateIcon
								className="editData_btn"
								onClick={() => {
									const editObj = { key: rowObj["key"], inline_cell: clmName };
									if (props.func["editData"] != null) {
										dispatch(props.func["editData"](editObj));
									} else if (props.func["editDataLocal"] != null) {
										props.func["editDataLocal"](props.tableData, editObj);
									} else if (props.func["editDataFactory"] != null) {
										props.func["editDataFactory"]({ rowObj: rowObj, dataObj: editObj });
									}
								}}
							/>
						)}
					</div>
				</td>
			);
		}
	}

	return (
		<td
			key={key}
			className="TableCell"
			row_type={getRowType()}
			clm={clmName}
			treecell={treeCell}
			is_clicked_clm_name={String(clmName === clickedTableClmName)}
			title={cellTitle == null ? "" : cellTitle.toString()}
			style={style}
			colSpan={colSpan}
		>
			{cellValue}
		</td>
	);
}

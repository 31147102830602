import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiProtocol from "../api/protocol";
import { downloadFile } from "core/request_utils";
import { changeRowDataRecourse } from "./utils";

export const getProtocol = createAsyncThunk("protocolSlice/get", async (obj) => {
	const response = await apiProtocol.get(obj["type"], obj["id"]);
	return response.data;
});

export const getProtocolExcel = createAsyncThunk("protocolSlice/getExcel", async (obj) => {
	const response = await apiProtocol.getExcel(obj["type"], obj["id"]);
	downloadFile(response);
});

export const getSelectedTypes = createAsyncThunk("protocolSlice/getSelectedTypes", async (id) => {
	const response = await apiProtocol.getSelectedTypes(id);
	return response.data;
});

export const getUserSetCritOnly = createAsyncThunk("protocolSlice/getUserSetCritOnly", async (obj) => {
	const response = await apiProtocol.getUserSetCritOnly(obj["user_id"], obj["project_id"]);
	return response.data;
});

export const setUserSetCritOnly = createAsyncThunk("protocolSlice/setUserSetCritOnly", async (obj) => {
	const response = await apiProtocol.putUserSetCritOnly(obj["user_id"], obj["project_id"], obj["body"]);
	return response.data;
});

export const getUserSetGpr = createAsyncThunk("protocolSlice/getUserSetGpr", async (obj) => {
	const response = await apiProtocol.getUserSetGpr(obj["user_id"], obj["project_id"]);
	return response.data;
});

export const postUserSetGpr = createAsyncThunk("protocolSlice/postUserSetGpr", async (obj) => {
	const response = await apiProtocol.postUserSetGpr(obj["user_id"], obj["project_id"], obj["body"]);
	return response.data;
});

export const getUserSetExcept = createAsyncThunk("protocolSlice/getUserSetExcept", async (obj) => {
	const response = await apiProtocol.getUserSetExcept(obj["user_id"], obj["project_id"]);
	return response.data;
});

export const delUserSetExcept = createAsyncThunk("protocolSlice/delUserSetExcept", async (obj) => {
	const response = await apiProtocol.delUserSetExcept(obj["user_id"], obj["project_id"], obj["id"]);
	return response.data;
});

export const postUserSetExcept = createAsyncThunk("protocolSlice/postUserSetExcept", async (obj) => {
	const response = await apiProtocol.postUserSetExcept(obj["user_id"], obj["project_id"], obj);
	return response.data;
});

export const getUserSetAllowErPerc = createAsyncThunk("protocolSlice/getUserSetAllowErPerc", async (obj) => {
	const response = await apiProtocol.getUserSetAllowErPerc(obj["user_id"], obj["project_id"]);
	return response.data;
});

export const setUserSetAllowErPerc = createAsyncThunk("protocolSlice/setUserSetAllowErPerc", async (obj) => {
	const response = await apiProtocol.putUserSetAllowErPerc(obj["user_id"], obj["project_id"], obj);
	return response.data;
});

export const getUserSetErrorStatus = createAsyncThunk("protocolSlice/getUserSetErrorStatus", async (obj) => {
	const response = await apiProtocol.getUserSetErrorStatus(obj["user_id"], obj["project_id"]);
	return response.data;
});

export const postUserSetErrorStatus = createAsyncThunk("protocolSlice/postUserSetErrorStatus", async (obj) => {
	const response = await apiProtocol.postUserSetErrorStatus(obj["user_id"], obj["project_id"], obj["errors_status_id"]);
	return response.data;
});

export const protocolSlice = createSlice({
	name: "protocolSlice",
	fetchStatus: null,
	initialState: {
		obj: {},
		data: [],
		selectTypeData: [],
		criticalOnly: false,
		allowErPerc: 0,
		gprVisible: [],
		reGetProtocol: false,
		usrExceptions: [],
		usrErrorsStatus: [],
	},
	reducers: {
		setFetchStatus: (state, { payload }) => {
			state.fetchStatus = payload;
		},
		selectData: (state, { payload }) => {
			state.data = state.data.map((row) => {
				if (row["key"] === payload) {
					row["selected"] = true;
				} else {
					row["selected"] = false;
				}
				return row;
			});
		},
		setCriticalOnly: (state, { payload }) => {
			state.criticalOnly = payload;
		},
		setGprVisibley: (state, { payload }) => {
			state.gprVisible = payload;
		},
		setReGetProtocol: (state, { payload }) => {
			state.reGetProtocol = payload;
		},
		setAllowErPerc: (state, { payload }) => {
			state.allowErPerc = payload;
		},
		setData: (state, { payload }) => {
			state.data = payload;
		},
		setSelectTypeData: (state, { payload }) => {
			state.selectTypeData = payload;
		},
		changeRowKeyValue: (state, { payload: { rowKey, stepId, key, value } }) => {
			state.data = state.data.map((x) => {
				if (x.key === rowKey && x.step_id === stepId) {
					x[key] = value;
				}
				return x;
			});
		},
		changeRowData: (state, { payload: { rowKey, callback } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.data = changeRowDataRecourse(state.data, rowKey, callback);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getProtocol.fulfilled, (state, action) => {
			state.obj = action["payload"]["obj"];
			state.data = action["payload"]["data"];
			state.fetchStatus = "success";
			state.reGetProtocol = false;
		});

		builder.addCase(getSelectedTypes.fulfilled, (state, { payload }) => {
			state.selectTypeData = payload;
			state.fetchStatus = "success";
		});

		builder.addCase(getUserSetCritOnly.fulfilled, (state, { payload }) => {
			state.criticalOnly = payload["val"];
			state.fetchStatus = "success";
		});

		builder.addCase(setUserSetCritOnly.fulfilled, (state, { payload }) => {
			state.fetchStatus = "success";
			state.reGetProtocol = true;
		});

		builder.addCase(getUserSetGpr.fulfilled, (state, { payload }) => {
			state.gprVisible = payload;
			state.fetchStatus = "success";
		});

		builder.addCase(postUserSetGpr.fulfilled, (state, action) => {
			state.fetchStatus = "success";
			state.reGetProtocol = true;
		});

		builder.addCase(getUserSetExcept.fulfilled, (state, { payload }) => {
			state.usrExceptions = payload;
			state.fetchStatus = "success";
		});

		builder.addCase(delUserSetExcept.fulfilled, (state, action) => {
			state.usrExceptions = state.usrExceptions.filter((x) => x["id"] !== action["meta"]["arg"]["id"]);
			state.reGetProtocol = true;
			state.fetchStatus = "success";
		});

		builder.addCase(postUserSetExcept.fulfilled, (state, { payload }) => {
			state.usrExceptions.push(payload);
			state.reGetProtocol = true;
			state.fetchStatus = "success";
		});

		builder.addCase(getUserSetAllowErPerc.fulfilled, (state, { payload }) => {
			state.allowErPerc = payload["val"] * 100;
			state.fetchStatus = "success";
		});

		builder.addCase(setUserSetAllowErPerc.fulfilled, (state, { payload }) => {
			state.fetchStatus = "success";
			state.reGetProtocol = true;
		});

		builder.addCase(getProtocolExcel.fulfilled, (state, action) => {
			state.fetchStatus = "success";
		});

		builder.addCase(getUserSetErrorStatus.fulfilled, (state, { payload }) => {
			state.usrErrorsStatus = payload;
			state.fetchStatus = "success";
		});

		builder.addCase(postUserSetErrorStatus.fulfilled, (state, { payload }) => {
			state.fetchStatus = "success";
			state.reGetProtocol = true;
		});

		builder.addMatcher(
			isAnyOf(
				getProtocol.pending,
				getSelectedTypes.pending,
				getUserSetCritOnly.pending,
				setUserSetCritOnly.pending,
				getUserSetGpr.pending,
				postUserSetGpr.pending,
				getUserSetExcept.pending,
				delUserSetExcept.pending,
				postUserSetExcept.pending,
				getUserSetAllowErPerc.pending,
				setUserSetAllowErPerc.pending,
				getProtocolExcel.pending,
				getUserSetErrorStatus.pending,
				postUserSetErrorStatus.pending
			),
			(state, action) => {
				state.fetchStatus = "loading";
			}
		);

		builder.addMatcher(
			isAnyOf(
				getProtocol.rejected,
				getSelectedTypes.rejected,
				getUserSetCritOnly.rejected,
				setUserSetCritOnly.rejected,
				getUserSetGpr.rejected,
				postUserSetGpr.rejected,
				getUserSetExcept.rejected,
				delUserSetExcept.rejected,
				postUserSetExcept.rejected,
				getUserSetAllowErPerc.rejected,
				setUserSetAllowErPerc.rejected,
				getProtocolExcel.rejected,
				getUserSetErrorStatus.rejected,
				postUserSetErrorStatus.rejected
			),
			(state, action) => {
				state.fetchStatus = "failed";
			}
		);
	},
});

export const {
	setFetchStatus,
	selectData,
	setCriticalOnly,
	setGprVisibley,
	setReGetProtocol,
	changeRowKeyValue,
	setData,
	setSelectTypeData,
	changeRowData,
} = protocolSlice.actions;

export default protocolSlice.reducer;

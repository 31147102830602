import { TextField } from '@mui/material';
// import { checkTextIsDigit } from "core/digit_utils";

const checkTextIsDigit = (text) => {
    if (typeof text === 'number' || (typeof text === 'string' && !isNaN(text))) {
        return true;
    } else return false;

}


export const MonitorDetailsTextField = (props) => {

    const handleChange = (e) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/,/g, '.');
        if (checkTextIsDigit(inputValue)) {
            e.target.value = inputValue;
            props.onChange(e);
        }
    };

    return (
        <TextField
            id="outlined-size-small"
            size="small"
            {...props}
            onChange={handleChange}
        />
    )
}

import { useEffect } from "react";

import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

import KsprButton from "components/UI/buttons/KsprButton";

import "./ColumnsSettings.css";

const ColumnsSettings = ({ clmArr, selectedClmArr, setSelectedOpts, handleSelectClick }) => {
	useEffect(() => {
		if (!selectedClmArr) return;
		if (selectedClmArr.length === 0) return;
		handleSelectClick();
	}, [selectedClmArr]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!clmArr) return;

	const handleChange = (row) => {
		setSelectedOpts((prev) => {
			const inArray = prev.find((x) => x.id === row.id);
			if (inArray == null) {
				return [...prev, row];
			} else {
				return prev.filter((x) => x.id !== row.id);
			}
		});
	};

	return (
		<div className="ColumnsSettings">
			{clmArr.map((opt) => (
				<MenuItem
					key={opt.id}
					title={opt.name}
					onClick={(e) => handleChange(opt)}
					selected={selectedClmArr.filter((x) => x.id === opt.id).length === 1}
				>
					<Checkbox checked={selectedClmArr.filter((x) => x.id === opt.id).length === 1} disabled={opt.disabled}></Checkbox>
					<ListItemText primary={opt.name} />
				</MenuItem>
			))}
			<KsprButton
				variant="text"
				label="сохранить"
				onClick={() => handleSelectClick()}
				style={{ paddingTop: 0, paddingBottom: 0, fontSize: "11px", marginLeft: "auto" }}
			/>
		</div>
	);
};

export default ColumnsSettings;

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";

const RibbonElementRadio = ({ elementSettings }) => {
	const { radioArray, isDisabled, style } = elementSettings;
	const currentPage = useSelector((state) => state.ribbon.currentPage);

	return (
		<FormControl disabled={isDisabled} style={style}>
			<RadioGroup
				disabled={isDisabled}
				className="ribbon-radio-buttons-group-label"
				aria-labelledby="ribbon-radio-buttons-group-label"
				name="radio-buttons-group"
			>
				{radioArray.map((item, index) => (
					<FormControlLabel
						className={isDisabled ? "radio-buttons-group--disable" : ""}
						checked={item.currentPage.includes(currentPage)}
						onClick={item.callback}
						key={index}
						control={
							<Radio
								size="small"
								sx={{
									"& .MuiSvgIcon-root": {
										fontSize: 13,
									},
								}}
							/>
						}
						label={item.name}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};

export default RibbonElementRadio;

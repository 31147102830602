import RibbonContent from "../../components/Ribbon/RibbonContent/RibbonContent";
import { useSelector } from "react-redux";

const EstimateRibbonContentWrapper = ({ EstimateRibbonSettings, ProtocolRibbonSettings, handleEnterParentSearchPress }) => {
	const currentPage = useSelector((state) => state.ribbon.currentPage);

	const currentRibbon = () => {
		if (currentPage === "LocalEstimateViewer") {
			return <RibbonContent ribbonSettings={EstimateRibbonSettings} useRibbonPanel handleEnterPress={handleEnterParentSearchPress} />;
		} else if (currentPage === "SummaryEstimateViewer") {
			return <RibbonContent ribbonSettings={EstimateRibbonSettings} useRibbonPanel handleEnterPress={handleEnterParentSearchPress} />;
		} else if (currentPage === "ObjectEstimateViewer") {
			return <RibbonContent ribbonSettings={EstimateRibbonSettings} useRibbonPanel handleEnterPress={handleEnterParentSearchPress} />;
		} else if (currentPage === "KatsEstimateViewer") {
			return <RibbonContent ribbonSettings={EstimateRibbonSettings} useRibbonPanel handleEnterPress={handleEnterParentSearchPress} />;
		} else if (currentPage === "KatsProjectEstimateViewer") {
			return <RibbonContent ribbonSettings={EstimateRibbonSettings} useRibbonPanel handleEnterPress={handleEnterParentSearchPress} />;
		} else if (currentPage === "StartEstimateViewer") {
			return <RibbonContent ribbonSettings={EstimateRibbonSettings} useRibbonPanel handleEnterPress={handleEnterParentSearchPress} />;
		} else if (currentPage === "ProtocolViewer") {
			return <RibbonContent ribbonSettings={ProtocolRibbonSettings} useRibbonPanel handleEnterPress={handleEnterParentSearchPress} />;
		}
	};

	return (
		<div className="ribbon-content-wrapper">
			{/* <RibbonContent ribbonSettings={EstimateRibbonSettings} /> */}
			{currentRibbon()}
		</div>
	);
};

export default EstimateRibbonContentWrapper;

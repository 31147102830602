import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiProjects from "api/projects";
import apiProjectsPack from "api/projects_pack";
import apiCalcer from "api/calcer";
import { changeNestedArrKeyValueOnCondition } from "core/iterable_utils";

export const fetchProjectsList = createAsyncThunk("projects/fetchProjectsList", async (body, { rejectWithValue }) => {
	try {
		const response = await apiProjects.getList(body);
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err);
	}
});

export const deleteProject = createAsyncThunk("projects/deleteProject", async (id, { rejectWithValue }) => {
	const response = await apiProjects.delete(id);
	return response.data;
});

export const deleteProjectPack = createAsyncThunk("projects/deleteProjectPack", async (id, { rejectWithValue }) => {
	const response = await apiProjectsPack.delete(id);
	return response.data;
});

export const createProject = createAsyncThunk("projects/createProject", async (body) => {
	const response = await apiProjects.create(body);
	return response.data;
});

export const createProjectPack = createAsyncThunk("projects/createProjectPack", async (body) => {
	const response = await apiProjectsPack.create(body);
	return response.data;
});

export const patchProject = createAsyncThunk("projects/patchProject", async (body) => {
	const response = await apiProjects.edit(body["id"], body);
	return response.data;
});

export const patchProjectPack = createAsyncThunk("projects/patchProject", async (body) => {
	const response = await apiProjectsPack.edit(body["id"], body);
	return response.data;
});

export const fetchNorthRegTerrs = createAsyncThunk("projects/fetchNorthRegTerrs", async (id, { rejectWithValue }) => {
	try {
		const response = await apiCalcer.getNorthRegTerrs(id);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const genEmptyRow = ({ model_name }) => {
	return {
		id: null,
		selected: true,
		code: "",
		name: "",
		date_create: "",
		model_name: model_name,
		key: model_name,
		north_reg_terr_id: 1,
		inline_cell: "name",
	};
};

export const projectsSlice = createSlice({
	name: "projectsSlice",
	fetchStatus: null,
	fetchStatusText: null,
	initialState: {
		table: {
			data: null,
			all_data_count: 0,
			lastSelected: null,
			selecting: {
				work_types: [],
				north_reg_terrs: [],
			},
			filtering: [],
			viewParams: {
				sort: {
					code: null,
					name: null,
					date_create: null,
					username: null,
					_order: ["code", "name", "date_create", "username"],
				},
				filter: {
					code: null,
					name: null,
					date_create: null,
					username: null,
				},
				isEmpty: true,
			},
		},
	},
	reducers: {
		pushNewEmpty: (state, { payload }) => {
			state.table["data"] = state.table["data"].map((row) => {
				row.selected = false;
				return row;
			});
			if (state.table["data"].filter((x) => x["id"] === null).length === 0) {
				const newRow = genEmptyRow({ model_name: payload.model_name });
				if (payload.model_name === "UserProjectPacks") {
					newRow["_children"] = [];
					newRow["colSpan"] = 1;
					newRow["colSpanTarget"] = "name";
					newRow["childs_visible"] = true;
				}
				state.table["data"] = [newRow, ...state.table["data"]];
			}
		},
		pushNewProjectToPack: (state, { payload: { rowObj } }) => {
			state.table["data"] = state.table["data"].map((row) => {
				if (row.id === rowObj.id) {
					row.selected = false;
					if (row?._children == null) {
						row._children = [];
					} else {
						row._children.forEach((x) => (x.selected = false));
					}
					row._children.push({
						...genEmptyRow({ model_name: "UserProjects" }),
						project_pack_id: row.id,
						row_type_style: "project_in_pack",
					});
				}
				return row;
			});
		},
		editProject: (state, { payload }) => {
			const recourceEdit = (row, rowId) => {
				if (row.key === payload.key && row.model_name === payload.model_name) {
					Object.keys(payload).forEach((key) => (row[key] = payload[key]));
				}
				if (row._children) row._children = row._children.map((x) => recourceEdit(x, rowId));
				return row;
			};
			state.table["data"] = state.table["data"].map((row) => recourceEdit(row, payload));
		},
		addProject: (state, { payload }) => {
			const recourceEdit = (row, rowId) => {
				if (row.id === null) Object.keys(payload).forEach((key) => (row[key] = payload[key]));
				if (row._children) row._children = row._children.map((x) => recourceEdit(x, rowId));
				return row;
			};
			state.table["data"] = state.table["data"].map((row) => recourceEdit(row, payload));
		},
		setFetchStatus: (state, { payload }) => {
			state.fetchStatus = payload;
			state.fetchStatusText = null;
		},
		setFetchStatusText: (state, { payload }) => {
			state.fetchStatusText = null;
		},

		setTableData: (state, { payload }) => {
			state.table.data = payload;
		},
		setLastSelected: (state, { payload }) => {
			state.lastSelected = payload;
		},
		setTableViewParams: (state, { payload }) => {
			if (payload.sort) {
				const sortKey = Object.keys(payload.sort)[0];
				state.table.viewParams = {
					...state.table.viewParams,
					sort: { ...state.table.viewParams.sort, [sortKey]: payload.sort[sortKey] },
				};
				state.table.viewParams.sort._order = state.table.viewParams.sort._order.filter((x) => x !== sortKey);
				state.table.viewParams.sort._order.push(sortKey);
				state.table.viewParams.isEmpty = false;
			} else if (payload.filter) {
				for (let fltObj of payload.filter) {
					if (fltObj.value != null) {
						state.table.viewParams.filter[fltObj.name] = fltObj.value;
						state.table.viewParams.isEmpty = false;
					}
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProjectsList.fulfilled, (state, { payload }) => {
			state.table["data"] = payload["data"];
			state.table["all_data_count"] = payload["all_data_count"];

			if (state?.lastSelected?.key) {
				state.table["data"] = changeNestedArrKeyValueOnCondition({
					arr: state.table["data"],
					keySearch: "key",
					valSearch: state.lastSelected.key,
					keyChange: "selected",
					valChange: true,
				});
			}
			state.fetchStatus = "success";
		});

		builder.addCase(fetchNorthRegTerrs.fulfilled, (state, { payload }) => {
			state.table["selecting"]["north_reg_terrs"] = payload;
		});

		builder.addMatcher(isAnyOf(createProject.fulfilled, createProjectPack.fulfilled), (state, { payload }) => {
			state.table["data"] = state.table["data"].map((row) => {
				if (row.id === null) Object.keys(payload).forEach((key) => (row[key] = payload[key]));
				if (row._children) {
					row._children.map((ch) => {
						if (ch.id === null) Object.keys(payload).forEach((key) => (ch[key] = payload[key]));
						return ch;
					});
				}
				return row;
			});
			state.fetchStatus = "success";
		});

		builder.addMatcher(isAnyOf(patchProject.fulfilled, patchProjectPack.fulfilled), (state, { payload }) => {
			state.fetchStatus = "success";
			state.fetchStatusText = payload?.detail;
		});

		builder.addMatcher(isAnyOf(deleteProject.fulfilled, deleteProjectPack.fulfilled), (state, action) => {
			state.fetchStatus = "success";
			const delObjId = action["meta"]["arg"];
			state.table["data"] = state.table["data"].filter((x) => x.id !== delObjId);
			state.table["data"] = state.table["data"].map((row) => {
				if (row._children) {
					row._children = row._children.filter((x) => x.id !== delObjId);
				}
				return row;
			});
		});

		builder.addMatcher(
			isAnyOf(
				fetchProjectsList.pending,
				deleteProject.pending,
				patchProject.pending,
				createProject.pending,
				createProjectPack.pending,
				fetchNorthRegTerrs.pending
			),
			(state, action) => {
				state.fetchStatus = "loading";
			}
		);

		builder.addMatcher(
			isAnyOf(
				fetchProjectsList.rejected,
				deleteProject.rejected,
				patchProject.rejected,
				createProject.rejected,
				createProjectPack.rejected,
				fetchNorthRegTerrs.rejected
			),
			(state, action) => {
				state.fetchStatus = "failed";
				state.fetchStatusCode = action?.payload?.response?.status;
				state.fetchStatusText = action?.payload?.detail;
			}
		);
	},
});

export const {
	pushNewEmpty,
	editProject,
	addProject,
	setFetchStatus,
	setFetchStatusText,
	setTableViewParams,
	setTableData,
	pushNewProjectToPack,
	setLastSelected,
} = projectsSlice.actions;
export default projectsSlice.reducer;

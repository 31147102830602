export const fsnbCheckAreaEnum = {
    CURRENT_ESTM: "CURRENT_ESTM",
    SELECTED_ESTMS: "SELECTED_ESTMS",
    ESTMS_WITHOUT_INDEX: "ESTMS_WITHOUT_INDEX",
}

export const fsnbExecOptions = [
    {
        label: 'Для текущей сметы',
        value: fsnbCheckAreaEnum.CURRENT_ESTM
    },
    {
        label: 'Для списка смет',
        value: fsnbCheckAreaEnum.SELECTED_ESTMS
    },
    {
        label: 'Для всех смет без СНБ',
        value: fsnbCheckAreaEnum.ESTMS_WITHOUT_INDEX
    }];

export const calcExecOptions = [
    {
        label: 'Для текущей сметы',
        value: fsnbCheckAreaEnum.CURRENT_ESTM
    },
    {
        label: 'Для списка смет',
        value: fsnbCheckAreaEnum.SELECTED_ESTMS
    },
    {
        label: 'Для всех смет без индексов',
        value: fsnbCheckAreaEnum.ESTMS_WITHOUT_INDEX
    }];

export const calcModeEnum = {
    CHECK_CALC: "CHECK_CALC",
    RECALC_TC: "RECALC_TC",
    RECALC_TC_WITH_OT: "RECALC_TC_WITH_OT",
}

export const calcModeOptions = [
    {
        label: 'Проверить рассчет',
        value: calcModeEnum.CHECK_CALC,
        id: 1
    },
    {
        label: 'Пересчитать в ТЦ',
        value: calcModeEnum.RECALC_TC,
        id: 2
    },
    {
        label: 'Пересчитать в текущие цены с поправками к ОТ',
        value: calcModeEnum.RECALC_TC_WITH_OT,
        id: 3
    }];

import ImportTableBody from "./ImportTableBody";

import "./ImportAccordionBody.css";

const ImportAccordionBody = ({ tableTab, setTableTab, importExpanded }) => {
    return (
        <div className="ImportAccordionBody">
            <div className="ImportAccordionBodyTable">
                <ImportTableBody tableTab={tableTab} importExpanded={importExpanded} />
            </div>
        </div>
    );
};

export default ImportAccordionBody;

import { useState } from "react";
import { useDispatch } from "react-redux";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import CellWIthColorPart from "../CellFormatter/CellWIthColorPart";
import CellIconWithAction from "../CellFormatter/CellIconWithAction";
import CellWithAdditionalIcon from "../CellFormatter/CellWithAdditionalIcon";

import { getTextWidth } from "components/utils.js";
import { ErrorStatusEnum } from "core/enums";

import LinkableErrorCell from "pages/EstimateViewer/ProtocolViewer/components/LinkableErrorCell";

const paddingHor = 7;
const paddingVer = 6;
const notTextWight = 39 + 352 + 352 + 10 + 7 + paddingHor * 6;

const clmsDefault = [
	{ error_status_id: "", width: "39px" },
	{ text: "Описание ошибки / замечания / уведомления", width: `calc(100% - ${notTextWight}px)` },
	{ hide_error: "", width: "10px" },
	{
		expected: "Ожидаемое значение",
		width: "352px",
		actions_icon_on_cell: [
			{
				check_value: {
					key: "has_just",
					value: [true],
				},
				action: "show_error_justification",
			},
			{
				check_value: {
					key: "code",
					value: ["2.14.1"],
				},
				action: "show_fsnb_coefs_on_error",
			},
		],
	},
	{ received: "Полученное значение", width: "352px" },
];

export function TableDetailsHead({ rowObj, columnsObj, columnsFlat, ...props }) {
	let columns = clmsDefault;
	if (columnsObj != null) columns = columnsObj;

	let rowId;
	let rowKey;
	if (rowObj == null) {
		rowId = 11;
		rowKey = "k11";
	} else {
		rowId = rowObj.id;
		rowKey = rowObj.key;
	}

	if (rowObj == null && !props.onlyContent) return;

	const headKey = "detail_head-" + rowId + "-" + rowObj?.target_key + "-" + rowKey;

	const headerContent = (
		<div className="DetailsHead">
			{columns.map((item, i) => {
				const key = `detail_head_${i}-${rowId}-${rowKey}`;
				const val = Object.values(item)[0];
				let styleCell = { width: item.width };
				if (!Object.keys(item).includes("text")) {
					styleCell.minWidth = item.width;
					styleCell.maxWidth = item.width;
				}
				return (
					<div className="DetailsHeadCell" style={styleCell} key={key} test_key={key} clm={Object.keys(item)[0]}>
						{val}
					</div>
				);
			})}
		</div>
	);

	if (props.onlyContent) return headerContent;

	return (
		<tr key={headKey} test_key={headKey} className="DetailsErrorHeadRow">
			<td colSpan={columnsFlat.length} style={{ borderLeft: "1px solid #dddddd" }}>
				{headerContent}
			</td>
		</tr>
	);
}

export function TableDetailsRow(props) {
	const dispatch = useDispatch();
	const [detailClass, setDetailClass] = useState("DetailsValue");
	const row_dct = props.row_dct;
	let columns = clmsDefault;
	if (props.columns != null) {
		columns = props.columns;
	}

	const showHideMore = (row) => {
		if (props.func?.changeRowData == null) return;

		if (row.rowHeightMultiply != null && row.rowHeightMultiply !== 1) {
			dispatch(
				props.func.changeRowData({
					rowKey: props.rowParent.key,
					callback: changeHeightCloser(row, props.rowParent, 1, (row.rowHeightMultiply - 1) * -1),
				})
			);
			return;
		}

		const windowWidth = window.innerWidth;
		const tableWidth = windowWidth * 0.8;
		const margins = 20 + 20 + 22; // left, right, icon
		const notTextClmWidth = notTextWight;
		const safeSpace = 1.2;

		const rowHeightMultiplyArr = [];
		for (let key of ["text", "expected", "received"]) {
			const textWidth = (getTextWidth(String(row[key]), "12px") + paddingHor * 2) * safeSpace;
			let rowWidth = 1;
			if (key === "text") {
				rowWidth = tableWidth - margins - notTextClmWidth;
			} else {
				rowWidth = parseInt(columns.find((x) => Object.keys(x).includes(key)).width.replace("px", ""));
			}
			const rowHeightMultiply = Math.ceil(textWidth / rowWidth);
			rowHeightMultiplyArr.push(rowHeightMultiply);
		}
		const maxValue = Math.max(...rowHeightMultiplyArr);
		const addToParent = maxValue - 1;
		if (maxValue > 0) {
			dispatch(
				props.func.changeRowData({
					rowKey: props.rowParent.key,
					callback: changeHeightCloser(row, props.rowParent, maxValue, addToParent),
				})
			);
		}
	};

	const changeHeightCloser = (row, parent, rowHeightMultiply, addToParent) => {
		const func = (elm) => {
			if (elm.addEmptyRowsCnt == null) elm.addEmptyRowsCnt = 0;
			elm.addEmptyRowsCnt = elm.addEmptyRowsCnt + addToParent;
			if (elm._details != null) {
				elm._details = elm._details.map((ch) => {
					if (ch.id === row.id) {
						ch.rowHeightMultiply = rowHeightMultiply;
					}
					return ch;
				});
			}
		};
		return func;
	};

	const getCellStyle = (item, row) => {
		let rowHeightMax;
		let stypeCell = {
			width: item.width,
			display: "inline-block",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
		};
		if (row.rowHeightMultiply != null && row.rowHeightMultiply !== 1) {
			rowHeightMax = 22 * row_dct.rowHeightMultiply - paddingVer + "px";
			stypeCell = {
				width: item.width,
				height: rowHeightMax,
				minHeight: rowHeightMax,
				whiteSpace: "normal",
				display: "flex",
				alignItems: "center",
				justifyContent: "left",
			};
		}
		if (!Object.keys(item).includes("text")) {
			stypeCell.minWidth = item.width;
			stypeCell.maxWidth = item.width;
			stypeCell.justifyContent = "center";
		}
		return stypeCell;
	};

	const getRowStyle = (row) => {
		let rowHeightMax;
		const paddingsHeight = 2;
		if (row.rowHeightMultiply != null && row.rowHeightMultiply !== 1) {
			rowHeightMax = 22 * row_dct.rowHeightMultiply - paddingsHeight + "px";
		}
		return { height: rowHeightMax };
	};

	const detailContent = (
		<div
			className={detailClass}
			style={getRowStyle(row_dct)}
			onClick={() => {
				if (detailClass === "DetailsValue") {
					setDetailClass("DetailsValue Clicked");
				} else {
					setDetailClass("DetailsValue");
				}
			}}
		>
			{columns.map(function (item, i) {
				let d_key = `detail_val_${i}`;
				let key = Object.keys(item)[0];
				let cheld_key_val = row_dct[key];
				if (key === "error_status_id") {
					if (cheld_key_val === ErrorStatusEnum.ERROR) {
						cheld_key_val = (
							<div className="DetailsErrorIcons" title="Значимая">
								<ErrorOutlineOutlinedIcon />
							</div>
						);
					} else if (cheld_key_val === ErrorStatusEnum.WARNING) {
						cheld_key_val = (
							<div className="DetailsWarningIcons" title="">
								<WarningAmberOutlinedIcon />
							</div>
						);
					} else if (cheld_key_val === ErrorStatusEnum.ALERT) {
						cheld_key_val = (
							<div className="DetailsWarningIcons" title="">
								<InfoOutlinedIcon />
							</div>
						);
					}
				}
				if (key === "hide_error") {
					const rowCopy = JSON.parse(JSON.stringify(row_dct));
					rowCopy.parentRow = props.rowParent;
					cheld_key_val = (
						<div className="DetailsErrorIcons">
							<CellIconWithAction
								clm={{ action: "add_except_by_state" }}
								row={rowCopy}
								rowOptions={props.rowOptions}
								funcs={props.func}
							/>
						</div>
					);
				}

				let _title = row_dct[key];
				if (_title == null) {
					_title = "";
				} else {
					_title = String(_title);
				}

				if (cheld_key_val != null) {
					if (cheld_key_val.toString().includes("<color:")) {
						_title = cheld_key_val.slice(0, cheld_key_val.indexOf("<color"));
						cheld_key_val = <CellWIthColorPart value={cheld_key_val} />;
					}
				}

				let actionIconOnCell;
				if (item.hasOwnProperty("actions_icon_on_cell")) {
					actionIconOnCell = <CellWithAdditionalIcon row={row_dct} clm={item} funcs={props.func} />;
					let styleFlexCell = getCellStyle(item, row_dct);
					styleFlexCell.minWidth = parseInt(styleFlexCell.minWidth.replace("px", "")) - 10 + "px";
					cheld_key_val = (
						<div className="TreeFlexCell" style={{ display: "flex" }}>
							<span className="TreeFlexCellSpan" title={_title} style={styleFlexCell}>
								{_title}
							</span>
							{actionIconOnCell}
						</div>
					);
				}

				if (key === "text") {
					if (row_dct.text_with_action) {
						cheld_key_val = (
							<div
								className="TreeFlexCell"
								style={{
									width: "100%",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								<LinkableErrorCell rowObj={row_dct} />
							</div>
						);
					}
				}

				return (
					<div
						className="DetailsValueCell"
						key={`${d_key}-${i}`}
						clm={key}
						title={String(_title)}
						style={getCellStyle(item, row_dct)}
						onClick={() => showHideMore(row_dct)}
					>
						{" "}
						{cheld_key_val}
					</div>
				);
			})}
		</div>
	);

	if (props.onlyContent) return detailContent;

	const getBgColor = () => {
		if (row_dct.error_status_id === ErrorStatusEnum.ALERT) {
			return "#f5f8fb";
		} else {
			return "#fff5f5";
		}
	};

	return (
		<tr className={props.trDelCls} style={{ backgroundColor: getBgColor() }}>
			<td colSpan={props.columnsFlat?.length} style={{ borderLeft: "1px solid #dddddd" }}>
				{detailContent}
			</td>
		</tr>
	);
}

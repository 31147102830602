export default function SummaryEstimateTableColumns({ priceLevelSelectorJsx }) {
	return [
		{
			name: "num",
			title: "№ п/п",
			width: "75px",
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "30px",
			marginLeft: "10px",
		},
		{
			name: "code",
			title: "Обоснование",
			width: "250px",
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "30px",
			marginLeft: "10px",
		},
		{
			name: "name",
			title: "Наименование работ и затрат",
			width: "auto",
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "30px",
			marginLeft: "10px",
		},
		{
			name: "cost",
			title: (
				<div>
					<span>Сметная стоимость, тыс. руб</span>
					{priceLevelSelectorJsx}
				</div>
			),
			width: "550px",
			cellHorAlign: "left",
			headHorAlign: "center",
			headHeight: "30px",
			colSpan: "5",
			children: [
				{
					name: "building",
					title: "строительных работ",
					width: "110px",
					cellHorAlign: "right",
					headHorAlign: "center",
					headHeight: "50px",
				},
				{
					name: "mounting",
					title: "монтажных работ",
					width: "110px",
					cellHorAlign: "right",
					headHorAlign: "center",
					headHeight: "50px",
				},
				{
					name: "equipment",
					title: "оборудования",
					width: "110px",
					cellHorAlign: "right",
					headHorAlign: "center",
					headHeight: "50px",
				},
				{
					name: "other",
					title: "прочих затрат",
					width: "110px",
					cellHorAlign: "right",
					headHorAlign: "center",
					headHeight: "50px",
				},
				{
					name: "total",
					title: "всего",
					width: "110px",
					cellHorAlign: "right",
					headHorAlign: "center",
					headHeight: "50px",
				},
			],
		},
		{
			name: "check",
			title: "",
			width: "25px",
			cellHorAlign: "center",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "25px",
			marginLeft: "10px",
		},
	];
}

import { LabelComposition, LabelCommon, LabelTwoVerticalLevel } from "components/UI/labels";
import { changeNullAndEmptyStringToDefault } from "core/str_utils";
import styles from "./LocalEstmFooterSignatures.module.css";

const KEYS_WIDTH = "80px";
const VALUE_WIDTH = "400px";
const mainLabelStyle = {
    borderBottom: "1px solid black",
    textDecoration: "auto",
    width: VALUE_WIDTH,
    textAlign: "center",
};

const LocalEstmFooterSignatures = ({ data }) => {
    return (
        <div className={styles.LocalEstmFooterSignatures}>
            <LabelComposition>
                <LabelCommon text="Составил" style={{ width: KEYS_WIDTH }} />
                <LabelTwoVerticalLevel
                    main={changeNullAndEmptyStringToDefault(data.signatures_composer, "-")}
                    second="[должность, подпись (инициалы, фамилия)]"
                    style={{ mainLabel: mainLabelStyle, box: { width: VALUE_WIDTH } }}
                />
            </LabelComposition>
            <LabelComposition>
                <LabelCommon text="Проверил" style={{ width: KEYS_WIDTH }} />
                <LabelTwoVerticalLevel
                    main={changeNullAndEmptyStringToDefault(data.signatures_verifier, "-")}
                    second="[должность, подпись (инициалы, фамилия)]"
                    style={{ mainLabel: mainLabelStyle, box: { width: VALUE_WIDTH } }}
                />
            </LabelComposition>
        </div>
    );
};

export { LocalEstmFooterSignatures };

import { useState, useEffect, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setClickedTableRow } from "slices/ribbon";

import TableMain from "components/Table/TableMain";

import FsnbTree from "./FsnbTree";
import FsnbCoefBox from "./FsnbCoefBox";
import FsnbPartNorm from "./FsnbPartNorm";
import FsnbTechGrpBox from "./FsnbTechGrpBox";
import FsnbBodyNoData from "./FsnbBodyNoData";
import FsnbNrspNormBox from "./FsnbNrspNormBox";

import FsemTableColumns from "../TableSetting/FsemTableColumns";
import FsemTableColumns2022 from "../TableSetting/FsemTableColumns2022";
import FsscTableColumns from "../TableSetting/FsscTableColumns";
import NormTableColumns from "../TableSetting/NormTableColumns";
import CoefTableColumns from "../TableSetting/CoefTableColumns";
import NrspTableColumns from "../TableSetting/NrspTableColumns";

import { changeNestedArrKeyValueOnCondition, arrProxy, copyArr } from "core/iterable_utils";
import { resourceOpenTree } from "../utils.js";

import { useEstimateManager } from "pages/EstimateEditor/hooks/useEstimateManager";
import { useSectionEditor } from "pages/EstimateEditor/hooks/useSectionEditor";
import { useItemEditor } from "pages/EstimateEditor/hooks/useItemEditor";
import { setFetchStatus, setFetchStatusText } from "slices/local_estimate_editor";

import "./FsnbBody.css";
import "./FsnbTables.css";
import FsscPgTableColumns from "../TableSetting/FsscTablePgColumns";
import KsrTableColumns from "../TableSetting/KsrTableColumns";

const FsnbTechPartsMain = lazy(() => import("pages/Fsnb/components/FsnbTechPartsMain/FsnbTechPartsMain"));

export default function FsnbBody(props) {
	const dispatch = useDispatch();
	const scrollBlock = props.scrollBlock;
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const navigateFromEditor = useSelector((state) => state.ribbon.navigateFromEditor);
	const [coefBoxVisible, setCoefBoxVisible] = useState(false);
	const [boxPartNormVisible, setBoxPartNormVisible] = useState(false);
	const [fsnbPartNormName, setFsnbPartNormName] = useState("");
	const [coefRow, setCoefRow] = useState({});

	const [nsrpNormVisible, setNsrpNormVisible] = useState(false);
	const [nsrpRow, setNsrpRow] = useState({});

	const [techGrpBoxVisible, setTechGrpBoxVisible] = useState(false);
	const [absResRow, setAbsResRow] = useState({});

	const currentEstmDocId = useSelector((state) => state.ribbon.currentEstmDocId);
	const currentProjectID = useSelector((state) => state.ribbon.currentProjectID);

	const editorEstmDocClickedSectionId = useSelector((state) => state.ribbon.editorEstmDocClickedSectionId);

	const sectionEditor = useSectionEditor({
		estmId: currentEstmDocId,
		_setFetchStatus: setFetchStatus,
		scrollBlock: {},
	});
	const itemEditor = useItemEditor({
		estmId: currentEstmDocId,
		_setFetchStatus: setFetchStatus,
		_setFetchStatusText: setFetchStatusText,
		scrollBlock: {},
	});
	const estmManager = useEstimateManager({
		estmId: currentEstmDocId,
		_setFetchStatus: setFetchStatus,
		_setFetchStatusText: setFetchStatusText,
		hooks: { sectionEditor, itemEditor, setSettingsVisible: () => {} },
	});

	useEffect(() => {
		let rowsPassed = 0;
		const rowHeight = 22;
		let isFind = 0;
		let isFound = false;
		for (let row of props.tableData) {
			if (row.isSearching) {
				isFind = 1;
				isFound = true;
				if (props.curSearchRow == null) break;
				if (Object.keys(props.curSearchRow).length === 0) {
					break;
				} else {
					if (row._children) {
						let addRowFromCur = 0;
						for (let child of row._children) {
							addRowFromCur += 1;

							// добавить условие для кср

							if (props.tabValue === "ksr") {
								if (child._children) {
									for (let subChild of child._children) {
										addRowFromCur += 1;
										if (subChild.id === props.curSearchRow.id) {
											rowsPassed = rowsPassed + addRowFromCur;
											break;
										}
									}
								}
							}

							if (child.id === props.curSearchRow.id) {
								rowsPassed = rowsPassed + addRowFromCur;
								break;
							}
						}
					}
				}
			}
			if (!isFound) {
				rowsPassed += 1;
				if (row._children != null) {
					rowsPassed += row._children.filter((x) => x.visible === true).length;

					// добавил условие для кср
					if (props.tabValue === "ksr") {
						for (let child of row._children) {
							if (child._children != null) {
								rowsPassed += child._children.filter((x) => x.visible === true).length;
							}
						}
					}
				}
			}
		}

		if (isFind) {
			scrollBlock.current.scrollTop = rowsPassed * rowHeight - rowHeight * 2;
		}
	}, [props.tableData, props.curSearchRow]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(setClickedTableRow(null));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const changeChildsVisible = ({ clickedRow }) => {
		props.f_setTableData((prev) => [
			...prev.map((row) => {
				if (row.id === clickedRow.id) {
					row.childs_visible = !row.childs_visible;
					if (row._children != null) {
						row._children = row._children.map((child) => {
							child.visible = !child.visible;
							return child;
						});
					}
				} else {
					if (row._children != null) {
						row._children = row._children.map((child) => {
							if (child.id === clickedRow.id) {
								child.childs_visible = !child.childs_visible;
								if (child._children != null) {
									child._children = child._children.map((subChild) => {
										subChild.visible = !subChild.visible;
										return subChild;
									});
								}
							}
							return child;
						});
					}
				}
				return row;
			}),
		]);
	};

	let columnsData;
	if (props.tabValue === "mat") {
		columnsData = FsscTableColumns();
	} else if (props.tabValue === "mach") {
		if (props?.selectedFsnbVers?.num >= 396) {
			// номер с которого начинаюся версии 2022
			columnsData = FsemTableColumns2022();
		} else {
			columnsData = FsemTableColumns();
		}
	} else if (props.tabValue === "norm") {
		columnsData = NormTableColumns({ inputSearchValue: props.inputSearchValue });
	} else if (props.tabValue === "pg") {
		columnsData = FsscPgTableColumns();
	} else if (props.tabValue === "ksr") {
		columnsData = KsrTableColumns();
	} else if (props.tabValue === "coef") {
		columnsData = CoefTableColumns();
	} else if (props.tabValue === "nrsp") {
		columnsData = NrspTableColumns();
	}

	const changeNormContentRowVisible = (row, contentType) => {
		if (contentType === "content") {
			row.contentVisibility = !row.contentVisibility;
		} else if (contentType === "resources") {
			row.resourcesVisibility = !row.resourcesVisibility;
		}
		if (row.contentVisibility && row.resourcesVisibility) {
			row._children = [...arrProxy(row.norm_content), ...arrProxy(row.norm_resource)];
		} else if (row.contentVisibility && !row.resourcesVisibility) {
			row._children = [...arrProxy(row.norm_content)];
		} else if (!row.contentVisibility && row.resourcesVisibility) {
			row._children = [...arrProxy(row.norm_resource)];
		} else if (!row.contentVisibility && !row.resourcesVisibility) {
			row._children = [];
		}
		return row;
	};

	const changeNormContentVisible = (clickedRow, contentType) => {
		props.f_setTableData((prev) => [
			...prev.map((gpr) => {
				let gprCopy = { ...gpr };
				if (gprCopy._children) {
					gprCopy._children = gprCopy._children.map((row) => {
						if (row.id === clickedRow.id) {
							gprCopy.isSearching = false;
							row = changeNormContentRowVisible(row, contentType);
						}
						return row;
					});
					return gprCopy;
				} else {
					if (gprCopy.id === clickedRow.id) {
						gprCopy = changeNormContentRowVisible(gprCopy, contentType);
					}
					return gprCopy;
				}
			}),
		]);
	};

	props.lowerCallbacks.current["changeNormContentVisible"] = changeNormContentVisible;

	const showCoefs = (row) => {
		setCoefRow(row);
		setCoefBoxVisible(true);
	};

	const showPartNorm = (row) => {
		setCoefRow(row);
		setBoxPartNormVisible(true);
		setFsnbPartNormName(`${clickedTableRow?.code} ${clickedTableRow?.name}`);
	};

	props.lowerCallbacks.current["showCoefs"] = showCoefs;
	props.lowerCallbacks.current["showPartNorm"] = showPartNorm;

	const showTechGroup = (row) => {
		setAbsResRow(row);
		setTechGrpBoxVisible(true);
	};

	const showConnectedNorm = (row) => {
		setNsrpRow(row);
		setNsrpNormVisible(true);
	};

	const localFsnbSelectData = (table, row, target, parentRow) => {
		let newTableData = copyArr(props.tableData);
		if (clickedTableRow) {
			newTableData = changeNestedArrKeyValueOnCondition({
				arr: newTableData,
				keySearch: "id",
				valSearch: clickedTableRow.id,
				keyChange: "selected",
				valChange: false,
			});
		}
		newTableData = changeNestedArrKeyValueOnCondition({
			arr: newTableData,
			keySearch: "id",
			valSearch: row.id,
			keyChange: "selected",
			valChange: true,
		});
		props.f_setTableData(newTableData);
		dispatch(setClickedTableRow({ ...row, parentRow: parentRow }));
	};

	const getMainTable = () => {
		if (props.tableData.length) {
			return (
				<TableMain
					rows={{ data: props.tableData }}
					columns={columnsData}
					options={{
						selectable: true,
						scrollBlock: scrollBlock,
						displayScrollDelay: 20,
						funcCall: "local",
					}}
					func={{
						selectDataLocal: localFsnbSelectData,
						changeVisible: changeChildsVisible,
						changeNormContentVisible: changeNormContentVisible,
						showCoefs: showCoefs,
						showTechGroup: showTechGroup,
						showConnectedNorm: showConnectedNorm,
						singleClickRowAction: {
							tagName: {
								allowed: ["td", "span"],
							},
							action: props.f_showMainTableMore,
						},
						doubleClickRowAction: {
							action: (rowObj, e) => {
								if (navigateFromEditor === "add") {
									const rowObj = { model_name: "LocalEstimatesItems", section_id: editorEstmDocClickedSectionId };
									const dataObj = { code: `${clickedTableRow.prefix || ""}${clickedTableRow.code}` };
									const clmName = "";
									estmManager.editorFactory({
										rowObj,
										dataObj,
										clmName,
										thenNavigate: {
											path: `/project/${currentProjectID}/editor/lsr/${currentEstmDocId}/`,
										},
										thenScroll: true,
									});
								} else if (rowObj?.row_type_style === "fake_parent") {
									props.f_handleTreeClick(rowObj);
									props.f_setTreeData((prev) => [...resourceOpenTree(prev, { key: rowObj.id }, 0)[0]]);
									props.f_setSelectedTreeData({ key: rowObj.id });
								} else {
									if (props.tabValue === "norm" && props.searchMode === "flat") {
										props.setInputSearchValue("");
									}
								}
							},
						},
					}}
				></TableMain>
			);
		} else if (props.tabValue === "coef") {
			return;
		} else {
			return <FsnbBodyNoData tabValue={props.tabValue} />;
		}
	};

	return (
		<div className="FsnbBody">
			{props.searchMode !== "flat" && (
				<div className="FsnbBodyLeft">
					{props.treeData?.length ? (
						<FsnbTree
							data={props.treeData}
							selected={props.selectedTreeData}
							searchObjScope={props.searchObjScope}
							f_setSelectedTreeData={props.f_setSelectedTreeData}
							f_changeChildsVisible={props.f_changeChildsVisible}
							f_setSearchObjScope={props.f_setSearchObjScope}
						/>
					) : (
						<div></div>
					)}
				</div>
			)}

			{props.tabValue === "coef" ? (
				<div className="FsnbBodyRightWrapper">
					<div
						style={{ maxHeight: "230px", minHeight: "230px" }}
						className="FsnbBodyRightCoef"
						ref={scrollBlock}
						table_type={props.tabValue}
					>
						{getMainTable()}
					</div>

					<Suspense style={{ maxHeight: "200px" }} fallback={" "}>
						<FsnbTechPartsMain
							fetchStatus={props.fetchStatus}
							tabValue={props.tabValue}
							pdfData={props?.pdfData}
							f_setFetchStatus={props.f_setFetchStatus}
						/>
					</Suspense>
				</div>
			) : (
				<div
					className="FsnbBodyRight"
					ref={scrollBlock}
					table_type={props.tabValue}
					search_mode={props.searchMode}
					onScroll={props.handleScroll}
				>
					{props.fsnbBodyContent === "table" ? (
						getMainTable()
					) : (
						<Suspense fallback={" "}>
							<FsnbTechPartsMain
								fetchStatus={props.fetchStatus}
								tabValue={props.tabValue}
								pdfData={props?.pdfData}
								f_setFetchStatus={props.f_setFetchStatus}
							/>
						</Suspense>
					)}
				</div>
			)}

			{coefBoxVisible && (
				<FsnbCoefBox row={coefRow} showNrsp={true} vers={props?.selectedFsnbVers} f_closeCoefBox={() => setCoefBoxVisible(false)} />
			)}

			{boxPartNormVisible && (
				<FsnbPartNorm
					name={fsnbPartNormName}
					row={coefRow}
					showNrsp={true}
					vers={props?.selectedFsnbVers}
					f_closeCoefBox={() => {
						setBoxPartNormVisible(false);
						setFsnbPartNormName("");
					}}
				/>
			)}

			{techGrpBoxVisible && (
				<FsnbTechGrpBox row={absResRow} f_closeCoefBox={() => setTechGrpBoxVisible(false)} vers={props?.selectedFsnbVers} />
			)}
			{nsrpNormVisible && <FsnbNrspNormBox row={nsrpRow} f_closeCoefBox={() => setNsrpNormVisible(false)} vers={props?.selectedFsnbVers} />}
		</div>
	);
}

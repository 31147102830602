import { apiBase } from "./base";

const apiMonitor = {
	client: apiBase.get_client(),
	url: "/smetdocs/",

	startMonitorAction(body) {
		return this.client.post(this.url + "local_estimates/monitor/", body, apiBase.get_headers());
	},

	startKatsMonitorAction(body) {
		return this.client.post(this.url + "kats/monitor/", body, apiBase.get_headers());
	},

	startCrossMonitorAction(id) {
		return this.client.get(this.url + "cross_estimate/monitor/" + id + "/", apiBase.get_headers());
	},

	execCompletenessErrors(id) {
		return this.client.get("/projects/completeness_check/" + id + "/", apiBase.get_headers());
	},

	downloadCompletenessErrors({ projectId, errorsFilter }) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.post("/projects/completeness_check/" + projectId + "/xlsx/", errorsFilter, headers);
	},

	getCompletenessErrors() {
		return this.client.get("/projects/completeness_check_errors/", apiBase.get_headers());
	},
};

export default apiMonitor;

import { getTextWidth } from "components/utils.js";

export const resourceChangeVisible = (dataList, row, changeChildren = false, _children = null) => {
	for (let elm of dataList) {
		if (elm.key === row.key) {
			elm.childs_visible = !elm.childs_visible;
			if (changeChildren) elm._children = _children;
			break;
		} else {
			if (elm._children != null && elm._children.length) {
				resourceChangeVisible(elm._children, row, changeChildren, _children);
			}
		}
	}
	return dataList;
};

export const resourceOpenTree = (dataList, row, level) => {
	let isFound = false;
	for (let elm of dataList) {
		if (elm.key === row.key) {
			elm.childs_visible = true;
			isFound = true;
			break;
		} else {
			if (elm._children != null && elm._children.length) {
				const res = resourceOpenTree(elm._children, row, 1);
				if (res[1]) {
					elm.childs_visible = true;
					elm.visible = true;
					isFound = true;
				} else {
					elm.childs_visible = false;
					if (level === 1) {
						elm.visible = false;
					}
				}
			}
		}
	}
	return [dataList, isFound];
};

export const arrProxy = (arr) => {
	if (arr == null) {
		return [];
	}
	return arr;
};

export const showTableMore = (row, fontSize, clms, f_setState, tableClass, clmName = "name") => {
	const safeSpace = 100;
	let textWidth = getTextWidth(row[`${clmName}`]?.toString(), fontSize) + safeSpace;
	const rowHeightMultiplyArr = [];

	for (let clm of clms) {
		textWidth = getTextWidth(row[`${clm}`]?.toString(), fontSize) + safeSpace;
		const rowWidth = parseInt(getComputedStyle(document.querySelector(`.${tableClass} th[clm='${clm}']`)).width.replace("px", ""));
		rowHeightMultiplyArr.push(Math.ceil(textWidth / rowWidth));
	}
	const rowHeightMultiply = Math.max(...rowHeightMultiplyArr);

	f_setState((prevArr) =>
		prevArr.map((prevRow) => {
			prevRow = changeRowHeight(prevRow, row, rowHeightMultiply);
			return prevRow;
		})
	);
};

export const changeRowHeight = (prevRow, clickedRow, rowHeightMultiply) => {
	const prevRowCopy = { ...prevRow };
	if (prevRowCopy.id === clickedRow.id) {
		if (prevRowCopy.rowHeightMultiply == null && rowHeightMultiply > 1) {
			prevRowCopy.rowHeightMultiply = rowHeightMultiply;
		} else {
			prevRowCopy.rowHeightMultiply = null;
		}
		return prevRowCopy;
	}

	if (prevRowCopy._children != null) {
		prevRowCopy._children = prevRowCopy._children.map((prevCh) => {
			prevCh = changeRowHeight(prevCh, clickedRow, rowHeightMultiply);
			return prevCh;
		});
	}
	return prevRowCopy;
};

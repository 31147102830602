import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import KsprSelectBox from "components/UI/input/KsprSelectBox";

import { getUserSetErrorStatus, postUserSetErrorStatus } from "slices/protocol";

import { errorStatusOpts } from "./consts";
import "./FilterErrorStatus.css";

const FilterErrorStatus = (props) => {
    const dispatch = useDispatch();
    const usrErrorsStatus = useSelector((state) => state.protocol.usrErrorsStatus);
    const userid = localStorage.getItem("userId");

    useEffect(() => {
        dispatch(getUserSetErrorStatus({ project_id: props.projectId, user_id: userid }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const usrErrorsStatusIds = usrErrorsStatus.map((e) => e.error_status_id);
        const usrParams = errorStatusOpts.filter((x) => usrErrorsStatusIds.includes(x.id));
        props.setSelectedErrorStatus(usrParams);
    }, [usrErrorsStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectClick = () => {
        const body = [];
        for (let elm of props.selectedErrorStatus) {
            body.push({ project_id: props.projectId, user_id: userid, error_status_id: elm.id });
        }
        dispatch(
            postUserSetErrorStatus({
                project_id: props.projectId,
                user_id: userid,
                errors_status_id: body,
            })
        );
    };

    return (
        <div className="FilterErrorStatus">
            <KsprSelectBox
                label="Значимость"
                isError={false}
                optionValues={errorStatusOpts}
                selectedOpts={props.selectedErrorStatus}
                setSelectedOpts={props.setSelectedErrorStatus}
                handleSelectClick={handleSelectClick}
                width="250px"
                buttonProps={{
                    style: { paddingTop: 0, paddingBottom: 0, fontSize: "11px", marginLeft: "auto" },
                }}
            />
        </div>
    );
};

export default FilterErrorStatus;

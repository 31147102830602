import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import TableMain from "components/Table/TableMain";
import LabelNormWithUnder from "components/UI/LabelNormWithUnder";
import { LabelTwoVerticalLevel } from "components/UI/labels";
import HeaderVisibleButton from "components/HeaderVisibleButton";
import NoDataBox from "components/NoDataBox";
import { SummaryEstmFooterSignatures, PriceLevelSwitcher } from "./components";
import EstmHeaderTitleButtons from "pages/EstimateViewer/components/EstmHeaderTitleButtons/EstmHeaderTitleButtons";

import { calcRowHeightMultiplier } from "../LocalEstimateViewer/utils/row_utils";

import {
	getSumEstimateTree,
	clearSumEstmData,
	getPrintXlsx,
	getInputXml,
	changeSumEstmVisible,
	changeSumEstmErrorsVisible,
	changeRowData,
	selectData,
	getPrintXlsxWithErrors,
} from "slices/summary_estimate";
import { setCurrentPage, setCurrentEstmDocId, setClickedTableRow, clearClickedTableCtrlRows } from "slices/ribbon";
import SummaryEstimateTableColumns from "./SummaryEstimateTableColumns";
import StartWorkBox from "components/StartWorkBox";

import { pushToClickedRows, removeFromClickedRows, clearClickedRows } from "components/Footer/aggFuncs";

import "pages/EstimateViewer/TableOuterBox.css";
import "./SummaryEstimateViewer.css";

export const SummaryEstimateViewer = (props) => {
	const dispatch = useDispatch();

	const headerData = useSelector((state) => state.summaryEstimate.headerData);
	const rowsData = useSelector((state) => state.summaryEstimate.tableData);
	const clickedTableCtrlRows = useSelector((state) => state.ribbon.clickedTableCtrlRows);
	const projectTreeData = useSelector((state) => state.projectWork.tree);

	const [fullVisible, setFullVisible] = useState(true);
	const [priceLevel, setPriceLevel] = useState("current");

	const columnsData = SummaryEstimateTableColumns({ priceLevelSelectorJsx: null });

	const headBlock = useRef();
	const headScrollControllBlock = useRef();
	const headBlock1 = useRef();
	const headBlock3 = useRef();
	const { id } = useParams();

	useLayoutEffect(() => {
		dispatch(setCurrentPage("SummaryEstimateViewer"));
		dispatch(setCurrentEstmDocId(id));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		return () => {
			dispatch(clearClickedTableCtrlRows());
			dispatch(clearSumEstmData([]));
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(getSumEstimateTree({ id, priceLevel }));
	}, [priceLevel, id]); // eslint-disable-line react-hooks/exhaustive-deps

	const setHeadBlockMaxHeight = (val) => {
		for (let headBlock of [headBlock1, headBlock3]) {
			if (headBlock.current != null) headBlock.current.style.maxHeight = val;
		}
	};

	const handleSetFullVisible = () => {
		if (fullVisible === true) {
			setHeadBlockMaxHeight(0);
			setFullVisible(false);
		} else if (fullVisible === false) {
			setHeadBlockMaxHeight("100px");
			setFullVisible(true);
		}
	};

	const getPrintXlsxSummary = () => {
		dispatch(getPrintXlsx({ projectId: id, priceLevel }));
	};

	const getPrintXlsxWithErrorsSummary = () => {
		dispatch(getPrintXlsxWithErrors({ projectId: id, priceLevel }));
	};

	const getInputXmlSummary = () => {
		dispatch(getInputXml({ projectId: id, priceLevel }));
	};

	props.lowerCallbacks.current.summary["getPrintXlsxSummary"] = getPrintXlsxSummary;
	props.lowerCallbacks.current.summary["getInputXmlSummary"] = getInputXmlSummary;
	props.lowerCallbacks.current.summary["getPrintXlsxWithErrorsSummary"] = getPrintXlsxWithErrorsSummary;

	const showMoreRow = (row, event) => {
		if (event?.ctrlKey) return;
		if (row.row_type !== "group") {
			const rowHeightMultiplier = calcRowHeightMultiplier(row, columnsData);

			dispatch(
				changeRowData({
					rowKey: row.key,
					callback: (x) => {
						if (x.rowHeightMultiply == null && rowHeightMultiplier > 1) {
							x.rowHeightMultiply = rowHeightMultiplier;
						} else {
							x.rowHeightMultiply = null;
						}
					},
				})
			);
		}
	};

	const summaryEstmSelectData = (table, row, target, parentRow, event) => {
		const clmName = target?.attributes?.clm?.value;
		if (event?.ctrlKey) {
			if (clickedTableCtrlRows.filter((x) => x.key === row.key && x.pressedClm === clmName).length > 0) {
				removeFromClickedRows({ rowObj: row, pressedClm: clmName, changeRowData, dispatch });
			} else {
				pushToClickedRows({ rowObj: row, pressedClm: clmName, changeRowData, dispatch });
			}
		} else {
			clearClickedRows({ clickedTableCtrlRows, changeRowData, dispatch });
			dispatch(setClickedTableRow({ ...row, parentRow: parentRow }));
			dispatch(selectData(row));
		}
	};

	const getPriceLevelSelectorJsx = ({ priceLevelText, baseText, curText }) => (
		<LabelNormWithUnder
			norm={
				<PriceLevelSwitcher
					priceLevel={priceLevel}
					setPriceLevel={setPriceLevel}
					priceLevelText={priceLevelText}
					baseText={baseText}
					curText={curText}
				/>
			}
			under={headerData.price_level_print}
			options={{ normIsDom: true }}
		/>
	);

	if (rowsData == null) {
		if (projectTreeData.find((x) => x._children && x._children.length && x._children.length > 0)) {
			return (
				<>
					<div className="NoDataPriceLevel">
						<PriceLevelSwitcher
							priceLevel={priceLevel}
							setPriceLevel={setPriceLevel}
							priceLevelText="Составлен в"
							baseText="базисном"
							curText="текущем"
						/>
					</div>
					<NoDataBox text="сводном сметном расчете" />
				</>
			);
		} else {
			return <StartWorkBox />;
		}
	} else if (rowsData.length > 0) {
		return (
			<div className="TableOuterBox" type="SummaryEstimate">
				<div className="TableOuterHead" ref={headBlock}>
					<div className="OuterHeadHidable" ref={headBlock1}>
						<div>
							<LabelNormWithUnder norm="Заказчик" under={headerData.customer} />
							<LabelNormWithUnder norm="Утвержден" under={headerData.approved + " г."} />
							<LabelNormWithUnder norm="Сводный сметный расчет сметной стоимостью" under={headerData.full_total_price + " тыс. руб."} />
							<LabelNormWithUnder norm="Ссылка на документ об утверждении" under="" />
						</div>
					</div>
					<div className="TableOuterHeadMainTitle">
						<div className="HeadFlex">
							<div className="TitleLeft"></div>
							<div className="TitleCenter">
								<span>СВОДНЫЙ СМЕТНЫЙ РАСЧЕТ СТОИМОСТИ СТРОИТЕЛЬСТВА № {headerData.num}</span>
								<HeaderVisibleButton fullVisible={fullVisible} f_handleVisible={handleSetFullVisible} />
							</div>
							<div className="TitleRight">
								<EstmHeaderTitleButtons data={headerData} id={id} estm_type="ssr" />
							</div>
						</div>
					</div>
					<div className="OuterHeadHidable" ref={headBlock3}>
						<div>
							<LabelTwoVerticalLevel main={headerData["name"]} second="(наименование стройки)" />
							<div className="TableOuterHeadFlex PriceLevelSelector" ref={headScrollControllBlock}>
								{getPriceLevelSelectorJsx({ priceLevelText: "Составлен в", baseText: "базисном", curText: "текущем" })}
							</div>
						</div>
					</div>
				</div>

				<TableMain
					rows={{ data: rowsData }}
					columns={SummaryEstimateTableColumns({
						priceLevelSelectorJsx: getPriceLevelSelectorJsx({ priceLevelText: "Уровень цен", baseText: "базисный", curText: "текущий" }),
					})}
					options={{
						selectable: true,
						scrollBlock: props.scrollBlock,
						headBlock: headBlock,
						addH: 200,
						displayScrollDelay: 30,
						headScrollControllBlock: headScrollControllBlock,
						tableType: "ssr",
					}}
					func={{
						changeVisible: changeSumEstmVisible,
						changeErrorsDetailsVisible: changeSumEstmErrorsVisible,
						selectDataLocal: summaryEstmSelectData,
						singleClickRowAction: {
							action: showMoreRow,
						},
					}}
				></TableMain>
				<div className="TableHeaderBlankPlaceHolder"></div>
				{rowsData != null && rowsData.length ? <SummaryEstmFooterSignatures data={headerData} /> : <></>}
				<br></br>
			</div>
		);
	}
};

import { apiBase } from "./base";

const apiQuestion = {
    client: apiBase.get_client(),
    url: "/user_adv/question/",

    add(file, text, user_email) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("text", text);
        formData.append("user_email", user_email);

        return this.client.post(this.url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("accessToken"),
                "Auth-Uuid": localStorage.getItem("authUuid"),
                // "Session-Project": localStorage.getItem("SessionProject")
            },
        });
    },

    getUserBaseInstruction() {
        const headers = apiBase.get_headers();
        headers["responseType"] = "blob";
        return this.client.get("/user_adv/user_instruction/base_user/", headers);
    },
};

export default apiQuestion;

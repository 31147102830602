const KatsViewerFooterSettings = (katsHeaderData) => {

    const getText = () => {
        return (
            <div className="katsViewerFooter">
                Итого стоимость позиций КА (без учета экспертных ЦП / с учетом экспертных ЦП)
                <span>
                    &nbsp; {katsHeaderData.proj_tc_price && katsHeaderData.proj_tc_price[0]}
                </span>
                &nbsp; /  &nbsp;
                <span>
                    {katsHeaderData.proj_tc_expert_price && katsHeaderData.proj_tc_expert_price[0]}  &nbsp;
                </span>

                <span className={katsHeaderData.price_diff && katsHeaderData.price_diff[1] <= 0 ? "katsViewerFooterPriceDiff" : "katsViewerFooterPriceDiffRed"} >
                    ({katsHeaderData.price_diff && katsHeaderData.price_diff[0]})
                </span>
            </div>
        )
    }

    return [

        {
            type: "common",
            text: getText(),
            icon: null,
            position: "right",
            callback: () => {
                // console.log("Home item clicked");
            },
            style: {},
            jsx: null,
        },

    ]
};

export default KatsViewerFooterSettings;

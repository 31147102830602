import { arrProxy } from "core/iterable_utils";

const fillRowAdvSelected = ({ rowObj, foundRow }) => {
	if (rowObj.key === foundRow.found_obj_key) {
		rowObj.advSelected = foundRow.search_key;
	} else {
		if (rowObj._children) {
			for (let ch of rowObj._children) fillRowAdvSelected({ rowObj: ch, foundRow });
		}
	}
};

export default function applyAdvSearch(tableData, foundRows) {
	if (foundRows.length === 0) return tableData;
	for (let sec of tableData) {
		for (let item of arrProxy(sec._children)) {
			for (let foundRow of foundRows) {
				if (foundRow.sec_id !== sec.id) continue;
				if (foundRow.item_id !== item.item_id) continue;
				fillRowAdvSelected({ rowObj: item, foundRow: foundRow });
			}
		}
	}
	return tableData;
}

export function applyAdvSearchKats(tableData, foundRows) {
	if (foundRows.length === 0) return tableData;
	for (let item of arrProxy(tableData)) {
		for (let foundRow of foundRows) {
			if (foundRow.item_id !== item.id) continue;
			fillRowAdvSelected({ rowObj: item, foundRow: foundRow });
		}
	}
	return tableData;
}

export function clearAdvSearchKats(tableData) {
	for (let tItm of tableData) {
		tItm.advSelected = null;
		for (let tItmChild of arrProxy(tItm._children)) {
			tItmChild.advSelected = null;
		}
	}

	return tableData;
}

export function clearAdvSearchLocalEstimate(tableData) {
	for (let tSec of tableData) {
		for (let tItm of arrProxy(tSec._children)) {
			tItm.advSelected = null;
			for (let tItmChild of arrProxy(tItm._children)) {
				tItmChild.advSelected = null;
				for (let tRes of arrProxy(tItmChild._children)) {
					tRes.advSelected = null;
				}
			}
		}
	}
	return tableData;
}

export function RecourseCalcPosition(list, row, obj) {
	if (list == null) {
		return obj;
	}
	if (obj.isFound) {
		return obj;
	}
	for (let elm of list) {
		if (elm.visible === false) {
			continue;
		}
		if (elm.rowHeightMultiply) {
			obj.rowCnt += elm.rowHeightMultiply;
		} else {
			obj.rowCnt += 1;
		}
		if (elm.key === row.found_obj_key) {
			obj.isFound = true;
			return obj;
		} else {
			obj = RecourseCalcPosition(arrProxy(elm["_children"]), row, obj);
			if (obj.isFound) {
				return obj;
			}
		}
	}
	return obj;
}

export function ScrollLocEstmToPos(scrollBox, rowCnt, addH, rowHeight = 22) {
	const elementH = scrollBox["current"].offsetHeight;
	const seekScroll = rowCnt * rowHeight + addH - elementH / 2;
	scrollBox["current"].scrollTop = seekScroll;
}

import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import apiCalcer from '../api/calcer';

export const getlocalEstimateIndexDirect = createAsyncThunk(
    "localEstimateSlice/getIndex",
    async (body, { rejectWithValue }) => {
        try {
            const response = await apiCalcer.getIndex(body["pz"], body["per"], body["obj"]);
            return response.data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    },
);

export const calcerSlice = createSlice({
    name: "calcerSlice",
    fetchStatus: null,
    fetchStatusText: null,
    initialState: {
        indexDb: {},
    },
    reducers: {
        setCalcerFetchStatus: (state, { payload }) => {
            state.fetchStatus = payload;
        },
    }, extraReducers: (builder) => {
        builder.addCase(getlocalEstimateIndexDirect.fulfilled, (state, { payload }) => {
            console.log(123, payload)
            if (payload.lenght > 0) {
                state.indexDb = payload[0];
            }
            state.fetchStatus = "success";
        });


        builder.addMatcher(isAnyOf(
            getlocalEstimateIndexDirect.pending,
        ), (state, action) => {
            state.fetchStatus = "loading";
        });


        builder.addMatcher(isAnyOf(
            getlocalEstimateIndexDirect.rejected
        ), (state, action) => {
            state.fetchStatus = "failed";
            if (action["payload"] !== undefined) {
                if (action["payload"].hasOwnProperty("error_text")) {
                    state.fetchStatusText = action["payload"]["error_text"];
                }
            }
        });
    }
});

export const {
    setCalcerFetchStatus
} = calcerSlice.actions;

export default calcerSlice.reducer;
import { apiBase } from "./base";

const apiLocalEstimateEditor = {
	client: apiBase.get_client(),
	url: "/editor/lsr/",

	getOrCreateCacheJson(estmId) {
		return this.client.get(this.url + estmId + "/cache_json/", apiBase.get_headers());
	},

	createEstimate({ body }) {
		return this.client.post(this.url + "create/", body, apiBase.get_headers());
	},

	editEstimateSettings({ estmId, body }) {
		return this.client.post(this.url + estmId + "/", body, apiBase.get_headers());
	},

	setVisibleSettings({ estmId, body }) {
		return this.client.post(this.url + estmId + "/visible_settings/", body, apiBase.get_headers());
	},

	convertJsonToDb({ estmId }) {
		return this.client.get(this.url + estmId + "/convert_to_db/0/", apiBase.get_headers());
	},

	convertJsonToDbAsCopy({ estmId }) {
		return this.client.get(this.url + estmId + "/convert_to_db/1/", apiBase.get_headers());
	},

	getLastActions(estmId) {
		return this.client.get(this.url + estmId + "/last_actions/", apiBase.get_headers());
	},

	cancelAction({ estmId, actionId }) {
		return this.client.get(this.url + estmId + "/cancel_action/" + actionId + "/", apiBase.get_headers());
	},

	addSection({ estmId, body }) {
		return this.client.post(this.url + estmId + "/section/", body, apiBase.get_headers());
	},

	editSection({ estmId, sectionId, body }) {
		return this.client.patch(this.url + estmId + "/section/" + sectionId + "/", body, apiBase.get_headers());
	},

	deleteSection({ estmId, sectionId }) {
		return this.client.delete(this.url + estmId + "/section/" + sectionId + "/", apiBase.get_headers());
	},

	moveSection({ estmId, sectionId, direction }) {
		return this.client.get(this.url + estmId + "/section/" + sectionId + "/move/" + direction + "/", apiBase.get_headers());
	},

	addItemByCode({ estmId, sectionId, requestCode, ItemIdAddAfter }) {
		return this.client.post(
			this.url + estmId + "/section/" + sectionId + "/item_by_code/",
			{ request_code: requestCode, item_id_add_after: ItemIdAddAfter },
			apiBase.get_headers()
		);
	},

	editItem({ estmId, sectionId, itemId, key, value, objType, resId, otherParams }) {
		let body = { key: key, value: value, obj_type: objType, res_id: resId };
		if (otherParams) {
			body = { key: key, value: value, obj_type: objType, res_id: resId, other_params: otherParams };
		}
		return this.client.patch(this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/", body, apiBase.get_headers());
	},

	editItemValArr({ estmId, sectionId, itemId, keyValArr, objType, resId }) {
		return this.client.patch(
			this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/",
			{ key_values: keyValArr, obj_type: objType, res_id: resId },
			apiBase.get_headers()
		);
	},

	deleteItem({ estmId, sectionId, itemId }) {
		return this.client.delete(this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/", apiBase.get_headers());
	},

	moveItem({ estmId, sectionId, itemId, direction }) {
		return this.client.get(this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/move/" + direction + "/", apiBase.get_headers());
	},

	getTechGroup({ estmId, sectionId, itemId, code, name }) {
		return this.client.post(
			this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/tech_grp/",
			{ res_code: code, res_name: name },
			apiBase.get_headers()
		);
	},

	replaceAbsResource({ estmId, sectionId, itemId, resId, newResCode }) {
		return this.client.post(
			this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/replace_abs/",
			{ res_id: resId, new_res_code: newResCode },
			apiBase.get_headers()
		);
	},

	deleteResource({ estmId, sectionId, itemId, resId }) {
		return this.client.delete(this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/res/" + resId + "/", apiBase.get_headers());
	},

	deleteDependantResource({ estmId, sectionId, itemId, parentResId, resId }) {
		return this.client.delete(
			this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/parent_res/" + parentResId + "/res/" + resId + "/",
			apiBase.get_headers()
		);
	},

	getNormCoefsForItem({ estmId, sectionId, itemId }) {
		return this.client.get(this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/norm_coefs/", apiBase.get_headers());
	},

	getItemData({ estmId, sectionId, itemId, body }) {
		return this.client.post(this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/data_getter/", body, apiBase.get_headers());
	},

	copyItem({ estmId, sectionId, itemId, body }) {
		return this.client.post(this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/copy_item/", body, apiBase.get_headers());
	},

	copyItemResToItemCost({ estmId, sectionId, itemId, body }) {
		return this.client.post(
			this.url + estmId + "/section/" + sectionId + "/item/" + itemId + "/copy_item_res_to_item_cost/",
			body,
			apiBase.get_headers()
		);
	},
};

export default apiLocalEstimateEditor;

import { useRef, useState, useEffect } from "react";

import Draggable from "react-draggable";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import KsprButton from "components/UI/buttons/KsprButton";
import CompletenessModalTableColumsSettings from "./CompletenessModalTableColumsSettings";
import CompletenessFilter from "./CompletenessFilter";
import useTable from "hooks/useTable.js";
import { showTableMore } from "pages/Fsnb/utils";
import apiMonitor from "api/monitor";

import "./CompletenessModal.css";
import "components/TextBoxModifyMui.css";

const CompletenessModal = ({ setVisible, tableData, setTableData, downloadCompletenessCheck }) => {
	const scrollBlock = useRef();
	const [optionValues, setOptionValues] = useState([]);
	const [curSelected, setCurSelected] = useState([]);
	const columnsData = CompletenessModalTableColumsSettings();

	useEffect(() => {
		apiMonitor.getCompletenessErrors().then((response) => {
			setOptionValues(response.data);
			setCurSelected(response.data);
		});
	}, []);

	useEffect(() => {
		const selectedCodes = curSelected.map((x) => x.code);
		setTableData((prev) =>
			prev.map((row) => {
				if (selectedCodes.includes(row.err_code)) {
					row.visible = true;
				} else {
					row.visible = false;
				}
				return row;
			})
		);
	}, [curSelected]); // eslint-disable-line react-hooks/exhaustive-deps

	const showMoreRow = (row) => {
		showTableMore(row, "12px", ["state_name", "text", "estm_name"], setTableData, "CompletenessModalModalContent");
	};

	const tableBody = useTable(
		tableData,
		columnsData,
		{
			selectable: true,
			scrollBlock: scrollBlock,
		},
		{
			updateData: setTableData,
			singleClickRowAction: {
				action: showMoreRow,
			},
		}
	);

	return (
		<Draggable handle=".CompletenessModalTitle">
			<Dialog
				className="CompletenessModalModalBox"
				sx={{ "& .MuiDialog-paper": { width: "80vw", maxWidth: "80vw", height: "800px" } }}
				open
				slotProps={{ backdrop: { style: { backgroundColor: "transparent" } } }}
				onKeyDown={(e) => {
					if (e.key === "Escape") setVisible(false);
				}}
			>
				<DialogTitle className="CompletenessModalTitle">
					<div>Проверка комплектности</div>
					<CompletenessFilter optionValues={optionValues} curSelected={curSelected} setCurSelected={setCurSelected} />
					<Tooltip title="Закрыть">
						<IconButton aria-label="Закрыть" onClick={() => setVisible(false)}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</DialogTitle>
				<DialogContent className="CompletenessModalModalContent" ref={scrollBlock}>
					{tableBody}
				</DialogContent>
				<DialogActions>
					<div className="MonitorModalbtnWrapper">
						<KsprButton
							sx={{ ml: "auto" }}
							variant="text"
							label="Скачать XSLX"
							onClick={() => downloadCompletenessCheck({ errorsFilter: curSelected.map((x) => x?.code) })}
						/>
					</div>
				</DialogActions>
			</Dialog>
		</Draggable>
	);
};

export default CompletenessModal;

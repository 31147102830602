import React, { useState } from 'react';
import RibbonElementCommon from "./RibbonElementCommon.jsx";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popover from '@mui/material/Popover';
import { Tooltip } from "@mui/material";

const RibbonElementSelect = ({ elementSettings }) => {
    const { name, _children, icon, isDisabled, nameVisible, size } = elementSettings;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className="ribbon-element-select-wrapper">

            <Tooltip title={nameVisible && name}>
                <span>
                    <button
                        className={
                            size === "large" ? "ribbon-element-select ribbon-element-select__large" : "ribbon-element-common"
                        }
                        disabled={isDisabled}
                        onClick={handleClick}
                    >
                        {icon}
                        {!nameVisible && name}
                        <ArrowDropDownIcon className={
                            size === "large" ? "ribbon-element-select-svg__large" : ""
                        } />
                    </button>
                </span>
            </Tooltip>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="ribbon-select-options">
                    {_children.map((child, index) => {
                        if (child.isVisible === false) {
                            return <span key={index}></span>;
                        }
                        return <RibbonElementCommon key={index} elementSettings={child} />
                    })}
                </div>
            </Popover>

        </div >
    );
};

export default RibbonElementSelect;

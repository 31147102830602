import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import "./JustificationModalBox.css";
import "./JustificationModalCalcJust.css";


export default function JustificationModalCalcJust({ data, estmId, f_visible }) {
    const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);

    const accordionData = [];
    let currentAccordion = null;

    data.forEach((item) => {
        if (item.data !== "Нет данных") {
            if (item.tag === "res_label" || item.tag === "cost_label") {
                if (item.data !== "Ресурс Машинист - ") {
                    if (currentAccordion) {
                        accordionData.push(currentAccordion);
                    }
                    currentAccordion = {
                        summary: item.data,
                        details: [],
                    };
                }
            } else {
                currentAccordion.details.push(item);
            }
        }
    });

    if (currentAccordion) {
        accordionData.push(currentAccordion);
    }

    const scrollToAccordionRef = useRef(null);

    useEffect(() => {
        if (scrollToAccordionRef.current) {
            scrollToAccordionRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, []);

    const isDefaultExpanded = (accordion) => {
        if (clickedTableRow.has_cost) return false
        if (accordion.summary.includes(clickedTableRow.code)) return true
        if (["LocalEstimatesItemsOverheads", "LocalEstimatesItemsProfits"].includes(clickedTableRow?.model_name)) {
            if (accordion.summary === "Накладные расходы / сметная прибыль") return true
        }
        return false
    }

    return (
        <Draggable handle=".JustificationHead">
            <div className="JustificationModalBox">
                <div className="JustificationHead">
                    <div className="JustificationTitle">Обоснование расчетов</div>
                </div>
                <div className="JustificationBody" type="CalcJust">
                    {accordionData.map((accordion, index) => (
                        <Accordion
                            key={index}
                            defaultExpanded={isDefaultExpanded(accordion)}
                            ref={(ref) => {
                                if (isDefaultExpanded(accordion)) {
                                    scrollToAccordionRef.current = ref;
                                }
                            }}
                        >
                            <AccordionSummary>{accordion.summary}</AccordionSummary>
                            <AccordionDetails>
                                {accordion.details.map((detail, detailIndex) => (
                                    <div key={detailIndex} type={detail.tag}>
                                        {detail.data}
                                    </div>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
                <div className="JustificationBodyFooter">
                    <div
                        className="But"
                        onClick={() => {
                            f_visible(false);
                        }}
                    >
                        Закрыть
                    </div>
                </div>
            </div>
        </Draggable >


    );
}

import React, { useRef, useEffect } from 'react';

import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';

import './TableRowDetails.css';

export default function TableRowDetails({ rows, ...props }) {

    const tableDetailsRef = useRef(null);

    useEffect(() => {
        const parent = props.scrollBlock.current.getBoundingClientRect();
        const element = tableDetailsRef.current.getBoundingClientRect();

        const y = element.top - parent.top;
        const bottomHeight = 150;

        if (y > window.innerHeight - parent.top - bottomHeight) {
            tableDetailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
            setTimeout(() => {
                props.scrollBlock.current.scrollTop += 52;
            }, 100);
        }

    }, [tableDetailsRef, props.scrollBlock]);

    return (
        <div className="LayoutDetail" ref={tableDetailsRef}>
            <div className="TableHeadDetailRow">
                <div className="TableDetailHeadCell" clm="line">Строка</div>
                <div className="TableDetailHeadCell" clm="element">Элемент</div>
                <div className="TableDetailHeadCell" clm="text">
                    <div className="Label">Ошибка</div>
                    <div className="IconAction" title="Выгрузить в XLSX"
                        onClick={() => props.f_downloadExcelError(props.rowId)}>
                        <FileOpenOutlinedIcon />
                    </div>
                </div>
            </div>
            <div className="TableDetailBody">
                {rows.map(row =>
                    <div className="TableDetailRow" key={row.id}>
                        <div className="TableDetailCell" clm="line" title={row.line}>{row.line}</div>
                        <div className="TableDetailCell" clm="element" title={row.element}>{row.element}</div>
                        <div className="TableDetailCell" clm="text" title={row.text}>{row.text}</div>
                    </div>
                )}
            </div>
        </div>
    )
}


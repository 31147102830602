import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
import apiProjects from "api/projects";
import { setMonFetchStatus } from "../../slices/project_work";
import { setRequestBodyEstimateIds } from "../../slices/monitor";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import TreeMainNewWrapper from "components/Tree/TreeMainNewWrapper";
import KsprButton from "components/UI/buttons/KsprButton";
import { changeNestedIterableKeyValue, copyArr, checkKeyValueExistNested } from "core/iterable_utils";
import Draggable from "react-draggable";

export default function MonitorEstimateTreeModal({ id, setModalTreeVisible }) {
    const dispatch = useDispatch();
    const [modalTree, setModalTree] = useState([]);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [hasUncheckedItems, setHasUncheckedItems] = useState(true);

    useEffect(() => {
        const treeProm = apiProjects.getTree(id);
        dispatch(setMonFetchStatus("loading"));
        treeProm
            .then((response) => {
                dispatch(setMonFetchStatus("success"));
                setModalTree(response.data);
            })
            .catch((err) => {
                dispatch(setMonFetchStatus("failed"));
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setHasUncheckedItems(
            checkKeyValueExistNested(modalTree, "isChecked", [false, null, undefined]) || !modalTree.length
        );
    }, [modalTree]);

    useEffect(() => {
        setModalTree((prev) => copyArr(changeNestedIterableKeyValue(prev, "isChecked", isCheckedAll)));
    }, [isCheckedAll]); // eslint-disable-line react-hooks/exhaustive-deps

    const getCheckedId = (items) => {
        let checkedKeys = [];

        const recourceGetCheckedId = (items) => {
            items.forEach((item) => {
                if (item.isChecked && !item._children) {
                    checkedKeys.push(item.id);
                } else if (item._children) {
                    // checkedKeys = checkedKeys.concat(recourceGetCheckedId(item._children));
                    recourceGetCheckedId(item._children);
                }
            });
        };

        recourceGetCheckedId(items);
        checkedKeys = checkedKeys.filter(Number);
        dispatch(setRequestBodyEstimateIds(checkedKeys));
        setModalTreeVisible(false);
    };

    return (
        <Draggable handle=".MonitorModalTitle">
            <Dialog
                className="MonitorModalEstimate"
                sx={{ "& .MuiDialog-paper": { width: "36vw", maxWidth: "36vw", height: "52vh", maxHeight: "52vh" } }}
                open
                BackdropProps={{
                    style: { backgroundColor: "transparent" }, // Set the background color with some transparency
                }}
                onClose={() => setModalTreeVisible(false)}>
                <DialogTitle className="MonitorModalTitle">Выбор смет для проверки</DialogTitle>
                <DialogContent className="MonitorModalTree">
                    <div className="TreeRow TreeRowCheckboxAll">
                        <Checkbox
                            size="small"
                            checked={!hasUncheckedItems}
                            onChange={() => setIsCheckedAll(!isCheckedAll)}
                        />
                        <span>Выбрать все сметы</span>
                    </div>

                    <TreeMainNewWrapper
                        data={modalTree.slice(0, -1)}
                        setData={setModalTree}
                        useCheckBox={true}
                        useRowSelect={false}
                        nameField="innertext"
                        paddingPropsArrow
                    />
                </DialogContent>
                <DialogActions>
                    <div className="MonitorModalbtnWrapper">
                        <KsprButton
                            sx={{ ml: "auto" }}
                            variant="text"
                            label="отмена"
                            onClick={() => setModalTreeVisible(false)}
                        />
                        <KsprButton
                            sx={{ ml: "auto" }}
                            variant="text"
                            label="запустить"
                            onClick={() => getCheckedId(modalTree)}
                        />
                    </div>
                </DialogActions>
            </Dialog>
        </Draggable>
    );
}

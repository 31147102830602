import { apiBase } from './base';

const apiSummaryEstimate = {
    client: apiBase.get_client(),
    url: '/smetdocs/summary_estimates/',

    get(id) {
        return this.client.get(this.url + id, apiBase.get_headers());
    },

    edit(id, body) {
        return this.client.patch(this.url + id + "/", body, apiBase.get_headers());
    },

    delete(id) {
        return this.client.delete(this.url + id, apiBase.get_headers());
    },

    calculate(id) {
        return this.client.get(this.url + "calculate/" + id + "/", apiBase.get_headers());
    },

    getTree(id, priceLevel) {
        return this.client.get(`${this.url}tree/${id}/${priceLevel}/`, apiBase.get_headers());
    },

    getInputXml({ projectId, priceLevel }) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "text/xml";
        return this.client.get(this.url + "xml/input/" + projectId + "/" + priceLevel + "/", apiBase.get_headers());
    },

    getPrintXlsx({ projectId, priceLevel }) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "blob";
        return this.client.get(this.url + "print/xlsx/" + projectId + "/" + priceLevel + "/", headers);
    },

    getPrintXlsxWithErrors({ projectId, priceLevel }) {
        const headers = apiBase.get_headers();
        headers["responseType"] = "blob";
        return this.client.get(this.url + "print/xlsx_with_errors/" + projectId + "/" + priceLevel + "/", headers);
    },

    setRowColor(body) {
        return this.client.post(this.url + "colorize/", body, apiBase.get_headers());
    },

};

export default apiSummaryEstimate;

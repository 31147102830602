import { useState, useEffect, useRef } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import apiCalcer from "../../api/calcer";
import TableMain from "../../components/Table/TableMain";
import AlertLoading from "../../components/AlertLoading";
import {
	IndexesBimPgTableColumns,
	IndexesRimAutoTruckPgTableColumns,
	IndexesRimAutoLoadPgTableColumns,
	IndexesRimRailPgTableColumns,
} from "./IndexesPgTableColumns";

import RibbonContent from "components/Ribbon/RibbonContent/RibbonContent";
import IndexesPgRibbonSettings from "./IndexesPgRibbonSettings";

import { getEnvVal } from "core/env.js";

import "./IndexesPg.css";
import "./IndexesAutocomplete.css";
import "./IndexesTable.css";

export default function IndexesPg(props) {
	const [fetchStatus, setFetchStatus] = useState(null);
	const [fetchStatusText, setFetchStatusText] = useState("");

	const [methods, setMethods] = useState([]);
	const [truckingCls, setTruckingCls] = useState([]);
	const [periods, setPeriods] = useState([]);
	const [priceZones, setPriceZones] = useState([]);
	const [vehicles, setVehicles] = useState([]);
	const [indOgrs, setIndOgrs] = useState([]);
	const [cargoNames, setCargoNames] = useState([]);

	const [selectedMethods, setSelectedMethods] = useState({});
	const [selectedTruckingCls, setSelectedTruckingCls] = useState({});
	const [selectedPeriod, setSelectedPeriod] = useState({});
	const [selectedPriceZone, setSelectedPriceZone] = useState({});
	const [selectedVehicle, setSelectedVehicle] = useState({});
	const [selectedIndOgr, setSelectedIndOgr] = useState({});
	const [selectedCargoNames, setSelectedCargoNames] = useState({});

	const [defaultMethods, setDefaultMethods] = useState({});
	const [defaultPeriod, setDefaultPeriod] = useState({});
	const [defaultPriceZone, setDefaultPriceZone] = useState({});
	const [defaultIndOgr, setDefaultIndOgr] = useState({});

	const [rowsData, setRowsData] = useState({});
	const columnsData = useRef([]);
	const curTable = useRef("");

	useEffect(() => {
		setFetchStatus("loading");
		const vehiclePromise = new Promise((resolve, reject) => {
			apiCalcer
				.getVehicleTypes()
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});

		const perPromise = new Promise((resolve, reject) => {
			apiCalcer
				.getPeriods()
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});

		const pzPromise = new Promise((resolve, reject) => {
			apiCalcer
				.getPriceZones()
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});

		const indOrgPromise = new Promise((resolve, reject) => {
			apiCalcer
				.getIndustryOrganization()
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});

		const cargoPromise = new Promise((resolve, reject) => {
			apiCalcer
				.getRailCargoNames()
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		});

		Promise.all([vehiclePromise, perPromise, pzPromise, indOrgPromise, cargoPromise])
			.then((response_arrs) => {
				setVehicles(response_arrs[0]);
				setPeriods(response_arrs[1]);
				setPriceZones(response_arrs[2]);
				setIndOgrs(response_arrs[3]);
				setCargoNames(response_arrs[4]);
				setSelectedCargoNames(response_arrs[4][0]);
				let methodArr = [];
				if (getEnvVal("App") === "sipcs") {
					methodArr = [{ id: "rim", name: "Ресурсно-индексный" }];
				} else {
					methodArr = [
						{ id: "bim", name: "Базисно-индексный" },
						{ id: "rim", name: "Ресурсно-индексный" },
					];
				}
				const truckArr = [
					{ id: "1", name: "Автоперевозки" },
					{ id: "2", name: "Погрузо-разгрузочные работы" },
					{ id: "3", name: "Ж/д перевозки" },
				];
				setMethods(methodArr);
				setTruckingCls(truckArr);
				setFetchStatus("success");
				return [...response_arrs, methodArr, truckArr];
			})
			.then((data) => {
				for (let obj of [
					{ localStgId: "vehicleId", dataIndex: 0, setDefaultFunc: setSelectedVehicle },
					{ localStgId: "indexPeriodId", dataIndex: 1, setDefaultFunc: setDefaultPeriod },
					{ localStgId: "indexPriceZonesId", dataIndex: 2, setDefaultFunc: setDefaultPriceZone },
					{ localStgId: "indexIndOrgId", dataIndex: 3, setDefaultFunc: setDefaultIndOgr },
					{ localStgId: "methodId", dataIndex: 5, setDefaultFunc: setDefaultMethods },
					{ localStgId: "truckingClsId", dataIndex: 6, setDefaultFunc: setSelectedTruckingCls },
				]) {
					if (localStorage.getItem(obj.localStgId) != null) {
						const val = data[obj.dataIndex].find((x) => String(x["id"]) === localStorage.getItem(obj.localStgId));
						obj.setDefaultFunc(val);
						if (obj.localStgId === "indexIndOrgId") setSelectedIndOgr(val);
					} else {
						obj.setDefaultFunc(data[obj.dataIndex][0]);
						if (obj.localStgId === "indexIndOrgId") setSelectedIndOgr(data[obj.dataIndex][0]);
					}
				}
			})
			.catch((error) => {
				console.error(error);
				setFetchStatus("failed");
				if (error?.response?.data?.detail != null) {
					setFetchStatusText(error.response.data.detail);
				} else {
					setFetchStatusText(error.message);
				}
			});
	}, []);

	useEffect(() => {
		if (selectedMethods?.id === "bim") {
			setFetchStatus("loading");
			columnsData.current = IndexesBimPgTableColumns();
			curTable.current = "bim";
			if (selectedPeriod.id != null && selectedPriceZone != null) {
				setRowsData({ data: [] });
				const apiPromise = apiCalcer.getPgIndex(selectedPriceZone.id, selectedPeriod.id);
				apiPromise
					.then((response) => {
						if (response.data.hasOwnProperty("id")) {
							response.data["key"] = response.data["id"];
							setRowsData({ data: [response.data] });
						} else {
							setRowsData({ data: [] });
						}
						setFetchStatus("success");
					})
					.catch((error) => {
						setFetchStatus("failed");
						setFetchStatusText(error.message);
					});
			}
		} else if (selectedMethods?.id === "rim") {
			if (selectedTruckingCls?.id === "1") {
				setFetchStatus("loading");
				columnsData.current = IndexesRimAutoTruckPgTableColumns();
				curTable.current = "rim_auto_truck";
				if (selectedPeriod.id != null && selectedPriceZone != null && selectedVehicle != null && selectedIndOgr != null) {
					setRowsData({ data: [] });
					const apiPromise = apiCalcer.getAutoTruckingTariff(
						selectedPriceZone.id,
						selectedPeriod.id,
						selectedVehicle.id,
						selectedIndOgr.id
					);
					apiPromise
						.then((response) => {
							setRowsData({ data: response.data });
							setFetchStatus("success");
						})
						.catch((error) => {
							setFetchStatus("failed");
							setFetchStatusText(error.message);
						});
				}
			} else if (selectedTruckingCls?.id === "2") {
				setFetchStatus("loading");
				columnsData.current = IndexesRimAutoLoadPgTableColumns();
				curTable.current = "rim_load_truck";
				if (selectedPeriod.id != null && selectedPriceZone != null && selectedIndOgr != null) {
					setRowsData({ data: [] });
					const apiPromise = apiCalcer.getAutoLoadingTariff(selectedPriceZone.id, selectedPeriod.id, selectedIndOgr.id);
					apiPromise
						.then((response) => {
							setRowsData({ data: response.data });
							setFetchStatus("success");
						})
						.catch((error) => {
							setFetchStatus("failed");
							setFetchStatusText(error.message);
						});
				}
			} else if (selectedTruckingCls?.id === "3") {
				setFetchStatus("loading");
				columnsData.current = IndexesRimRailPgTableColumns();
				curTable.current = "rim_auto_truck";
				if (selectedPeriod.id != null) {
					setRowsData({ data: [] });
					const apiPromise = apiCalcer.getRailTruckingTariff(selectedPeriod.id, selectedCargoNames.id);
					apiPromise
						.then((response) => {
							setRowsData({ data: response.data });
							setFetchStatus("success");
						})
						.catch((error) => {
							setFetchStatus("failed");
							setFetchStatusText(error.message);
						});
				}
			}
		}
	}, [selectedPeriod, selectedPriceZone, selectedMethods, selectedTruckingCls, selectedVehicle, selectedIndOgr, selectedCargoNames]);

	useEffect(() => {
		if (selectedMethods?.id === "bim") {
			if (truckingCls.length > 0) {
				setSelectedTruckingCls(truckingCls[0]);
			}
			if (vehicles.length > 0) {
				setSelectedVehicle(vehicles[0]);
			}
		}
	}, [selectedMethods, truckingCls, vehicles]);

	if (fetchStatus === "failed") {
		return <AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} />;
	}

	for (let arrState of [vehicles, periods, priceZones, methods, truckingCls]) {
		if (arrState.length === 0) {
			return;
		}
	}

	const getAutocompleteSx = () => {
		return {
			width: "100%",
			"& legend": {
				fontSize: "9px",
			},
		};
	};

	const periodPriceZoneAutocomplete = () => {
		return (
			<div className="indexesAutocompleteWrapper">
				<Autocomplete
					sx={getAutocompleteSx()}
					options={periods}
					defaultValue={defaultPeriod}
					disableClearable
					getOptionLabel={(option) => option.name}
					size="small"
					noOptionsText="нет данных"
					onInputChange={(e, val) => {
						const inpVal = periods.find((x) => x["name"] === val);
						if (inpVal != null) {
							setSelectedPeriod(inpVal);
						}
					}}
					onClose={(e) => {
						if (e.target.innerText !== "") {
							const itm = periods.find((x) => x["name"] === e.target.innerText);
							localStorage.setItem("indexPeriodId", itm.id);
						}
					}}
					renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} label="Период" />}
				/>
				<Autocomplete
					sx={getAutocompleteSx()}
					options={priceZones}
					defaultValue={defaultPriceZone}
					disableClearable
					getOptionLabel={(option) => option.name}
					size="small"
					noOptionsText="нет данных"
					onInputChange={(e, val) => {
						const inpVal = priceZones.find((x) => x["name"] === val);
						if (inpVal != null) {
							setSelectedPriceZone(inpVal);
						}
					}}
					onClose={(e) => {
						if (e.target.innerText !== "") {
							const itm = priceZones.find((x) => x["name"] === e.target.innerText);
							if (itm != null) {
								localStorage.setItem("indexPriceZonesId", itm.id);
							}
						}
					}}
					renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} label="Ценовая зона" />}
				/>
			</div>
		);
	};

	const methodClassTransporAutocomplete = () => {
		return (
			<div className="indexesAutocompleteWrapper">
				<div className="indexesAutocompleteWrapperTop">
					<Autocomplete
						sx={getAutocompleteSx()}
						options={methods}
						defaultValue={defaultMethods}
						disableClearable
						getOptionLabel={(option) => option.name}
						size="small"
						noOptionsText="нет данных"
						onInputChange={(e, val) => {
							setSelectedMethods(methods.find((x) => x["name"] === val));
						}}
						onClose={(e) => {
							if (e.target.innerText !== "") {
								const itm = methods.find((x) => x["name"] === e.target.innerText);
								localStorage.setItem("methodId", itm.id);
							}
						}}
						renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} label="Метод расчёта" />}
					/>
					<Autocomplete
						sx={getAutocompleteSx()}
						getOptionDisabled={(option) => selectedMethods["id"] === "bim"}
						readOnly={selectedMethods["id"] === "bim"}
						options={truckingCls}
						value={selectedTruckingCls}
						disableClearable
						getOptionLabel={(option) => option.name}
						size="small"
						noOptionsText="нет данных"
						onInputChange={(e, val) => {
							const inpTrk = truckingCls.find((x) => x["name"] === val);
							if (inpTrk != null) {
								setSelectedTruckingCls(inpTrk);
							}
						}}
						onClose={(e) => {
							if (e.target.innerText !== "") {
								const itm = truckingCls.find((x) => x["name"] === e.target.innerText);
								localStorage.setItem("truckingClsId", itm.id);
							}
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								InputLabelProps={{ shrink: true }}
								disabled={selectedMethods["id"] === "bim"}
								label="Классификация услуг"
							/>
						)}
					/>
				</div>

				<div className="indexesAutocompleteWrapperTop">
					{selectedTruckingCls?.id === "3" ? (
						<Autocomplete
							sx={getAutocompleteSx()}
							getOptionDisabled={(option) => selectedMethods["id"] === "bim" || selectedTruckingCls["id"] !== "3"}
							readOnly={selectedMethods["id"] === "bim" || selectedTruckingCls["id"] !== "3"}
							options={cargoNames}
							value={selectedCargoNames}
							disableClearable
							getOptionLabel={(option) => option.name}
							size="small"
							noOptionsText="нет данных"
							onInputChange={(e, val) => {
								const inpVal = cargoNames.find((x) => x["name"] === val);
								if (inpVal != null) setSelectedCargoNames(inpVal);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									InputLabelProps={{ shrink: true }}
									disabled={selectedMethods["id"] === "bim" || selectedTruckingCls["id"] !== "3"}
									label="Наименование группы грузов"
								/>
							)}
						/>
					) : (
						<Autocomplete
							sx={getAutocompleteSx()}
							getOptionDisabled={(option) => selectedMethods["id"] === "bim" || selectedTruckingCls["id"] !== "1"}
							readOnly={selectedMethods["id"] === "bim" || selectedTruckingCls["id"] !== "1"}
							options={vehicles}
							value={selectedVehicle}
							disableClearable
							getOptionLabel={(option) => option.name}
							size="small"
							noOptionsText="нет данных"
							onInputChange={(e, val) => {
								const inpVal = vehicles.find((x) => x["name"] === val);
								if (inpVal != null) {
									setSelectedVehicle(inpVal);
								}
							}}
							onClose={(e) => {
								if (e.target.innerText !== "") {
									const itm = vehicles.find((x) => x["name"] === e.target.innerText);
									if (itm != null) {
										localStorage.setItem("vehicleId", itm.id);
									}
								}
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									InputLabelProps={{ shrink: true }}
									disabled={selectedMethods["id"] === "bim" || selectedTruckingCls["id"] !== "1"}
									label="Тип транспортного средства"
								/>
							)}
						/>
					)}

					<Autocomplete
						sx={getAutocompleteSx()}
						options={indOgrs}
						defaultValue={defaultIndOgr}
						getOptionLabel={(option) => option.name || ""}
						size="small"
						noOptionsText="нет данных"
						onInputChange={(e, val) => {
							const inpVal = indOgrs.find((x) => x["name"] === val);
							if (inpVal) setSelectedIndOgr(inpVal);
							// if (!e?.target) localStorage.setItem("indexIndOrgId", 0);
						}}
						onClose={(e) => {
							const itm = indOgrs.find((x) => x["name"] === e.target.innerText);
							if (itm) localStorage.setItem("indexIndOrgId", itm.id);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								InputLabelProps={{ shrink: true }}
								disabled={selectedMethods["id"] === "bim"}
								label="Отраслевая организация"
							/>
						)}
					/>
				</div>
			</div>
		);
	};

	return (
		<>
			<RibbonContent ribbonSettings={IndexesPgRibbonSettings(periodPriceZoneAutocomplete, methodClassTransporAutocomplete)} />
			<div className="IndexesPgMain" cur_table={curTable.current}>
				<div className="IndexesFilters"></div>
				<div className="IndexesTable">
					<TableMain
						rows={rowsData}
						columns={columnsData.current}
						options={{
							selectable: false,
							scrollBlock: props.scrollBlock,
							displayScrollDelay: 20,
						}}
						func={{}}
					></TableMain>
				</div>
				<AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} />
			</div>
		</>
	);
}

import { useState, useEffect, useRef, useLayoutEffect, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";

import Draggable from "react-draggable";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import KsprButton from "components/UI/buttons/KsprButton";
import KsprIconButton from "components/UI/buttons/KsprIconButton";
import KsprInput from "components/UI/input/KsprInput";

import AlertLoading from "components/AlertLoading";

import { KatsPriceOfferSelectorColumns } from "./KatsPriceOfferSelectorColumns";
import { KatsDetailsPaper } from "../../KatsTableColumns";
import { extractTextBetweenSimbols } from "core/str_utils";
import apiKatsEstimate from "api/kats";
import { getKatsTree } from "slices/kats";

import useTable from "hooks/useTable.js";
import usePrevious from "hooks/usePrevious.js";

import { KatsDateRangeAutocomplete } from "./KatsDateRangeAutocomplete";
import { PriceOfferDataSourceModal } from "../PriceOfferDataSourceModal/PriceOfferDataSourceModal";
import { sortObjArrByNumberAsStr } from "core/iterable_utils";

import "./KatsPriceOfferSelectorModal.css";
const InnKppModal = lazy(() => import("pages/EstimateViewer/KatsViewer/components/InnKppModal/InnKppModal"));

const KatsPriceOfferSelectorModal = ({ setVisible, lowerCallbacks }) => {
	const dispatch = useDispatch();
	const scrollBlock = useRef(null);

	const [ksrMode, setKsrMode] = useState();
	const [regionMode, setRegionMode] = useState();
	const [onlyWinner, setOnlyWinner] = useState(false);

	const [selectedPeriod, setSelectedPeriod] = useState({});

	const [fetchStatus, setFetchStatus] = useState(null);
	const [fetchStatusText, setFetchStatusText] = useState(null);
	const [searchName, setSearchName] = useState();
	const [searchButtonClicked, setSearchButtonClicked] = useState(0);

	const [offerPriceTableData, setOfferPriceTableData] = useState([]);
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const currentEstmDocId = useSelector((state) => state.ribbon.currentEstmDocId);
	const currentProjectID = useSelector((state) => state.ribbon.currentProjectID);
	const projectPriceZoneId = useSelector((state) => state.projectWork.main.price_zone_id);
	const projectPriceZoneName = useSelector((state) => state.projectWork.main.price_zone_name);
	const projectDateCreate = useSelector((state) => state.projectWork.main.date_create);
	const windowWidth = useSelector((state) => state.windowDimensions.windowWidth);

	const [searchDatePeriod, setSearchDatePeriod] = useState({ startDate: null, endDate: null });
	const [paperDataSourceObj, setPaperDataSourceObj] = useState({});
	const [priceOfferDataSourceModalVisible, setPriceOfferDataSourceModalVisible] = useState(false);

	const [innKppInfoVisible, setInnKppInfoVisible] = useState(false);
	const [innKppInfoData, setInnKppInfoData] = useState({});

	const changePaperDetailsVisible = ({ clickedRow, val }) => {
		setOfferPriceTableData((prev) =>
			prev.map((row) => {
				if (clickedRow.key === row.key) {
					let newVal;
					if (val != null) {
						newVal = val;
					} else {
						newVal = row.paper_visible !== null ? !row.paper_visible : true;
					}
					row.paper_visible = newVal;
				}
				return row;
			})
		);
	};

	const columnsData = KatsPriceOfferSelectorColumns({ getOfferNeighbour, changePaperDetailsVisible });

	let ksrGroupCode = "";
	if (clickedTableRow.model_name === "KatsResources") {
		ksrGroupCode = extractTextBetweenSimbols(clickedTableRow.code, "_");
	} else if (clickedTableRow.model_name === "KatsOffers") {
		ksrGroupCode = extractTextBetweenSimbols(clickedTableRow.code, "_");
		if (ksrGroupCode === "") {
			ksrGroupCode = extractTextBetweenSimbols(clickedTableRow?.parentRow?.code, "_");
		}
	}

	const getFormattedDate = (dateObj) => {
		if (!dateObj) return;
		let month = dateObj.getMonth() + 1;
		if (month < 10) month = "0" + month;
		let day = dateObj.getDate();
		if (day < 10) day = "0" + day;

		return `${dateObj.getFullYear()}-${month}-${day}`;
	};

	const getRequestData = () => {
		return {
			ksr: {
				code_group: ksrGroupCode,
				name: searchName,
				use_neighbour: ksrMode === "neighbour",
				use_all: ksrMode === "all",
				name_search_mode: "fuzzy",
			},
			price_zone: {
				id: projectPriceZoneId,
				use_neighbour: regionMode === "neighbour",
				use_all: regionMode === "all",
			},
			date: {
				start: getFormattedDate(searchDatePeriod.startDate),
				end: getFormattedDate(searchDatePeriod.endDate),
			},
			params: {
				project_id: currentProjectID,
				only_winner: onlyWinner,
			},
		};
	};

	const getDateRange = (options) => {
		let initStartDate = new Date(projectDateCreate);
		let initEndDate = new Date(projectDateCreate);
		initStartDate.setMonth(initStartDate.getMonth() - 6);
		initEndDate.setDate(initEndDate.getDate() + 1);

		if (options && options?.month) {
			initStartDate.setMonth(initStartDate.getMonth() - options.month);
			initEndDate.setMonth(initEndDate.getMonth() + options.month);
			if (initEndDate > new Date()) initEndDate = new Date();
		} else if (options && options?.all_time) {
			initStartDate = new Date(2022, 1, 1);
			initEndDate = new Date();
		}

		return { initStartDate, initEndDate };
	};

	useLayoutEffect(() => {
		setKsrMode("all");
		setRegionMode("all");
		setSearchName(clickedTableRow.name);
	}, [clickedTableRow]);

	const searchDatePeriodPrev = usePrevious(searchDatePeriod);
	const ksrModePrev = usePrevious(ksrMode);
	const regionModePrev = usePrevious(regionMode);
	const onlyWinnerPrev = usePrevious(onlyWinner);
	const searchButtonClickedPrev = usePrevious(searchButtonClicked);

	useEffect(() => {
		if (!searchDatePeriod.startDate || !searchDatePeriod.endDate || !ksrMode || !regionMode) return;

		if (
			ksrMode === ksrModePrev &&
			regionMode === regionModePrev &&
			onlyWinner === onlyWinnerPrev &&
			searchButtonClicked === searchButtonClickedPrev &&
			String(searchDatePeriod.startDate) === String(searchDatePeriodPrev.startDate) &&
			String(searchDatePeriod.endDate) === String(searchDatePeriodPrev.endDate)
		) {
			return;
		}

		setFetchStatus("loading");

		const prom = apiKatsEstimate.getPriceOfferData({ body: getRequestData() });
		prom.then((response) => {
			setOfferPriceTableData(
				response.data.data.map((row) => {
					delete row._children;
					return row;
				})
			);
			setFetchStatus("success");
		}).catch((error) => {
			console.error(error);
			setFetchStatus("failed");
		});
	}, [searchDatePeriod, ksrMode, regionMode, onlyWinner, searchButtonClicked]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!selectedPeriod?.id) return;
		let seekDateRange = {};
		if (selectedPeriod.id === "start_state") {
			seekDateRange = getDateRange();
		} else if (selectedPeriod.id === "3_month") {
			seekDateRange = getDateRange({ month: 3 });
		} else if (selectedPeriod.id === "6_month") {
			seekDateRange = getDateRange({ month: 6 });
		} else if (selectedPeriod.id === "9_month") {
			seekDateRange = getDateRange({ month: 9 });
		} else if (selectedPeriod.id === "12_month") {
			seekDateRange = getDateRange({ month: 12 });
		} else if (selectedPeriod.id === "all_time") {
			seekDateRange = getDateRange({ all_time: true });
		}
		setSearchDatePeriod({ startDate: seekDateRange.initStartDate, endDate: seekDateRange.initEndDate });
	}, [selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

	const addExpertOffer = () => {
		const selectedOffer = offerPriceTableData.find((row) => row.selected === true);
		if (selectedOffer) {
			setFetchStatus("loading");
			let resId;
			if (clickedTableRow.model_name === "KatsResources") {
				resId = clickedTableRow?.id;
			} else if (clickedTableRow.model_name === "KatsOffers") {
				resId = clickedTableRow?.parentRow?.id;
			}
			const prom = apiKatsEstimate.addExpertOffer({
				resId: resId,
				body: { price_offer_id: selectedOffer.id },
			});
			prom.then((response) => {
				dispatch(getKatsTree(currentEstmDocId));
				setVisible(false);
				setFetchStatus("success");
			}).catch((error) => {
				console.error(error);
				setFetchStatus("failed");
			});
		}
	};

	function getOfferNeighbour({ offerId }) {
		setFetchStatus("loading");
		const prom = apiKatsEstimate.getOfferNeighbour({ body: { offerId: offerId, offerName: clickedTableRow.name } });
		prom.then((response) => {
			const copyTableData = [...offerPriceTableData].filter((x) => x.isNeighbour !== true);
			const indexOffer = copyTableData.map((x) => x.price_offer_id).indexOf(offerId) + 1;

			const newTableData = response.data.map((row) => {
				row.bgColor = "#e6f5e6";
				row.key = `${row.key}-neighbour`;
				row.isNeighbour = true;
				return row;
			});

			const tableCopy = [...copyTableData.slice(0, indexOffer), ...newTableData, ...copyTableData.slice(indexOffer)];
			setOfferPriceTableData(tableCopy);
			setFetchStatus("success");
		}).catch((error) => {
			console.error(error);
			setFetchStatus("failed");
		});
	}

	const updateDataL = (data) => setOfferPriceTableData(data);

	const selectDataLocal = (table, row, target, parentRow) => {
		setOfferPriceTableData((prev) =>
			prev.map((x) => {
				if (x.key === row.key) {
					x.selected = true;
				} else {
					x.selected = false;
				}
				return x;
			})
		);
	};

	const setPriceOfferDataSourceModalVisibleCompose = () => {
		setPriceOfferDataSourceModalVisible(false);
		setPaperDataSourceObj({});
	};

	const openSourceInfo = ({ paperRow }) => {
		setPriceOfferDataSourceModalVisible(true);
		setPaperDataSourceObj(paperRow);
	};

	const tableBody = useTable(
		offerPriceTableData,
		columnsData,
		{
			scrollBlock: scrollBlock,
			selectable: true,
			funcCall: "local",
			paperSettings: KatsDetailsPaper({ setInnKppInfoVisible, setInnKppInfoData }),
		},
		{
			updateData: updateDataL,
			selectDataLocal: selectDataLocal,
			changePaperDetailsVisible: changePaperDetailsVisible,
			getJustFile: lowerCallbacks.current.kats.getJustFile,
			openSourceInfo: openSourceInfo,
			customSortDataUp: {
				estimate_without_vat: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: clmName, direction: "up" }),
			},
			customSortDataDown: {
				estimate_without_vat: ({ data, clmName }) => sortObjArrByNumberAsStr({ data: data, clmName: clmName, direction: "down" }),
			},
		}
	);

	const handleCustomSearch = () => {
		setKsrMode("all");
		setRegionMode("all");
		setSearchButtonClicked((prev) => prev + 1);
	};

	return (
		<Draggable handle=".KatsPriceOfferSelectorModalTitle">
			<Dialog
				className="KatsPriceOfferSelectorModal"
				sx={{ "& .MuiDialog-paper": { width: "85vw", maxWidth: "85vw", height: "710px" } }}
				open
				slotProps={{ backdrop: { style: { backgroundColor: "transparent" } } }}
			>
				<DialogTitle className="KatsPriceOfferSelectorModalTitle">
					<div className="TitleText">
						Подбор ценовых предложений для [{ksrGroupCode}] {clickedTableRow.name}, {clickedTableRow.unit}
					</div>
					<div>
						<Tooltip title="Закрыть">
							<IconButton aria-label="Закрыть" onClick={() => setVisible(false)}>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					</div>
				</DialogTitle>
				<DialogContent className="KatsPriceOfferSelectorModalContent">
					<div className="KatsPriceOfferSelectorModalHeader">
						<Grid pt={2} container>
							<Grid item xs={2.5}>
								<table className="searchOfferInfoTable">
									<tbody className="searchOfferInfo">
										<tr>
											<td>Заявленая сметная цена, руб: </td>
											<td className="offerValue">{clickedTableRow.cost}</td>
										</tr>
										<tr>
											<td>{windowWidth > 1850 ? "Регион (ценовая зона) объекта" : "Ценовая зона"}:</td>
											<td className="offerValue">{projectPriceZoneName}</td>
										</tr>
										<tr>
											<td>{windowWidth > 1850 ? "Период определения цены" : "Период"}:</td>
											<td className="offerValue">
												{getFormattedDate(searchDatePeriod.startDate)} - {getFormattedDate(searchDatePeriod.endDate)}
											</td>
										</tr>
										<tr>
											<td>
												<div className="searchOfferPeriodWrapper">
													<div className="searchOfferCheckboxTitle"></div>
												</div>
											</td>
											<td className="searchOfferPeriodSelect">
												<KatsDateRangeAutocomplete
													selectedOption={selectedPeriod}
													setSelectedOption={setSelectedPeriod}
													label="Выбрать период"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</Grid>
							<Grid item xs={2.5}>
								<FormControl>
									<FormLabel className="searchOfferInfoLabel" id="demo-radio-buttons-group-label">
										Условия отбора по привязке КСР
									</FormLabel>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										value={ksrMode}
										onChange={(e) => setKsrMode(e.target.value)}
										name="radio-buttons-group"
									>
										<FormControlLabel
											value="exact"
											control={<Radio size="small" />}
											label={
												windowWidth > 1700
													? "Показать только предложения по группе КСР"
													: "Показать предложения по группе КСР"
											}
										/>
										<FormControlLabel
											value="neighbour"
											control={<Radio size="small" />}
											label={
												windowWidth > 1700 ? "Добавить предложения из соседних групп КСР" : "Добавить из соседних групп КСР"
											}
										/>
										<FormControlLabel value="all" control={<Radio size="small" />} label="Показать по всей базе" />
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={2.5}>
								<FormControl>
									<FormLabel className="searchOfferInfoLabel" id="demo-radio-buttons-group-label">
										Условия отбора по ценовым зонам
									</FormLabel>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										value={regionMode}
										onChange={(e) => setRegionMode(e.target.value)}
										name="region"
									>
										<FormControlLabel
											value="exact"
											control={<Radio size="small" />}
											label={windowWidth > 1700 ? "Искать только по ценовой зоне объекта" : "Искать по ценовой зоне объекта"}
										/>
										<FormControlLabel value="neighbour" control={<Radio size="small" />} label="Добавить соседние ценовые зоны" />
										<FormControlLabel value="all" control={<Radio size="small" />} label="Искать по всем регионам" />
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={4.5} type="winner">
								<FormControl>
									<FormLabel className="searchOfferInfoLabel" id="demo-radio-buttons-group-label">
										Дополнительные условия отбора
									</FormLabel>
									<FormControlLabel
										control={
											<div>
												<EmojiEventsOutlinedIcon />
												<Switch
													checked={onlyWinner}
													size="small"
													onChange={() => setOnlyWinner((prev) => !prev)}
													inputProps={{ "aria-label": "Победитель" }}
												/>
											</div>
										}
										label="Предложения с минимальной ценой"
									/>
								</FormControl>
								<div className="CustomSearchInput">
									<KsprInput
										label="Поиск по произвольному наименованию"
										size="small"
										value={searchName}
										onChange={(e) => setSearchName(e.target.value)}
										onKeyDown={(e) => {
											if (e.key === "Enter") handleCustomSearch();
										}}
									/>
									<KsprIconButton
										size="small"
										label="Найти"
										startIcon={<SearchOutlinedIcon />}
										onClick={() => handleCustomSearch()}
									/>
								</div>
							</Grid>
						</Grid>
					</div>

					<div className="KatsPriceOfferSelectorModalTable" ref={scrollBlock}>
						{tableBody}
					</div>

					<AlertLoading
						fetchStatus={fetchStatus}
						func={[setFetchStatus]}
						fetchStatusText={fetchStatusText}
						setFetchStatusText={setFetchStatusText}
						isLocal={true}
					/>

					{priceOfferDataSourceModalVisible && (
						<PriceOfferDataSourceModal setVisible={setPriceOfferDataSourceModalVisibleCompose} dataObj={paperDataSourceObj} />
					)}

					{innKppInfoVisible && (
						<Suspense>
							<InnKppModal setVisible={setInnKppInfoVisible} requestData={innKppInfoData} />
						</Suspense>
					)}
				</DialogContent>
				<DialogActions>
					<div className="MonitorModalbtnWrapper">
						{/* <KsprButton
                            sx={{ ml: "auto" }}
                            variant="text"
                            label="отмена"
                            onClick={() => setVisible(false)}
                        /> */}
						<KsprButton
							sx={{ ml: "auto" }}
							variant="text"
							label="добавить в проект"
							disabled={!offerPriceTableData.find((row) => row.selected === true)}
							onClick={() => addExpertOffer()}
						/>
					</div>
				</DialogActions>
			</Dialog>
		</Draggable>
	);
};

export { KatsPriceOfferSelectorModal };

import { useState } from "react";
import Popover from '@mui/material/Popover';
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

import ContextMenuCommon from "./ContextMenuCommon";

const ContextMenuSelect = ({ label, options, disabled, icon }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [style, setStyle] = useState({});

    const openSelect = (event) => {
        setAnchorEl(event.currentTarget);
        setStyle({ backgroundColor: "#ececec" });
    };

    const hideSelect = () => {
        setAnchorEl(null);
        setStyle({ backgroundColor: null });
    };

    const open = Boolean(anchorEl) && !disabled;

    return (
        <div
            className={`context-menu-common ${disabled ? "disabled" : ""}`}
            style={style}
            onMouseEnter={(e) => openSelect(e)}
            onMouseLeave={() => hideSelect()}>
            {icon ? icon : <div className="context-menu-empty-item"></div>}
            {label}

            <ArrowRightOutlinedIcon className="context-menu-arrow" />

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={hideSelect}
                onClick={hideSelect}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
            >
                <div className="ribbon-select-options"
                    onMouseLeave={(e) => hideSelect()}
                    onClick={(e) => hideSelect()}>
                    {options.map((option, index) => (
                        <ContextMenuCommon
                            key={index}
                            label={option.label}
                            callback={option?.callback}
                            disabled={option?.isDisabled}
                            icon={<></>}
                        />
                    ))}
                </div>
            </Popover>
        </div>
    );
};

export default ContextMenuSelect;

import apiCalcer from "api/calcer";

const isValidVal = (val) => {
	if (val === 0) return false;
	if (val == null) return false;
	if (val === "") return false;
	return true;
};

export const updateIndexFromDb = (p, setDetailParamsDecorator) => {
	if (isValidVal(p.pz_vers) && isValidVal(p.per_vers) && isValidVal(p.obj_vers)) {
		apiCalcer
			.getIndex(p.pz_vers, p.per_vers, p.obj_vers)
			.then((response) => {
				if (response.data.length > 0) {
					setDetailParamsDecorator(response.data[0]);
				} else {
					setDetailParamsDecorator({ ot_index: "", mech_index: "", mat_index: "" });
				}
			})
			.catch((error) => {
				console.error(error);
				setDetailParamsDecorator({ ot_index: "", mech_index: "", mat_index: "" });
			});
	}
};

export const updatePgIndexFromDb = (p, setDetailParamsDecorator) => {
	if (isValidVal(p.per_vers) && isValidVal(p.pz_vers)) {
		apiCalcer.getPgIndex(p.pz_vers, p.per_vers).then((response) => {
			if (Object.keys(response.data).length > 0) {
				setDetailParamsDecorator(response.data);
			} else {
				setDetailParamsDecorator({
					pg_bort_index: "",
					pg_sam_index: "",
					pg_pan_index: "",
					pg_trub_index: "",
					pg_bet_index: "",
				});
			}
		});
	}
};

export const updateSmrIndexFromDb = (p, setDetailParamsDecorator) => {
	if (isValidVal(p.pz_vers) && isValidVal(p.per_vers) && isValidVal(p.obj_vers)) {
		apiCalcer.getIndexSmr(p.pz_vers, p.per_vers, p.obj_vers).then((response) => {
			if (response.data.length > 0) {
				setDetailParamsDecorator(response.data[0]);
			} else {
				setDetailParamsDecorator({ smr_index: "" });
			}
		});
	}
};

export const updateEqpIndexFromDb = (p, setDetailParamsDecorator) => {
	if (isValidVal(p.per_vers) && isValidVal(p.ind_vers)) {
		apiCalcer.getEqpIndex(p.per_vers, p.ind_vers).then((response) => {
			if (response.data.length > 0) {
				setDetailParamsDecorator(response.data[0]);
			} else {
				setDetailParamsDecorator("");
			}
		});
	}
};

import { useDispatch } from "react-redux";

// import { getNextObjInArrOnPrevKeyVal } from "core/iterable_utils";

const useCellEditing = ({ rowObj, funcs, options }) => {
    const dispatch = useDispatch();

    const stopCurrentCellEditing = () => {
        if (funcs.editData != null) {
            const clearObj = { key: rowObj.key, inline_cell: "" };
            dispatch(funcs["editData"](clearObj));
        }
    };

    // const startNextCellEditing = ({ prevClmName }) => {
    //     const nextClm = getNextObjInArrOnPrevKeyVal({ arr: options.editingClmsRobin, key: "name", value: prevClmName });
    //     const clearObj = { key: rowObj.key, inline_cell: nextClm.name };
    //     console.log("clearObj", clearObj);
    //     dispatch(funcs["editData"](clearObj));
    // };

    const commitChanges = ({ clmName, selectedValue, remoteSave, force }) => {
        const dataObj = {
            id: rowObj.id,
            key: rowObj.key,
        };

        if (selectedValue === "empty") {
            dataObj[clmName] = null;
        } else if (selectedValue == null) {
            dataObj[clmName] = options.editingCell;
        } else {
            dataObj[clmName] = selectedValue;
            if (String(dataObj[clmName]) === "0") {
                dataObj[clmName] = null;
            }
        }

        if (funcs.editDataFactory != null) {
            if (options.rowOptions?.blockInputCommit && !force) return;
            funcs.editDataFactory({ rowObj, dataObj, clmName });
            return;
        }

        if (rowObj.id == null) {
            dispatch(funcs["addData"](dataObj));
            dispatch(funcs["createData"](dataObj));
        } else {
            if (funcs["editData"] != null) {
                dispatch(funcs["editData"](dataObj));
            } else if (funcs["editDataLocal"] !== null) {
                funcs["editDataLocal"](options.otherProps.tableData, dataObj);
            }

            if (remoteSave) {
                if (funcs["patchData"] != null) {
                    dispatch(funcs["patchData"](dataObj));
                } else if (funcs["patchDataLocal"] != null) {
                    funcs["patchDataLocal"](options.otherProps.tableData, dataObj);
                }
            }
        }
    };

    const handleEdit = (clmName, selectedValue = null, remoteSave = true) => {
        stopCurrentCellEditing();
        commitChanges({ clmName, selectedValue, remoteSave });
    };

    return { handleEdit, commitChanges, stopCurrentCellEditing };
};

export { useCellEditing };

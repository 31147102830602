import { useSelector, useDispatch } from "react-redux";
import { setPanelSearchNumber, setPanelSearchValue } from "slices/ribbon";

import KsprInput from "components/UI/input/KsprInput";
import SearchIcon from "@mui/icons-material/Search";

import "./RibbonContent.css";
import { Grid, InputAdornment } from "@mui/material";

const RibbonPanel = ({ handleEnterPress }) => {
	const dispatch = useDispatch();

	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const clickedTableClmName = useSelector((state) => state.ribbon.clickedTableClmName);
	const clickedCurrentCell =
		clickedTableClmName &&
		clickedTableRow &&
		clickedTableRow[clickedTableClmName] != null &&
		typeof clickedTableRow[clickedTableClmName] != "object" &&
		String(clickedTableRow[clickedTableClmName]).replaceAll(/<.+?>/g, "");
	const panelSearchNumber = useSelector((state) => state.ribbon.panelSearchNumber);
	const panelFoundRowPositions = useSelector((state) => state.ribbon.panelFoundRowPositions);
	const panelFoundRowPositionIndex = useSelector((state) => state.ribbon.panelFoundRowPositionIndex);
	const panelSearchValue = useSelector((state) => state.ribbon.panelSearchValue);

	const getResultFound = () => {
		if (panelFoundRowPositions.length > 0) {
			let textLabel = `Найдено: ${panelFoundRowPositionIndex + 1} из ${panelFoundRowPositions.length}`;
			if (panelFoundRowPositionIndex > panelFoundRowPositions.length) {
				textLabel = `Найдено: 1 из ${panelFoundRowPositions.length}`;
			}
			return (
				<span
					style={{
						fontFamily: "Segoe UI",
						fontSize: "11px",
						marginRight: "5px",
					}}
				>
					{textLabel}
				</span>
			);
		}
	};

	return (
		<div className="ribbon-panel">
			<Grid px={"6px"} container spacing={1}>
				<Grid item xs={1}>
					<KsprInput
						placeholder="Перейти к N-позиции"
						size="small"
						value={panelSearchNumber}
						onChange={(e) => {
							if (!e.target.value.match(/^\d+\.?\d{0,1}$/) && e.target.value !== "") return;
							dispatch(setPanelSearchNumber(e.target.value));
						}}
					/>
				</Grid>
				<Grid item xs={9}>
					<KsprInput label="" size="small" value={clickedCurrentCell || ""} />
				</Grid>
				<Grid item xs={2}>
					<KsprInput
						placeholder="Поиск"
						size="small"
						value={panelSearchValue}
						onChange={(e) => dispatch(setPanelSearchValue(e.target.value))}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								handleEnterPress();
							}
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<>
										{getResultFound()}
										<SearchIcon />
									</>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default RibbonPanel;

export default function ObjectEstimateTableColumns() {
    return [
        {
            name: "num",
            title: "№ п/п",
            width: "65px",
            cellHorAlign: "center",
            headHorAlign: "left",
            rowSpan: "2",
            headHeight: "30px",
            marginLeft: "10px"
        },
        {
            name: "reason",
            title: "Обоснование",
            width: "260px",
            cellHorAlign: "left",
            headHorAlign: "left",
            rowSpan: "2",
            headHeight: "30px",
            marginLeft: "10px"
        },
        {
            name: "name",
            title: "Наименование локальных сметных расчетов",
            width: "auto",
            cellHorAlign: "left",
            headHorAlign: "left",
            rowSpan: "2",
            headHeight: "30px",
            marginLeft: "10px"
        },
        {
            name: "cost",
            title: "Сметная стоимость, тыс. руб",
            width: "500px",
            cellHorAlign: "left",
            headHorAlign: "center",
            headHeight: "30px",
            colSpan: "5",
            children: [
                {
                    name: "building",
                    title: "строительных работ",
                    width: "100px",
                    cellHorAlign: "right",
                    headHorAlign: "center",
                    headHeight: "50px"
                },
                {
                    name: "mounting",
                    title: "монтажных работ",
                    width: "100px",
                    cellHorAlign: "right",
                    headHorAlign: "center",
                    headHeight: "50px"
                },
                {
                    name: "equipment",
                    title: "оборудования",
                    width: "100px",
                    cellHorAlign: "right",
                    headHorAlign: "center",
                    headHeight: "50px"
                },
                {
                    name: "other",
                    title: "прочих затрат",
                    width: "100px",
                    cellHorAlign: "right",
                    headHorAlign: "center",
                    headHeight: "50px"
                },
                {
                    name: "total",
                    title: "всего",
                    width: "100px",
                    cellHorAlign: "right",
                    headHorAlign: "center",
                    headHeight: "50px"
                },
            ]
        }, {
            name: "check",
            title: "",
            width: "25px",
            cellHorAlign: "center",
            headHorAlign: "left",
            rowSpan: "2",
            headHeight: "25px",
            marginLeft: "10px"
        },
    ];
}
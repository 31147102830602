import ContextMenuCommon from "./ContextMenuCommon";
import ContextMenuSelect from "./ContextMenuSelect.jsx";
import ContextMenuCustom from "./ContextMenuCustom.jsx";
import ContextMenuSelectCustomOptions from "./ContextMenuSelectCustomOptions.jsx";


const ContextMenuElementWrapper = ({ elementSettings }) => {
    const { type } = elementSettings;

    switch (type) {
        case "common":
            return <ContextMenuCommon {...elementSettings} />;
        case "select":
            return <ContextMenuSelect {...elementSettings} />;
        case "select-custom-options":
            return <ContextMenuSelectCustomOptions {...elementSettings} />;
        case "custom":
            return <ContextMenuCustom {...elementSettings} />;
        default:
            return null;
    }
};

export default ContextMenuElementWrapper;
import { useState, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";

import Draggable from "react-draggable";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import KsprButton from "components/UI/buttons/KsprButton";
import KsprTextBoxWithSupLabels from "components/UI/textboxs/KsprTextBoxWithSupLabels";

import { setMonFetchStatus } from "slices/project_work";
import { getCurrentStrDate, parseDateFromStr, stringifyDayjs } from "core/date_utils";
import { setFoundRecommendedIndex, setSelectedDate } from "slices/monitor";

import { RECOMMENDED_INDEX_TEXT_BOX_PARAMS } from "./consts";

import apiCalcer from "api/calcer";

import "./RecommendedIndexModal.css";

const KsprDatePicker = lazy(() => import("components/UI/input/KsprDatePicker"));

const RecommendedIndexModal = (props) => {
	const dispatch = useDispatch();
	const selectedDate = useSelector((state) => state.monitor.selectedDate);
	const [searchDate, setSearchDate] = useState(
		selectedDate != null ? stringifyDayjs(selectedDate, "DD.MM.YYYY") : getCurrentStrDate().format("DD.MM.YYYY")
	);
	const [indexDisplayData, setIndexDisplayData] = useState(JSON.parse(JSON.stringify(RECOMMENDED_INDEX_TEXT_BOX_PARAMS)));
	const [foundIndexes, setFoundIndexes] = useState(null);
	const [useDirectSmrPeriod, setUseDirectSmrPeriod] = useState(false);
	const params = useSelector((state) => state.monitor.lastSelectedParams);

	const handleSearchIndexes = () => {
		const prom = apiCalcer.getRecommendedIndexes(params.pz, params.obj, params.inx, searchDate, String(useDirectSmrPeriod));
		dispatch(setMonFetchStatus("loading"));
		dispatch(setSelectedDate(parseDateFromStr(searchDate, "DD.MM.YYYY")));
		prom.then((response) => {
			setIndexDisplayData((prev) => prev.map((row) => updateIndexData(row, response.data)));
			setFoundIndexes(response.data);
			dispatch(setMonFetchStatus("success"));
		}).catch((err) => {
			console.error(err);
			dispatch(setMonFetchStatus("failed"));
		});
	};

	const updateIndexData = (row, respData) => {
		row.value = respData[row.key];
		if (row._children) {
			row._children.map((child) => {
				updateIndexData(child, respData);
				return child;
			});
		}
		return row;
	};

	const saveFoundIndex = () => {
		if (foundIndexes) dispatch(setFoundRecommendedIndex(foundIndexes));
		props.f_setVisible(false);
	};

	return (
		<Draggable handle=".RecommendedIndexModalHeader">
			<div className="RecommendedIndexModal">
				<div className="RecommendedIndexModalHeader">Подбор индексов по письмам</div>
				<div className="RecommendedIndexModalBody">
					<div className="RecommendedIndexModalParams">
						<Suspense>
							<KsprDatePicker
								label="Дата представления ПСД"
								changeCallback={setSearchDate}
								defaultDate={selectedDate}
								setCurrentDateAsDefault={true}
							/>
						</Suspense>
						<KsprButton title="Найти письма" label="Найти письма" variant="outlined" onClick={handleSearchIndexes} />
					</div>
					<div className="RecommendedIndexEzSmrCheckBox">
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={useDirectSmrPeriod}
										size="small"
										onChange={(event) => setUseDirectSmrPeriod(event.target.checked)}
										inputProps={{ "aria-label": "controlled" }}
									/>
								}
								label="Использовать период индексов ЭЗ / СМР"
							/>
						</FormGroup>
					</div>
					<div className="RecommendedIndexModalValues">
						{indexDisplayData.map((obj) => (
							<div key={obj.key} style={{ paddingTop: "10px" }}>
								<KsprTextBoxWithSupLabels obj={obj} />
							</div>
						))}
					</div>
				</div>
				<div className="RecommendedIndexModalFooter">
					<KsprButton title="Отмена" label="Отмена" onClick={() => props.f_setVisible(false)} />
					<KsprButton title="Применить" label="Применить" onClick={() => saveFoundIndex()} />
				</div>
			</div>
		</Draggable>
	);
};

export default RecommendedIndexModal;

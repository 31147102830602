import { useState, useEffect } from "react";

import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";

export default function HeaderFilter(props) {
	const [value, setValue] = useState("");
	const [className, setClassName] = useState("FilterInput WithAnimation");

	useEffect(() => {
		if (props?.filterValues) {
			if (props.filterValues[props.clmName] && String(props.filterValues[props.clmName]).length > 0) {
				setValue(props.filterValues[props.clmName]);
				setClassName("FilterInput NoAnimation");
			} else {
				setClassName("FilterInput WithAnimation");
			}
		}
	}, [props.filterValues]); // eslint-disable-line react-hooks/exhaustive-deps

	const getCloseFilter = () => {
		return (
			<div
				className="TableHeadCellFilter"
				onClick={() => {
					setClassName("FilterInput WithAnimation");
					props.updateFilterStatus(props.clmName, "open");
				}}
			>
				<SearchIcon />
			</div>
		);
	};

	const getOpenFilter = () => {
		return (
			<div className="TableHeadCellFilter">
				<input
					value={value}
					column={props.clmName}
					type="search"
					autoFocus
					className={className}
					placeholder="поиск..."
					//onInput={(e) =>
					onChange={(e) => {
						props.filterData(props.tableData, props.clmName, e.target.value);
						setValue(e.target.value);
					}}
				></input>
				<SearchOffIcon
					onClick={() => {
						props.updateFilterStatus(props.clmName, "close");
						props.filterData(props.tableData, props.clmName, "");
					}}
				></SearchOffIcon>
			</div>
		);
	};

	if (props.filterParams == null) return;

	if (props.filterParams[props.clmName] === "close") {
		return getCloseFilter();
	} else if (props.filterParams[props.clmName] === "open") {
		return getOpenFilter();
	}
}

import "./NoDataBox.css";
import "./NoProjectWelcome.css";

import nodata from "../imgs/welcome.jpg"

export default function NoProjectWelcome(props) {
    let textPrimary = "Добро пожаловать!";
    let textSecondary = "Для начала работы создайте свой первый проект.";

    if (props.textPrimary != null) textPrimary = props.textPrimary
    if (props.textSecondary != null) textSecondary = props.textSecondary

    return (
        <div className="NoDataBox">
            <div className="NoDataTopWelcome">
                <div className="NoDataImg">
                    <img src={nodata} alt=""></img>
                </div>
            </div>
            <div className="NotDataBottom">
                <div className="NotDataBottomLabel">{textPrimary}</div>
                <div className="NotDataBottomText">{textSecondary}</div>
            </div>
        </div>
    );
}
import AddBoxIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

export default function JustTableColumns({ changeChildrenVisible }) {
    return [
        {
            name: "file_name",
            title: "Наименование файла",
            width: "auto",
            cellHorAlign: "left",
            headHorAlign: "left",
            sort: true,
            filter: true,
            editable: false,
        },
        {
            name: "date_create",
            title: "Дата загрузки",
            width: "130px",
            cellHorAlign: "left",
            headHorAlign: "center",
            sort: true,
            filter: false,
            editable: false,
        },
        {
            name: "connection_count",
            title: "Привязанные ЦП",
            width: "150px",
            cellHorAlign: "center",
            headHorAlign: "center",
            sort: true,
            filter: false,
            editable: false,
            cellJsx: ({ rowObj }) => {
                const cellValue = rowObj.connection_count;
                let icon;
                if (rowObj.is_parent && cellValue > 0) {
                    if (rowObj.childs_visible) {
                        icon = <IndeterminateCheckBoxOutlinedIcon />;
                    } else {
                        icon = <AddBoxIcon />;
                    }
                }
                return (
                    <td
                        key={`cell-code-connection_count`}
                        className="TableCell"
                        clm="connection_count"
                        treecell="false"
                        title={cellValue}>
                        <div
                            title={cellValue}
                            className="TreeActionCell"
                            onClick={() => changeChildrenVisible({ clickedRow: rowObj })}
                            style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                            <span style={{ marginLeft: "2px" }}>{cellValue}</span>
                            {icon}
                        </div>
                    </td>
                );
            },
        },
    ];
}

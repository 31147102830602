import { createSlice } from "@reduxjs/toolkit";

export const booksSlice = createSlice({
	name: "books",
	initialState: {
		priceZones: [],
		units: [],
		expensesTypes: [],
		matchTypes: [],
		charsBook: [],
		charsEnumBook: [],
		somStatus: [],
		somClassifier: [],
	},
	reducers: {
		setPriceZones: (state, { payload }) => {
			state.priceZones = payload;
		},
		setUnits: (state, { payload }) => {
			state.units = payload;
		},
		setExpensesTypes: (state, { payload }) => {
			state.expensesTypes = payload;
		},
		setMatchTypes: (state, { payload }) => {
			state.matchTypes = payload;
		},
		setCharsBook: (state, { payload }) => {
			state.charsBook = payload;
		},
		setCharsEnumBook: (state, { payload }) => {
			state.charsEnumBook = payload;
		},
		setSomStatus: (state, { payload }) => {
			state.somStatus = payload;
		},
		setSomClassifier: (state, { payload }) => {
			state.somClassifier = payload;
		},
	},
});

export const { setPriceZones, setUnits, setExpensesTypes, setMatchTypes, setCharsBook, setCharsEnumBook, setSomStatus, setSomClassifier } =
	booksSlice.actions;
export default booksSlice.reducer;

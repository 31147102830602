import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
// import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

export const KatsPriceOfferSelectorColumns = ({ getOfferNeighbour, changePaperDetailsVisible }) => {
	return [
		{
			name: "ksr_code",
			title: "Код КСР",
			width: "100px",
			cellHorAlign: "left",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
			filter: false,
			cellJsx: ({ rowObj }) => {
				const cellValue = rowObj.ksr_code == null ? "" : rowObj.ksr_code.toString();
				return (
					<td
						key={`cell-ksr_code-${rowObj.id}`}
						className="TableCell"
						clm="code"
						treecell="false"
						onClick={() => changePaperDetailsVisible({ clickedRow: rowObj })}
						title={cellValue}
					>
						<div title={cellValue} className="TableCell" style={{ display: "flex", justifyContent: "space-between" }}>
							<span className="TableCell" style={{ marginLeft: "2px", flexBasic: "60%" }}>
								{cellValue}
							</span>
							<div>
								{rowObj.expert_valid === true ? <CheckCircleOutlinedIcon /> : <span style={{ paddingLeft: "17px" }}></span>}
								{rowObj.is_winner === true ? <EmojiEventsOutlinedIcon /> : <span style={{ paddingLeft: "17px" }}></span>}
							</div>
						</div>
					</td>
				);
			},
		},
		{
			name: "name",
			title: "Наименование строительного ресурса, затрат",
			width: "auto",
			cellHorAlign: "left",
			headHorAlign: "left",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
			filter: true,
			cellJsx: ({ rowObj }) => {
				const cellValue = rowObj.name == null ? "" : rowObj.name.toString();
				return (
					<td
						key={`cell-price_offer_name-${rowObj.id}`}
						className="TableCell"
						clm="name"
						treecell="false"
						onClick={() => changePaperDetailsVisible({ clickedRow: rowObj })}
						title={cellValue}
					>
						<div title={cellValue} className="TableCell" style={{ display: "flex", justifyContent: "space-between" }}>
							<span
								className="TableCell"
								style={{ marginLeft: "2px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
							>
								{cellValue}
							</span>
							{/* {rowObj.selected === true && (
								<AddCircleOutlineOutlinedIcon onClick={() => getOfferNeighbour({ offerId: rowObj.price_offer_id })} />
							)} */}
						</div>
					</td>
				);
			},
		},
		{
			name: "unit",
			title: "Ед.изм.",
			width: "42px",
			cellHorAlign: "center",
			headHorAlign: "center",
			headHeight: "12px",
			marginLeft: "10px",
			sort: false,
			filter: false,
		},
		{
			name: "estimate_without_vat",
			title: "СЦ без НДС",
			width: "110px",
			cellHorAlign: "right",
			headHorAlign: "right",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
			filter: false,
		},
		{
			name: "company_name",
			title: "Поставщик",
			width: "150px",
			cellHorAlign: "left",
			headHorAlign: "left",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
			filter: false,
		},
		{
			name: "store_place",
			title: "Нас.пункт склада",
			width: "150px",
			cellHorAlign: "left",
			headHorAlign: "left",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
			filter: false,
		},
		{
			name: "pricezone_name",
			title: "Ценовая зона",
			width: "150px",
			cellHorAlign: "left",
			headHorAlign: "left",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
			filter: false,
		},
		{
			name: "offer_date",
			title: "Дата",
			width: "75px",
			cellHorAlign: "left",
			headHorAlign: "left",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
			filter: false,
		},
		{
			name: "ratio_str",
			title: "%",
			width: "40px",
			cellHorAlign: "center",
			headHorAlign: "center",
			headHeight: "12px",
			marginLeft: "5px",
			sort: true,
			filter: false,
		},
	];
};

import { IconButton, Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";

import KsprInput from "components/UI/input/KsprInput";
import { moveNextSearch } from "core/fsnb_utils";
import "./KsrDrawerHeader.css";

const KsrDrawerHeader = ({ func, state }) => {
	let searchResult = "";
	if (state.searchFounds?.length) {
		if (state.searchFounds.length >= 1000) {
			searchResult = `Найдено: более ${state.searchFounds.length}`;
		} else {
			searchResult = `Найдено: ${state.searchFounds.length}`;
		}
	}

	return (
		<div className="KsrDrawerHeader">
			<div className="KsrDrawerHeaderLeft">
				<Tooltip title="Закрыть">
					<IconButton
						onClick={() => {
							func.setVisible(false);
							func.setInitDelayInMs();
						}}
					>
						<ExitToAppOutlinedIcon fontSize="small" />
					</IconButton>
				</Tooltip>
				<div className="KsrDrawerTitle">Классификатор строительных ресурсов</div>
			</div>

			<div className="KsrDrawerHeaderRight">
				<div className="KsrDrawerHeaderInput">
					<KsprInput
						label="Поиск"
						size="small"
						value={state.inputSearchValue}
						onChange={(e) => {
							func.setInputSearchValue((prev) => e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") func.setInputSearchValue((prev) => e.target.value);
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon fontSize="16px" />
								</InputAdornment>
							),
						}}
					/>
				</div>

				<div className="KsrDrawerHeaderResult">
					{state.searchFounds?.length > 0 && (
						<div className="SearchNavButtons">
							<ArrowDropDownOutlinedIcon
								onClick={(e) => {
									moveNextSearch("next", state.searchFounds, state.curSearchRow, func.setCurSearchRow);
								}}
							/>
							<ArrowDropUpOutlinedIcon
								onClick={(e) => {
									moveNextSearch("prev", state.searchFounds, state.curSearchRow, func.setCurSearchRow);
								}}
							/>
						</div>
					)}

					<div className="SearchResults">{searchResult}</div>
				</div>
			</div>
		</div>
	);
};

export default KsrDrawerHeader;

import { getArgOrCallback } from "core/args_wrapper";
import { getDefaultTextForSelect } from "../CellFormatter/CellSelect";

export const getCellStyle = (row, options, clm) => {
    let cellStyle = { width: "100%" };
    let cellDataStyle;
    if (row?._style?._cell) cellDataStyle = row._style._cell
    if (row.rowHeightMultiply != null) {
        const rowHeightMax = getTargetHeight(options) * row.rowHeightMultiply;
        cellStyle = {
            width: "100%",
            height: rowHeightMax,
            whiteSpace: "normal",
            display: "flex",
            alignItems: "center",
            wordBreak: "break-word",
            hyphens: "auto",
            // justifyContent: "center",
        };
        // временна заглушка
        if (row.model_name === "check_status" || row.expected != null) cellStyle.justifyContent = "left";
    }
    // if (clm.minWidth) cellStyle.minWidth = clm.minWidth;
    if (cellDataStyle) cellStyle = { ...cellStyle, ...cellDataStyle }
    return cellStyle;
};

export const getTargetHeight = (options) => {
    let _height = 22;
    if (options.targerRowHeight != null) _height = options.targerRowHeight;
    return _height;
};


export const getIsCellParams = ({ rowObj, clmName, props }) => {
    let isCellEditable = false;
    let isClmSelectable = false;
    let defaultSelectVal = "";

    for (const clm of props.columnsFlat) {
        if (clm["name"] === clmName) {
            if (clm.hasOwnProperty("editable")) {
                isCellEditable = getArgOrCallback(clm["editable"], { rowObj });
            }
            if (clm.hasOwnProperty("selecting")) {
                isClmSelectable = true;
                defaultSelectVal = getDefaultTextForSelect(clm, rowObj, props.rowOptions?.selecting);
            }
        }
    }

    return { isCellEditable, isClmSelectable, defaultSelectVal }
}

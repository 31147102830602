import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Stack } from "@mui/material";

import TableMain from "components/Table/TableMain";
import LabelNormWithUnder from "components/UI/LabelNormWithUnder";
import ObjectEstimateTableColumns from "./ObjectEstimateTableColumns";
import AlertLoading from "components/AlertLoading";
import EstmHeaderTitleButtons from "../components/EstmHeaderTitleButtons/EstmHeaderTitleButtons";
import HeaderVisibleButton from "components/HeaderVisibleButton";
import { ObjectEstmFooterSignatures } from "./components/ObjectEstmFooterSignatures/ObjectEstmFooterSignatures";

import { getObjectEstimateTree, changeObjEstmErrorsVisible, protectObjEstmFull, setObjFetchStatus, changeRowData } from "slices/object_estimate";
import { setReGetProtocol } from "slices/protocol";
import { setCurrentPage, setCurrentEstmDocId, setClickedTableRow, setClickedTableClmName, clearClickedTableCtrlRows } from "slices/ribbon";

import { pushToClickedRows, removeFromClickedRows, clearClickedRows } from "components/Footer/aggFuncs";

import "../TableOuterBox.css";
import "./ObjectEstimateViewer.css";

export const ObjectEstimateViewer = (props) => {
	const dispatch = useDispatch();
	const [fullVisible, setFullVisible] = useState(true);
	const columns_data = ObjectEstimateTableColumns();
	const headerData = useSelector((state) => state.objectEstimate.headerData);
	const rowsData = useSelector((state) => state.objectEstimate.tableData);
	const fetchStatus = useSelector((state) => state.objectEstimate.fetchStatus);
	const clickedTablePrevRow = useSelector((state) => state.ribbon.clickedTablePrevRow);
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const reGetProtocol = useSelector((state) => state.protocol.reGetProtocol);
	const clickedTableCtrlRows = useSelector((state) => state.ribbon.clickedTableCtrlRows);
	const { id } = useParams();
	const headBlock = useRef();
	const headScrollControllBlock = useRef();
	const headBlock1 = useRef();
	const headBlock3 = useRef();
	const maxHeight = "150px";

	useEffect(() => {
		dispatch(getObjectEstimateTree(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (reGetProtocol === true) {
			dispatch(getObjectEstimateTree(id));
			dispatch(setReGetProtocol(false));
		}
	}, [reGetProtocol]); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() => {
		dispatch(setCurrentPage("ObjectEstimateViewer"));
		dispatch(setCurrentEstmDocId(id));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		return () => dispatch(clearClickedTableCtrlRows());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleSetFullVisible = () => {
		const toggleMaxHeight = (block) => {
			if (block) {
				block.style.maxHeight = fullVisible ? 0 : maxHeight;
			}
		};

		[headBlock1?.current, headBlock3?.current].forEach(toggleMaxHeight);
		setFullVisible(!fullVisible);
	};

	useEffect(() => {
		if (clickedTablePrevRow) {
			dispatch(
				changeRowData({
					rowKey: clickedTablePrevRow.key,
					callback: (row) => (row.selected = false),
				})
			);
		}
		if (clickedTableRow) {
			dispatch(
				changeRowData({
					rowKey: clickedTableRow.key,
					callback: (row) => (row.selected = true),
				})
			);
		}
	}, [clickedTableRow]); // eslint-disable-line react-hooks/exhaustive-deps

	const objectEstmSelectData = (table, row, target, parentRow, event) => {
		const clmName = target?.attributes?.clm?.value;
		if (event?.ctrlKey) {
			if (clickedTableCtrlRows.filter((x) => x.key === row.key && x.pressedClm === clmName).length > 0) {
				removeFromClickedRows({ rowObj: row, pressedClm: clmName, changeRowData, dispatch });
			} else {
				pushToClickedRows({ rowObj: row, pressedClm: clmName, changeRowData, dispatch });
			}
		} else {
			clearClickedRows({ clickedTableCtrlRows, changeRowData, dispatch });
			dispatch(setClickedTableRow({ ...row, parentRow: parentRow }));
			dispatch(setClickedTableClmName(clmName));
		}
	};

	return (
		<div className="TableOuterBox" type="ObjectEstimate">
			<div className="TableOuterHead" ref={headBlock}>
				<div className="OuterHeadHidable" ref={headBlock1}>
					<div>
						<LabelNormWithUnder norm="Наименование стройки" under={headerData["main_name"]} />
						<LabelNormWithUnder norm="Наименование объекта капитального строительства" under={headerData["name"]} />
					</div>
				</div>
				<div className="TableOuterHeadMainTitle">
					<div className="HeadFlex">
						<div className="TitleLeft"></div>
						<div className="TitleCenter">
							<span>ОБЪЕКТНЫЙ СМЕТНЫЙ РАСЧЕТ (СМЕТА) № {headerData["num"]}</span>
							<HeaderVisibleButton fullVisible={fullVisible} f_handleVisible={handleSetFullVisible} />
						</div>
						<div className="TitleRight">
							<EstmHeaderTitleButtons data={headerData} id={id} estm_type="osr" f_protect={protectObjEstmFull} />
						</div>
					</div>
				</div>
				<div className="OuterHeadHidable" ref={headBlock3}>
					<div>
						<LabelNormWithUnder norm="Основание" under={headerData["reason"]} />
						<LabelNormWithUnder norm="Сметная стоимость" under={headerData["full_cost"] + " тыс.руб."} />
						<LabelNormWithUnder norm="Расчетный измеритель объекта капитального строительства" under={headerData["quantity_unit"]} />
						<LabelNormWithUnder
							norm="Показатель единичной стоимости на расчетный измеритель объекта капитального строительства"
							under={headerData["price_per_unit"] + " тыс.руб."}
						/>
						<div className="TableOuterHeadFlex" ref={headScrollControllBlock}>
							<LabelNormWithUnder norm="Составлен в текущем уровне цен" under={headerData["price_level_print"]} />
							<div className="TableOuterHeadPanel">
								<Stack direction="row" spacing={0}>
									<div className="TableOuterHeadPanelElement"></div>
								</Stack>
							</div>
						</div>
					</div>
				</div>
			</div>

			<TableMain
				rows={{ data: rowsData }}
				columns={columns_data}
				options={{
					selectable: true,
					navigate: null,
					scrollBlock: props.scrollBlock,
					headBlock: headBlock,
					addH: 200,
					displayScrollDelay: 30,
					headScrollControllBlock: headScrollControllBlock,
					tableType: "osr",
				}}
				func={{
					changeErrorsDetailsVisible: changeObjEstmErrorsVisible,
					selectDataLocal: objectEstmSelectData,
				}}
			></TableMain>
			<div className="TableHeaderBlankPlaceHolder"></div>
			{rowsData != null && rowsData.length ? <ObjectEstmFooterSignatures data={headerData} /> : <></>}

			<AlertLoading fetchStatus={fetchStatus} func={setObjFetchStatus} />
		</div>
	);
};

import { useState } from "react";
import Popover from "@mui/material/Popover";

const ContextMenuSelectCustomOptions = ({ label, callback, disabled, icon, hotBtns, getJsxOptions }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [style, setStyle] = useState({});

    const openCustomJsx = (event) => {
        if (callback) {
            callback();
        } else {
            setAnchorEl(event.currentTarget);
        }
        setStyle({ backgroundColor: "#ececec" });
    };

    const hideCustomJsx = () => {
        setAnchorEl(null);
        setStyle({ backgroundColor: "none" });
    };

    const open = Boolean(anchorEl);

    return (
        <div
            className={`context-menu-common ${disabled ? "disabled" : ""}`}
            style={style}
            onClick={(e) => openCustomJsx(e)}>
            {icon ? icon : <div className="context-menu-empty-item"></div>}
            {label}
            <span className="context-menu-hot-btns">{hotBtns}</span>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={hideCustomJsx}
                onClick={hideCustomJsx}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}>
                <div className="ribbon-select-options" onMouseLeave={(e) => hideCustomJsx(e)}>
                    {getJsxOptions()}
                </div>
            </Popover>
        </div>
    );
};

export default ContextMenuSelectCustomOptions;

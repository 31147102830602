import { apiBase } from './base';

const apiNewsFeed = {
    client: apiBase.get_client(),
    url: "/news",

    getNewsForUser() {
        return this.client.get(`${this.url}/for_user/`, apiBase.get_headers());
    },

    getUnreadNewsForUser() {
        return this.client.get(`${this.url}/for_user/unread/`, apiBase.get_headers());
    },

    readNews(id) {
        return this.client.get(`${this.url}/read/${id}/`, apiBase.get_headers());
    },

};

export default apiNewsFeed;

import { Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Tooltip, Button, IconButton } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import Badge from "@mui/material/Badge";

import QuestionBox from "components/QuestionBox/QuestionBox";

import { Logout } from "slices/auth";
import { clearTree } from "slices/project_work";
import { setQuestionVisible, setNewsFeedVisible } from "slices/global_visible";
import { getUserTasksAndRead, setUserTasksVisible } from "slices/user_adv";

import apiQuestion from "api/question";

import { getEnvVal } from "core/env.js";
import { downloadFile } from "core/request_utils";

import "./Header.css";
import logo from "../imgs/logo.png";

const NewsFeed = lazy(() => import("components/NewsFeed/NewsFeed"));

export default function Header() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userPermissions = useSelector((state) => state.auth.permissions);
	const questionVis = useSelector((state) => state.globalVis.question);
	const newsFeedVis = useSelector((state) => state.globalVis.newsFeed);
	const unreadTasks = useSelector((state) => state.userAdv.unreadTasks);

	const userName = localStorage.getItem("userFio");

	const handleLogout = () => {
		localStorage.clear();
		dispatch(Logout());
		// dispatch(setProjectsList([]))
		if (getEnvVal("App") === "sipcs") {
			navigate("/kpsr");
		} else {
			navigate("/");
		}
	};

	const handleMain = () => {
		if (userPermissions.is_guest) return;
		dispatch(clearTree());
		navigate("/");
	};

	const getUserInstruction = () => {
		const prom = apiQuestion.getUserBaseInstruction();
		prom.then((response) => {
			downloadFile(response);
		}).catch((err) => {
			console.error(err);
		});
	};

	let headerLabel;
	if (getEnvVal("App") === "sipcs") {
		headerLabel = "Специальный портал ценообразования в строительстве";
	} else if (userPermissions?.is_baseuser) {
		headerLabel = "Сервис комплексной проверки сметных расчетов";
	} else if (userPermissions?.is_guest) {
		headerLabel = "Сервис комплексной проверки сметных расчетов";
	}

	return (
		<div className="Header">
			<div className="HeaderLeft" onClick={handleMain}>
				<div className="HeaderImg">{getEnvVal("App") !== "sipcs" && <img src={logo} alt=""></img>}</div>
				<div className="HeaderTitle">{headerLabel}</div>
			</div>

			<div className="HeaderRight">
				{userPermissions?.is_guest === true && (
					<div className="HeaderRightItem Info" style={{ cursor: "pointer" }}>
						<Button
							variant="outlined"
							onClick={() => window.open("https://gge.ru/services/servis-kompleksnoy-proverki-smetnykh-raschetov/")}
							size="small"
						>
							<span className="HeaderRightItemText">Получить полный доступ</span>
						</Button>
					</div>
				)}
				<div className="HeaderRightItem">
					<Button variant="text" startIcon={<ContactSupportOutlinedIcon />} onClick={() => dispatch(setQuestionVisible(true))} size="small">
						<span className="HeaderRightItemText">ЗАДАТЬ ВОПРОС</span>
					</Button>
				</div>
				<div className="HeaderRightItem">
					<Button variant="text" startIcon={<ArticleOutlinedIcon />} onClick={getUserInstruction} size="small">
						<span className="HeaderRightItemText">ИНСТРУКЦИЯ</span>
					</Button>
					{!userPermissions.is_guest && (
						<Button variant="text" startIcon={<NewspaperIcon />} onClick={() => dispatch(setNewsFeedVisible(true))} size="small">
							<span className="HeaderRightItemText">НОВОСТИ</span>
						</Button>
					)}
					{!userPermissions?.is_guest && (
						<Button
							variant="text"
							startIcon={
								<Badge badgeContent={unreadTasks} color="success">
									<NotificationsActiveOutlinedIcon />
								</Badge>
							}
							onClick={() => {
								dispatch(setUserTasksVisible(true));
								dispatch(getUserTasksAndRead());
							}}
							size="small"
						>
							<span className="HeaderRightItemText">ЗАДАЧИ</span>
						</Button>
					)}

					{!userPermissions?.is_guest && <div className="HeaderUserName">{userName}</div>}

					{!userPermissions?.is_guest && (
						<Tooltip title="Выход">
							<IconButton aria-label="Выход" onClick={handleLogout}>
								<LogoutOutlinedIcon />
							</IconButton>
						</Tooltip>
					)}
				</div>
			</div>
			{questionVis && <QuestionBox />}
			{newsFeedVis && (
				<Suspense fallback={""}>
					<NewsFeed />
				</Suspense>
			)}
		</div>
	);
}


import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';

const CustomNumCell = ({ rowObj, clmName, clickedTableClmName, hooks }) => {
    const cellVal = rowObj[clmName] == null ? "" : rowObj[clmName].toString();
    let cellIcon;
    let cellRBlank;
    let cellText = <div className="NumCellText" title={cellVal}>{cellVal}</div>;

    if (rowObj.is_void === true) {
        cellIcon = <div className="NumCellIcon" title={rowObj.void_reason}
            style={{ flexBasis: "20%", position: "relative", left: "-7px" }}>
            <ErrorOutlineOutlinedIcon />
        </div>
        cellText = <div className="NumCellText" title={cellVal}
            style={{ flexBasis: "60%", display: "flex", justifyContent: "center", position: "relative", "left": "-2px" }} >
            {cellVal}</div>
        cellRBlank = <div title={cellVal} style={{ flexBasis: "20%" }} ></div>
    } else if (rowObj.allow_calc === false) {
        cellIcon = <div className="NumCellIcon" title="Не учитывается в расчете"
            style={{ flexBasis: "20%", position: "relative", left: "-7px" }}>
            <CancelPresentationOutlinedIcon />
        </div>
        cellText = <div className="NumCellText" title="Не учитывается в расчете"
            style={{
                flexBasis: "60%", display: "flex", justifyContent: "center",
                position: "relative", "left": "-2px", ...rowObj?._style?._cell
            }} >
            {cellVal}</div>
        cellRBlank = <div title="Не учитывается в расчете" style={{ flexBasis: "20%" }} ></div>
    }
    return <td
        key={`cell-${clmName}-${rowObj.key}`}
        className="TableCell"
        clm={clmName}
        treecell={"false"}
        is_clicked_clm_name={String(clmName === clickedTableClmName)}
        title={cellVal}
        onClick={() => {
            if (rowObj.model_name === "LocalEstimatesResVoid") {
                hooks.setModalContent.positionSettings(rowObj);
                hooks.setModalVisible.positionSettings(true);
            }
        }}>
        <div className="NumCellWithIcon">
            {cellIcon}
            {cellText}
            {cellRBlank}
        </div>
    </td>
}

export { CustomNumCell }
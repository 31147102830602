export default function FsemTableColumns2022() {
    return [
        {
            name: 'code',
            title: 'Код',
            width: '80px',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'name',
            title: 'Наименование',
            width: 'auto',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'measure_unit',
            title: 'Ед. изм',
            width: '50px',
            cellHorAlign: 'left',
            headHorAlign: 'left',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'cost',
            title: 'Смет. стоим. без ОТМ',
            width: '65px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'salary_mach',
            title: 'ОТМ',
            width: '55px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'machinist_category',
            title: 'Средний разряд',
            width: '45px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'driver_code',
            title: 'Код среднего разряда',
            width: '75px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'labour_mach',
            title: 'Трудозатраты',
            width: '70px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'electricity',
            title: 'Затраты на э/э, кВт/ч',
            width: '70px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'electricity_cost',
            title: 'Затраты на э/э, руб.',
            width: '70px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        },
        {
            name: 'with_relocation',
            title: 'Перебазировка',
            width: '85px',
            cellHorAlign: 'center',
            headHorAlign: 'center',
            sort: false,
            filter: false,
            editable: false,
        }
    ];
}
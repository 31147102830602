import { Tooltip } from "@mui/material";

const RibbonElementCommon = ({ elementSettings }) => {
    const { name, callback, styles, isDisabled, icon, size, nameVisible, active } = elementSettings;

    return (
        <Tooltip title={nameVisible && name}>
            <span className={active && "ribbon-element-common--active"}>
                <button
                    className={
                        size === "large" ? "ribbon-element-common ribbon-element-common-large" : "ribbon-element-common"
                    }
                    style={styles}
                    onClick={callback}
                    disabled={isDisabled}
                >
                    {icon}
                    {!nameVisible && name}
                </button>
            </span>
        </Tooltip>
    );
};

export default RibbonElementCommon;

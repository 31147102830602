import RibbonPanelMoveToNumFactory from "./RibbonPanelMoveToNumFactory";
import RibbonPaneSearchInputFactory from "./RibbonPaneSearchInputFactory";
import RibbonPanelMoveToIndexFactory from "./RibbonPanelMoveToIndexFactory";

const RibbonPanelActionFactory = ({ action, currentPage, arg, tableData, updateTableData, scrollBlock, dispatch, panelSearchPrevKey }) => {
	if (action === "MOVE_TO_NUMBER") {
		RibbonPanelMoveToNumFactory({ currentPage, arg, tableData, updateTableData, scrollBlock, dispatch, panelSearchPrevKey });
	} else if (action === "SEARCH_INPUT") {
		RibbonPaneSearchInputFactory({ currentPage, arg, tableData, updateTableData, scrollBlock, dispatch, panelSearchPrevKey });
	} else if (action === "MOVE_TO_INDEX") {
		RibbonPanelMoveToIndexFactory({ currentPage, arg, scrollBlock });
	}
};

export default RibbonPanelActionFactory;

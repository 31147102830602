import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import KsprButton from "components/UI/buttons/KsprButton";

export default function AdvSearchBoxOptsFlat({ optionValues, curSelected, setCurSelected, handleSelectClick }) {
	const handleChange = (row) => {
		setCurSelected((prev) => {
			const inArray = prev.find((x) => x.id === row.id);
			if (inArray == null) {
				return [...prev, row];
			} else {
				return prev.filter((x) => x.id !== row.id);
			}
		});
	};

	return (
		<>
			{optionValues.map((opt) => (
				<MenuItem
					key={opt.id}
					title={opt.name}
					onClick={(e) => handleChange(opt)}
					selected={curSelected.filter((x) => x.id === opt.id).length === 1}
				>
					<Checkbox checked={curSelected.filter((x) => x.id === opt.id).length === 1}></Checkbox>
					<ListItemText primary={opt.name} />
				</MenuItem>
			))}

			<KsprButton sx={{ ml: "auto" }} variant="text" label="сохранить" onClick={handleSelectClick} />
		</>
	);
}

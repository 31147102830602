import React from "react";

import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";

import Settings from "@mui/icons-material/Settings";

import AdvSearchBoxOptsFlat from "./AdvSearchBoxOptsFlat";
import AdvSearchBoxOptsTree from "./AdvSearchBoxOptsTree";

export default function AdvSearchBoxTextBox({
    label,
    optionValues,
    boxType,
    curSelected,
    setCurSelected,
    isError,
    setChangeScopeCnt,
}) {
    //const [curSelected, setCurSelected] = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleSelectClick = () => {
        setOpen(!open);
    };

    const renderSelValue = (selected) => {
        let chipLst = [];
        for (let x of selected) {
            if (x != null) {
                chipLst.push(<Chip key={x.id} label={x.name} />);
            }
        }
        return chipLst;
    };

    return (
        <TextField
            sx={{ "& legend": { fontSize: "9px" } }}
            label={label}
            size="small"
            error={isError}
            select
            SelectProps={{
                open: open,
                onClose: handleSelectClick,
                onOpen: handleSelectClick,
                multiple: true,
                value: curSelected,
                renderValue: renderSelValue,
                IconComponent: Settings,
            }}>
            {boxType === "tree" ? (
                <AdvSearchBoxOptsTree
                    optionValues={optionValues}
                    curSelected={curSelected}
                    setCurSelected={setCurSelected}
                    handleSelectClick={handleSelectClick}
                    setChangeScopeCnt={setChangeScopeCnt}
                />
            ) : (
                <AdvSearchBoxOptsFlat
                    optionValues={optionValues}
                    curSelected={curSelected}
                    setCurSelected={setCurSelected}
                    handleSelectClick={handleSelectClick}
                />
            )}
        </TextField>
    );
}

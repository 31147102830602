import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import DoNotDisturbOutlinedIcon from "@mui/icons-material/DoNotDisturbOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";

import { setCurrentPage, setClickedTableRow } from "slices/ribbon";
import { setFetchStatus, setFetchStatusText } from "slices/local_estimate_editor";

import { useEstimateManager } from "pages/EstimateEditor/hooks/useEstimateManager";
import { useSectionEditor } from "pages/EstimateEditor/hooks/useSectionEditor";
import { useItemEditor } from "pages/EstimateEditor/hooks/useItemEditor";

import { FsnbRibbonFilters } from "./components/FsnbRibbonFilters";

const FsnbRibbonSettings = ({
	fsnbVersArr,
	selectedFsnbVers,
	setSelectedFsnbVers,
	attrsOpts,
	selectedSearchOptions,
	setSelectedSearchOptions,
	inputSearchValue,
	fetchStatus,
	setInputSearchValue,
	moveNextSearch,
	searchFounds,
	setFsnbBodyContent,
	setSuperSearchView,
	tabValue,
	clickedTableRow,
	lowerCallbacks,
	superSearchView,
	history,
	windowWidth,
	setBlackListVisible,
	addToBlackList,
	searchObjScope,
	setSearchObjScope,
	selectedTreeData,
	searchMode,
	setSearchMode,
	setFilterTrigger,
	// snbTreeDrawerVisible,
	// setSnbTreeDrawerVisible,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const navigateFromEditor = useSelector((state) => state.ribbon.navigateFromEditor);
	const currentEstmDocId = useSelector((state) => state.ribbon.currentEstmDocId);
	const currentProjectID = useSelector((state) => state.ribbon.currentProjectID);
	const editorEstmDocClickedSectionId = useSelector((state) => state.ribbon.editorEstmDocClickedSectionId);
	const editorClickedRowBeforeNav = useSelector((state) => state.localEstimateEditor.clickedRowBeforeNav);
	const userPermissions = useSelector((state) => state.auth.permissions);

	const sectionEditor = useSectionEditor({
		estmId: currentEstmDocId,
		_setFetchStatus: setFetchStatus,
		scrollBlock: {},
	});
	const itemEditor = useItemEditor({
		estmId: currentEstmDocId,
		_setFetchStatus: setFetchStatus,
		_setFetchStatusText: setFetchStatusText,
		scrollBlock: {},
	});
	const estmManager = useEstimateManager({
		estmId: currentEstmDocId,
		_setFetchStatus: setFetchStatus,
		_setFetchStatusText: setFetchStatusText,
		hooks: { sectionEditor, itemEditor, setSettingsVisible: () => {} },
	});

	const getAddToBlackListDisabled = () => {
		if (!userPermissions.is_black_list_admin) return true;
		if (clickedTableRow?.model_name === "FsnbMach" && clickedTableRow?.level === 3) {
			return false;
		} else if (clickedTableRow?.model_name === "FsnbMat" && clickedTableRow?.level === 4) {
			return false;
		} else if (clickedTableRow?.model_name === "FsnbNorm") {
			return false;
		}
		return true;
	};

	return [
		{
			name: "Режимы просмотра",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "вид",
							icon: <AddBoxOutlinedIcon />,
							size: "medium",
							type: "radio",
							isDisabled: superSearchView || ["coef", "nrsp", "pg", "ksr"].includes(tabValue) || searchMode === "flat",
							radioArray: [
								{
									name: "Сметные нормативы",
									currentPage: "table",
									callback: () => {
										setFsnbBodyContent("table");
										dispatch(setCurrentPage("table"));
									},
								},
								{
									name: "Техническая часть",
									currentPage: "pdf",
									callback: () => {
										setFsnbBodyContent("pdf");
										dispatch(setCurrentPage("pdf"));
									},
								},
							],
							callback: () => {},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Фильтр данных",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "",
							icon: <CalculateOutlinedIcon />,
							size: "large",
							type: "custom",
							customJsx: FsnbRibbonFilters({
								fsnbVersArr,
								selectedFsnbVers,
								setSelectedFsnbVers,
								attrsOpts,
								selectedSearchOptions,
								setSelectedSearchOptions,
								inputSearchValue,
								fetchStatus,
								setInputSearchValue,
								moveNextSearch,
								searchFounds,
								tabValue,
								superSearchView,
								searchObjScope,
								setSearchObjScope,
								selectedTreeData,
								searchMode,
								setSearchMode,
								setFilterTrigger,
								// snbTreeDrawerVisible,
								// setSnbTreeDrawerVisible,
							}),
							callback: () => {},
							styles: {
								width: "980px",
								pt: "7px",
							},
						},
					],
				},
			],
		},
		{
			name: "Поиск по БД",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Поиск по всей базе",
							icon: <ManageSearchOutlinedIcon />,
							size: "large",
							type: "common",
							active: superSearchView,
							callback: () => {
								if (!superSearchView) {
									navigate("/fsnb/search/");
									setSuperSearchView(true);
								} else {
									setSuperSearchView(false);
									if (history.length === 1) {
										navigate("/fsnb/mat/");
									} else {
										navigate(-1);
									}
								}
							},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Редактор",
			styles: {},
			isVisible: navigateFromEditor === "add",
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Добавить в смету",
							icon: <PlaylistAddOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled:
								!["FsnbNorm", "FsnbMat", "FsnbMach"].includes(clickedTableRow?.model_name) || clickedTableRow?.row_type === "group",
							callback: () => {
								const rowObj = { model_name: "LocalEstimatesItems", section_id: editorEstmDocClickedSectionId };
								const dataObj = { code: `${clickedTableRow.prefix || ""}${clickedTableRow.code}` };
								const clmName = "";
								estmManager.editorFactory({ rowObj, dataObj, clmName, thenAlert: true });
							},
							styles: {},
						},
					],
				},
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Добавить и вернуться",
							icon: <PlaylistAddCheckOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled:
								!["FsnbNorm", "FsnbMat", "FsnbMach"].includes(clickedTableRow?.model_name) || clickedTableRow?.row_type === "group",
							callback: () => {
								const rowObj = { model_name: "LocalEstimatesItems", section_id: editorEstmDocClickedSectionId };
								const dataObj = { code: `${clickedTableRow.prefix || ""}${clickedTableRow.code}` };
								const clmName = "";
								estmManager.editorFactory({
									rowObj,
									dataObj,
									clmName,
									thenNavigate: {
										path: `/project/${currentProjectID}/editor/lsr/${currentEstmDocId}/`,
									},
									thenScroll: true,
								});
							},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Редактор",
			styles: {},
			isVisible: navigateFromEditor === "abs",
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Уточнить ресурс",
							icon: <PlaylistAddOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: !["FsnbMat"].includes(clickedTableRow?.model_name) || clickedTableRow?.row_type === "group",
							callback: () => {
								estmManager.hooks.itemEditor.replaceAbsResource({
									sectionId: editorEstmDocClickedSectionId,
									itemId: editorClickedRowBeforeNav?.item_id,
									resId: editorClickedRowBeforeNav?.id,
									newResCode: clickedTableRow.code,
									thenAlert: true,
								});
							},
							styles: {},
						},
					],
				},
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Уточнить и вернуться",
							icon: <PlaylistAddCheckOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: !["FsnbMat"].includes(clickedTableRow?.model_name) || clickedTableRow?.row_type === "group",
							callback: () => {
								estmManager.hooks.itemEditor.replaceAbsResource({
									sectionId: editorEstmDocClickedSectionId,
									itemId: editorClickedRowBeforeNav?.item_id,
									resId: editorClickedRowBeforeNav?.id,
									newResCode: clickedTableRow.code,
									thenNavigate: {
										path: `/project/${currentProjectID}/editor/lsr/${currentEstmDocId}/`,
									},
									thenScroll: true,
								});
							},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Справка",
			styles: {},
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Состав нормы",
							nameVisible: windowWidth < 1400,
							icon: <FileOpenOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							isDisabled:
								clickedTableRow == null ||
								tabValue !== "norm" ||
								clickedTableRow?.norm_content == null ||
								clickedTableRow?.norm_content?.length < 1,
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "Открыть",
									value: "print1",
									isDisabled: clickedTableRow?.resourcesVisibility === true,
									callback: () => {
										lowerCallbacks.current["changeNormContentVisible"](clickedTableRow, "resources");
										dispatch(setClickedTableRow({ ...clickedTableRow, resourcesVisibility: true }));
									},
								},
								{
									name: "Свернуть",
									value: "print2",
									isDisabled: clickedTableRow?.resourcesVisibility === false,
									callback: () => {
										lowerCallbacks.current["changeNormContentVisible"](clickedTableRow, "resources");
										dispatch(setClickedTableRow({ ...clickedTableRow, resourcesVisibility: false }));
									},
								},
							],
						},
						{
							name: "Состав работ",
							nameVisible: windowWidth < 1400,
							icon: <FileOpenOutlinedIcon />,
							size: "MEDIUM",
							type: "select",
							isDisabled:
								clickedTableRow == null ||
								tabValue !== "norm" ||
								clickedTableRow?.norm_resource == null ||
								clickedTableRow?.norm_resource?.length < 1,
							callback: () => {},
							styles: {},
							_children: [
								{
									name: "Открыть",
									value: "print1",
									isDisabled: clickedTableRow?.contentVisibility === true,
									callback: () => {
										lowerCallbacks.current["changeNormContentVisible"](clickedTableRow, "content");
										dispatch(setClickedTableRow({ ...clickedTableRow, contentVisibility: true }));
									},
								},
								{
									name: "Свернуть",
									value: "print2",
									isDisabled: clickedTableRow?.contentVisibility === false,
									callback: () => {
										lowerCallbacks.current["changeNormContentVisible"](clickedTableRow, "content");
										dispatch(setClickedTableRow({ ...clickedTableRow, contentVisibility: false }));
									},
								},
							],
						},
					],
				},

				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "НР и СП",
							nameVisible: windowWidth < 1400,
							icon: <InfoOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled: clickedTableRow === null || tabValue !== "norm" || clickedTableRow?.cost_has_coef !== true,
							callback: () => {
								lowerCallbacks.current["showCoefs"](clickedTableRow);
							},
							styles: {},
						},
						{
							name: "В составе норм",
							nameVisible: windowWidth < 1400,
							icon: <FactCheckOutlinedIcon />,
							size: "MEDIUM",
							type: "common",
							isDisabled:
								clickedTableRow === null ||
								clickedTableRow?.model_name_box === "FsnbPartNorm" ||
								["norm", "coef", "nrsp", "pg", "ksr"].includes(tabValue),
							callback: () => {
								lowerCallbacks.current["showPartNorm"](clickedTableRow);
							},
							styles: {},
						},
					],
				},
			],
		},
		{
			name: "Настройка исключений",
			styles: {},
			isVisible: userPermissions.is_user_org_has_black_list,
			_children: [
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Исключить из рассмотрения",
							icon: <DoNotDisturbOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: getAddToBlackListDisabled(),
							callback: () => addToBlackList(),
							styles: {},
						},
					],
				},
				{
					name: "Subgroup 1",
					orientation: "vertical",
					styles: {},
					_children: [
						{
							name: "Список исключений",
							icon: <ListOutlinedIcon />,
							size: "large",
							type: "common",
							isDisabled: !userPermissions.is_user_org_has_black_list,
							callback: () => setBlackListVisible(true),
							styles: {},
						},
					],
				},
			],
		},
	];
};

export default FsnbRibbonSettings;

import { createSlice } from "@reduxjs/toolkit";

export const contextMenu = createSlice({
    name: "contextMenu",
    initialState: {
        isContextMenuOpen: false,
        contextMenuPosition: { top: 0, left: 0 },
        contextMenuObj: false,
    },
    reducers: {
        setIsContextMenuOpen: (state, { payload }) => {
            state.isContextMenuOpen = payload;
        },
        setContextMenuPosition: (state, { payload }) => {
            const { event } = payload;
            state.contextMenuPosition = { top: event.clientY, left: event.clientX };
        },
        setContextMenuObj: (state, { payload }) => {
            state.contextMenuObj = payload;
        },
    },
});

export const { setIsContextMenuOpen, setContextMenuPosition, setContextMenuObj } = contextMenu.actions;

export default contextMenu.reducer;

import React from "react";

export default function CellWIthColorPart({ value }) {
    const notColorPart = value.slice(0, value.indexOf("<color"));
    const colorPart = value.slice(value.indexOf("_clr_end_>") + 10, value.indexOf("</>"));
    const color = value.slice(value.indexOf("_clr_start_") + 11, value.indexOf("_clr_end_"));
    return (
        <span>
            {notColorPart}
            <span style={{ color: color }}>{colorPart}</span>
        </span>
    );
}

import { apiBase } from "./base";

const apiAuth = {
	client: apiBase.get_client(),
	url: "/token/",

	getToken(data) {
		return this.client.post(this.url, data);
	},

	getTokenAsEcpe(data) {
		return this.client.post("/integration/ecpe/token/", data);
	},

	getTokenAsAis(data) {
		return this.client.post("/integration/ais/token/", data);
	},

	getTokenAsSipCs(data) {
		return this.client.post("/integration/sipcs/token/", data);
	},

	getUserPermission() {
		return this.client.get("/check_user_permissions/", apiBase.get_headers());
	},

	authAsGuest({ guestUuid }) {
		return this.client.get("/auth_as_guest/" + guestUuid + "/");
	},

	getPersenalAgreement(instanceName) {
		return this.client.get("/persenal_agreement/" + instanceName + "/", apiBase.get_headers());
	},
};

export default apiAuth;

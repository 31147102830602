import RibbonElementCommon from './RibbonElementCommon.jsx';
import RibbonElementSelect from './RibbonElementSelect.jsx';
import RibbonElementCustom from './RibbonElementCustom.jsx';
import RibbonElementRadio from './RibbonElementRadio.jsx';
import RibbonElementSeparateSelect from './RibbonElementSeparateSelect.jsx';
import RibbonElementSelectCustomOptions from './RibbonElementSelectCustomOptions.jsx';


const RibbonElementWrapper = ({ elementSettings }) => {
    const { type } = elementSettings;

    if (type === 'common') {
        return <RibbonElementCommon elementSettings={elementSettings} />;
    } else if (type === 'select') {
        return <RibbonElementSelect elementSettings={elementSettings} />;
    } else if (type === 'custom') {
        return <RibbonElementCustom elementSettings={elementSettings} />;
    } else if (type === 'radio') {
        return <RibbonElementRadio elementSettings={elementSettings} />;
    } else if (type === 'separate-select') {
        return <RibbonElementSeparateSelect elementSettings={elementSettings} />;
    } else if (type === 'select-custom-options') {
        return <RibbonElementSelectCustomOptions elementSettings={elementSettings} />;
    }

    return null;
};

export default RibbonElementWrapper;
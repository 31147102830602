import React, { useRef, useState, useEffect } from 'react';
import Draggable from "react-draggable";
import { useSelector, useDispatch } from "react-redux";

import KsprButton from "components/UI/buttons/KsprButton";
import PartNormColumns from '../TableSetting/PartNormColumns.js';
import AlertLoading from "components/AlertLoading";
import TableMain from "components/Table/TableMain";
import { changeNestedArrKeyValueOnCondition, arrProxy, copyArr } from "core/iterable_utils";
import { setClickedTableRow } from "slices/ribbon";

import apiFsnb from 'api/fsnb';

import "./FsnbPartNorm.css";

export default function FsnbPartNorm(props) {
    const dispatch = useDispatch();
    const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
    const [data, setData] = useState([]);
    const [allCount, setAllCount] = useState(0);
    const scrollBlock = useRef(null);
    const [fetchStatus, setFetchStatus] = useState(null);
    const [fetchStatusText, setFetchStatusText] = useState("");

    const columnsData = PartNormColumns();

    useEffect(() => {

        let count = data.length + 100;

        setFetchStatus("loading");
        const prom = apiFsnb.getPartNorm(props.vers.id, props.row.code, count);
        prom.then((responce) => {
            setData(responce.data.data);
            setAllCount(responce.data.meta.data_len);
            setFetchStatus("success");
        }).catch(error => {
            console.error(error)
            setFetchStatus("failed");
            setFetchStatusText(error);
        });

    }, [props.vers, props.row.code]) // eslint-disable-line react-hooks/exhaustive-deps

    let boxWidth = "90%";
    if (props.width != null) boxWidth = props.width

    const localFsnbSelectData = (table, row, target, parentRow) => {
        let newTableData = copyArr(data);
        if (clickedTableRow) {
            newTableData = changeNestedArrKeyValueOnCondition({
                arr: newTableData,
                keySearch: "id",
                valSearch: clickedTableRow.id,
                keyChange: "selected",
                valChange: false,
            });
        }
        newTableData = changeNestedArrKeyValueOnCondition({
            arr: newTableData,
            keySearch: "id",
            valSearch: row.id,
            keyChange: "selected",
            valChange: true,
        });
        setData(newTableData);
        dispatch(setClickedTableRow({ ...row, parentRow: parentRow }));
    };

    const changeNormContentVisible = (clickedRow, contentType) => {
        setData((prev) => [
            ...prev.map((row) => {
                if (row.id === clickedRow.id) {
                    if (contentType === "content") {
                        row.contentVisibility = !row.contentVisibility;
                    } else if (contentType === "resources") {
                        row.resourcesVisibility = !row.resourcesVisibility;
                    }

                    if (row.contentVisibility && row.resourcesVisibility) {
                        row._children = [...arrProxy(row.norm_content), ...arrProxy(row.norm_resource)];
                    } else if (row.contentVisibility && !row.resourcesVisibility) {
                        row._children = [...arrProxy(row.norm_content)];
                    } else if (!row.contentVisibility && row.resourcesVisibility) {
                        row._children = [...arrProxy(row.norm_resource)];
                    } else if (!row.contentVisibility && !row.resourcesVisibility) {
                        row._children = [];
                    }
                }
                return row;
            }),
        ]);
    };

    const getMainTable = () => {
        if (data.length) {
            return (
                <TableMain
                    rows={{ data: data }}
                    columns={columnsData}
                    options={{
                        selectable: true,
                        scrollBlock: scrollBlock,
                        displayScrollDelay: 20,
                        funcCall: "local",
                    }}
                    func={{
                        selectDataLocal: localFsnbSelectData,
                        changeNormContentVisible: changeNormContentVisible,
                    }}></TableMain>
            )
        }
    };

    const handleScroll = () => {

        if (fetchStatus === "loading") {
            return;
        }

        const { scrollTop, clientHeight, scrollHeight } = scrollBlock.current;
        const diff = Math.abs(scrollHeight - scrollTop - clientHeight);
        if (diff < 100 && data.length < allCount) {
            let count = data.length + 100;
            setFetchStatus("loading");
            const prom = apiFsnb.getPartNorm(props.vers.id, props.row.code, count);
            prom.then((responce) => {
                setData(responce.data.data);
                setFetchStatus("success");
            }).catch(error => {
                console.error(error)
                setFetchStatus("failed");
                setFetchStatusText(error);
            });
        }
    };

    return (
        <Draggable handle=".FsnbPartNormBoxHeaderLabel">
            <div className="FsnbPartNormBox" style={{ width: boxWidth }} >
                <div className="FsnbPartNormBoxHeader">
                    <div className="FsnbPartNormBoxHeaderLabel">
                        <div> {props.name}</div>
                        <div> Количество: {allCount}</div>
                    </div>
                </div>
                <div className="FsnbCoefBoxBody" >
                    <div
                        onScroll={handleScroll}
                        className="FsnbBodyRight"
                        table_type="norm"
                        ref={scrollBlock}>
                        {getMainTable()}
                    </div>
                </div>
                <div className="FsnbPartNormBoxFooter">
                    <KsprButton variant="text" label="Закрыть" onClick={props.f_closeCoefBox} />
                </div>
                <AlertLoading
                    fetchStatus={fetchStatus}
                    fetchStatusText={fetchStatusText}
                    func={setFetchStatus}
                />
            </ div>
        </Draggable >
    )
}

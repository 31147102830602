import { useState, useLayoutEffect, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoProjectWelcome from "components/NoProjectWelcome";
import { DragDropContext } from "react-beautiful-dnd";
import {
	addProject,
	editProject,
	createProject,
	createProjectPack,
	patchProject,
	patchProjectPack,
	setTableViewParams,
	setTableData,
	setLastSelected,
} from "slices/projects";
import { setClickedTableRow } from "slices/ribbon";
import ProjectTableColumns from "./ProjectTableColumns";

import Table from "components/Table/Table";

import apiProjects from "api/projects";

import { searchNestedIterableItem } from "core/iterable_utils";

import "./ProjectsTable.css";

export default function ProjectsTable(props) {
	const dispatch = useDispatch();
	const rowsData = useSelector((state) => state.projects.table);
	const tableFiltering = useSelector((state) => state.projects.table.viewParams.filter);
	const lastSelectedRow = useSelector((state) => state.projects.lastSelectedRow);
	const allRowsDataCount = useSelector((state) => state.projects.table.all_data_count);
	const [rowsDataHasPack, setRowsDataHasPack] = useState(false);
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);

	const selectingOpts = {
		north_reg_terrs: [
			{ id: 1, name: "Обычная" },
			{ id: 2, name: "МПРКС" },
			{ id: 3, name: "РКС" },
		],
		price_zones: props.priceZones,
	};

	useLayoutEffect(() => {
		if (rowsData?.data?.find((x) => x.model_name === "UserProjectPacks")) {
			setRowsDataHasPack(true);
		} else {
			setRowsDataHasPack(false);
		}
	}, [rowsData?.data?.length]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (["UserProjects", "UserProjectPacks"].includes(clickedTableRow?.model_name)) {
			if (clickedTableRow?.id) dispatch(setLastSelected(clickedTableRow));
		}
	}, [clickedTableRow]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (lastSelectedRow?.id) dispatch(setClickedTableRow(lastSelectedRow));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const setTableDataLocal = ({ newTableData }) => dispatch(setTableData(newTableData));

	const editDataFactory = ({ rowObj, dataObj }) => {
		if (rowObj.id == null) {
			if (dataObj.key === "UserProjects") {
				dispatch(addProject({ ...rowObj, inline_cell: "", ...dataObj }));
				const prom = dispatch(createProject({ ...rowObj, ...dataObj }));
				prom.then((resp) => dispatch(setClickedTableRow(resp.payload)));
			} else if (rowObj.model_name === "UserProjectPacks") {
				dispatch(addProject({ ...rowObj, inline_cell: "", ...dataObj }));
				const prom = dispatch(createProjectPack({ ...rowObj, ...dataObj }));
				const row = searchNestedIterableItem({ iterable: rowsData.data, isSeekRowCallback: (x) => x.selected === true });
				prom.then((resp) => dispatch(setClickedTableRow(row.seekItem)));
			}
		} else {
			if (dataObj.inline_cell != null && dataObj.inline_cell !== "") {
				dispatch(editProject({ ...rowObj, ...dataObj }));
			} else {
				if (rowObj.model_name === "UserProjects") {
					dispatch(editProject({ ...rowObj, inline_cell: "", ...dataObj }));
					dispatch(patchProject({ ...rowObj, ...dataObj }));
				} else if (rowObj.model_name === "UserProjectPacks") {
					dispatch(editProject({ ...rowObj, inline_cell: "", ...dataObj }));
					dispatch(patchProjectPack({ ...rowObj, ...dataObj }));
				}
			}
		}
	};

	const handleDragEnd = (result) => {
		let droppableId = result?.destination?.droppableId;
		if (result?.destination?.droppableId == null) droppableId = null;
		if (String(result?.destination?.droppableId).includes("UserProjects")) droppableId = null;
		const prom = apiProjects.edit(result.draggableId, { project_pack_id: droppableId });
		prom.then((response) => props.funcs.getTableData());
	};

	const columnsData = ProjectTableColumns({ funcs: { ...props.funcs, editDataFactory: editDataFactory }, rowsDataHasPack });

	if (rowsData.data == null) return null;
	if (rowsData.data.length === 0 && allRowsDataCount === 0) {
		return (
			<div style={{ height: "500px" }}>
				<NoProjectWelcome />
			</div>
		);
	}

	return (
		<DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
			<Table
				rowData={rowsData.data}
				columnsData={columnsData}
				scrollBlock={props.scrollBlock}
				options={{
					selectOptions: {
						controlled: true,
						uncontrolled: false,
						multiple: false,
					},
					selectingInputOptions: selectingOpts,
					nestingOptions: {
						changeChildrenVisible: { tagName: { allowed: ["path", "svg"] } },
						childPaddingLeft: 40,
					},
					displayOptions: {
						rowHeight: 37,
						rowsDisplayCount: 40,
						displayScrollDelay: 20,
						scrollHeightControll: 50,
					},
					filterOptions: {
						serverFilter: true,
						prevFiltredData: tableFiltering,
					},
					sortOptions: {
						serverSort: true,
					},
					singleClickOptions: {
						useTargetParentNode: false,
						useStringInclude: false,
					},
				}}
				funcs={{
					setTableData: setTableDataLocal,
					editDataFactory: editDataFactory,
					doubleClickRowAction: {
						action: props.funcs.enterToProject,
					},
					customSortDataUp: {
						code: () => dispatch(setTableViewParams({ sort: { code: "up" } })),
						name: () => dispatch(setTableViewParams({ sort: { name: "up" } })),
						date_create: () => dispatch(setTableViewParams({ sort: { date_create: "up" } })),
						username: () => dispatch(setTableViewParams({ sort: { username: "up" } })),
					},
					customSortDataDown: {
						code: () => dispatch(setTableViewParams({ sort: { code: "down" } })),
						name: () => dispatch(setTableViewParams({ sort: { name: "down" } })),
						date_create: () => dispatch(setTableViewParams({ sort: { date_create: "down" } })),
						username: () => dispatch(setTableViewParams({ sort: { username: "down" } })),
					},
					customFilter: (filterValues) => dispatch(setTableViewParams({ filter: filterValues })),
				}}
			/>
		</DragDropContext>
	);
}

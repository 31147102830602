import "./LabelTwoVerticalLevel.css";

const LabelTwoVerticalLevel = (props) => {
    const defaultBoxStyle = {};
    const applyingBoxStyle = { ...defaultBoxStyle, ...props.style?.box };

    const defaultMainStyle = { textDecoration: "underline" };
    const applyingMainStyle = { ...defaultMainStyle, ...props.style?.mainLabel };

    const defaultSecondStyle = {};
    const applyingSecondStyle = { ...defaultSecondStyle, ...props.style?.secondLabel };

    const labelMain = props.main;
    const labelSecond = props.second;
    return (
        <div className="LabelTwoVerticalLevel" style={applyingBoxStyle}>
            <div className="LabelMain" style={applyingMainStyle} title={labelMain}>
                {labelMain}
            </div>
            <div className="LabelSecond" style={applyingSecondStyle} title={labelSecond}>
                {labelSecond}
            </div>
        </div>
    );
};

export { LabelTwoVerticalLevel };

import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import { getSelectedItemsSum } from "components/Footer/aggFuncs";

const LocalEstimateViewerFooterSettings = ({ clickedTableCtrlRows, lsrData }) => {
	return [
		{
			type: "common",
			text: lsrData?.methodic || "",
			icon: null,
			position: "left",
			callback: () => {},
			style: {},
			jsx: null,
		},
		{
			type: "common",
			text: lsrData?.estm_indextype ? `(${lsrData?.estm_indextype})` : "",
			icon: null,
			position: "left",
			callback: () => {},
			style: {},
			jsx: null,
		},
		{
			type: "common",
			text: lsrData?.fsnb_vers_name || "",
			icon: null,
			position: "left",
			callback: () => {},
			style: {},
			jsx: null,
		},
		{
			type: "common",
			text: lsrData?.price_zone_name || "",
			icon: null,
			position: "left",
			callback: () => {},
			style: {},
			jsx: null,
		},
		{
			type: "common",
			text: lsrData?.estm_prclvl_cur_str || "",
			icon: null,
			position: "left",
			callback: () => {},
			style: {},
			jsx: null,
		},
		{
			type: "common",
			text: `Выделено ячеек: ${clickedTableCtrlRows.length}. Сумма: ${getSelectedItemsSum({ clickedTableCtrlRows }) || ""}`,
			icon: <FunctionsOutlinedIcon />,
			position: "right",
			isVisible: true,
			callback: () => {},
			style: {},
			jsx: null,
		},
	];
};

export default LocalEstimateViewerFooterSettings;

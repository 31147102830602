import React, { useRef, useEffect, useState } from "react";

import TableMain from "components/Table/TableMain";

export default function useTable(rowsData, columns, userOptions, userFuncs, addinitalParams = {}, notClosedFunc = {}) {
	const scrollBlock = useRef();
	const needRecource = useRef(true);
	const [sortParams, setSortParams] = useState({});
	const [filterParams, setFilterParams] = useState({});
	const [filterValues, setFilterValues] = useState({});
	const options = useRef({
		selectable: true,
		scrollBlock: scrollBlock,
		displayScrollDelay: 4,
		...userOptions,
	});
	const funcs = useRef({ ...userFuncs });

	const replaceNull = (str) => {
		if (str == null) return "";
		return str;
	};

	const changeRecourceBoolArg = (row, clickedRow, clm, callback) => {
		if (!needRecource.current) return row;
		if (row.key === clickedRow.key) {
			row[clm] == null ? (row[clm] = true) : (row[clm] = !row[clm]);
			callback(row, clm, row[clm]);
			needRecource.current = false;
		} else {
			if (row._children != null) {
				for (let ch of row._children) changeRecourceBoolArg(ch, clickedRow, clm, callback);
			}
		}
		return row;
	};

	const getSortDataUpFunc = (customFunc) => {
		return (rowsData, clmName) => {
			let data = [...rowsData];
			if (customFunc && customFunc[clmName]) {
				data = customFunc[clmName]({ data, clmName });
			} else {
				data = data.sort((a, b) => (replaceNull(a[clmName]) < replaceNull(b[clmName]) ? 1 : -1));
			}
			userFuncs.updateData(data);
			setSortParams((prev) => ({ ...prev, [clmName]: "down" }));
		};
	};

	const getSortDataDownFunc = (customFunc) => {
		return (rowsData, clmName) => {
			let data = [...rowsData];
			if (customFunc && customFunc[clmName]) {
				data = customFunc[clmName]({ data, clmName });
			} else {
				data = data.sort((a, b) => (replaceNull(a[clmName]) > replaceNull(b[clmName]) ? 1 : -1));
			}
			userFuncs.updateData(data);
			setSortParams((prev) => ({ ...prev, [clmName]: "up" }));
		};
	};

	const getUpdateFilterStatusFunc = () => {
		return (clmName, status) => {
			setFilterParams((prev) => ({ ...prev, [clmName]: status }));
		};
	};

	const getFilterDataFunc = () => {
		return (rowsData, clmName, strValue) => {
			if (userFuncs?.updateFilterValues) {
				userFuncs?.updateFilterValues({ name: clmName, value: strValue });
			}
			if (userOptions?.useUpdateDataForFilter === false) return;
			userFuncs.updateData(
				JSON.parse(JSON.stringify(rowsData)).map((row) => {
					row.visible = true;
					if (!String(row[clmName]).toLowerCase().includes(String(strValue).toLowerCase())) {
						row.visible = false;
					}
					return row;
				})
			);
		};
	};

	const getSelectDataFunc = () => {
		return (rowsData, clickedRow) => {
			if (!options.current.selectable) return;
			userFuncs.updateData(
				[...rowsData].map((row) => {
					if (row.key === clickedRow.key) {
						row.selected = !row.selected;
					} else {
						row.selected = false;
					}
					return row;
				})
			);
		};
	};

	const getChangeVisibleFunc = () => {
		// not for redux
		return ({ clickedRow }) => {
			needRecource.current = true;
			const callback = (row, clm, val) => {
				if (row._children != null) {
					for (let ch of row._children) {
						ch.visible = val;
						callback(ch, clm, val);
					}
				}
			};
			userFuncs.updateData((prev) =>
				prev.map((row) => {
					return changeRecourceBoolArg(row, clickedRow, "childs_visible", callback);
				})
			);
		};
	};

	/*eslint-disable */
	useEffect(() => {
		const sort = {};
		const flt = {};
		const fltVals = {};
		for (let clm of columns) {
			if (clm.sort) sort[clm.name] = "down";
			if (clm.filter) flt[clm.name] = "close";
		}
		if (userOptions?.originColumnsData) {
			for (let clm of userOptions?.originColumnsData) {
				if (clm.sort) sort[clm.name] = "down";
				if (clm.filter) flt[clm.name] = "close";
			}
		}
		if (addinitalParams?.notClosedOptions?.prevFiltredData) {
			for (let clm of addinitalParams?.notClosedOptions?.prevFiltredData) {
				if (String(clm.value).length > 0) {
					flt[clm.name] = "open";
					fltVals[clm.name] = clm.value;
				}
			}
		}
		setSortParams((prev) => sort);
		setFilterParams((prev) => flt);
		setFilterValues((prev) => fltVals);
	}, []);

	useEffect(() => {
		funcs.current.sortDataUp = getSortDataUpFunc(funcs.current.customSortDataUp);
		funcs.current.sortDataDown = getSortDataDownFunc(funcs.current.customSortDataDown);
		if (funcs.current.updateFilterStatus == null) funcs.current.updateFilterStatus = getUpdateFilterStatusFunc();
		if (funcs.current.filterData == null) funcs.current.filterData = getFilterDataFunc();
		if (funcs.current.selectData == null && funcs.current.selectDataLocal == null) funcs.current.selectDataLocal = getSelectDataFunc();
		if (funcs.current.changeVisible == null) funcs.current.changeVisible = getChangeVisibleFunc();
	}, [rowsData]);
	/*eslint-enable */

	const tableComponent = (
		<div className="TableWrapper" ref={scrollBlock}>
			<TableMain
				rows={{ data: rowsData }}
				columns={columns}
				options={{ ...options.current, ...addinitalParams.notClosedOptions }}
				func={{ ...funcs.current, ...notClosedFunc }}
				sortParams={sortParams}
				filterParams={filterParams}
				filterValues={filterValues}
			></TableMain>
		</div>
	);
	return tableComponent;
}

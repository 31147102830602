import Draggable from "react-draggable";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import KsprButton from "components/UI/buttons/KsprButton";

import "./PriceOfferDataSourceModal.css";

const PriceOfferDataSourceModal = ({ setVisible, dataObj }) => {
    return (
        <Draggable handle=".PriceOfferDataSourceModalTitle">
            <Dialog
                className="PriceOfferDataSourceModal"
                sx={{ "& .MuiDialog-paper": { width: "28vw", maxWidth: "28vw", height: "280px" } }}
                open
                slotProps={{ backdrop: { style: { backgroundColor: "transparent" } }, }}
            >
                <DialogTitle className="PriceOfferDataSourceModalTitle">Источник данных</DialogTitle>
                <DialogContent className="PriceOfferDataSourceModalContent">
                    <table className='PriceOfferDataSourceInfoTable'>
                        <tbody className='PriceOfferDataSourceInfo'>
                            <tr>
                                <td>Наименование стройки (объекта): </td>
                                <td>{dataObj.project_name}</td>
                            </tr>
                            <tr>
                                <td>Регион (ценовая зона) объекта:</td>
                                <td>{dataObj.project_price_zone_name}</td>
                            </tr>
                            <tr>
                                <td>Текущий уровень цен:</td>
                                <td>{dataObj.price_level}</td>
                            </tr>
                            <tr>
                                <td>Автор проекта:</td>
                                <td>{dataObj.username}</td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <div className="MonitorModalbtnWrapper">
                        <KsprButton
                            sx={{ ml: "auto" }}
                            variant="text"
                            label="закрыть"
                            onClick={() => setVisible(false)}
                        />
                    </div>
                </DialogActions>
            </Dialog>
        </Draggable>
    );
}

export { PriceOfferDataSourceModal }

import CustomCodeCell from "./CustomCell/CustomCodeCell";
import CustomNameCell from "./CustomCell/CustomNameCell";

export default function NormTableColumns({ inputSearchValue }) {
	return [
		{
			name: "show_fsnb_content",
			title: "",
			width: "8px",
			cellHorAlign: "left",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
			action: "show_fsnb_content",
		},
		{
			name: "show_fsnb_resource",
			title: "",
			width: "8px",
			cellHorAlign: "left",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
			action: "show_fsnb_resource",
		},
		{
			name: "code",
			title: "Шифр",
			width: "100px",
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
			cellJsx: ({ rowObj, funcs }) => CustomCodeCell({ rowObj, funcs, inputSearchValue }),
		},
		{
			name: "name",
			title: "Наименование",
			width: "auto",
			cellHorAlign: "left",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
			cellJsx: ({ rowObj, funcs, clmObj, parentProps }) => CustomNameCell({ rowObj, funcs, clmObj, parentProps, inputSearchValue }),
			actions_icon_on_cell: [
				{
					check_value: {
						key: "cost_has_coef",
						value: [true],
					},
					action: "show_fsnb_coefs",
				},
				{
					check_value: {
						key: "resource_type",
						value: ["AbstractResource"],
					},
					action: "show_tech_groups",
				},
			],
		},
		{
			name: "measure_unit",
			title: "Ед. изм",
			width: "70px",
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
		},
		{
			name: "quantity",
			title: "Расход",
			width: "70px",
			cellHorAlign: "right",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
		},
		{
			name: "prc_cost",
			title: "ПЗ, руб.",
			width: "75px",
			cellHorAlign: "right",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
		},
		{
			name: "prc_salary",
			title: "ОТ, руб.",
			width: "75px",
			cellHorAlign: "right",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
		},
		{
			name: "em",
			title: "ЭМ, руб.",
			width: "150px",
			cellHorAlign: "left",
			headHorAlign: "center",
			headHeight: "25px",
			colSpan: "2",
			children: [
				{
					name: "prc_machines",
					title: "всего",
					width: "75px",
					cellHorAlign: "right",
					headHorAlign: "center",
					sort: false,
					filter: false,
					editable: false,
					headHeight: "25px",
				},
				{
					name: "prc_salary_mach",
					title: "в т.ч. ОТМ",
					width: "75px",
					cellHorAlign: "right",
					headHorAlign: "center",
					sort: false,
					filter: false,
					editable: false,
					headHeight: "25px",
				},
			],
		},
		{
			name: "prc_materials",
			title: "МАТ, руб.",
			width: "75px",
			cellHorAlign: "right",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
		},
		{
			name: "zt",
			title: "ЗТ, чел.-ч",
			width: "75px",
			cellHorAlign: "right",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
		},
		{
			name: "ztm",
			title: "ЗТМ, чел.-ч",
			width: "75px",
			cellHorAlign: "right",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
			rowSpan: "2",
		},
	];
}

import { useState, useRef } from "react";

import { TextField } from "@mui/material";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import InputAdornment from "@mui/material/InputAdornment";
import KsprButton from "components/UI/buttons/KsprButton";

import Settings from "@mui/icons-material/Settings";

import "components/TextBoxModifyMui.css";
import "./AdvSearchInput.css";

const AdvSearchInput = ({ searchText, handleChange, execute, searchTextisError, attrsOpts, selectedSearchOptions, setSelectedSearchOptions }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const popoverId = open ? "simple-popover" : undefined;
	const inputRef = useRef();

	const handleToggleList = () => setAnchorEl(inputRef.current);
	const handleCloseList = () => setAnchorEl(null);
	const handleChangePatterts = (opt) => setSelectedSearchOptions(opt);

	return (
		<TextField
			ref={inputRef}
			sx={{ "& legend": { fontSize: "9px" } }}
			value={searchText}
			onChange={(e) => handleChange(e)}
			onKeyDown={(e) => {
				if (e.key === "Enter") execute();
			}}
			label="Что искать"
			error={searchTextisError}
			size="small"
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<div className="AdvSearchPatternIcon">
							<Settings fontSize="16px" onClick={handleToggleList} />
						</div>

						<Popover
							parent_component="AdvSearchInput"
							id={popoverId}
							open={open}
							anchorEl={anchorEl}
							onClose={handleCloseList}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
						>
							<List sx={{ width: "360px" }}>
								{attrsOpts.map((opt) => (
									<ListItem key={opt.id} title={opt.name} onClick={() => handleChangePatterts(opt)}>
										<Checkbox checked={selectedSearchOptions.id === opt.id} />
										<ListItemText primary={opt.name} />
									</ListItem>
								))}
							</List>
							<KsprButton
								sx={{ ml: "auto" }}
								variant="text"
								label="найти"
								onClick={() => {
									handleCloseList();
									execute();
								}}
							/>
						</Popover>
					</InputAdornment>
				),
			}}
		/>
	);
};

export default AdvSearchInput;

import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

import { changeMonitorDataDetailsVisible, changeModalVisible } from "slices/import_files";
import { setQuestionVisible } from "slices/global_visible";

import "./MonitorStepper.css";

const MonitorStepper = ({ steps, row }) => {
	const dispatch = useDispatch();
	const filesList = useSelector((state) => state.importFiles.filesList);

	if (!["local", "kats-child"].includes(row.type)) steps = steps.slice(1);

	let activeStep = -1;
	const completed = {
		0: null,
		1: null,
		2: null,
	};
	const err_cnt = {
		0: 0,
		1: 0,
		2: 0,
	};
	const err_text = {
		0: row.step_1_error_text,
		1: row.step_2_error_text,
		2: row.step_3_error_text,
	};

	if (row.id === 0 || row.type === "summary" || row.type === "kats") return <></>;

	if (row.hasOwnProperty("monitor_stats")) {
		if (row["step_1_status"] != null) {
			activeStep = 1;
			completed[0] = row["step_1_status"];
			err_cnt[0] = row["monitor_stats"]["step_1_cnt"] + " замечаний";
			if (row["step_2_status"] != null) {
				activeStep = 2;
				completed[1] = row["step_2_status"];
				err_cnt[1] = row["monitor_stats"]["step_2_cnt"] + " замечаний";
			}
			if (row["step_3_status"] != null) {
				let skip = false;
				if (row["step_2_status"] !== true) {
					if (["local", "kats-child"].includes(row["type"])) {
						skip = true;
					}
				}
				if (skip === false) {
					activeStep = 3;
					let ind = 2;
					if (row["step_2_status"] == null) {
						ind = 1;
					}
					completed[ind] = row["step_3_status"];
					err_cnt[ind] = row["monitor_stats"]["step_3_cnt"] + " замечаний";
				}
				if (row["type"] === "kats-child") {
					activeStep = 3;
					completed[2] = row["step_3_status"];
					err_cnt[2] = row["monitor_stats"]["step_3_cnt"] + " замечаний";
				}
			}
			if (row["step_2_status"] == null && row["step_3_status"] != null && row.type === "local") {
				activeStep = 1;
				completed[2] = true;
				err_cnt[2] = row["monitor_stats"]["step_3_cnt"] + " замечаний";
			}
		}
	}

	let wsCheck = null;
	if (row.monitor_stats) {
		wsCheck = row.monitor_stats.step_1_cnt;
		if (wsCheck !== -1) {
			wsCheck = row.monitor_stats.step_2_cnt;
		}
	}

	const openDetails = () => {
		const completed = filesList.filter((x) => x.status > 1);
		if (filesList.length === completed.length) {
			dispatch(changeMonitorDataDetailsVisible({ row: row }));
		} else {
			dispatch(changeModalVisible({ key: "waiting", value: true }));
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const labelProps = {};
					let stepTitle;
					if (completed[index] === false) {
						let errorText = err_text[index];
						if (errorText === "Ошибка ответа сервера") {
							errorText = (
								<span>
									<span>{`${errorText}.`}</span>
									<span className="StepperReportError" onClick={() => dispatch(setQuestionVisible(true))}>
										Сообщить
									</span>
								</span>
							);
						} else {
							errorText = `${String(err_text[index]).slice(0, 27)}...`;
						}
						labelProps.optional = (
							<Typography variant="caption" color="error">
								{errorText}
							</Typography>
						);
						labelProps.error = true;
						if (errorText.length < err_text[index].length) stepTitle = err_text[index];

						// labelProps.icon = <ErrorIcon></ErrorIcon>
					} else if (completed[index] === true) {
						labelProps.optional = <Typography>{err_cnt[index]}</Typography>;
					} else if (completed[index] === null) {
						if (wsCheck === -1 && index < 2) {
							labelProps.optional = <Typography style={{ color: "white" }}>-</Typography>;
						}
					}

					let completedWithoutPrev = "0";
					if (index === 2 && completed[1] !== true && completed[2] === true) completedWithoutPrev = "1";

					return (
						<Step key={label} style={{ cursor: "pointer" }} onClick={() => openDetails()} completed_without_prev={completedWithoutPrev}>
							<StepLabel {...labelProps} title={stepTitle}>
								{label}
							</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		</Box>
	);
};

export default MonitorStepper;

const ErrorStatusEnum = {
    ERROR: 1,
    WARNING: 2,
    ALERT: 3,
};

const MaxFileSizeEnum = {
    QUESTION: 1024 * 1024 * 15,
    KATS_JUST: 1024 * 1024 * 81,
};

const XmlResourceTypeEnum = {
    ITEM: 1,
    ITEM_RESOURCE: 2,
    COST_RESOURCE: 3,
    COST_RESOURCE_INTERNAL: 4,
};


export { ErrorStatusEnum, MaxFileSizeEnum, XmlResourceTypeEnum };


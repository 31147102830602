import React from 'react';

import CellIconWithAction from "./CellIconWithAction";

export default function CellWithAdditionalIcon({ row, clm, rowOptions, funcs, parentRow }) {
    let actionIconArr = [];

    for (let act of clm.actions_icon_on_cell) {
        const check_value = act.check_value;
        if (check_value.value.includes(row[check_value.key])) {
            const clmCopy = JSON.parse(JSON.stringify(clm))
            clmCopy.action = act.action;
            actionIconArr.push(
                <span className="CellIconWithAction" key={act.action}>
                    <CellIconWithAction
                        row={row} clm={clmCopy}
                        rowOptions={{ ...{ parentRow: parentRow }, ...rowOptions }}
                        funcs={funcs} />
                </span>
            )
        }
    }

    return actionIconArr;
}
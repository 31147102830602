const RibbonElementCustom = ({ elementSettings }) => {
    const { customJsx, styles } = elementSettings;

    return (
        <div className="ribbon-element-custom" style={{ width: styles.width, paddingTop: styles.pt }}>
            {customJsx}
        </div>
    );
};

export default RibbonElementCustom;

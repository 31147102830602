import { useState, useEffect, useRef } from "react";
import { useNavigate, Route, Routes, useMatch } from "react-router-dom";

import { IconButton, Tooltip } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import IndexesDirect from "./IndexesDirect";
import IndexesEqp from "./IndexesEqp";
import IndexesPg from "./IndexesPg";
import IndexesGosr from "./IndexesGosr";

import { getLastFiltredUrlFromStack } from "../../components/utils";
import { getEnvVal } from "core/env.js";

import IndexesOtherWorks from "./IndexesOtherWorks";
import IndexesSmr from "./IndexesSmr";
import RibbonContent from "components/Ribbon/RibbonContent/RibbonContent";

import "./IndexesMain.css";

const IndexesMain = (props) => {
    const navigate = useNavigate();
    const [value, setValue] = useState();
    const match = useMatch("/indexes/:lastPart");
    const scrollBlock = useRef();

    useEffect(() => {
        if (match == null) {
            setValue("direct");
            if (getEnvVal("App") !== "sipcs") {
                navigate(`/indexes/direct/`);
            } else {
                navigate(`/indexes/gosr/`);
            }
        } else {
            setValue(match.params.lastPart);
        }
    }, [match, navigate]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate(`/indexes/${newValue}/`);
    };

    return (
        <>
            <RibbonContent />
            <div className="IndexesDataMain" ref={scrollBlock}>
                {value != null && (
                    <div>
                        <div className="IndexesDataTabs">
                            <div
                                style={{
                                    flexBasis: "calc(2%)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <Tooltip title="Выход">
                                    <IconButton
                                        onClick={() => {
                                            navigate(getLastFiltredUrlFromStack(props.history, ["/index"]));
                                        }}>
                                        <LogoutOutlinedIcon rotate="180" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div
                                style={{
                                    flexBasis: "calc(96%)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange}>
                                    <Tab value="gosr" label="ИНДЕКСЫ ПО ГРУППАМ ОДНОРОДНЫХ РЕСУРСОВ" />
                                    {getEnvVal("App") !== "sipcs" && (
                                        <Tab value="direct" label="ИНДЕКСЫ ПО ОБЪЕКТАМ СТРОИТЕЛЬСТВА" />
                                    )}
                                    {getEnvVal("App") !== "sipcs" && <Tab value="smr" label="ИНДЕКСЫ СМР" />}
                                    <Tab
                                        value="pg"
                                        className="tab-transport"
                                        label="ИНДЕКСЫ НА ПЕРЕВОЗКУ И ПОГРУЗО-РАЗГРУЗОЧНЫЕ РАБОТЫ"
                                    />
                                    {getEnvVal("App") !== "sipcs" && (
                                        <Tab value="eqp" label="ИНДЕКСЫ НА ОБОРУДОВАНИЕ" />
                                    )}
                                    {getEnvVal("App") !== "sipcs" && (
                                        <Tab value="other_index" label="ИНДЕКСЫ НА ПРОЧИЕ РАБОТЫ И ЗАТРАТЫ" />
                                    )}
                                </Tabs>
                            </div>
                            <div style={{ flexBasis: "calc(2%)" }}></div>
                        </div>

                        <Routes>
                            <Route path="/gosr" element={<IndexesGosr scrollBlock={scrollBlock} />} />
                            <Route path="/direct" element={<IndexesDirect />} />
                            <Route path="/pg" element={<IndexesPg scrollBlock={scrollBlock} />} />
                            <Route path="/eqp" element={<IndexesEqp />} />
                            <Route path="/other_index" element={<IndexesOtherWorks />} />
                            <Route path="/smr" element={<IndexesSmr />} />
                        </Routes>
                    </div>
                )}
            </div>
        </>
    );
};

export default IndexesMain;

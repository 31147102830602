const highlightSubstring = (_string, subString, setClassName) => {
    const lowerStr = String(_string).toLocaleLowerCase();
    const lowerSubStr = String(subString).toLocaleLowerCase();

    if (!lowerStr.includes(lowerSubStr)) return _string;

    const preHighlight = _string.slice(0, lowerStr.indexOf(lowerSubStr));
    const highlight = _string.slice(lowerStr.indexOf(lowerSubStr), lowerStr.indexOf(lowerSubStr) + lowerSubStr.length);
    const postHighlight = _string.slice(lowerStr.indexOf(lowerSubStr) + lowerSubStr.length, lowerStr.length);

    return (
        <span>
            {preHighlight}
            <span className={setClassName}>{highlight}</span>
            {postHighlight}
        </span>
    );
};

export { highlightSubstring };

import { useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import KsprButton from "components/UI/buttons/KsprButton";

import "./AdvSearchBoxOptsTree.css";

export default function AdvSearchBoxOptsTree({
    optionValues,
    curSelected,
    setCurSelected,
    handleSelectClick,
    setChangeScopeCnt,
}) {
    const [treeOpts, setTreeOpts] = useState(optionValues);
    const [reRender, setReRender] = useState(0);

    const handleChange = (row) => {
        setCurSelected((prev) => {
            const inArray = prev.find((x) => x.id === row.id);
            if (inArray === undefined) {
                // для добавления сср и оср в выборку, если все его дети выбраны
                return selectParent([...prev, row]);
            } else {
                // для очистки сср после очистки оср запуск выполняется 2 раза
                return unselectParent(unselectParent(prev.filter((x) => x.id !== row.id)));
            }
        });
    };

    const unselectParent = (selected) => {
        for (let row of selected) {
            if (row.hasOwnProperty("_children")) {
                const diff = getDiffWithSelected(row, selected);
                if (diff === "null") {
                    selected = selected.filter((x) => x.id !== row.id);
                }
            }
        }
        return selected;
    };

    const selectParent = (selected) => {
        for (let srr of treeOpts) {
            if (srr._children != null) {
                for (let osr of srr._children) {
                    if (osr._children != null) {
                        if (getDiffWithSelected(osr, selected) === "full") {
                            if (!selected.filter((x) => x.id === osr.id).length) {
                                selected.push(osr);
                            }
                        }
                    }
                }
                if (getDiffWithSelected(srr, selected) === "full") {
                    if (!selected.filter((x) => x.id === srr.id).length) {
                        selected.push(srr);
                    }
                }
            }
        }
        return selected;
    };

    const grpChange = (row) => {
        let optForAdd = [];
        if (row.id === "all") {
            // выбрать все
            if (curSelected.filter((x) => x.id === "all").length) {
                setCurSelected((prev) => []);
            } else {
                optForAdd = [
                    ...treeOpts,
                    ...treeOpts.flatMap((x) => x?._children),
                    ...treeOpts.flatMap((x) => x?._children).flatMap((x) => x?._children),
                ].filter((x) => x != null);
                setCurSelected((prev) => optForAdd);
            }
        } else {
            for (let opt of treeOpts) {
                if (row.id.includes(opt.id)) {
                    // ключ родителя является подмножество ключа детей
                    // если родитель и есть искомая строка - добавлем его
                    // и всех его детей, в том числе вложенных
                    if (opt.id === row.id) {
                        optForAdd.push(opt);
                    } // ССР только
                    if (opt._children != null) {
                        if (opt.id === row.id) {
                            // ССР только
                            optForAdd = [
                                ...optForAdd,
                                ...opt._children,
                                ...opt._children.flatMap((x) => x?._children),
                            ].filter((x) => x != null);
                            // иначе ищем среди детей старшего родителя искомую
                            // строку и добаляем её и всех её детей
                        } else {
                            // ОСР только
                            for (let chld of opt._children) {
                                if (chld.id === row.id) {
                                    optForAdd = [...optForAdd, chld, ...chld._children].filter((x) => x != null);
                                }
                            }
                        }
                    }
                    break;
                }
            }
            setCurSelected((prev) => {
                const inArray = prev.find((x) => x.id === row.id);
                if (inArray === undefined) {
                    if (prev.length) {
                        optForAdd = optForAdd.filter((x) => !prev.map((p) => p.id).includes(x.id));
                    }
                    return [...prev, ...optForAdd];
                } else {
                    return prev.filter((x) => !optForAdd.map((opt) => opt.id).includes(x.id));
                }
            });
        }
    };

    const getDiffWithSelected = (row, selected = null) => {
        if (row.id === "all") {
            if (curSelected.filter((x) => x.id === "all").length > 0) {
                const treeFlat = [
                    ...treeOpts,
                    ...treeOpts.flatMap((x) => x?._children),
                    ...treeOpts.flatMap((x) => x?._children).flatMap((x) => x?._children),
                ];
                const treeCnt = treeFlat.filter((x) => x != null).length;
                if (treeCnt === curSelected.length) {
                    return "full";
                } else if (treeCnt > 0) {
                    return "partical";
                } else {
                    return "null";
                }
            } else {
                return "null";
            }
        }
        const children_ids = [...row?._children, ...row?._children.flatMap((x) => x?._children)]
            .filter((x) => x != null)
            .map((x) => x.id);
        if (selected === null) {
            selected = curSelected;
        }
        const curSelected_ids = selected.map((x) => x.id);
        const diff = children_ids
            .map((child) => curSelected_ids.includes(child))
            .reduce((partialSum, a) => partialSum + a, 0);
        if (diff === children_ids.length && diff > 0) {
            return "full";
        } else if (diff > 0) {
            return "partical";
        } else {
            return "null";
        }
    };

    const getColor = (row) => {
        if (getDiffWithSelected(row) === "full") {
            return "primary";
        } else {
            return "default";
        }
    };

    const getGrpChecked = (row) => {
        if (getDiffWithSelected(row) === "null") {
            return false;
        } else {
            return true;
        }
    };

    const changeChildsVisible = (row, visb) => {
        setTreeOpts((prev) => {
            for (let elm of prev) {
                if (row.id.includes(elm.id)) {
                    if (elm.id === row.id) {
                        elm.childs_visible = visb;
                    } else {
                        for (let chld of elm._children) {
                            if (chld.id === row.id) {
                                chld.childs_visible = visb;
                            }
                        }
                    }
                }
            }
            return prev;
        });
        setReRender((prev) => prev + 1);
    };

    const getArrowVisible = (row) => {
        if (["local"].includes(row.type)) {
            return;
        }
        if (["all"].includes(row.id)) {
            return;
        }
        if (row.childs_visible === false && row?._children?.length > 0) {
            return <KeyboardArrowRightIcon onClick={(e) => changeChildsVisible(row, true)}></KeyboardArrowRightIcon>;
        } else if (row.childs_visible === true && row?._children?.length > 0) {
            return <KeyboardArrowDownIcon onClick={(e) => changeChildsVisible(row, false)}></KeyboardArrowDownIcon>;
        } else {
            return <KeyboardArrowDownIcon style={{ opacity: 0 }}></KeyboardArrowDownIcon>;
        }
    };

    const funcs = {
        handleChange: handleChange,
        grpChange: grpChange,
        getColor: getColor,
        getGrpChecked: getGrpChecked,
        getArrowVisible: getArrowVisible,
    };

    return (
        <div className="advSearchBoxOptsTreeWrapper">
            {genOptsTree(treeOpts, curSelected, funcs, reRender)}
            <KsprButton
                sx={{ ml: "auto" }}
                variant="text"
                label="сохранить"
                onClick={() => {
                    handleSelectClick();
                    setChangeScopeCnt((prev) => prev + 1);
                }}
            />
        </div>
    );
}

function genOptsTree(data, curSelected, funcs, reRender, level = 0) {
    let $opts = [];
    for (let elm of data) {
        if (elm._children != null) {
            const isChecked = funcs.getGrpChecked(elm);
            const has_children = elm._children.length > 0 && !["all", -1].includes(elm.id);
            $opts.push(
                <MenuItem
                    key={elm.id}
                    test_key={elm.id}
                    title={elm.name}
                    level={level}
                    row_id={elm.id}
                    has_children={has_children.toString()}
                    // onClick={(e) => console.log(e.target)}
                    // selected={curSelected.filter(x => x.id === elm.id).length === 1}
                    selected={isChecked}>
                    {funcs.getArrowVisible(elm)}
                    <Checkbox
                        onChange={(e) => funcs.grpChange(elm)}
                        color={funcs.getColor(elm)}
                        checked={isChecked}></Checkbox>
                    <ListItemText primary={elm.name} onClick={(e) => funcs.grpChange(elm)} />
                    <div style={{ display: "none" }}>{reRender}</div>
                </MenuItem>
            );
            const child_level = level + 1;
            if (elm.childs_visible) {
                $opts = [...$opts, genOptsTree(elm._children, curSelected, funcs, reRender, child_level)];
            }
        } else {
            const isSelected = curSelected.filter((x) => x.id === elm.id).length === 1;
            $opts.push(
                <MenuItem
                    key={elm.id}
                    test_key={elm.id}
                    title={elm.name}
                    level={level}
                    // style={{display: }}
                    selected={isSelected}>
                    {funcs.getArrowVisible(elm)}
                    <Checkbox onChange={(e) => funcs.handleChange(elm)} checked={isSelected}></Checkbox>
                    <ListItemText primary={elm.name} onClick={(e) => funcs.handleChange(elm)} />
                </MenuItem>
            );
        }
    }
    return $opts;
}

import { apiBase } from "./base";

const apiUserAdv = {
	client: apiBase.get_client(),
	url: "/user_adv/",

	getBlackListData() {
		return this.client.get(this.url + "organization_blacklist/", apiBase.get_headers());
	},

	addToBlackList(body) {
		return this.client.post(this.url + "organization_blacklist/", body, apiBase.get_headers());
	},

	delFromBlackList(id) {
		return this.client.delete(this.url + "organization_blacklist/" + id + "/", apiBase.get_headers());
	},

	getUserSettings({ userId }) {
		return this.client.get(this.url + "user_settings/", apiBase.get_headers());
	},

	getUserProgress({ parametr, arg }) {
		return this.client.get(this.url + "user_process_progress/" + parametr + "/" + arg + "/", apiBase.get_headers());
	},

	getUserTasks() {
		return this.client.get(this.url + "user_tasks/", apiBase.get_headers());
	},

	getUserTasksAndRead() {
		return this.client.get(this.url + "user_tasks/read/", apiBase.get_headers());
	},

	delUserTasks({ taskId }) {
		return this.client.delete(this.url + "user_tasks/" + taskId + "/", apiBase.get_headers());
	},

	saveUserSettings({ body }) {
		return this.client.post(this.url + "user_settings/", body, apiBase.get_headers());
	},
};

export default apiUserAdv;

import { useEffect, useRef, useState, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import AlertLoading from "components/AlertLoading";
import TableMain from "components/Table/TableMain";
import FsnbBodyNoData from "pages/Fsnb/components/FsnbBodyNoData";
import NormTableColumns from "./NormTableColumns.js";
import { setStartSearchObj } from "slices/fsnb";

import { showTableMore } from "pages/Fsnb/utils";
import FsnbTree from "pages/Fsnb/components/FsnbTree";
import { resourceChangeVisible, resourceOpenTree } from "pages/Fsnb/utils";

import { changeNestedArrKeyValueOnCondition, copyArr, arrProxy } from "core/iterable_utils";
import { moveNextSearch } from "core/fsnb_utils";

import apiFsnb from "api/fsnb";
import { setClickedTableRow } from "slices/ribbon";

import "pages/EstimateViewer/TableOuterBox.css";

import "./EstimateFsnbDrawer.css";
import FsemTableColumns from "./FsemTableColumns.js";
import FsemTableColumns2022 from "./FsemTableColumns2022.js";

import FsscTableColumns from "pages/Fsnb/TableSetting/FsscTableColumns";
import FsscPgTableColumns from "pages/Fsnb/TableSetting/FsscTablePgColumns";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";

const FsnbTechPartsMain = lazy(() => import("pages/Fsnb/components/FsnbTechPartsMain/FsnbTechPartsMain"));

export const EstimateFsnbDrawer = (props) => {
	const dispatch = useDispatch();
	const inputEl = useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const scrollBlock = useRef(null);
	const LocalEstimateDataVersId = useSelector((state) => state.localEstimate.data.fsnb_vers_id);
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const startSearchObj = useSelector((state) => state.fsnb.startSearchObj);

	const [tableData, setTableData] = useState([]);
	const [treeData, setTreeData] = useState([]);
	const [selectedTreeData, setSelectedTreeData] = useState({});
	const [inputSearchValue, setInputSearchValue] = useState("");
	const [fetchStatusText, setFetchStatusText] = useState();
	const [fetchStatus, setFetchStatus] = useState(null);
	const [curSearchRow, setCurSearchRow] = useState({});
	const [searchFounds, setSearchFounds] = useState([]);
	const [fsnbTableType, setFsnbTableType] = useState("");
	const [firstOpenDrawer, setFirstOpenDrawer] = useState(true);

	const [clickedTreeCnt, setClickedTreeCnt] = useState(0);
	const [dataView, setDataView] = useState("table");
	const [pdfData, setPdfData] = useState("");
	const [searchObjScope, setSearchObjScope] = useState({});

	const callbacks = useRef([]);
	const delay = 1750;

	const attrsOpts = [
		{ id: 1, name: "Наименование расценки" },
		{ id: 3, name: "Шифр расценки" },
		{ id: 2, name: "Наименование ресурса" },
		{ id: 4, name: "Код ресурса" },
		{ id: 6, name: "Состав работ" },
	];
	const [selectedSearchOptions, setSelectedSearchOptions] = useState(attrsOpts.filter((x) => [1, 3].includes(x.id)));

	useEffect(() => {
		return () => {
			setPdfData("");
			dispatch(setStartSearchObj());
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let rowsPassed = 0;
		const rowHeight = 22;
		let isFound = false;
		for (let row of tableData) {
			if (row.isSearching) {
				isFound = true;
				if (curSearchRow == null) break;
				if (Object.keys(curSearchRow).length === 0) {
					break;
				} else {
					if (row._children) {
						let addRowFromCur = 0;
						for (let child of row._children) {
							addRowFromCur += 1;
							if (child.id === curSearchRow.id) {
								rowsPassed = rowsPassed + addRowFromCur;
								break;
							}
						}
					}
				}
			}
			if (!isFound) {
				rowsPassed += 1;
				if (row._children != null) {
					rowsPassed += row._children.filter((x) => x.visible === true).length;
				}
			}
		}

		if (isFound && firstOpenDrawer) {
			scrollBlock.current.scrollTop = rowsPassed * rowHeight - rowHeight * 2;
		}
	}, [tableData?.length, curSearchRow, firstOpenDrawer, clickedTreeCnt]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setFetchStatus("loading");

		let fetchTree = null;

		const handleTreeFetch = (treePromise) => {
			treePromise
				.then((response) => {
					setTreeData(response.data);
				})
				.then(() => {
					if (startSearchObj) {
						setSearchFounds([startSearchObj]);
						setCurSearchRow({
							...startSearchObj,
							openChilds: true,
						});
					}

					setFetchStatus("success");
				})
				.catch((error) => {
					console.error(error);
					setFetchStatus("failed");
				});
		};

		if (clickedTableRow?.model_name === "LocalEstimatesItems") {
			setFsnbTableType("norm");
			fetchTree = apiFsnb.getNormTreeBase(LocalEstimateDataVersId);
		} else if (clickedTableRow?.model_name === "LocalEstimatesResMachine") {
			setFsnbTableType("mach");
			fetchTree = apiFsnb.getFsemTree(LocalEstimateDataVersId);
		} else if (clickedTableRow?.model_name === "LocalEstimatesResMaterial") {
			setFsnbTableType("mat");
			fetchTree = apiFsnb.getFsscTree(LocalEstimateDataVersId);
		} else if (clickedTableRow?.model_name === "LocalEstimatesResTransport") {
			setFsnbTableType("pg");
			fetchTree = apiFsnb.getFsscPgTree(LocalEstimateDataVersId);
		}

		if (fetchTree !== null) {
			handleTreeFetch(fetchTree);
		}
	}, [LocalEstimateDataVersId, startSearchObj]); // eslint-disable-line react-hooks/exhaustive-deps

	//search effect
	useEffect(() => {
		if (!searchFounds.length) return;
		if (curSearchRow.parent_id == null) return;

		let tableProm;

		if (fsnbTableType === "mat") {
			tableProm = apiFsnb.getFsscTableResource(LocalEstimateDataVersId, curSearchRow.parent_id);
		} else if (fsnbTableType === "mach") {
			tableProm = apiFsnb.getFsemTableResource(LocalEstimateDataVersId, curSearchRow.parent_id);
		} else if (fsnbTableType === "norm") {
			tableProm = apiFsnb.getNormTableResource(LocalEstimateDataVersId, curSearchRow.parent_id);
		} else if (fsnbTableType === "pg") {
			tableProm = apiFsnb.getFsscPgTableResource(LocalEstimateDataVersId, curSearchRow.parent_id);
		} else if (fsnbTableType === "search") {
			return;
		}
		setFetchStatus("loading");
		tableProm
			.then((response) => {
				if (response.data.length) {
					const searchIds = searchFounds.map((x) => x.id);
					const searchResIds = searchFounds.map((x) => x.res_id);
					let data = response.data;
					if (["mat", "mach", "norm", "pg"].includes(fsnbTableType)) {
						data = data.map((gpr) => {
							if (gpr._children == null) return gpr;
							gpr._children = gpr._children.map((row) => {
								if (searchIds.includes(row.id)) {
									if (["name", "code", "cost_name", "cost_code", "full_row"].includes(curSearchRow.str_clm)) {
										row.row_type_style = `selected_norm_${searchFounds.find((x) => x.id === row.id).str_clm}`;
										if (row._children) {
											row._children.map((child) => {
												if (searchIds.includes(child.id)) {
													child.row_type_style = `selected_norm_${searchFounds.find((x) => x.id === child.id).str_clm}`;
												}
												if (child.id === curSearchRow.id) {
													child.row_type_style = `cur_selected_norm_${curSearchRow.str_clm}`;
												}

												return child;
											});
										}
									}
									if (row.id === curSearchRow.id) {
										if (["name", "code", "cost_name", "cost_code"].includes(curSearchRow.str_clm)) {
											row.row_type_style = `cur_selected_norm_${curSearchRow.str_clm}`;
										}
										if (
											fsnbTableType === "norm" &&
											(["res_name", "res_code", "content_name"].includes(curSearchRow.str_clm) || curSearchRow.openChilds)
										) {
											row.resourcesVisibility = true;
											row.contentVisibility = true;
											row._children = [...arrProxy(row.norm_content), ...arrProxy(row.norm_resource)];
											row._children = [
												...row._children.map((ch) => {
													if (curSearchRow.res_id === ch.id) {
														ch.row_type_style = `${ch.row_type_style} cur_selected_norm_${curSearchRow.str_clm}`;
													} else if (searchResIds.includes(ch.id)) {
														ch.row_type_style = `${ch.row_type_style} selected_norm_${curSearchRow.str_clm}`;
													}
													return ch;
												}),
											];
										}
									}
								}
								return row;
							});
							return gpr;
						});
					}
					setTableData(data);
				}
				if (fsnbTableType === "pg") {
					setTreeData((prev) => [...resourceOpenTree(prev, { key: curSearchRow.parent_parent_id }, 0)[0]]);
					setSelectedTreeData({ key: curSearchRow.parent_parent_id });
					inputEl.current = { id: curSearchRow.parent_parent_id };
				} else {
					setTreeData((prev) => [...resourceOpenTree(prev, { key: curSearchRow.parent_id }, 0)[0]]);
					setSelectedTreeData({ key: curSearchRow.parent_id });
					inputEl.current = { id: curSearchRow.parent_id };
				}

				setFetchStatus("success");
			})
			.catch((error) => {
				console.error(error);
				setFetchStatus("failed");
			});
	}, [searchFounds, fsnbTableType, LocalEstimateDataVersId, curSearchRow]);
	/*eslint-enable */

	useEffect(() => {
		if (inputSearchValue?.length > 3) {
			callbacks.current.push(closedSearch(inputSearchValue));

			setTimeout(() => {
				if (callbacks.current.length) {
					const lastCallback = callbacks.current.slice(-1)[0];
					lastCallback();
					callbacks.current = [];
				}
			}, delay);
		}
	}, [inputSearchValue]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedSearchOptions?.length > 0 && inputSearchValue?.length > 3) handleSearch(inputSearchValue);
	}, [selectedSearchOptions?.length]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (searchObjScope?.id > 0 && inputSearchValue?.length > 3) handleSearch(inputSearchValue);
	}, [searchObjScope?.id]); // eslint-disable-line react-hooks/exhaustive-deps

	const closedSearch = (arg) => {
		const func = () => {
			handleSearch(arg);
		};
		return func;
	};

	const handleSearch = (strSearch) => {
		let searchProm;
		if (fsnbTableType === "mat") {
			searchProm = apiFsnb.getFsscTableSearch(LocalEstimateDataVersId, strSearch);
		} else if (fsnbTableType === "mach") {
			searchProm = apiFsnb.getFsemTableSearch(LocalEstimateDataVersId, strSearch);
		} else if (fsnbTableType === "pg") {
			searchProm = apiFsnb.getFsscPgTableSearch(LocalEstimateDataVersId, strSearch);
		} else if (fsnbTableType === "norm") {
			const body = {
				attr_opts: selectedSearchOptions.map((x) => x.id),
				selected_parent_tree_id: searchObjScope?.id,
				filter_on_parent_tree: searchObjScope?.id != null,
			};
			searchProm = apiFsnb.getNormTableSearch(LocalEstimateDataVersId, strSearch, body);
		}
		setFetchStatus("loading");

		searchProm
			.then((response) => {
				setCurSearchRow(response.data[0]);
				setFirstOpenDrawer(true);
				if (!response.data.length) {
					setTableData([]);
				}
				setSearchFounds(response.data);
				setFetchStatus("success");
			})
			.catch((error) => {
				console.error(error);
				setFetchStatus("failed");
				setFetchStatusText(error);
			});
	};

	let searchResult = "";
	if (searchFounds?.length) {
		if (searchFounds.length >= 1000) {
			searchResult = `Найдено: более ${searchFounds.length}`;
		} else {
			searchResult = `Найдено: ${searchFounds.length}`;
		}
	}

	const handleTreeClick = (row) => {
		setFirstOpenDrawer(true);
		inputEl.current = row;
		setTreeData((prev) => [...resourceChangeVisible(prev, row)]);
		let tableProm;
		if (dataView === "pdf") {
			setPdfData(apiFsnb.getTechPartPdfStrLink(fsnbTableType, row.id));
		} else {
			if (fsnbTableType === "norm") {
				tableProm = apiFsnb.getNormTableResource(LocalEstimateDataVersId, row.id);
			} else if (fsnbTableType === "mach") {
				tableProm = apiFsnb.getFsemTableResource(LocalEstimateDataVersId, row.id);
			} else if (fsnbTableType === "mat") {
				tableProm = apiFsnb.getFsscTableResource(LocalEstimateDataVersId, row.id);
			} else if (fsnbTableType === "pg") {
				tableProm = apiFsnb.getFsscPgTableResource(LocalEstimateDataVersId, row.id);
			}

			setFetchStatus("loading");
			tableProm
				.then((response) => {
					setTableData(response.data);
					setClickedTreeCnt((prev) => (prev += 1));
					setFetchStatus("success");
				})
				.catch((error) => {
					console.error(error);
					setFetchStatus("failed");
					setFetchStatusText(error);
				});
		}
	};

	const showMainTableMore = (row) => {
		if (row?.row_type === "group" || row?.row_type_style === "norm_name_group" || row?.row_type_style === "fake_parent") return;
		showTableMore(row, "12px", ["name"], setTableData, "FsnbTable");
	};

	const localFsnbSelectData = (table, row, target, parentRow) => {
		let newTableData = copyArr(tableData);
		if (clickedTableRow) {
			newTableData = changeNestedArrKeyValueOnCondition({
				arr: newTableData,
				keySearch: "id",
				valSearch: clickedTableRow.id,
				keyChange: "selected",
				valChange: false,
			});
		}
		newTableData = changeNestedArrKeyValueOnCondition({
			arr: newTableData,
			keySearch: "id",
			valSearch: row.id,
			keyChange: "selected",
			valChange: true,
		});
		setTableData(newTableData);
		dispatch(setClickedTableRow({ ...row, parentRow: parentRow }));
	};

	const changeChildsVisible = ({ clickedRow }) => {
		setTableData((prev) => [
			...prev.map((row) => {
				if (row.id === clickedRow.id) {
					row.childs_visible = !row.childs_visible;
					if (row._children != null) {
						row._children = row._children.map((child) => {
							child.visible = !child.visible;
							return child;
						});
					}
				} else {
					if (row._children != null) {
						row._children = row._children.map((child) => {
							if (child.id === clickedRow.id) {
								child.childs_visible = !child.childs_visible;
								if (child._children != null) {
									child._children = child._children.map((subChild) => {
										subChild.visible = !subChild.visible;
										return subChild;
									});
								}
							}
							return child;
						});
					}
				}
				return row;
			}),
		]);
	};

	const changeNormContentVisible = (clickedRow, contentType) => {
		setTableData((prev) => [
			...prev.map((gpr) => {
				if (gpr._children == null) return gpr;
				const gprCopy = { ...gpr };
				gprCopy._children = gprCopy._children.map((row) => {
					if (row.id === clickedRow.id) {
						gprCopy.isSearching = false;
						if (contentType === "content") {
							row.contentVisibility = !row.contentVisibility;
						} else if (contentType === "resources") {
							row.resourcesVisibility = !row.resourcesVisibility;
						}

						if (row.contentVisibility && row.resourcesVisibility) {
							row._children = [...arrProxy(row.norm_content), ...arrProxy(row.norm_resource)];
						} else if (row.contentVisibility && !row.resourcesVisibility) {
							row._children = [...arrProxy(row.norm_content)];
						} else if (!row.contentVisibility && row.resourcesVisibility) {
							row._children = [...arrProxy(row.norm_resource)];
						} else if (!row.contentVisibility && !row.resourcesVisibility) {
							row._children = [];
						}
					}
					return row;
				});
				return gprCopy;
			}),
		]);
	};

	const getTableColumns = () => {
		if (fsnbTableType === "norm") {
			return NormTableColumns();
		} else if (fsnbTableType === "mach") {
			if (LocalEstimateDataVersId > 7) {
				// номер с которого начинаюся версии 2022
				return FsemTableColumns2022();
			} else {
				return FsemTableColumns();
			}
		} else if (fsnbTableType === "mat") {
			return FsscTableColumns();
		} else if (fsnbTableType === "pg") {
			return FsscPgTableColumns();
		}
	};

	const getMainTable = () => {
		if (tableData.length) {
			return (
				<>
					<TableMain
						rows={{ data: tableData }}
						columns={getTableColumns()}
						options={{
							selectable: true,
							scrollBlock: scrollBlock,
							displayScrollDelay: 20,
							funcCall: "local",
						}}
						func={{
							selectDataLocal: localFsnbSelectData,
							changeVisible: changeChildsVisible,
							changeNormContentVisible: changeNormContentVisible,
							singleClickRowAction: {
								tagName: {
									allowed: ["td", "span"],
								},
								action: showMainTableMore,
							},
							doubleClickRowAction: {
								action: (rowObj, e) => {
									if (rowObj?.row_type_style === "fake_parent") {
										handleTreeClick(rowObj);
										setTreeData((prev) => [...resourceOpenTree(prev, { key: rowObj.id }, 0)[0]]);
										setSelectedTreeData({ key: rowObj.id });
									}
								},
							},
						}}
					></TableMain>
				</>
			);
		} else {
			return <FsnbBodyNoData />;
		}
	};

	const handleToggleList = (event) => setAnchorEl(event.currentTarget);

	const handleCloseList = () => setAnchorEl(null);

	const handleAttrChange = (row) => {
		setSelectedSearchOptions((prev) => {
			const inArray = prev.find((x) => x.id === row.id);
			if (inArray === undefined) {
				return [...prev, row];
			} else {
				return prev.filter((x) => x.id !== row.id);
			}
		});
	};

	const open = Boolean(anchorEl);
	const textAreaId = open ? "simple-popover" : undefined;

	return (
		<div className="EstimateFsnbDrawerWrapper">
			<div className="EstimateFsnbDrawerHeader">
				<IconButton
					title="Закрыть"
					onClick={() => {
						dispatch(setStartSearchObj(null));
						props.setFsnbVisible(false);
					}}
				>
					<ExitToAppOutlinedIcon fontSize="small" />
				</IconButton>
				<IconButton
					title="Открыть в новой вкладке"
					onClick={() => {
						const lsObj = {
							id: startSearchObj.id,
							parent_id: startSearchObj.parent_id,
							parent_parent_id: startSearchObj?.parent_parent_id,
							vers_id: startSearchObj.vers_id,
							str_clm: "code",
						};
						if (fsnbTableType === "norm") lsObj.model_name = "FsnbNorm";
						if (fsnbTableType === "mat") lsObj.model_name = "FsnbFssc";
						if (fsnbTableType === "mach") lsObj.model_name = "FsnbFsem";
						if (fsnbTableType === "pg") lsObj.model_name = "FsnbFsscPg";
						localStorage.setItem("superSearchItem", JSON.stringify(lsObj));
						window.open(`/fsnb/${fsnbTableType}/`);
					}}
				>
					<OpenInNewOutlinedIcon fontSize="small" />
				</IconButton>
				<h2 className="KsrDrawerTitle">ФСНБ</h2>

				<div className="ribbon-element-custom">
					<div className="fsnbCustomFilterGroupBottom">
						<TextField
							aria-describedby={textAreaId}
							sx={{
								marginLeft: "8px",
								width: "70%",
								"& legend": {
									fontSize: "9px",
								},
							}}
							label="Поиск"
							size="small"
							value={inputSearchValue}
							onChange={(e) => {
								if (fetchStatus !== "loading") {
									setFirstOpenDrawer(true);
									setInputSearchValue((prev) => e.target.value);
								}
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									if (fetchStatus !== "loading") {
										setInputSearchValue((prev) => e.target.value);
									}
								}
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<SearchIcon fontSize="16px" />
										{fsnbTableType === "norm" && <SettingsIcon fontSize="16px" onClick={handleToggleList} />}
									</InputAdornment>
								),
							}}
						></TextField>
						{fsnbTableType === "norm" && open && (
							<Popover
								id={textAreaId}
								open
								anchorEl={anchorEl}
								onClose={handleCloseList}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
							>
								<MenuList>
									{attrsOpts.map((opt) => (
										<MenuItem
											key={opt.id}
											title={opt.name}
											onClick={() => handleAttrChange(opt)}
											selected={selectedSearchOptions.filter((x) => x.id === opt.id).length === 1}
										>
											<Checkbox checked={selectedSearchOptions.filter((x) => x.id === opt.id).length === 1}></Checkbox>
											<ListItemText primary={opt.name} />
											<span style={{ miWidth: "8px" }} />
										</MenuItem>
									))}
								</MenuList>
							</Popover>
						)}
						{fsnbTableType === "norm" && (
							<div
								className={selectedTreeData?.id == null ? "SearchOnSelectedDisabled" : "SearchOnSelected"}
								title="Фиксировать область поиска"
								onClick={() => {
									if (!selectedTreeData?.id || searchObjScope?.id === selectedTreeData?.id) {
										setSearchObjScope({});
									} else if (selectedTreeData?.id) {
										setSearchObjScope(selectedTreeData);
									}
								}}
							>
								<ShieldOutlinedIcon />
							</div>
						)}
						{searchFounds?.length > 0 && (
							<div className="SearchNavButtons">
								<ArrowDropDownOutlinedIcon
									onClick={(e) => {
										setFirstOpenDrawer(true);
										moveNextSearch("next", searchFounds, curSearchRow, setCurSearchRow);
									}}
								/>
								<ArrowDropUpOutlinedIcon
									onClick={(e) => {
										setFirstOpenDrawer(true);
										moveNextSearch("prev", searchFounds, curSearchRow, setCurSearchRow);
									}}
								/>
							</div>
						)}
						<div className="SearchResults">{searchResult}</div>
					</div>
				</div>

				<div className="EstimateFsnbDrawerRadio">
					<FormControl>
						<RadioGroup row>
							<FormControlLabel
								value="table"
								checked={dataView === "table"}
								label="Смет. норм."
								onClick={(e) => setDataView("table")}
								control={<Radio size="small" />}
							/>
							<FormControlLabel
								value="pdf"
								checked={dataView === "pdf"}
								disabled={fsnbTableType === "pg"}
								label="Тех. часть."
								onClick={(e) => {
									setDataView("pdf");
									setPdfData(apiFsnb.getTechPartPdfStrLink(fsnbTableType, selectedTreeData.key));
								}}
								control={<Radio size="small" />}
							/>
						</RadioGroup>
					</FormControl>
				</div>
			</div>

			<div className="EstimateFsnbDrawerMainWrapper">
				<div className="FsnbTree">
					{treeData?.length ? (
						<FsnbTree
							data={treeData}
							selected={selectedTreeData}
							searchObjScope={searchObjScope}
							f_setSelectedTreeData={setSelectedTreeData}
							f_changeChildsVisible={handleTreeClick}
							f_setSearchObjScope={setSearchObjScope}
							propsPadLeft={4}
						/>
					) : (
						<div></div>
					)}
				</div>

				<div className="FsnbTable" ref={scrollBlock} table_type={fsnbTableType}>
					{dataView === "table" && getMainTable()}
					{dataView === "pdf" && (
						<Suspense style={{ maxHeight: "200px" }} fallback={" "}>
							<FsnbTechPartsMain
								fetchStatus={fetchStatus}
								tabValue={fsnbTableType}
								pdfData={pdfData}
								f_setFetchStatus={setFetchStatus}
								height="98%"
								width="95%"
							/>
						</Suspense>
					)}
				</div>
			</div>
			<AlertLoading fetchStatus={fetchStatus} fetchStatusText={fetchStatusText} func={setFetchStatus} isLocal={true} left={"60%"} />
		</div>
	);
};

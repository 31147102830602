const FillColorSeparateIcon = ({ primaryColor, secondaryColor }) => {
    let primaryColorDefined = "rgba(0,0,0,.54)";
    if (primaryColor) primaryColorDefined = primaryColor

    let secondaryColorDefined = "rgba(0,0,0,.54)";
    if (secondaryColor) secondaryColorDefined = secondaryColor
    
    return (
        <svg
            style={{width: "1em", height: "1em"}}
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="FillColorSeparateIcon">
            <path
                d="M 16.56 8.94 L 7.62 0 L 6.21 1.41 l 2.38 2.38 l -5.15 5.15 c -0.59 0.59 -0.59 1.54 0 2.12 l 5.5 5.5 c 0.29 0.29 0.68 0.44 1.06 0.44 s 0.77 -0.15 1.06 -0.44 l 5.5 -5.5 c 0.59 -0.58 0.59 -1.53 0 -2.12 z M 5.21 10 L 10 5.21 L 14.79 10 H 5.21 z M 19 11.5 s -2 2.17 -2 3.5 c 0 1.1 0.9 2 2 2 s 2 -0.9 2 -2 c 0 -1.33 -2 -3.5 -2 -3.5 z"
                fill={primaryColorDefined}></path>
            <path d="M 16.56 8.94 M 2 20 h 20 v 4 H 2 v -4 z" fill={secondaryColorDefined}></path>
        </svg>
    );
};

export default FillColorSeparateIcon;

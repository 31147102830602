import { useDispatch } from "react-redux";

import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";

import { fetchProjectErrorsExcel } from "slices/project_work";

import "./CustomExportProtocolCell.css";

const CustomExportProtocolCell = ({ rowObj, funcs }) => {
	const dispatch = useDispatch();

	const getCellInnerDom = () => {
		if (rowObj.model_name !== "UserProjects") return;
		return (
			<div title="Протокол проверки" className="TreeActionCell" onClick={() => dispatch(fetchProjectErrorsExcel(rowObj.id))}>
				<FileOpenOutlinedIcon />
			</div>
		);
	};
	return (
		<td key={`cell-export_protocol-${rowObj.id}`} className="TableCell" clm="export_protocol" treecell="false">
			{getCellInnerDom()}
		</td>
	);
};

export default CustomExportProtocolCell;

import { apiBase } from "./base";

const apiProtocol = {
	client: apiBase.get_client(),
	url: "/smetdocs/protocol/",

	get(type, id) {
		return this.client.get(this.url + type + "/" + id + "/", apiBase.get_headers());
	},

	getExcel(type, id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + type + "/" + id + "/excel/", headers);
	},

	getSelectedTypes(projectId) {
		return this.client.get(this.url + "selected_types/" + projectId + "/", apiBase.get_headers());
	},

	getUserSetCritOnly(user_id, project_id) {
		return this.client.get("projects/user_set_protocol_critical_only/" + user_id + "/" + project_id + "/", apiBase.get_headers());
	},

	putUserSetCritOnly(user_id, project_id, body) {
		return this.client.put("projects/user_set_protocol_critical_only/" + user_id + "/" + project_id + "/", body, apiBase.get_headers());
	},

	getUserSetGpr(user_id, project_id) {
		return this.client.get("projects/user_set_grp_visible/" + user_id + "/" + project_id + "/", apiBase.get_headers());
	},

	postUserSetGpr(user_id, project_id, body) {
		return this.client.post("projects/user_set_grp_visible/" + user_id + "/" + project_id + "/", body, apiBase.get_headers());
	},

	getUserSetExcept(user_id, project_id) {
		return this.client.get("projects/user_set_exceptions/" + user_id + "/" + project_id + "/", apiBase.get_headers());
	},

	postUserSetExcept(user_id, project_id, body) {
		return this.client.post("projects/user_set_exceptions/" + user_id + "/" + project_id + "/", body, apiBase.get_headers());
	},

	delUserSetExcept(user_id, project_id, id) {
		return this.client.delete("projects/user_set_exceptions/" + user_id + "/" + project_id + "/" + id + "/", apiBase.get_headers());
	},

	getUserSetAllowErPerc(user_id, project_id) {
		return this.client.get("projects/user_set_protocol_allowed_er_perc/" + user_id + "/" + project_id + "/", apiBase.get_headers());
	},

	putUserSetAllowErPerc(user_id, project_id, body) {
		return this.client.put("projects/user_set_protocol_allowed_er_perc/" + user_id + "/" + project_id + "/", body, apiBase.get_headers());
	},

	getUserSetErrorStatus(user_id, project_id) {
		return this.client.get("projects/user_set_error_status/" + user_id + "/" + project_id + "/", apiBase.get_headers());
	},

	postUserSetErrorStatus(user_id, project_id, body) {
		return this.client.post("projects/user_set_error_status/" + user_id + "/" + project_id + "/", body, apiBase.get_headers());
	},

	deleteProtocolError({ projectId, estmType, errorId }) {
		return this.client.delete(this.url + "errors/" + projectId + "/" + estmType + "/" + errorId + "/", apiBase.get_headers());
	},
};

export default apiProtocol;

import React from "react";
import RibbonSubGroup from "./RibbonSubGroup.jsx";

const RibbonGroup = ({ groupSettings }) => {
	const { name, styles, stylesGroupWrapper, _children, attr } = groupSettings;

	return (
		<div className="ribbon-group" style={styles} attr={attr}>
			<div className="ribbonGroupWrapper" style={stylesGroupWrapper}>
				{_children.map((subGroup, index) => {
					if (subGroup.isVisible === false) {
						return null;
					} else {
						return <RibbonSubGroup key={index} subGroupSettings={subGroup} />;
					}
				})}
			</div>
			<h3>{name}</h3>
		</div>
	);
};

export default RibbonGroup;

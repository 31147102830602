import { useState, useEffect, useRef, useLayoutEffect, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import LabelNormWithUnder from "components/UI/LabelNormWithUnder";
import { LabelTwoVerticalLevel } from "components/UI/labels";
import LabelNormWithUnderTwoSides from "components/UI/LabelNormWithUnderTwoSides";
import TableMain from "components/Table/TableMain";
import { TableDetailsHead, TableDetailsRow } from "components/Table/TableDetails/TableDetails";
import AlertLoading, { selectFetchStatus } from "components/AlertLoading";
import HeaderVisibleButton from "components/HeaderVisibleButton";
import { EstimateFsnbDrawer } from "./components/EstimateFsnbDrawer/EstimateFsnbDrawer.jsx";
import FsnbCoefBox from "pages/Fsnb/components/FsnbCoefBox";
import FsnbTechGrpBox from "pages/Fsnb/components/FsnbTechGrpBox";
import { searchNestedIterableItem } from "core/iterable_utils";

import { EstmHeaderTitleButtons } from "pages/EstimateViewer/components";

import {
	getLocaltEstimateTree,
	changeLocEstmVisible,
	changeLocEstmErrorsVisible,
	handleProtectObj,
	handleUnProtectObj,
	calcLocalEstimateCache,
	protectLocEstmFull,
	setLocalFetchStatus,
	setLocalFetchStatusText,
	applyAdvSearchSelected,
	addCostContent,
	getLocalEstimateTreeWithOutRefresh,
	hideCostContent,
	changeRowData,
} from "slices/local_estimate";
import { setFetchStatus as setProjFetchStatus, setFetchStatusText as setProjFetchStatusText, forceFetchProjectTree } from "slices/project_work";
import { setFetchStatus as setProtFetchStatus, postUserSetExcept, setReGetProtocol } from "slices/protocol";
import {
	setCurrentPage,
	setCurrentEstmDocId,
	setClickedTableRow,
	setClickedTableClmName,
	setLastActiveScroll,
	clearClickedTableCtrlRows,
} from "slices/ribbon";
import { setContextMenuPosition, setContextMenuObj, setIsContextMenuOpen } from "slices/context_menu";
import { setActiveRow } from "slices/adv_search";
import { setStartSearchObj } from "slices/fsnb";

import apiFsnb from "api/fsnb";
import apiKatsEstimate from "api/kats";
import apiLocalEstm from "api/local_estimate";

import { RecourseCalcPosition, ScrollLocEstmToPos } from "../components/AdvSearchBox/apply_adv_search";

import LocalEstimateTableColumnsRim from "./LocalEstimateTableColumnsRim";
import LocalEstimateTableColumnsBim from "./LocalEstimateTableColumnsBim";
import EstimatePricesHead from "./EstimatePricesHead";
import EstimateInfoHead from "./EstimateInfoHead";
import CostSourceFormulas from "./modal/CostSourceFormulas";
import CustomDetailsComments from "./components/CustomDetails/CustomDetailsComments";
import CustomDetailsRowComment from "./components/CustomDetails/CustomDetailsRowComment";
import { LocalEstmFooterSignatures } from "./components";
import { useLocalStorage } from "hooks/useLocalStorage";

import { calcRowHeightMultiplier } from "./utils/row_utils";

import { pushToClickedRows, removeFromClickedRows, clearClickedRows } from "components/Footer/aggFuncs";

import "pages/EstimateViewer/TableOuterBox.css";
import "./LocalEstimateViewer.css";

const JustificationModalBoxShort = lazy(() => import("pages/EstimateViewer/components/JustificationModalBox/JustificationModalBoxShort"));
const JustificationModalCalcJust = lazy(() => import("pages/EstimateViewer/components/JustificationModalBox/JustificationModalCalcJust"));
const JustificationUnionModalBox = lazy(() => import("pages/EstimateViewer/components/JustificationModalBox/JustificationUnionModalBox"));

const SlideDrawer = lazy(() => import("components/Drawer/Drawer.tsx"));

export default function LocalEstimateViewer(props) {
	const dispatch = useDispatch();

	const [fullVisible, setFullVisible] = useState(true);
	let data = useSelector((state) => state.localEstimate.data);
	const proj_main = useSelector((state) => state.projectWork.main);
	const rows_data = useSelector((state) => state.localEstimate.tableData);
	const treeControlData = useSelector((state) => state.localEstimate.treeControlData);
	const fetchStatus = useSelector((state) => state.localEstimate.fetchStatus);
	const fetchStatusProtocol = useSelector((state) => state.protocol.fetchStatus);
	const fetchStatusText = useSelector((state) => state.localEstimate.fetchStatusText);
	const fetchTreeStatus = useSelector((state) => state.projectWork.fetchTreeStatus);
	const advSearchActiveRow = useSelector((state) => state.advSearch.activeRow);
	const currentEstmDocId = useSelector((state) => state.ribbon.currentEstmDocId);

	const advSearchActiveColorRow = useSelector((state) => state.advSearch.activeColorRow);

	const currentPage = useSelector((state) => state.ribbon.currentPage);
	const lastEstimateCurrentPage = useSelector((state) => state.ribbon.lastEstimateCurrentPage);
	const lastActiveScroll = useSelector((state) => state.ribbon.lastActiveScroll);
	const clickedTablePrevRow = useSelector((state) => state.ribbon.clickedTablePrevRow);
	const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const clickedTableCtrlRows = useSelector((state) => state.ribbon.clickedTableCtrlRows);
	const clickedTableClmName = useSelector((state) => state.ribbon.clickedTableClmName);
	const currentProjectID = useSelector((state) => state.ribbon.currentProjectID);

	const reGetProtocol = useSelector((state) => state.protocol.reGetProtocol);

	const headBlock1 = useRef();
	const headBlock2 = useRef();
	const headBlock3 = useRef();
	const fsnbEstmDom = useRef();

	const { id } = useParams();
	const localFetchStatus = selectFetchStatus([fetchStatus, fetchTreeStatus, fetchStatusProtocol]);

	const [justBoxVisible, setJustBoxVisible] = useState(false);
	const [justBoxVisibleShort, setJustBoxVisibleShort] = useState(false);
	const [justBoxVisibleCalcJust, setJustBoxVisibleCalcJust] = useState(false);
	const [justData, setJustData] = useState(null);
	const [justXmlData, setJustXmlData] = useState(null);
	const [justCalcJustData, setJustCalcJustData] = useState(null);

	const [coefBoxVisible, setCoefBoxVisible] = useState(false);
	const [coefRow, setCoefRow] = useState({});

	const [techGprBoxVisible, setTechGprBoxVisible] = useState(false);
	const [techGrpRow, setTechGrpRow] = useState({});

	const [costFormulasVisible, setCostFormulasVisible] = useState(false);
	const [costFormulasRow, setCostFormulasRow] = useState({});

	const { removeLsItem } = useLocalStorage();

	const coefBoxWidth = useRef("90%");

	let columns_data = [];
	if (data != null) {
		if (data.hasOwnProperty("methodic")) {
			if (data["methodic"] === "РИМ") {
				columns_data = LocalEstimateTableColumnsRim();
			} else if (data["methodic"] === "БИМ") {
				columns_data = LocalEstimateTableColumnsBim();
			}
		}
	} else {
		data = {};
	}

	useLayoutEffect(() => {
		dispatch(setCurrentEstmDocId(false));
		dispatch(setCurrentPage("LocalEstimateViewer"));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (id === currentEstmDocId && rows_data.length && lastEstimateCurrentPage === "LocalEstimateViewer") {
			props.scrollBlock.current.scrollTop = lastActiveScroll;
		} else {
			const prom = dispatch(getLocaltEstimateTree(id));
			prom.then(() => {
				dispatch(applyAdvSearchSelected());
			});
		}
		dispatch(setCurrentEstmDocId(id));
		//   dispatch(setLastActiveId(0));
		dispatch(setLastActiveScroll(0));

		return () => {
			dispatch(clearClickedTableCtrlRows());
		};
	}, [id]); // eslint-disable-line react-hooks/exhaustive-deps

	useLayoutEffect(() => {
		if (reGetProtocol === true) {
			dispatch(getLocaltEstimateTree(id));
			dispatch(setReGetProtocol(false));
		}
	}, [reGetProtocol]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let erVis = null;
		if (data["fsnb_errors"] != null) {
			if (data["fsnb_errors"].length) {
				erVis = true;
			}
		}
		props.setErrorsVisible(erVis);
		return () => {
			if (fsnbEstmDom["current"] != null) {
				fsnbEstmDom["current"].style.maxHeight = 0;
			}
			props.setErrorsVisible(null);
		};
	}, [data]); // eslint-disable-line react-hooks/exhaustive-deps

	// Скрол к найденной позиции в advSearch
	useEffect(() => {
		if (currentPage !== "LocalEstimateViewer") return;
		if (rows_data.length && Object.keys(advSearchActiveRow).length && props.scrollBlock["current"] != null) {
			const { rowCnt, isFound } = RecourseCalcPosition(rows_data, advSearchActiveRow, {
				rowCnt: 0,
				isFound: false,
			});
			if (isFound) {
				removeLsItem({ key: "displayScrollTop" });
				ScrollLocEstmToPos(props.scrollBlock, rowCnt, 610);
				dispatch(setActiveRow({}));
			}
		}
	}, [advSearchActiveRow, rows_data, props.scrollBlock]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleSetFullVisible = () => {
		for (let head of [headBlock1, headBlock3]) {
			if (fullVisible === true) {
				if (head["current"] != null) head["current"].style.maxHeight = 0;
				setFullVisible(false);
			} else {
				if (head["current"] != null) head["current"].style.maxHeight = "270px";
				setFullVisible(true);
			}
		}
	};

	if (props.errorsVisible === true) {
		fsnbEstmDom["current"].style.maxHeight = "0px";
	} else if (props.errorsVisible === false) {
		if (data["fsnb_errors"] != null) {
			if (data["fsnb_errors"].length > 0) {
				fsnbEstmDom["current"].style.maxHeight = (data["fsnb_errors"].length + 1) * 22 + "px";
			}
		}
	}

	const lsrProtection = (row) => {
		let promise;
		if (row.hasOwnProperty("protected_id")) {
			promise = dispatch(handleUnProtectObj(row["protected_id"]));
		} else {
			promise = dispatch(
				handleProtectObj({
					estm_id: id,
					item_id: row["item_id"],
					object_id: row["id"],
					model_name: row["model_name"],
				})
			);
		}
		promise.then((resp) => {
			const prom = dispatch(calcLocalEstimateCache({ id: id, item_id: row["item_id"] }));
			prom.then(() => dispatch(forceFetchProjectTree(proj_main["id"])));
			dispatch(
				changeRowData({
					rowKey: row.key,
					callback: (x) => (x.selected = true),
				})
			);
			dispatch(
				setClickedTableRow({
					...clickedTableRow,
					protected_id: resp?.payload?.id,
				})
			);
		});
	};

	props.lowerCallbacks.current.local["lsrProtection"] = lsrProtection;

	const handleAddException = (row) => {
		const body = {
			user_id: localStorage.getItem("userId"),
			project_id: proj_main["id"],
			except_str: row["state_text"],
			parent_key: row?.parentRow?.key,
		};
		const promise = dispatch(postUserSetExcept(body));
		promise
			.then((x) => dispatch(getLocalEstimateTreeWithOutRefresh(id)))
			.then((data) => dispatch(changeLocEstmErrorsVisible({ val: true, row: row.parentRow })))
			.catch((err) => console.error(err));
	};

	const handleAddCostContent = (row) => {
		if (row._children.filter((x) => x.model_name === "CostContent").length > 0 || row.hasCostContent === true) {
			dispatch(hideCostContent({ rowKey: row.key }));
		} else {
			const prom = new Promise((resolve, reject) => {
				dispatch(setLocalFetchStatus("loading"));
				apiFsnb
					.getCostContent(data.fsnb_vers_id, row.parentRow.code.split(" ")[0], row.parentRow.code.split(" ")[1])
					.then((response) => {
						resolve(response.data);
					})
					.catch((error) => {
						reject(error.message);
					});
			});
			prom.then((data) => {
				dispatch(addCostContent({ rowKey: row.key, costContents: data }));
				dispatch(setLocalFetchStatus("success"));
			}).catch((error) => {
				console.error(error);
				dispatch(setLocalFetchStatus("failed"));
				dispatch(setLocalFetchStatusText(`Нет состава работ`));
				dispatch(setProjFetchStatusText(`Нет состава работ`));
			});
		}
	};

	props.lowerCallbacks.current.local["handleAddCostContent"] = handleAddCostContent;

	const showErrorJustification = (row) => {
		dispatch(setLocalFetchStatus("loading"));
		const justPromise = new Promise((resolve, reject) => {
			apiLocalEstm
				.getErrorJustification(row.id)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.message));
		});
		const xmlPromise = new Promise((resolve, reject) => {
			apiLocalEstm
				.getInputXmlPartOnError(row.id)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.message));
		});
		Promise.all([justPromise, xmlPromise])
			.then((response_arrs) => {
				setJustData(response_arrs[0]);
				setJustBoxVisible(true);
				setJustXmlData(response_arrs[1]);
				dispatch(setLocalFetchStatus("success"));
			})
			.catch((error) => {
				console.error(error);
				dispatch(setLocalFetchStatus("failed"));
			});
	};

	const showErrorJustificationShort = ({ obj }) => {
		dispatch(setLocalFetchStatus("loading"));
		let prom;
		if (obj === "item") {
			prom = apiLocalEstm.getInputXmlPartItem(id, clickedTableRow.item_id);
		} else if (obj === "section") {
			prom = apiLocalEstm.getInputXmlPartSection(id, clickedTableRow.section_id);
		} else if (obj === "estimate") {
			prom = apiLocalEstm.getInputXmlPartEstimatePrice(id);
		}
		prom.then((response) => {
			setJustBoxVisibleShort(true);
			setJustXmlData(response.data);
			dispatch(setLocalFetchStatus("success"));
		}).catch((error) => {
			console.error(error);
			dispatch(setLocalFetchStatus("failed"));
		});
	};

	props.lowerCallbacks.current.local["showErrorJustificationShort"] = showErrorJustificationShort;

	const showLogJustification = ({ obj }) => {
		dispatch(setLocalFetchStatus("loading"));
		let prom;
		if (obj === "item") {
			prom = apiLocalEstm.getJustificationLogItem(clickedTableRow.item_id);
		} else if (obj === "section") {
			prom = apiLocalEstm.getJustificationLogSection(clickedTableRow.section_id);
		} else if (obj === "estimate") {
			prom = apiLocalEstm.getJustificationLogEstimatePrice(id);
		} else if (obj === "pnr") {
			prom = apiLocalEstm.getJustificationLogPnr(id);
		}
		prom.then((response_arrs) => {
			console.log(response_arrs);
			setJustBoxVisibleCalcJust(true);
			setJustCalcJustData(response_arrs.data);
			dispatch(setLocalFetchStatus("success"));
		}).catch((error) => {
			console.error(error);
			dispatch(setLocalFetchStatus("failed"));
		});
	};

	props.lowerCallbacks.current.local["showLogJustification"] = showLogJustification;

	const openFsnbCatalog = (row) => {
		const search_clm = "full_row";

		apiFsnb
			.getNormIdOnTableRow(data.fsnb_vers_id, row.id, row.model_name)
			.then((response) => {
				return response.data;
			})
			.then((obj) => {
				return dispatch(setStartSearchObj({ ...obj, vers_id: data.fsnb_vers_id, str_clm: search_clm }));
			})
			.then((x) => {
				props.onClickShowFsnb();
			})
			.catch((error) => {
				dispatch(setLocalFetchStatus("failed"));
				dispatch(setLocalFetchStatusText(`Выбранный ресурс отсутствует в базе ФСНБ`));
				dispatch(setProjFetchStatusText(`Выбранный ресурс отсутствует в базе ФСНБ`));
				console.error(error);
			});
	};

	const openKats = (row) => {
		let katsMapping;
		dispatch(setLocalFetchStatus("loading"));
		apiKatsEstimate
			.getKatsMapper(currentProjectID, row.code, row.name)
			.then((response) => {
				katsMapping = response.data;
				return response.data;
			})
			.then((data) => {
				return apiKatsEstimate.getTree(data[0].kats_id);
			})
			.then((res) => {
				const tableData = res.data.table_data;
				const displayScrollTop = searchNestedIterableItem({
					iterable: tableData,
					isSeekRowCallback: (item) => {
						if (item.key && item.key === katsMapping[0].key) {
							return true;
						} else {
							return false;
						}
					},
					isPermittedRowCallback: () => true,
					isPermittedChildsLoopCallback: () => true,
				});

				localStorage.setItem("displayScrollTop", displayScrollTop.seekCount);
				localStorage.setItem("highlightRowClm", "name");
				localStorage.setItem("scrollKey", katsMapping[0].key);
				dispatch(setLocalFetchStatus("success"));
				window.open(`/project/${currentProjectID}/kats/${katsMapping[0].kats_id}`);
			})
			.catch((error) => {
				dispatch(setLocalFetchStatus("failed"));
				dispatch(setLocalFetchStatusText(`Выбранный ресурс отсутствует в КА`));
				dispatch(setProjFetchStatusText(`Выбранный ресурс отсутствует в КА`));
				console.error(error);
			});
	};

	props.lowerCallbacks.current.local["openFsnbCatalog"] = openFsnbCatalog;
	props.lowerCallbacks.current.local["openKats"] = openKats;

	const showCoefs = (row) => {
		coefBoxWidth.current = "90%";
		apiFsnb
			.getNormIdOnTableRow(data.fsnb_vers_id, row.id, row.model_name)
			.then((response) => {
				setCoefRow(response.data);
				setCoefBoxVisible(true);
			})
			.catch((error) => {
				dispatch(setLocalFetchStatus("failed"));
				dispatch(setLocalFetchStatusText("Выбранный ресурс отсутствует в базе ФСНБ"));
				dispatch(setProjFetchStatusText(`Выбранный ресурс отсутствует в базе ФСНБ`));
				console.error(error);
			});
	};

	props.lowerCallbacks.current.local["showCoefs"] = showCoefs;

	const showCoefsOnError = (row) => {
		coefBoxWidth.current = "calc(90% - 350px)";
		apiFsnb
			.getNormIdOnErrorRow(row.id)
			.then((response) => {
				setCoefRow(response.data);
				setCoefBoxVisible(true);
			})
			.catch((error) => {
				dispatch(setLocalFetchStatus("failed"));
				console.error(error);
			});
	};

	const openTechGrp = (row) => {
		apiFsnb
			.getNormResIdOnTableRow(data.fsnb_vers_id, row.id)
			.then((response) => {
				setTechGrpRow(response.data);
				setTechGprBoxVisible(true);
			})
			.catch((error) => {
				dispatch(setLocalFetchStatus("failed"));
				dispatch(setLocalFetchStatusText(`Выбранный ресурс отсутствует в базе ФСНБ`));
				console.error(error);
			});
	};

	const showMoreRow = (row, event) => {
		if (event?.ctrlKey) return;
		const rowHeightMultiplier = calcRowHeightMultiplier(row, columns_data);
		dispatch(
			changeRowData({
				rowKey: row.key,
				callback: (x) => {
					if (x.rowHeightMultiply == null && rowHeightMultiplier > 1) {
						x.rowHeightMultiply = rowHeightMultiplier;
					} else {
						x.rowHeightMultiply = null;
					}
				},
			})
		);
	};

	const showSourceCostFormulas = (row, event) => {
		setCostFormulasRow({ row: row, event: event });
		setCostFormulasVisible(true);
	};

	useEffect(() => {
		if (clickedTablePrevRow) {
			dispatch(
				changeRowData({
					rowKey: clickedTablePrevRow.key,
					callback: (row) => (row.selected = false),
				})
			);
		}
		if (clickedTableRow) {
			dispatch(
				changeRowData({
					rowKey: clickedTableRow.key,
					callback: (row) => (row.selected = true),
				})
			);
		}
	}, [clickedTableRow]); // eslint-disable-line react-hooks/exhaustive-deps

	const localEstmSelectData = (table, row, target, parentRow, event) => {
		const clmName = target?.attributes?.clm?.value;
		if (event?.ctrlKey) {
			if (clickedTableCtrlRows.filter((x) => x.key === row.key && x.pressedClm === clmName).length > 0) {
				removeFromClickedRows({ rowObj: row, pressedClm: clmName, changeRowData, dispatch });
			} else {
				pushToClickedRows({ rowObj: row, pressedClm: clmName, changeRowData, dispatch });
			}
		} else {
			clearClickedRows({ clickedTableCtrlRows, changeRowData, dispatch });
			dispatch(setClickedTableRow({ ...row, parentRow: parentRow }));
			dispatch(setClickedTableClmName(clmName));
		}
	};

	const showComments = ({ val, targetRowObj, fetchData }) => {
		let rowObj = clickedTableRow;
		if (targetRowObj) rowObj = targetRowObj;

		if (val === true || fetchData === true) {
			dispatch(setLocalFetchStatus("loading"));
			const prom = apiLocalEstm.getRowComments({ estmId: id, key: rowObj.key });
			prom.then((response) => {
				dispatch(
					changeRowData({
						rowKey: rowObj.key,
						callback: (x) => {
							x.showComments = val;
							x._comments = response.data;
							if (response.data.length > 0) {
								x._has_comment = true;
								x._comments_cnt = response.data.length;
								x._comments = response.data;
							} else {
								x._has_comment = false;
							}
							return x;
						},
					})
				);
				dispatch(setClickedTableRow({ ...rowObj, showComments: val }));
				dispatch(setLocalFetchStatus("success"));
			}).catch((error) => {
				console.error(error);
				dispatch(setLocalFetchStatus("failed"));
			});
		} else if (val === false) {
			dispatch(
				changeRowData({
					rowKey: rowObj.key,
					callback: (x) => {
						x.showComments = val;
						x._has_comment = true;
						return x;
					},
				})
			);
			dispatch(setClickedTableRow({ ...rowObj, showComments: false }));
		}
	};

	props.lowerCallbacks.current.local["showComments"] = showComments;

	const customRowDetailFactory = ({ rowObj, options, funcs }) => {
		if (rowObj.showComments === true && clickedTableRow) {
			return (
				<CustomDetailsComments
					rowObj={rowObj}
					colSpan={13}
					options={options}
					funcs={funcs}
					setVisible={(val, targetRowObj) => showComments({ val: val, targetRowObj: targetRowObj })}
					api={apiLocalEstm}
					postInitBody={{
						project_id: proj_main.id,
						estm_id: id,
						section_id: clickedTableRow.section_id === "" ? null : clickedTableRow.section_id,
						item_id: clickedTableRow.item_id === "" ? null : clickedTableRow.item_id,
						key: clickedTableRow.key,
					}}
					setFetchStatus={(val) => dispatch(setLocalFetchStatus(val))}
					setFetchStatusText={(text) => dispatch(setProjFetchStatusText(text))}
					successPostAct={() => {
						showComments({ val: false, targetRowObj: rowObj, fetchData: true });
					}}
				/>
			);
		} else if (rowObj._comments && rowObj._comments?.length > 0) {
			const commentTrs = [];
			for (let comment of rowObj._comments) {
				commentTrs.push(<CustomDetailsRowComment rowObj={comment} colSpan={13} key={`com-${comment?.id}`} />);
			}
			return commentTrs;
		}
	};

	const onContextMenuClick = (event, rowObj, parentRow) => {
		event.preventDefault();

		dispatch(setClickedTableRow({ ...rowObj, parentRow: parentRow }));
		dispatch(setClickedTableClmName(event?.target?.attributes?.clm?.value));

		dispatch(setContextMenuPosition({ event }));
		dispatch(setContextMenuObj(rowObj));
		dispatch(setIsContextMenuOpen(true));
	};

	const getAlertShowPermisson = () => {
		if (props.parentFetchStatus === "failed" && localFetchStatus === "failed") {
			return false;
		}
		return true;
	};

	return (
		<>
			<div className="TableOuterBox" type="LocalEstimate" id="TableOuterBox">
				<div className="TableOuterHead">
					<div ref={headBlock1} className="OuterHeadHidable" id="OuterHeadHidableLocalTop">
						<EstimateInfoHead data={data} />
					</div>
					<div className="TableOuterHeadMainTitle" ref={headBlock2}>
						<div className="HeadFlex">
							<div className="TitleLeft"></div>
							<div className="TitleCenter">
								<span>ЛОКАЛЬНЫЙ СМЕТНЫЙ РАСЧЕТ (СМЕТА) № {data["num"]}</span>
								<HeaderVisibleButton fullVisible={fullVisible} f_handleVisible={handleSetFullVisible} />
							</div>
							<div className="TitleRight">
								<EstmHeaderTitleButtons
									data={data}
									id={id}
									estm_type="lsr"
									errorsVisible={props.errorsVisible}
									f_setErrorsVisible={props.setErrorsVisible}
									f_protect={protectLocEstmFull}
								/>
							</div>
						</div>
						<div className="LocEstmHeadError" ref={fsnbEstmDom}>
							<TableDetailsHead onlyContent={true} />
							{data["fsnb_errors"] != null
								? data["fsnb_errors"].map((row, i) => {
										return <TableDetailsRow row_dct={row} key={"fsnb-errors-" + i} onlyContent={true} />;
								  })
								: null}
						</div>
					</div>

					<div ref={headBlock3} className="OuterHeadHidable" id="OuterHeadHidableLocalBottom">
						<LabelTwoVerticalLevel main={data["estm_name"]} second="(наименование работ и затрат)" />
						<LabelNormWithUnderTwoSides
							norm_start="Составлен"
							under={data["methodic_name"]}
							norm_end="методом"
							under_width="300px"
							norm_margin_top="-20px"
						/>
						<LabelNormWithUnder
							type="under-fixed-width"
							norm={"Основание"}
							norm_bold={false}
							under={data["estm_reason"]}
							useColon={false}
						/>
						<LabelNormWithUnder
							type="under-fixed-width"
							norm={data["methodic"] === "РИМ" ? "Составлен(а) в текущем уровне цен" : "Составлен(а) в текущем (базисном) уровне цен"}
							norm_bold={true}
							under={data["estm_prclvl_cur_str"]}
						/>
						<EstimatePricesHead data={data} />
						<div className="TableOuterHeadFlex">
							<div></div>
							<div className="TableOuterHeadPanel"></div>
						</div>
					</div>

					<TableMain
						rows={{ data: rows_data }}
						columns={columns_data}
						options={{
							selectable: true,
							treeControlData: treeControlData,
							scrollBlock: props.scrollBlock,
							clickedTableClmName: clickedTableClmName,
							headBlocks: [headBlock1, headBlock2, headBlock3],
							addH: 435,
							displayScrollDelay: 30,
							scrollHeightControll: 250,
							tableType: "local",
							advSeachActiveRow: advSearchActiveColorRow,
							targerRowHeight: 19.5,
							customRowDetail: {
								Jsx: ({ rowObj, options, funcs }) => customRowDetailFactory({ rowObj, options, funcs }),
								emptyRowCount: ({ rowObj }) => (rowObj.showComments === true ? 11 : 0),
								allowAdding: ({ rowObj }) => (rowObj.showComments === true || rowObj?._comments_cnt > 0 ? true : false),
							},
						}}
						func={{
							selectDataLocal: localEstmSelectData,
							changeVisible: changeLocEstmVisible,
							changeErrorsDetailsVisible: changeLocEstmErrorsVisible,
							lsrProtection: lsrProtection,
							addExcept: handleAddException,
							addCostContent: handleAddCostContent,
							setActiveRow: setActiveRow,
							showErrorJustification: showErrorJustification,
							openFsnbCatalog: openFsnbCatalog,
							changeRowData: changeRowData,
							showCoefs: showCoefs,
							showCoefsOnError: showCoefsOnError,
							openTechGrp: openTechGrp,
							showSourceCostFormulas: showSourceCostFormulas,
							showComments: showComments,
							singleClickRowAction: {
								tagName: {
									allowed: ["td", "span"],
								},
								action: showMoreRow,
							},
							changeVisibleOptions: {
								tagName: {
									allowed: ["svg", "path"],
								},
							},
							onContextMenuRowAction: {
								action: onContextMenuClick,
							},
						}}
					></TableMain>

					{rows_data != null && rows_data.length ? <LocalEstmFooterSignatures data={data} /> : <></>}

					{getAlertShowPermisson() && (
						<AlertLoading
							fetchStatus={localFetchStatus}
							fetchStatusText={fetchStatusText}
							func={[setLocalFetchStatus, setProjFetchStatus, setProtFetchStatus]}
						/>
					)}
					{justBoxVisible && (
						<Suspense>
							<JustificationUnionModalBox justData={justData} xmlData={justXmlData} estmId={id} f_visible={setJustBoxVisible} />
						</Suspense>
					)}
					{justBoxVisibleShort && (
						<Suspense>
							<JustificationModalBoxShort xmlData={justXmlData} estmId={id} f_visible={setJustBoxVisibleShort} />
						</Suspense>
					)}
					{justBoxVisibleCalcJust && (
						<Suspense>
							<JustificationModalCalcJust data={justCalcJustData} estmId={id} f_visible={setJustBoxVisibleCalcJust} />
						</Suspense>
					)}
					{coefBoxVisible && (
						<FsnbCoefBox
							width={coefBoxWidth.current}
							row={coefRow}
							vers={{ id: data.fsnb_vers_id }}
							showNrsp={true}
							f_closeCoefBox={() => setCoefBoxVisible(false)}
						/>
					)}
					{techGprBoxVisible && (
						<FsnbTechGrpBox vers={{ id: data.fsnb_vers_id }} row={techGrpRow} f_closeCoefBox={() => setTechGprBoxVisible(false)} />
					)}
					{costFormulasVisible && <CostSourceFormulas data={costFormulasRow} f_closeBox={() => setCostFormulasVisible(false)} />}
					<br></br>
				</div>
			</div>

			{props.fsnbVisible && (
				<Suspense fallback={" "}>
					<SlideDrawer
						isVisible={props.fsnbVisible}
						direction={"left"}
						width={"65%"}
						top={"143px"}
						content={() => <EstimateFsnbDrawer setFsnbVisible={props.setFsnbVisible} />}
					/>
				</Suspense>
			)}
		</>
	);
}

const FooterItem = ({ type, text, icon, position, callback, style, jsx }) => {
    // You can customize the rendering logic based on the properties passed
    return (
        <div className={`footer-item ${position}`} style={style}>

            {jsx ? jsx : <span> {icon} {text}</span>}
            {/* Additional rendering based on other properties like icon, type, etc. */}
        </div>
    );
};

export default FooterItem;
import { apiBase } from "./base";

const apiObjectEstimate = {
	client: apiBase.get_client(),
	url: "/smetdocs/object_estimates/",

	get(id) {
		return this.client.get(this.url + id + "/", apiBase.get_headers());
	},

	getTree(id) {
		return this.client.get(this.url + "tree/" + id + "/", apiBase.get_headers());
	},

	edit(id, body) {
		return this.client.patch(this.url + id + "/", body, apiBase.get_headers());
	},

	delete({ estmId, cascade }) {
		return this.client.delete(this.url + estmId + "/cascade/" + String(cascade) + "/", apiBase.get_headers());
	},

	calculate(id) {
		return this.client.get(this.url + "calculate/" + id + "/", apiBase.get_headers());
	},

	protectFull(id, body) {
		return this.client.patch(this.url + "protect_full/" + id + "/", body, apiBase.get_headers());
	},

	getInputXml(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "text/xml";
		return this.client.get(this.url + "xml/input/" + id + "/", apiBase.get_headers());
	},

	getPrintXlsx(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "print/xlsx/" + id + "/", headers);
	},

	getPrintXlsxWithErrors(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "print/xlsx_with_errors/" + id + "/", headers);
	},
};

export default apiObjectEstimate;


import RibbonContent from "components/Ribbon/RibbonContent/RibbonContent";
import EstimateViewerMain from "./EstimateViewerMain";

export default function EstimateViewerMainWrapper({ history }) {

    return (
        <>
            <RibbonContent />
            <EstimateViewerMain history={history} />
        </>

    )
}
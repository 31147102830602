import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiSummaryEstimate from "../api/summary_estimate";
import { searchDataRecourse, changeRowDataRecourse } from "./utils";
import { downloadFile } from "core/request_utils";

export const getSumEstimateTree = createAsyncThunk("getSumEstimateTree/tree", async ({ id, priceLevel }) => {
	const response = await apiSummaryEstimate.getTree(id, priceLevel);
	return response.data;
});

export const calcSumEstimate = createAsyncThunk("summaryEstimateSlice/calc", async (id, { rejectWithValue }) => {
	try {
		const response = await apiSummaryEstimate.calculate(id);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const getInputXml = createAsyncThunk("summaryEstimateSlice/getInputXml", async ({ projectId, priceLevel }) => {
	const response = await apiSummaryEstimate.getInputXml({ projectId, priceLevel });
	downloadFile(response);
});

export const getPrintXlsx = createAsyncThunk("summaryEstimateSlice/getPrintXlsx", async ({ projectId, priceLevel }) => {
	const response = await apiSummaryEstimate.getPrintXlsx({ projectId, priceLevel });
	downloadFile(response);
});

export const getPrintXlsxWithErrors = createAsyncThunk("summaryEstimateSlice/getPrintXlsxWithErrors", async ({ projectId, priceLevel }) => {
	const response = await apiSummaryEstimate.getPrintXlsxWithErrors({ projectId, priceLevel });
	downloadFile(response);
});

export const summaryEstimateSlice = createSlice({
	name: "summaryEstimateSlice",
	fetchStatus: null,
	fetchStatusText: null,
	initialState: {
		headerData: {},
		tableData: [],
	},
	reducers: {
		clearSumEstmData: (state, { payload }) => {
			state.data = {};
			state.tableData = [];
		},
		setFetchStatus: (state, { payload }) => {
			state.fetchStatus = payload;
		},
		changeSumEstmVisible: (state, { payload: { id, val } }) => {
			if (state.tableData == null) return;
			state.tableData = state.tableData.map((row) => {
				if (row["project_chapter_id"] === id || row["id"] === id) {
					row["childs_visible"] = !row["childs_visible"];
					if (row?._children != null) {
						row["_children"].map((x) => (x["visible"] = val === null ? !x["visible"] : val));
					}
				}
				return row;
			});
		},
		changeSumEstmErrorsVisible: (state, { payload: { row, val } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = searchDataRecourse(state.tableData, row["key"], val, "_details", "errors_visible");
		},
		changeRowData: (state, { payload: { rowKey, callback } }) => {
			localStorage.setItem("DO_RECOURSE", "1");
			state.tableData = changeRowDataRecourse(state.tableData, rowKey, callback);
		},
		selectData: (state, { payload }) => {
			state.tableData = state.tableData.map((row) => {
				if (row._children) {
					row._children = row._children.map((ch) => {
						if (ch.key === payload.key) {
							ch.selected = true;
						} else {
							ch.selected = false;
						}
						return ch;
					});
				}
				return row;
			});
		},
		setTableData: (state, { payload }) => {
			state.tableData = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(getSumEstimateTree.fulfilled, calcSumEstimate.fulfilled), (state, { payload }) => {
			state.tableData = payload["table_data"];
			state.headerData = payload["header_data"];
			if (localStorage.getItem("scrollType") === "ssr") {
				localStorage.setItem("DO_RECOURSE", "1");
				state.tableData = searchDataRecourse(state.tableData, localStorage.getItem("scrollParentKey"), true);
				state.tableData = searchDataRecourse(state.tableData, localStorage.getItem("scrollKey"), true, "_details", "errors_visible");
				localStorage.removeItem("scrollType");
				localStorage.removeItem("scrollKey");
				localStorage.removeItem("scrollParentKey");
			}
			state.fetchStatus = "success";
		});

		builder.addMatcher(isAnyOf(getPrintXlsx.fulfilled, getPrintXlsxWithErrors.fulfilled), (state, action) => {
			state.fetchStatus = "success";
		});

		builder.addMatcher(
			isAnyOf(getSumEstimateTree.pending, calcSumEstimate.pending, getPrintXlsx.pending, getPrintXlsxWithErrors.pending),
			(state, action) => {
				state.fetchStatus = "loading";
			}
		);

		builder.addMatcher(
			isAnyOf(getSumEstimateTree.rejected, calcSumEstimate.rejected, getPrintXlsx.rejected, getPrintXlsxWithErrors.rejected),
			(state, { payload }) => {
				state.fetchStatus = "failed";
				if (payload != null) {
					if (payload.hasOwnProperty("error_text")) {
						state.fetchStatusText = payload["error_text"];
					}
				}
			}
		);
	},
});

export const { clearSumEstmData, setFetchStatus, changeSumEstmVisible, changeSumEstmErrorsVisible, changeRowData, selectData, setTableData } =
	summaryEstimateSlice.actions;

export default summaryEstimateSlice.reducer;

import IconButton from "@mui/material/IconButton";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import "./CustomModalNameCell.css";

const CustomModalNameCell = ({ rowObj, openLsrTc }) => {
	const cellLeftLabel = rowObj.res_name == null ? "" : rowObj.res_name.toString();

	let cellLeft = <span className="CustomModalNameCellLeft">{cellLeftLabel}</span>;
	let cellRight = (
		<span className="CustomModalNameCellRight">
			<IconButton size="small" style={{ position: "relative", top: "1px" }} onClick={() => openLsrTc({ rowObj })}>
				<OpenInNewOutlinedIcon fontSize="small" />
			</IconButton>
		</span>
	);

	let height = 17;
	if (rowObj.rowHeightMultiply && rowObj.rowHeightMultiply !== 1) {
		height = 22 * rowObj.rowHeightMultiply;
	}

	return (
		<td
			key={`cell-name-${rowObj.key}`}
			className="TableCell"
			clm="res_name"
			treecell="false"
			style={{
				display: "flex",
				justifyContent: "space-between",
				height: `${height}px`,
				paddingLeft: 10,
				paddingRight: 10,
				alignItems: "center",
			}}
			title={cellLeftLabel}
		>
			{cellLeft}
			{cellRight}
		</td>
	);
};

export { CustomModalNameCell };

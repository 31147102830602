import React from "react";
import RibbonElementWrapper from "./RibbonElementWrapper.jsx";

const RibbonSubGroup = ({ subGroupSettings }) => {
    const { orientation, styles, _children } = subGroupSettings;

    const subgroupStyle = {
        flexDirection: orientation === "vertical" ? "column" : "row",
        ...styles,
    };

    return (
        <div className="ribbon-subgroup" style={subgroupStyle}>
            {_children.map((element, index) => {
                if (element.isVisible === false) {
                    return <span key={index}></span>;
                }
                return <RibbonElementWrapper key={index} elementSettings={element} />
            })}
        </div>
    );
};

export default RibbonSubGroup;

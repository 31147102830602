import { LinearProgress } from "@mui/material";

import "./RibbonLoader.css";

const RibbonLoader = ({ status, useRibbonPanel }) => {
	let top;
	if (useRibbonPanel) {
		top = "167px";
	} else {
		top = "141px";
	}

	return (
		<>
			{status === "loading" && (
				<div className="RibbonLoader" style={{ top: top }}>
					<LinearProgress />
				</div>
			)}
			{status && !isNaN(status) && (
				<div className="RibbonLoader" style={{ top: top }}>
					<LinearProgress variant="determinate" value={status} />
				</div>
			)}
		</>
	);
};

export default RibbonLoader;

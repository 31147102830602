import { useDispatch } from "react-redux";

import { selectData, changeKatsPaperDetailsVisible } from "slices/kats";
import { setClickedTableRow } from "slices/ribbon";

import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";

import "./CustomNumCell.css";

const CustomNumCell = ({ rowObj }) => {
	const dispatch = useDispatch();

	const cellCenterLabel = rowObj.num == null ? "" : rowObj.num.toString();
	const cellCenter = (
		<span className="CustomNumCellCenter" style={{ flexBasis: "50%", textAlign: "center", display: "flex", justifyContent: "center" }}>
			{cellCenterLabel}
		</span>
	);

	let cellLeft = <span className="CustomNumCellLeft" style={{ flexBasis: "25%" }}></span>;
	let cellRight = <span className="CustomNumCellRight" style={{ flexBasis: "25%" }}></span>;

	if (rowObj.is_winner === true) {
		cellRight = (
			<span className="CustomNumCellRight" style={{ flexBasis: "25%" }}>
				<EmojiEventsOutlinedIcon />
			</span>
		);
	}

	let height = 17;
	if (rowObj.rowHeightMultiply && rowObj.rowHeightMultiply !== 1) height = 22 * rowObj.rowHeightMultiply;

	return (
		<td
			key={`cell-num-${rowObj.key}`}
			className="TableCell"
			clm="num"
			treecell="false"
			style={{
				display: "flex",
				justifyContent: "space-around",
				height: `${height}px`,
				paddingLeft: 0,
				paddingRight: 0,
				alignItems: "center",
			}}
			title={cellCenterLabel}
			onClick={() => {
				dispatch(setClickedTableRow({ ...rowObj }));
				dispatch(selectData(rowObj));
				dispatch(changeKatsPaperDetailsVisible({ row: rowObj }));
			}}
		>
			<div className="CustomNumCell Kats">
				{cellLeft}
				{cellCenter}
				{cellRight}
			</div>
		</td>
	);
};

export { CustomNumCell };

import { lazy, Suspense } from "react";

import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import FillColorSeparateIcon from "components/UI/icons/FillColorSeparateIcon";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import AddModeratorOutlinedIcon from "@mui/icons-material/AddModeratorOutlined";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";

const ColorPicker = lazy(() => import("components/UI/ColorPicker"));

const EstimateViewerContextMenuLsrItem = ({ contextMenuObj, callbacks, userSettings }) => {
    return [
        {
            name: "",
            subgroups: [
                {
                    name: "Subgroup",
                    items: [
                        {
                            label: "Показать в ФСНБ",
                            icon: <AccountTreeOutlinedIcon />,
                            type: "common",
                            disabled:
                                ![
                                    "LocalEstimatesItems",
                                    "LocalEstimatesResMachine",
                                    "LocalEstimatesResMaterial",
                                    "LocalEstimatesResEquipment",
                                ].includes(contextMenuObj.model_name) || String(contextMenuObj.code).startsWith("ТЦ_"),
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["openFsnbCatalog"](contextMenuObj);
                                callbacks.closeContextMenu();
                            },
                        },
                        {
                            label: "Доп. информация",
                            icon: <InfoOutlinedIcon />,
                            type: "common",
                            disabled: contextMenuObj.cost_has_coef !== true,
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["showCoefs"](contextMenuObj);
                                callbacks.closeContextMenu();
                            },
                        },

                        {
                            label: "Показать в КАЦ",
                            isVisible: String(contextMenuObj.code).startsWith("ТЦ_"),
                            icon: <InfoOutlinedIcon />,
                            type: "common",
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["openKats"](contextMenuObj);
                                callbacks.closeContextMenu();
                            },
                        },

                    ],
                },
            ],
        },
        {
            name: "",
            subgroups: [
                {
                    name: "Subgroup",
                    items: [
                        // {
                        //     label: "Информация об ошибке",
                        //     icon: <ReportGmailerrorredOutlinedIcon />,
                        //     type: "common",
                        //     disabled: true,
                        //     callback: () => {
                        //         callbacks.closeContextMenu();
                        //     },
                        // },
                        {
                            label: "Комментарии",
                            icon: <CommentOutlinedIcon />,
                            type: "common",
                            disabled: false,
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["showComments"]({
                                    val: true,
                                    targetRowObj: contextMenuObj,
                                });
                                callbacks.closeContextMenu();
                            },
                            styles: {},
                        },
                        {
                            label: "Заливка",
                            icon: <FillColorSeparateIcon secondaryColor={userSettings?.USER_COLORS?.last_color} />,
                            type: "select-custom-options",
                            disabled: false,
                            callback: () => {
                                callbacks.fillColorTableRow({ hex: userSettings?.USER_COLORS?.last_color });
                                callbacks.closeContextMenu();
                            },
                            getJsxOptions: () => {
                                return (
                                    <Suspense>
                                        <ColorPicker
                                            type="GithubPicker"
                                            onChange={(color) => {
                                                callbacks.fillColorTableRow(color);
                                                callbacks.closeContextMenu();
                                            }}
                                        />
                                    </Suspense>
                                );
                            },
                        },
                    ],
                },
            ],
        },
        {
            name: "Защита",
            subgroups: [
                {
                    name: "Subgroup",
                    items: [
                        {
                            label: "Защитить строку",
                            icon: <ShieldOutlinedIcon />,
                            type: "common",
                            disabled: contextMenuObj.protectable !== true || contextMenuObj.protected_id != null,
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["lsrProtection"](contextMenuObj);
                                callbacks.closeContextMenu();
                            },
                        },
                        {
                            label: "Снять защиту",
                            icon: <AddModeratorOutlinedIcon />,
                            type: "common",
                            disabled: contextMenuObj.protectable !== true || contextMenuObj.protected_id == null,
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["lsrProtection"](contextMenuObj);
                                callbacks.closeContextMenu();
                            },
                        },
                    ],
                },
            ],
        },
        {
            name: "Позиция",
            subgroups: [
                {
                    name: "Subgroup",
                    items: [
                        {
                            label: "Позиция в XML",
                            icon: <CodeOutlinedIcon />,
                            type: "common",
                            disabled: contextMenuObj?.model_name === "LocalEstimatesResWorker",
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["showErrorJustificationShort"]({ obj: "item" });
                                callbacks.closeContextMenu();
                            },
                        },
                        {
                            label: "Обоснование расчета",
                            icon: <CalculateOutlinedIcon />,
                            type: "common",
                            disabled: false,
                            callback: () => {
                                callbacks.lowerCallbacks.current.local["showLogJustification"]({ obj: "item" });
                                callbacks.closeContextMenu();
                            },
                        },
                    ],
                },
            ],
        },
    ];
};

export { EstimateViewerContextMenuLsrItem };

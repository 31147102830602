import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import apiAuth from "../api/auth";

export const fetchAuth = createAsyncThunk("auth", async (data) => {
	const response = await apiAuth.getToken(data);
	return response.data;
});

export const fetchEcpeAuth = createAsyncThunk("auth/fetchEcpeAuth", async (data, { rejectWithValue }) => {
	try {
		const response = await apiAuth.getTokenAsEcpe(data);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const fetchAisAuth = createAsyncThunk("auth/fetchAisAuth", async (data, { rejectWithValue }) => {
	try {
		const response = await apiAuth.getTokenAsAis(data);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const fetchSipCsAuth = createAsyncThunk("auth/fetchSipCsAuth", async (data, { rejectWithValue }) => {
	try {
		const response = await apiAuth.getTokenAsSipCs(data);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

export const getUserPermission = createAsyncThunk("permission", async () => {
	const response = await apiAuth.getUserPermission();
	return response.data;
});

export const authSlice = createSlice({
	name: "auth",
	initialState: {
		authFact: null,
		inputLogin: "",
		inputPssw: "",
		showPassword: false,
		fetchStatus: null,
		fetchStatusText: "",
		userName: "",
		userId: "",
		clearToken: "",
		permissions: null,
		sessionProject: null,
	},
	reducers: {
		setInputLogin: (state, action) => {
			state.inputLogin = action.payload;
		},
		setPsswLogin: (state, action) => {
			state.inputPssw = action.payload;
		},
		setShowPassword: (state) => {
			state.showPassword = !state.showPassword;
		},
		Logout: (state) => {
			state.authFact = false;
			state.authName = "";
			state.inputLogin = "";
			state.inputPssw = "";
			state.showPassword = false;
			state.fetchStatus = null;
			localStorage.removeItem("accessToken");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("userName");
			localStorage.removeItem("userId");
			localStorage.removeItem("userFio");
		},
		setAuthData: (state, { payload }) => {
			localStorage.setItem("userName", payload.user_name);
			localStorage.setItem("userId", payload.user_id);
			localStorage.setItem("userFio", payload.user_fio);
			localStorage.setItem("accessToken", "Bearer " + payload.access);
			localStorage.setItem("refreshToken", payload.refresh);
			state.permissions = payload;
			state.fetchStatus = "success";
			state.authFact = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserPermission.fulfilled, (state, { payload }) => {
			state.permissions = payload;
		});
		builder.addCase(getUserPermission.rejected, (state, action) => {
			state.fetchStatus = "failed";
		});

		builder.addMatcher(
			isAnyOf(fetchAuth.fulfilled, fetchEcpeAuth.fulfilled, fetchAisAuth.fulfilled, fetchSipCsAuth.fulfilled),
			(state, { payload }) => {
				localStorage.setItem("userName", payload.user_name);
				localStorage.setItem("userId", payload.user_id);
				localStorage.setItem("userFio", payload.user_fio);
				localStorage.setItem("accessToken", "Bearer " + payload.access);
				localStorage.setItem("refreshToken", payload.refresh);
				state.permissions = payload;
				state.fetchStatus = "success";
				state.authFact = true;
			}
		);

		builder.addMatcher(isAnyOf(fetchAuth.rejected, fetchEcpeAuth.rejected, fetchAisAuth.rejected, fetchSipCsAuth.rejected), (state, action) => {
			state.fetchStatus = "failed";
			if (action.type === "auth/rejected") {
				state.fetchStatusText = "Неверный логин или пароль";
				state.fetchStatus = "rejected";
			}
			if (action?.payload != null) {
				if (action?.payload?.error_text !== null) {
					state.fetchStatusText = action.payload.error_text;
					state.fetchStatus = "rejected";
				}
			}
		});

		builder.addMatcher(
			isAnyOf(fetchAuth.pending, getUserPermission.pending, fetchEcpeAuth.pending, fetchAisAuth.pending, fetchSipCsAuth.pending),
			(state, action) => {
				state.fetchStatus = "loading";
			}
		);
	},
});

export const { setInputLogin, setPsswLogin, setShowPassword, Logout, setAuthData } = authSlice.actions;
export default authSlice.reducer;

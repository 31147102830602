import { getTextWidth } from "core/str_utils.js";

export const getRowOnCellClick = (target) => {
    if (typeof target.className !== "string") return;
    if (target.className.includes("TableRow")) {
        return target;
    } else if (target.className.includes("TableCell")) {
        return target.parentNode;
    } else if (target.className.includes("TreeFlexCellSpan")) {
        return target.parentNode.parentNode.parentNode;
    }
};

export const getRowHeightMultiplier = (row, columnsData, clmArr, fontSize, customMult = 1, realWidth = 0) => {

    const rowHeightMultiplyArr = [];
    for (let key of clmArr) {
        const textWidth = getTextWidth(String(row[key]), fontSize) * customMult;
        let rowWidth;
        if (realWidth === 0) {
            rowWidth = parseInt(columnsData.find((x) => x.name === key).width.replace("px", ""));
        } else {
            rowWidth = realWidth;
        }

        const rowHeightMultiply = Math.ceil(textWidth / rowWidth);
        rowHeightMultiplyArr.push(rowHeightMultiply);
    }
    return Math.max(...rowHeightMultiplyArr);
};

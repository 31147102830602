import { CustomModalNameCell } from "../../components/CustomCell/CustomModalNameCell";

export default function ResourceMappingModalColumns(hideProject, openLsrTc) {
	let clms = [
		{
			name: "proj_name",
			title: "Проект",
			width: `200px`,
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "lsr_name",
			title: "Cметный расчет",
			width: `400px`,
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "pos",
			title: "Позиция",
			width: `60px`,
			cellHorAlign: "center",
			headHorAlign: "center",
			sort: false,
			filter: false,
			editable: false,
		},
		{
			name: "res_name",
			title: "Обоснование и наименование работ и затрат",
			width: `auto`,
			cellHorAlign: "left",
			headHorAlign: "left",
			sort: false,
			filter: false,
			editable: false,
			cellJsx: ({ rowObj }) => CustomModalNameCell({ rowObj, openLsrTc }),
		},
	];

	if (hideProject) {
		clms = clms.slice(1, clms.length);
	}

	return clms;
}

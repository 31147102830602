const ChangeSectionChildsVisible = ({ tableData, visibleSet, value }) => {
    tableData = tableData.map((sec) => {
        if (sec._children) {
            sec.childs_visible = value;
            sec._children = sec._children.map((item) => {
                item.visible = value;
                return item;
            });
            if (value) {
                delete visibleSet[sec["key"]];
            } else {
                visibleSet[sec["key"]] = false;
            }
        }
        return sec;
    });
    return { tableData, visibleSet };
};

const ChangeEndingChildsVisible = ({ tableData, visibleSet, value }) => {
    tableData = tableData.map((sec) => {
        if (sec._children && sec?.model_name === "LocalEstimateRimSum") {
            sec.childs_visible = value;
            sec._children = sec._children.map((item) => {
                item.visible = value;
                return item;
            });
            if (value) {
                delete visibleSet[sec["key"]];
            } else {
                visibleSet[sec["key"]] = false;
            }
        } else if (sec._children) {
            sec._children = sec._children.map((item) => {
                if (
                    item._children &&
                    ["LocalEstimateRimSecSum", "LocalEstimateRimSecSumDirect"].includes(item.model_name)
                ) {
                    item.childs_visible = value;
                    item._children.forEach((child) => {
                        child.visible = value;
                    });
                    if (value) {
                        delete visibleSet[item["key"]];
                    } else {
                        visibleSet[item["key"]] = false;
                    }
                }
                return item;
            });
        }
        return sec;
    });
    return { tableData, visibleSet };
};

const ChangeItemChildsVisible = ({ tableData, visibleSet, value }) => {
    tableData = tableData.map((sec) => {
        if (sec._children) {
            sec._children = sec._children.map((item) => {
                if (item._children && item.is_item === "1") {
                    item.childs_visible = value;
                    item._children.forEach((child) => {
                        child.visible = value;
                    });
                    if (value) {
                        delete visibleSet[item["key"]];
                    } else {
                        visibleSet[item["key"]] = false;
                    }
                }
                return item;
            });
        }
        return sec;
    });
    return { tableData, visibleSet };
};

const ShowHideItemsNameVisible = ({ tableData, oneLineLen }) => {
    tableData = tableData.map((sec) => {
        if (sec._children) {
            sec._children = sec._children.map((item) => {
                if (item.rowHeightMultiply == null || item.rowHeightMultiply === 1) {
                    const nameLen = String(item?.name).length;
                    const seekMult = Math.ceil(nameLen / oneLineLen);
                    if (seekMult > 1) item.rowHeightMultiply = seekMult;
                } else {
                    item.rowHeightMultiply = null;
                }
                if (item._children) {
                    const { tableData } = ShowHideItemsNameVisible({
                        tableData: item._children,
                        oneLineLen: oneLineLen,
                    });
                    item._children = tableData;
                }
                return item;
            });
        }
        return sec;
    });
    return { tableData };
};

const ShowHideCostChildrenVisible = ({ tableData, val }) => {
    tableData = tableData.map((sec) => {
        if (sec._children) {
            sec._children = sec._children.map((item) => {
                if (item._children) {
                    for (let row of item._children) {
                        if (row.model_name === "CostParentLabel" && row._children) {
                            row.childs_visible = val;
                            for (let costCh of row._children) {
                                costCh.visible = val;
                            }
                        }
                    }
                }
                return item;
            });
        }
        return sec;
    });
    return { tableData };
};

const ChangeTableVisibleOnColor = ({ tableData, color }) => {
    const changeColorVisible = (tableData) => {
        let foundColor = false;
        for (let row of tableData) {
            if (row.bgColor === color || color === "#ffffff") {
                row.visible = true;
                foundColor = true;
            } else {
                row.visible = false;
            }
            if (row._children) {
                const result = changeColorVisible(row._children);
                row._children = result.tableData;
                if (result.foundColor) {
                    foundColor = true;
                    row.visible = true;
                }
            }
        }
        return { tableData, foundColor };
    };

    const result = changeColorVisible(tableData);
    return { tableData: result.tableData };
};

export {
    ChangeSectionChildsVisible,
    ChangeEndingChildsVisible,
    ChangeItemChildsVisible,
    ShowHideItemsNameVisible,
    ShowHideCostChildrenVisible,
    ChangeTableVisibleOnColor,
};

import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';

import "./CellCheckBox.css"

const CellCheckBox = ({ rowObj, clmName, setCheckedFunc }) => {
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setCheckedFunc({ rowObj, clmName, value: event.target.checked });
    };

    useEffect(() => {
        if (rowObj[clmName] === true) {
            setChecked(rowObj[clmName])
        } else {
            setChecked(false)
        }
    }, [rowObj[clmName]]) // eslint-disable-line react-hooks/exhaustive-deps



    return (
        <td className="TableCell" clm={clmName}>
            <div className="TableCellCheckBox">
                <Checkbox
                    size="small"
                    checked={checked}
                    onChange={handleChange}
                />
            </div>
        </td>
    );
};

export { CellCheckBox };

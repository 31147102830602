import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";

import "./FsnbTree.css";

export default function FsnbTree(props) {
	const treeDom = [];

	for (let row of props.data) {
		const rowDom = (
			<ResurceTree
				key={`parent${row.id}`}
				row={row}
				selected={props.selected}
				searchObjScope={props.searchObjScope}
				f_setSelectedTreeData={props.f_setSelectedTreeData}
				f_changeChildsVisible={props.f_changeChildsVisible}
				f_setSearchObjScope={props.f_setSearchObjScope}
				styleLevel={0}
				propsPadLeft={props.propsPadLeft}
				parentProps={props}
			/>
		);
		treeDom.push(rowDom);
	}
	return treeDom;
}

function ResurceTree({
	row,
	selected,
	searchObjScope,
	f_setSelectedTreeData,
	f_changeChildsVisible,
	f_setSearchObjScope,
	styleLevel,
	propsPadLeft = 15,
	parentProps,
}) {
	const padLeft = propsPadLeft * styleLevel;

	const isSelected = row.key === selected.key;
	const arrowDown = <KeyboardArrowDownIcon style={{ paddingLeft: padLeft }} />;
	const arrowRight = <KeyboardArrowRightIcon style={{ paddingLeft: padLeft }} />;

	const onDoubleClickAction = () => {
		if (parentProps?.actions?.doubleClick) {
			parentProps.actions.doubleClick(row);
		}
	};

	const treeFullRow = (
		<div
			className="TreeFullRow"
			isselected={isSelected.toString()}
			islocked={String(searchObjScope?.id && searchObjScope?.id === row.id)}
			title={row.name}
			onClick={() => {
				f_setSelectedTreeData(row);
				f_changeChildsVisible(row);
			}}
			onDoubleClick={() => onDoubleClickAction()}
		>
			{row._children ? row.childs_visible ? arrowDown : arrowRight : <span style={{ paddingLeft: padLeft + 10 }} />}
			{searchObjScope?.id && searchObjScope?.id === row.id && <ShieldOutlinedIcon onClick={() => f_setSearchObjScope({})} />}
			<span>{row.name}</span>
		</div>
	);

	const rowDom = (
		<div key={row.key} className="TreeRow" elm_id={row.id}>
			{treeFullRow}
			{row.childs_visible ? (
				row._children?.map((child) => {
					return (
						<div key={child.key}>
							<ResurceTree
								row={child}
								selected={selected}
								searchObjScope={searchObjScope}
								f_setSelectedTreeData={f_setSelectedTreeData}
								f_changeChildsVisible={f_changeChildsVisible}
								f_setSearchObjScope={f_setSearchObjScope}
								styleLevel={styleLevel + 1}
								propsPadLeft={propsPadLeft}
								parentProps={parentProps}
							/>
						</div>
					);
				})
			) : (
				<></>
			)}
		</div>
	);

	return rowDom;
}

import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";

const FsnbFooterSettings = ({ searchMode, searchFoundsStats, selectedSnbRow }) => {
	let searchFoundsStr = "";
	let searchScopeStr = "";

	for (let key of Object.keys(searchFoundsStats)) {
		if (!key.includes("__")) {
			searchFoundsStr += ` ${key}: ${searchFoundsStats[key]}.`;
		}
	}

	if (selectedSnbRow.length > 0) {
		searchScopeStr = `Область поиска: ${selectedSnbRow
			.slice(0, 3)
			.map((x) => x.name)
			.join(", ")}.`;
	}
	if (selectedSnbRow.length > 3) {
		searchScopeStr = `${searchScopeStr}....`;
	}

	return [
		{
			type: "common",
			text: searchScopeStr,
			position: "left",
			isVisible: searchMode === "flat",
			style: {},
			jsx: null,
		},
		{
			type: "common",
			text: searchFoundsStr,
			icon: <ManageSearchOutlinedIcon />,
			position: "right",
			isVisible: true,
			callback: () => {
				// console.log("About item clicked");
			},
			style: {},
			jsx: null,
		},
	];
};

export default FsnbFooterSettings;

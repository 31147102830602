import { createSlice } from "@reduxjs/toolkit";
import { fsnbCheckAreaEnum } from "pages/Monitor/const";

export const monitorSlice = createSlice({
    name: "monitorSlice",
    initialState: {
        lastSelectedParams: {
            pz: 0,
            obj: 0,
            inx: 0,
            per: 0,
        },
        lastClickedRow: {},
        foundRecommendedIndex: {},
        selectedDate: null,
        requestBody: null,
        allowRequestBody: false,

    },
    reducers: {
        setLastSelectedParams: (state, { payload }) => {
            state.lastSelectedParams = payload;
        },
        setLastClickedRow: (state, { payload }) => {
            state.lastClickedRow = payload;
        },
        setFoundRecommendedIndex: (state, { payload }) => {
            state.foundRecommendedIndex = payload;
        },
        setSelectedDate: (state, { payload }) => {
            state.selectedDate = payload;
        },
        setRequestBody: (state, { payload }) => {
            state.requestBody = payload.body;
            if (payload.arg !== fsnbCheckAreaEnum.SELECTED_ESTMS) {
                state.allowRequestBody = true;
            }
        },
        setAllowRequestBody: (state, { payload }) => {
            state.allowRequestBody = payload;
        },

        setRequestBodyEstimateIds: (state, { payload }) => {
            state.requestBody.checkedEstmIds = payload;
            state.allowRequestBody = true
        },

    },
    extraReducers: (builder) => { },
});

export const { setLastSelectedParams, setLastClickedRow, setFoundRecommendedIndex,
    setSelectedDate, setRequestBody, setAllowRequestBody,
    setRequestBodyEstimateIds } =
    monitorSlice.actions;

export default monitorSlice.reducer;

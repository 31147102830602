
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import "./FsnbFilterVersSelect.css";

export default function FsnbFilterVersSelect(props) {
    return (
        <>
            <Autocomplete
                sx={{ width: "100%" }}
                options={props.fsnbVersArr}
                value={props.selectedFsnbVers}
                disableClearable
                getOptionLabel={(option) => option.name}
                size="small"
                noOptionsText="нет данных"
                onInputChange={(e, val) => {
                    const inpVal = props.fsnbVersArr.find(x => x["name"] === val);
                    if (inpVal != null) { props.f_setSelectedFsnbVers(inpVal) }
                }}
                renderInput={(params) => <TextField {...params}
                    InputLabelProps={{ shrink: true, }} label={props.label} />
                }
            />
        </>

    );
}

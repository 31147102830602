const PageParams = () => {
	return {
		local: {
			headerHeight: 435,
			rowsBeforeCnt: 3,
		},
		object: {
			headerHeight: 200,
			rowsBeforeCnt: 2,
		},
		summary: {
			headerHeight: 200,
			rowsBeforeCnt: 2,
		},
		kats: {
			headerHeight: 15,
			rowsBeforeCnt: 3,
		},
		protocol: {
			headerHeight: 15,
			rowsBeforeCnt: 3,
		},
		katsEditor: {
			headerHeight: 15,
			rowsBeforeCnt: 3,
			rowHeight: 45,
		},
	};
};

export { PageParams };

import { useRef, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { IconButton, Tooltip, FormControl, Button, FormGroup, Typography, Card, CardActions, CardContent, Box } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import CheckboxControl from "./CheckboxControl";
import CustomTypography from "./CustomTypography";

import { setStartSearchObj, setStartSearchCode } from "slices/fsnb";

import apiFsnb from "api/fsnb";

import { getTextWidth } from "components/utils.js";
import { getEnvVal } from "core/env.js";

import "../../Fsnb/components/FsnbBody.css";

const SEARCH_ATTRS = {
	res_code: 1,
	res_name: 2,
	cost_code: 3,
	cost_name: 4,
	cost_res_code: 5,
	cost_res_name: 6,
	cost_content_name: 7,
	coef_code: 8,
	coef_name: 9,
	tech_part_text: 10,
};

export default function FsnbSuperSearchBody(props) {
	const scrollBlock = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const searchText = props.inputSearchValue;
	const setSearchTextisError = useState(false)[1];
	const [posts, setPosts] = useState([]);

	const [displayedPosts, setDisplayedPosts] = useState([]);
	const [visiblePostsCnt, setVisiblePostsCnt] = useState(10);
	const [checkBoxStatus, setCheckBoxStatus] = useState({
		all_data: false,
		res_code: false,
		res_name: false,
		cost_code: false,
		cost_name: false,
		cost_res_name: false,
		cost_res_code: false,
		cost_content_name: false,
		coef_code: false,
		coef_name: false,
		tech_part_text: false,
	});

	useEffect(() => {
		props.f_setSuperSearchView(true);
	}, [props]);

	useEffect(() => {
		props.f_setSearchFounds(posts);
		const endIndex = visiblePostsCnt > posts.length ? posts.length : visiblePostsCnt;
		setDisplayedPosts(posts.slice(0, endIndex));
	}, [posts, visiblePostsCnt, props]);

	const clearCheckbox = () => {
		setCheckBoxStatus((prevState) => ({
			...prevState,
			all_data: false,
			res_code: false,
			res_name: false,
			cost_code: false,
			cost_name: false,
			cost_res_name: false,
			cost_res_code: false,
			cost_content_name: false,
			coef_code: false,
			coef_name: false,
			tech_part_text: false,
		}));
	};

	const handleChange = (event) => {
		const { name, checked } = event.target;
		if (name === "all_data") {
			setCheckBoxStatus((prevState) =>
				Object.keys(prevState).reduce((acc, objKey) => {
					acc[objKey] = event.target.checked;
					return acc;
				}, {})
			);
		} else {
			setCheckBoxStatus((prevState) => ({
				...prevState,
				[name]: checked,
			}));
		}
	};

	const highlightText = (text) => {
		const regex = new RegExp(`(${searchText})`, "gi");
		return text.replace(regex, "<span class='highlight'>$1</span>");
	};

	const dataToSend = useMemo(
		() => ({
			vers_id: props.selectedFsnbVers?.id,
			search_text: searchText,
			search_attrs: Object.keys(SEARCH_ATTRS)
				.filter((key) => checkBoxStatus[key])
				.map((key) => SEARCH_ATTRS[key]),
		}),
		[checkBoxStatus, searchText, props.selectedFsnbVers]
	);

	const getRightBoxSize = () => {
		const paddingsPx = 32;
		const cardMulti = 0.675;
		const rowWidth =
			parseInt(getComputedStyle(document.getElementsByClassName("FsnbBodyRight")[0]).width.replace("px", "")) * cardMulti - paddingsPx;
		return rowWidth;
	};

	const executeSearch = () => {
		let textErr = false;
		if (searchText.length < 3 || Object.values(checkBoxStatus).every((value) => value === false)) textErr = true;

		setSearchTextisError(textErr);

		if (textErr) return;

		props.f_setFetchStatus("loading");
		setPosts([]);

		apiFsnb
			.getSuperSearch(dataToSend)
			.then((response) => {
				const rowWidth = getRightBoxSize();
				const highlightedResults = response.data.map((post) => {
					return {
						...post,
						found_text: post.found_text
							.split("__NEW_LINE__")
							.map((text) => {
								let resText = text;
								const textWidth = getTextWidth(text.toString(), 14);
								if (text.length) {
									const pxOnSymb = textWidth / text.length;
									let allowedLen = 160;
									if (pxOnSymb) allowedLen = Math.ceil(rowWidth / pxOnSymb);
									if (text.length > allowedLen) {
										if (!post.found_text.includes("__NEW_LINE__")) {
											let part1 = text.substring(0, allowedLen);
											const lastSpace = part1.lastIndexOf(" ");
											allowedLen = lastSpace;
											part1 = text.substring(0, allowedLen);
											let part2 = text.substring(allowedLen, text.length);
											if (part2.length > allowedLen) {
												part2 = part2.substring(0, allowedLen) + "...";
											}
											resText = [part1, part2].join("<br/>");
										} else {
											resText = text.substring(0, allowedLen) + "...";
										}
									} else {
										resText = text;
									}
								}
								return highlightText(resText);
							})
							.join("<br/>"),
						title: String(post.found_text).replace("__NEW_LINE__", ". "),
					};
				});

				setPosts(highlightedResults);
				props.f_setFetchStatus("success");
			})
			.catch((error) => {
				console.error(error);
				props.f_setFetchStatus("failed");
				props.f_setFetchStatusText(error);
			});

		scrollBlock.current.scrollTo({ top: 0, behavior: "smooth" });
		// setSearchText('')
	};

	const {
		all_data,
		res_code,
		res_name,
		cost_code,
		cost_name,
		cost_res_name,
		cost_res_code,
		cost_content_name,
		coef_code,
		coef_name,
		tech_part_text,
	} = checkBoxStatus;

	const handleScroll = () => {
		const { scrollTop, clientHeight, scrollHeight } = scrollBlock.current;
		const diff = Math.abs(scrollHeight - scrollTop - clientHeight);
		if (diff < 100 && visiblePostsCnt <= posts.length) {
			setVisiblePostsCnt((prev) => prev + 10);
		}
	};

	const clickHandler = (item) => {
		const modelLinks = {
			FsnbFssc: "/fsnb/mat/",
			FsnbNorm: "/fsnb/norm/",
			FsnbNormResources: "/fsnb/norm/",
			FsnbNormContent: "/fsnb/norm/",
			FsnbFsem: "/fsnb/mach/",
			FsnbCoefficient: "/fsnb/coef/",
			TechPartsNorm: "/fsnb/norm/",
			TechPartsFssc: "/fsnb/mat/",
			TechPartsFsem: "/fsnb/mach/",
		};

		const linkName = modelLinks[item.model_name];

		if (linkName) {
			const itemLs = { ...item, vers_id: props.selectedFsnbVers?.id };
			if (getEnvVal("App") === "guest") {
				dispatch(setStartSearchObj(itemLs));
				dispatch(setStartSearchCode(""));
				props.f_setInputSearchValue("");
				props.f_setSearchFounds([itemLs]);
				props.f_setSuperSearchView(false);
				props.f_setSelectedTreeData({});

				setTimeout(() => {
					navigate(linkName);
				}, 100);
			} else {
				localStorage.setItem("superSearchItem", JSON.stringify(itemLs));
				localStorage.setItem("searchText", JSON.stringify({ searchText }));
				window.open(linkName, "_blank");
			}
		}
	};

	return (
		<div className="FsnbBody FsnbSuperSearchBody">
			<div className="FsnbBodyLeft">
				<div className="FsnbSuperSearchBodyLeft">
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
							width: "100%",
						}}
					>
						<Tooltip title="Выход">
							<IconButton
								onClick={() => {
									props.f_setSuperSearchView(false);
									if (props.history.length === 1) {
										navigate("/fsnb/mat/");
									} else {
										navigate(-1);
									}
								}}
							>
								<LogoutOutlinedIcon rotate="180" />
							</IconButton>
						</Tooltip>
					</Box>

					<FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
						<CustomTypography text="Где искать" />
						<FormGroup sx={{ pl: 2 }}>
							<CheckboxControl
								label="Искать по всей базе"
								name="all_data"
								checked={all_data}
								onChange={handleChange}
								disabled={false}
							/>
							<CustomTypography text=" Материалы, обрудование, машины и механизмы" />
							<CheckboxControl label="Код ресурса" name="res_code" checked={res_code} onChange={handleChange} disabled={all_data} />
							<CheckboxControl
								label="Наименование ресурса"
								name="res_name"
								checked={res_name}
								onChange={handleChange}
								disabled={all_data}
							/>
							<CustomTypography text="Коэффициенты и документы" />
							<Typography
								p={1}
								variant="body2"
								sx={{
									color: "#000000b3",
									fontSize: "14px",
								}}
							>
								Сметные нормативы
							</Typography>
							<CheckboxControl label="Шифр расценки" name="cost_code" checked={cost_code} onChange={handleChange} disabled={all_data} />
							<CheckboxControl
								label="Наименование расценки"
								name="cost_name"
								checked={cost_name}
								onChange={handleChange}
								disabled={all_data}
							/>
							<CheckboxControl
								label="Наименование ресурса в составе норматива"
								name="cost_res_name"
								checked={cost_res_name}
								onChange={handleChange}
								disabled={all_data}
							/>
							<CheckboxControl
								label="Шифр ресурса в составе норматива"
								name="cost_res_code"
								checked={cost_res_code}
								onChange={handleChange}
								disabled={all_data}
							/>
							<CheckboxControl
								label="Состав работ"
								name="cost_content_name"
								checked={cost_content_name}
								onChange={handleChange}
								disabled={all_data}
							/>
							<CustomTypography text="Коэффициенты и документы" />
							<CheckboxControl
								label="Шифр коэффициента"
								name="coef_code"
								checked={coef_code}
								onChange={handleChange}
								disabled={all_data}
							/>
							<CheckboxControl
								label="Наименование коэффициента"
								name="coef_name"
								checked={coef_name}
								onChange={handleChange}
								disabled={all_data}
							/>
							<CheckboxControl
								label="В тексте документов (ТЧ и МДС)"
								name="tech_part_text"
								checked={tech_part_text}
								onChange={handleChange}
								disabled={all_data}
							/>
						</FormGroup>
					</FormControl>
				</div>

				<Box textAlign="end" p={2}>
					<Button
						onClick={clearCheckbox}
						size="small"
						sx={{
							padding: "5px",
							fontSize: "11px",
							fontWeight: "600",
							height: "25px",
						}}
					>
						очистить
					</Button>
					<Button
						disabled={Object.values(checkBoxStatus).every((value) => value === false)}
						onClick={executeSearch}
						size="small"
						sx={{
							marginLeft: "10px",
							padding: "5px",
							fontSize: "11px",
							fontWeight: "600",
							height: "25px",
						}}
					>
						искать
					</Button>
				</Box>
			</div>

			<div className="FsnbBodyRight FsnbSuperSearchBodyRight" onScroll={handleScroll} ref={scrollBlock} table_type={props.tabValue}>
				{displayedPosts.map((item) => (
					<Card
						key={`${item.id}-${item.parent_id}-${item.res_id}-${item.str_clm}`}
						raised
						sx={{
							width: "99%",
							height: "114px",
							minHeight: "114px",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							m: "8px 0",
						}}
					>
						<CardContent>
							<Typography component={"div"} variant="body2" sx={{ color: "#00000099", fontSize: "14px" }} title={item.title}>
								<div
									dangerouslySetInnerHTML={{
										__html: item.found_text,
									}}
								/>
							</Typography>
						</CardContent>
						<CardActions>
							<Button
								size="small"
								endIcon={<ArrowForwardIcon />}
								onClick={() => clickHandler(item)}
								sx={{ padding: "5px", fontSize: "11px", fontWeight: "600", height: "25px" }}
							>
								<div className="btnInnerText">ПЕРЕЙТИ К ОБЪЕКТУ</div>
							</Button>
						</CardActions>
					</Card>
				))}
			</div>
		</div>
	);
}

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export default function HeaderSort(props) {
	const getSortUpTh = () => {
		return (
			<div className="TableHeadCellSort" onClick={() => props.sortDataUp(props.tableData, props.clmName)}>
				<ArrowUpwardIcon />
			</div>
		);
	};

	const getSortDownTh = () => {
		return (
			<div className="TableHeadCellSort" onClick={() => props.sortDataDown(props.tableData, props.clmName)}>
				<ArrowDownwardIcon />
			</div>
		);
	};

	if (props.sortParams == null) return;

	if (props.sortParams[props.clmName] === "up") {
		return getSortUpTh();
	} else if (props.sortParams[props.clmName] === "down") {
		return getSortDownTh();
	}
}

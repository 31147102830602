import { createSlice } from '@reduxjs/toolkit';

export const newsFeed = createSlice({
    name: 'newsFeed',
    initialState: {
        currentId: false,
        hasUnreadNews: false,
    },
    reducers: {
        setCurrentId: (state, { payload }) => {
            state.currentId = payload;
        },
        setHasUnreadNews: (state, { payload }) => {
            state.hasUnreadNews = payload;
        },
    },
});

export const { setCurrentId, setHasUnreadNews } = newsFeed.actions;

export default newsFeed.reducer;

import { apiBase } from "api/base";

const downloadFile = (response, defaultFileName = "") => {
	let filename = "";
	if (defaultFileName === "") {
		filename = apiBase.get_file_name(response.headers["content-disposition"]);
	} else {
		filename = defaultFileName;
	}
	const blob = new Blob([response.data]);
	const href = URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.href = href;
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	URL.revokeObjectURL(href);
	return response.data;
};

const getUrlFromText = ({ text }) => {
	const url = String(text);
	if (url.startsWith("http://") || url.startsWith("https://")) {
		return url;
	} else {
		return `http://${url}`;
	}
};

export { downloadFile, getUrlFromText };

import { apiBase } from "./base";

const apiProjects = {
	client: apiBase.get_client(),
	url: "/projects/",

	get(id) {
		return this.client.get(this.url + id + "/", apiBase.get_headers());
	},

	getList(body) {
		return this.client.post(this.url + "limit/", body, apiBase.get_headers());
	},

	delete(id) {
		return this.client.delete(this.url + id + "/", apiBase.get_headers());
	},

	edit(id, body) {
		return this.client.patch(this.url + id + "/", body, apiBase.get_headers());
	},

	create(body) {
		return this.client.post(this.url, body, apiBase.get_headers());
	},

	getTree(id) {
		return this.client.get(this.url + "tree/" + id, apiBase.get_headers());
	},

	getMonitorData(id) {
		return this.client.get(this.url + "monitor/" + id, apiBase.get_headers());
	},

	getLsrMonitorData(id) {
		return this.client.get(this.url + "monitor/lsr/" + id, apiBase.get_headers());
	},

	getLsrResStatsData(id) {
		return this.client.get(this.url + "stats/resources/" + id + "/", apiBase.get_headers());
	},

	getLsrResStatsExcel(id) {
		let headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "stats/resources/" + id + "/excel/", headers);
	},

	getProjectErrorsExcel(id) {
		let headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "protocol/" + id + "/excel/", headers);
	},

	getProjectPrintXlsx(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + id + "/print/xlsx/", headers);
	},

	getProjectInputXml(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "xml/input/" + id, headers);
	},

	getProjectPrintXlsxWithErrors(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + id + "/print/xlsx_with_errors/", headers);
	},

	getPartProjectPrintXlsx(id, body) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.post(this.url + id + "/print/xlsx/", body, headers);
	},

	getPartProjectPrintXlsxWithErrors(id, body) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.post(this.url + id + "/print/xlsx_with_errors/", body, headers);
	},

	getNotDeepList() {
		return this.client.get(this.url + "no_deep/", apiBase.get_headers());
	},

	getEstmDeepListAll() {
		return this.client.get(this.url + "estm_deep/", apiBase.get_headers());
	},

	getEstmDeepListById(body) {
		return this.client.post(this.url + "estm_deep/", body, apiBase.get_headers());
	},

	getProjectOnEstmId({ estmId }) {
		return this.client.get(this.url + "on_estm_id/" + estmId + "/", apiBase.get_headers());
	},

	getProjectKats({ estmId }) {
		return this.client.get(this.url + "kats/" + estmId + "/", apiBase.get_headers());
	},

	setRowColor(body) {
		return this.client.post(this.url + "colorize/", body, apiBase.get_headers());
	},

	postRowComment(body) {
		return this.client.post(this.url + "comment/", body, apiBase.get_headers());
	},

	getRowComments({ projectId, key }) {
		return this.client.get(this.url + "comment/" + projectId + "/" + key + "/", apiBase.get_headers());
	},

	deleteRowComment(id) {
		return this.client.delete(this.url + "comment/" + id + "/", apiBase.get_headers());
	},

	editRowComment({ commentId, text }) {
		return this.client.patch(this.url + "comment/" + commentId + "/", { text: text }, apiBase.get_headers());
	},

	clearProject(id) {
		return this.client.get(this.url + id + "/clear/", apiBase.get_headers());
	},

	getProjectLsrOsrReestr(id) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + id + "/print/reestr_lsr_osr/", headers);
	},

	downloadPrinterXlsxResult({ taskId }) {
		const headers = apiBase.get_headers();
		headers["responseType"] = "blob";
		return this.client.get(this.url + "print/download_zipped_xlsx/" + taskId + "/", headers);
	},

	dedublicateProjectLsr({ projectId }) {
		return this.client.get(this.url + "dedublicate_project_lsr/" + projectId + "/", apiBase.get_headers());
	},

	undoDedublicateProjectLsr({ projectId }) {
		return this.client.get(this.url + "undo_dedublicate_project_lsr/" + projectId + "/", apiBase.get_headers());
	},

	makeProjectLsrDoubleWinner({ projectId, groupId, lsrId }) {
		return this.client.get(
			this.url + "make_project_lsr_double_winner/project/" + projectId + "/group/" + groupId + "/lsr/" + lsrId + "/",
			apiBase.get_headers()
		);
	},
};

export default apiProjects;

export default function CompletenessModalTableColumsSettings(objType) {
	let clms = [
		{
			name: "num",
			title: "№",
			width: "45px",
			cellHorAlign: "center",
			headHorAlign: "center",
			rowSpan: "2",
			headHeight: "22px",
			marginLeft: "1px",
		},
		{
			name: "estm_name",
			title: "Сметный документ",
			width: "200px",
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "22px",
			marginLeft: "1px",
		},
		{
			name: "state_name",
			title: "Проверка",
			width: "300px",
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "22px",
			marginLeft: "1px",
		},
		// {
		// 	name: "item_num",
		// 	title: "Поз.",
		// 	width: "35px",
		// 	cellHorAlign: "center",
		// 	headHorAlign: "center",
		// 	rowSpan: "2",
		// 	headHeight: "22px",
		// 	marginLeft: "1px",
		// },
		{
			name: "text",
			title: "Описание ошибки",
			width: "auto",
			cellHorAlign: "left",
			headHorAlign: "left",
			rowSpan: "2",
			headHeight: "22px",
			marginLeft: "1px",
		},
		// {
		// 	name: "expected",
		// 	title: "Ожидаемое значение",
		// 	width: "250px",
		// 	cellHorAlign: "left",
		// 	headHorAlign: "center",
		// 	rowSpan: "2",
		// 	headHeight: "22px",
		// 	marginLeft: "1px",
		// },
		// {
		// 	name: "received",
		// 	title: "Полученное значение",
		// 	width: "250px",
		// 	cellHorAlign: "left",
		// 	headHorAlign: "center",
		// 	rowSpan: "2",
		// 	headHeight: "22px",
		// 	marginLeft: "1px",
		// },
	];
	return clms;
}

import Cell from "./Cell";
import { getArgOrCallback } from "core/args_wrapper";

export default function Cells(props) {
    const cellDom = [];

    for (let clmObj of props.columnsFlat) {
        if (props.row.hasOwnProperty("hideColumns")) {
            if (props.row["hideColumns"].includes(clmObj.name)) continue;
        }
        if (clmObj.hasOwnProperty("skip")) {
            if (getArgOrCallback(clmObj.skip, props.row)) continue;
        }
        cellDom.push(<Cell key={`cell-${clmObj.name}-${props.row.id}`} rowObj={props.row} clmObj={clmObj} props={props} />);
    }

    return cellDom;
}

import { useSelector } from "react-redux";

import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import ReportOffOutlinedIcon from "@mui/icons-material/ReportOffOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import AddModeratorOutlinedIcon from "@mui/icons-material/AddModeratorOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import CommentsDisabledOutlinedIcon from "@mui/icons-material/CommentsDisabledOutlined";

import "./CustomMarksCell.css";

const CustomMarksCell = ({ rowObj, parentProps, funcs }) => {
	const userPermissions = useSelector((state) => state.auth.permissions);

	const getHeight = () => {
		let height = 17;
		if (rowObj.rowHeightMultiply && rowObj.rowHeightMultiply !== 1) {
			height = 22 * rowObj.rowHeightMultiply;
		}
		return height;
	};

	const getErrorJsx = () => {
		const blankJsx = <span style={{ minWidth: "17px" }}></span>;
		if (!rowObj._details) return blankJsx;
		if (rowObj._details.length === 0) return blankJsx;

		let icon;
		let title = "";

		if (parentProps.errorsVisible === true) {
			icon = <ReportOffOutlinedIcon row_type="ErrorsDetails" />;
			title = "Закрыть";
		} else {
			icon = <ReportOutlinedIcon row_type="ErrorsDetails" />;
			title = "Открыть";
		}

		return (
			<div className="ErrorMark" title={title}>
				{icon}
			</div>
		);
	};

	const getCommentJsx = () => {
		if (!userPermissions.is_expert) return;
		if (!rowObj._has_comment && !rowObj.showComments) return;

		let icon;
		let title = "";
		let valAfterClick = null;

		if (rowObj.showComments === true) {
			icon = <CommentsDisabledOutlinedIcon />;
			title = "Закрыть комментарии";
			valAfterClick = false;
		} else {
			icon = <CommentOutlinedIcon />;
			title = "Открыть комментарии";
			valAfterClick = true;
		}
		return (
			<div className="CommentMark" title={title} onClick={() => funcs["showComments"]({ val: valAfterClick, targetRowObj: rowObj })}>
				{icon}
			</div>
		);
	};

	const getProtectionJsx = () => {
		if (!userPermissions.is_expert) return;
		if (!rowObj.protectable) return;
		let isProtected;
		let icon;
		if (rowObj.protected_id) {
			icon = <ShieldOutlinedIcon />;
			isProtected = "true";
		} else {
			icon = <AddModeratorOutlinedIcon />;
			isProtected = "false";
		}
		return (
			<div
				className="ProtectionMark"
				title="Защита"
				is_protected={isProtected}
				style={{ color: "#6c6c6c" }}
				onClick={() => funcs["lsrProtection"](rowObj)}
			>
				{icon}
			</div>
		);
	};

	return (
		<td key={`cell-marks-${rowObj.key}`} className="TableCell" clm="marks" treecell="false" style={{ height: `${getHeight()}px`, padding: 0 }}>
			<div className="CustomMarksCell">
				{getErrorJsx()}
				{getCommentJsx()}
				{getProtectionJsx()}
			</div>
		</td>
	);
};

export { CustomMarksCell };


const ContextMenuCommon = ({ label, callback, disabled, icon, hotBtns }) => {

    const handleClick = () => {
        if (!disabled && callback) {
            callback();
        }
    };

    return (
        <div
            className={`context-menu-common ${disabled ? 'disabled' : ''}`}
            onClick={handleClick}
        >
            {icon ? icon : <div className="context-menu-empty-item"></div>}
            {label}
            <span className="context-menu-hot-btns">
                {hotBtns}
            </span>
        </div>
    );
};

export default ContextMenuCommon;

import { EstimateViewerContextMenuProjectTree } from "./EstimateViewerContextMenuProjectTree";
import { EstimateViewerContextMenuLsrItem } from "./EstimateViewerContextMenuLsrItem";
import { EstimateViewerContextMenuLsrCommon } from "./EstimateViewerContextMenuLsrCommon";
import { EstimateViewerContextMenuLsrInnerItem } from "./EstimateViewerContextMenuLsrInnerItem";
import { EstimateViewerContextMenuLsrRes } from "./EstimateViewerContextMenuLsrRes";
import { EstimateViewerContextMenuProtocol } from "./EstimateViewerContextMenuProtocol";
import { EstimateViewerContextMenuSectionEnding } from "./EstimateViewerContextMenuSectionEnding";
import { EstimateViewerContextMenuEstimateEnding } from "./EstimateViewerContextMenuEstimateEnding";
import { EstimateViewerContextMenuPnrGroup } from "./EstimateViewerContextMenuPnrGroup";

const EstimateViewerContextMenuFactory = ({ contextMenuObj, callbacks, currentPage, userSettings }) => {
    if (contextMenuObj.model_name === "ProjectTreeDoc") {
        return EstimateViewerContextMenuProjectTree({ contextMenuObj, callbacks, userSettings });
    } else if (
        [
            "LocalEstimatesItems",
            "LocalEstimatesResMaterial",
            "LocalEstimatesResMachine",
            "LocalEstimatesResTransport",
            "LocalEstimatesResEquipment",
        ].includes(contextMenuObj.model_name) &&
        contextMenuObj.is_item === "1"
    ) {
        return EstimateViewerContextMenuLsrItem({ contextMenuObj, callbacks, userSettings });
    } else if (
        ["LocalEstimatesResMaterial", "LocalEstimatesResMachine", "LocalEstimatesResEquipment"].includes(
            contextMenuObj.model_name
        ) &&
        contextMenuObj.is_item !== "1"
    ) {
        return EstimateViewerContextMenuLsrRes({ contextMenuObj, callbacks, userSettings });
    } else if (["LocalEstimateRimSecSumDirect"].includes(contextMenuObj.model_name)) {
        return EstimateViewerContextMenuSectionEnding({ contextMenuObj, callbacks, userSettings });
    } else if (["LocalEstimateRimSum"].includes(contextMenuObj.model_name)) {
        return EstimateViewerContextMenuEstimateEnding({ contextMenuObj, callbacks, userSettings });
    } else if (["LocalEstimatePnrGroup"].includes(contextMenuObj.model_name)) {
        return EstimateViewerContextMenuPnrGroup({ contextMenuObj, callbacks, userSettings });
    } else if (
        currentPage === "LocalEstimateViewer" &&
        contextMenuObj.item_id != null &&
        contextMenuObj.item_id !== ""
    ) {
        return EstimateViewerContextMenuLsrInnerItem({ contextMenuObj, callbacks, userSettings });
    } else if (currentPage === "LocalEstimateViewer") {
        return EstimateViewerContextMenuLsrCommon({ contextMenuObj, callbacks, userSettings });
    } else if (currentPage === "ProtocolViewer") {
        return EstimateViewerContextMenuProtocol({ contextMenuObj, callbacks, userSettings });
    }

    return [];
};

const getContextMenuVisible = (obj) => {
    return true;
};

export { EstimateViewerContextMenuFactory, getContextMenuVisible };

import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setClickedTableRow, setClickedTableClmName } from "slices/ribbon";

import TableMain from "./TableMain";
import { getTableDefaultOptions } from "./TableDefaultOptions";

import { changeNestedArrKeyValueOnCondition, copyObj, copyArr } from "core/iterable_utils";
import { getStrOrEmptyStrIfNull } from "core/str_utils";
import { getTextWidth } from "components/utils.js";

const Table = ({ rowData, columnsData, scrollBlock, options, funcs }) => {
	const dispatch = useDispatch();

	const [sortParams, setSortParams] = useState({});
	const [filterParams, setFilterParams] = useState({});
	const [filterValues, setFilterValues] = useState({});

	// const clickedTableRow = useSelector((state) => state.ribbon.clickedTableRow);
	const clickedTableClmName = useSelector((state) => state.ribbon.clickedTableClmName);

	const tableDefaultOptions = getTableDefaultOptions();
	const displayOptions = { ...tableDefaultOptions.displayOptions, ...(options.displayOptions != null ? options.displayOptions : {}) };
	const tableOptions = { ...getTableDefaultOptions(), ...options };

	const selectRow = (tableData, clickedRow, targetDom, parentRowObj) => {
		if (tableOptions?.selectOptions?.controlled !== true) return;

		const nextSelectedRow = copyObj(clickedRow);
		if (!tableOptions?.selectOptions?.preventSetClickedTableRow) {
			dispatch(setClickedTableRow({ ...clickedRow, parentRow: parentRowObj }));
		}
		dispatch(setClickedTableClmName(targetDom?.attributes?.clm?.value));

		let newTableData = copyArr(rowData);

		if (tableOptions?.selectOptions?.multiple !== true) {
			newTableData = changeNestedArrKeyValueOnCondition({
				arr: newTableData,
				keySearch: "selected",
				valSearch: true,
				keyChange: "selected",
				valChange: false,
			});
		}

		if (nextSelectedRow) {
			newTableData = changeNestedArrKeyValueOnCondition({
				arr: newTableData,
				keySearch: "key",
				valSearch: nextSelectedRow.key,
				keyChange: "selected",
				valChange: true,
				callback: (row) => {
					if (tableOptions?.selectOptions?.expandOnSelect) {
						row.rowHeightMultiply = getRowMultiply({ rowObj: row });
					}
					return row;
				},
			});
		}

		return funcs.setTableData({ newTableData: newTableData });
	};

	const getRowMultiply = ({ rowObj }) => {
		const safeSpace = 100;
		const fontSize = tableOptions.selectOptions.expandOnSelect.fontSize;
		const tableClass = tableOptions.selectOptions.expandOnSelect.tableClass;
		let textWidth = 0;
		const rowHeightMultiplyArr = [];
		for (let clm of tableOptions.selectOptions.expandOnSelect.columnsName) {
			textWidth = getTextWidth(rowObj[`${clm}`]?.toString(), fontSize) + safeSpace;
			const rowWidth = parseInt(getComputedStyle(document.querySelector(`.${tableClass} th[clm='${clm}']`)).width.replace("px", ""));
			rowHeightMultiplyArr.push(Math.ceil(textWidth / rowWidth));
		}
		const rowHeightMultiply = Math.max(...rowHeightMultiplyArr);
		if (rowObj.rowHeightMultiply == null || rowObj.rowHeightMultiply === 1) {
			return rowHeightMultiply;
		} else if (rowObj.rowHeightMultiply > 1) {
			return null;
		} else {
			return null;
		}
	};

	const setRowCheckBox = ({ rowObj, clmName, value }) => {
		const newTableData = changeNestedArrKeyValueOnCondition({
			arr: copyArr(rowData),
			keySearch: "key",
			valSearch: rowObj.key,
			keyChange: [clmName],
			valChange: value,
		});
		return funcs.setTableData({ newTableData: newTableData });
	};

	const changeRowChildrenVisible = ({ clickedRow, tableData }) => {
		let newTableData = copyObj(rowData);
		let newValue = true;
		if (clickedRow.childs_visible === true) newValue = false;
		if (clickedRow) {
			newTableData = changeNestedArrKeyValueOnCondition({
				arr: newTableData,
				keySearch: "key",
				valSearch: clickedRow.key,
				keyChange: "childs_visible",
				valChange: newValue,
				callback: (row) => {
					if (row._children) {
						row._children.forEach((ch) => {
							ch.visible = newValue;
							return ch;
						});
					}
					return row;
				},
			});
		}

		return funcs.setTableData({ newTableData: newTableData });
	};

	const changePaperDetailsVisible = ({ clickedRow, tableData }) => {
		if (tableOptions?.paperSettings?.use !== true) return;
		let newTableData = copyObj(tableData);

		newTableData = changeNestedArrKeyValueOnCondition({
			arr: newTableData,
			keySearch: "key",
			valSearch: clickedRow.key,
			keyChange: "paper_visible",
			valChange: true,
			callback: (row) => {
				if (row.paper_visible == null) {
					row.paper_visible = true;
				} else {
					row.paper_visible = !row.paper_visible;
				}
				return row;
			},
		});

		return funcs.setTableData({ newTableData: newTableData });
	};

	const sortData = ({ clmName, direction }) => {
		let customFunc;
		let stadartSortCallback;
		let newDirection;
		if (direction === "up") {
			customFunc = funcs.customSortDataUp;
			stadartSortCallback = (a, b) => (getStrOrEmptyStrIfNull(a[clmName]) < getStrOrEmptyStrIfNull(b[clmName]) ? 1 : -1);
			newDirection = "down";
		} else if (direction === "down") {
			customFunc = funcs.customSortDataDown;
			stadartSortCallback = (a, b) => (getStrOrEmptyStrIfNull(a[clmName]) > getStrOrEmptyStrIfNull(b[clmName]) ? 1 : -1);
			newDirection = "up";
		}

		if (tableOptions?.sortOptions?.serverSort === true) {
			if (customFunc[clmName]) {
				customFunc[clmName]();
				setSortParams((prev) => ({ ...prev, [clmName]: newDirection }));
			}
		} else {
			let newTableData = copyObj(rowData);
			if (customFunc && customFunc[clmName]) {
				newTableData = customFunc[clmName]({ newTableData, clmName });
			} else {
				newTableData = newTableData.sort((a, b) => stadartSortCallback(a, b));
			}
			setSortParams((prev) => ({ ...prev, [clmName]: newDirection }));
			funcs.setTableData({ newTableData: newTableData });
		}
	};

	const filterData = (rowsData, clmName, strValue) => {
		if (tableOptions?.filterOptions?.serverFilter === true) {
			if (funcs.customFilter) {
				funcs.customFilter([{ name: clmName, value: strValue }]);
			}
		} else {
			const newTableData = copyObj(rowData).map((row) => {
				row.visible = true;
				if (!String(row[clmName]).toLowerCase().includes(String(strValue).toLowerCase())) {
					row.visible = false;
				}
				return row;
			});
			funcs.setTableData({ newTableData: newTableData });
		}
	};

	const updateFilterStatus = (clmName, status) => {
		setFilterParams((prev) => ({ ...prev, [clmName]: status }));
	};

	useEffect(() => {
		const sort = {};
		const flt = {};
		const fltVals = {};
		for (let clm of columnsData) {
			if (clm.sort) sort[clm.name] = "down";
			if (clm.filter) flt[clm.name] = "close";
		}

		if (tableOptions?.filterOptions?.prevFiltredData) {
			Object.keys(tableOptions?.filterOptions?.prevFiltredData).forEach((key) => {
				const value = tableOptions?.filterOptions?.prevFiltredData[key];
				if (value != null && String(value).length > 0) {
					flt[key] = "open";
					fltVals[key] = value;
				}
			});
		}
		setSortParams((prev) => sort);
		setFilterParams((prev) => flt);
		setFilterValues((prev) => fltVals);

		if (funcs.keyboardAction) {
			document.addEventListener("keydown", funcs.keyboardAction.onKeyDown);
			document.addEventListener("keyup", funcs.keyboardAction.onKeyUp);
		}

		return () => {
			if (funcs.keyboardAction) {
				document.removeEventListener("keydown", funcs.keyboardAction.onKeyDown);
				document.removeEventListener("keyup", funcs.keyboardAction.onKeyUp);
			}
			dispatch(setClickedTableClmName(null));
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<TableMain
			rows={{ data: rowData }}
			columns={columnsData}
			options={{
				selectable: tableOptions?.selectOptions?.controlled,
				selectableUncontrolled: tableOptions?.selectOptions?.uncontrolled,
				scrollBlock: scrollBlock,
				scrollHeightControll: displayOptions.scrollHeightControll,
				clickedTableClmName: clickedTableClmName,
				rowsDisplayCount: displayOptions.rowsDisplayCount,
				rowHeight: displayOptions.rowHeight,
				funcCall: "local",
				singleClickOptions: {
					useTargetParentNode: tableOptions.singleClickOptions.useTargetParentNode,
					useStringInclude: tableOptions.singleClickOptions.useTargetParentNode,
				},
				useEditIcon: displayOptions.useEditIcon,
				resizableColumns: tableOptions.resizableColumns,
				columnsSettingsName: tableOptions.columnsSettingsName,
				userSettings: tableOptions.userSettings,
				selecting: tableOptions.selectingInputOptions,
				treeChildPaddingLeft: tableOptions?.nestingOptions?.childPaddingLeft,
				paperSettings: tableOptions?.paperSettings?.jsx,
				customRowDetail: tableOptions?.customRowDetail,
			}}
			func={{
				selectDataLocal: selectRow,
				changeVisible: changeRowChildrenVisible,
				changePaperDetailsVisible: changePaperDetailsVisible,
				setCheckedFunc: setRowCheckBox,
				changeVisibleOptions: tableOptions?.nestingOptions?.changeChildrenVisible,
				editDataFactory: funcs.editDataFactory,
				updateFilterValuesLocal: funcs.updateFilterValuesLocal,
				doubleClickRowAction: funcs.doubleClickRowAction,
				sortDataUp: (rowsData, clmName) => sortData({ clmName: clmName, direction: "up" }),
				sortDataDown: (rowsData, clmName) => sortData({ clmName: clmName, direction: "down" }),
				filterData: filterData,
				updateFilterStatus: updateFilterStatus,
				singleClickRowAction: {
					tagName: tableOptions?.singleClickOptions?.tagName,
					action: tableOptions?.singleClickOptions?.action,
				},
				...funcs.customFuncs,
			}}
			sortParams={sortParams}
			filterParams={filterParams}
			filterValues={filterValues}
		></TableMain>
	);
};

export default Table;

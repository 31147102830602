import { useRef } from "react";

import Draggable from "react-draggable";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { showTableMore } from "pages/Fsnb/utils";
import useTable from "hooks/useTable";
import ResourceMappingModalColumns from "./ResourceMappingModalColumns.js";
import apiLocalEstimate from "api/local_estimate";
import { searchNestedIterableItem } from "core/iterable_utils";

import "./ResourceMappingModal.css";

const ResourceMappingModal = ({ tableData, setResourceMappingModalVisible, setTableData, ResourceMappingItem, hideProject }) => {
	const scrollBlock = useRef(null);

	const showMainTableMore = (row) => {
		showTableMore(row, "12px", ["lsr_name", "res_name"], setTableData, "ResourceMappingModalTableLayout", "res_name");
	};

	const openLsrTc = ({ rowObj }) => {
		const prom = apiLocalEstimate.getTree(rowObj.lsr_id);

		prom.then((res) => {
			const tableData = res.data.table_data;
			const displayScrollTop = searchNestedIterableItem({
				iterable: tableData,
				isSeekRowCallback: (row) => {
					if (row.key && row.key === rowObj.key) {
						return true;
					} else {
						return false;
					}
				},
				isPermittedRowCallback: (row) => {
					if (row.visible !== false) {
						return true;
					} else {
						return false;
					}
				},
				isPermittedChildsLoopCallback: (row) => {
					if (row.childs_visible !== false || row.model_name === "CostParentLabel") {
						return true;
					} else {
						return false;
					}
				},
			});
			localStorage.setItem("displayScrollTop", displayScrollTop.seekCount);
			localStorage.setItem("scrollType", "lsr");
			localStorage.setItem("scrollKey", rowObj.key);
			localStorage.setItem("highlightRowClm", "code");
		});
		window.open(`/project/${rowObj.proj_id}/lsr/${rowObj.lsr_id}/`);
	};

	const tableBody = useTable(
		tableData,
		ResourceMappingModalColumns(hideProject, openLsrTc),
		{
			scrollBlock: scrollBlock,
			selectable: true,
			funcCall: "local",
			singleClickOptions: {
				useTargetParentNode: true,
			},
		},
		{
			updateData: setTableData,
			singleClickRowAction: {
				tagName: {
					allowed: ["td", "span"],
				},
				action: showMainTableMore,
			},
			doubleClickRowAction: {
				action: (row) => openLsrTc({ rowObj: row }),
			},
		}
	);

	return (
		<>
			<Draggable handle=".ResourceMappingModalTitle">
				<Dialog className="ResourceMappingModal" open slotProps={{ backdrop: { style: { backgroundColor: "transparent" } } }}>
					<DialogTitle className="ResourceMappingModalTitle">
						<div>Исходные данные: {ResourceMappingItem?.name}</div>
						<div>
							<Tooltip title="Закрыть">
								<IconButton
									aria-label="Закрыть"
									onClick={() => {
										setTableData([]);
										setResourceMappingModalVisible(false);
									}}
								>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						</div>
					</DialogTitle>

					<DialogContent className="ResourceMappingModalContent" ref={scrollBlock}>
						<div className="ResourceMappingModalBody">
							<div className="ResourceMappingModalTableLayout">{tableBody}</div>
						</div>
					</DialogContent>
				</Dialog>
			</Draggable>
		</>
	);
};

export { ResourceMappingModal };

import React from 'react';

import { IconButton, Tooltip } from '@mui/material';

import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';

export default function HeaderVisibleButton(props) {
    return (
        <Tooltip>
            <IconButton size="small"
                onClick={() => { props.f_handleVisible(); }}>
                {props.fullVisible === true ? <CloseFullscreenOutlinedIcon /> : <OpenInFullOutlinedIcon />}
            </IconButton>
        </Tooltip>
    );
}
import { useDispatch, useSelector } from "react-redux";

import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";

import { pushFilesToList } from "slices/import_files";

import "./ImportAccordionHeader.css";
import "./ImportAccordionBody.css";

const ImportAccordionHeader = ({ setImportExpanded, tableTab, setTableTab }) => {
	const dispatch = useDispatch();
	const filesList = useSelector((state) => state.importFiles.filesList);

	return (
		<div className="ImportAccordionHeader">
			<div className="ImportAccordionLeft">Импорт данных</div>
			<div className="ImportAccordionCenter">
				<UploadFileOutlinedIcon />
				<span>Для загрузки </span>
				<label htmlFor="UploadXml">
					<input
						style={{ display: "none" }}
						id="UploadXml"
						type="file"
						multiple
						value=""
						onChange={(e) => {
							setImportExpanded(true);
							dispatch(pushFilesToList(e.target.files));
						}}
					/>
					<span className="ImportLink">укажите файлы</span>
				</label>
				<span>сметных расчетов (ЛСР, ОСР, ССРСС, КА) или перенесите файлы на эту страницу </span>
				<span className="ImportComment">GGE или XML (max. 85 MB)</span>
			</div>
			<div className="ImportAccordionRight">
				<div className="ImportAccordionBodyHead">
					<span>
						Загружено {filesList.filter((x) => x.is_imported === true).length} из {filesList.length} файлов.
					</span>
					<span>Показать</span>
					{tableTab === "all" ? (
						<span className="ActiveTab" onClick={() => setTableTab("all")}>
							все загрузки
						</span>
					) : (
						<span className="NotActiveTab" onClick={() => setTableTab("all")}>
							все загрузки
						</span>
					)}
					<span>|</span>
					{tableTab === "errors" ? (
						<span className="ActiveTab" onClick={() => setTableTab("errors")}>
							с ошибкой
						</span>
					) : (
						<span className="NotActiveTab" onClick={() => setTableTab("errors")}>
							с ошибкой
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default ImportAccordionHeader;
